import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { Utils, mapQuery, FormCardRender } from '@gv/triage-components';

import { useDetailsQuery } from 'store/api/hospitals';
import { useGetFormQuery } from 'store/api/dynamic-forms';
import { generateRequestProps } from 'hooks/hospital/helpers';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { GetFormResponseData } from 'store/api/dynamic-forms/types';
import { ValidationFields } from 'components/cases-dynamic/form/types';
import {
  PassedStep,
  CallOutcomeType,
  DynamicStepState,
  CallOutcomeAdministrative,
} from 'types';
import {
  setStep,
  setActive,
  setPassed,
  selectDynamicFormStep,
} from 'store/slices/dynamic-form-step';

import { hasNoneOption, getRequiredFields } from './helpers';

const {
  Helpers: { mergeObjects },
  DFValidation: {
    FieldValidation,
    getFieldsValidation,
    validateWithNoneOption,
  },
} = Utils;
export const useDynamicFormProps = ({
  hospitalId,
  dynamicForm,
}: {
  hospitalId?: string | number;
  dynamicForm?: GetFormResponseData;
}) => {
  const dispatch = useAppDispatch();
  const [validationFields, setValidationFields] = useState<ValidationFields>(
    {}
  );
  const id = hospitalId ? String(hospitalId) : undefined;

  const { isFetching, data: hospital } = useDetailsQuery(
    id!,
    generateRequestProps(id)
  );

  const formQuery = useGetFormQuery(
    { hospitalId: hospitalId! },
    { skip: !hospitalId || !hospital?.is_dynamic_flow_enabled || !!dynamicForm }
  );
  const { data, ...restProps } = formQuery;
  const { steps = {} } = dynamicForm?.data ?? data?.data ?? {};

  const { activeStep, passedSteps = [] } = useAppSelector(
    selectDynamicFormStep()
  );

  const { isShowFeedback } = useSelector(
    (state: RootState) => state.analyticsExport
  );

  const setActiveStep = useCallback(
    (step?: string) => dispatch(setActive(step)),
    [dispatch]
  );

  const setPassedSteps = useCallback(
    (passed: PassedStep[]) => dispatch(setPassed(passed)),
    [dispatch]
  );

  const setSteps = useCallback(
    (stepState: DynamicStepState) => dispatch(setStep(stepState)),
    [dispatch]
  );

  const initialStep = useMemo(
    () => Object.values(steps).find((stp) => !!stp?.initialStep),
    [steps]
  );

  const resetSteps = useCallback(
    () =>
      setSteps({
        activeStep: initialStep?.id,
        passedSteps: initialStep ? [initialStep] : [],
      }),
    [setSteps, initialStep]
  );

  const passedStepsFieldValues = useMemo(() => {
    const filteredSteps = passedSteps.filter(
      (step) => !step.hideStep || activeStep === step.id
    );

    const fieldValues = filteredSteps.reduce<FormikValues>(
      (acc, step) => mergeObjects(acc, step.fieldsState ?? {}),
      {}
    );
    if (data?.id) {
      if (!fieldValues.own_fields) {
        fieldValues.own_fields = {};
      }
      fieldValues.own_fields.$flowId = data.id;
      fieldValues.own_fields.$passedSteps = filteredSteps.map(
        (item) => item.id
      );
    }
    return fieldValues;
  }, [passedSteps, activeStep, data?.id]);

  const currentStep = useMemo(
    () => (activeStep ? steps[activeStep] : undefined),
    [activeStep, steps]
  );

  const stepQuestions = useMemo(
    () =>
      currentStep?.widgets?.reduce<FormCardRender[]>(
        (sum, widget) => [...sum, ...(widget?.questions ?? [])],
        []
      ) ?? [],
    [currentStep?.widgets]
  );

  const validateCallOutcomeSubTypeM = (subType: CallOutcomeType) =>
    Yup.object().shape({
      type: Yup.string().test(
        'subTypeValidation',
        'Please select option',
        (value, context) => {
          const contextGeneric = context as unknown as {
            from: { value?: Record<string, unknown> }[];
          };
          const callOutcomeValue = contextGeneric.from[1].value;
          return !(callOutcomeValue?.type === subType && !value);
        }
      ),
    });

  const validationSchema = useMemo(() => {
    let schemaObject: Record<string, any> = {};
    const ownFieldsSchema: Record<string, any> = {};

    stepQuestions.forEach(({ fields, validationType, requiredFields = [] }) => {
      if (validationType) {
        const calcFields = validationFields[validationType];
        schemaObject = {
          ...schemaObject,
          ...getFieldsValidation({
            validationType,
            requiredFields: calcFields
              ? getRequiredFields(requiredFields, calcFields)
              : requiredFields,
          }),
        };
      } else {
        fields.forEach(({ formFieldProps, type: fieldType }) => {
          const name = formFieldProps?.name;
          const fieldSchema = FieldValidation[String(fieldType)];

          if (fieldSchema && name) {
            const nameSchema = requiredFields.includes(name)
              ? hasNoneOption(name)
                ? validateWithNoneOption(fieldSchema, name)
                : fieldSchema.required('Required field')
              : fieldSchema.nullable();

            if (name.startsWith('own_fields.')) {
              const [, ownName] = name.split('.');
              ownFieldsSchema[ownName] = nameSchema;
            } else {
              schemaObject[name] = nameSchema;
            }
          }
        });
      }
    });

    const isEditFeedbackEmail = location.pathname.includes('consultation/edit');

    return Yup.object().shape({
      ...schemaObject,
      own_fields: Yup.object().shape(ownFieldsSchema),
      chief_complaints: Yup.array().min(1).required(),
      problem_summary: Yup.string().trim().required().min(10),
      survey: Yup.object().shape({
        feedback_email:
          isShowFeedback && !isEditFeedbackEmail
            ? Yup.string()
                .email('Invalid email address')
                .required('Feedback Email is required')
            : Yup.string(),
      }),
      callOutcome: Yup.object().shape({
        type: Yup.string().required('Please select call outcome'),
        [CallOutcomeType.Emergency]: validateCallOutcomeSubTypeM(
          CallOutcomeType.Emergency
        ),
        [CallOutcomeType.Appointment]: validateCallOutcomeSubTypeM(
          CallOutcomeType.Appointment
        ),
        [CallOutcomeType.TransferToBackline]: validateCallOutcomeSubTypeM(
          CallOutcomeType.TransferToBackline
        ),
        [CallOutcomeType.Administrative]: Yup.object().when('type', {
          otherwise: (schema) => schema.notRequired(),
          is: (value: CallOutcomeType) =>
            value === CallOutcomeType.Administrative,
          then: (schema) =>
            schema.shape({
              [CallOutcomeAdministrative.Call]: Yup.object().shape({
                outcomes: Yup.array()
                  .min(
                    1,
                    'Administrative call option field must have at least 1 items'
                  )
                  .required('Please select outcome'),
              }),
            }),
        }),
      }),
    });
  }, [stepQuestions, validationFields]);

  useEffect(() => {
    if ((!activeStep || !passedSteps.length) && initialStep) {
      resetSteps();
    }
  }, [activeStep, initialStep, resetSteps]);

  return {
    steps,
    setSteps,
    activeStep,
    resetSteps,
    initialStep,
    passedSteps,
    stepQuestions,
    setActiveStep,
    setPassedSteps,
    validationSchema,
    setValidationFields,
    passedStepsFieldValues,
    ...mapQuery(restProps),
    isLoading: restProps.isFetching || isFetching,
  };
};
