import { useMemo } from 'react';
import { Row, Col, List, ListHeader } from '@gv/triage-components';

import { Case } from 'types';
import { getCaseFormSelectedPets } from 'utils/helpers';
import { titles, PetCard, PetsTemplate } from 'components/pet-card';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';

export const PetInfo = ({
  pets,
  petId,
  clientId,
  hospitalId,
}: {
  pets: Case['pets'];
  petId: Case['pet_id'];
  clientId: Case['client_id'];
  hospitalId: Case['hospital_id'];
}) => {
  const { data: client } = useClientQuery(
    {
      allowDeleted: true,
      id: String(clientId),
      hospitalId: hospitalId ? String(hospitalId) : undefined,
    },
    {
      skip: !clientId,
    }
  );

  const listPets = useMemo(
    () => getCaseFormSelectedPets(client?.pets, pets, petId),
    [client?.pets, petId, pets]
  );

  if (!listPets) {
    return null;
  }

  return (
    <Row>
      <Col>
        <p className="semibold">Pet info</p>

        <List
          count={listPets.length}
          mainWrapperStyles={{ padding: '0' }}
          header={
            <ListHeader
              titles={titles}
              padding="8px 0px"
              template={PetsTemplate.desktop}
              tabletTemplate={PetsTemplate.desktop}
            />
          }
        >
          {listPets.map((pet) => (
            <PetCard pet={pet} key={pet.id} />
          ))}
        </List>
      </Col>
    </Row>
  );
};
