import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery, generateAPIPath } from 'store/query';
import {
  SchedulingMatrixItem,
  MatrixAppointmentType,
  AppointmentMatrixConfig,
} from 'types';

import {
  GetMatrixRequest,
  GetMatrixResponse,
  CreateMatrixRequest,
  UpdateMatrixRequest,
  DeleteMatrixRequest,
  GetMatrixTypesRequest,
  GetMatrixConfigRequest,
  GetMatrixTypesResponse,
  GetMatricesListRequest,
  GetMatrixConfigResponse,
  GetMatricesListResponse,
  UpdateAppointmentMatrixConfigRequest,
} from './types';

const tagTypes = ['SchedulingMatrix'];

export const schedulingMatrixApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'schedulingMatrixApi',
  endpoints: (build) => ({
    deleteMatrix: build.mutation<void, DeleteMatrixRequest>({
      invalidatesTags: tagTypes,
      query: ({ matrixId, hospitalId }) => {
        return {
          method: 'delete',
          url: generateAPIPath(URL.DELETE_HOSPITAL_SCHEDULING_MATRIX, {
            matrixId,
            hospitalId,
          }),
        };
      },
    }),

    createMatrix: build.mutation<SchedulingMatrixItem, CreateMatrixRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, hospitalId }) => {
        return {
          data,
          method: 'post',
          url: generateAPIPath(URL.CREATE_HOSPITAL_SCHEDULING_MATRIX, {
            hospitalId,
          }),
        };
      },
    }),

    updateMatrix: build.mutation<void, UpdateMatrixRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, matrixId, hospitalId }) => {
        return {
          data,
          method: 'patch',
          url: generateAPIPath(URL.UPDATE_HOSPITAL_SCHEDULING_MATRIX, {
            matrixId,
            hospitalId,
          }),
        };
      },
    }),

    updateMatrixConfig: build.mutation<
      void,
      UpdateAppointmentMatrixConfigRequest
    >({
      invalidatesTags: tagTypes,
      query: ({ data, hospitalId }) => {
        return {
          data,
          method: 'patch',
          url: generateAPIPath(URL.UPDATE_HOSPITAL_SCHEDULING_MATRIX_CONFIG, {
            hospitalId,
          }),
        };
      },
    }),

    getMatrix: build.query<SchedulingMatrixItem, GetMatrixRequest>({
      providesTags: tagTypes,
      transformResponse: (response: GetMatrixResponse) => response.data,
      query: ({ matrixId, hospitalId }) => {
        return {
          method: 'get',
          url: generateAPIPath(URL.GET_HOSPITAL_SCHEDULING_MATRIX, {
            matrixId,
            hospitalId,
          }),
        };
      },
    }),

    getMatricesList: build.query<
      SchedulingMatrixItem[],
      GetMatricesListRequest
    >({
      providesTags: tagTypes,
      transformResponse: (response: GetMatricesListResponse) => response.data,
      query: ({ hospitalId }) => {
        return {
          method: 'get',
          url: generateAPIPath(URL.GET_HOSPITAL_SCHEDULING_MATRICES, {
            hospitalId,
          }),
        };
      },
    }),

    getMatrixConfig: build.query<
      AppointmentMatrixConfig,
      GetMatrixConfigRequest
    >({
      providesTags: tagTypes,
      transformResponse: (response: GetMatrixConfigResponse) => response.data,
      query: ({ hospitalId }) => {
        return {
          method: 'get',
          url: generateAPIPath(URL.GET_HOSPITAL_SCHEDULING_MATRIX_CONFIG, {
            hospitalId,
          }),
        };
      },
    }),

    getMatrixTypes: build.query<MatrixAppointmentType[], GetMatrixTypesRequest>(
      {
        providesTags: tagTypes,
        transformResponse: (response: GetMatrixTypesResponse) => response.data,
        query: ({ hospitalId }) => {
          return {
            method: 'get',
            url: generateAPIPath(
              URL.GET_HOSPITAL_SCHEDULING_APPOINTMENT_TYPES,
              {
                hospitalId,
              }
            ),
          };
        },
      }
    ),
  }),
});

export const {
  useGetMatrixQuery,
  useGetMatrixTypesQuery,
  useGetMatrixConfigQuery,
  useCreateMatrixMutation,
  useUpdateMatrixMutation,
  useDeleteMatrixMutation,
  useGetMatricesListQuery,
  useUpdateMatrixConfigMutation,
} = schedulingMatrixApi;
