import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Utils,
  Badges,
  FlexLayout,
  SelectField,
  ChildrenProps,
  SelectFieldProps,
} from '@gv/triage-components';

import { CallOutcomeEmergency } from 'types';
import { HospitalTitle } from 'components/hospital-title';
import { AcceptanceBadges } from 'components/acceptance-badges';

export const DepartmentSelect = ({
  name,
  options,
  children,
  disableInitialFirstOption = false,
  ...props
}: ChildrenProps<
  SelectFieldProps & {
    disableInitialFirstOption?: boolean;
  }
>) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (
      options?.length === 1 &&
      Utils.Helpers.isSingleSelectValue(options[0]) &&
      !disableInitialFirstOption
    ) {
      setFieldValue(name, String(options[0].data.id));
    }
  }, [options, disableInitialFirstOption]);

  if (!options) {
    return null;
  }

  return (
    <SelectField
      {...props}
      onlyValue
      name={name}
      isClearable
      isSearchable
      options={options}
      label="Select hospital"
      placeholder="Select hospital"
      formatOptionLabel={({ data, label, fieldName }, { context }) =>
        context === 'menu' ? (
          <FlexLayout gap={8} flexDirection="column">
            <HospitalTitle name={data.name} address={data.address} />

            {fieldName === CallOutcomeEmergency.ReferToEr &&
              data.HospitalAcceptance && (
                <Badges>
                  <AcceptanceBadges data={data.HospitalAcceptance} />
                </Badges>
              )}
          </FlexLayout>
        ) : (
          label
        )
      }
    />
  );
};
