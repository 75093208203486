import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { ERService, ERSubService, BaseResponse } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { cacheByIdArg, providesList, invalidatesList } from 'utils/query-cache';

import {
  ListResponse,
  CreateResponse,
  DetailsResponse,
  DetailsRequestQuery,
  CreateRequestMutation,
  UpdateRequestMutation,
  DeleteRequestMutation,
  SubservicesListResponse,
  CreateSubserviceResponce,
  SubserviceDetailsResponse,
  SubservicesListRequestQuery,
  SubservicesDetailsRequestQuery,
  CreateSubserviceRequestMutation,
  UpdateSubserviceRequestMutation,
  DeleteSubserviceRequestMutation,
} from './types';

const serviceAdapter = createEntityAdapter<ERService, number>({
  selectId: (item) => item.id,
});

const subserviceAdapter = createEntityAdapter<ERSubService, number>({
  selectId: (item) => item.id,
});

const tagType = 'ErServices';

export const erServicesApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'erServicesApi',
  endpoints: (build) => ({
    list: build.query<ERService[], string>({
      providesTags: providesList(tagType),
      transformResponse: (response: ListResponse) => response.data,
      query: (id) => ({
        method: 'get',
        url: URL.GET_ER_SERVICES.replace(':hospitalId', id),
      }),
    }),

    subservicesList: build.query<ERSubService[], SubservicesListRequestQuery>({
      transformResponse: (response: SubservicesListResponse) => response.data,
      query: ({ serviceId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_ER_SUBSERVICES, {
          serviceId,
          hospitalId,
        }),
      }),
    }),

    create: build.mutation<ERService, CreateRequestMutation>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CreateResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: URL.CREATE_ER_SERVICE.replace(':hospitalId', hospitalId),
      }),
    }),

    delete: build.mutation<string, DeleteRequestMutation>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: BaseResponse<string>) => response.data,
      query: ({ serviceId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_ER_SERVICE, {
          serviceId,
          hospitalId,
        }),
      }),
    }),

    subserviceDetails: build.query<
      ERSubService,
      SubservicesDetailsRequestQuery
    >({
      transformResponse: (response: SubserviceDetailsResponse) => response.data,
      query: ({ subId, serviceId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_ER_SUBSERVICE, {
          subId,
          serviceId,
          hospitalId,
        }),
      }),
    }),

    details: build.query<ERService, DetailsRequestQuery>({
      transformResponse: (response: DetailsResponse) => response.data,
      providesTags: (result, error, { serviceId }) =>
        cacheByIdArg(tagType)(result, error, serviceId),
      query: ({ serviceId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_ER_SERVICE, {
          serviceId,
          hospitalId,
        }),
      }),
    }),

    update: build.mutation<ERService, UpdateRequestMutation>({
      transformResponse: (response: BaseResponse<ERService>) => response.data,
      invalidatesTags: (result, error, { serviceId }) =>
        cacheByIdArg(tagType)(result, error, serviceId),
      query: ({ serviceId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_ER_SERVICE, {
          serviceId,
          hospitalId,
        }),
      }),
    }),

    deleteSubservice: build.mutation<string, DeleteSubserviceRequestMutation>({
      transformResponse: (response: BaseResponse<string>) => response.data,
      invalidatesTags: (result, error, { serviceId }) =>
        cacheByIdArg(tagType)(result, error, serviceId),
      query: ({ subId, serviceId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_ER_SUBSERVICE, {
          subId,
          serviceId,
          hospitalId,
        }),
      }),
    }),

    createSubservice: build.mutation<
      ERSubService,
      CreateSubserviceRequestMutation
    >({
      transformResponse: (response: CreateSubserviceResponce) => response.data,
      invalidatesTags: (result, error, { serviceId }) =>
        cacheByIdArg(tagType)(result, error, serviceId),
      query: ({ serviceId, hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.CREATE_ER_SUBSERVICE, {
          serviceId,
          hospitalId,
        }),
      }),
    }),

    updateSubservice: build.mutation<
      ERSubService,
      UpdateSubserviceRequestMutation
    >({
      transformResponse: (response: BaseResponse<ERSubService>) =>
        response.data,
      invalidatesTags: (result, error, { serviceId }) =>
        cacheByIdArg(tagType)(result, error, serviceId),
      query: ({ subId, serviceId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_ER_SUBSERVICE, {
          subId,
          serviceId,
          hospitalId,
        }),
      }),
    }),
  }),
});

export const erServicesSlice = createSlice({
  reducers: {},
  name: 'erServices',
  initialState: serviceAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      erServicesApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        serviceAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      erServicesApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        serviceAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      erServicesApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        serviceAdapter.updateOne(state, {
          changes: payload,
          id: serviceAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      serviceAdapter.removeAll(state);
    });
  },
});

export const erSubservicesSlice = createSlice({
  reducers: {},
  name: 'erSubservices',
  initialState: subserviceAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      erServicesApi.endpoints.subservicesList.matchFulfilled,
      (state, { payload }) => {
        subserviceAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      erServicesApi.endpoints.subserviceDetails.matchFulfilled,
      (state, { payload }) => {
        subserviceAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      erServicesApi.endpoints.updateSubservice.matchFulfilled,
      (state, { payload }) => {
        subserviceAdapter.updateOne(state, {
          changes: payload,
          id: subserviceAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      subserviceAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useSubservicesListQuery,
  useSubserviceDetailsQuery,
  useCreateSubserviceMutation,
  useUpdateSubserviceMutation,
  useDeleteSubserviceMutation,
} = erServicesApi;
