import {
  Col,
  Row,
  SmsStatus,
  FlexLayout,
  RadioFieldGroup,
} from '@gv/triage-components';

import { useUpdateMutation } from 'store/api/clients';

import { useFormEntities } from '../form';

export const SMSNotificationsField = () => {
  const { client } = useFormEntities();
  const isStopSms = client?.sms_status === SmsStatus.Stop;
  const [updateClient, updateClientMutation] = useUpdateMutation();

  return (
    <Row>
      <Col>
        {isStopSms ? (
          <FlexLayout flexDirection="column">
            <h4>Confirm SMS notifications</h4>
            <p>
              Client has chosen to disable SMS notifications. To resume SMS
              notifications, please advise them to send{' '}
              <span className="semibold">"START"</span> to the current calling
              number.
            </p>
          </FlexLayout>
        ) : (
          <RadioFieldGroup
            name="sms_status"
            label="Does client confirm to receive SMS notifications from the hospital?"
            onChange={async (value) => {
              if (client?.id) {
                await updateClient({
                  client_id: client.id,
                  sms_status: value as SmsStatus,
                });
              }
            }}
            items={[
              {
                label: 'Yes',
                value: 'allowed',
                id: 'sms_status-yes',
                disabled: updateClientMutation.isLoading,
              },
              {
                label: 'No',
                value: 'restricted',
                id: 'sms_status-no',
                disabled: updateClientMutation.isLoading,
              },
            ]}
          />
        )}
      </Col>
    </Row>
  );
};
