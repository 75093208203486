import { Utils } from '@gv/triage-components';

import { generateUrl } from 'utils/helpers';

import { apiInstance } from '../instance';

import { downloadXLSXRequest } from './types';

const { addParamsToUrl } = Utils.Query;

export const downloadXLSX = ({
  url,
  end,
  start,
  orderBy,
  direction,
  hospitalId,
  departmentId,
}: downloadXLSXRequest) => {
  const requestUrl = hospitalId ? generateUrl(url, { hospitalId }) : url;

  return apiInstance.get(
    addParamsToUrl(requestUrl, {
      end,
      start,
      orderBy,
      direction,
      departmentId,
    }),
    { responseType: 'blob' }
  );
};
