import { GVWorkspace } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import { GetWorkspaceResponse } from './types';

export const gvWorkspaceApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'gvWorkspaceApi',
  endpoints: (build) => ({
    getWorkspace: build.query<GVWorkspace, {} | void>({
      transformResponse: (response: GetWorkspaceResponse) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.GET_GV_TALK_WORKSPACE,
        };
      },
    }),
  }),
});

export const { useGetWorkspaceQuery } = gvWorkspaceApi;
