import { Step } from 'react-joyride';
import { isDesktop } from '@gv/triage-components';

import { PagePermissions } from 'types';
import { WalkthroughStep, WalkthroughDataAttribute } from 'types/data';

import { target } from './config';

export const getTarget = (step: WalkthroughStep) =>
  `[${WalkthroughDataAttribute}="${step}"]`;

const commonStepProps = {
  placement: 'right',
};

export const getSteps = (
  permissions: PagePermissions,
  desktop: ReturnType<typeof isDesktop>
): Step[] => {
  const result = [
    {
      placement: 'center',
      target: target[WalkthroughStep.Intro],
    },
  ];

  if (permissions.hospital) {
    result.push(
      {
        ...commonStepProps,
        target: target[WalkthroughStep.HospitalProfile],
      },
      {
        ...commonStepProps,
        target: target[WalkthroughStep.Acceptance],
      }
    );
  }

  if (permissions.actionItems) {
    result.push({
      ...commonStepProps,
      target: target[WalkthroughStep.ActionItems],
    });
  }

  if (permissions.cases) {
    result.push({
      ...commonStepProps,
      target: target[WalkthroughStep.Cases],
    });
  }

  if (permissions.calendar) {
    result.push({
      ...commonStepProps,
      target: target[WalkthroughStep.Calendar],
    });
  }

  if (permissions.analytics) {
    result.push({
      ...commonStepProps,
      target: target[WalkthroughStep.Analytics],
    });
  }

  result.push(
    {
      placement: 'right-end',
      target: target[WalkthroughStep.HelpCenter],
    },
    {
      placement: desktop ? 'bottom' : 'right',
      target: target[WalkthroughStep.MyAccount],
    }
  );

  return result as Step[];
};
