import { Styles } from '@gv/triage-components';
import styled, { css } from 'styled-components';

const { bodySemibold } = Styles.Font;

export const Container = styled.div<{ isDarkTheme?: boolean }>`
  padding: 4px 16px;
  text-align: center;
  cursor: pointer;
  z-index: 51;

  span {
    ${bodySemibold};
  }

  ${({ theme, isDarkTheme }) => css`
    color: ${theme.config.text.white};
    background-color: ${isDarkTheme
      ? theme.colors.yellow.primary.hover
      : theme.colors.yellow.primary.standard};
  `}
`;

export const Email = styled.p`
  ${bodySemibold};

  span {
    margin: 0 8px;
  }
`;
