import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { PackageTemplate } from 'types';
import { generateUrl } from 'utils/helpers';

import {
  GetPackageRequest,
  DeletePackageRequest,
  CreatePackageRequest,
  UpdatePackageRequest,
} from './types';

const tagTypes = ['PackageTemplates'];

export const packageTemplatesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'packageTemplatesApi',
  endpoints: (build) => ({
    list: build.query<PackageTemplate[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_PACKAGES_LIST,
      }),
    }),

    delete: build.mutation<void, DeletePackageRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_PACKAGE, { id }),
      }),
    }),

    getPackage: build.query<PackageTemplate, GetPackageRequest>({
      providesTags: tagTypes,
      query: ({ id }) => ({
        method: 'get',
        url: generateUrl(URL.DELETE_PACKAGE, { id }),
      }),
    }),

    create: build.mutation<PackageTemplate, CreatePackageRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_PACKAGES_LIST,
      }),
    }),

    update: build.mutation<void, UpdatePackageRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.DELETE_PACKAGE, { id: data.id }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDeleteMutation,
  useCreateMutation,
  useUpdateMutation,
  useGetPackageQuery,
} = packageTemplatesApi;
