import { Formik } from 'formik';
import {
  Dialog,
  isMobile,
  DialogType,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { TaskFormField } from 'components';

import * as Styles from './styles';
import { InternalFormProps } from './types';
import { getInitialValues, validationSchema } from './config';

export const InternalForm = ({
  data,
  isEdit,
  trigger,
  onSubmit,
}: InternalFormProps) => {
  const mobile = isMobile();

  const title = `${isEdit ? 'Edit' : 'Create'} task`;

  return (
    <Dialog
      title={title}
      trigger={trigger}
      closeOnDocumentClick={false}
      width={mobile ? '90vw' : '704px'}
    >
      {(close, _, Buttons) => (
        <Formik
          validationSchema={validationSchema}
          initialValues={getInitialValues(data)}
          onSubmit={(values) => {
            onSubmit(values);
            close();
          }}
        >
          {({ dirty, values, isSubmitting, setFieldValue }) => (
            <Styles.Form>
              <TaskFormField
                showName
                showDueAt
                showAllAssignee
                values={values}
                setFieldValue={setFieldValue}
              />
              <Buttons type={DialogType.Plain}>
                <StandardButton
                  fullWidth
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.Secondary}
                />
                <StandardButton
                  fullWidth
                  text={title}
                  type={ButtonTypes.Submit}
                  disabled={isSubmitting || !dirty}
                />
              </Buttons>
            </Styles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
