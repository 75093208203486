import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';

import {
  OnCallProtocol,
  SimpleDaysUpdate,
  OnCallProtocolResponse,
  OnCallProtocolEditRequest,
} from './types';

const protocolAdapter = createEntityAdapter<OnCallProtocol, number>({
  selectId: (item) => item.hospitalInfo.id,
});

const transformResponse = (response: OnCallProtocolResponse) => response.data;

const tagTypes = ['OnCallProtocol'];

export const onCallProtocolApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'onCallProtocolApi',
  endpoints: (build) => ({
    setClinicHours: build.mutation<any, SimpleDaysUpdate>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.SET_SIMPLE_CLINIC_HOURS,
      }),
    }),
    onCallProtocol: build.query<OnCallProtocol, string>({
      transformResponse,
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.GET_ON_CALL_PROTOCOL.replace('{hospital_id}', id),
      }),
    }),
    update: build.mutation<OnCallProtocol, OnCallProtocolEditRequest>({
      transformResponse,
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.UPDATE_ON_CALL_PROTOCOL,
      }),
    }),
  }),
});

export const onCallProtocolSlice = createSlice({
  reducers: {},
  name: 'onCallProtocol',
  initialState: protocolAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      onCallProtocolApi.endpoints.onCallProtocol.matchFulfilled,
      (state, { payload }) => {
        protocolAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(
      onCallProtocolApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        protocolAdapter.updateOne(state, {
          changes: payload,
          id: protocolAdapter.selectId(payload),
        });
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      protocolAdapter.removeAll(state);
    });
  },
});

export const {
  useUpdateMutation,
  useOnCallProtocolQuery,
  useSetClinicHoursMutation,
} = onCallProtocolApi;
export type { OnCallProtocolEditRequest };
