import isEqual from 'lodash.isequal';
import { diff } from 'deep-object-diff';
import { retry, createApi } from '@reduxjs/toolkit/query/react';
import { QueueTab, ActionQueue, ActionQueueType } from '@gv/triage-components';
import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { TransferMergeFormValues } from 'types';
import { login, isLogoutAction } from 'store/slices/auth';
import { getUser, clearCaseOnCallPopupOpened } from 'utils/storage';
import {
  getQueueItemId,
  jsonSortedKeysReplacer,
  getActionCenterCaseForm,
} from 'utils/helpers';

import { booleanFormFields, fillSavedFormIfExist } from '../cases/thunks';

import { isSame, selectActionQueueFromStore } from './selectors';
import {
  AddTask,
  EndTask,
  UpdateTask,
  UpdateCaseForm,
  SetOnHoldAction,
  SMSBatchPayload,
  ActionQueueState,
  PetOwnerTemplate,
  TaskIsLoadingProps,
  ActionQueueResponse,
  MessageRecipientType,
  UpdateCallFlowFormPayload,
  SetClientInProgressUpdate,
} from './types';

const selectId = (item: ActionQueue) => getQueueItemId(item);

const actionQueueAdapter = createEntityAdapter<ActionQueue, string>({
  selectId,
});

const initialState: ActionQueueState = {
  ...actionQueueAdapter.getInitialState(),
  taskForms: {},
  currentTasks: [],
  loadingTasks: [],
  callFlowForms: {},
  isTaskSwitching: false,
  clientSetInProgress: [],
  receiverId: getUser()?.id,
};

const retryQuery = retry(apiQuery, {
  maxRetries: 5,
});

export const actionQueueApi = createApi({
  baseQuery: retryQuery,
  reducerPath: 'actionQueueApi',
  endpoints: (build) => ({
    list: build.query<ActionQueue[], void>({
      query: () => ({
        method: 'get',
        url: URL.GET_ACTION_QUEUE,
      }),
      transformResponse: (response: ActionQueueResponse) => {
        const result = response.data.data;
        return result;
      },
    }),
  }),
});

let oldQueue: ActionQueue[] = [];
let oldVisibleQueue: ActionQueue[] = [];

const logQueuePayload = (state: ActionQueueState, payload: ActionQueue[]) => {
  if (!isEqual(oldQueue, payload)) {
    let payloadData = JSON.parse(
      JSON.stringify(payload, jsonSortedKeysReplacer)
    );
    console.log('New Queue', {
      queue: payloadData,
      diff: diff(oldQueue, payloadData),
    });
    oldQueue = payloadData;
  }
};

const logQueueChanges = (state: ActionQueueState) => {
  let newVisible: ActionQueue[] = [];
  Object.values(QueueTab).forEach((tab) => {
    newVisible = newVisible.concat(
      selectActionQueueFromStore(state, tab).map((item) =>
        JSON.parse(JSON.stringify(item), jsonSortedKeysReplacer)
      )
    );
  });

  if (!isEqual(oldVisibleQueue, newVisible)) {
    console.log('New Local Queue', {
      queue: newVisible,
      diff: diff(oldVisibleQueue, newVisible),
      diffWithResponse: diff(oldQueue, newVisible),
    });
    oldVisibleQueue = newVisible;
  }
};

export const actionQueueSlice = createSlice({
  initialState,
  name: 'actionQueue',
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { user } = action.payload?.data;
      state.receiverId = user?.id;
    });
    builder.addMatcher(
      actionQueueApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        logQueuePayload(state, payload);

        actionQueueAdapter.setAll(state, payload);
        const { receiverId, currentTasks } = state;

        if (currentTasks.length) {
          state.currentTasks = currentTasks.map((currentTask) => {
            const update = payload.find((value) =>
              isSame(currentTask, getQueueItemId(value))
            );
            if (update) {
              return {
                ...currentTask,
                ...update,
                $storedLocally: false,
              };
            } else {
              const newTaskInfo = {
                ...currentTask,
                connected: false,
                initializing: false,
                $storedLocally: true,
                isConnectedByMe: false,
              };
              if (!newTaskInfo.$finished) {
                newTaskInfo.$finished = Date.now();
              }
              return newTaskInfo;
            }
          });
        }

        if (receiverId) {
          state.currentTasks.push(
            ...payload.filter(
              (task) =>
                task.type === ActionQueueType.SMS &&
                task.connected &&
                task.receiver_id.includes(receiverId) &&
                !state.currentTasks.find((value) =>
                  isSame(task, getQueueItemId(value))
                )
            )
          );
        }

        logQueueChanges(state);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      actionQueueAdapter.removeAll(state);
      logQueuePayload(state, []);
      logQueueChanges(state);
      state.currentTasks = [];
      state.currentTaskId = undefined;
      state.taskForms = {};
    });
  },
  reducers: {
    removeTransferCallForm: (state) => {
      state.transferCallForm = undefined;
    },
    removeTaskForResponse: (state) => {
      state.taskIdForResponse = undefined;
    },
    setCurrentTaskId: (state, { payload }) => {
      state.currentTaskId = payload;
    },
    setIsTaskSwitching: (state, { payload }: PayloadAction<boolean>) => {
      state.isTaskSwitching = payload;
    },
    addTransferCallForm: (
      state,
      { payload }: PayloadAction<TransferMergeFormValues>
    ) => {
      state.transferCallForm = payload;
    },
    updateCallFlowForm: (
      state,
      { payload }: PayloadAction<UpdateCallFlowFormPayload>
    ) => {
      const { task, form } = payload;
      const id = actionQueueAdapter.selectId(task);

      state.callFlowForms[id] = form;
    },
    setTaskIsLoading: (
      state,
      { payload }: PayloadAction<TaskIsLoadingProps>
    ) => {
      const { task, isLoading } = payload;
      const current = new Set(state.loadingTasks);
      const id = getQueueItemId(task);
      if (isLoading) {
        current.add(id);
      } else {
        current.delete(id);
      }
      state.loadingTasks = Array.from(current);
    },
    setClientInProgress: (
      state,
      {
        payload: { task, inProgress },
      }: PayloadAction<SetClientInProgressUpdate>
    ) => {
      const id = getQueueItemId(task);
      const set = new Set(state.clientSetInProgress);
      if (inProgress) {
        set.add(id);
      } else {
        set.delete(id);
      }
      state.clientSetInProgress = Array.from(set);
    },
    setTaskOnHold: (state, { payload }: PayloadAction<SetOnHoldAction>) => {
      const { currentTasks } = state;
      const index = currentTasks.findIndex((value) =>
        isSame(payload.task, getQueueItemId(value))
      );
      if (index >= 0) {
        state.currentTasks[index] = {
          ...state.currentTasks[index],
          $isOnHold: payload.isOnHold,
        };
        logQueueChanges(state);
      }
    },
    updateForm: (state, { payload }: PayloadAction<UpdateCaseForm>) => {
      const { task, form } = payload;
      const id = actionQueueAdapter.selectId(task);
      const filteredForm: Record<string, any> = {};
      Object.entries(form).forEach(([key, value]) => {
        if (value || booleanFormFields.includes(key)) {
          filteredForm[key] = value;
        }
      });
      console.log('update form', id);
      state.taskForms[id] = {
        ...state.taskForms[id],
        ...filteredForm,
      };
    },
    updateTask: (state, { payload }: PayloadAction<UpdateTask>) => {
      let index = -1;
      if ('caseId' in payload) {
        index = state.currentTasks.findIndex(
          (value) => value.case_id === payload.caseId
        );
      } else {
        index = state.currentTasks.findIndex((value) =>
          isSame(payload.changes as ActionQueue, getQueueItemId(value))
        );
      }
      if (index >= 0) {
        state.currentTasks[index] = {
          ...state.currentTasks[index],
          ...payload.changes,
        };
        logQueueChanges(state);
      }
    },
    addTask: (state, { payload }: PayloadAction<AddTask>) => {
      const { task, form } = payload;
      let { receiverId, currentTasks, taskIdForResponse } = state;
      const isVoice = task.type === ActionQueueType.Voice;
      const id = getQueueItemId(task);
      const index = currentTasks.findIndex((value) =>
        isSame(task, getQueueItemId(value))
      );
      console.log('add task', id, task.CSID);
      state.currentTaskId = id;
      state.isTaskSwitching = false;

      if (form) {
        state.taskForms[id] = fillSavedFormIfExist(form, id, receiverId);
      } else {
        const currentForm = state.taskForms[id];
        if (currentForm) {
          const loadedForm = fillSavedFormIfExist(
            state.taskForms[id],
            id,
            receiverId
          );
          state.taskForms[id] = loadedForm;
        }
      }
      if (!state.callFlowForms[id] && isVoice) {
        state.callFlowForms[id] = { opened: [], checked: {} };
      }

      if (index >= 0) {
        currentTasks[index] = {
          ...currentTasks[index],
          ...payload,
          $finished: undefined,
          $callEndedByUser: false,
        };
      } else {
        currentTasks.push(task);
      }
      state.currentTasks = state.currentTasks.map((currentTask) => {
        if (isSame(currentTask, state.currentTaskId)) {
          return {
            ...currentTask,
            $finished: undefined,
          };
        } else {
          return {
            ...currentTask,
            $finished: Date.now(),
          };
        }
      });
      logQueueChanges(state);
      if (id === taskIdForResponse) {
        state.taskIdForResponse = undefined;
      }
    },
    removeTask: (state, { payload }: PayloadAction<EndTask>) => {
      const { taskForms, currentTasks, callFlowForms, currentTaskId } = state;
      let task: ActionQueue | undefined = undefined;
      let index: number = -1;
      if ('task' in payload) {
        task = payload.task;
        index = currentTasks.findIndex((value) =>
          isSame(payload.task, getQueueItemId(value))
        );
      } else if ('caseId' in payload) {
        index = currentTasks.findIndex(
          (value) => value.case_id === payload.caseId
        );
        task = currentTasks[index];
      }
      if (!task) {
        return;
      }

      const id = actionQueueAdapter.selectId(task);
      const isVoice = task.type === ActionQueueType.Voice;
      let newTaskId: string | undefined;
      console.log('removeTask', id, `${index} of ${currentTasks.length}`);

      if (index >= 0) {
        const { user } = payload;
        currentTasks.splice(index, 1);
        if (currentTaskId && isSame(task, currentTaskId)) {
          if (currentTasks.length && user) {
            const firstTask = currentTasks[0];
            newTaskId = getQueueItemId(firstTask);
            state.currentTaskId = newTaskId;
            state.isTaskSwitching = true;

            if (!taskForms[newTaskId]) {
              state.taskForms[newTaskId] = getActionCenterCaseForm(
                firstTask,
                user
              );
            }
            if (!callFlowForms[newTaskId] && isVoice) {
              state.callFlowForms[newTaskId] = { opened: [], checked: {} };
            }
          } else {
            state.currentTaskId = undefined;
          }
        }
      }
      clearCaseOnCallPopupOpened(id);
      delete state.taskForms[id];
      delete state.callFlowForms[id];
      state.taskIdForResponse = state.currentTaskId;
    },
  },
});

export const { useListQuery, useLazyListQuery } = actionQueueApi;

const {
  actions: {
    addTask,
    removeTask,
    updateForm,
    updateTask,
    setTaskOnHold,
    setCurrentTaskId,
    setTaskIsLoading,
    setIsTaskSwitching,
    updateCallFlowForm,
    addTransferCallForm,
    setClientInProgress,
    removeTaskForResponse,
    removeTransferCallForm,
  },
} = actionQueueSlice;

export {
  addTask,
  updateForm,
  removeTask,
  updateTask,
  setTaskOnHold,
  setTaskIsLoading,
  PetOwnerTemplate,
  setCurrentTaskId,
  setIsTaskSwitching,
  updateCallFlowForm,
  addTransferCallForm,
  setClientInProgress,
  MessageRecipientType,
  removeTaskForResponse,
  removeTransferCallForm,
};

export type { SMSBatchPayload };
