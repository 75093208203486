import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 12px 16px 0 16px;
  justify-content: space-between;
`;

export const IDContainer = styled.div`
  display: flex;
`;

export const ID = styled.div`
  margin-right: 8px;
  color: ${({ theme }) => theme.config.table.head.title};
`;
