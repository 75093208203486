import { useCallback } from 'react';
import { Utils, Nullable, useNavigate } from '@gv/triage-components';

import { Routes } from 'config';
import { joinRoutes } from 'utils/helpers';

import { useTalkHome } from './useTalkHome';

const { Inbox } = Routes.Office;
const { Chat } = Inbox;
const { addParamsToUrl } = Utils.Query;

export const useChatNavigate = () => {
  const navigate = useNavigate();
  const home = useTalkHome();

  return useCallback(
    (id: Nullable<string | number>, query?: Record<string, any>) => {
      if (id) {
        const url = joinRoutes([home, Inbox.Home, Chat.Home, Chat.Details], {
          chatId: id,
        });
        navigate(query ? addParamsToUrl(url, query) : url);
      }
    },
    [navigate, home]
  );
};
