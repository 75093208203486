import { useMemo } from 'react';
import {
  Utils,
  Timer,
  Badge,
  isMobile,
  FlexLayout,
  OpenStatus,
  useNavigate,
  BadgePadding,
  OpenStatusBadge,
  BadgeBorderRadius,
  WorkingHoursUtils,
  OpenStatusTextHospital,
} from '@gv/triage-components';

import { CallTab } from 'types';
import { dotSymbol } from 'config';

import * as Styles from './styles';
import { HospitalTimeProps } from './types';

const { getData, getOpenStatus, getHospitalTime } = WorkingHoursUtils;

/**
 * This component displays the current time for a user and hospital and the hospital open status.
 */
export const HospitalTime = ({
  hospital,
  onCallProtocol,
  hideOpenStatus,
}: HospitalTimeProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const schedule = useMemo(() => {
    if (!hospital || !onCallProtocol || hideOpenStatus) {
      return undefined;
    }
    return getData(
      onCallProtocol.clinicHours,
      hospital.timezone?.time_zone_code
    ).items;
  }, [hospital, onCallProtocol, hideOpenStatus]);

  return (
    <div>
      <Timer tick={1000} started={1}>
        {() => {
          const { time, code, timezoneIsOk } = getHospitalTime(hospital);
          const formattedTime = time.format('LT');
          let clinicTime;
          if (timezoneIsOk) {
            clinicTime = `${formattedTime} (${code})`;
          } else {
            clinicTime = formattedTime;
          }
          const nurseTime = Utils.Date.formatDate(new Date(), 'LT (z)');
          const openStatus = hideOpenStatus
            ? undefined
            : getOpenStatus(time, schedule, OpenStatusTextHospital);
          return (
            <FlexLayout gap={8} flexDirection="column">
              <FlexLayout
                gap={4}
                className="caption-regular"
                flexWrap={mobile ? 'wrap' : 'nowrap'}
                justifyContent={mobile ? 'flex-end' : undefined}
              >
                <FlexLayout gap={4} alignItems="center">
                  <Styles.UserIcon />
                  <Styles.Time>{nurseTime}</Styles.Time>
                </FlexLayout>
                {!mobile && dotSymbol}
                <FlexLayout gap={4} alignItems="center">
                  <Styles.HospitalIcon />
                  <Styles.Time>{clinicTime}</Styles.Time>
                </FlexLayout>
              </FlexLayout>
              {openStatus && (
                <Styles.RightBadges>
                  <Badge
                    smallFont
                    text={openStatus.title}
                    padding={BadgePadding.Medium}
                    borderRadius={BadgeBorderRadius.Small}
                    styleType={OpenStatusBadge[openStatus.type as OpenStatus]}
                    onClick={() =>
                      navigate(CallTab.Hospital, {
                        state: { scrollToSchedule: true },
                      })
                    }
                  />
                </Styles.RightBadges>
              )}
            </FlexLayout>
          );
        }}
      </Timer>
    </div>
  );
};
