import styled, { css } from 'styled-components';
import { ListCard } from '@gv/triage-components';

export const Card = styled(ListCard)`
  border-radius: 0;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.config.sidebar.border};
    transition: ${theme.transition.default};
  `};
`;
