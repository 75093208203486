import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, BaseResponse } from '@gv/triage-components';

import { URL } from 'api/constants';
import { InternalTask } from 'types';
import { generateUrl } from 'utils/helpers';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import {
  ListRequest,
  UpdateRequest,
  CreateRequest,
  DeleteRequest,
  ResolveRequest,
  AssigneeResponse,
  InternalTasksData,
  ListPendingRequest,
  AssigneeListRequest,
  CreateCommentRequest,
  DeleteCommentRequest,
  UpdateCommentRequest,
  InternalPendingTasksData,
  InternalTaskCommentsData,
  InternalTasksFilterValues,
  InternalTasksFilterParams,
  InternalTasksFilterRequest,
  GetInternalTaskCommentsRequest,
} from './types';

const tagType = 'internalTasks';
const commentsTagType = 'comments';

export const internalTeamTasksApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'internalTeamTasksApi',
  tagTypes: [tagType, commentsTagType],
  endpoints: (build) => ({
    delete: build.mutation<void, DeleteRequest>({
      invalidatesTags: invalidatesList(tagType),
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.INTERNAL_TASK, { id }),
      }),
    }),

    resolve: build.mutation<void, ResolveRequest>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'patch',
        url: URL.INTERNAL_TASK_RESOLVE,
      }),
    }),

    create: build.mutation<void, CreateRequest>({
      invalidatesTags: (result, error) =>
        error ? [] : invalidatesList(tagType)(),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.INTERNAL_TASKS,
      }),
    }),

    createComment: build.mutation<void, CreateCommentRequest>({
      invalidatesTags: [commentsTagType],
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.INTERNAL_TASK_C0MMENTS, { id }),
      }),
    }),

    deleteComment: build.mutation<void, DeleteCommentRequest>({
      invalidatesTags: [commentsTagType],
      query: ({ id, commentId }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_INTERNAL_TASK_C0MMENT, { id, commentId }),
      }),
    }),

    updateComment: build.mutation<void, UpdateCommentRequest>({
      invalidatesTags: [commentsTagType],
      query: ({ id, commentId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_INTERNAL_TASK_C0MMENT, { id, commentId }),
      }),
    }),

    get: build.query<InternalTask, string>({
      providesTags: cacheByIdArg(tagType),
      transformResponse: (response: BaseResponse<InternalTask>) =>
        response.data,
      query: (id) => ({
        method: 'get',
        url: generateUrl(URL.INTERNAL_TASK, { id }),
      }),
    }),

    update: build.mutation<void, UpdateRequest>({
      invalidatesTags: (result, error, arg) =>
        error ? [] : cacheByIdArgProperty(tagType)(result, error, arg),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.INTERNAL_TASK, { id }),
      }),
    }),

    assignee: build.query<AssigneeResponse, AssigneeListRequest>({
      transformResponse: (response: BaseResponse<AssigneeResponse>) =>
        response.data,
      query: ({ search, page = 1, limit = 20 }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(URL.INTERNAL_TASKS_ASSIGNEE, {
          page,
          limit,
          search,
        }),
      }),
    }),

    getComments: build.query<
      InternalTaskCommentsData,
      GetInternalTaskCommentsRequest
    >({
      providesTags: [commentsTagType],
      transformResponse: (response: BaseResponse<InternalTaskCommentsData>) =>
        response.data,
      query: ({ id, page = 1, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(
            generateUrl(URL.INTERNAL_TASK_C0MMENTS, { id }),
            { limit, offset }
          ),
        };
      },
    }),

    pendingList: build.query<InternalPendingTasksData, ListPendingRequest>({
      providesTags: [tagType],
      transformResponse: (response: BaseResponse<InternalPendingTasksData>) =>
        response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);

        const searchParams = {
          limit,
          search,
          offset,
        };

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(
            URL.INTERNAL_TASK_PENDING,
            searchParams
          ),
        };
      },
    }),

    list: build.query<InternalTasksData, ListRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: BaseResponse<InternalTasksData>) =>
        response.data,
      query: ({ search, filters, page = 1, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);

        const {
          dueAt,
          statuses,
          authorId,
          createdAt,
          priorities,
          isResolved,
          assigneeId,
          ...rest
        } = filters;

        const resultFilters: InternalTasksFilterRequest = rest;

        if (authorId) {
          resultFilters.authorId = Number(authorId);
        }

        if (assigneeId || assigneeId === null) {
          resultFilters.assigneeId = +assigneeId || null;
        }

        if (isResolved) {
          resultFilters.isResolved = isResolved;
        }

        if (statuses?.length) {
          resultFilters.statuses = statuses;
        }

        if (priorities?.length) {
          resultFilters.priorities = priorities;
        }

        if (createdAt.from || createdAt.to) {
          resultFilters.createdAt = {
            ...(createdAt.to && { to: createdAt.to }),
            ...(createdAt.from && { from: createdAt.from }),
          };
        }

        if (dueAt.from || dueAt.to) {
          resultFilters.dueAt = {
            ...(dueAt.to && { to: dueAt.to }),
            ...(dueAt.from && { from: dueAt.from }),
          };
        }

        const searchParams = {
          limit,
          search,
          offset,
          filters: JSON.stringify(resultFilters),
        };

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(URL.INTERNAL_TASKS, searchParams),
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useAssigneeQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useResolveMutation,
  usePendingListQuery,
  useGetCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} = internalTeamTasksApi;

export type {
  CreateRequest,
  UpdateRequest,
  InternalTasksFilterValues,
  InternalTasksFilterParams,
};
