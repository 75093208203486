import {
  Utils,
  Hospital,
  UserRole,
  ERSubService,
  CaseFormHospitalReferral,
} from '@gv/triage-components';

import { caseFormModel } from '../config';
import {
  petToString,
  getPriceRange,
  getCaseFormSelectedPets,
} from '../helpers';

import { TextOutReferral, GetTextOutTemplate } from './types';

const { joinStrings } = Utils.Helpers;

const join = (array: (string | undefined)[]) => joinStrings(array, '\n');

const noReply = 'Please note this inbox is not monitored.';

export const prepareTextOutReferral =
  (hospital?: Hospital) =>
  (referral: CaseFormHospitalReferral): TextOutReferral => {
    const { state, address, zip_code, city_name } = referral || {};

    const fullAddress = Utils.Helpers.joinStrings(
      [address, city_name, state?.name, zip_code],
      ', ',
      ''
    );

    return {
      ...referral,
      address: fullAddress,
      distance:
        'distance' in referral && referral.distance && hospital
          ? `${referral.distance} minutes from clinic ${hospital.name || ''}`
          : undefined,
    };
  };

export const prepareTextOutService = (service?: ERSubService) =>
  service && {
    ...service,
    priceRange: getPriceRange(service),
  };

export const getERTemplate: GetTextOutTemplate = ({
  notes,
  client,
  referrals,
}) => {
  if (!referrals?.length) {
    return '';
  }
  let clinics = referrals?.map((referral) => {
    const { name, phone, address, distance } = referral || {};

    const nameRow = name ? `Hospital name: ${name}` : '';
    const addressRow = address ? `Address: ${address}` : '';
    const phoneRow = phone ? `Phone: ${phone}` : '';
    const distanceRow = distance ? `Within: ${distance}` : '';

    return join([nameRow, addressRow, phoneRow, distanceRow, '\n']);
  });

  const notesRow = notes ? `Other notes: ${notes}\n` : '';

  return join([
    client ? `Hello ${client.name}.\n` : '',
    'Per our conversation, you have been referred to the emergency room. The hospital details are provided below.\n',
    ...clinics,
    notesRow,
    noReply,
  ]);
};

export const getDVMTemplate: GetTextOutTemplate = ({
  dvm,
  form,
  notes,
  client,
  teamMember,
}) => {
  const {
    pets,
    pet_id,
    cvt_plan,
    problem_summary,
    assessment_note,
    chief_complaints,
  } = form || {};
  const { name, phone } = client || {};

  const clientPets = getCaseFormSelectedPets(client?.pets, pets, pet_id);

  const petsInfo = clientPets
    ? clientPets.map(
        (pet, index) =>
          `Pet${clientPets.length > 1 ? ` ${index + 1}` : ''}: ${petToString(pet)}`
      )
    : [];

  return join([
    dvm ? `Hi, Dr. ${dvm.name}.\n` : '',
    teamMember
      ? `This is ${teamMember.name}, ${
          UserRole[teamMember.roles[0]]
        } with GuardianVets. \n`
      : '',
    'We are contacting you about a patient emergency. The full case details are provided below.\n',
    name ? `Caller name: ${name}` : '',
    phone ? `Caller phone number: ${phone}` : '',
    ...petsInfo,
    chief_complaints.length > 0
      ? `Chief complaint: ${chief_complaints.join(', ')}`
      : '',
    problem_summary
      ? `${caseFormModel.problemSummary.label}: ${problem_summary}`
      : '',
    assessment_note
      ? `${caseFormModel.assessmentNote.label}: ${assessment_note}`
      : '',
    cvt_plan ? `${caseFormModel.cvtPlan.label}: ${cvt_plan}` : '',
    notes ? `Other notes: ${notes}` : '',
    '\nPlease do not reply to this message directly as this inbox is not regularly monitored. Should you have any questions, please feel free to call our backline at: 716-309-5512.',
  ]);
};

export const getHotlineInfoTemplate: GetTextOutTemplate = ({
  notes,
  client,
  subService,
}) => {
  const { name, phone, priceRange } = subService || {};

  return join([
    client ? `Hello ${client.name}.\n` : '',
    'Per our conversation, you have been referred to poison control. Details are provided below:\n',
    name ? `Service name: ${name}` : '',
    phone ? `Phone: ${phone}` : '',
    `Price range: ${priceRange}`,
    notes ? `Other notes: ${notes}` : '',
    '\nPlease remember to write down and bring the case number if a visit to the ER is recommended.\n',
    noReply,
  ]);
};

export const getCustomTemplate: GetTextOutTemplate = ({ notes }) =>
  join([`${notes}\n`, noReply]);

export const getPaymentLinksTemplate: GetTextOutTemplate = ({
  notes,
  paymentLinks,
}) => {
  const links = join(paymentLinks ?? []);
  return join([
    `Hi! There are payment links provided below:\n`,
    `${links}\n`,
    `${notes}\n`,
    noReply,
  ]);
};
