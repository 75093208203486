import { useMemo } from 'react';

import { ExpandableList } from 'components/cases';

import { Notes } from './notes';
import { CaseActionsProps } from './types';
import { CallOutcome } from './call-outcome';

export const CaseActions = ({
  csr_plan,
  cvt_plan,
  timezone,
  client_id,
  hospital_id,
  callOutcome,
  call_outcome,
  follow_up_steps,
  assessment_note,
  additional_notes,
  triage_recommendation,
}: CaseActionsProps): JSX.Element => {
  const lists = useMemo(() => {
    const result = [];

    if (triage_recommendation) {
      result.push({
        value: triage_recommendation,
        label: 'Triage recommendations',
      });
    }

    if (callOutcome && call_outcome) {
      result.push({
        value: call_outcome,
        label: 'Call outcome',
      });
    }

    if (follow_up_steps && follow_up_steps?.length > 0) {
      result.push({ value: follow_up_steps, label: 'Follow up steps' });
    }

    return result;
  }, [call_outcome, follow_up_steps, triage_recommendation, callOutcome]);

  return (
    <>
      {lists.map((list, index) => (
        <ExpandableList
          defaultOpened
          label={list.label}
          value={list.value}
          key={`${list.label}-${index.toString()}`}
        />
      ))}

      {csr_plan && <Notes type="CSR" plan={csr_plan} />}

      {cvt_plan && (
        <Notes type="CVT" plan={cvt_plan} assessment={assessment_note} />
      )}

      <CallOutcome
        asSection
        data={callOutcome}
        timezone={timezone}
        clientId={client_id}
        hospitalId={String(hospital_id)}
        additionalNotes={additional_notes}
      />
    </>
  );
};
