import { VideoQuality, event_video_active_change } from '@zoom/videosdk';
import { useRef, useMemo, useState, useEffect, useContext } from 'react';

import { Participant } from 'context/video-comm/types';
import { convertAudio, isShallowEqual } from 'utils/helpers';
import { MediaContext, AvatarActionContext } from 'context/video-comm';

import * as Styles from './styles';
import { SELF_VIDEO_ID } from './constants';
import {
  usePrevious,
  useAvatarAction,
  useNetworkQuality,
  useCanvasDimension,
  useParticipantsChange,
} from './hooks';

export const VideoSingle = () => {
  const { media, zoomClient } = useContext(MediaContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = media;
  const { videoRef, canvasDimension } = useCanvasDimension(mediaStream);

  const [participants, setParticipants] = useState<Participant[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(
    mediaStream?.getActiveVideoId() ?? 0
  );
  const previousActiveUser = useRef<Participant>();

  const networkQuality = useNetworkQuality(zoomClient);
  const previousCanvasDimension = usePrevious(canvasDimension);

  useParticipantsChange(zoomClient, (payload) => {
    setParticipants(payload);
  });

  useEffect(() => {
    const onActiveVideoChange: typeof event_video_active_change = (payload) => {
      const { userId } = payload;
      setActiveVideo(userId);
    };

    zoomClient.on('video-active-change', onActiveVideoChange);
    return () => {
      zoomClient.off('video-active-change', onActiveVideoChange);
    };
  }, [zoomClient]);

  const activeUser = useMemo(() => {
    const participant = participants.find(
      (user) => user.userId === activeVideo
    );
    return participant
      ? { ...participant, audio: convertAudio(participant.audio) }
      : undefined;
  }, [participants, activeVideo]);

  const isCurrentUserStartedVideo = zoomClient.getCurrentUserInfo()?.bVideoOn;

  useEffect(() => {
    if (mediaStream && videoRef.current && isVideoDecodeReady) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        if (activeUser?.bVideoOn) {
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P
          );
        } else {
          if (previousActiveUser.current?.bVideoOn) {
            mediaStream.stopRenderVideo(
              videoRef.current,
              previousActiveUser.current?.userId
            );
          }
        }
      }
      if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
        if (activeUser.userId !== previousActiveUser.current.userId) {
          mediaStream.stopRenderVideo(
            videoRef.current,
            previousActiveUser.current?.userId
          );
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P
          );
        } else {
          if (!isShallowEqual(canvasDimension, previousCanvasDimension)) {
            mediaStream.adjustRenderedVideoPosition(
              videoRef.current,
              activeUser.userId,
              canvasDimension.width,
              canvasDimension.height,
              0,
              0
            );
          }
        }
      }
      previousActiveUser.current = activeUser;
    }
  }, [
    mediaStream,
    activeUser,
    isVideoDecodeReady,
    canvasDimension,
    previousCanvasDimension,
  ]);

  const avatarParticipants = useMemo(
    () => (activeUser ? [activeUser] : []),
    [activeUser]
  );

  const avatarActionState = useAvatarAction(zoomClient, avatarParticipants);

  const selfVideoOptions = {
    show: isCurrentUserStartedVideo,
    single: participants.length === 1,
  };

  return (
    <Styles.Viewport>
      <Styles.VideoContainerSingle>
        <Styles.SingleVideoWrap>
          <Styles.VideoCanvas
            width="800"
            height="600"
            ref={videoRef}
            id="video-canvas"
          />

          <AvatarActionContext.Provider value={avatarActionState}>
            {activeUser && (
              <Styles.SingleViewAvatar
                participant={activeUser}
                networkQuality={networkQuality[`${activeUser.userId}`]}
              />
            )}
          </AvatarActionContext.Provider>
        </Styles.SingleVideoWrap>

        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
          <Styles.SelfVideo id={SELF_VIDEO_ID} {...selfVideoOptions} />
        ) : (
          <Styles.SelfVideoCanvas
            width="254"
            height="143"
            id={SELF_VIDEO_ID}
            {...selfVideoOptions}
          />
        )}
      </Styles.VideoContainerSingle>
    </Styles.Viewport>
  );
};
