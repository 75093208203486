import styled, { css } from 'styled-components';
import {
  Icon,
  Styles,
  FlexLayout,
  Col as TCCol,
  StandardButton,
} from '@gv/triage-components';

export const Container = styled(FlexLayout)`
  position: relative;
  flex-direction: column;
  min-width: 0;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.config.infoBlock.shadow};
    background-color: ${theme.config.background};

    padding: 16px 56px ${theme.responsive.isDesktop ? 6 : 0}px 16px;

    ${theme.responsive.isDesktop &&
    css`
      top: 0;
      z-index: 5;
      position: sticky;
    `}
  `}
`;

export const Info = styled(FlexLayout)`
  row-gap: 8px;
  flex-wrap: wrap;
  column-gap: 48px;
  align-items: flex-end;
`;

export const CheckIcon = styled(Icon.CheckFilled)`
  path {
    fill: ${({ theme }) => theme.colors.green.primary.standard};
  }
`;

export const Title = styled.span`
  ${Styles.Font.captionRegular};
  color: ${({ theme }) => theme.config.input.placeholder};
`;

export const CopyButton = styled(StandardButton)`
  top: 8px;
  right: 8px;
  position: absolute;
`;

export const Col = styled(TCCol)`
  min-width: 0;
  display: flex;
  align-items: flex-end;

  ${({ theme }) => css`
    ${theme.responsive.isDesktop &&
    css`
      margin-bottom: 10px;
      align-items: flex-end;
    `}

    ${!theme.responsive.isDesktop &&
    css`
      margin-bottom: 16px;
    `}
  `}
`;
