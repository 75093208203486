import * as Yup from 'yup';
import { Pet, Utils } from '@gv/triage-components';
import { FormikErrors, FormikValues } from 'formik';

import { PetSpecies } from 'types';
import { removeFields } from 'utils/helpers';
import {
  BasePetRequest,
  PetsCreateRequest,
  PetsUpdateRequest,
} from 'store/api/clients';

import { getAge, getDateOfBirth } from '../helpers';

import {
  GetCreatePayloadProps,
  GetUpdatePayloadProps,
  GetSelectOptionsProps,
} from './types';

export const initialValues = {
  name: '',
  days: '',
  breed: '',
  years: '',
  gender: '',
  months: '',
  otherBreed: '',
  species: undefined,
};

const {
  Object: { filterEmptyFields },
  Helpers: { sortSelectOptions },
} = Utils;

export const getInitialSelectOption = (
  species: PetSpecies[],
  value: string
) => {
  if (!species?.length || !value) {
    return;
  }

  const specie = species.find(
    (option) =>
      option.name === value || option.name.split('(')[0].trim() === value
  );
  if (specie) {
    return { label: specie.name, value: String(specie.id) };
  }
};

export const getInitialValues = (data: Pet[], petId: string | undefined) => {
  if (!data?.length || !petId) {
    return initialValues;
  }

  const pet = data.find((item) => String(item.id) === petId);
  const [years, months, days] = getAge(pet?.date_of_birth);
  return {
    otherBreed: '',
    name: pet?.name,
    days: days || '',
    breed: pet?.breed,
    years: years || '',
    gender: pet?.gender,
    months: months || '',
    species: pet?.species,
  };
};

export const getSelectOptions = ({
  values,
  initialValue,
  otherOption = false,
}: GetSelectOptionsProps) => {
  if (!values?.length) {
    return [];
  }
  const other = { label: 'Other', value: 'Other' };
  const initial = { label: String(initialValue), value: String(initialValue) };
  const result = values.find((item) => item?.name === initialValue);

  const options = sortSelectOptions(
    values.map((value) => ({
      label: value.name,
      value: value.name,
    }))
  );

  if (result || !initialValue) {
    return otherOption ? [other, ...options] : options;
  }

  return otherOption ? [other, initial, ...options] : [initial, ...options];
};

export const validateValue = Yup.string().test(function (value) {
  if (this.parent?.breed === 'Other' && !value) {
    return false;
  }
  return true;
});

export const ageValidate = (values: FormikValues) => {
  const daysError = 'Max 31 days';
  const monthsError = 'Max 11 months';
  // const globalError = 'At least one of the age fields is required';

  const { days, years, months } = values;
  const errors: FormikErrors<FormikValues> = {};

  if (!years && !months && !days) {
    // errors.years = globalError;
    // errors.months = globalError;
    // errors.days = globalError;
  } else {
    if (Number(months) > 11) {
      errors.months = monthsError;
    }
    if (Number(days) > 31) {
      errors.days = daysError;
    }
  }
  return errors;
};

export const petFieldsValidation = {
  otherBreed: validateValue,
  species: Yup.string().required(),
  name: Yup.string().trim().required(),
};

export const validationSchema = Yup.object().shape({
  ...petFieldsValidation,
});

export const getPetPayload = (values: FormikValues) =>
  filterEmptyFields({
    name: values.name,
    color: values?.color,
    gender: values.gender,
    species: values.species,
    breed:
      values.breed === 'Other' && values.otherBreed
        ? values.otherBreed
        : values.breed,
    date_of_birth:
      values.days || values.years || values.months
        ? getDateOfBirth({
            days: values.days,
            years: values.years,
            months: values.months,
          })
        : undefined,
  });

export const getBasePayload = (
  values: FormikValues,
  removeEmptyFields?: boolean
): BasePetRequest => {
  const filteredPayload = removeFields<FormikValues>(values, [
    'days',
    'year',
    'years',
    'months',
    'otherBreed',
  ]);

  const payload = { ...filteredPayload, ...getPetPayload(values) };
  return removeEmptyFields ? filterEmptyFields(payload) : payload;
};

export const getCreatePayload = ({
  values,
  clientId,
}: GetCreatePayloadProps): PetsCreateRequest => ({
  ...getBasePayload(values, true),
  client_id: Number(clientId),
});

export const getUpdatePayload = ({
  petId,
  values,
  clientId,
}: GetUpdatePayloadProps): PetsUpdateRequest => ({
  ...getCreatePayload({ values, clientId }),
  pet_id: Number(petId),
});
