import { useState, useEffect, useCallback } from 'react';

import { ZoomClient } from 'context/video-comm/types';

import { Dimension } from '../types';
import { maxViewportVideoCounts } from '../video-layout-helper';

const MAX_NUMBER_PER_PAGE = 9;

export const usePagination = (zoomClient: ZoomClient, dimension: Dimension) => {
  const [page, setPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [pageSize, setPageSize] = useState(MAX_NUMBER_PER_PAGE);

  useEffect(() => {
    const size = Math.min(
      MAX_NUMBER_PER_PAGE,
      maxViewportVideoCounts(dimension.width, dimension.height)
    );
    setPageSize(size);
  }, [dimension]);

  const onParticipantsChange = useCallback(() => {
    setTotalSize(zoomClient.getAllUser().length);
  }, [zoomClient]);

  useEffect(() => {
    zoomClient.on('user-added', onParticipantsChange);
    zoomClient.on('user-removed', onParticipantsChange);
    zoomClient.on('user-updated', onParticipantsChange);
    return () => {
      zoomClient.off('user-added', onParticipantsChange);
      zoomClient.off('user-removed', onParticipantsChange);
      zoomClient.off('user-updated', onParticipantsChange);
    };
  }, [zoomClient, onParticipantsChange]);

  useEffect(() => {
    setTotalSize(zoomClient.getAllUser().length);
  }, []);

  return {
    page,
    setPage,
    pageSize,
    totalSize,
    totalPage: Math.ceil(totalSize / pageSize),
  };
};
