import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { ExploreUser } from 'types';
import { mockExploreApiQuery } from 'store/mockQuery';

import { ListData, ListResponse, ExploreListPayload } from './types';

const exploreAdapter = createEntityAdapter<ExploreUser, number>({
  selectId: (item) => item.id,
});

export const exploreApi = createApi({
  reducerPath: 'exploreApi',
  baseQuery: mockExploreApiQuery,
  endpoints: (build) => ({
    list: build.query<ListData, ExploreListPayload>({
      transformResponse: (response: ListResponse) => response.data,
      query: (data) => {
        return {
          data,
          method: 'get',
          url: '/users',
        };
      },
    }),
  }),
});

export const exploreSlice = createSlice({
  reducers: {},
  name: 'explore',
  initialState: exploreAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      exploreApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        exploreAdapter.setAll(state, payload.users);
      }
    );
  },
});

export const { useListQuery } = exploreApi;
