import { use100vh } from 'react-div-100vh';
import { ChildrenProps } from '@gv/triage-components';

import * as Styles from './styles';

export const AppContainer = ({ children }: ChildrenProps) => {
  const height = use100vh();
  return (
    <Styles.Wrap>
      <Styles.Container style={{ height: height ?? '100vh' }}>
        {children}
      </Styles.Container>
    </Styles.Wrap>
  );
};
