import { useRef } from 'react';
import { Formik } from 'formik';
import {
  Row,
  Col,
  Utils,
  Dialog,
  useAlert,
  isDesktop,
  InputField,
  DialogType,
  OfficeChat,
  ButtonColors,
  CheckboxField,
  StandardButton,
} from '@gv/triage-components';

import { useAppDispatch } from 'store';
import { useProfileQuery } from 'store/api/clients';
import { useCreateMutation } from 'store/api/gv-chats';
import { sendSMS } from 'store/api/action-queue/thunks';
import { useCreateMessageMutation } from 'store/api/gv-messages';
import { useChatNavigate, useHospitalData, useDefaultFolder } from 'hooks';

import * as Styles from './styles';
import { initialValues, validationSchema } from './config';
import { FormValues, SendMessagePODialogProps } from './types';

export const SendMessagePODialog = ({
  task,
  onClose,
  isOpened,
}: SendMessagePODialogProps) => {
  const desktop = isDesktop();

  const { hospital } = useHospitalData();

  const client = task.client!;

  const isChatCreationPossible =
    !!hospital?.has_pet_owner_portal && hospital?.id === task.hospital?.id;

  const { defaultFolderId, error: defaultFolderError } = useDefaultFolder({
    skip: !isChatCreationPossible,
  });
  const { data: profileData, error: profileError } = useProfileQuery(
    client.id,
    { skip: !isChatCreationPossible }
  );

  const { handleError, showErrorAlert, showSuccessAlert } = useAlert();
  const chatNavigate = useChatNavigate();
  const dispatch = useAppDispatch();

  const frontDeskChat = useRef<OfficeChat>();

  const [createChat] = useCreateMutation();
  const [createMessage] = useCreateMessageMutation();

  const onDialogClose = () => {
    frontDeskChat.current = undefined;
    onClose();
  };

  const onSubmit = async (values: FormValues, close: () => void) => {
    const { message, shouldCreateChat } = values;
    if (shouldCreateChat) {
      if (!defaultFolderId) {
        showErrorAlert(Utils.Error.getErrorMessage(defaultFolderError));
        return;
      } else if (!profileData) {
        showErrorAlert(Utils.Error.getErrorMessage(profileError));
        return;
      }

      try {
        if (!frontDeskChat.current) {
          const newChat = await createChat({
            taskId: task.id,
            folderId: defaultFolderId,
            members: [{ profileId: profileData.id }],
          }).unwrap();
          frontDeskChat.current = newChat;
        }

        await createMessage({
          text: message,
          chatId: frontDeskChat.current!.id,
        }).unwrap();

        close();

        chatNavigate(frontDeskChat.current!.id);
      } catch (error) {
        handleError(error);
      }
    } else {
      const defaultPhoneNumber = client.last_login_phone || client.phone;
      const phones = defaultPhoneNumber
        ? [defaultPhoneNumber]
        : (client.phones ?? []);
      if (!phones.length) {
        showErrorAlert("The client don't have an phone number");
        return;
      }
      const { failed, successful } = await dispatch(
        sendSMS({
          phones,
          message,
        })
      ).unwrap();
      if (failed) {
        showErrorAlert(`Text failed to sent to:\n${failed}`);
      } else {
        if (successful) {
          showSuccessAlert(`Text successfully sent to:\n${successful}`);
        }
        close();
      }
    }
  };

  return (
    <Dialog
      open={isOpened}
      title="Send message"
      onClose={onDialogClose}
      closeOnDocumentClick={false}
      width={desktop ? '480px' : '90vw'}
    >
      {(close, _, Buttons) => (
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: FormValues) => onSubmit(values, close)}
        >
          {({ dirty, submitForm, isSubmitting }) => (
            <Styles.Form>
              <Row>
                <Col>
                  <InputField
                    label="Notes"
                    name="message"
                    type="textarea"
                    placeholder="Add notes"
                  />
                </Col>
              </Row>

              {isChatCreationPossible && (
                <Row>
                  <Col>
                    <CheckboxField
                      name="shouldCreateChat"
                      label="Create new chat in GV-Talk"
                    />
                  </Col>
                </Row>
              )}

              <Buttons type={DialogType.Plain}>
                <StandardButton
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.Secondary}
                />
                <StandardButton
                  text="Send"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                />
              </Buttons>
            </Styles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
