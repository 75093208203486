import {
  Icon,
  withRef,
  isMobile,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { NewEntityButtonProps } from './types';

export const NewEntityButton = ({
  path,
  text,
  ...props
}: NewEntityButtonProps) => {
  const mobile = isMobile();

  return (
    <StandardButton
      text={mobile ? undefined : text}
      colorType={ButtonColors.Secondary}
      icon={mobile ? <Icon.Plus /> : undefined}
      to={path ? location.pathname + `/${path}` : undefined}
      {...props}
    />
  );
};

export const NewEntityButtonWithRef = withRef(NewEntityButton);
