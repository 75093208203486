import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const onCallProtocolStore = (store: RootState) => store?.onCallProtocol;

export const selectOnCallProtocol = (id?: string) =>
  createSelector([onCallProtocolStore], (state) =>
    id ? state?.entities[Number(id)] : undefined
  );
