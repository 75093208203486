import { FlexLayout } from '@gv/triage-components';

import { TimerBadge } from '../timer-badge';
import { ReportButton } from '../report-button';

import * as Styles from './styles';

export const Statuses = () => (
  <Styles.Container>
    <TimerBadge />
    <FlexLayout gap={16}>
      <ReportButton />
    </FlexLayout>
  </Styles.Container>
);
