import { FormikValues, useFormikContext } from 'formik';
import {
  Icon,
  FlexLayout,
  DeleteButton,
  FileDropzoneField,
} from '@gv/triage-components';

import { AudioPlayer } from 'components/recording/audio-player';

import * as Styles from './styles';

export const AudioDropzone = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  return values?.upload?.src ? (
    <Styles.UploadedFile>
      <AudioPlayer value={values.upload}>
        <FlexLayout gap={16} flexDirection="column">
          <FlexLayout alignItems="flex-start" justifyContent="space-between">
            <FlexLayout gap={8} alignItems="flex-start">
              <AudioPlayer.Play />
              <AudioPlayer.Pause />
              <AudioPlayer.Info />
            </FlexLayout>

            <DeleteButton onClick={() => setFieldValue('upload', null)} />
          </FlexLayout>

          <AudioPlayer.Time />
        </FlexLayout>
      </AudioPlayer>
    </Styles.UploadedFile>
  ) : (
    <FileDropzoneField name="upload" accept={{ 'audio/mp3': ['.mp3'] }}>
      <Styles.Dropzone>
        <Icon.Upload />
        <p>
          Drop your audio here or <span className="semibold">browse</span>
        </p>
      </Styles.Dropzone>
    </FileDropzoneField>
  );
};
