import { useMemo } from 'react';
import { Row, Col, ClientForm, ClientFormProps } from '@gv/triage-components';

import { clientAddress, getClientEmails } from 'utils/helpers';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';

const initialProps = {
  name: '',
  address: '',
  emails: [] as string[],
};

export const ClientInfo = ({
  clientId,
  hospitalId,
}: {
  clientId?: string;
  hospitalId?: string;
}) => {
  const { data: client } = useClientQuery(
    { hospitalId, id: clientId!, allowDeleted: true },
    {
      skip: !clientId,
    }
  );

  const props = useMemo<Partial<ClientFormProps>>(() => {
    if (client) {
      return {
        name: client.name,
        phones: client.phones,
        mainPhone: client.phone,
        homePhones: client.home_phones,
        emails: getClientEmails(client),
        address: clientAddress(client) ?? undefined,
      };
    }

    return initialProps;
  }, [client]);

  return (
    <Row>
      <Col>
        <ClientForm
          isNameConfirmed
          isPhoneConfirmed
          withPlaceholders={false}
          {...props}
        />
      </Col>
    </Row>
  );
};
