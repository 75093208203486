import { AppTheme, BadgeColor } from '@gv/triage-components';

import { WhiteboardConfigTitle, WhiteboardConfigTemplate } from 'types/data';
import {
  TaskType,
  TaskClass,
  TaskStatus,
  AppointmentDate,
  ExtendedTaskClass,
  HospitalAppointment,
  StripeInvoiceStatus,
  WhiteboardConfigKey,
  AppointmentTaskStatus,
} from 'types';

import { GetTitlesAndTemplateProps } from './types';

export const getInvoiceStatusColor = (
  theme: AppTheme,
  status?: StripeInvoiceStatus
) => {
  if (!status) {
    return theme.config.userInfo.text.gvSubtitle;
  }

  const colors = {
    [StripeInvoiceStatus.Void]: theme.colors.red.primary.standard,
    [StripeInvoiceStatus.Open]: theme.colors.yellow.primary.standard,
    [StripeInvoiceStatus.Refunded]: theme.colors.red.primary.standard,
    [StripeInvoiceStatus.Paid]: theme.colors.cerulean.primary.standard,
    [StripeInvoiceStatus.Draft]: theme.colors.cerulean.primary.standard,
    [StripeInvoiceStatus.Uncollectible]: theme.config.userInfo.text.gvSubtitle,
    [StripeInvoiceStatus.PartiallyRefunded]: theme.colors.red.primary.standard,
  };

  return colors[status];
};

const commonStatusText = {
  [TaskStatus.Review]: 'For review',
  [TaskStatus.Cancelled]: 'Canceled',
  [TaskStatus.Confirmed]: 'Confirmed',
  [TaskStatus.Reviewing]: 'In progress',
};

export const statusText = {
  [ExtendedTaskClass.Appointment]: commonStatusText,
  [ExtendedTaskClass.VirtualExam]: commonStatusText,
  [ExtendedTaskClass.Administrative]: commonStatusText,
  [ExtendedTaskClass.TransferToBackline]: commonStatusText,
  [ExtendedTaskClass.VirtualExamWithoutPayment]: commonStatusText,
  [ExtendedTaskClass.AppointmentWithoutPayment]: commonStatusText,
  [ExtendedTaskClass.Emergency]: {
    ...commonStatusText,
    [TaskStatus.Review]: 'On the way',
    [TaskStatus.Confirmed]: 'Arrived',
  },
};

export const statusColor = {
  [TaskStatus.Cancelled]: BadgeColor.Red,
  [TaskStatus.Confirmed]: BadgeColor.Green,
  [TaskStatus.Reviewing]: BadgeColor.TertiaryOrange,
  [TaskStatus.Review]: BadgeColor.SecondaryPigmentIndigo,
};

export const getStatusOptions = (
  taskClass: TaskClass,
  currentStatus?: TaskStatus,
  type?: TaskType
) => {
  let availableStatuses = currentStatus
    ? Object.values(TaskStatus).filter((value) => value !== currentStatus)
    : Object.values(TaskStatus);

  if (taskClass === TaskClass.Emergency) {
    availableStatuses = availableStatuses.filter(
      (value) => value !== TaskStatus.Reviewing
    );
  } else if (
    taskClass === TaskClass.Administrative ||
    type === TaskType.Voicemail
  ) {
    availableStatuses = availableStatuses.filter(
      (value) => value !== TaskStatus.Cancelled
    );
  }

  return availableStatuses.map((value) => ({
    value,
    label: statusText[taskClass][value],
  }));
};

export const getTitlesAndTemplate = ({
  config,
  hideCheckbox,
  hasPaymentAccess,
}: GetTitlesAndTemplateProps) => {
  let template = '';
  const titles: string[] = [];

  if (!hideCheckbox) {
    titles.push('');
    template = '24px ';
  }

  if (config) {
    Object.entries(config).forEach(([key, value]) => {
      const configKey = key as keyof typeof WhiteboardConfigTemplate;
      if (configKey in WhiteboardConfigTitle && value) {
        if (configKey === WhiteboardConfigKey.Payment && !hasPaymentAccess) {
          return;
        }

        titles.push(WhiteboardConfigTitle[configKey]);
        template += `${WhiteboardConfigTemplate[configKey]} `;
      }
    });
  }

  return { titles, template };
};

export const getAppointmentDates = (appointment?: HospitalAppointment) => {
  const notConfirmedAppointments: AppointmentDate[] = [];
  let confirmedAppointment: AppointmentDate | undefined = undefined;
  if (appointment?.appointmentDates) {
    for (const appointmentDate of appointment.appointmentDates) {
      if (appointmentDate.status === AppointmentTaskStatus.Confirmed) {
        if (!confirmedAppointment) {
          confirmedAppointment = appointmentDate;
        }
      } else {
        notConfirmedAppointments.push(appointmentDate);
      }
    }
  }
  return {
    confirmedAppointmentDate: confirmedAppointment,
    notConfirmedAppointmentDates: notConfirmedAppointments,
    visibleDate: confirmedAppointment || appointment?.appointmentDates?.[0],
  };
};
