import { MockHandler } from '@gv/triage-components';

import { URL } from 'api/constants';

const lorem =
  'Lorem ipsum dolor sit amet consectetur. Et enim viverra viverra malesuada quis. Lacus commodo pellentesque feugiat elementum ac quam. Nec quis tincidunt cras varius sagittis in. Sagittis in ultrices donec felis vestibulum ullamcorper lobortis.';

export const getLastMessage = (index: number) => ({
  id: 1,
  files: [],
  media_url: '',
  author_id: 1234,
  msg_text: lorem,
  media_type: null,
  thread_id: '123456',
  updated_at: '1675158603346',
  created_at: String(Date.now() - 86400000 * index),
  author: { id: 12, phone: '', name: 'Donald Miles' },
});

export const officeChatsMockApiHandlers: MockHandler[] = [
  {
    method: 'post',
    url: URL.OFFICE_CHAT_TRANSFER,
    responseData: {
      data: {
        status: 'success',
      },
    },
  },
];
