import CryptoJS from 'crypto-js';
import {
  TriageUser,
  OfficeChat,
  UnknownObject,
  OpenedFoldersSections,
} from '@gv/triage-components';

import { Config } from 'config';
import { AuthClient } from 'store/slices/auth/types';
import { GetFormResponseData } from 'store/api/dynamic-forms/types';
import {
  Tokens,
  TokenKey,
  SavedCaseForm,
  LocalTemplateChunk,
  LocalUnsavedTemplate,
  StorageTemplateParams,
} from 'types';

import { splitStringByLength } from './helpers';

const userKey = 'authUser';
const matrixKey = 'matrix';
const clientKey = 'authClient';
const caseFormsKey = 'caseForms';
const walkthrough = 'walkthrough';
const phoneNumberKey = 'phoneNumber';
const lastPortalType = 'lastPortalType';
const templateParams = 'templateParams';
const unsavedTemplates = 'unsavedTemplates';
const replaceChatBanner = 'replaceChatBanner';
const gvTalkOpenedChats = 'gvTalkOpenedChats';
const hospitalSpeciality = 'hospitalSpeciality';
const corporateGroupBanner = 'corporateGroupBanner';
const caseOnCallPopupOpened = 'caseOnCallPopupOpened';
const calendarInLocalTimezone = 'calendarInLocalTimezone';

const accessTokenKey: TokenKey = 'accessToken';
const refreshTokenKey: TokenKey = 'refreshToken';
const tokenKeys: TokenKey[] = [accessTokenKey, refreshTokenKey];

export const clearTokens = () =>
  tokenKeys.forEach((tokenKey) => localStorage.removeItem(tokenKey));

export const clearUser = () => localStorage.removeItem(userKey);

export const clearPhone = () => localStorage.removeItem(phoneNumberKey);

export const clearClient = () => localStorage.removeItem(clientKey);

export const clearMatrix = () => localStorage.removeItem(matrixKey);

export const clearHospitalSpeciality = () =>
  localStorage.removeItem(hospitalSpeciality);

export const clearWalkthrough = () => localStorage.removeItem(walkthrough);

export const clearAll = () => {
  clearUser();
  clearPhone();
  clearClient();
  clearTokens();
  clearMatrix();
  clearWalkthrough();
  clearHospitalSpeciality();
  localStorage.removeItem(caseOnCallPopupOpened);
};

export const saveToken = (tokenKey: TokenKey, token: string) =>
  localStorage.setItem(
    tokenKey,
    CryptoJS.AES.encrypt(token, Config.cryptoKey).toString()
  );

export const saveTokens = ({ token, refreshToken }: Tokens) => {
  if (token) {
    saveToken('accessToken', token);
  }
  if (refreshToken) {
    saveToken('refreshToken', refreshToken);
  }
};

export const getToken = (tokenKey: TokenKey = accessTokenKey) => {
  const token = localStorage.getItem(tokenKey);

  if (!token) {
    return;
  }

  try {
    return CryptoJS.AES.decrypt(token, Config.cryptoKey).toString(
      CryptoJS.enc.Utf8
    );
  } catch (err) {
    clearAll();
  }
};

export const getTokens = () => ({
  token: getToken('accessToken'),
  refreshToken: getToken('refreshToken'),
});

export const saveUser = (user: TriageUser) =>
  localStorage.setItem(userKey, JSON.stringify(user));

export const getUser = (): TriageUser | undefined => {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return undefined;
  }
  return JSON.parse(user);
};

export const saveClient = (client: AuthClient) =>
  localStorage.setItem(clientKey, JSON.stringify(client));

export const getClient = (): AuthClient | undefined => {
  const client = localStorage.getItem(clientKey);
  if (!client) {
    return undefined;
  }
  return JSON.parse(client);
};

export const savePhone = (phone: string) =>
  localStorage.setItem(
    phoneNumberKey,
    CryptoJS.AES.encrypt(phone, Config.cryptoKey).toString()
  );

export const getPhone = (): string | undefined => {
  const phone = localStorage.getItem(phoneNumberKey);
  if (!phone) {
    return undefined;
  }
  try {
    return CryptoJS.AES.decrypt(phone, Config.cryptoKey).toString(
      CryptoJS.enc.Utf8
    );
  } catch (err) {
    return undefined;
  }
};

export const saveMatrix = (value: string) => {
  const encrypted = CryptoJS.AES.encrypt(value, Config.cryptoKey);
  localStorage.setItem(matrixKey, encrypted.toString());
};

export const getMatrix = () => {
  const matrix = localStorage.getItem(matrixKey);
  if (!matrix) {
    return undefined;
  }
  try {
    return CryptoJS.AES.decrypt(matrix, Config.cryptoKey).toString(
      CryptoJS.enc.Utf8
    );
  } catch (err) {
    return undefined;
  }
};

export const savePortalType = (value: string) => {
  const encrypted = CryptoJS.AES.encrypt(value, Config.cryptoKey);
  localStorage.setItem(lastPortalType, encrypted.toString());
};

export const getLastPortalType = () => {
  const type = localStorage.getItem(lastPortalType);
  if (!type) {
    return undefined;
  }
  try {
    return CryptoJS.AES.decrypt(type, Config.cryptoKey).toString(
      CryptoJS.enc.Utf8
    );
  } catch (err) {
    return undefined;
  }
};

export const saveCaseForms = (forms: SavedCaseForm[]) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(forms),
    Config.cryptoKey
  );
  localStorage.setItem(caseFormsKey, encrypted.toString());
};

export const getSavedCaseForms = (): SavedCaseForm[] => {
  const data = localStorage.getItem(caseFormsKey);
  if (!data) {
    return [];
  }
  try {
    let result: SavedCaseForm[] =
      JSON.parse(
        CryptoJS.AES.decrypt(data, Config.cryptoKey).toString(CryptoJS.enc.Utf8)
      ) ?? [];
    const currentCount = result.length;
    if (currentCount > 0) {
      const time = Date.now();
      result = result.filter(({ expiredAt }) => expiredAt > time);
      if (currentCount !== result.length) {
        saveCaseForms(result);
      }
    }
    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const saveCaseForm = (props: Omit<SavedCaseForm, 'expiredAt'>) => {
  console.log('save case form for taskId', props.roomId, props.data.id, props);
  let savedForms = getSavedCaseForms();
  const expiredAt = Date.now() + 1000 * 3600 * 2; // store 2 hours
  const newValue = {
    ...props,
    expiredAt,
  };
  if (savedForms.length === 0) {
    savedForms.push(newValue);
  } else {
    const { roomId, userId } = props;
    const index = savedForms.findIndex(
      (form) => form.roomId === roomId && form.userId === userId
    );
    if (index >= 0) {
      const data = savedForms[index].data;
      savedForms[index] = {
        ...savedForms[index],
        ...newValue,
        data: {
          ...data,
          ...newValue.data,
        },
      };
    } else {
      savedForms.push(newValue);
    }
  }
  saveCaseForms(savedForms);
};

export const saveGVTalkOpenedChats = (
  state: OpenedFoldersSections,
  authId?: number
) => {
  const value = JSON.stringify(
    Object.fromEntries(
      Object.entries(state).map(([key, val]) => {
        return [key, Array.from(val)];
      })
    )
  );
  localStorage.setItem(`${gvTalkOpenedChats}_${authId}`, value);
};

export const getGVTalkOpenedChats = (authId?: number) => {
  const opened: OpenedFoldersSections = {};

  const value = localStorage.getItem(`${gvTalkOpenedChats}_${authId}`);
  if (value) {
    Object.entries(JSON.parse(value)).forEach(([key, val]) => {
      opened[key] = new Set(val as number[]);
    });
  }

  return opened;
};

export const getSavedForm = (
  taskId: string,
  userId: number
): any | undefined => {
  const savedForms = getSavedCaseForms();
  const index = savedForms.findIndex(
    (form) => form.roomId === taskId && form.userId === userId
  );
  if (index < 0) {
    return undefined;
  }
  const form = savedForms[index].data;
  console.log('get cased form for taskId', taskId, form.id);
  return form;
};

export const getCorporateGroupBanner = (): boolean =>
  localStorage.getItem(corporateGroupBanner) !== 'true';

export const saveCorporateGroupBanner = (value: boolean) =>
  localStorage.setItem(corporateGroupBanner, String(value));

export const saveHospitalSpeciality = (speciality: string) =>
  localStorage.setItem(hospitalSpeciality, speciality);

export const getHospitalSpeciality = (): string | null =>
  localStorage.getItem(hospitalSpeciality);

export const saveWalkthrough = (value: string) =>
  localStorage.setItem(walkthrough, value);

export const getWalkthrough = (): UnknownObject | undefined => {
  const persistedState = localStorage.getItem(walkthrough);

  if (persistedState) {
    return JSON.parse(persistedState);
  }
};

export const getCaseOnCallPopupOpened = (
  taskId: string
): boolean | undefined => {
  const persistedState = localStorage.getItem(caseOnCallPopupOpened);

  if (persistedState) {
    return JSON.parse(persistedState)[taskId];
  }
};

export const setCaseOnCallPopupOpened = (
  taskId: string,
  value: boolean
): void => {
  const persistedState = localStorage.getItem(caseOnCallPopupOpened);
  const parsedState =
    typeof persistedState === 'string' ? JSON.parse(persistedState) : {};

  localStorage.setItem(
    caseOnCallPopupOpened,
    JSON.stringify({
      ...parsedState,
      [taskId]: value,
    })
  );
};

export const clearCaseOnCallPopupOpened = (taskId: string): void => {
  const persistedState = localStorage.getItem(caseOnCallPopupOpened);

  if (persistedState) {
    const parsedState = JSON.parse(persistedState);
    delete parsedState[taskId];
    localStorage.setItem(caseOnCallPopupOpened, JSON.stringify(parsedState));
  }
};

export const saveReplaceChatBanner = (chat: OfficeChat) =>
  localStorage.setItem(replaceChatBanner, JSON.stringify(chat));

export const clearReplaceChatBanner = () =>
  localStorage.removeItem(replaceChatBanner);

export const getReplaceChatBanner = () => {
  const chat = localStorage.getItem(replaceChatBanner);
  if (!chat) {
    return undefined;
  }
  return JSON.parse(chat) as OfficeChat;
};

export const getTemplateChunkName = (
  userId: number,
  hospitalId: string,
  id?: number
) => `${hospitalId}_templateChunk_${userId}_${id || 'new'}`;

export const getTemplateChunkId = (
  userId: number,
  index: number,
  hospitalId: string,
  id?: number
) => `${getTemplateChunkName(userId, hospitalId, id)}_${index}`;

export const getIdFromChunkName = (name: string) => {
  const [id] = name.split('_').reverse();
  return id === 'new' ? undefined : Number(id);
};

export const getUserIdFromChunkName = (name: string) => {
  const userId = name.split('_')[2];
  return userId ? Number(userId) : undefined;
};

export const getHospitalIdFromChunkName = (name: string) => {
  const [hospitalId] = name.split('_');
  return hospitalId ? String(hospitalId) : undefined;
};

export const getUnsavedTemplates = (
  userId: number,
  hospitalId: string,
  excludeUserOrHospital?: boolean
) => {
  const templates = localStorage.getItem(unsavedTemplates);
  if (!templates || !userId || !hospitalId) {
    return undefined;
  }
  const parsed = JSON.parse(templates) as LocalUnsavedTemplate[];
  return parsed?.filter((template) => {
    const user = getUserIdFromChunkName(template.chunkName);
    const hospital = getHospitalIdFromChunkName(template.chunkName);
    return Boolean(
      user && hospital && excludeUserOrHospital
        ? user !== userId || hospital !== hospitalId
        : user === userId && hospital === hospitalId
    );
  });
};

export const saveUnsavedTemplates = (
  userId: number,
  hospitalId: string,
  templates: LocalUnsavedTemplate[]
) => {
  const otherTemplates = getUnsavedTemplates(userId, hospitalId, true) ?? [];
  localStorage.setItem(
    unsavedTemplates,
    JSON.stringify([...otherTemplates, ...templates])
  );
};

export const getTemplate = (chunkName: LocalUnsavedTemplate['chunkName']) => {
  const userId = getUserIdFromChunkName(chunkName);
  const hospitalId = getHospitalIdFromChunkName(chunkName);
  if (!userId || !hospitalId) {
    return undefined;
  }

  const templates = getUnsavedTemplates(userId, hospitalId) ?? [];
  return templates.find((item) => item.chunkName === chunkName);
};

export const saveTemplate = (
  newTemplate: LocalUnsavedTemplate,
  remove?: boolean
) => {
  const userId = getUserIdFromChunkName(newTemplate.chunkName);
  const hospitalId = getHospitalIdFromChunkName(newTemplate.chunkName);
  if (!userId || !hospitalId) {
    return;
  }
  const templates = (getUnsavedTemplates(userId, hospitalId) ?? []).filter(
    (item) => item.chunkName !== newTemplate.chunkName
  );
  saveUnsavedTemplates(
    userId,
    hospitalId,
    remove ? templates : [newTemplate, ...templates]
  );
};

export const removeTemplateChunks = (chunkName?: string) => {
  if (!chunkName) {
    return;
  }
  const savedTemplate = getTemplate(chunkName);
  if (savedTemplate) {
    for (let i = 0; i < savedTemplate.chunksCount; i++) {
      localStorage.removeItem(`${chunkName}_${i}`);
    }
  }
};

export const getTemplateChunks = (template?: LocalUnsavedTemplate) => {
  if (!template) {
    return undefined;
  }
  const { chunkName, chunksCount } = template;
  const result: string[] = [];
  for (let i = 0; i < chunksCount; i++) {
    const chunkJson = localStorage.getItem(`${chunkName}_${i}`);
    if (chunkJson) {
      result.push(JSON.parse(chunkJson));
    } else {
      break;
    }
  }
  if (result.length !== chunksCount) {
    removeTemplateChunks(chunkName);
    saveTemplate(template, true);
    return undefined;
  }
  return result;
};

export const saveTemplateChunks = (
  chunks: LocalTemplateChunk[],
  chunkName?: string
) => {
  if (chunkName) {
    removeTemplateChunks(chunkName);
  }
  chunks.forEach(({ id, chunk }) =>
    localStorage.setItem(id, JSON.stringify(chunk))
  );
};

export const saveDynamicFormTemplate = (
  userId: number,
  form?: GetFormResponseData,
  hospitalId?: string
) => {
  if (!form || !userId || !hospitalId) {
    return;
  }
  const { id } = form;
  const chunkName = getTemplateChunkName(userId, hospitalId, id);
  const chunks = splitStringByLength(JSON.stringify(form), 5e6);
  const templateChunks: LocalTemplateChunk[] = chunks.map((chunk, index) => ({
    chunk,
    id: getTemplateChunkId(userId, index, hospitalId, id),
  }));
  const unsavedTemplate = { chunkName, chunksCount: templateChunks.length };
  saveTemplateChunks(templateChunks, chunkName);
  saveTemplate(unsavedTemplate);
};

export const getDynamicFormTemplate = (
  userId: number,
  hospitalId: string,
  formId?: number
) => {
  if (!userId || !hospitalId) {
    return undefined;
  }
  const chunkName = getTemplateChunkName(userId, hospitalId, formId);
  const template = getTemplate(chunkName);
  const chunks = getTemplateChunks(template);
  if (!chunks?.length) {
    return undefined;
  }
  return JSON.parse(chunks.join('')) as GetFormResponseData;
};

export const removeDynamicFormTemplate = (
  userId: number,
  hospitalId: string,
  id?: number
) => {
  const chunkName = getTemplateChunkName(userId, hospitalId, id);
  const savedTemplate = getTemplate(chunkName);
  if (savedTemplate) {
    removeTemplateChunks(chunkName);
    saveTemplate(savedTemplate, true);
  }
};

export const getUnsavedTemplatesList = (
  userId?: number,
  hospitalId?: string
) => {
  if (!userId || !hospitalId) {
    return [];
  }
  const templates = getUnsavedTemplates(userId, hospitalId);
  if (!templates) {
    return [];
  }
  const formTemplates: GetFormResponseData[] = [];
  templates.forEach(({ chunkName }) => {
    const form = getDynamicFormTemplate(
      userId,
      hospitalId,
      getIdFromChunkName(chunkName)
    );
    if (form) {
      formTemplates.push(form);
    }
  });
  return formTemplates;
};

export const getTemplateParams = () => {
  const list = localStorage.getItem(templateParams);
  if (list) {
    return JSON.parse(list) as Record<string, StorageTemplateParams>;
  }
  return {};
};

export const addTemplateParam = ({
  userId,
  formId,
  payload,
  hospitalId,
}: {
  formId?: number;
  userId?: number;
  hospitalId?: string;
  payload: StorageTemplateParams;
}) => {
  if (userId && hospitalId) {
    const params = getTemplateParams();
    const key = `${hospitalId}_${userId}_${formId ?? 'new'}`;
    params[key] = { ...params[key], ...payload };

    localStorage.setItem(templateParams, JSON.stringify(params));
  }
};

export const getTemplateParam = ({
  userId,
  formId,
  hospitalId,
}: {
  formId?: number;
  userId?: number;
  hospitalId?: string;
}): StorageTemplateParams | undefined => {
  if (userId && hospitalId) {
    const params = getTemplateParams();
    return params[`${hospitalId}_${userId}_${formId ?? 'new'}`];
  }
};

export const getStorageHospitalId = (
  isGeneral?: boolean,
  hospitalId?: string | number
) => (isGeneral ? 'master' : hospitalId ? String(hospitalId) : undefined);

export const isCalendarInLocalTimezone = () =>
  localStorage.getItem(calendarInLocalTimezone) === 'true';
