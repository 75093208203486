import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormikValues, useFormikContext } from 'formik';
import { SubMenu, SidebarCard } from '@gv/triage-components';

import { Routes } from 'config';
import { joinRoutes } from 'utils/helpers';

import * as Styles from './styles';
import { AsideContentProps } from './types';

const {
  Cases: { Details },
  ActionCenter: { Comm },
} = Routes;

const AsideContent = ({
  isLoading,
  passedSteps,
  setMenuOpened,
  setActiveStep,
  setPassedSteps,
  stepsToReplace,
}: AsideContentProps) => {
  const { values } = useFormikContext<FormikValues>();
  const closeMenu = useCallback(() => setMenuOpened?.(false), [setMenuOpened]);

  return (
    <Styles.Side>
      <Styles.StyledList
        isLoading={isLoading}
        count={passedSteps?.length ?? 0}
        mainWrapperStyles={{
          marginRight: '-8px',
          padding: '0 10px 0 0',
          overflow: 'hidden scroll',
        }}
      >
        <SidebarCard
          values={values}
          passedSteps={passedSteps}
          onClick={(id: string) => {
            setPassedSteps?.(stepsToReplace);
            setActiveStep?.(id);
            closeMenu();
          }}
        />
      </Styles.StyledList>
    </Styles.Side>
  );
};

export const Aside = (props: AsideContentProps) => {
  const { caseId } = useParams();

  const items = useMemo(() => {
    const result = [
      {
        text: 'Passed steps',
        path: joinRoutes([Comm, Details.Consultation.Index], {}),
      },
    ];

    if (caseId) {
      result.push({
        text: 'Passed steps',
        path: joinRoutes([Details.Home, Details.Consultation.Edit], {
          caseId: caseId,
        }),
      });
    }
    return result;
  }, [caseId]);

  return (
    <SubMenu
      sections={[{ items }]}
      innerContent={<AsideContent {...props} />}
      menuButtonProps={{ style: { padding: '8px 16px' } }}
    />
  );
};
