import { ToxicityCard, RadioFieldGroup } from '@gv/triage-components';

import {
  DepartmentSelect,
  callOutcomeModel,
  CheckProtocolsInfo,
} from 'components/cases/form';

import { clientConfirmItems } from '../config';

import { ReferToPPHProps } from './types';

const { emergency } = callOutcomeModel;

export const ReferToPPH = ({
  options,
  toxicityProps,
  isCorporateFetching,
}: ReferToPPHProps) => (
  <>
    <DepartmentSelect
      options={options}
      isDisabled={isCorporateFetching}
      name={emergency.referToPPH.corporateHospitalId}
    />

    <CheckProtocolsInfo>
      <RadioFieldGroup
        items={clientConfirmItems}
        label="Confirm action with client"
        name={emergency.referToPPH.accepted}
      />

      {toxicityProps?.services && toxicityProps.services.length > 0 && (
        <ToxicityCard {...toxicityProps} />
      )}
    </CheckProtocolsInfo>
  </>
);
