import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CaseFormStep, ConsultationStep } from 'types';

import { editSteps, baseSteps } from './config';
import {
  AddPayload,
  BasePayload,
  InitPayload,
  BackPayload,
  ChangeStepPayload,
  ChangeStepsPayload,
} from './types';

const initialState: Record<string, CaseFormStep> = {};

export const caseFormStepSlice = createSlice({
  initialState,
  name: 'caseFormStepSlice',
  reducers: {
    init: (state, { payload }: PayloadAction<InitPayload>) => {
      const { formId, initialState: payloadInitialState } = payload;
      state[formId] = payloadInitialState;
    },
    deinit: (state, { payload }: PayloadAction<BasePayload>) => {
      const { formId } = payload;
      state[formId] = {
        stepsList: editSteps,
        currentStep: editSteps[0],
        maxOpenedStep: editSteps[0],
      };
    },
    removeStep: (state, { payload }: PayloadAction<ChangeStepPayload>) => {
      const { step, formId } = payload;
      const { stepsList } = state[formId];
      state[formId].stepsList = stepsList.filter((value) => value !== step);
    },
    changeStep: (state, { payload }: PayloadAction<ChangeStepPayload>) => {
      const { step, formId } = payload;
      const { currentStep } = state[formId];
      console.log('[changeStep]: change step in slice');
      if (step !== currentStep) {
        state[formId].currentStep = step;
      }
    },
    insertStep: (state, { payload }: PayloadAction<AddPayload>) => {
      const { step, after, formId } = payload;
      const { stepsList } = state[formId];

      if (!stepsList.includes(step)) {
        const afterIndex = stepsList.indexOf(after);
        stepsList.splice(afterIndex + 1, 0, step);
        state[formId].stepsList = stepsList;
      }
    },
    back: (state, { payload }: PayloadAction<BackPayload>) => {
      const { step, formId } = payload;
      const { stepsList, currentStep } = state[formId];

      if (currentStep === ConsultationStep.DeadlineIssue) {
        state[formId] = {
          stepsList: baseSteps,
          currentStep: ConsultationStep.Greeting,
          maxOpenedStep: ConsultationStep.Greeting,
        };
      } else {
        const prevStep = step ?? stepsList[stepsList.indexOf(currentStep) - 1];
        state[formId].currentStep = prevStep;
      }
    },
    next: (state, { payload }: PayloadAction<BasePayload>) => {
      const { formId } = payload;
      const { stepsList, currentStep, maxOpenedStep } = state[formId];
      const nextStep = stepsList[stepsList.indexOf(currentStep) + 1];
      const nextStepIndex = stepsList.indexOf(nextStep);
      const maxStepIndex = stepsList.indexOf(maxOpenedStep);

      const newMax = Math.max(maxStepIndex, nextStepIndex);
      if (newMax > maxStepIndex) {
        state[formId].maxOpenedStep = nextStep;
      }
      state[formId].currentStep = nextStep;
    },
    changeSteps: (state, { payload }: PayloadAction<ChangeStepsPayload>) => {
      const { step, steps, formId } = payload;

      state[formId].stepsList = steps;
      state[formId].currentStep = step;

      const maxOpenedStep = state[formId].maxOpenedStep;
      const maxStepIndex = steps.indexOf(maxOpenedStep);
      if (maxOpenedStep && maxStepIndex === -1) {
        state[formId].maxOpenedStep = step;
      } else {
        const nextStepIndex = steps.indexOf(step);
        const newMax = Math.max(maxStepIndex, nextStepIndex);
        if (newMax > maxStepIndex) {
          state[formId].maxOpenedStep = step;
        }
      }
    },
  },
});

const {
  reducer: caseFormStepReducer,
  actions: {
    next,
    back,
    init,
    deinit,
    insertStep,
    changeStep,
    removeStep,
    changeSteps,
  },
} = caseFormStepSlice;

const caseFormStepStore = (store: RootState) => store?.caseFormStep;

export const selectCaseFormStep = (formId?: BasePayload['formId']) =>
  createSelector([caseFormStepStore], (store) => {
    const step = store?.[formId ?? ''] ?? ({} as Partial<CaseFormStep>);
    const { stepsList, currentStep, maxOpenedStep } = step;
    return {
      ...step,
      currentStepIndex:
        stepsList && currentStep ? stepsList.indexOf(currentStep) : 0,
      maxOpenedStepIndex:
        stepsList && maxOpenedStep ? stepsList.indexOf(maxOpenedStep) : 0,
    };
  });

export const selectCurrentStep = (formId?: BasePayload['formId']) =>
  createSelector(
    [caseFormStepStore],
    (store) => store?.[formId ?? '']?.currentStep
  );

export const selectIsLastStep = (formId?: BasePayload['formId']) =>
  createSelector([caseFormStepStore], (store) => {
    const { stepsList, currentStep } = store?.[formId ?? ''] ?? {};
    if (!stepsList || !currentStep) {
      return false;
    }
    return stepsList[stepsList.length - 1] === currentStep;
  });

export {
  next,
  back,
  init,
  deinit,
  insertStep,
  changeStep,
  removeStep,
  changeSteps,
  caseFormStepReducer,
};
