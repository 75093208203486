import { useState, useContext, useCallback } from 'react';
import {
  Dialog,
  FlexLayout,
  ButtonColors,
  ThemeContext,
  handleMutation,
} from '@gv/triage-components';

import { dotSymbol } from 'config';
import { useOptionalHospitalData } from 'hooks';
import {
  useUnsubscribedEmailsQuery,
  useResubscribeEmailsMutation,
} from 'store/api/hospitals';

import * as Styles from './styles';

export const EmailUnsubscribedBanner = () => {
  const { id } = useOptionalHospitalData();
  const { isDarkTheme } = useContext(ThemeContext);

  const [isOpened, setOpened] = useState(false);

  const { data: { unsubscribedEmails } = {} } = useUnsubscribedEmailsQuery(
    { hospitalId: id! },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  const [resubscribe, mutation] = useResubscribeEmailsMutation();

  const onClose = useCallback(() => setOpened(false), []);

  const onSuccess = useCallback(() => {
    onClose();
  }, []);

  handleMutation({ ...mutation, onSuccess });

  const onConfirm = useCallback(() => {
    if (unsubscribedEmails?.length && id) {
      resubscribe({ hospitalId: id, emails: unsubscribedEmails });
    }
  }, [unsubscribedEmails, id]);

  if (!unsubscribedEmails?.length || mutation.isLoading) {
    return null;
  }

  return (
    <>
      <Styles.Container
        isDarkTheme={isDarkTheme}
        onClick={() => setOpened(true)}
      >
        <p>
          Some from hospital's email addresses are unsubscribed. Please{' '}
          <span>click here</span> to resubscribe.
        </p>
      </Styles.Container>

      <Dialog
        open={isOpened}
        onClose={onClose}
        cancelText="Cancel"
        onConfirm={onConfirm}
        confirmText="Resubscribe"
        title="Confirm your action"
        confirmStyle={ButtonColors.Primary}
        text={
          <FlexLayout flexDirection="column">
            <p>
              To continue receiving updates for the following email addresses:
            </p>

            {!!unsubscribedEmails?.length &&
              unsubscribedEmails.map((email, idx) => (
                <Styles.Email key={idx}>
                  <span>{dotSymbol}</span>
                  {email}
                </Styles.Email>
              ))}

            <p>Please click resubscribe.</p>
          </FlexLayout>
        }
      />
    </>
  );
};
