import { BaseResponse } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { cacheByIdArg, providesList } from 'utils/query-cache';

import {
  PhoneTreeItem,
  BasePhoneTreeItemRequest,
  GetPhoneTreeItemResponse,
  GetPhoneTreeListResponse,
  ClearPhoneTreeItemRequest,
  CreatePhoneTreeItemRequest,
  UpdatePhoneTreeItemRequest,
  DeletePhoneTreeItemRequest,
  CreatePhoneTreeItemResponse,
} from './types';

const tagType = 'Phones';

export const phoneTreeApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'phoneTreeApi',
  endpoints: (build) => ({
    list: build.query<PhoneTreeItem[], string>({
      providesTags: providesList(tagType),
      transformResponse: (response: GetPhoneTreeListResponse) => response.data,
      query: (hospitalId) => ({
        method: 'get',
        url: generateUrl(URL.PHONE_TREE_LIST, {
          hospitalId,
        }),
      }),
    }),
    clear: build.mutation<number, ClearPhoneTreeItemRequest>({
      invalidatesTags: (result, error, { mainKeyId }) =>
        cacheByIdArg(tagType)(result, error, mainKeyId),
      query: ({ branchId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.PHONE_TREE_CLEAR, {
          branchId,
          hospitalId,
        }),
      }),
    }),
    update: build.mutation<BaseResponse<string>, UpdatePhoneTreeItemRequest>({
      invalidatesTags: (result, error, { mainKeyId }) =>
        cacheByIdArg(tagType)(result, error, mainKeyId),
      query: ({ data, branchId, hospitalId }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.PHONE_TREE, {
          branchId,
          hospitalId,
        }),
      }),
    }),
    delete: build.mutation<BaseResponse<string>, DeletePhoneTreeItemRequest>({
      invalidatesTags: (result, error, { mainKeyId }) =>
        cacheByIdArg(tagType)(result, error, mainKeyId ? mainKeyId : 'LIST'),
      query: ({ branchId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.PHONE_TREE, {
          branchId,
          hospitalId,
        }),
      }),
    }),
    item: build.query<PhoneTreeItem, BasePhoneTreeItemRequest>({
      transformResponse: (response: GetPhoneTreeItemResponse) => response.data,
      providesTags: (result, error, { branchId }) =>
        cacheByIdArg(tagType)(result, error, branchId),
      query: ({ branchId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.PHONE_TREE, {
          branchId,
          hospitalId,
        }),
      }),
    }),
    create: build.mutation<PhoneTreeItem, CreatePhoneTreeItemRequest>({
      transformResponse: (response: CreatePhoneTreeItemResponse) =>
        response.data,
      invalidatesTags: (result, error, { mainKeyId }) =>
        cacheByIdArg(tagType)(result, error, mainKeyId ? mainKeyId : 'LIST'),
      query: ({ data, hospitalId }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.PHONE_TREE_LIST, {
          hospitalId,
        }),
      }),
    }),
  }),
});

export const {
  useItemQuery,
  useListQuery,
  useClearMutation,
  useCreateMutation,
  useDeleteMutation,
  useUpdateMutation,
} = phoneTreeApi;
