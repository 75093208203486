import { ActionQueueType } from '@gv/triage-components';

import { processCase } from 'utils/helpers';
import { generateAPIPath } from 'store/query';
import { ActionQueueTypeNumber } from 'types/data';

import { URL } from '../constants';
import { apiInstance } from '../instance';

import {
  CreateCaseRequest,
  SubmitCaseRequest,
  UpdateCaseRequest,
} from './types';

export const createCase = (data: CreateCaseRequest) =>
  apiInstance.post(URL.CREATE_CASE, data);

export const updateCase = ({ data, caseId }: UpdateCaseRequest) =>
  apiInstance.patch(generateAPIPath(URL.UPDATE_CASE, { caseId }), data);

export const submitCase = ({ data, caseId }: SubmitCaseRequest) =>
  apiInstance.post(generateAPIPath(URL.SUBMIT_CASE, { caseId }), data);

export const updateCaseChatChannel = (data: any) =>
  apiInstance.post(URL.UPDATE_CASE_CHAT_CHANNEL, data);

export const fetchCase = async (
  searchValue: any,
  option: number,
  type: ActionQueueType
) => {
  const typeValue = String(ActionQueueTypeNumber[type]);
  if (typeValue === undefined || typeValue === 'undefined') {
    console.error(
      'Something went wrong with task type',
      type,
      ActionQueueTypeNumber[type],
      typeValue
    );
  }

  /**
   * option 1 -Case Id
   * option 2 -client id
   * option 3 - client Name
   * option 4 pet name
   */
  const isFirstOption = option === 1;
  const typeReplacement = {
    replace: isFirstOption ? '' : typeValue,
    search: isFirstOption ? '&type={type}' : '{type}',
  };

  const url = URL.FETCH_CASES_BASED_ON_FILTER.replace(
    '{search_value}',
    searchValue
  )
    .replace('{option}', String(option))
    .replace(typeReplacement.search, typeReplacement.replace);
  const response = await apiInstance.get(url);
  const caseForm = processCase(response.data.data.CaseForm);

  return caseForm;
};
