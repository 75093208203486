import { Formik } from 'formik';
import {
  Col,
  Row,
  Dialog,
  useAlert,
  DialogType,
  InputField,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { useAppDispatch } from 'store';
import { useOptionalHospitalData } from 'hooks';
import { sendSMS } from 'store/api/action-queue/thunks';
import { getERTemplate, prepareTextOutReferral } from 'utils/templates';

import { ERTemplateInfoList } from '../er-template-info-list';

import * as Styles from './styles';
import { FormValues, ERInfoDialogProps } from './types';
import { getInitialValues, validationSchema } from './config';

export const ERInfoDialog = ({
  onClose,
  caseData,
  referral,
}: ERInfoDialogProps) => {
  const { hospital } = useOptionalHospitalData();
  const dispatch = useAppDispatch();
  const { showErrorAlert, showSuccessAlert } = useAlert();

  const onSubmit = async ({ phone, message }: FormValues) => {
    const { failed, successful } = await dispatch(
      sendSMS({
        phones: [phone],
        isFromSendErInfo: true,
        message: getERTemplate({
          notes: message,
          referrals: [referral].map(prepareTextOutReferral(hospital)),
        }),
      })
    ).unwrap();
    if (failed) {
      showErrorAlert(`Text failed to sent to:\n${failed}`);
    } else {
      if (successful) {
        showSuccessAlert(`Text successfully sent to:\n${successful}`);
      }
      onClose();
    }
  };

  return (
    <Dialog open onClose={onClose} title="Send hospital info">
      {(close, _, Buttons) => (
        <Formik<FormValues>
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={getInitialValues({ caseData })}
        >
          {({ isSubmitting }) => (
            <Styles.Form>
              <Row>
                <Col>
                  <InputField type="tel" name="phone" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <ERTemplateInfoList data={referral} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <InputField
                    minRows={5}
                    name="message"
                    placeholder="Message"
                    type="textarea-autosize"
                  />
                </Col>
              </Row>

              <Buttons type={DialogType.Plain}>
                <StandardButton
                  fullWidth
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.Secondary}
                />
                <StandardButton
                  fullWidth
                  text="Send"
                  disabled={isSubmitting}
                  type={ButtonTypes.Submit}
                />
              </Buttons>
            </Styles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
