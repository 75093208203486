import { TriageUser, UserRoleType } from '@gv/triage-components';

import { RolePortalType } from 'types';
import { Config, PortalType } from 'config';

export const hasRoles = (
  roles?: number[],
  allowedRoles?: UserRoleType[]
): boolean => {
  if (roles && allowedRoles && roles.length > 0) {
    const allowed = roles.some((value) => {
      return allowedRoles.indexOf(value) !== -1;
    });
    return allowed;
  }

  return false;
};

const AllowedRoles = {
  [PortalType.PetOwner]: [UserRoleType.PetOwner],
  [PortalType.Team]: [
    UserRoleType.CSR,
    UserRoleType.CVT,
    UserRoleType.TeamCoach,
    UserRoleType.SuperAdmin,
  ],
  [PortalType.Hospital]: [
    UserRoleType.DVM,
    UserRoleType.CSR2,
    UserRoleType.Doctor,
    UserRoleType.Technician,
    UserRoleType.HospitalAdmin,
    UserRoleType.VeterinaryAssistant,
    UserRoleType.SecondaryHospitalAdmin,
  ],
};

export const isUserAllowed = (user?: TriageUser): boolean => {
  const { raw: portalType } = Config.portalType;
  const allowedRoles = AllowedRoles[portalType];
  return (
    hasRoles(user?.roles, allowedRoles) ||
    (user?.role_type ? RolePortalType[portalType] === user.role_type : false)
  );
};
