import { useCallback } from 'react';
import {
  Icon,
  Dialog,
  useAlert,
  Messages,
  FlexLayout,
  useNavigate,
  ButtonColors,
  handleMutation,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { Routes } from 'config';
import { InternalTaskEdit } from 'pages/internal-team-tasks/form';
import { useDeleteMutation } from 'store/api/internal-team-tasks';

import { InternalTaskButtonsProps } from './types';

const { Home } = Routes.InternalTeamTasks;

export const InternalTaskButtons = ({
  data,
  fullWidth,
  isEditDenied,
}: InternalTaskButtonsProps) => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [deleteTask, deleteMutation] = useDeleteMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Task ${Messages.DELETED_SUCCESSFULLY}`);
    navigate(Home);
  }, []);

  handleMutation({ ...deleteMutation, onSuccess });

  return (
    <FlexLayout gap={16}>
      <InternalTaskEdit
        data={data}
        fullWidth={fullWidth}
        isEditDenied={isEditDenied}
      />

      <Dialog
        confirmText="Yes, delete"
        title="Delete internal task"
        onConfirm={() => deleteTask({ id: data.id })}
        text={
          <>
            You are about to delete the task{' '}
            <span className="semibold">{data.title}</span>. Are you sure you
            want to delete this task?
          </>
        }
        trigger={
          <StandardButtonWithRef
            fullWidth={fullWidth}
            icon={<Icon.Delete />}
            disabled={isEditDenied}
            colorType={ButtonColors.LightRed}
          />
        }
      />
    </FlexLayout>
  );
};
