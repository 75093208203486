import dayjs from 'dayjs';
import {
  Row,
  Col,
  Utils,
  Linkify,
  FlexLayout,
  ChildrenProps,
} from '@gv/triage-components';

import { Addendum } from 'types';
import { dotSymbol } from 'config';
import { useOptionalHospitalData } from 'hooks';
import { ExpandableSection } from 'components/cases';

import * as Styles from './styles';

interface AddendumInfoProps {
  hospitalId?: number;
  addendums?: Addendum[];
  defaultOpened?: boolean;
}

const AddendumsInfo = ({
  children,
  addendums,
  hospitalId,
  defaultOpened,
}: ChildrenProps<AddendumInfoProps>) => {
  const { hospital } = useOptionalHospitalData(String(hospitalId ?? ''));
  const timezone = hospital?.timezone?.time_zone_code;

  const hasItems = addendums && addendums?.length > 0;
  return (
    <>
      {(hasItems || children) && (
        <ExpandableSection
          withoutSpacing
          label="Addendums"
          defaultOpened={defaultOpened}
        >
          {hasItems && (
            <Row>
              <Col>
                <Linkify>
                  <FlexLayout gap={16} flexDirection="column">
                    {addendums.map((addendum) => {
                      let date = dayjs(+addendum.created_at);
                      if (timezone) {
                        try {
                          date = date.tz(timezone);
                        } catch (error) {
                          console.error(error);
                        }
                      }
                      const header = `${Utils.Helpers.textValue(
                        addendum.user?.name
                      )} ${dotSymbol} ${Utils.Date.advancedFormat(date)}`;
                      return (
                        <div key={addendum.id}>
                          <Styles.Header>{header}</Styles.Header>
                          <div>{addendum.addendum_msg}</div>
                        </div>
                      );
                    })}
                  </FlexLayout>
                </Linkify>
              </Col>
            </Row>
          )}
          {children}
        </ExpandableSection>
      )}
    </>
  );
};

export { AddendumsInfo };
