import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  DeleteRequest,
  RecordingData,
  RecordingListRequest,
  RecordingListResponse,
} from './types';

const tagTypes = ['Recordings'];

export const recordingsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'recordingsApi',
  endpoints: (build) => ({
    create: build.mutation<void, any>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_RECORDING,
      }),
    }),

    delete: build.mutation<void, DeleteRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, name }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_RECORDING, { id, name }),
      }),
    }),

    list: build.query<RecordingData, RecordingListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: RecordingListResponse) => response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_RECORDINGS_LIST, {
          limit,
          search,
          offset: Utils.Helpers.offset(page, limit),
        });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useListQuery, useDeleteMutation, useCreateMutation } =
  recordingsApi;
