import styled, { css } from 'styled-components';

export const Eta = styled.div<{ $isToday: boolean }>`
  font-weight: 700;

  ${({ $isToday }) =>
    $isToday &&
    css`
      font-size: 20px;
    `}
`;

export const Container = styled.div`
  gap: 8px;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
