import { MediaData } from './types';

export const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};

export const mediaReducer = (
  state: Omit<MediaData, 'mediaStream'>,
  action: any
) => {
  switch (action.type) {
    case 'audio-encode':
      return {
        ...state,
        audio: { ...state.audio, encode: action.payload },
      };
    case 'audio-decode':
      return {
        ...state,
        audio: { ...state.audio, decode: action.payload },
      };
    case 'video-encode':
      return {
        ...state,
        video: { ...state.video, encode: action.payload },
      };
    case 'video-decode':
      return {
        ...state,
        video: { ...state.video, decode: action.payload },
      };
    case 'share-encode':
      return {
        ...state,
        share: { ...state.share, encode: action.payload },
      };
    case 'share-decode':
      return {
        ...state,
        share: { ...state.share, decode: action.payload },
      };
    case 'reset-media':
      return { ...mediaShape };
    default:
      return state;
  }
};
