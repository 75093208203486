import {
  Row,
  Badge,
  Badges,
  Hospital,
  BadgeColor,
  BadgePadding,
  BadgeBorderRadius,
  ProtocolLevelLabels,
} from '@gv/triage-components';

import * as Styles from './styles';

interface HospitalHeaderTagsProps {
  hospital: Hospital;
  withServices?: boolean;
  withoutMargin?: boolean;
}

export const HospitalHeaderTags = ({
  hospital,
  withServices,
  withoutMargin,
}: HospitalHeaderTagsProps) => {
  const { services, protocol_level } = hospital;

  const getHeaderBadge = (text: string, key?: string) => {
    return (
      <Badge
        key={key}
        text={text}
        styleType={BadgeColor.Blue}
        padding={BadgePadding.Medium}
        borderRadius={BadgeBorderRadius.Small}
      />
    );
  };

  const hospitalServices = services?.length
    ? services.map((item, idx) => getHeaderBadge(item, `${item}-${idx}`))
    : null;

  return (
    <Row>
      <Styles.StyledCol withoutMargin={withoutMargin}>
        <Badges>
          {protocol_level &&
            getHeaderBadge(ProtocolLevelLabels[protocol_level])}
          {withServices && hospitalServices}
        </Badges>
      </Styles.StyledCol>
    </Row>
  );
};
