import { useCallback } from 'react';
import {
  Icon,
  useAlert,
  Messages,
  ButtonColors,
  handleMutation,
  StandardButtonWithRef,
} from '@gv/triage-components';

import {
  UpdateRequest,
  useUpdateMutation,
} from 'store/api/internal-team-tasks';

import { getPayload } from './config';
import { InternalForm } from './form';
import { InternalTaskEditProps } from './types';

export const InternalTaskEdit = ({
  data,
  fullWidth,
  isEditDenied,
}: InternalTaskEditProps) => {
  const { showSuccessAlert } = useAlert();
  const [update, updateMutation] = useUpdateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Internal task ${Messages.UPDATED_SUCCESSFULLY}`);
  }, []);

  handleMutation({ ...updateMutation, onSuccess });

  return (
    <InternalForm
      isEdit
      data={data}
      onSubmit={(values) =>
        update(getPayload({ ...values, id: data!.id! }) as UpdateRequest)
      }
      trigger={
        <StandardButtonWithRef
          icon={<Icon.Edit />}
          fullWidth={fullWidth}
          disabled={isEditDenied}
          colorType={ButtonColors.Secondary}
        />
      }
    />
  );
};
