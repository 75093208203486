import { MockHandler } from '@gv/triage-components';

import { URL } from 'api/constants';
import { MarketingType } from 'types';

const contacts = [
  'Robert Fox',
  'Cody Fisher',
  'Annette Black',
  'Kathryn Murphy',
  'Darrell Steward',
  'Leslie Alexander',
  'Darlene Robertson',
];

const marketingListData = [
  {
    id: 1234,
    clickRate: 72,
    responseRate: 30,
    name: 'Re-check',
    subscribers: 124,
    end: 1675756311000,
    start: 1675669911000,
    type: MarketingType.Scheduled,
  },
  {
    id: 4444,
    subscribers: 14,
    name: 'Black friday',
    type: MarketingType.Draft,
  },
  {
    id: 444234,
    clickRate: 45,
    subscribers: 78,
    name: 'Christmas',
    end: 1675756311000,
    start: 1675669911000,
    type: MarketingType.Completed,
  },
];

const subscribersListData = [
  {
    id: 14324,
    title: 'Subscribers 1',
    contacts: ['Jhon Banks', 'Brian Jacobs'],
  },
  {
    contacts,
    id: 141234324,
    title: 'Subscribers 2',
  },
  {
    id: 14,
    contacts,
    title: 'Subscribers 3',
  },
  {
    contacts,
    id: 14325123,
    title: 'Subscribers 4',
  },
  {
    contacts,
    id: 1432124444,
    title: 'Subscribers 5',
  },
];

const subscribersDetailsList = [
  {
    id: 1,
    name: 'Theresa Webb',
    phone: '+17025550122',
    type: 'CVT Team Lead',
  },
  {
    id: 2,
    type: 'pet owner',
    name: 'Jenny Wilson',
    phone: '+17025550122',
  },
  {
    id: 3,
    type: 'pet owner',
    name: 'Eleanor Pena',
    phone: '+17025550122',
  },
];

const searchFilter = (array: any[], value: string, search?: string) => {
  if (!search) {
    return array;
  }

  return array.filter((item) =>
    item[value].toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
};

export const marketingMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    url: URL.GET_MARKETING_LIST,
    responseFunc: ({ data }) => ({
      data: searchFilter(marketingListData, 'name', data?.search),
    }),
  },
  {
    method: 'get',
    url: URL.GET_MARKETING_SUBSCRIBERS,
    responseFunc: ({ data }) => ({
      data: searchFilter(subscribersListData, 'title', data?.search),
    }),
  },
  {
    method: 'get',
    url: URL.GET_MARKETING_DETAILS,
    responseData: {
      id: 444234,
      clickRate: 45,
      responseRate: 20,
      name: 'Christmas',
      end: 1675756311000,
      start: 1675669911000,
      type: MarketingType.Scheduled,
      messages: [
        {
          id: 1,
          thread_id: '',
          author_id: null,
          media_url: null,
          media_type: null,
          created_at: '1653849226323',
          updated_at: '1653849226323',
          msg_text: 'Hi! We have new Christmas discounts, call!',
        },
      ],
      subscribers: [
        {
          id: 1,
          click: true,
          response: false,
          name: 'Theresa Webb',
          type: 'CVT Team Lead',
          phone: '+17025550122',
        },
        {
          id: 2,
          click: true,
          response: true,
          type: 'pet owner',
          name: 'Jenny Wilson',
          phone: '+17025550122',
        },
        {
          id: 3,
          click: false,
          response: false,
          type: 'pet owner',
          name: 'Eleanor Pena',
          phone: '+17025550122',
        },
      ],
    },
  },
  {
    method: 'get',
    url: URL.GET_MARKETING_SUBSCRIBERS_DETAILS,
    responseFunc: ({ data }) => ({
      id: 444234,
      name: 'Subscribers 1',
      subscribers: searchFilter(subscribersDetailsList, 'name', data?.search),
    }),
  },
  {
    method: 'post',
    url: URL.CREATE_MARKETING,
    responseFunc: ({ data }) => data,
  },
  {
    method: 'patch',
    url: URL.EDIT_MARKETING,
    responseFunc: ({ data }) => data,
  },
];
