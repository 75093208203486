import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';

import {
  ActionItem,
  ActionItemData,
  ActionItemsRequest,
  ActionItemsResponse,
} from './types';

const actionItemAdapter = createEntityAdapter<ActionItem, number>({
  selectId: (item) => item.id,
});

const tagTypes = ['ActionItems'];

export const actionItemApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'actionItemApi',
  endpoints: (build) => ({
    complete: build.mutation<void, Set<number>>({
      invalidatesTags: tagTypes,
      query: (ids) => ({
        method: 'put',
        url: URL.COMPLETE_ACTION_ITEMS,
        data: {
          caseIds: Array.from(ids),
        },
      }),
    }),
    list: build.query<ActionItemData, ActionItemsRequest>({
      providesTags: tagTypes,
      transformResponse: (response: ActionItemsResponse) => response.data,
      query: ({ search, page = 1, hospitalId, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams: Record<string, any> = {
          limit,
          offset,
          search,
          hospital_id: hospitalId,
        };

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(URL.ACTION_ITEMS, searchParams),
        };
      },
    }),
  }),
});

export const actionItemSlice = createSlice({
  reducers: {},
  name: 'actionItems',
  initialState: actionItemAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      actionItemApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        actionItemAdapter.setAll(state, payload.rows);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      actionItemAdapter.removeAll(state);
    });
  },
});

export const { useListQuery, useCompleteMutation } = actionItemApi;
