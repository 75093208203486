import { isMobile } from '@gv/triage-components';
import { TooltipRenderProps } from 'react-joyride';

import { Intro } from '../intro';
import { StepPopup } from '../step-popup';

import * as Styles from './styles';

export const Tooltip = ({
  size,
  step,
  index,
  skipProps,
  isLastStep,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const mobile = isMobile();
  const isIntro = index === 0;

  return (
    <Styles.Tooltip
      {...tooltipProps}
      width={isIntro ? (mobile ? '100%' : '400px') : '280px'}
    >
      {isIntro ? (
        <Intro skipProps={skipProps} primaryProps={primaryProps} />
      ) : (
        <StepPopup
          step={step}
          size={size}
          index={index}
          skipProps={skipProps}
          isLastStep={isLastStep}
          primaryProps={primaryProps}
        />
      )}
    </Styles.Tooltip>
  );
};
