import { useAudio } from 'react-use';
import { useEffect, createContext } from 'react';
import { ChildrenProps } from '@gv/triage-components';

import { Play } from './play';
import { Time } from './time';
import { Info } from './info';
import { Pause } from './pause';
import { RecordPlay } from './record-play';
import { AudioPlayerProps, AudioPlayerContext } from './types';

export const AudioContext = createContext<AudioPlayerContext>({
  state: null,
  value: null,
  controls: null,
});

const AudioPlayer = ({
  value,
  children,
  setPlayingControlsRef,
}: ChildrenProps<AudioPlayerProps>): JSX.Element => {
  const [audio, state, controls, ref] = useAudio({
    src: value?.src,
  });

  useEffect(() => {
    if (state.playing) {
      setPlayingControlsRef?.(ref.current ?? undefined, controls);
    }
  }, [ref.current, state.playing]);

  return (
    <AudioContext.Provider value={{ value, state, controls }}>
      {audio}
      {children}
    </AudioContext.Provider>
  );
};

AudioPlayer.Play = Play;
AudioPlayer.Time = Time;
AudioPlayer.Info = Info;
AudioPlayer.Pause = Pause;
AudioPlayer.RecordPlay = RecordPlay;

export { AudioPlayer };
