import { Contact, SelectOptionProps } from '@gv/triage-components';

import { ContactOption } from 'components/contact-option';

export const SelectContactOption = ({
  innerRef,
  data: { contact },
  innerProps: { onClick, ...rest },
}: SelectOptionProps) => {
  return (
    <div ref={innerRef} {...rest}>
      <ContactOption onClick={onClick} contact={contact as Contact} />
    </div>
  );
};
