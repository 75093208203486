import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { CallFlow } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheListAndByIdArg,
} from 'utils/query-cache';

import {
  CallFlowsData,
  CallFlowsListRequest,
  CallFlowCreateRequest,
  CallFlowDeleteRequest,
  CallFlowUpdateRequest,
  CallFlowsListResponse,
  CallFlowCreateResponse,
  CallFlowDeleteResponse,
  CallFlowDetailsRequest,
  CallFlowDetailsResponse,
  CallFlowsUpdateResponse,
} from './types';

const callFlowsAdapter = createEntityAdapter<CallFlow, number>({
  selectId: (item) => item.id,
});

const tagType = 'CallFlows';

export const callFlowsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'callFlowsApi',
  endpoints: (build) => ({
    create: build.mutation<CallFlow, CallFlowCreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CallFlowCreateResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.CREATE_CALL_FLOW, {
          hospitalId,
        }),
      }),
    }),

    delete: build.mutation<string, CallFlowDeleteRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CallFlowDeleteResponse) => response.data,
      query: ({ flowId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_CALL_FLOW, {
          flowId,
          hospitalId,
        }),
      }),
    }),

    details: build.query<CallFlow, CallFlowDetailsRequest>({
      transformResponse: (response: CallFlowDetailsResponse) => response.data,
      providesTags: (result, error, { flowId }) =>
        cacheByIdArg(tagType)(result, error, flowId),
      query: ({ flowId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_CALL_FLOW, {
          flowId,
          hospitalId,
        }),
      }),
    }),

    update: build.mutation<CallFlow, CallFlowUpdateRequest>({
      transformResponse: (response: CallFlowsUpdateResponse) => response.data,
      invalidatesTags: (result, error, { flowId }) =>
        cacheListAndByIdArg(tagType)(result, error, flowId),
      query: ({ flowId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_CALL_FLOW, {
          flowId,
          hospitalId,
        }),
      }),
    }),

    list: build.query<CallFlowsData, CallFlowsListRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: CallFlowsListResponse) => response.data,
      query: ({ search, page = 1, limit = 20, hospitalId, hideDuringCall }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const urlParams = new URLSearchParams();
        urlParams.append('limit', String(limit));
        urlParams.append('offset', String(offset));
        if (search && search.length) {
          urlParams.append('searchString', search);
        }
        if (typeof hideDuringCall === 'boolean') {
          urlParams.append('hideDuringCall', String(hideDuringCall));
        }
        const url = `${URL.GET_CALL_FLOWS.replace(
          ':hospitalId',
          hospitalId!
        )}?${urlParams.toString()}`;

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const callFlowsSlice = createSlice({
  reducers: {},
  name: 'callFlows',
  initialState: callFlowsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      callFlowsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        callFlowsAdapter.setAll(state, payload.rows);
      }
    );
    builder.addMatcher(
      callFlowsApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        callFlowsAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      callFlowsAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
} = callFlowsApi;
