import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TaskType } from 'types';
import { RootState } from 'store/store';

import { isLogoutAction } from '../auth';

import { BadgesProps, ActionItemsBadgesResponse } from './types';

const getInitialValue = (): BadgesProps => {
  return {
    onTheWay: 0,
    voicemails: 0,
    internalTasks: 0,
    actionItems: {
      caseForms: 0,
      orderFoods: 0,
      medicalRefills: 0,
    },
    tasks: {
      emergency: { total: 0 },
      administrative: { total: 0 },
      transferToBackline: { total: 0 },
      appointment: { total: 0, appointment: 0, [TaskType.VirtualExam]: 0 },
    },
  };
};

const badgesSlice = createSlice({
  name: 'badges',
  initialState: getInitialValue(),
  extraReducers: (builder) => {
    builder.addMatcher(isLogoutAction, (state) => {
      const initialState = getInitialValue();
      Object.entries(initialState).forEach((item) => {
        const [key, value] = item;
        state[key as keyof BadgesProps] = value;
      });
    });
  },
  reducers: {
    setTasks: (state, { payload }) => {
      state.tasks = payload;
    },
    setOnTheWay: (state, { payload }) => {
      state.onTheWay = payload;
    },
    setVoicemail: (state, { payload }) => {
      state.voicemails = payload;
    },
    setInternalTasks: (state, { payload }) => {
      state.internalTasks = payload;
    },
    setActionItems: (
      state,
      { payload }: PayloadAction<ActionItemsBadgesResponse>
    ) => {
      state.actionItems = {
        caseForms: payload.case_forms,
        orderFoods: payload.order_foods,
        medicalRefills: payload.medical_fefills,
      };
    },
  },
});

const {
  reducer: badgesReducer,
  actions: {
    setTasks,
    setOnTheWay,
    setVoicemail,
    setActionItems,
    setInternalTasks,
  },
} = badgesSlice;

const badgesStore = (store: RootState) => store?.badges;

const selectVoicemailBadge = createSelector(
  [badgesStore],
  (store) => store?.voicemails ?? 0
);

const selectActionItemsCasesBadge = createSelector(
  [badgesStore],
  (store) => store?.actionItems.caseForms ?? 0
);

const selectOnTheWayBadge = createSelector(
  [badgesStore],
  (store) => store?.onTheWay ?? 0
);

const selectTasksBadgeCount = createSelector(
  [badgesStore],
  (store) => store?.tasks ?? {}
);

const selectInternalTasksBadgeCount = createSelector(
  [badgesStore],
  (store) => store?.internalTasks ?? 0
);

export {
  setTasks,
  setOnTheWay,
  setVoicemail,
  badgesReducer,
  setActionItems,
  setInternalTasks,
  selectOnTheWayBadge,
  selectVoicemailBadge,
  selectTasksBadgeCount,
  selectActionItemsCasesBadge,
  selectInternalTasksBadgeCount,
};
