import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Routes } from 'config';
import { joinRoutes } from 'utils/helpers';

const { Home } = Routes.Office.Inbox.Chat;

const prefix = 'contact-';
const target = Home.replace('/', '');

export const getContactChatPath = (
  contactId: string | number,
  onlyLastComponent?: boolean
) => {
  const chatId = `${prefix}${contactId}`;
  if (onlyLastComponent) {
    return chatId;
  }
  return joinRoutes(
    [
      Routes.Office.Home,
      Routes.Office.Inbox.Home,
      Routes.Office.Inbox.Chat.Home,
      Routes.Office.Inbox.Chat.Details,
    ],
    { chatId }
  );
};

export const useChatParams = () => {
  const { chatId } = useParams();
  const { search, pathname } = useLocation();

  const { threadId, messageId } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      threadId: params.get('threadId'),
      messageId: params.get('messageId'),
    };
  }, [search]);

  const id = useMemo(() => {
    const pathes = pathname.split('/');
    const index = pathes.indexOf(target);
    return index !== -1 ? pathes[index + 1] : undefined;
  }, [pathname]);

  const isContactId = useMemo(
    () => !!chatId && chatId.startsWith(prefix),
    [chatId]
  );

  return {
    id,
    threadId,
    messageId,
    chatId: isContactId ? undefined : chatId,
    contactId: isContactId ? chatId?.replace(prefix, '') : undefined,
  };
};
