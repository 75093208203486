import { Call } from '@twilio/voice-sdk';
import { EntityState } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  Client,
  Nullable,
  TriageUser,
  ActionQueue,
  BaseResponse,
} from '@gv/triage-components';

import { TextOutPayload } from 'api/comm/types';
import { CommContextProps } from 'context/comm/types';
import { TextOutService, TextOutReferral } from 'utils/templates/types';
import {
  PetOwnerTemplate,
  MessageRecipientType,
  TransferMergeFormValues,
} from 'types';

export { PetOwnerTemplate, MessageRecipientType };

export interface DataProps {
  data: ActionQueue[];
}

export type ActionQueueResponse = BaseResponse<DataProps>;

export interface ActionQueueState extends EntityState<ActionQueue, string> {
  receiverId?: number;
  currentTaskId?: string;
  loadingTasks: string[];
  isTaskSwitching?: boolean;
  taskIdForResponse?: string;
  currentTasks: ActionQueue[];
  clientSetInProgress: string[];
  taskForms: Record<string, any>;
  callFlowForms: Record<string, any>;
  transferCallForm?: TransferMergeFormValues;
}

export interface AddTask {
  form?: any;
  task: ActionQueue;
}

export interface UpdateTaskBase {
  changes: Partial<ActionQueue>;
}

interface UpdateTaskWithCaseId extends UpdateTaskBase {
  caseId: number;
}

export type UpdateTask = UpdateTaskBase | UpdateTaskWithCaseId;

interface EndTaskBase {
  user?: TriageUser;
}

interface EndTaskWithCaseId extends EndTaskBase {
  caseId: number;
}

interface EndTaskWithTask extends EndTaskBase {
  task: ActionQueue;
}

export type EndTask = EndTaskWithTask | EndTaskWithCaseId;

export interface SetClientInProgressUpdate {
  task: ActionQueue;
  inProgress: boolean;
}

export enum PriorityTypeSort {
  High = 5,
  Medium = 4,
  Low = 3,
  DVM = 2,
  Escalate = 1,
}

export interface ResponseCommQueueProps extends CommContextProps {
  task: ActionQueue;
  isLocal?: boolean;
  isContinue?: boolean;
  navigate: NavigateFunction;
}

export interface SetCurrentTaskClient {
  client: Client;
}

export interface UpdateCaseForm {
  form: any;
  task: ActionQueue;
}

export interface TextOutBatchPayload
  extends Omit<
    TextOutPayload,
    'phone' | 'email' | 'roomId' | 'hospitalId' | 'hospitalName'
  > {
  phones?: string[];
  emails?: string[];
  service?: TextOutService;
  referrals?: TextOutReferral[];
}

export interface SMSBatchPayload {
  raw?: boolean;
  note?: string;
  dvmId?: number;
  message: string;
  roomId?: string;
  phones: string[];
  isFromSendErInfo?: boolean;
  type?: MessageRecipientType;
  petOwnerType?: PetOwnerTemplate;
}

export interface ContactsBatchResult {
  failed?: string;
  successful?: string;
}

export interface SetOnHoldAction {
  task: ActionQueue;
  isOnHold: boolean;
}

export interface CallDVMPayload {
  phone: string;
  dvmId?: Nullable<string>;
  dvmName?: Nullable<string>;
}

export interface OnHoldPayload {
  value?: boolean;
  transferring?: boolean;
}

export interface CallOnHoldPayload {
  call?: Call;
  setCall: (call?: Call) => void;
}

interface CallFlowForm {
  opened: number[];
  checked: Record<string, any>;
}

export interface UpdateCallFlowFormPayload {
  task: ActionQueue;
  form: CallFlowForm;
}

export interface TaskIsLoadingProps {
  task: ActionQueue;
  isLoading: boolean;
}

export const types = {
  textOut: 'tasks/textOut',
  sendSMS: 'tasks/sendSMS',
  callDVM: 'tasks/callDVM',
  setOnHold: 'tasks/setOnHold',
  responseCommQueue: 'tasks/responseCommQueue',
  setCurrentCallOnHold: 'tasks/setCurrentCallOnHold',
  setCurrentTaskClient: 'tasks/setCurrentTaskClient',
};
