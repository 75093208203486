import { Formik } from 'formik';
import {
  Col,
  Row,
  Dialog,
  isMobile,
  DialogType,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { SelectAssignee } from 'components';

import * as Styles from './styles';
import { FormValues, AssignToModalProps } from './types';
import { validationSchema, getInitialValues } from './config';

export const AssignToModal = ({
  open,
  onClose,
  onAssign,
  assignValues,
  assignOptions,
  assignToMeDisabled,
  assignSelectFieldProps,
}: AssignToModalProps) => {
  const mobile = isMobile();
  return (
    <Dialog
      open={open}
      title="Assign to"
      onClose={onClose}
      closeOnDocumentClick={false}
      width={mobile ? '90vw' : '480px'}
    >
      {(close, _, Buttons) => (
        <Formik<FormValues>
          validationSchema={validationSchema}
          initialValues={getInitialValues(assignValues)}
          onSubmit={(values) => {
            if (assignValues) {
              onAssign({
                id: assignValues.selectedTask,
                assigneeId: values.assignee ? +values.assignee.value : null,
              });
            }
            close();
          }}
        >
          {({ isSubmitting }) => (
            <Styles.Form>
              <Row>
                <Col>
                  <SelectAssignee
                    assignOptions={assignOptions}
                    assignToMeDisabled={assignToMeDisabled}
                    assignSelectFieldProps={assignSelectFieldProps}
                  />
                </Col>
              </Row>

              <Buttons type={DialogType.Plain}>
                <StandardButton
                  fullWidth
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.Secondary}
                />
                <StandardButton
                  fullWidth
                  text="Assign"
                  disabled={isSubmitting}
                  type={ButtonTypes.Submit}
                />
              </Buttons>
            </Styles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export type { AssignToModalProps };
