import { FormikValues } from 'formik';
import {
  Pet,
  Step,
  Utils,
  DFWidget,
  Hospital,
  DFOutcomeRule,
  FormCardTypes,
  CorporateType,
  FormCardRender,
  CallOutcomeAppointment,
} from '@gv/triage-components';

import { submitStepId } from 'utils/initials';
import {
  CorporateOptions,
  CallOutcomeEmergency,
  CallOutcomeDetailsType,
  CallOutcomeDeadlineIssue,
} from 'types';

const { mergeObjects, checkRuleCondition } = Utils.Helpers;

export const allNotAcceptableSpecies = 'all-not-acceptable';

export const getNextStepId = ({
  currentStep,
  passedStepsFieldValues,
  currentStepFieldValues,
}: {
  currentStep?: Step;
  passedStepsFieldValues: FormikValues;
  currentStepFieldValues: FormikValues;
}) => {
  const defaultStep = String(currentStep?.nextStep?.default);
  const rules = currentStep?.nextStep?.rules;
  if (!rules?.length) {
    return defaultStep;
  }
  const firstRule = rules.find((rule) =>
    checkRuleCondition({
      condition: rule.condition,
      values: mergeObjects(passedStepsFieldValues, currentStepFieldValues),
    })
  );

  return firstRule?.step ?? defaultStep;
};

export const getNextOutcomeId = ({
  ruleValues,
  outcomeRules,
}: {
  ruleValues: FormikValues;
  outcomeRules?: DFOutcomeRule[];
}) => {
  if (!outcomeRules?.length) {
    return undefined;
  }
  const rule = outcomeRules.find(
    ({ step, condition }) =>
      !!step &&
      checkRuleCondition({
        values: ruleValues,
        condition: { $and: condition?.map((option) => option.config) },
      })
  );

  return rule?.step?.value;
};

export const getSpeciesStepId = ({
  values,
  speciesRules,
  acceptableSpecies,
}: {
  values: FormikValues;
  acceptableSpecies: string[];
  speciesRules?: DFOutcomeRule[];
}) => {
  if (!speciesRules?.length) {
    return undefined;
  }
  const { pets, clientPets = [] } = values;
  const activePetsSpecies: string[] = [];
  pets?.forEach((petId: number) => {
    const species = clientPets.find((pet: Pet) => pet.id === petId)?.species;
    if (species) {
      activePetsSpecies.push(species);
    }
  });
  const hasAcceptableSpecies = activePetsSpecies.some((item) =>
    acceptableSpecies.includes(item)
  );

  if (hasAcceptableSpecies) {
    return undefined;
  }

  const rule = speciesRules.find(
    ({ step, condition }) =>
      !!step &&
      condition?.length &&
      (condition.some(
        (item) => String(item.config) === allNotAcceptableSpecies
      ) ||
        checkRuleCondition({
          values: activePetsSpecies,
          condition: {
            $or: condition.map((option) => option.config),
          },
        }))
  );

  return rule?.step?.value;
};

export const hasFieldType = (type: FormCardTypes, widget: DFWidget) =>
  widget.questions?.some((question: FormCardRender) =>
    question?.fields?.some((field) => field?.type === type)
  );

const hideSelectDepartmentOutcomes: string[] = [
  CallOutcomeEmergency.ReferToEr,
  CallOutcomeAppointment.Scheduled,
  CallOutcomeDeadlineIssue.DeadlineIssue,
];

export const getShowDepartmentSelect = ({
  hospital,
  allOutcomes,
  selectedStep,
}: {
  hospital?: Hospital;
  selectedStep?: Step;
  allOutcomes?: boolean;
}) => {
  if (!hospital || !selectedStep) {
    return false;
  }
  const isMainCorporate = hospital?.corporate_type === CorporateType.Main;
  const { outcome, nextStep, subOutcome, isOutcomeItem } = selectedStep;

  return Boolean(
    outcome &&
      subOutcome &&
      isMainCorporate &&
      (allOutcomes || !hideSelectDepartmentOutcomes.includes(subOutcome)) &&
      (!isOutcomeItem || (isOutcomeItem && nextStep?.default !== submitStepId))
  );
};

export const getCorporateOptionsByOutcome = ({
  selectedStep,
  corporateGroupOptions,
}: {
  selectedStep?: Step;
  corporateGroupOptions?: CorporateOptions;
}) => {
  const { outcome, subOutcome } = selectedStep ?? {};
  if (!outcome || !subOutcome || !corporateGroupOptions) {
    return undefined;
  }
  return corporateGroupOptions[subOutcome as CallOutcomeDetailsType];
};

export const getDepartmentsSelectPath = (selectedStep?: Step) => {
  const { outcome, subOutcome } = selectedStep ?? {};
  return `callOutcome.${outcome}.${subOutcome}.corporate_hospital_id`;
};
