import { useParams, generatePath } from 'react-router-dom';
import {
  List,
  Utils,
  ListCard,
  ListHeader,
  mapQueries,
  BackButton,
  useNavigate,
  EllipsisText,
  ListWithShowMore,
  useSearchPagination,
} from '@gv/triage-components';

import { Routes } from 'config';
import { CaseId } from 'components';
import { useAppSelector } from 'store';
import { useListQuery } from 'store/api/cases';
import { selectClient, useDetailsQuery } from 'store/api/clients';

import { PetFormPage } from '../types';

import { PetMedicalRecordsQueries } from './types';

const template = '3fr 3fr 2fr 3fr 3fr';

export const MedicalRecordsGlobal = ({
  clientId,
}: PetFormPage): JSX.Element => {
  const navigate = useNavigate();
  const { petId } = useParams();
  const pageData = useSearchPagination();

  const { currentPage: page, debounceQuery: search } = pageData;

  const { pet, client } = useAppSelector((state) => {
    const data = selectClient(clientId)(state);
    if (!data) {
      return {};
    }
    return {
      client: data,
      pet: data.pets.find((val) => val.id === Number(petId)),
    };
  });

  const hasData = !!client && !!pet;

  const { cases, ...props } = mapQueries<PetMedicalRecordsQueries>({
    client: useDetailsQuery({ id: clientId }, { skip: hasData }),
    cases: useListQuery({
      page,
      search,
      filters: {
        petId,
        clientId,
        type: 'pet',
      },
    }),
  });

  return (
    <List
      count={cases?.quantity ?? 0}
      topHeader={
        <BackButton
          text={Utils.Helpers.joinStrings(
            ['Medical Records', pet?.name],
            ' of '
          )}
        />
      }
      header={
        <ListHeader
          padding="8px 20px"
          template={template}
          titles={[
            'Case ID',
            'Handled by',
            'Record Type',
            'Issues',
            'Problem Summary',
          ]}
        />
      }
      {...props}
      {...pageData}
    >
      {cases?.cases.map((record) => {
        const date = Utils.Date.formatTimezoneSpecific(
          record.updated_at,
          record.client?.hospital?.timezone?.time_zone_code,
          'L LT'
        );

        const caseID = (
          <div>
            <CaseId bold caseId={record.id} />
            <EllipsisText>{date}</EllipsisText>
          </div>
        );

        const url = generatePath(Routes.Cases.Details.Home, {
          caseId: String(record.id),
        });

        return (
          <ListCard
            key={record.id}
            template={template}
            padding="16px 24px"
            onClick={() => navigate(url)}
            values={[
              caseID,
              record.updatedBy.name,
              record.pms_record_type ?? 'Medical case',
              <ListWithShowMore value={record.chief_complaints} />,
              record.problem_summary,
            ]}
          />
        );
      })}
    </List>
  );
};
