import { useMemo } from 'react';
import {
  Utils,
  UserInfo,
  useNavigate,
  ContactInfoParsed,
  AvatarPlaceholderType,
} from '@gv/triage-components';

import { Routes } from 'config';
import { MenuCard } from 'components';
import { joinRoutes } from 'utils/helpers';
import { useTalkHome, useChatNavigate, getContactChatPath } from 'hooks';

import { ContactOptionProps } from './types';

export const ContactOption = ({
  contact,
  isHeader,
  ...props
}: ContactOptionProps) => {
  const navigate = useNavigate();
  const chatNavigate = useChatNavigate();
  const home = useTalkHome();
  const data = Utils.Helpers.parseContact(contact) ?? ({} as ContactInfoParsed);
  const { id, role, profile_pic } = data;

  const contactPath = useMemo(
    () =>
      contact
        ? isHeader
          ? joinRoutes(
              [
                home,
                Routes.Office.Contacts.Home,
                Routes.Office.Contacts.Details,
              ],
              { id: contact.id }
            )
          : getContactChatPath(contact.id, true)
        : undefined,
    [home, isHeader, contact?.id]
  );

  return (
    <MenuCard
      onClick={
        contactPath
          ? () => {
              if (isHeader) {
                navigate(contactPath);
              } else {
                chatNavigate(contactPath);
              }
            }
          : undefined
      }
      values={[
        <UserInfo
          isHeader
          placeholder={AvatarPlaceholderType.UserAvatar}
          user={{
            id,
            imageUrl: profile_pic,
            role: role ?? undefined,
            name: Utils.Helpers.getChatUserName(data),
          }}
        />,
      ]}
      {...props}
    />
  );
};
