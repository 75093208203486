import {
  Link,
  Icon,
  Utils,
  ReachOut,
  FlexLayout,
  EllipsisText,
} from '@gv/triage-components';

import * as Styles from './styles';
import { OnCallDocInfoProps } from './types';

const { formatPhone, formatPrice, transformPhone } = Utils.Helpers;

export const OnCallDocInfo = ({ doc }: OnCallDocInfoProps) => {
  const { email, phone, hospitalOnCallInfo } = doc;
  const { etaOfDvm, erExamFees, phoneConsultFees, locationOfServices } =
    hospitalOnCallInfo || {};

  const hasLocationInfo = locationOfServices || etaOfDvm;
  const hasFees = erExamFees || phoneConsultFees;

  return (
    <FlexLayout gap={16} flexDirection="column">
      <Styles.Row>
        <div>
          {email && (
            <Styles.ContactInfo>
              <Icon.Mail />
              <Link ellipsis url={`mailto:${email}`} />
            </Styles.ContactInfo>
          )}
          <Styles.ContactInfo>
            <Icon.Phone />
            <EllipsisText>{formatPhone(transformPhone(phone))}</EllipsisText>
          </Styles.ContactInfo>
        </div>

        <ReachOut />
      </Styles.Row>

      {(hasLocationInfo || hasFees) && (
        <Styles.Row>
          {hasFees && (
            <Styles.InfoContainer>
              <Icon.USD />
              <FlexLayout gap={8} flexDirection="column">
                {erExamFees && (
                  <FlexLayout gap={16} alignItems="center">
                    <p>ER exam fee</p>
                    <Styles.DashedView />
                    <h3>{formatPrice(erExamFees)}</h3>
                  </FlexLayout>
                )}

                {phoneConsultFees && (
                  <FlexLayout gap={16} alignItems="center">
                    <p>Phone consultation fee</p>
                    <Styles.DashedView />
                    <h3>
                      {phoneConsultFees && !isNaN(Number(phoneConsultFees))
                        ? formatPrice(phoneConsultFees)
                        : phoneConsultFees}
                    </h3>
                  </FlexLayout>
                )}
              </FlexLayout>
            </Styles.InfoContainer>
          )}
          {hasLocationInfo && (
            <Styles.InfoContainer>
              <Icon.LocationOutlined />
              <div>
                {locationOfServices && (
                  <p className="semibold">{locationOfServices}</p>
                )}

                {etaOfDvm && <p>{`ETA of DVM - ${etaOfDvm}`}</p>}
              </div>
            </Styles.InfoContainer>
          )}
        </Styles.Row>
      )}
    </FlexLayout>
  );
};
