import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import { EventsResponse } from '../types';
import { generateItems } from '../helpers';

import {
  VisitHoursRequest,
  VisitsHoursResponse,
  VisitHourCreateRequest,
  VisitHourDeleteRequest,
} from './types';

const tagTypes = ['VisitHours'];

export const visitHoursApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'visitHoursApi',
  endpoints: (build) => ({
    delete: build.mutation<void, VisitHourDeleteRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, type, slot, hospitalId }) => ({
        method: 'post',
        url: URL.DELETE_VIRTUAL_VISIT,
        data: {
          availability_type: type,
          availability_id: String(id),
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
        },
      }),
    }),

    list: build.query<EventsResponse, VisitHoursRequest>({
      providesTags: tagTypes,
      transformResponse: (response: VisitsHoursResponse) =>
        generateItems(response?.data),
      query: ({ ids, end, type, start, hospitalId }) => {
        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(URL.GET_APPOINTMENT, {
            end_time: end,
            start_time: start,
            hospital_id: hospitalId,
            availability_type: type,
            with_appointments: true,
            dvm_user_id: JSON.stringify(ids),
          }),
        };
      },
    }),

    create: build.mutation<void, VisitHourCreateRequest>({
      invalidatesTags: tagTypes,
      query: ({
        slot,
        type,
        docId,
        every,
        endDate,
        startDate,
        hospitalId,
        clinicDays,
        ...props
      }) => ({
        method: 'post',
        url: URL.ADD_APPOINTMENT,
        data: {
          // required from backend
          month_day: '1',
          repeat_after: '1',
          day_position: '0',
          end_date: endDate,
          every: String(every),
          start_date: startDate,
          month_filter_type: '1',
          availability_type: type,
          dvm_user_id: String(docId),
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
          clinic_days: JSON.stringify(clinicDays ?? []),
          ...props,
        },
      }),
    }),
  }),
});

export { tagTypes as visitsHoursTags };
