import { cloneElement, isValidElement } from 'react';
import {
  Badge,
  Popup,
  Acceptance,
  BadgeColor,
  FlexLayout,
  BadgePadding,
  BadgeWithRef,
  TraumaLevelTitle,
  BadgeBorderRadius,
  TraumaLevelBadgeColor,
} from '@gv/triage-components';

import * as Styles from './styles';

const commonBadgeProps = {
  padding: BadgePadding.WideMedium,
  borderRadius: BadgeBorderRadius.Small,
};

export const AcceptanceBadges = ({ data }: { data?: Acceptance }) => {
  if (!data) {
    return null;
  }

  const { waitTime, traumaLevel, waitTimeRange, customWaitTimes } = data;

  const items = [];

  if (traumaLevel) {
    items.push({
      isActive: true,
      text: TraumaLevelTitle[traumaLevel],
      styleType: TraumaLevelBadgeColor[traumaLevel],
    });
  }

  if (waitTime) {
    if (waitTime === 'No wait times') {
      items.push({
        text: waitTime,
        styleType: BadgeColor.SecondaryCerulean,
      });
    } else if (waitTime === 'Other') {
      if (waitTimeRange) {
        items.push({
          styleType: BadgeColor.SecondaryCerulean,
          text: `Wait time: ${waitTimeRange[0]} - ${waitTimeRange[1]} hours`,
        });
      }

      if (customWaitTimes?.length) {
        items.push(
          <Popup
            on="hover"
            width="180px"
            trigger={
              <BadgeWithRef
                text={`+ ${customWaitTimes.length}`}
                styleType={BadgeColor.SecondaryCerulean}
                {...commonBadgeProps}
              />
            }
          >
            <Styles.CustomWaitTimesContainer>
              <FlexLayout gap={8}>
                <Styles.WaitTimesIcon />
                <h3>Wait times</h3>
              </FlexLayout>

              <FlexLayout
                gap={16}
                flexDirection="column"
                alignItems="flex-start"
              >
                {customWaitTimes.map(({ time, title }, index) => (
                  <Badge
                    key={index}
                    text={`${title} - ${time} h`}
                    styleType={BadgeColor.SecondaryCerulean}
                    {...commonBadgeProps}
                  />
                ))}
              </FlexLayout>
            </Styles.CustomWaitTimesContainer>
          </Popup>
        );
      }
    } else {
      items.push({
        text: `Wait time: ${waitTime}`,
        styleType: BadgeColor.SecondaryCerulean,
      });
    }
  }

  return (
    <>
      {items.map((badge, index) =>
        isValidElement(badge) ? (
          cloneElement(badge, { key: index })
        ) : (
          <Badge key={index} {...commonBadgeProps} {...badge} />
        )
      )}
    </>
  );
};
