import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from '.';

const RecordPlay = () => {
  const { value, state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      transparent
      padding="0"
      disabled={!value?.src}
      colorType={ButtonColors.Transparent}
      icon={state?.paused ? <Icon.Play /> : <Icon.Stop />}
      onClick={state?.paused ? controls?.play : controls?.pause}
    />
  );
};

export { RecordPlay };
