import { useAppSelector } from 'store';
import { selectAuthId, selectPermissions } from 'store/slices/auth';

import { useChatNavigate } from './useChatNavigate';

interface VirtualExamStartProps {
  chatId?: number;
  doctorId?: number;
  disabled?: boolean;
}

export const useVirtualExamStart = ({
  chatId,
  doctorId,
  disabled,
}: VirtualExamStartProps) => {
  const authUserId = useAppSelector(selectAuthId);
  const permissions = useAppSelector(selectPermissions);
  const chatNavigate = useChatNavigate();

  const isAvailable =
    !disabled && permissions.gvtalk && authUserId === doctorId && chatId;

  return {
    isAvailable,
    onStart: isAvailable ? () => chatNavigate(chatId) : undefined,
  };
};
