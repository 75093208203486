import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState, AppDispatch } from './store';

/**
 * A utility function to create typed asynchronous thunks.
 */
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  rejectValue: any;
  dispatch: AppDispatch;
}>();
