import styled from 'styled-components';
import { Styles, EllipsisText } from '@gv/triage-components';

export const Container = styled.div`
  min-width: 0;
`;

export const Address = styled(EllipsisText)`
  ${Styles.Font.captionRegular};
  color: ${({ theme }) => theme.config.input.placeholder};
`;
