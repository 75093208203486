import { Icon } from '@gv/triage-components';
import styled, { css } from 'styled-components';

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.red.primary.standard};
`;

export const ConditionListItem = styled.li`
  margin-left: 16px;
  list-style-type: disc;

  &:first-child {
    margin-top: 8px;
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const Pawprint = styled(Icon.PawprintFilled)`
  ${({ theme }) => css`
    path {
      fill: ${theme.colors.blue.primary.standard};
    }
  `}
`;
