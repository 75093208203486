import { EllipsisText } from '@gv/triage-components';

import * as Styles from './styles';
import { HospitalTitleProps } from './types';

export const HospitalTitle = ({
  name,
  address,
  className,
  nameClassName,
}: HospitalTitleProps) => {
  return (
    <Styles.Container className={className}>
      <EllipsisText className={nameClassName}>{name}</EllipsisText>
      {address && <Styles.Address>{address}</Styles.Address>}
    </Styles.Container>
  );
};
