import { useUpdateEffect } from 'react-use';
import { useState, useCallback } from 'react';
import { Call, Device } from '@twilio/voice-sdk';
import { Messages, useAlert, TriageUser } from '@gv/triage-components';

import { api } from 'api';
import { conversationName } from 'utils/helpers';

export const useTwilio = (user?: TriageUser) => {
  const { showErrorAlert } = useAlert(); // maybe change to warning
  const [twilioDevice, setTwilioDevice] = useState<Device | undefined>(
    undefined
  );
  const [isTwilioConnected, setTwilioConnected] = useState<boolean>(false);
  const [twilioError, setTwilioError] = useState<any>(undefined);

  const twilioName = user?.name?.replace(/\s+/g, '_').trim();

  const destroyTwilio = useCallback(() => {
    setTwilioError(undefined);
    if (twilioDevice) {
      console.log('disconnect all & destroy');
      const events = [
        Device.EventName.Error,
        Device.EventName.Registered,
        Device.EventName.TokenWillExpire,
      ];
      for (const event of events) {
        twilioDevice.removeAllListeners(event);
      }
      twilioDevice.disconnectAll();
      twilioDevice.destroy();
      setTwilioDevice(undefined);
    }
    setTwilioConnected(false);
  }, [twilioDevice]);

  useUpdateEffect(() => {
    console.log('destroy by name');
    destroyTwilio();
  }, [twilioName]);

  const connectTwilio = useCallback(async () => {
    if (twilioDevice && twilioDevice.state !== Device.State.Destroyed) {
      console.log('twilio device alredy exist', twilioDevice.state);
      return;
    }
    destroyTwilio();

    if (twilioName) {
      try {
        const token = await api.comm.getTwilioVoiceToken(twilioName);
        const device = new Device(token.data.data.token, {
          logLevel: 1,
          appName: 'Triage',
          closeProtection: true,
          tokenRefreshMs: 60000,
          codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        });
        setTwilioDevice(device);

        const refreshToken = async () => {
          if (twilioName) {
            try {
              const newToken = await api.comm.getTwilioVoiceToken(twilioName);
              device.updateToken(newToken.data.data.token);
            } catch (error) {
              console.error("can't update token", error);
            }
          }
        };

        device.on(Device.EventName.Registered, () => {
          setTwilioConnected(true);
        });
        device.on(Device.EventName.TokenWillExpire, async () => {
          console.log('twilio tokenWillExpire');
          await device.unregister();
          await device.register();
          await refreshToken();
        });
        device.on(Device.EventName.Error, (error) => {
          console.error('twilio error', error.code, error.message);
          const reportDeviceError = async () => {
            try {
              await api.comm.deviceError(
                { user_id: conversationName(user) },
                {
                  code: error.code,
                  message: error.message,
                  token:
                    error.message &&
                    error.message.toLowerCase().includes('token')
                      ? (device.token ?? 'null')
                      : undefined,
                }
              );
            } catch (err) {
              console.error(err);
            }
          };
          switch (error.code) {
            case 20101:
              reportDeviceError();
              refreshToken();
              showErrorAlert(error.message);
              break;
            case 20104:
              refreshToken();
              break;
            case 31203:
              setTwilioError(new Error(Messages.BALANCE_LOW_ERROR));
              break;

            case 31201:
            case 31208:
              reportDeviceError();
              showErrorAlert(error.message);
              break;

            case 31402:
              reportDeviceError();
              setTwilioError(new Error(Messages.ERROR_ACCESS_MICROPHONE));
              break;

            case 31000:
            case 31001:
            case 31002:
            case 53405:
            case 31004:
            case 31005:
            case 31006:
            case 31007:
            case 31008:
            case 31009:
              reportDeviceError();
              setTwilioError(new Error(Messages.INTERNET_CONNECTIVITY_ERROR));
              break;

            default:
              reportDeviceError();
              setTwilioError(undefined);
          }
          setTwilioConnected(false);
        });

        if (device.state !== Device.State.Registered) {
          device.register();
        }
      } catch (error) {
        setTwilioError(error);
      }
    }
  }, [twilioName, twilioDevice, destroyTwilio]);

  return {
    twilioError,
    twilioDevice,
    connectTwilio,
    destroyTwilio,
    isTwilioConnected,
    isTwilioSupported: Device.isSupported,
  };
};
