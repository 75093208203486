import { Message } from 'ably';
import { useCallback } from 'react';
import { useWrapRef } from '@gv/triage-components';

import { useAblyChannel, useAblyEventChannel } from 'hooks';

import { UseAutosubmitHandlerProps } from './types';

/**
 * Custom hook that manages the autosubmit event lifecycle using an Ably channel specific to a user.
 *
 * @remarks
 * This hook subscribes to 'started', 'stopped', and 'submitted' events on an Ably channel that
 * is named according to the user's ID. It processes messages related to the user's current task
 * and handles the task updates based on received messages.
 */
export const useAutosubmitHandler = ({
  userId,
  onStop,
  onStart,
  onSubmit,
}: UseAutosubmitHandlerProps) => {
  const onStartRef = useWrapRef((message: Message) => {
    const { caseId, willAutosubmitAt } = message.data || {};
    onStart({ caseId, willAutosubmitAt });
  });

  const onStopRef = useWrapRef((message: Message) => {
    const { caseId } = message.data || {};
    onStop({ caseId });
  });

  const onSubmitRef = useWrapRef((message: Message) => {
    const { caseId } = message.data || {};
    onSubmit({ caseId });
  });

  const channel = useAblyChannel(`autosubmit-timer:${userId}`);

  const onStartHandler = useCallback((message: Message) => {
    console.log('[autosubmit-timer] started', message.data);
    onStartRef.current(message);
  }, []);

  const onStopHandler = useCallback((message: Message) => {
    console.log('[autosubmit-timer] stopped', message.data);
    onStopRef.current(message);
  }, []);

  const onSubmitHandler = useCallback((message: Message) => {
    console.log('[autosubmit-timer] submitted', message.data);
    onSubmitRef.current(message);
  }, []);

  useAblyEventChannel('started', onStartHandler, channel);

  useAblyEventChannel('stopped', onStopHandler, channel);

  useAblyEventChannel('submitted', onSubmitHandler, channel);
};
