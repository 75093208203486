import { useState } from 'react';
import {
  Row,
  Info,
  Linkify,
  RadioGrid,
  PriorityType,
} from '@gv/triage-components';

import { assessmentsFieldsNames } from 'types/data';
import { ExpandableSection } from 'components/cases';
import { assessmentsRadioGridInputProps } from 'utils/config';

import * as Styles from '../styles';
import { Medications } from '../medications';
import { parseNotes, parseMedications } from '../helpers';

import { NotesProps, StabilityAssessmentProps } from './types';

const Notes = ({ index, medications }: NotesProps) => {
  const notes = index ? parseNotes(medications[index]) : [];

  if (notes.length === 0) {
    return null;
  }

  return (
    <Info
      noEllipsis
      title="Notes"
      value={
        <Linkify>
          {notes.map((note) => (
            <p key={note}>{note}</p>
          ))}
        </Linkify>
      }
    />
  );
};

export const StabilityAssessment = ({
  caseItem,
  isDynamicFlow,
}: StabilityAssessmentProps) => {
  const { medications_list, priority_of_concern, chronial_medical_issues } =
    caseItem;

  type SelectedType = number | undefined;
  const [selectedIndex, setSelectedIndex] = useState<SelectedType>(undefined);

  const medications = parseMedications(medications_list);
  const showAssessment =
    isDynamicFlow ||
    (priority_of_concern && priority_of_concern !== PriorityType.DVM);
  const hasAssessment = assessmentsFieldsNames.some(
    (fieldName) => !!caseItem[fieldName]
  );
  const hasMedications = medications.length > 0;

  if (
    (!hasMedications && !chronial_medical_issues && !hasAssessment) ||
    !showAssessment
  ) {
    return null;
  }

  return (
    <ExpandableSection
      defaultOpened
      withoutSpacing
      label="Stability assessment"
    >
      {showAssessment && hasAssessment && (
        <RadioGrid {...assessmentsRadioGridInputProps} values={caseItem} />
      )}

      {hasMedications && (
        <ExpandableSection
          defaultOpened
          withoutSpacing
          label="Medications/Food"
        >
          <Medications
            medications={medications}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />

          <Notes index={selectedIndex} medications={medications} />
        </ExpandableSection>
      )}

      {showAssessment &&
        chronial_medical_issues &&
        chronial_medical_issues.length > 0 && (
          <Row>
            <Styles.Col withoutMargin={isDynamicFlow}>
              <Info
                value={chronial_medical_issues}
                title="Any previous medical conditions?"
              />
            </Styles.Col>
          </Row>
        )}
    </ExpandableSection>
  );
};
