import styled from 'styled-components';
import { EllipsisText } from '@gv/triage-components';

export const Card = styled.div`
  min-width: 0;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.config.userInfo.text.gvSubtitle};
`;

export const Contact = styled(EllipsisText)`
  margin-top: 8px;
`;
