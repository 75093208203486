import { useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import {
  Row,
  Pet,
  Icon,
  Utils,
  BadgeColor,
  FlexLayout,
  showMessage,
  EllipsisText,
  ButtonColors,
  ShowMoreBadges,
  StandardButton,
  ButtonStyleTypes,
  FlexLayoutWithRef,
} from '@gv/triage-components';

import { CallTab } from 'types';
import { Routes } from 'config';
import { useAppSelector } from 'store';
import { selectCurrentTask } from 'store/api/action-queue/selectors';
import {
  petToString,
  getCaseSummary,
  getCaseFormSelectedPets,
} from 'utils/helpers';

import { useFormEntities } from '../use-form-entities';

import * as Styles from './styles';

export const CaseMainInfo = () => {
  const { values } = useFormikContext<FormikValues>();
  const task = useAppSelector(selectCurrentTask);
  const { client } = useFormEntities();
  const { pets, pet_id, isNameConfirm, chief_complaints } = values;

  const hasChiefComplaints = chief_complaints?.length > 0;

  const listPets = useMemo(
    () => getCaseFormSelectedPets(client?.pets, pets, pet_id),
    [client?.pets, pet_id, pets]
  );

  const onCopy = () => {
    navigator.clipboard.writeText(
      getCaseSummary({ task, client, full: true, form: values })
    );
    showMessage('Copied to clipboard');
  };

  return (
    <Styles.Container>
      <Row>
        <Styles.Col col={6}>
          <FlexLayout gap={4} minWidth={0} flexDirection="column">
            <Styles.Title>Client name</Styles.Title>
            <FlexLayout gap={8}>
              <EllipsisText as="h3">
                {Utils.Helpers.textValue(client?.name)}
              </EllipsisText>
              {isNameConfirm ? <Styles.CheckIcon /> : null}
            </FlexLayout>
          </FlexLayout>
        </Styles.Col>

        <Styles.Col col={6}>
          <ShowMoreBadges<Pet>
            items={listPets}
            renderBadgeText={petToString}
            renderListBadge={(badge) => (
              <FlexLayoutWithRef gap={4} minWidth={0} flexDirection="column">
                <Styles.Title>Pet info</Styles.Title>
                {badge}
              </FlexLayoutWithRef>
            )}
          />
        </Styles.Col>
      </Row>

      <Row>
        <Styles.Col col={6}>
          <ShowMoreBadges
            items={hasChiefComplaints ? chief_complaints : ['Not specified']}
            badgeProps={{
              styleType: hasChiefComplaints ? BadgeColor.Red : BadgeColor.Grey,
            }}
            renderListBadge={(badge) => (
              <FlexLayoutWithRef gap={4} minWidth={0} flexDirection="column">
                <Styles.Title>Chief complaint</Styles.Title>
                {badge}
              </FlexLayoutWithRef>
            )}
          />
        </Styles.Col>

        <Styles.Col col={6}>
          <StandardButton
            text="View full summary"
            colorType={ButtonColors.Secondary}
            to={`${Routes.ActionCenter.Comm}/${CallTab.Summary}`}
          />
        </Styles.Col>
      </Row>

      <Styles.CopyButton
        onClick={onCopy}
        icon={<Icon.Copy />}
        styleType={ButtonStyleTypes.Ghost}
      />
    </Styles.Container>
  );
};
