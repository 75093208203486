export const getBrowserState = () => {
  let outdated = false;
  try {
    // use api which crash app
    const obj = { a: 1 };
    Object.fromEntries(Object.entries(obj));
    structuredClone(obj);
  } catch {
    outdated = true;
  }
  return { outdated };
};
