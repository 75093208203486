import dayjs from 'dayjs';
import { useMemo, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  DVMUser,
  WorkingHoursUtils,
  ReferToOnCallAcceptacePath,
} from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { formatDoctorName } from 'utils/helpers';
import { useListScheduleQuery } from 'store/api/clinic-dvms';
import { selectOutboundIsBlocked } from 'store/api/action-queue/selectors';
import {
  useFormEntities,
  callOutcomeModel,
  CaseFormTextOutProps,
} from 'components/cases/form';

const { getHospitalTime } = WorkingHoursUtils;

const { referToOnCall } = callOutcomeModel.emergency;

export interface ReferToOnCallHook
  extends Pick<CaseFormTextOutProps, 'setDVM'> {
  referClientToER?: () => void;
}

export const useReferToOnCall = ({
  setDVM,
  referClientToER,
}: ReferToOnCallHook) => {
  const isCallBlocked = useAppSelector(selectOutboundIsBlocked);
  const { setFieldValue } = useFormikContext();
  const { hospital, hospitalId } = useFormEntities();
  const hospitalTime = hospital && getHospitalTime(hospital).time;
  const [referToOnCallDoctorId, , referToOnCallDoctorIdHelpers] = useField(
    referToOnCall.doctorId
  );

  const filterDocs = ({ doctorOnCallSchedule }: DVMUser) =>
    doctorOnCallSchedule &&
    hospitalTime &&
    doctorOnCallSchedule.timeSlots.some(([start, end]) =>
      hospitalTime.isBetween(dayjs(start), dayjs(end))
    );

  const { data: docs, ...props } = useListScheduleQuery(
    {
      all: true,
      hospitalId: hospitalId!,
    },
    {
      skip: !hospitalId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, ...docsProps }) => ({
        ...docsProps,
        data: data?.filter(filterDocs),
      }),
    }
  );

  const docsOptions = useMemo(
    () =>
      docs?.map((user) => ({
        id: String(user.id),
        value: String(user.id),
        label: formatDoctorName(user.name),
      })),
    [docs]
  );

  const selectedDoc = useMemo(
    () => docs?.find(({ id }) => String(id) === referToOnCallDoctorId.value),
    [docs, referToOnCallDoctorId.value]
  );

  const firstDocOptionValue = docsOptions?.[0]?.value;
  useEffect(() => {
    if (!firstDocOptionValue || !referToOnCallDoctorId.value) {
      return;
    }

    if (!referToOnCallDoctorId.value) {
      referToOnCallDoctorIdHelpers.setValue(firstDocOptionValue);
    }
  }, [firstDocOptionValue, referToOnCallDoctorId.value]);

  useEffect(() => {
    Object.values(ReferToOnCallAcceptacePath).forEach((name) =>
      setFieldValue(name, '')
    );
  }, [referToOnCallDoctorId.value]);

  const requiredFields = useMemo(() => {
    const result: string[] = [];
    const { erExamFees, speciesSeenOnCall, speciesSeenDuringBusinessHours } =
      selectedDoc?.hospitalOnCallInfo ?? {};

    if (erExamFees) {
      result.push(ReferToOnCallAcceptacePath.EmergencyFee);
    }
    if (speciesSeenOnCall) {
      result.push(ReferToOnCallAcceptacePath.SpeciesSeen);
    }
    if (speciesSeenDuringBusinessHours) {
      result.push(ReferToOnCallAcceptacePath.DuringBusiness);
    }

    return result;
  }, [selectedDoc?.hospitalOnCallInfo]);

  return {
    requiredFields,
    requestProps: props,
    onCallDocProps: {
      setDVM,
      docsOptions,
      selectedDoc,
      isRadio: true,
      isCallBlocked,
      referToOnCall,
      referClientToER,
      firstDocOptionValue,
      referToOnCallDoctorId,
      referToOnCallDoctorIdHelpers,
      onCallPath: Routes.Outbound.Home + Routes.Outbound.Tabs.Comm,
    },
  };
};
