import { useField } from 'formik';
import { useMemo, useEffect } from 'react';
import {
  Utils,
  DVMUser,
  SmsStatus,
  mapQueries,
  handleMutation,
  FormOptionsType,
} from '@gv/triage-components';

import { ChiefComplaint } from 'types';
import { useUpdateMutation } from 'store/api/clients';
import { complaintsOptionsFactory } from 'utils/helpers';
import { MedicationList } from 'store/api/medications/types';
import { useListScheduleQuery } from 'store/api/clinic-dvms';
import { useListQuery as useMedicationsQuery } from 'store/api/medications';
import { useListQuery as useChiefComplaintListQuery } from 'store/api/chief-complaints';

const { sortSelectOptions } = Utils.Helpers;

type Queries = {
  dvms: DVMUser[];
  chiefs: ChiefComplaint[];
  medications: MedicationList;
};

export const useDynamicOptions = ({ isTesting }: { isTesting?: boolean }) => {
  const [clientField] = useField('client_id');
  const [careHospitalField] = useField('care_hospital_id');
  const [notificationField, , notificationHelpers] = useField('sms_status');

  const careHospital = careHospitalField.value;
  const smsStatus = notificationField.value;

  const [updateClient, updateClientMutation] = useUpdateMutation();
  handleMutation({ ...updateClientMutation });

  useEffect(() => {
    if (isTesting) {
      return;
    }
    if (smsStatus && clientField.value) {
      updateClient({
        client_id: clientField.value,
        sms_status: notificationField.value as SmsStatus,
      });
    } else {
      notificationHelpers.setValue('');
    }
  }, [isTesting, notificationField.value]);

  const { dvms, chiefs, medications } = mapQueries<Queries>({
    chiefs: useChiefComplaintListQuery(),
    medications: useMedicationsQuery({}),
    dvms: useListScheduleQuery(
      { all: true, hospitalId: careHospital },
      { skip: !careHospital || careHospital.length === 0 }
    ),
  });

  return useMemo(
    () => ({
      [FormOptionsType.ChiefComplaint]: {
        options: complaintsOptionsFactory(chiefs)() ?? [],
      },
      [FormOptionsType.CareDoctorId]: {
        options: dvms
          ? sortSelectOptions(
              dvms.map((item) => ({
                value: String(item.id),
                label: item.name ?? String(item.id),
              }))
            )
          : [],
      },
      [FormOptionsType.MedicationList]: {
        options: medications?.rows?.length
          ? sortSelectOptions(
              medications.rows.map((item) => ({
                value: item.name,
                label: item.name,
                category: item.category,
              }))
            )
          : [],
      },
    }),
    [dvms, chiefs, medications]
  );
};
