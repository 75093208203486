import { ComponentProps } from 'react';
import { CheckboxFieldGroup } from '@gv/triage-components';

export const getCheckboxesItems = (
  values: Readonly<string[]>
): ComponentProps<typeof CheckboxFieldGroup>['items'] =>
  values.map((value) => ({
    value,
    label: value,
  }));
