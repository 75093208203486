import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { PayoutDetails } from 'types';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  PaymentStatus,
  CreatePaymentAccount,
  DetelePaymentRequest,
  DetailsPaymentRequest,
} from './types';

const { addParamsToUrl } = Utils.Query;

const tagTypes = ['Payments'];

export const paymentsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'paymentsApi',
  endpoints: (build) => ({
    update: build.mutation<void, CreatePaymentAccount>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.GET_PAYOUT_DETAILS,
      }),
    }),

    create: build.mutation<void, CreatePaymentAccount>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_PAYOUT_DETAILS,
      }),
    }),

    delete: build.mutation<void, DetelePaymentRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_PAYOUT_DETAILS, { id }),
      }),
    }),

    status: build.query<PaymentStatus, DetailsPaymentRequest>({
      providesTags: tagTypes,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_ACCOUNT_STATUS, {
          hospitalId,
        }),
      }),
    }),

    details: build.query<PayoutDetails, DetailsPaymentRequest>({
      providesTags: tagTypes,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: addParamsToUrl(URL.GET_PAYOUT_DETAILS, {
          hospitalId,
        }),
      }),
    }),
  }),
});

export const {
  useStatusQuery,
  useDetailsQuery,
  useCreateMutation,
  useDeleteMutation,
  useUpdateMutation,
} = paymentsApi;
