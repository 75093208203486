import {
  Member,
  FileType,
  ChatMessage,
  OfficeMessage,
  GVSendMessageValues,
} from '@gv/triage-components';

export const getLoadingFilesMock = (count: number) => {
  const media = { url: '', name: '', id: Math.random(), type: FileType.File };

  if (!count) {
    return {};
  }
  return {
    groupedMessages: Array(count).fill({ media, id: Math.random() }),
  };
};

export const getUploadMessage = (
  id: number,
  authMember: Member,
  { files, message }: GVSendMessageValues
): OfficeMessage => {
  const created = new Date().toString();
  return {
    id,
    chatId: 1,
    isRead: true,
    text: message,
    reactions: [],
    isPinned: false,
    isSystem: false,
    isSending: true,
    isDeleted: false,
    createdAt: created,
    updatedAt: created,
    author: authMember,
    authorId: Number(authMember?.id),
    ...getLoadingFilesMock(files?.length),
  };
};

export const getOfficeMessage = (msg: ChatMessage, authMember: Member) => {
  const {
    id,
    isError,
    mentions,
    msg_text,
    reactions,
    author_id,
    isDeleted,
    isSending,
    created_at,
    groupedMessages,
    displayAsHeader,
    groupedMessageId,
    threadedMessageId,
    threadedMessagesCount,
  } = msg;

  return {
    id,
    isError,
    mentions,
    reactions,
    isDeleted,
    isSending,
    text: msg_text,
    groupedMessages,
    groupedMessageId,
    threadedMessageId,
    author: authMember,
    authorId: author_id,
    createdAt: created_at,
    threadedMessagesCount,
    isSystem: displayAsHeader,
    updatedAt: new Date().toString(),
  };
};
