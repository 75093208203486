import { useField } from 'formik';
import { useEffect, useCallback } from 'react';
import {
  isMobile,
  Nullable,
  FlexLayout,
  InputField,
} from '@gv/triage-components';

import { getPaymentPrice, getStripeCentsFee } from 'utils/helpers';

import { PricesFieldProps, PriceFieldOnChangeValues } from './types';

const commonInputProps = {
  disabled: true,
  placeholder: '$0.00',
};

export const PricesField = ({
  name,
  gvFee,
  labels,
  disabled,
  onChange,
  isVirtualExam,
  isRequiredDeposit,
  gvFeeIncludedInPrice,
}: PricesFieldProps) => {
  const mobile = isMobile();
  const [{ value }] = useField<number | null>(name);

  const calculateFees = useCallback(
    (price?: Nullable<string | number>) => {
      if (!price) {
        onChange({
          gvFee: null,
          stripeFee: null,
          totalPrice: null,
          virtualExamFee: null,
        });
        return;
      }
      const {
        gvFeeValue,
        stripeFeeValue,
        totalPriceValue,
        virtualExamFeeValue,
      } = getPaymentPrice(+price, {
        gvFee,
        isVirtualExam,
        gvFeeIncludedInPrice,
        withDeposit: isRequiredDeposit,
      });
      onChange({
        gvFee: gvFeeValue,
        stripeFee: stripeFeeValue,
        totalPrice: +totalPriceValue,
        virtualExamFee: isVirtualExam ? virtualExamFeeValue : null,
      });
    },
    [isVirtualExam, isRequiredDeposit, gvFee, gvFeeIncludedInPrice]
  );

  useEffect(() => {
    calculateFees(value);
  }, [value, calculateFees]);

  return (
    <FlexLayout gap={16} flexWrap={mobile ? 'wrap' : 'nowrap'}>
      <InputField
        {...commonInputProps}
        number
        required
        name={name}
        disabled={disabled}
        label={labels.price}
        requiredText="(Required)"
      />

      <InputField
        {...commonInputProps}
        name="gvFee"
        label={labels.gv ?? `GV (${gvFee}%)`}
      />

      <InputField
        {...commonInputProps}
        name="stripeFee"
        label={
          labels.stripe ??
          `Stripe (2.9% + ${getStripeCentsFee(isRequiredDeposit)}¢)`
        }
      />

      {isVirtualExam && (
        <InputField
          {...commonInputProps}
          name="virtualExam"
          label={labels.virtualExam ?? 'Video-call fee'}
        />
      )}

      <InputField
        {...commonInputProps}
        name="totalPrice"
        label={labels.total ?? 'What the customer pays'}
      />
    </FlexLayout>
  );
};

export type { PriceFieldOnChangeValues };
