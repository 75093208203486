import styled, { css } from 'styled-components';
import { VideoCallAvatar } from '@gv/triage-components';

export const Viewport = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const VideoCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

export const VideoContainerSingle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarList = styled.ul`
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
`;

export const SingleVideoWrap = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  aspect-ratio: 16/9;
  position: relative;
`;

export const SingleViewAvatar = styled(VideoCallAvatar)`
  top: 0;
  left: 0;
`;

interface SelfVideoProps {
  show: boolean;
  single: boolean;
}

const baseSelfVideoStyle = css<SelfVideoProps>`
  position: absolute;
  width: 254px;
  height: 143px;
  top: 50px;
  right: 30px;
  z-index: 2;
  display: none;

  ${({ show, single }) => css`
    ${single &&
    css`
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    `}

    ${show &&
    css`
      display: block;
    `}
  `}
`;

export const SelfVideo = styled.video<SelfVideoProps>`
  ${baseSelfVideoStyle}
`;

export const SelfVideoCanvas = styled.canvas<SelfVideoProps>`
  ${baseSelfVideoStyle}
`;
