import { createApi } from '@reduxjs/toolkit/query/react';

import { mockContactsApiQuery } from 'store/mockQuery';

import { BulkMessageRequest } from './types';

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: mockContactsApiQuery,
  endpoints: (build) => ({
    bulkMessage: build.mutation<void, BulkMessageRequest>({
      query: (body) => {
        return {
          data: body,
          method: 'post',
          url: '/bulk-message',
        };
      },
    }),
  }),
});

export const { useBulkMessageMutation } = contactsApi;
