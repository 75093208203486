import debounce from 'lodash.debounce';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import { AblyChannelName, AblyMessageEvent } from '@gv/triage-components';

import { useAblyChannel, useAblyEventChannel } from 'hooks';
import { useLazyUnreadCountQuery } from 'store/api/gv-chats';
import { UnreadCountRequest } from 'store/api/gv-chats/types';

export const useUnreadCount = (props: UnreadCountRequest) => {
  const [trigger, data] = useLazyUnreadCountQuery();

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    lastRequest.current?.abort();
    const req = trigger(props);
    lastRequest.current = req;
  }, [props]);

  useEffect(refetch, [JSON.stringify(props)]);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  useEffect(() => {
    return () => debounceRefetch.cancel();
  }, [debounceRefetch]);

  const readChannel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(
    [AblyMessageEvent.Read, AblyMessageEvent.Created, AblyMessageEvent.Updated],
    debounceRefetch,
    readChannel
  );

  return { ...data, refetch };
};
