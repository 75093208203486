import styled, { css } from 'styled-components';
import {
  Styles,
  FlexLayout,
  EllipsisText,
  InputField as PhoneField,
  BackButton as TCBackButton,
} from '@gv/triage-components';

const { captionRegular, captionSemibold } = Styles.Font;

export const Contacts = styled(FlexLayout)`
  flex-direction: column;
  justify-content: center;
  height: 48px;
`;

export const ListWrap = styled.div`
  overflow-y: hidden;
`;

export const CaptionRegular = styled(EllipsisText)`
  ${captionRegular};
`;

export const CasesWrap = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

export const BackButton = styled(TCBackButton)`
  button {
    padding: 12px 12px 12px 0;
  }
`;

export const InputField = styled(PhoneField)`
  > p {
    position: absolute;
    bottom: -22px;
  }
`;

export const StyledTitle = styled.h3`
  margin-bottom: 16px;
`;

export const AgeField = styled(PhoneField)<{ showError: boolean }>`
  ${({ showError }) =>
    showError &&
    css`
      > p {
        display: none;
      }
    `};
`;

export const Date = styled.span`
  ${captionSemibold};
`;
