import {
  ActionQueue,
  BaseResponse,
  ActionQueueType,
} from '@gv/triage-components';

import {
  Case,
  Addendum,
  DiscardType,
  PaginationRequest,
  CasesFilterValues,
} from 'types';

export type { Case };

export type TimeOptions = 'lt' | 'gt';

export interface CaseData {
  cases: Case[];
  quantity: number;
}

export type CaseResponse = BaseResponse<CaseData>;

export type CaseListType =
  | 'pet'
  | 'all'
  | 'mine'
  | 'client'
  | 'discarded'
  | 'incomplete';

export interface CaseFilters extends CasesFilterValues {
  petId?: string;
  clientId?: string;
  type?: CaseListType;
  holdTimeOptions?: TimeOptions;
  totalTimeOptions?: TimeOptions;
}

export type HoldTimeRequest = Pick<CasesFilterValues, 'holdTime'> &
  Pick<CaseFilters, 'holdTimeOptions'>;
export type TotalTimeRequest = Pick<CasesFilterValues, 'totalTime'> &
  Pick<CaseFilters, 'totalTimeOptions'>;

export interface CaseRequest extends PaginationRequest {
  search?: string;
  filters?: CaseFilters;
}

/**
 * Interface for the properties required to create or update a case.
 */
export interface CreateUpdateCase {
  /**
   * The values to be updated or created in the case.
   */
  values: any;

  /**
   * Optional flag to perform the action silently without updating redux form.
   */
  silent?: boolean;

  /**
   * The task associated with the case update or creation.
   */
  task: ActionQueue;

  /**
   * Optional flag to hide the loading indicator.
   */
  hideLoading?: boolean;

  /**
   * Optional flag indicating if the case has been submitted.
   */
  isSubmitted?: boolean;

  /**
   * Optional flag indicating if the client has completed the case.
   */
  isClientDone?: boolean;

  /**
   * Optional flag to indicate if the case is dynamic flow.
   */
  isDynamicFlow?: boolean;
}

export interface GetLatelySubmittedRequest {
  search?: string;
  hospitalId: string;
  phoneNumber: string;
}

export interface GetAllCasesRequest extends PaginationRequest {
  type?: string;
  petId?: string;
  clientId?: string;
  hospitalId?: string;
  searchString?: string;
}

export type GetAllCasesResponse = BaseResponse<Case[]>;

interface CaseDetails {
  caseForm?: Case;
  addenda?: Addendum[];
}

export interface AddendumRequest {
  id: number;
  message: string;
}

export type CaseCreateResponse = BaseResponse<Case>;
export type CaseDetailsResponse = BaseResponse<CaseDetails>;
export type AddendumResponse = BaseResponse<Addendum>;
export type CasePDFResponse = BaseResponse<string>;

export interface CaseDiscardRequest {
  to?: string;
  note?: string;
  from?: string;
  other?: string;
  commId?: string;
  caseId?: number;
  type: DiscardType;
  hospitalId?: number;
  commType?: ActionQueueType;
}

export enum CasePayloadType {
  Create = 'create',
  Update = 'update',
  Submit = 'submit',
}

export interface WrongTriageRequest {
  text: string;
  caseId: number;
  phones: string[];
}

export const types = {
  createCase: 'cases/createCase',
  updateCase: 'cases/updateCase',
  submitCase: 'cases/submitCase',
  createUpdateSubmit: 'cases/createUpdateSubmit',
};
