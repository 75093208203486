import {
  Icon,
  Link,
  Utils,
  FlexLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { AudioPlayer } from 'components/audio-player';
import * as CommonStyles from 'components/communication-log/styles';

import { SelectedEventPageProps } from '../types';

import * as Styles from './styles';

export const CallEvent = ({
  event,
  onClose,
  relatedEvents,
  onSelectEvent,
}: SelectedEventPageProps): JSX.Element => {
  const recordingUrl =
    event.details && 'recording' in event.details && event.details.recording;

  return (
    <>
      <CommonStyles.Header justifyContent="space-between">
        <FlexLayout gap={8}>
          <StandardButton
            padding="0"
            onClick={onClose}
            icon={<Icon.ChevronLeft />}
            colorType={ButtonColors.Transparent}
          />

          <div>
            <h3>Call {event.callId}</h3>
            <p className="caption-regular">
              {Utils.Date.formatDate(event.timestamp, 'MMMM D, YYYY LT')}
            </p>
          </div>
        </FlexLayout>

        {recordingUrl && (
          <Link
            download
            withoutButton
            text="Download"
            url={recordingUrl}
            className="semibold"
          />
        )}
      </CommonStyles.Header>

      <CommonStyles.EventContent>
        <FlexLayout gap={24} flexDirection="column">
          {recordingUrl && (
            <AudioPlayer src={recordingUrl}>
              <AudioPlayer.PlayerContainer>
                <AudioPlayer.Player />
              </AudioPlayer.PlayerContainer>
            </AudioPlayer>
          )}
          {relatedEvents && relatedEvents?.length > 0 && (
            <Styles.Events events={relatedEvents} onClick={onSelectEvent} />
          )}
        </FlexLayout>
      </CommonStyles.EventContent>
    </>
  );
};
