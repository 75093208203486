import { useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import { useAppDispatch, useAppSelector } from 'store';
import { getSelectedCallOutcome } from 'utils/helpers';
import { init, selectCurrentStep } from 'store/slices/case-form-step';

import { useFormEntities } from '../use-form-entities';

import { getInitialStepState } from './helpers';

export const useCaseFormSteps = (hasTask: boolean) => {
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<FormikValues>();
  const { formId } = useFormEntities();
  const currentStep = useAppSelector(selectCurrentStep(formId));

  const selectedCallOutcome = getSelectedCallOutcome(values.callOutcome);

  useEffect(() => {
    if (!currentStep) {
      dispatch(
        init({
          formId,
          initialState: getInitialStepState(
            values.current_step,
            values.max_opened_step,
            hasTask
          ),
        })
      );
    }
  }, [
    formId,
    hasTask,
    currentStep,
    values.current_step,
    values.max_opened_step,
    selectedCallOutcome?.subType,
  ]);
};
