import { Call, Device } from '@twilio/voice-sdk';
import { Messages, ChildrenProps, CommStatusEnum } from '@gv/triage-components';
import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import { CallStatus } from 'types';
import { CommContext } from 'context/comm';
import { selectAuthUser } from 'store/slices/auth';
import { setOutboundCall } from 'store/slices/call';
import { useAppSelector, useAppDispatch } from 'store';
import { useCallStatus, disconnectCallOnUnmount } from 'hooks';
import { selectOutboundIsBlocked } from 'store/api/action-queue/selectors';

import { OutboundContextProps } from './types';

const getInitialState = (): OutboundContextProps => ({
  isReady: false,
  call: () => {},
  inProgress: false,
  disabledButtons: [],
  disconnect: () => {},
  setActiveCall: () => {},
});

export const OutboundContext =
  createContext<OutboundContextProps>(getInitialState());

export const OutboundContextProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthUser);
  const isOutboundBlocked = useAppSelector(selectOutboundIsBlocked);
  const [activeCall, setActiveCall] = useState<Call>();
  disconnectCallOnUnmount(activeCall);

  const { twilioDevice, status: commStatus } = useContext(CommContext);

  const { status, startedAt, inProgress, disabledButtons } =
    useCallStatus(activeCall);

  const isReady = commStatus === CommStatusEnum.Ready;
  const hasOutboundCall = !!activeCall;

  useEffect(() => {
    dispatch(setOutboundCall(hasOutboundCall));
  }, [dispatch, hasOutboundCall]);

  const call = useCallback(
    async (phone: string) => {
      if (!twilioDevice || twilioDevice.state !== Device.State.Registered) {
        return Promise.reject(
          new Error(Messages.STILL_TWILIO_DEVICE_SETUP_IS_NOT_DONE)
        );
      }
      if (isOutboundBlocked) {
        return Promise.reject(new Error(Messages.OUTBOUND_BLOCKED_BY_CALL));
      }
      if (hasOutboundCall) {
        return Promise.reject(new Error(Messages.OUTBOUND_CALL_IN_PROGRESS));
      }

      const newCall = await twilioDevice.connect({
        params: {
          To: phone,
          type: 'outgoing',
          user_id: String(user!.id),
          hospital_id: String(user!.hospital_id),
        },
      });
      console.log('connect to outbound call');
      setActiveCall(newCall);
    },
    [
      user?.id,
      twilioDevice,
      hasOutboundCall,
      user?.hospital_id,
      isOutboundBlocked,
    ]
  );

  const disconnect = useCallback(() => {
    if (activeCall) {
      activeCall?.disconnect();
      console.log('disconnect outbound call');
      setActiveCall(undefined);
    }
  }, [activeCall]);

  useEffect(() => {
    if (status === CallStatus.Completed) {
      disconnect();
    }
  }, [status, disconnect]);

  return (
    <OutboundContext.Provider
      value={{
        call,
        status,
        isReady,
        startedAt,
        disconnect,
        activeCall,
        inProgress,
        setActiveCall,
        disabledButtons,
      }}
    >
      {children}
    </OutboundContext.Provider>
  );
};
