import { useMemo } from 'react';
import { FlexLayout, InputField, RadioFieldGroup } from '@gv/triage-components';

import { AppointmentDeclinedReason } from 'types';
import { selectOptionsWithNone } from 'utils/helpers';
import { AppointmentDeclinedReasonTitle } from 'types/data';

import { callOutcomeModel } from '../../config';

const { appointment } = callOutcomeModel;
const fieldName = appointment.declined.reason;

export const DeclinedReason = () => {
  const items = useMemo(
    () =>
      selectOptionsWithNone(
        Object.values(AppointmentDeclinedReason).map((value) => ({
          value,
          label: AppointmentDeclinedReasonTitle[value],
        }))
      ),

    []
  );

  return (
    <>
      <FlexLayout flexDirection="column">
        <RadioFieldGroup
          transparent
          items={items}
          name={fieldName}
          label="Select reason"
          grid={{ gap: '0', template: '100% 100% 100%' }}
        />
      </FlexLayout>

      <InputField
        label="Notes"
        placeholder="Add note"
        name="additional_notes"
        type="textarea-autosize"
      />
    </>
  );
};
