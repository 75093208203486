import {
  UserRole,
  UserStatus,
  MockHandler,
  UserRoleType,
} from '@gv/triage-components';

import { ExploreUser } from 'types';
import { ExploreListPayload } from 'store/api/explore/types';

const mockUsers: ExploreUser[] = [
  {
    id: 1,
    location: 'Alabama',
    name: 'Jacob Jones',
    status: UserStatus.Online,
    role: UserRole[UserRoleType.DVM],
    address: '2464 Royal Ln. Mesa, New Jersey 45463',
    specialities: ['Cardiology', 'End of life care', 'Avian'],
  },
  {
    id: 2,
    location: 'Alaska',
    name: 'Wade Warren',
    status: UserStatus.Break,
    role: UserRole[UserRoleType.DVM],
    address: '4140 Parker Rd. Allentown, New Mexico 31134',
    specialities: ['Cardiology', 'End of life care', 'Beef & Cattle'],
  },
  {
    id: 3,
    location: 'Arkansas',
    name: 'Darrell Steward',
    status: UserStatus.Break,
    role: UserRole[UserRoleType.DVM],
    specialities: ['End of life care'],
    address: '8502 Preston Rd. Inglewood, Maine 98380',
  },
  {
    id: 4,
    location: 'Alaska',
    name: 'Courtney Henry',
    status: UserStatus.Online,
    specialities: ['Cardiology'],
    role: UserRole[UserRoleType.DVM],
    address: '3517 W. Gray St. Utica, Pennsylvania 57867',
  },
  {
    id: 5,
    location: 'Alabama',
    name: 'Cody Fisher',
    status: UserStatus.Offline,
    role: UserRole[UserRoleType.DVM],
    address: '2464 Royal Ln. Mesa, New Jersey 45463',
    specialities: ['Cardiology', 'End of life care', 'Beef & Cattle'],
  },
  {
    id: 6,
    location: 'Alabama',
    name: 'Dianne Russell',
    status: UserStatus.Break,
    role: UserRole[UserRoleType.DVM],
    specialities: ['End of life care', 'Avian'],
    address: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 7,
    location: 'Arkansas',
    name: 'Esther Howard',
    status: UserStatus.Online,
    role: UserRole[UserRoleType.DVM],
    specialities: ['Cardiology', 'End of life care'],
    address: '2118 Thornridge Cir. Syracuse, Connecticut 35624',
  },
  {
    id: 8,
    location: 'Arizona',
    status: UserStatus.Online,
    name: 'Darlene Robertson',
    role: UserRole[UserRoleType.DVM],
    specialities: ['End of life care', 'Avian'],
    address: '6391 Elgin St. Celina, Delaware 10299',
  },
  {
    id: 9,
    name: 'Robert Fox',
    location: 'Arkansas',
    status: UserStatus.Break,
    specialities: ['Cardiology'],
    role: UserRole[UserRoleType.DVM],
    address: '3891 Ranchview Dr. Richardson, California 62639',
  },
  {
    id: 10,
    location: 'Alaska',
    name: 'Marvin McKinney',
    status: UserStatus.Offline,
    role: UserRole[UserRoleType.DVM],
    specialities: ['Cardiology', 'End of life care'],
    address: '2715 Ash Dr. San Jose, South Dakota 83475',
  },
  {
    id: 11,
    location: 'Alabama',
    name: 'Brooklyn Simmons',
    status: UserStatus.Online,
    role: UserRole[UserRoleType.DVM],
    address: '2972 Westheimer Rd. Santa Ana, Illinois 85486',
    specialities: ['Cardiology', 'End of life care', 'Beef & Cattle'],
  },
  {
    id: 12,
    location: 'Alaska',
    name: 'Floyd Miles',
    status: UserStatus.Offline,
    role: UserRole[UserRoleType.DVM],
    specialities: ['Cardiology', 'End of life care'],
    address: '4517 Washington Ave. Manchester, Kentucky 39495',
  },
  {
    id: 13,
    location: 'Alabama',
    name: 'Alex Garter',
    status: UserStatus.Online,
    role: UserRole[UserRoleType.DVM],
    specialities: ['End of life care'],
    address: '2464 Royal Ln. Mesa, New Jersey 45463',
  },
  {
    id: 14,
    name: 'Jim Hanks',
    location: 'Arkansas',
    status: UserStatus.Break,
    role: UserRole[UserRoleType.DVM],
    specialities: ['Cardiology', 'Avian'],
    address: '4140 Parker Rd. Allentown, New Mexico 31134',
  },
  {
    id: 15,
    name: 'Tom Pole',
    location: 'Alaska',
    status: UserStatus.Online,
    role: UserRole[UserRoleType.DVM],
    address: '6391 Elgin St. Celina, Delaware 10299',
    specialities: ['Cardiology', 'End of life care', 'Beef & Cattle'],
  },
];

const filterUsersByFields = ({
  common,
  location,
  speciality,
  isFavorites,
}: Omit<ExploreListPayload, 'page' | 'search' | 'pageSize'>) => {
  const data = isFavorites ? mockUsers.slice(0, 10) : mockUsers;
  return !!speciality?.length || !!location?.length || !!common.length
    ? data.filter(
        (user) =>
          speciality?.some((spec) => user.specialities.includes(spec)) ||
          location?.includes(user.location) ||
          [user.status, user.location].some((prop) =>
            common.includes(prop as string | UserStatus)
          )
      )
    : data;
};

const filterUsersBySearch = (users: ExploreUser[], search: string) =>
  users.filter(({ name, address }) =>
    [name, address].some((field) =>
      field.toLowerCase().includes(search.toLowerCase())
    )
  );

const filterUsers = ({
  search,
  ...fieldsFilters
}: Omit<ExploreListPayload, 'page' | 'pageSize'>) => {
  const filteredByFields = filterUsersByFields(fieldsFilters);

  return filterUsersBySearch(filteredByFields, search);
};

const pagination = (users: ExploreUser[], page: number, perPage: number) =>
  users.slice((page - 1) * perPage, page * perPage);

const getExploreListData = ({
  page,
  pageSize = 12,
  ...filterData
}: ExploreListPayload) => {
  const filteredUsers = filterUsers(filterData);

  return {
    count: filteredUsers.length,
    users: pagination(filteredUsers, page, pageSize),
  };
};

export const exploreMockApiHandlers: MockHandler[] = [
  {
    url: '/users',
    method: 'get',
    responseFunc: ({ data }) => ({
      data: getExploreListData(data),
    }),
  },
];
