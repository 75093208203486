import { mockBaseQuery } from '@gv/triage-components';

import { exploreMockApiHandlers } from '../api/explore/config';
import { contactsMockApiHandlers } from '../api/contacts/config';
import { marketingMockApiHandlers } from '../api/marketing/config';
import { billingMockApiHandlers } from '../api/mock-billing/config';
import { officeChatsMockApiHandlers } from '../api/office-chats/config';

export const mockContactsApiQuery = mockBaseQuery({
  handlers: contactsMockApiHandlers,
});

export const mockExploreApiQuery = mockBaseQuery({
  handlers: exploreMockApiHandlers,
});

export const mockOfficeChatsApiQuery = mockBaseQuery({
  handlers: officeChatsMockApiHandlers,
});

export const mockMarketingApiQuery = mockBaseQuery({
  handlers: marketingMockApiHandlers,
});

export const mockBillingApiQuery = mockBaseQuery({
  handlers: billingMockApiHandlers,
});
