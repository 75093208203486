import { Call } from '@twilio/voice-sdk';
import { useRef, useEffect } from 'react';

export const disconnectCallOnUnmount = (call?: Call) => {
  const callRef = useRef(call);

  useEffect(() => {
    callRef.current = call;
  }, [call]);

  useEffect(() => {
    return () => {
      if (callRef.current) {
        console.log(
          'disconnect call on unmount',
          callRef.current?.parameters.CallSid
        );
      }
      callRef.current?.disconnect();
    };
  }, []);
};
