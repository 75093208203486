import { Formik, FormikValues } from 'formik';
import {
  Dialog,
  IdObject,
  DialogType,
  InputField,
  ButtonTypes,
  DialogProps,
  ButtonColors,
  StandardButton,
  handleMutation,
} from '@gv/triage-components';

import { useCreateAddendumMutation } from 'store/api/cases';

import * as Styles from './styles';
import { initialValues, validationSchema } from './config';

export const CreateAddendumDialog = ({
  id,
  open,
  onClose,
}: IdObject<number | undefined> & Pick<DialogProps, 'open' | 'onClose'>) => {
  const [create, mutation] = useCreateAddendumMutation();
  handleMutation({ ...mutation, onSuccess: onClose });

  const onSubmit = ({ message }: FormikValues) => create({ id: id!, message });

  return (
    <>
      <Dialog open={open} onClose={onClose} title="Addend case">
        {(close: () => void, Text, Buttons) => (
          <Formik
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ dirty, isSubmitting }) => (
              <Styles.Form>
                <Text type={DialogType.Plain}>
                  <InputField
                    minRows={5}
                    name="message"
                    type="textarea-autosize"
                    placeholder="Add addendum"
                  />
                </Text>

                <Buttons type={DialogType.Plain}>
                  <StandardButton
                    text="Cancel"
                    onClick={() => close()}
                    colorType={ButtonColors.Secondary}
                  />

                  <StandardButton
                    text="Submit"
                    type={ButtonTypes.Submit}
                    colorType={ButtonColors.Primary}
                    disabled={!dirty || isSubmitting}
                  />
                </Buttons>
              </Styles.Form>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
};
