import { Utils } from '@gv/triage-components';

import { URL } from '../constants';
import { apiInstance } from '../instance';

import { TasksCountRequest } from './types';

const { addParamsToUrl } = Utils.Query;

export const getHospitalTasksCount = ({
  hospitalId,
  classes = [],
}: TasksCountRequest) =>
  apiInstance.get(
    addParamsToUrl(URL.TASKS_COUNT_BY_CLASSES, {
      hospitalId,
      classes: classes.join(','),
    })
  );
