import styled, { css } from 'styled-components';
import {
  Styles,
  EllipsisText,
  ListHeader as TCListHeader,
} from '@gv/triage-components';

import { SchedulingMatrixTableProps } from './types';

const { bodySemibold } = Styles.Font;

export const Ellipsis = styled(EllipsisText)<{
  bold?: boolean;
}>`
  ${({ bold }) => css`
    p {
      ${bold && bodySemibold};
    }
  `}
`;

export const Container = styled.div`
  width: 200px;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
`;

export const ListHeader = styled(TCListHeader)<
  Pick<SchedulingMatrixTableProps, 'widgetView'>
>`
  ${({ theme, widgetView }) =>
    widgetView &&
    !theme.responsive.isMobile &&
    css`
      margin: 0;
      min-width: 900px;
    `};
`;
