import {
  Icon,
  FlexLayout,
  ButtonColors,
  ChildrenProps,
  StandardButton,
  StandardButtonProps,
} from '@gv/triage-components';

export interface FollowUpCellProps extends ChildrenProps<StandardButtonProps> {
  onCall?: () => void;
  onChat?: () => void;
  onMail?: () => void;
  hasCenterAlign?: boolean;
}

export const FollowUpCell = ({
  onChat,
  onMail,
  onCall,
  children,
  hasCenterAlign = true,
  ...props
}: FollowUpCellProps) => {
  return (
    <FlexLayout gap={16} justifyContent={hasCenterAlign ? 'center' : undefined}>
      {onCall && (
        <StandardButton
          {...props}
          disabled
          onClick={onCall}
          icon={<Icon.Call />}
          colorType={ButtonColors.Secondary}
        />
      )}

      <StandardButton
        {...props}
        disabled={!onChat}
        icon={<Icon.Chat />}
        colorType={ButtonColors.Secondary}
        onClick={(e) => {
          e.stopPropagation();
          onChat?.();
        }}
      />

      {onMail && (
        <StandardButton
          {...props}
          disabled
          onClick={onMail}
          icon={<Icon.MailEmpty />}
          colorType={ButtonColors.Secondary}
        />
      )}
      {children}
    </FlexLayout>
  );
};
