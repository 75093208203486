import { Icon, Messages, ActionQueueType } from '@gv/triage-components';

export const title = {
  [ActionQueueType.SMS]: 'Complete chat',
  [ActionQueueType.Chat]: 'Complete chat',
  [ActionQueueType.Voice]: 'Complete call',
};

export const icon = {
  [ActionQueueType.SMS]: <Icon.ChatCompleted />,
  [ActionQueueType.Chat]: <Icon.ChatCompleted />,
  [ActionQueueType.Voice]: <Icon.CallCompleted />,
};

export const text = {
  [ActionQueueType.SMS]: Messages.COMPLETE_CHAT,
  [ActionQueueType.Chat]: Messages.COMPLETE_CHAT,
  [ActionQueueType.Voice]: Messages.COMPLETE_CALL,
};

export const completeText = {
  [ActionQueueType.SMS]: 'Complete chat',
  [ActionQueueType.Chat]: 'Complete chat',
  [ActionQueueType.Voice]: 'Complete call',
};

export const completeAndSubmitText = {
  [ActionQueueType.SMS]: Messages.COMPLETE_CHAT_AND_SUBMIT,
  [ActionQueueType.Chat]: Messages.COMPLETE_CHAT_AND_SUBMIT,
  [ActionQueueType.Voice]: Messages.COMPLETE_CALL_AND_SUBMIT,
};
