import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import {
  Info,
  Link,
  Utils,
  Badge,
  isMobile,
  AddButton,
  BadgeColor,
  useNavigate,
  GeneralInfo,
} from '@gv/triage-components';

import { CaseId } from 'components';
import { Config, Routes } from 'config';
import { clientAddress } from 'utils/helpers';

import * as Styles from './styles';
import { ClienDetailsProps } from './types';

const {
  Helpers: { formatPhone },
  Date: { formatTimezoneCode },
} = Utils;

export const ClientDetails = ({
  data,
  onEdit,
  sections,
  hasEditPermission = false,
}: ClienDetailsProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();
  const { isPetOwner } = Config.portalType;

  const {
    id,
    name,
    email,
    emails,
    phones,
    timezone,
    pets = [],
    home_phones,
    last_case_id,
  } = data || {};

  const getPhoneNumbersRow = () => {
    const result = [];

    if (phones && phones.length) {
      result.push({
        col: 4,
        colTablet: 6,
        title: 'Mobile phone',
        value: (
          <ul>
            {phones?.map((value, idx) => (
              <li key={`mobile-${value}-${idx}`}>
                {formatPhone(String(value))}
              </li>
            ))}
          </ul>
        ),
      });
    }

    if (home_phones && home_phones.length) {
      result.push({
        col: 4,
        colTablet: 6,
        title: 'Home phone',
        value: (
          <>
            {home_phones?.map((value, idx) => (
              <li key={`mobile-${value}-${idx}`}>
                {formatPhone(String(value))}
              </li>
            ))}
          </>
        ),
      });
    }

    return result;
  };

  const getLinksList = (): JSX.Element | undefined => {
    let links: string[] = email ? [email] : [];
    if (Array.isArray(emails)) {
      links = Array.from(new Set(links.concat(emails)));
    }

    if (links.length > 0) {
      return (
        <ul>
          {links.map((link, indx) => (
            <li key={`link-${indx}`}>
              <Link ellipsis url={`mailto:${link}`} />
            </li>
          ))}
        </ul>
      );
    }
  };

  const generalSections = useMemo(() => {
    const defaultSections = [
      {
        title: 'Contact information',
        rows: [
          [
            {
              col: 4,
              colTablet: 6,
              children: (
                <Info
                  noEllipsis
                  title="Client ID"
                  value={
                    <Badge
                      text={String(id)}
                      styleType={BadgeColor.TetrieryCerulean}
                    />
                  }
                />
              ),
            },
            {
              col: 4,
              colTablet: 6,
              children: last_case_id && (
                <Info
                  noEllipsis
                  title="Last communication"
                  value={<CaseId caseId={last_case_id} />}
                />
              ),
            },
            {
              col: 4,
              colTablet: 6,
              title: 'Timezone',
              value: timezone && formatTimezoneCode(timezone),
            },
          ],
          [...getPhoneNumbersRow()],
          [
            {
              col: 4,
              colTablet: 6,
              title: 'Email',
              noEllipsis: true,
              value: getLinksList(),
            },
            {
              col: 4,
              colTablet: 6,
              title: 'Address',
              value: clientAddress(data),
            },
          ],
        ],
      },
      {
        title: 'Pets',
        rightSlot: hasEditPermission ? (
          <AddButton
            text="Add new pet"
            onClick={() =>
              navigate(Routes.Hospitals.Details.Clients.Details.New)
            }
          />
        ) : undefined,
        children: (
          <Styles.Pets
            data={pets}
            withMargin={!isPetOwner}
            hasEditPermission={hasEditPermission}
            editPath={(petId) =>
              generatePath(Routes.Hospitals.Details.Clients.Details.Edit, {
                petId: String(petId),
              })
            }
          />
        ),
      },
    ];

    if (sections?.length) {
      return [...defaultSections, ...sections];
    }

    return defaultSections;
  }, [id, pets, sections, timezone, last_case_id, hasEditPermission]);

  const topOffeset = useMemo(() => {
    let offset = mobile ? 186 : 112;
    if (isPetOwner) {
      offset = mobile ? 128 : 64;
    }
    return offset;
  }, [mobile, isPetOwner]);

  return (
    <GeneralInfo
      name={name}
      onEdit={onEdit}
      topOffset={topOffeset}
      sections={generalSections}
    />
  );
};
