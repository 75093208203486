import styled, { css } from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

import { AnalyticsExportStatus } from 'store/slices/analytics-export';

import { exportReportCard } from './config';

export const ExportCard = styled(FlexLayout)<{ status: AnalyticsExportStatus }>`
  gap: 8px;
  position: relative;
  border-radius: 16px;
  flex-direction: column;

  ${({ theme, status }) => {
    return css`
      box-shadow: ${theme.config.fieldCard.shadow};

      ${status === AnalyticsExportStatus.Initial
        ? css`
            padding: 24px 24px 0 24px;
          `
        : css`
            padding: 24px;
          `}
    `;
  }}
`;

export const ProgressBarContainer = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
  overflow: hidden;
  position: absolute;
  border-radius: 16px;
`;

export const ProgressBar = styled.div<{ status: AnalyticsExportStatus }>`
  bottom: 0;
  width: 1%;
  height: 6px;
  position: absolute;
  border-top-right-radius: inherit;
  transition: width 0.6s ease-in-out;

  ${({ theme, status }) => {
    const colorByStatus = {
      [AnalyticsExportStatus.Error]: theme.colors.red.primary.standard,
      [AnalyticsExportStatus.Ready]: theme.colors.green.primary.standard,
      [AnalyticsExportStatus.Processing]: theme.colors.green.secondary.action,
      [AnalyticsExportStatus.FileCreated]: theme.colors.green.secondary.action,
      [AnalyticsExportStatus.DataPrepared]: theme.colors.green.secondary.action,
    };

    return css`
      ${status !== AnalyticsExportStatus.Initial &&
      css`
        background-color: ${colorByStatus[status]};
        width: ${exportReportCard[status].progress};
      `}
    `;
  }}
`;
