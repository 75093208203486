import { TooltipRenderProps } from 'react-joyride';
import {
  Cat,
  FlexLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as Styles from './styles';

export const Intro = ({
  skipProps,
  primaryProps,
}: Pick<TooltipRenderProps, 'skipProps' | 'primaryProps'>) => {
  return (
    <>
      <FlexLayout alignItems="center" flexDirection="column">
        <Cat.Walkthrough />

        <Styles.TitleContainer>
          <h3>Welcome To GuardianVets!</h3>

          <Styles.Subtitle>
            Let’s help you get acquainted with our software and teach you how to
            get the most out of our platform.
          </Styles.Subtitle>
        </Styles.TitleContainer>
      </FlexLayout>

      <FlexLayout gap={24}>
        <StandardButton
          fullWidth
          text="Skip"
          colorType={ButtonColors.Secondary}
          {...skipProps}
        />
        <StandardButton fullWidth text="Show Me Around" {...primaryProps} />
      </FlexLayout>
    </>
  );
};
