import { useContext } from 'react';
import { useAlert, ButtonColors, StandardButton } from '@gv/triage-components';

import { ZoomContext } from 'context/video-comm';

export const ReportButton = () => {
  const { showSuccessAlert } = useAlert();
  const zoomClient = useContext(ZoomContext);

  const onReportClick = async () => {
    try {
      await zoomClient?.getLoggerClient()?.reportToGlobalTracing();
      showSuccessAlert('Report successfully sent');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StandardButton
      text="Report"
      padding="4px 8px"
      onClick={onReportClick}
      colorType={ButtonColors.TetrieryGrey}
    />
  );
};
