import styled from 'styled-components';
import { Styles } from '@gv/triage-components';

const { bodySemibold } = Styles.Font;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 221px;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const Name = styled.p`
  ${bodySemibold};
`;
