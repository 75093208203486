import { Utils, CaseFormHospitalReferral } from '@gv/triage-components';

import { useOptionalHospitalData } from 'hooks';

import * as Styles from './styles';

export const ERTemplateInfoList = ({
  data,
  hospitalId,
  ...props
}: {
  hospitalId?: string;
  data: CaseFormHospitalReferral;
}) => {
  const { hospital } = useOptionalHospitalData(hospitalId);
  const { name, phone, state, address, zip_code, city_name } = data;

  const fullAddress = Utils.Helpers.joinStrings(
    [address, city_name, state?.name, zip_code],
    ', ',
    ''
  );

  return (
    <Styles.List {...props}>
      {name && (
        <li>
          Hospital name: <span className="semibold">{name}</span>
        </li>
      )}

      {fullAddress && (
        <li>
          Address: <span className="semibold">{fullAddress}</span>
        </li>
      )}

      {phone && (
        <li>
          Phone: <span className="semibold">{phone}</span>
        </li>
      )}

      {'distance' in data && data.distance && hospital?.name && (
        <li>
          Within: <span className="semibold">{data.distance}</span> minutes from
          clinic <span className="semibold">{hospital.name}</span>
        </li>
      )}
    </Styles.List>
  );
};
