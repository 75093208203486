import { Badge } from '@gv/triage-components';

import {
  StripeInvoiceStatusText,
  StripeInvoiceStatusBadgeColor,
} from 'types/data';

import { PaymentStatusBadgeProps } from './types';

export const PaymentStatusBadge = ({ status }: PaymentStatusBadgeProps) => (
  <div>
    <Badge
      showDot
      text={StripeInvoiceStatusText[status]}
      styleType={StripeInvoiceStatusBadgeColor[status]}
    />
  </div>
);
