import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import minMax from 'dayjs/plugin/minMax';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { createRoot } from 'react-dom/client';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isPropValid from '@emotion/is-prop-valid';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import { StyleSheetManager } from 'styled-components';
import { Provider as AlertProvider } from 'react-alert';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as amplitude from '@amplitude/analytics-browser';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  Utils,
  Alert,
  Styles,
  ThemeWrap,
  ToastifyMessage,
  ThemeContextProvider,
} from '@gv/triage-components';

import { store } from 'store';
import { Config } from 'config';
import { LoaderRedux } from 'components';
import { interceptor } from 'api/instance';
import { AppPetOwner, AppTeamHospital } from 'app';
import { UserActionsContextProvider } from 'context/user-actions';

import { GlobalStyles } from './styles';
import reportWebVitals from './reportWebVitals';
import { getWalkthrough } from './utils/storage';
import { hydrateWalkthrough } from './store/slices/walkthrough';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const {
  sentry: { release, environment, config: sentry },
} = Config;

if (sentry && release && environment) {
  console.log(`VERSION ${release}`);
  try {
    Sentry.init({
      release,
      dsn: sentry,
      environment,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.replayIntegration(),
        Sentry.browserTracingIntegration(),
      ],
      ignoreErrors: [
        'AxiosError',
        'TimeoutError',
        'Loading chunk',
        "Unexpected token '<'",
        'request was interrupted by a call to pause',
        'possibly because the user denied permission',
        "failed because the user didn't interact with the document first",
      ],
    });
  } catch (error) {
    console.error(error);
  }
}

try {
  Utils.Firebase.initialize(Config.firebase.config);
} catch (error) {
  console.error(error);
}

const walkthrough = getWalkthrough();
if (walkthrough) {
  store.dispatch(hydrateWalkthrough(walkthrough));
}

interceptor(store.dispatch);

const container = document.getElementById('root');
const root = createRoot(container!);

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');

root.render(
  <Provider store={store}>
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '*',
          element: (
            <StyleSheetManager
              shouldForwardProp={(propName, elementToBeRendered) =>
                typeof elementToBeRendered === 'string'
                  ? isPropValid(propName)
                  : true
              }
            >
              <UserActionsContextProvider>
                <ThemeContextProvider>
                  <ThemeWrap>
                    <AlertProvider
                      timeout={5000}
                      template={Alert}
                      position="top right"
                      containerStyle={{ zIndex: 10000 }}
                    >
                      <LoaderRedux>
                        <Styles.GlobalStyles />
                        <GlobalStyles />
                        {Config.portalType.isPetOwner ? (
                          <AppPetOwner />
                        ) : (
                          <AppTeamHospital />
                        )}
                        <ToastifyMessage />
                      </LoaderRedux>
                    </AlertProvider>
                  </ThemeWrap>
                </ThemeContextProvider>
              </UserActionsContextProvider>
            </StyleSheetManager>
          ),
        },
      ])}
    />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
