import { generatePath } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import {
  OnCall,
  Loader,
  useAlert,
  ButtonColors,
  StandardButton,
  RadioFieldGroup,
} from '@gv/triage-components';

import { Config, Routes } from 'config';
import { useEmergencyHint } from 'hooks';
import { getDVMTemplate } from 'utils/templates';
import { selectAuthUser } from 'store/slices/auth';
import { getEmergencyHintConfig } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'store';
import { textOut } from 'store/api/action-queue/thunks';
import { changeStep } from 'store/slices/case-form-step';
import { TextOutBatchPayload } from 'store/api/action-queue/types';
import {
  ConsultationStep,
  CallFlowFolderName,
  CallOutcomeEmergency,
  MessageRecipientType,
} from 'types';
import {
  useFormEntities,
  DepartmentSelect,
  callOutcomeModel,
  CheckProtocolsInfo,
} from 'components/cases/form';

import { clientConfirmItems } from '../config';

import * as Styles from './styles';
import { ReferToOnCallProps } from './types';

const { HomeInfo, CallFlows, Home: HospitalHome } = Routes.Hospitals.Details;

export const ReferToOnCall = ({
  setDVM,
  options,
  callFlows,
  onCallPath,
  docsOptions,
  selectedDoc,
  referToOnCall,
  isCallBlocked,
  isQueriesLoading,
  isCorporateFetching,
  firstDocOptionValue,
  referToOnCallDoctorId,
  referToOnCallDoctorIdHelpers,
}: ReferToOnCallProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const authUser = useAppSelector(selectAuthUser);
  const { formId, client, hospitalId } = useFormEntities();
  const { handleError, showErrorAlert, showSuccessAlert } = useAlert();
  const { showHint, ...emergencyHintProps } = useEmergencyHint(hospitalId);
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikValues>();

  const [referToOnCallCorporate] = useField(referToOnCall.corporateHospitalId);

  const emergencyCallFlowId = useMemo(
    () =>
      callFlows?.rows.find((item) => item.name === CallFlowFolderName.Urgent)
        ?.id,
    [callFlows?.rows]
  );

  const emergencyHint = useMemo(() => {
    if (!showHint || !emergencyCallFlowId || referToOnCallCorporate.value) {
      return undefined;
    }
    const emergencyHintConfig = getEmergencyHintConfig(
      emergencyHintProps,
      hospitalId
    );
    if (!emergencyHintConfig) {
      return undefined;
    }
    const baseUrl = Config.portalType.isTeam
      ? generatePath(HospitalHome, {
          id: hospitalId,
        })
      : HomeInfo;
    return (
      <Styles.EmergencyScript>
        <p className="semibold">{emergencyHintConfig.title}</p>

        <Styles.EmergencyText>{emergencyHintConfig.text}</Styles.EmergencyText>

        <StandardButton
          target="_blank"
          text="Open Urgent/ER protocol"
          disabled={!emergencyCallFlowId}
          colorType={ButtonColors.TetrieryGrey}
          to={`${baseUrl}${CallFlows.Index}/${emergencyCallFlowId}`}
        />
      </Styles.EmergencyScript>
    );
  }, [
    showHint,
    hospitalId,
    emergencyHintProps,
    emergencyCallFlowId,
    referToOnCallCorporate.value,
  ]);

  useEffect(() => {
    if (
      (!firstDocOptionValue || !referToOnCallDoctorId.value) &&
      isQueriesLoading
    ) {
      return;
    }

    if (!referToOnCallDoctorId.value) {
      referToOnCallDoctorIdHelpers.setValue(firstDocOptionValue);
    }
  }, [firstDocOptionValue, referToOnCallDoctorId.value, isQueriesLoading]);

  useEffect(() => {
    const setDoctor = async () => {
      if (selectedDoc?.hospitalOnCallInfo) {
        await setFieldValue(
          'selectedDVMOnCallInfo',
          {
            erExamFees: selectedDoc.hospitalOnCallInfo.erExamFees,
            speciesSeenOnCall: selectedDoc.hospitalOnCallInfo.speciesSeenOnCall,
          },
          true
        );
      } else {
        setFieldValue('selectedDVMOnCallInfo', null);
      }
      setFieldTouched('selectedDVMOnCallInfo', true);
    };
    setDoctor();
  }, [selectedDoc?.hospitalOnCallInfo]);

  const referClientToER = () => {
    console.log('[changeStep]: change step in referClientToER', {
      values,
      formId,
      step: ConsultationStep.CallOutcome,
    });
    dispatch(changeStep({ formId, step: ConsultationStep.CallOutcome }));
    setFieldValue(
      callOutcomeModel.emergency.type,
      CallOutcomeEmergency.ReferToEr
    );
    setFieldValue(callOutcomeModel.emergency.referToEr.accepted, 'true');
  };

  const onCallTextOut = selectedDoc
    ? async () => {
        try {
          setLoading(true);
          const name = selectedDoc.name;
          const payload: TextOutBatchPayload = {
            caseId: values.id,
            dvmId: selectedDoc.id,
            phones: [selectedDoc.phone!],
            type: MessageRecipientType.DVM,
            body: getDVMTemplate({
              client,
              form: values,
              dvm: selectedDoc,
              teamMember: authUser,
            }),
          };

          const { failed, successful } = await dispatch(
            textOut(payload)
          ).unwrap();
          if (successful) {
            showSuccessAlert(
              `Text to ${name} successfully sent to:\n${successful}`
            );
          }
          if (failed) {
            showErrorAlert(`Text to ${name} failed to sent to:\n${failed}`);
          }
        } catch (error) {
          handleError(error);
        } finally {
          setLoading(false);
        }
      }
    : undefined;

  return (
    <Loader isLoading={isLoading}>
      {emergencyHint}

      <DepartmentSelect
        options={options}
        isDisabled={isCorporateFetching}
        name={referToOnCall.corporateHospitalId}
        onChange={() => referToOnCallDoctorIdHelpers.setValue(null)}
        disableInitialFirstOption={
          isQueriesLoading ||
          referToOnCallDoctorId.value === null ||
          Boolean(referToOnCallDoctorId.value) ||
          Boolean(!referToOnCallDoctorId.value && firstDocOptionValue)
        }
      />

      <CheckProtocolsInfo>
        <RadioFieldGroup
          items={clientConfirmItems}
          name={referToOnCall.accepted}
          label="Confirm action with client"
        />

        <OnCall
          setDVM={setDVM}
          onCallPath={onCallPath}
          docsOptions={docsOptions}
          selectedDoc={selectedDoc}
          referToOnCall={referToOnCall}
          isCallBlocked={isCallBlocked}
          onCallTextOut={onCallTextOut}
          referClientToER={referClientToER}
        />
      </CheckProtocolsInfo>
    </Loader>
  );
};
