import { useLocation } from 'react-router-dom';
import { FormikValues, useFormikContext } from 'formik';
import { mapQueries, CorporateType } from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { useGetQuery } from 'store/api/acceptance';
import { useDetailsQuery } from 'store/api/hospitals';
import { selectIsLastStep } from 'store/slices/case-form-step';
import { useOnCallProtocolQuery } from 'store/api/on-call-protocol';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';
import { selectCurrentTaskId } from 'store/api/action-queue/selectors';
import { useListQuery as useReferralsListQuery } from 'store/api/hospital-referrals';

import { Queries } from './types';

export const useFormEntities = () => {
  const location = useLocation();
  const { values } = useFormikContext<FormikValues>();
  const { client_id, hospital_id } = values;
  const currentTaskId = useAppSelector(selectCurrentTaskId);
  const isActionQueue = location.pathname.includes(Routes.ActionCenter.Home);
  const formId = isActionQueue && currentTaskId ? currentTaskId : values.id;
  const isLastStep = useAppSelector(selectIsLastStep(formId));

  const hospitalId = String(hospital_id);

  const queryParams = { skip: !hospital_id };

  const queries = mapQueries<Queries>({
    hospital: useDetailsQuery(hospitalId, queryParams),
    referrals: useReferralsListQuery(hospitalId, queryParams),
    onCallProtocol: useOnCallProtocolQuery(hospitalId, queryParams),
    acceptance: useGetQuery(
      { hospitalId: String(hospitalId) },
      { skip: !hospital_id }
    ),
    client: useClientQuery(
      { hospitalId, allowDeleted: true, id: String(client_id) },
      { skip: !client_id || !hospital_id }
    ),
  });

  const isMainHospital =
    queries?.hospital && queries.hospital.corporate_type === CorporateType.Main;

  return {
    formId,
    isLastStep,
    isMainHospital,
    clientId: client_id,
    hospitalId: hospital_id ? String(hospital_id) : undefined,
    ...queries,
  };
};
