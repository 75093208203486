import debounce from 'lodash.debounce';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import {
  Icon,
  BadgeColor,
  useNavigate,
  UsersBadgePopup,
  isMobile as isMobileHook,
} from '@gv/triage-components';

import { NurseStatus } from 'types';
import { useAblyEvent } from 'hooks';
import { useAppSelector } from 'store';
import { Config, Routes } from 'config';
import { selectIsAdmin } from 'store/slices/auth';
import { useLazyOnlineQuery } from 'store/api/users';
import { joinRoutes, getCurrentUserStatus } from 'utils/helpers';
import { NurseStatusTitle, NurseStatusBadgeColor } from 'types/data';
import { getUsersTasksCount } from 'pages/team/analytics/list/helpers';

const { Home, List } = Routes.Team;

const badgeAllowed = Config.portalType.isTeam;
const analyticsPath = joinRoutes([Home, List.Analytics], {});

export const useOnlineBadge = () => {
  const isMobile = isMobileHook();
  const navigate = useNavigate();

  const [trigger, { data }] = useLazyOnlineQuery();

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    if (!badgeAllowed) {
      return;
    }
    lastRequest.current?.abort();
    const req = trigger({ page: 1, limit: 5 });
    lastRequest.current = req;
  }, []);

  useEffect(refetch, []);

  useEffect(() => {
    refetch(); // Initial call on mount
    const intervalId = setInterval(refetch, 10000); // 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [refetch]);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  const isAdmin = useAppSelector(selectIsAdmin);

  useEffect(() => {
    return () => debounceRefetch.cancel();
  }, [debounceRefetch]);

  useAblyEvent(
    badgeAllowed ? ['online-status-changed', 'user-status-changed'] : undefined,
    debounceRefetch
  );

  const currentTasks = useAppSelector((store) => store?.actionQueue?.entities);

  const badges = useMemo(() => {
    if (!data || !badgeAllowed) {
      return [];
    }
    const { count, users } = data;
    const usersTasksCount = getUsersTasksCount(currentTasks, users);
    if (count === 0) {
      return [];
    }
    if (isMobile) {
      return [
        {
          count,
          icon: <Icon.Team />,
          styleType: BadgeColor.Blue,
          onClick: isAdmin ? () => navigate(analyticsPath) : undefined,
        },
      ];
    }
    const usersList = users.map((user) => {
      const { status } = user;
      const userStatus = getCurrentUserStatus(status);
      const tasksCount = usersTasksCount[user.id];

      return {
        id: user.id,
        name: user.name,
        status: userStatus,
        role: user.role.name,
        imageUrl: user.profile_pic,
        badgeColor: NurseStatusBadgeColor[status!],
        badgeText:
          tasksCount?.chats > 0
            ? NurseStatusTitle[NurseStatus.Chat]
            : NurseStatusTitle[status!],
      };
    });
    return [
      {
        count,
        text: 'Team online',
        styleType: BadgeColor.Blue,
        popup: (
          <UsersBadgePopup
            users={usersList}
            viewAllLink={isAdmin ? analyticsPath : undefined}
          />
        ),
      },
    ];
  }, [data, isMobile, currentTasks]);

  return badges;
};
