import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  TimeZone,
  TimeZonesListResponse,
  CreateTimeZonePayload,
  UpdateTimeZonePayload,
  TimeZoneMutationResponse,
} from './types';

const timeZonesAdapter = createEntityAdapter<TimeZone, number>({
  selectId: (item) => item.id,
});

const tagType = 'TimeZones';

export const timeZonesApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'timeZonesApi',
  endpoints: (build) => ({
    delete: build.mutation<TimeZone, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_MASTER_TIMEZONE, { id }),
      }),
    }),

    list: build.query<TimeZone[], void>({
      providesTags: (result) => providesList(tagType)(result),
      transformResponse: (response: TimeZonesListResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.GET_TIMEZONES_MASTER_LIST,
      }),
    }),

    create: build.mutation<TimeZone, CreateTimeZonePayload>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: TimeZoneMutationResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ADD_MASTER_TIMEZONE,
      }),
    }),

    update: build.mutation<TimeZone, UpdateTimeZonePayload>({
      transformResponse: (response: TimeZoneMutationResponse) => response.data,
      invalidatesTags: (result, error, { timezone_id }) =>
        cacheByIdArg(tagType)(result, error, timezone_id),
      query: ({ name, code, timezone_id, time_zone_code }) => ({
        method: 'patch',
        data: { name, code, time_zone_code },
        url: generateUrl(URL.UPDATE_MASTER_TIMEZONE, { id: timezone_id }),
      }),
    }),
  }),
});

export const timeZonesSlice = createSlice({
  reducers: {},
  name: 'timeZones',
  initialState: timeZonesAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      timeZonesApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        timeZonesAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      timeZonesApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        timeZonesAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      timeZonesApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        timeZonesAdapter.updateOne(state, {
          changes: payload,
          id: timeZonesAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      timeZonesAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = timeZonesApi;
