import { useState } from 'react';
import { Carousel, PhotoCard, FlexLayout } from '@gv/triage-components';

import * as Styles from './styles';

export const PetOwnerCarousel = () => {
  const [data] = useState([
    {
      angle: 4,
      name: 'Emily and Max',
      photo: require('../../assets/images/po-carousel/11.jpg'),
    },
    {
      angle: -4,
      name: 'John and Buddy',
      photo: require('../../assets/images/po-carousel/10.jpg'),
    },
    {
      angle: 4,
      name: 'Jennifer and Oliver',
      photo: require('../../assets/images/po-carousel/9.jpg'),
    },
    {
      angle: -4,
      name: 'Rebecca and Chloe',
      photo: require('../../assets/images/po-carousel/8.jpg'),
    },
    {
      angle: 4,
      name: 'Michael and Max',
      photo: require('../../assets/images/po-carousel/7.jpg'),
    },
    {
      angle: -4,
      name: 'Sarah and Bella',
      photo: require('../../assets/images/po-carousel/6.jpg'),
    },
    {
      angle: 4,
      name: 'Lauren and Sadie',
      photo: require('../../assets/images/po-carousel/5.jpg'),
    },
    {
      angle: -4,
      name: 'David and Rocky',
      photo: require('../../assets/images/po-carousel/4.jpg'),
    },
    {
      angle: 4,
      name: 'Ava and Milo',
      photo: require('../../assets/images/po-carousel/3.jpg'),
    },
    {
      angle: -4,
      name: 'William and Charlie',
      photo: require('../../assets/images/po-carousel/2.jpg'),
    },
    {
      angle: 4,
      name: 'Olivia and Daisy',
      photo: require('../../assets/images/po-carousel/1.jpg'),
    },
  ]);

  return (
    <Styles.Container>
      <Carousel
        infinite
        autoplay
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={3000}
      >
        {data.map((props) => (
          <div key={props.name}>
            <FlexLayout flex={1} alignItems="center" justifyContent="center">
              <PhotoCard {...props} />
            </FlexLayout>
          </div>
        ))}
      </Carousel>
    </Styles.Container>
  );
};
