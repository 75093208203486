import { GVService, MockHandler } from '@gv/triage-components';

import { URL } from 'api/constants';
import {
  BillStatus,
  BillInvoice,
  AppointmentType,
  SubscriptionPlan,
  SubscriptionPlanType,
} from 'types';

const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const list: BillInvoice[] = new Array(20).fill(1).map((_, idx) => ({
  tax: 0,
  total: 250,
  amount: 750,
  quantity: 3,
  subtotal: 250,
  id: 100 + idx,
  total_excl_tax: 250,
  dueAt: new Date().toString(),
  description: 'GV Talk - Monthly',
  bill_number: 'GV01380300182' + idx,
  status: idx === 0 ? BillStatus.Missed : BillStatus.Paid,
  createdAt: new Date(`2023-${getRandomInt(1, 12)}-10`).toString(),
  to: {
    city_name: 'LA',
    zip_code: '83475',
    phone: '+13025550107',
    name: 'Suffolk Veterinary Group',
    address: '2715 Ash Dr. San Jose',
    email_consult_notif: 'deanna.curtis@example.com',
  },
  from: {
    city_name: 'IL',
    zip_code: '60613',
    name: 'GuardianVets',
    phone: '+12195550114',
    address: '1801 W Belle Plaine Ave, Suite 206',
    email_consult_notif: 'contactus@guardianvets.com',
  },
}));

const activeSubsctiptions: SubscriptionPlan[] = [
  {
    id: 111,
    calls: 30,
    messages: 120,
    fee_percent: 2,
    per_user: 8.99,
    editable: true,
    active_users: 5,
    total_amount: 250,
    phone_numbers: 50,
    consultations_fee: 50,
    cents_per_number: 0.39,
    name: GVService.GVTalk,
    next_billing_date: new Date().toString(),
    plan_type: SubscriptionPlanType.Essential,
    description:
      'Relocate calls to the GV Virtual Front Desk team. Certified Vet Techs can work more efficiently with the time saved from delegating tasks. Boost productivity so that there is no additional revenue lost.',
  },
  {
    id: 112,
    total_amount: 400,
    monthly_plan: 400,
    name: GVService.AfterHours,
    next_billing_date: new Date().toString(),
    description:
      'Relocate calls to the GV Virtual Front Desk team. Certified Vet Techs can work more efficiently with the time saved from delegating tasks. Boost productivity so that there is no additional revenue lost.',
  },
];

const availableSubsctiptions: SubscriptionPlan[] = [
  {
    id: 113,
    total_amount: 400,
    name: GVService.VirtualFrontDesk,
    next_billing_date: new Date().toString(),
    description:
      'Relocate calls to the GV Virtual Front Desk team. Certified Vet Techs can work more efficiently with the time saved from delegating tasks. Boost productivity so that there is no additional revenue lost.',
  },
  {
    id: 114,
    total_amount: 400,
    name: GVService.VET,
    next_billing_date: new Date().toString(),
    description:
      'Free up DVM & CVT time to focus on hospitalized patients.  GV V.E.T. hospitals can screen patients & gather history before they arrive. Communicate in real-time to update capacity status & much more!',
  },
];

const accountDetails = {
  account_number: '•••• 4673',
  account_holder: 'Darrell Steward',
};

const appointmentTypesList: AppointmentType[] = [
  { id: 1, name: 'Wellness' },
  { id: 2, name: 'Some type' },
];

const getBillingHistory = (search?: string) => {
  if (!search) {
    return list;
  }
  return list.filter(({ status, bill_number }) => {
    const hasValue = (value?: string | number) => {
      if (!value) {
        return false;
      }
      return !!String(value)?.toLowerCase()?.includes(search.toLowerCase());
    };
    return hasValue(bill_number) || hasValue(status);
  });
};

export const billingMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    url: URL.HOSPITAL_BILLING_HISTORY,
    responseFunc: ({ data }) => getBillingHistory(data?.search),
  },
  {
    method: 'get',
    responseData: activeSubsctiptions,
    url: URL.ACTIVE_SUBSCRIPTIONS_LIST,
  },
  {
    method: 'get',
    responseData: availableSubsctiptions,
    url: URL.AVAILABLE_SUBSCRIPTIONS_LIST,
  },
  {
    method: 'get',
    responseData: accountDetails,
    url: URL.BILLING_ACCOUNT_DETAILS,
  },
  {
    method: 'get',
    responseData: appointmentTypesList,
    url: URL.GET_APPOINTMENT_TYPES_LIST,
  },
];
