import { ConsultationStep } from 'types';

const mainSteps = [
  ConsultationStep.PetOwner,
  ConsultationStep.ProblemSummary,
  ConsultationStep.CallOutcome,
];

export const editSteps = [...mainSteps];

export const baseSteps = [ConsultationStep.Greeting, ...mainSteps];

export const discardSteps = [
  ConsultationStep.Greeting,
  ConsultationStep.DeadlineIssue,
];
