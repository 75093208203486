import { useMemo, useState, useContext } from 'react';
import {
  Icon,
  List,
  FlexLayout,
  ButtonColors,
  ThemeContext,
  StandardButton,
} from '@gv/triage-components';

import * as Styles from './styles';
import { ConversationListContainerProps } from './types';

export const ConversationListContainer = ({
  buttons,
  children,
  searchQuery,
  chatCardProps,
  setSearchQuery,
  ...props
}: ConversationListContainerProps) => {
  const { theme } = useContext(ThemeContext);
  const [activeSearch, setActiveSearch] = useState(false);

  const listProps = useMemo(() => {
    const gap = 16;
    const mainWrapperStyles = {
      padding: '8px 16px 0',
      background: theme.config.chat.main.bg,
    };

    if (!activeSearch) {
      return {
        gap,
        mainWrapperStyles,
        topHeader: (
          <Styles.Card
            rightSlot={
              searchQuery ? (
                <FlexLayout gap={16}>
                  <StandardButton
                    icon={<Icon.Search />}
                    colorType={ButtonColors.Secondary}
                    onClick={() => setActiveSearch(true)}
                  />
                  {buttons}
                </FlexLayout>
              ) : undefined
            }
            {...chatCardProps}
          />
        ),
      };
    }

    return {
      gap,
      searchQuery,
      setSearchQuery,
      mainWrapperStyles,
      searchContainerStyle: {
        margin: 0,
        boxShadow: `inset 0px -1px 0px ${theme.config.sidebar.border}`,
        padding: theme.responsive.isMobile ? '0 16px 12px' : '12px 16px',
      },
      searchInputProps: {
        search: false,
        autoFocus: true,
        leftIcon: <Icon.Search />,
        rightIcon: searchQuery ? <Icon.CloseCircle /> : <Icon.Close />,
        onRightIconClick: () => {
          if (searchQuery) {
            setSearchQuery?.('');
          } else {
            setActiveSearch(false);
          }
        },
      },
    };
  }, [activeSearch, setSearchQuery, searchQuery, theme]);

  return (
    <List {...listProps} {...props}>
      {children}
    </List>
  );
};
