import * as Styles from './styles';
import { AddressInfoProps } from './types';

const AddressInfo = ({ title, address, distance }: AddressInfoProps) => {
  return (
    <Styles.Column>
      <Styles.Title className="semibold">{title}</Styles.Title>
      <p>{address}</p>
      {distance && (
        <p className="caption-regular">Within {distance} min from clinic</p>
      )}
    </Styles.Column>
  );
};

export { AddressInfo };
