import { Pet, ListCard } from '@gv/triage-components';

import { PetsCells, PetsTemplate, prepareCardValues } from './helpers';

export const titles = ['Pet', 'Species', 'Age', 'Breed', 'Gender'];

export const PetCard = ({ pet }: { pet: Pet }) => {
  const values = prepareCardValues(pet);

  return (
    <ListCard
      values={values}
      padding="24px 16px"
      mobileHeaders={titles}
      cellsMobile={PetsCells.mobile}
      template={PetsTemplate.desktop}
      mobileTemplate={PetsTemplate.mobile}
    />
  );
};

export { PetsTemplate };
