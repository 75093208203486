import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import {
  Medication,
  MedicationList,
  MedicationCategory,
  MedicationListRequest,
  MedicationListResponse,
  CreateMedicationPayload,
  UpdateMedicationPayload,
  DeleteMedicationPayload,
  MedicationCategoriesResponse,
} from './types';

const tagTypes = ['Medications'];

export const medicationsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'medicationsApi',
  endpoints: (build) => ({
    create: build.mutation<Medication, CreateMedicationPayload>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.MEDICATIONS,
      }),
    }),

    update: build.mutation<Medication, UpdateMedicationPayload>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'patch',
        url: URL.MEDICATIONS,
      }),
    }),

    delete: build.mutation<Medication, DeleteMedicationPayload>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'delete',
        url: URL.MEDICATIONS,
      }),
    }),

    categories: build.query<MedicationCategory[], void>({
      providesTags: tagTypes,
      query: () => ({ method: 'get', url: URL.GET_MEDICATION_CATEGORIES }),
      transformResponse: (response: MedicationCategoriesResponse) =>
        response.data,
    }),

    list: build.query<MedicationList, MedicationListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: MedicationListResponse) => response.data,
      query: ({ page, search, limit = 20 }) => {
        const url = Utils.Query.addParamsToUrl(URL.MEDICATIONS, {
          search,
          limit: page ? limit : undefined,
          offset: Utils.Helpers.offset(page || 1, limit),
        });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useCategoriesQuery,
} = medicationsApi;
