import styled, { css } from 'styled-components';

export const Container = styled.div<{ gap?: number }>`
  flex: 1;
  padding: 16px;
  display: flex;
  height: max-content;
  flex-direction: column;
  ${({ gap, theme }) => css`
    ${gap && `gap: ${gap}px;`};
    border-radius: ${theme.borderRadius};
    background-color: ${theme.colors.grey.tertiary.standard};
  `}
`;
