import { Utils, CustomInvoiceOption } from '@gv/triage-components';

import { HospitalPrice } from 'types';

const { capitalize } = Utils.String;

export const getHospitalPricesOptions = (
  data?: HospitalPrice[],
  isCustomHidden?: boolean
) => {
  const priceOptions =
    data?.map((value) => ({
      label: value.service.name,
      value: value.id.toString(),
    })) || [];

  if (!isCustomHidden) {
    priceOptions.push({
      value: CustomInvoiceOption.Custom,
      label: capitalize(CustomInvoiceOption.Custom),
    });
  }

  return priceOptions;
};
