import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ProfileUser,
  UpdateProfileRequest,
  ChangePasswordRequest,
} from '@gv/triage-components';

import { URL } from 'api/constants';

import { apiQuery } from '../../query';
import { updateAuthUser } from '../../slices/auth';

import { UserInfoResponse } from './types';

const tagTypes = ['Profile'];

export const userInfoApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'userInfoApi',
  endpoints: (build) => ({
    get: build.query<ProfileUser, void>({
      providesTags: tagTypes,
      transformResponse: (response: UserInfoResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.PROFILE,
      }),
    }),
    changePassword: build.mutation<void, ChangePasswordRequest>({
      invalidatesTags: (result, error) => (error ? [] : tagTypes),
      query: (data) => ({
        data,
        method: 'patch',
        url: URL.CHANGE_PASSWORD,
      }),
    }),
    update: build.mutation<ProfileUser, UpdateProfileRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: UserInfoResponse) => response.data,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.PROFILE,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            updateAuthUser({
              name: data.name,
              profile_pic: data.profile_pic,
              walkthrough_completed: data.walkthrough_completed,
            })
          );
        } catch {}
      },
    }),
  }),
});

export const { useGetQuery, useUpdateMutation, useChangePasswordMutation } =
  userInfoApi;

export type { ChangePasswordRequest };
