import { CacheItem, InnerProvidesList } from './types';

export const providesList =
  <T extends string>(tagType: T): InnerProvidesList<T> =>
  (result) =>
    result
      ? [
          { id: 'LIST', type: tagType },
          ...result.map(({ id }) => ({ id, type: tagType })),
        ]
      : [{ id: 'LIST', type: tagType }];

export const invalidatesList =
  <T extends string>(type: T) =>
  (): readonly [CacheItem<T, 'LIST'>] =>
    [{ type, id: 'LIST' }] as const;

export const cacheByIdArg =
  <T extends string>(type: T) =>
  <ID, Result = undefined, Error = undefined>(
    result: Result,
    error: Error,
    id: ID
  ) =>
    error ? [] : [{ id, type }];

export const cacheListAndByIdArg =
  <T extends string>(type: T) =>
  <ID, Result = undefined, Error = undefined>(
    result: Result,
    error: Error,
    id: ID
  ) =>
    error
      ? []
      : [
          { id, type },
          { type, id: 'LIST' },
        ];

export const cacheByIdArgProperty =
  <T extends string>(type: T) =>
  <Arg extends { id: unknown }, Result = undefined, Error = undefined>(
    result: Result,
    error: Error,
    arg: Arg
  ): [] | readonly [CacheItem<T, Arg['id']>] =>
    [{ type, id: arg.id }] as const;

export const cacheListAndByIdArgProperty =
  <T extends string>(type: T) =>
  <Arg extends { id: unknown }, Result = undefined, Error = undefined>(
    result: Result,
    error: Error,
    arg: Arg
  ): [] | readonly CacheItem<T, Arg['id']>[] =>
    [
      { type, id: arg.id },
      { type, id: 'LIST' },
    ] as const;
