import dayjs from 'dayjs';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { SubscriptionsSliceProps } from './types';

const subscriptionsStore = (store: RootState) => store?.subscriptions;

const isClientSubscribed = ({
  expiration,
  isSubscribed,
  isSubscriptionRequired,
}: SubscriptionsSliceProps) => {
  if (!isSubscriptionRequired || !expiration) {
    return false;
  }
  return isSubscribed && dayjs(expiration).isAfter(dayjs(), 'second');
};

export const selectIsSubscriptionActivationRequired = createSelector(
  [subscriptionsStore],
  (store) => {
    if (!store) {
      return false;
    }
    if (!store.isSubscriptionRequired) {
      return false;
    }

    return !isClientSubscribed(store);
  }
);

export const selectIsSubscribed = createSelector(
  [subscriptionsStore],
  (store) => {
    if (!store) {
      return false;
    }
    return isClientSubscribed(store);
  }
);

export const selectIsSubscriptionRequired = createSelector(
  [subscriptionsStore],
  (store) => store?.isSubscriptionRequired || false
);

export const selectSubscribeToProductIdAfterSignUp = createSelector(
  [subscriptionsStore],
  (store) => store?.subscribeToProductIdAfterSignUp || false
);
