import styled, { css } from 'styled-components';
import {
  FlexLayout,
  AddButton as TCAddButton,
  DeleteButton as TCDeleteButton,
} from '@gv/triage-components';

export const FieldContainer = styled(FlexLayout)<{ $margin?: string }>`
  gap: 8px;
  align-items: flex-start;

  ${({ $margin }) =>
    $margin &&
    css`
      margin: ${$margin};
    `}
`;

export const AddButton = styled(TCAddButton)`
  margin-top: 8px;
`;

export const DeleteButton = styled(TCDeleteButton)`
  width: 40px;
  height: 40px;
`;
