import { useEffect, useContext } from 'react';
import { useAlert, ActionQueue } from '@gv/triage-components';

import { getCallOutcomePayload } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'store';
import { selectHospital } from 'store/api/hospitals/selectors';
import { CallOutcomeType, CallOutcomeDetailsType } from 'types';
import { useHospitalDataApi, useOptionalHospitalData } from 'hooks';
import { setCurrentTaskClient } from 'store/api/action-queue/thunks';
import { useGetQuery as useAcceptanceQuery } from 'store/api/acceptance';
import { useListQuery as useMedicationsQuery } from 'store/api/medications';
import { useListQuery as useChiefComplaintListQuery } from 'store/api/chief-complaints';
import {
  selectClient,
  useDetailsQuery as useClientQuery,
} from 'store/api/clients';
import {
  selectCurrentTask,
  selectCurrentTaskForm,
} from 'store/api/action-queue/selectors';
import {
  useDetailsQuery,
  useListQuery as useHospitalListQuery,
} from 'store/api/hospitals';

import { CallContext } from './context';

export const useCommClient = () => {
  const task = useAppSelector(selectCurrentTask);
  const clientId = task?.c_id ? task.c_id : undefined;
  const clientIdString = clientId ? String(clientId) : '';
  const client = useAppSelector(selectClient(clientIdString));

  return {
    client,
    clientId: clientIdString,
  };
};

export const useCommEntities = () => {
  const task = useAppSelector(selectCurrentTask);

  const hospitalId = task?.h_id ? String(task?.h_id) : undefined;
  const clientId = task?.c_id ? String(task?.c_id) : undefined;

  const client = useAppSelector(selectClient(clientId));
  const hospital = useAppSelector(selectHospital(hospitalId));

  return {
    client,
    hospital,
    clientId,
    hospitalId,
  };
};

export const useTaskLoader = (task?: ActionQueue) => {
  const dispatch = useAppDispatch();
  const { handleError } = useAlert();
  const currentTaskForm = useAppSelector(selectCurrentTaskForm);

  const { isClientDone } = currentTaskForm ?? {};

  const { client, clientId } = useCommClient();

  const { h_id } = task ?? {};
  const hospitalId = h_id && h_id > 0 ? String(h_id) : undefined;

  useHospitalDataApi(hospitalId ? String(hospitalId) : 'undefined');

  const corporateHospitalId = currentTaskForm?.corporate_hospital_id;

  useClientQuery(
    {
      id: clientId,
      ...(corporateHospitalId && {
        hospitalId,
      }),
    },
    { skip: !hospitalId || !clientId }
  );

  useMedicationsQuery({});
  const { data: acceptance } = useAcceptanceQuery(
    { hospitalId },
    {
      skip: !hospitalId,
    }
  );

  useHospitalListQuery({ page: 1, limit: 10000 });
  useChiefComplaintListQuery();

  useEffect(() => {
    if (!client || isClientDone) {
      return;
    }
    const setClient = async () => {
      try {
        await dispatch(setCurrentTaskClient({ client })).unwrap();
      } catch (error) {
        handleError(error);
      }
    };

    setClient();
  }, [
    dispatch,
    client?.id,
    client?.pet,
    client?.pets,
    client?.name,
    isClientDone,
    client?.phone,
    client?.email,
    client?.phones,
    client?.home_phones,
    client?.hospital_id,
  ]); // deps has fields which used in setCurrentTaskClient

  const { hospital, onCallProtocol } = useOptionalHospitalData(hospitalId);

  return { client, hospital, clientId, acceptance, hospitalId, onCallProtocol };
};

export const useSelectedCorporateId = (
  type?: CallOutcomeType,
  detailsType?: CallOutcomeDetailsType
) => {
  const { form } = useContext(CallContext);

  const payload = getCallOutcomePayload(form.values.callOutcome);

  if (!payload) {
    return;
  }

  if (type && detailsType) {
    if (payload.type === type && payload.details?.[type] === detailsType) {
      return payload.corporate_hospital_id;
    }
  } else {
    return payload?.corporate_hospital_id;
  }
};

export const useGetSelectedCorporate = (
  type?: CallOutcomeType,
  detailsType?: CallOutcomeDetailsType
) => {
  const id = useSelectedCorporateId(type, detailsType);

  return useDetailsQuery(String(id), { skip: !id });
};
