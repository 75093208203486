import { useEffect } from 'react';

export const useNumpadInput = (handleInput: (value: string) => void) => {
  useEffect(() => {
    const listener = function (event: KeyboardEvent) {
      const element = event.target as HTMLElement;

      if (
        (event.code === 'Backspace' || event.code?.includes('Digit')) &&
        element?.tagName === 'BODY'
      ) {
        handleInput(event.key);
      }
    };
    document.body.addEventListener('keydown', listener);
    return () => {
      document.body.removeEventListener('keydown', listener);
    };
  }, [handleInput]);
};
