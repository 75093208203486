import { useField } from 'formik';
import {
  ERCard,
  FlexLayout,
  DateTimeField,
  StandardButton,
  ButtonStyleTypes,
  ERSelectHospital,
} from '@gv/triage-components';

import { useHospitalTime } from 'components/cases/form/useHospitalTime';
import {
  useFormEntities,
  callOutcomeModel,
  CheckProtocolsInfo,
} from 'components/cases/form';

import { ReferToERProps } from './types';

const {
  emergency: { referToEr },
} = callOutcomeModel;

export const ReferToEr = ({
  options,
  protocol,
  acceptance,
  setReferral,
  mainHospital,
  referralData,
  isERHospital,
  showReferrals,
  setShowReferrals,
  hospitalsOptions,
}: ReferToERProps) => {
  const { hospital, isMainHospital } = useFormEntities();
  const { isHospitalOpen } = useHospitalTime();

  const [, , referralHelpers] = useField(referToEr.hospitalRefId);

  if (isERHospital && !showReferrals) {
    return (
      <CheckProtocolsInfo>
        {hospital && (
          <ERCard
            data={hospital}
            protocol={protocol}
            acceptance={acceptance}
            setReferral={setReferral}
          />
        )}

        <FlexLayout gap={16} flexDirection="column" alignItems="flex-start">
          <p>
            If client not accept hospital emergency fee or acceptance criteria,
            please refer client to other ER
          </p>

          <StandardButton
            text="Refer client to ER"
            styleType={ButtonStyleTypes.Outline}
            onClick={() => {
              referralHelpers.setValue(null);
              setShowReferrals(true);
            }}
          />
        </FlexLayout>
      </CheckProtocolsInfo>
    );
  }

  return (
    <>
      <ERSelectHospital
        options={options}
        referToEr={referToEr}
        isERHospital={isERHospital}
        hospitalName={hospital?.name}
        isHospitalOpen={isHospitalOpen}
        isMainHospital={isMainHospital}
        hospitalsOptions={hospitalsOptions}
      />

      <CheckProtocolsInfo>
        {referralData?.hospital && (
          <ERCard
            setReferral={setReferral}
            mainHospital={mainHospital}
            data={referralData.hospital}
            acceptance={referralData.acceptance}
          />
        )}

        <DateTimeField
          timezone={hospital?.timezone}
          name={callOutcomeModel.emergency.referToEr.datetime}
        />
      </CheckProtocolsInfo>
    </>
  );
};
