import { ChatMessage } from '@gv/triage-components';

export interface ChatData {
  prevId?: number;
  nextId?: number;
  messages: ChatMessage[];
}

export type ChatSlice = Record<string, ChatData>;

export interface BasePayload {
  channelName: string;
}

export interface SetMessages extends BasePayload {
  data: ChatData;
}

export interface AddMessage extends BasePayload {
  message: ChatMessage;
}

export interface RemoveMessage extends BasePayload {
  id: ChatMessage['id'];
}

export type UpdateMessage = BasePayload & Partial<ChatMessage>;

export const types = {
  sendChatMessage: 'messages/sendChatMessage',
  loadOldMessages: 'messages/loadOldMessages',
};
