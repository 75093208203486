import dayjs from 'dayjs';
import { useMemo } from 'react';
import { EmptyPlaceholder } from '@gv/triage-components';

import { TaskType } from 'types';
import { getAppointmentDates } from 'pages/tasks/config';
import { TaskEta, TaskPriorityBadge, TaskVoicemailBadge } from 'components';

import { TaskDueDateProps } from './types';

export const TaskDueDate = ({
  data,
  taskClass,
  hidePriority,
  hideVoicemail,
  hideAvailability,
}: TaskDueDateProps) => {
  const { dueAt, hospital, priority, appointment, case: taskCase } = data;

  const { datetime, availabilityTime, availabilityDays } =
    taskCase?.callOutcome?.details ?? {};

  const timezone = hospital?.timezone?.time_zone_code;

  const { date, localDate, hasTimezone } = useMemo(() => {
    let isTimezoneUsed = false;
    const hasDueAt = !!dueAt;
    const hasDateTime = !!datetime;
    const taskDate = hasDueAt
      ? dueAt
      : hasDateTime
        ? datetime
        : getAppointmentDates(appointment)?.visibleDate?.date;
    if (!taskDate) {
      return {};
    }
    let dayjsValue = dayjs(taskDate);
    const localValue = dayjsValue;
    if (timezone) {
      isTimezoneUsed = true;
      dayjsValue = dayjsValue.tz(timezone);
    }
    return {
      date: dayjsValue,
      localDate: localValue,
      hasTimezone: isTimezoneUsed,
    };
  }, [dueAt, appointment?.appointmentDates, timezone]);

  return date ? (
    <TaskEta
      date={date}
      localDate={localDate}
      taskClass={taskClass}
      hasTimezone={hasTimezone}
    />
  ) : priority && !hidePriority ? (
    <TaskPriorityBadge priority={priority} />
  ) : (availabilityDays || availabilityTime) && !hideAvailability ? (
    <>
      <span className="semibold">
        {availabilityDays?.join(', ')} - {availabilityTime?.join(', ')}
      </span>
    </>
  ) : data.type === TaskType.Voicemail && !hideVoicemail ? (
    <TaskVoicemailBadge type={data.type} />
  ) : (
    <>{EmptyPlaceholder}</>
  );
};
