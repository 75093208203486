import { Tabs, useHeader, Responsive, FlexLayout } from '@gv/triage-components';

import { usePageName } from 'hooks';

import { TabsPageProps } from './types';

export const TabsPage = ({
  tabs,
  children,
  tabsProps,
  hideMobileTab,
  hideSingleTab,
  ...props
}: TabsPageProps) => {
  const pageName = usePageName(tabs);
  const isMoreThenOneTab = tabs && tabs?.length > 1;

  useHeader({
    pageName,
    tabsProps,
    // TODO: check is hideSingleTab needed
    tabs: hideSingleTab ? (isMoreThenOneTab ? tabs : undefined) : tabs,
    ...props,
  });

  return (
    <FlexLayout flex={1} fullWidth fullHeight flexDirection="column">
      {isMoreThenOneTab && !hideMobileTab && (
        <Responsive.Mobile>
          <Tabs
            items={tabs}
            innerStyle={{ padding: '0 16px' }}
            {...tabsProps}
          />
        </Responsive.Mobile>
      )}
      {children}
    </FlexLayout>
  );
};
