import { CorporateOptions } from 'types';
import { CorporateGroupedData } from 'store/api/corporate-group/types';

export const getCorporateGroupOptions = (
  data?: CorporateGroupedData
): CorporateOptions | undefined =>
  data &&
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value.map((item) => ({
        data: item,
        fieldName: key,
        label: item.name,
        value: String(item.id),
      })),
    ])
  );
