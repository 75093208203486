import dayjs from 'dayjs';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, BaseResponse } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { HospitalTask, HospitalTaskPet, HospitalTaskClient } from 'types';

import {
  Filters,
  TasksData,
  GetRequest,
  ListRequest,
  FilterValues,
  CreateRequest,
  UpdateRequest,
  CompleteRequest,
  FilterValuesRequest,
} from './types';

const { Query, Helpers } = Utils;

const isHospitalTask = (data: any): data is HospitalTask =>
  (data as HospitalTask).childTasks !== undefined;

const tagTypes = ['hospitalTasks'];

export const hospitalTasksApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'hospitalTasksApi',
  endpoints: (build) => ({
    complete: build.mutation<void, CompleteRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'patch',
        url: URL.TASKS_COMPLETE,
      }),
    }),

    create: build.mutation<HospitalTask, CreateRequest>({
      invalidatesTags: tagTypes,
      transformResponse: (response: BaseResponse<HospitalTask>) =>
        response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.TASKS,
      }),
    }),

    get: build.query<HospitalTask, GetRequest>({
      providesTags: tagTypes,
      transformResponse: (response: BaseResponse<HospitalTask>) =>
        response.data,
      query: ({ id, hospitalId }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.TASK, {
            id,
          }),
          { hospitalId }
        ),
      }),
    }),

    update: build.mutation<HospitalTask, UpdateRequest>({
      transformResponse: (response: BaseResponse<HospitalTask>) =>
        response.data,
      invalidatesTags: (_, __, { withoutInvalidation }) =>
        withoutInvalidation ? [] : tagTypes,
      query: ({
        id,
        listRequestData,
        detailsRequestData,
        withoutInvalidation,
        ...data
      }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.TASK, { id }),
      }),
      async onQueryStarted(
        { id, listRequestData, detailsRequestData, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const requestData = detailsRequestData || listRequestData;
        if (!requestData) {
          return;
        }
        const patchResult = dispatch(
          hospitalTasksApi.util.updateQueryData(
            detailsRequestData ? 'get' : 'list',
            requestData,
            (draft) => {
              const taskPath = isHospitalTask(draft)
                ? draft.parentTasks && draft.parentTasks.length
                  ? draft.parentTasks
                  : draft.childTasks
                : draft.rows;
              const currentTask = taskPath.find((task) => task.id === id);
              if (currentTask) {
                Object.assign(currentTask, patch);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    list: build.query<TasksData, ListRequest>({
      keepUnusedDataFor: 1,
      providesTags: tagTypes,
      transformResponse: (response: BaseResponse<TasksData>) => response.data,
      query: ({ search, filters, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);

        const {
          types,
          endDate,
          statuses,
          startDate,
          assigneeId,
          priorities,
          portalType,
          isCompleted,
          ...restFilters
        } = filters;

        const resultFilters: Filters = {
          ...restFilters,
        };

        if (types?.length) {
          resultFilters.types = types;
        }

        if (statuses?.length) {
          resultFilters.statuses = statuses;
        }

        if (portalType?.length) {
          resultFilters.portalType = portalType;
        }

        if (priorities?.length) {
          resultFilters.priorities = priorities;
        }

        if (assigneeId || assigneeId === null) {
          resultFilters.assigneeId = assigneeId ? +assigneeId : null;
        }

        if (isCompleted) {
          resultFilters.isCompleted = true;
        }

        if (startDate && endDate) {
          resultFilters.period = {
            to: dayjs(endDate).endOf('D').format(),
            from: dayjs(startDate).startOf('D').format(),
          };
        }

        const searchParams = {
          limit,
          search,
          offset,
          filters: JSON.stringify(resultFilters),
        };
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.TASKS, searchParams),
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useCompleteMutation,
} = hospitalTasksApi;

export type {
  Filters,
  TasksData,
  HospitalTask,
  FilterValues,
  CreateRequest,
  HospitalTaskPet,
  HospitalTaskClient,
  FilterValuesRequest,
};
