import styled, { css } from 'styled-components';
import { Icon, ListCard, List as TCList } from '@gv/triage-components';

export const ExpiredAt = styled.div<{ isExpired?: boolean }>`
  color: ${({ theme, isExpired }) =>
    isExpired ? theme.config.text.red : theme.config.text.primary};
`;

export const IconCard = styled(Icon.Card)`
  path {
    fill: ${({ theme }) => theme.config.text.primary};
  }
`;

export const CardContainer = styled.span`
  svg {
    margin-bottom: -8px;
  }
`;

export const List = styled(TCList)<{ $asPage?: boolean }>`
  ${({ $asPage }) =>
    !$asPage &&
    css`
      > div {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
      }
    `}
`;

export const Card = styled(ListCard)<{ isDefault?: boolean }>`
  ${({ theme, isDefault }) =>
    isDefault &&
    css`
      border: 1px solid ${theme.config.text.blue};
    `}
`;
