import { useMemo, Fragment } from 'react';
import {
  Icon,
  Utils,
  Badge,
  Badges,
  ListCard,
  FlexLayout,
  EllipsisText,
  StandardButton,
  EmptyPlaceholder,
  isMobile as isMobileHook,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { TaskPriorityBadge } from 'components';
import { selectAuthUser } from 'store/slices/auth';

import * as Styles from './styles';
import { TaskCardProps } from './types';
import { cells, getTaskProps, getButtonsOptions } from './config';

const { textValue, formatPhone } = Utils.Helpers;

export const TaskListCard = ({ task, onClick }: TaskCardProps) => {
  const isMobile = isMobileHook();
  const authUser = useAppSelector(selectAuthUser);
  const authUserId = authUser?.id;
  const hasAccess = task.hospital.id === authUser?.hospital_id;

  const { assignee, priority } = task;
  const { title, dateString } = getTaskProps(task);
  const { pet, client, problem_summary, chief_complaints } = task.case ?? {};

  const buttonsOptions = useMemo(
    () =>
      getButtonsOptions({
        task,
        onClick,
        hasAccess,
        assignedToMe: authUserId === assignee?.id,
      }),
    [onClick, authUserId, assignee?.id, task]
  );

  const priorityBadge = priority ? (
    <Styles.BadgeWrap>
      <TaskPriorityBadge priority={priority} />
    </Styles.BadgeWrap>
  ) : (
    <div />
  );

  const infoRows = (
    <Styles.Wrapper>
      <EllipsisText as="h3">{title}</EllipsisText>
      <Styles.UserRow>{dateString}</Styles.UserRow>
    </Styles.Wrapper>
  );

  const clientInfo = (
    <Styles.Wrapper gap={8}>
      <FlexLayout gap={8}>
        <Icon.User />
        <EllipsisText>
          {client?.name || formatPhone(client?.phone)}
        </EllipsisText>
      </FlexLayout>

      <FlexLayout gap={8}>
        <Icon.Pawprint />
        <EllipsisText>{textValue(pet?.name)}</EllipsisText>
      </FlexLayout>
    </Styles.Wrapper>
  );

  const chiefComplaints = (
    <Styles.Wrapper gap={4}>
      <Styles.Label>Chief complaint</Styles.Label>
      <Badges>
        {chief_complaints?.length
          ? chief_complaints.map((complaint, index) => (
              <Badge key={index} text={complaint} />
            ))
          : EmptyPlaceholder}
      </Badges>
    </Styles.Wrapper>
  );

  const description = (
    <Styles.Wrapper>
      <Styles.Label>Problem summary</Styles.Label>
      <p>{textValue(problem_summary)}</p>
    </Styles.Wrapper>
  );

  const buttons = (
    <FlexLayout
      gap={16}
      alignItems="center"
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
    >
      {buttonsOptions.map((option, idx) =>
        idx > 0 && !isMobile ? (
          <Fragment key={idx}>
            <Styles.Separator />
            <StandardButton {...option} />
          </Fragment>
        ) : (
          <StandardButton key={idx} {...option} fullWidth={isMobile} />
        )
      )}
    </FlexLayout>
  );

  return (
    <ListCard
      selected
      rowGap={8}
      isOdd={false}
      cells={cells}
      padding="16px"
      template="100%"
      values={[
        priorityBadge,
        infoRows,
        clientInfo,
        chiefComplaints,
        description,
        buttons,
      ]}
    />
  );
};
