import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from '.';

export const Play = () => {
  const { state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      icon={<Icon.Play />}
      onClick={controls?.play}
      disabled={state?.playing}
      colorType={ButtonColors.LightGreen}
    />
  );
};
