import { Pet, Utils } from '@gv/triage-components';

const { getFormattedAge } = Utils.Date;

export const prepareCardValues = (props: Pet | undefined) => [
  props?.name || '',
  props?.species || '',
  getFormattedAge(props?.date_of_birth),
  props?.breed || '',
  props?.gender || '',
];

export const PetsTemplate = {
  mobile: '1fr 1fr',
  desktop: 'repeat(5, 1fr)',
};

export const PetsCells = {
  mobile: [
    { row: '1/2', col: '1/3' },

    { row: '2/3', col: '1/2' },
    { row: '2/3', col: '2/3' },

    { row: '3/4', col: '1/2' },
    { row: '3/4', col: '2/3' },
  ],
};
