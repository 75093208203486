import { AxiosError } from 'axios';
import { Utils, AuthEmail } from '@gv/triage-components';

import { api } from 'api';
import { Config } from 'config';
import { resetApiState } from 'store/store';
import { createAppAsyncThunk } from 'store/helpers';
import { AuthGmail, AuthPetOwner } from 'api/auth/types';
import {
  clearAll,
  saveUser,
  getTokens,
  saveTokens,
  clearTokens,
  saveHospitalSpeciality,
} from 'utils/storage';

import { setLoading } from '../loader';

import { isUserAllowed } from './helpers';
import { types, AuthLogout, AuthClient } from './types';
import { clearAuth, setAuthError, setAuthPhone, updateAuthClient } from '.';

const { parseAxiosError } = Utils.Error;

export const login = createAppAsyncThunk(
  types.auth,
  async (payload: AuthEmail | AuthGmail, { rejectWithValue }) => {
    try {
      const data = await api.auth.login(payload);
      const authData = data?.data?.data;
      const { user, token, refreshToken } = authData;

      if (!isUserAllowed(user)) {
        throw new Error("You don't have permissions for this portal");
      }

      const isLoggedIn = user !== undefined;
      if (isLoggedIn) {
        saveUser(user);
        saveTokens({ token, refreshToken });

        if (Config.portalType.isHospital) {
          const hospital = await api.hospital.getHosptalDetails(
            user.hospital_id
          );
          localStorage.setItem('hospitalId', user.hospital_id);
          saveHospitalSpeciality(hospital.data.data.hospital.speciality);
        }
      }

      return { isLoggedIn, data: authData };
    } catch (error) {
      return rejectWithValue(parseAxiosError(error));
    }
  }
);

export const logout = createAppAsyncThunk(
  types.logout,
  async (values: AuthLogout, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      try {
        const { token, refreshToken } = getTokens();
        if (token && refreshToken) {
          if ('serviceWorker' in navigator && 'PushManager' in window) {
            const registration =
              await navigator.serviceWorker.getRegistration();
            if (registration) {
              const subscribed =
                await registration.pushManager.getSubscription();
              if (subscribed) {
                api.auth.pushUnsubscribe(subscribed);
              }
            }
          }

          const tokens = { token, refreshToken };
          if (Config.portalType.isPetOwner) {
            await api.auth.petOwnerLogout(tokens);
          } else {
            await api.auth.logout(tokens);
          }
        }
      } catch (error: any) {
        console.error(error.message);
      }

      if (values.error) {
        const message = parseAxiosError(values.error);
        const axiosError = values.error as AxiosError;
        const restorePath = !(
          axiosError.code === '401' &&
          (axiosError.response?.data as any)?.error?.msg !==
            'Invalid authorization token'
        );
        dispatch(
          setAuthError({
            message,
            restorePath,
          })
        );
      }
    } catch (error) {
    } finally {
      clearAll();
      dispatch(setLoading(false));
      dispatch(clearAuth());
      resetApiState(dispatch);
      return {};
    }
  }
);

export const loginPetOwner = createAppAsyncThunk(
  types.authPetOwner,
  async (payload: AuthPetOwner, { dispatch, rejectWithValue }) => {
    try {
      const data = await api.auth.petOwnerLogin(payload);
      const authData = data?.data?.data;
      const { token, profile, refreshToken } = authData ?? {};

      if (!token || !profile || !refreshToken) {
        throw new Error("Auth was broken, response doesn't have required info");
      }
      saveTokens({ token, refreshToken });

      const clientResponse = await api.client.getAuthClientDetails();
      const clientData = clientResponse?.data?.data;
      if (!clientData) {
        throw new Error("Auth was broken, can't get client info");
      }

      const client = {
        ...clientData,
        profileId: profile.id,
      } as AuthClient;
      const { phone } = payload;

      dispatch(updateAuthClient(client));
      dispatch(setAuthPhone({ phoneNumber: phone }));

      return { client, isLoggedIn: true };
    } catch (error) {
      clearTokens();
      return rejectWithValue(parseAxiosError(error));
    }
  }
);
