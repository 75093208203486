import dayjs from 'dayjs';
import { RecommendationTitle } from '@gv/triage-components';

import { AvailabilityTime, CallOutcomeStatus } from 'types';

import { getCheckboxesItems } from './helpers';

export const appointmentRecommendationItems = Object.entries(
  RecommendationTitle
).map(([value, label]) => ({
  label,
  value,
}));

export const availabilityDaysItems = getCheckboxesItems(dayjs.weekdaysShort());

export const availabilityTimeItems = getCheckboxesItems(AvailabilityTime);

export const clientConfirmItems = [
  {
    value: 'true',
    label: CallOutcomeStatus.Accepted,
  },
  {
    value: 'false',
    label: CallOutcomeStatus.Declined,
  },
];
