import {
  Row,
  Col,
  Icon,
  useNavigate,
  AuthPOLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { usePetOwnerHomePath } from 'hooks';

import * as Styles from './styles';
import { PetOwnerSuccessRequestProps } from './types';

export const PetOwnerSuccessRequest = ({
  side,
  title,
  button,
  subtitle,
  children,
  isChildrenBeforeSide,
}: PetOwnerSuccessRequestProps) => {
  const navigate = useNavigate();
  const { homePath } = usePetOwnerHomePath();

  return (
    <AuthPOLayout
      title={title}
      isAppointmentCard
      subtitle={subtitle}
      side={<Icon.CheckmarkLarge />}
    >
      <Styles.Container>
        {isChildrenBeforeSide && children}
        <Row>
          <Col>{side}</Col>
        </Row>
        {!isChildrenBeforeSide && <Col>{children}</Col>}
        {button ||
          (homePath && (
            <Col>
              <StandardButton
                text="Go to dashboard"
                colorType={ButtonColors.WhiteBlack}
                onClick={() => navigate(homePath, { replace: true })}
              />
            </Col>
          ))}
      </Styles.Container>
    </AuthPOLayout>
  );
};
