import { useInterval } from 'usehooks-ts';
import { StandardButton } from '@gv/triage-components';
import { useMemo, useEffect, useCallback } from 'react';

import { Config } from 'config';
import { useAblyEvent } from 'hooks';
import { useLazyVersionQuery } from 'store/api/common';

import * as Styles from './styles';
import { OutdatedBannerProps } from './types';

export const OutdatedBanner = ({ polling }: OutdatedBannerProps) => {
  const { buildVersion } = Config;

  const hasVersion = !!buildVersion;

  const [trigger, data] = useLazyVersionQuery();

  const currentVersion = data.currentData?.version;

  const updateVersion = useCallback(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (hasVersion) {
      updateVersion();
    }
  }, [hasVersion]);

  useInterval(updateVersion, polling ? 60000 : null);

  useEffect(() => {
    if (hasVersion) {
      window.addEventListener('online', updateVersion);
      if (polling) {
        window.addEventListener('focus', updateVersion);
      }
      return () => {
        window.removeEventListener('online', updateVersion);
        if (polling) {
          window.removeEventListener('focus', updateVersion);
        }
      };
    }
  }, [polling, updateVersion, hasVersion]);

  useAblyEvent('triage:build-version', updateVersion);

  const hasUpdate = useMemo(
    () =>
      hasVersion &&
      buildVersion &&
      Number(buildVersion) < Number(currentVersion),
    [buildVersion, currentVersion]
  );

  if (!hasUpdate) {
    return null;
  }

  const onRefresh = () => window.location.reload();

  return (
    <Styles.Container onClick={onRefresh}>
      A new version {currentVersion} is available. Please{' '}
      <StandardButton
        transparent
        padding="0"
        text="click here"
        onClick={onRefresh}
      />{' '}
      to update from the {buildVersion} version.
    </Styles.Container>
  );
};
