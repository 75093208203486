import { useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Icon,
  Timer,
  BadgeColor,
  useNavigate,
  useTimeouts,
  ActionQueueType,
  ConversationStatusIcon,
} from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { activeCallProps } from 'utils/helpers';
import { OutboundContext } from 'context/outbound';
import { selectCurrentTask } from 'store/api/action-queue/selectors';
import { OutboundBadgePopup } from 'components/outbound-badge-popup';
import { ConversationBadgePopup } from 'components/conversation-badge-popup';

import * as Styles from '../styles';

import { getBadgeColor } from './helpers';

interface BadgeItem {
  timers: any[];
  getColor: () => BadgeColor;
  badge: Record<string, any>;
  isVisible: (pathname: string) => boolean;
}

export const useConverstationBadges = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTask = useAppSelector(selectCurrentTask);
  const { startedAt, activeCall: outboundCall } = useContext(OutboundContext);

  const badges = useMemo((): BadgeItem[] => {
    if (outboundCall) {
      const outboundBadge = {
        timers: [],
        getColor: () => {
          return BadgeColor.Green;
        },
        isVisible: (pathname: string) =>
          !pathname.includes(Routes.Outbound.Home),
        badge: {
          icon: <Icon.Call />,
          popup: (
            <OutboundBadgePopup
              onClick={() => navigate(Routes.Outbound.Home)}
            />
          ),
          text: startedAt ? (
            <Timer started={startedAt.getTime()}>
              {(seconds) => <Styles.Text>{seconds}</Styles.Text>}
            </Timer>
          ) : (
            <Styles.Text>Outbound call</Styles.Text>
          ),
        } as any,
      };
      return [outboundBadge];
    }
    if (currentTask) {
      const {
        name,
        phone,
        started,
        finished,
        conversationType,
        conversationStatus,
      } = activeCallProps(currentTask);

      const isCall = conversationType === ActionQueueType.Voice;
      const timers = started ? [{ seconds: 300, date: started }] : [];

      const taskBadge = {
        timers,
        getColor: () => getBadgeColor(started, conversationStatus),
        isVisible: (pathname: string) =>
          !pathname.includes(Routes.ActionCenter.Comm),
        badge: {
          icon: ConversationStatusIcon[conversationStatus][conversationType],
          popup: (
            <ConversationBadgePopup
              name={name}
              phone={phone}
              type={conversationType}
              onClick={() => navigate(Routes.ActionCenter.Comm)}
            />
          ),
          text: isCall ? (
            <Timer started={started} finished={finished}>
              {(seconds) => <Styles.Text>{seconds}</Styles.Text>}
            </Timer>
          ) : (
            <Styles.Text>Ongoing</Styles.Text>
          ),
        } as any,
      };
      return [taskBadge];
    }
    return [];
  }, [currentTask, outboundCall, startedAt]);

  const activeBadges = useMemo(
    () => badges.filter((badge) => badge.isVisible(location.pathname)),
    [badges, location.pathname]
  );

  const timers = useMemo(
    () => activeBadges.map((badge) => badge.timers).flat(),
    [activeBadges]
  );

  const timer = useTimeouts(timers);

  const styleTypes = useMemo(
    () => activeBadges.map((badge) => badge.getColor()),
    [activeBadges, timer]
  );

  const renderedBadges = useMemo(
    () =>
      activeBadges.map((badge, index) => ({
        ...badge.badge,
        styleType: styleTypes[index],
      })),
    [styleTypes, activeBadges]
  );

  return renderedBadges;
};
