import styled from 'styled-components';

export const Dots = styled.div`
  flex: 1;
  border-top: 1px dashed ${({ theme }) => theme.colors.grey.secondary.standard};
`;

export const Required = styled.span`
  color: ${({ theme }) => theme.colors.red.primary.standard};
`;
