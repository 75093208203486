import { Device } from '@twilio/voice-sdk';
import { createSelector } from '@reduxjs/toolkit';
import { UserRoleType, ChatParseOptions } from '@gv/triage-components';

import { RootState } from 'store/store';
import { PagePermissions } from 'types';
import { Config, adminRoles } from 'config';
import { conversationName } from 'utils/helpers';
import { getHospitalSpeciality } from 'utils/storage';
import { hospitalsStore } from 'store/api/hospitals/selectors';

import { types } from './types';
import { hasRoles } from './helpers';
import { login, logout } from './thunks';

const authStore = (store: RootState) => store?.auth;

const allStore = (store: RootState) => store;

const selectIsLoggedIn = createSelector(
  [authStore],
  (store) => store?.isLoggedIn ?? false
);

const selectPermissions = createSelector(
  [authStore],
  (store) => store?.permission ?? {}
);

const selectAuthError = createSelector([authStore], (store) => store?.error);

const selectAuthRestorePath = createSelector(
  [authStore],
  (store) => store?.path
);

const selectSubscriptionRestorePath = createSelector(
  [authStore],
  (store) => store?.subscriptionPath
);

const selectAuthUser = createSelector([authStore], (store) => store?.user);

const selectAuthId = createSelector([authStore], (store) => store?.user?.id);

const selectIsNurseStatusSupported = createSelector(
  [authStore],
  (store) => store?.user?.id && !store.user.hospital_id
);

const selectNurseStatus = createSelector([allStore], (store) =>
  selectIsNurseStatusSupported(store) ? store?.auth?.nurseStatus : undefined
);

const selectRealNurseStatus = createSelector(
  [authStore],
  (store) => store?.nurseStatus
);

const selectConversationName = createSelector([authStore], (store) =>
  conversationName(store?.user)
);

const selectAuthHospitalId = createSelector(
  [authStore],
  (store) => store?.user?.hospital_id
);

const selectHasRoles = (roles: UserRoleType[]) =>
  createSelector([authStore], (store) => hasRoles(store?.user?.roles, roles));

const selectUserRole = createSelector(
  [authStore],
  (store) => store?.user?.roles[0] as UserRoleType
);

const selectIsAdmin = createSelector([authStore], (store) =>
  hasRoles(store?.user?.roles, adminRoles)
);

const selectAuthHospital = createSelector(
  [authStore, hospitalsStore],
  (storeAuth, storeHospitals) => {
    if (!storeAuth) {
      return undefined;
    }
    const hospitalId = storeAuth?.user?.hospital_id;
    return hospitalId ? storeHospitals?.entities[hospitalId] : undefined;
  }
);

const selectPagePermissions = createSelector([authStore], (store) => {
  const permissions = store?.permission ?? {};
  const hospitalId = store?.user?.hospital_id;
  const { isTeam, isHospital } = Config.portalType;
  const isSuperAdmin = hasRoles(store?.user?.roles, [UserRoleType.SuperAdmin]);
  const hospitalSpeciality = store?.speciality ?? getHospitalSpeciality();

  let values: PagePermissions = {};
  if (permissions.preference && hospitalId) {
    values.hospital = true;
  }

  if (permissions.actionItem && hospitalSpeciality && hospitalId) {
    values.actionItems = true;
  }

  if (isTeam && permissions.communication && Device.isSupported) {
    values.queue = true;
    values.outbound = true;
  }

  if (permissions.voicemail && hospitalId) {
    values.voicemails = true;
  }

  if (permissions.case) {
    values.cases = true;
  }

  if (permissions.hospital && !hospitalId) {
    values.hospitals = true;
  }

  if (permissions.calendar && hospitalId) {
    values.calendar = true;
  }

  // if (permissions.phoneLineTesting) {
  //   values.phoneLineTesting = true;
  // }

  if (isTeam) {
    values.team = true;
    if (isSuperAdmin) {
      values.internalTasks = true;
    }
  }

  if (permissions.feedback) {
    // values.feedbacks = true;
  }

  if (permissions.analytic) {
    if (isHospital) {
      values.analytics = true;
    }

    if (isTeam) {
      values.teamAnalytics = true;
    }

    if (isHospital) {
      values.clients = true;
    }
  }

  if (isTeam && isSuperAdmin) {
    values.ivr = true;
    values.nurses = true;
    values.recordings = true;
    values.ringGroups = true;
    values.masterRecords = true;
    values.subscriptions = true;
    values.callNotifications = true;
  }

  if (isHospital) {
    values.internalTasks = true;
    values.help = true;
    values.dashboard = true;
  }

  if (permissions.gvtalk) {
    values.office = true;
  }

  if (values.actionItems && values.voicemails && values.cases) {
    values.tasks = true;
  }

  if (permissions.paymentsWrite) {
    values.paymentsWrite = true;
  }

  if (permissions.dynamicFlowsWrite) {
    values.dynamicFlowsWrite = true;
  }

  return values;
});

const selectPauseIdleTimer = createSelector(
  [authStore],
  (store) => store?.pauseIdleTimer ?? false
);

const selectAuthClient = createSelector([authStore], (store) => store?.client);

const selectAuthPhoneNumber = createSelector(
  [authStore],
  (store) => store?.authPhoneNumber
);

const selectAuthForGVTalk = createSelector(
  [authStore],
  (store): ChatParseOptions => ({
    authUserId: store.user?.id,
    authClientId: store.client?.id,
    authProfileId: store.client?.profileId ?? store.user?.profileId,
  })
);

const selectAuthDataForChat = createSelector([authStore], (store) => ({
  authorId: store.user?.id ?? store.client?.id ?? 0,
  name: store.user?.name ?? store.client?.name ?? '',
}));

export {
  login,
  types,
  logout,
  selectAuthId,
  selectIsAdmin,
  selectHasRoles,
  selectUserRole,
  selectAuthUser,
  selectAuthError,
  selectIsLoggedIn,
  selectAuthClient,
  selectNurseStatus,
  selectPermissions,
  selectAuthHospital,
  selectAuthForGVTalk,
  selectPauseIdleTimer,
  selectAuthHospitalId,
  selectAuthRestorePath,
  selectRealNurseStatus,
  selectPagePermissions,
  selectAuthPhoneNumber,
  selectAuthDataForChat,
  selectConversationName,
  selectIsNurseStatusSupported,
  selectSubscriptionRestorePath,
};
