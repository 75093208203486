import { Form } from 'formik';
import styled from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const PickerWrap = styled(FlexLayout)`
  em-emoji-picker {
    max-height: 360px;
  }
`;

export const ListWrap = styled(FlexLayout)`
  flex-direction: column;
  gap: 8px;

  svg path {
    fill: ${({ theme }) => theme.config.text.primary};
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
