import { Popup } from '@gv/triage-components';

import * as Styles from './styles';
import { DisabledReasonWrapProps } from './types';

export const DisabledReasonWrap = ({
  children,
  disabledReason,
}: DisabledReasonWrapProps) => {
  if (disabledReason.length > 0) {
    return (
      <Popup on="hover" maxWidth="75vw" trigger={<div>{children}</div>}>
        <Styles.Text>{disabledReason}</Styles.Text>
      </Popup>
    );
  }
  return children;
};
