import { useMemo } from 'react';
import {
  Timer,
  Badge,
  OpenStatus,
  BadgePadding,
  OpenStatusBadge,
  WorkingHoursUtils,
  BadgeBorderRadius,
  OpenStatusTextHospital,
} from '@gv/triage-components';

import * as Styles from './styles';
import { BadgesProps } from './types';

const { getData, getOpenStatus, getHospitalTime } = WorkingHoursUtils;

export const ERBadges = ({ hospital, referral }: BadgesProps) => {
  const schedule = useMemo(() => {
    return getData(referral.clinic_hours).items;
  }, [referral]);

  return (
    <Timer started={1} tick={30000}>
      {() => {
        const time = getHospitalTime(hospital).time;
        if (!time) {
          return null;
        }

        const status = getOpenStatus(time, schedule, OpenStatusTextHospital);
        if (!status) {
          return null;
        }

        return (
          <Styles.Badges>
            <Badge
              smallFont
              text={status.title}
              padding={BadgePadding.Medium}
              borderRadius={BadgeBorderRadius.Small}
              styleType={OpenStatusBadge[status.type as OpenStatus]}
            />
          </Styles.Badges>
        );
      }}
    </Timer>
  );
};
