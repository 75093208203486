import { Icon } from '@gv/triage-components';
import styled, { css } from 'styled-components';

export const ExpiredAt = styled.div<{ $isExpired?: boolean }>`
  color: ${({ theme, $isExpired }) =>
    $isExpired ? theme.config.text.red : theme.config.text.primary};
`;

export const IconCard = styled(Icon.Card)`
  path {
    fill: ${({ theme }) => theme.config.text.primary};
  }
`;

export const Container = styled.span<{ $inline?: boolean }>`
  gap: 8px;
  display: inline-flex;
  align-items: center;
  height: 24px;

  ${({ $inline }) =>
    $inline &&
    css`
      transform: translateY(8px);
    `}
`;
