import { Client, Nullable, TriageUser } from '@gv/triage-components';

import { Permission, NurseStatus } from 'types';

export interface AuthClient extends Client {
  profileId: number;
}

export interface AuthState {
  error?: Error;
  path?: string;
  user?: TriageUser;
  client?: AuthClient;
  isLoggedIn: boolean;
  permission: Permission;
  pauseIdleTimer: boolean;
  authPhoneNumber?: string;
  subscriptionPath?: string;
  nurseStatus?: NurseStatus;
  speciality: Nullable<string>;
}

export interface AuthLogout {
  error?: Error;
  byUser?: boolean;
}

export const types = {
  auth: 'auth/login',
  logout: 'auth/logout',
  authPetOwner: 'auth/login-pet-owner',
};
