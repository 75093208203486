import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateAPIPath } from 'store/query';

import { clientsApi, paymentClientTag } from '../clients';

import {
  ProductRequest,
  SubscribersData,
  ProductsRequest,
  GVFeeGetRequest,
  SubscribePayload,
  SubscriptionState,
  GVFeeUpdateRequest,
  EditProductRequest,
  SubscribersRequest,
  SubscriptionPackage,
  CreateProductRequest,
  ProductStatusRequest,
  PaymentHospitalSetting,
  UnsubscribeByHospitalRequest,
  SubscriptionProductsResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;

const gvFeeTags = ['GvFee'];
const productsTags = ['Products'];
const subscriptionStateTags = ['SubscriptionState'];
const subscribersTags = ['Subscribers'];

export const subscriptionsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'subscriptionsApi',
  tagTypes: [...productsTags, ...subscribersTags, ...subscriptionStateTags],
  endpoints: (build) => ({
    subscriptions: build.query<SubscriptionState[], void>({
      providesTags: subscriptionStateTags,
      query: () => ({
        method: 'get',
        url: URL.SUBSCRIPTIONS,
      }),
    }),

    product: build.query<SubscriptionPackage, ProductRequest>({
      providesTags: productsTags,
      query: ({ id }) => ({
        method: 'get',
        url: generateAPIPath(URL.SUBSCRIPTION_PRODUCT, { id }),
      }),
    }),

    create: build.mutation<SubscriptionPackage, CreateProductRequest>({
      invalidatesTags: productsTags,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.SUBSCRIPTION_PRODUCTS,
      }),
    }),

    unsubscribeByHospital: build.mutation<void, UnsubscribeByHospitalRequest>({
      invalidatesTags: subscribersTags,
      query: (data) => ({
        method: 'delete',
        url: generateAPIPath(URL.UNSUBSCRIBE_BY_HOSPITAL, data),
      }),
    }),

    edit: build.mutation<SubscriptionPackage, EditProductRequest>({
      invalidatesTags: productsTags,
      query: ({ id, ...data }) => ({
        data: data,
        method: 'patch',
        url: generateAPIPath(URL.SUBSCRIPTION_PRODUCT, { id }),
      }),
    }),

    productStatus: build.mutation<SubscriptionPackage, ProductStatusRequest>({
      invalidatesTags: productsTags,
      query: ({ id, ...data }) => ({
        data: data,
        method: 'patch',
        url: generateAPIPath(URL.SUBSCRIPTION_PRODUCT_ACTIVE, { id }),
      }),
    }),

    products: build.query<SubscriptionProductsResponse, ProductsRequest>({
      providesTags: productsTags,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: addParamsToUrl(URL.SUBSCRIPTION_PRODUCTS, {
          hospitalId: String(hospitalId),
        }),
      }),
    }),

    gvFee: build.query<PaymentHospitalSetting, GVFeeGetRequest>({
      keepUnusedDataFor: 1,
      providesTags: gvFeeTags,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: URL.HOSPITAL_PAYMENT_SETTINGS.replace(
          ':hospitalId',
          String(hospitalId)
        ),
      }),
    }),

    updateGvFee: build.mutation<void, GVFeeUpdateRequest>({
      invalidatesTags: [...gvFeeTags, ...productsTags, ...subscribersTags],
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: URL.HOSPITAL_PAYMENT_SETTINGS.replace(
          ':hospitalId',
          String(hospitalId)
        ),
      }),
    }),

    subscribe: build.mutation<void, SubscribePayload>({
      invalidatesTags: subscriptionStateTags,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.SUBSCRIPTIONS,
      }),
      async onCacheEntryAdded(payload, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(clientsApi.util.invalidateTags([paymentClientTag]));
      },
    }),

    unsubscribe: build.mutation<void, number>({
      invalidatesTags: subscriptionStateTags,
      query: (id) => ({
        method: 'delete',
        url: generateAPIPath(URL.UNSUBSCRIBE, { id }),
      }),
      async onCacheEntryAdded(payload, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(clientsApi.util.invalidateTags([paymentClientTag]));
      },
    }),

    subscribers: build.query<SubscribersData, SubscribersRequest>({
      providesTags: subscribersTags,
      query: ({ page, limit = 20, hospitalId, ...request }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...request,
        };
        const url = addParamsToUrl(
          generateAPIPath(URL.GET_HOSPITAL_SUBSCRIBERS, { hospitalId }),
          searchParams
        );
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useGvFeeQuery,
  useProductQuery,
  useEditMutation,
  useProductsQuery,
  useCreateMutation,
  useSubscribersQuery,
  useSubscribeMutation,
  useSubscriptionsQuery,
  useUnsubscribeMutation,
  useUpdateGvFeeMutation,
  useProductStatusMutation,
  useLazySubscriptionsQuery,
  useUnsubscribeByHospitalMutation,
} = subscriptionsApi;
