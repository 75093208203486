import styled, { css } from 'styled-components';
import { Checkbox as TCCheckbox } from '@gv/triage-components';

export const Checkbox = styled(TCCheckbox)`
  ${({ block }) =>
    block &&
    css`
      padding: 0 6px;
    `}
`;
