import styled, { css } from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Row = styled(FlexLayout)`
  ${({ theme }) => css`
    gap: 16px;
    ${theme.responsive.isMobile
      ? css`
          flex-direction: column;
        `
      : css`
          justify-content: space-between;
        `};
  `};
`;

export const ContactInfo = styled(FlexLayout)`
  ${({ theme }) => css`
    gap: 4px;
    height: 32px;
    align-items: center;
    svg {
      box {
        width: 40%;
      }
      path {
        fill: ${theme.config.userInfo.text.subtitle};
      }
    }
  `};
`;

export const InfoContainer = styled(FlexLayout)`
  flex: 1;
  gap: 10px;
  flex-direction: column;
  padding: 10px 16px 8px 16px;
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    background-color: ${theme.config.appointmentCard.bg};
    svg {
      path {
        fill: ${theme.config.text.primary};
      }
    }
  `};
`;

export const DashedView = styled(FlexLayout)`
  flex: 1;
  border-bottom: ${({ theme }) =>
    `1px dashed ${theme.config.userInfo.text.gvSubtitle}`};
`;
