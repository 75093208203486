import axios from 'axios';
import { UploadFile, GetUploadServerResponse } from '@gv/triage-components';

import { getFileType } from 'utils/helpers';

import { URL } from '../constants';
import { apiInstance, apiLoaderInstance } from '../instance';

export const multimedia = async (
  formData: FormData,
  url?: string,
  transformResponse?: (response?: any) => any
): Promise<any> => {
  const response = await apiLoaderInstance.post(
    url || URL.UPLOAD_MULTIMEDIA,
    formData
  );

  return transformResponse?.(response) || response.data.data.message;
};

export const uploadFile = async (file?: File) => {
  if (!file) {
    return;
  }

  const serverPayload = {
    contentType: file.type,
    extension: file.name.split('.').at(-1),
  };

  const { data } = await apiInstance.post(
    URL.GET_GV_TALK_FILE_SERVER,
    serverPayload
  );
  const { url, fields } = data?.data || ({} as GetUploadServerResponse);

  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) =>
    formData.append(key, value as Blob | string)
  );
  formData.append('file', file);

  await axios.post(url, formData);

  return {
    key: fields.key,
    name: file.name,
    size: file.size,
    url: url + fields.key,
    type: getFileType(file),
  };
};

export const uploadMedia = async (files: UploadFile[]) => {
  if (!files.length) {
    return null;
  }

  const media = files.map(({ file }) => file);
  return Promise.all(media.map((item: File) => uploadFile(item)));
};
