import {
  Icon,
  Utils,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as CommonStyles from 'components/communication-log/styles';

import { SelectedEventPageProps } from '../types';

export const TextOutEvent = ({
  event,
  onClose,
  parsedEvent,
}: SelectedEventPageProps): JSX.Element => {
  const details =
    event.details && 'body' in event.details ? event.details : undefined;
  return (
    <>
      <CommonStyles.Header>
        <StandardButton
          padding="0"
          onClick={onClose}
          icon={<Icon.ChevronLeft />}
          colorType={ButtonColors.Transparent}
        />

        <div>
          <h3>Text out to {parsedEvent.name}</h3>
          <p className="caption-regular">{parsedEvent.role}</p>
        </div>
      </CommonStyles.Header>

      <CommonStyles.EventContent>
        <p className="pre-wrap">
          {Utils.Helpers.joinStrings([details?.body, details?.note], '\n')}
        </p>
      </CommonStyles.EventContent>
    </>
  );
};
