import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';
import { cacheByIdArg, providesList } from 'utils/query-cache';

import {
  Voicemail,
  VoicemailData,
  VoicemailUpdate,
  VoicemailResponse,
  VoicemailsRequest,
  VoicemailsResponse,
} from './types';

const voicemailAdapter = createEntityAdapter<Voicemail, number>({
  selectId: (item) => item.id,
});

const tagType = 'Voicemails';

export const voicemailApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'voicemailApi',
  endpoints: (build) => ({
    update: build.mutation<void, VoicemailUpdate>({
      invalidatesTags: [tagType],
      query: (payload) => ({
        data: payload,
        method: 'put',
        url: URL.UPDATE_VOICEMAIL,
      }),
    }),
    complete: build.mutation<void, number>({
      invalidatesTags: cacheByIdArg(tagType),
      query: (id) => ({
        data: { id },
        method: 'put',
        url: URL.CHANGE_VOICEMAIL_READ_STATUS,
      }),
    }),
    details: build.query<Voicemail, number>({
      providesTags: cacheByIdArg(tagType),
      transformResponse: (response: VoicemailResponse) => response.data,
      query: (id) => ({
        method: 'get',
        url: URL.VOICEMAIL.replace('{id}', id.toString()),
      }),
    }),
    list: build.query<VoicemailData, VoicemailsRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: VoicemailsResponse) => response.data,
      query: ({ search, page = 1, limit = 20 }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(URL.VOICEMAILS, {
          limit,
          search,
          offset: Utils.Helpers.offset(page, limit),
        }),
      }),
    }),
  }),
});

export const voicemailSlice = createSlice({
  reducers: {},
  name: 'voicemails',
  initialState: voicemailAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      voicemailApi.endpoints.list.matchFulfilled,
      (state, { payload }) => voicemailAdapter.setAll(state, payload.rows)
    );

    builder.addMatcher(isLogoutAction, (state) =>
      voicemailAdapter.removeAll(state)
    );
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useUpdateMutation,
  useCompleteMutation,
} = voicemailApi;
