import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  #jsd-widget { 
    transform: scale(0.8);
    z-index: 9998 !important;
  }
  
  .portal-team {
    #jsd-widget {
      left: 5px;
      visibility: hidden;
      right: unset !important;
      bottom: -5px !important;
    }

    &.side-bar-visible {
      #jsd-widget {
        visibility: visible;
      }
    }
  }
  
  .portal-hospital {
    #jsd-widget {
      visibility: hidden;
    }
    
    &.jsd-visible {
      #jsd-widget {
        visibility: visible;
      }
    }
  }

  .portal-pet-owner {
    #jsd-widget {
      visibility: hidden;
    }
    
    &.jsd-visible {
      #jsd-widget {
        visibility: visible;
      }
    }
  }
  
  .__floater__arrow {
    polygon {
      fill: ${({ theme }) => (theme as any).config.background};
    }
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
`;
