import { composeName } from 'utils/helpers';
import {
  CallOutcomeType,
  ConsultationStep,
  CallOutcomeEmergency,
  CallOutcomeAppointment,
  CallOutcomeAdministrative,
} from 'types';

export const stepTitle = {
  [ConsultationStep.Greeting]: 'Greeting',
  [ConsultationStep.PetOwner]: 'Client info',
  [ConsultationStep.CallOutcome]: 'Call outcome',
  [ConsultationStep.DeadlineIssue]: 'Deadline issue',
  [ConsultationStep.ProblemSummary]: 'Problem summary',
};

export const stepTitleMobile = {
  [ConsultationStep.Greeting]: 'Greeting',
  [ConsultationStep.CallOutcome]: 'Outcome',
  [ConsultationStep.PetOwner]: 'Client info',
  [ConsultationStep.ProblemSummary]: 'Problem',
  [ConsultationStep.DeadlineIssue]: 'Deadline issue',
};

export const datetime = 'datetime';
export const callOutcome = 'callOutcome';
export const survey = 'survey';
export const type = 'type';
export const feedback_email = 'feedback_email';

const accepted = 'accepted';
const corporateHospitalId = 'corporate_hospital_id';

const emergency = composeName(callOutcome, CallOutcomeType.Emergency);
const appointment = composeName(callOutcome, CallOutcomeType.Appointment);
const administrative = composeName(callOutcome, CallOutcomeType.Administrative);

const appointmentRecommended = composeName(
  appointment,
  CallOutcomeAppointment.Recommended
);
const appointmentScheduled = composeName(
  appointment,
  CallOutcomeAppointment.Scheduled
);
const appointmentCancelled = composeName(
  appointment,
  CallOutcomeAppointment.Cancelled
);
const appointmentDeclined = composeName(
  appointment,
  CallOutcomeAppointment.Declined
);

export const emergencyReferToOnCall = composeName(
  emergency,
  CallOutcomeEmergency.ReferToOnCall
);
export const emergencyReferToEr = composeName(
  emergency,
  CallOutcomeEmergency.ReferToEr
);
const emergencyReferToPPH = composeName(
  emergency,
  CallOutcomeEmergency.ReferToPPH
);

const emergencyInbound = composeName(emergency, CallOutcomeEmergency.Inbound);

const emergencyClientDeclined = composeName(
  emergency,
  CallOutcomeEmergency.ClientDeclined
);

const administrativeCall = composeName(
  administrative,
  CallOutcomeAdministrative.Call
);

export const surveyModal = {
  feedback_email: composeName(survey, feedback_email),
};

export const callOutcomeModel = {
  type: composeName(callOutcome, type),
  [CallOutcomeType.TransferToBackline]: {
    type: composeName(callOutcome, CallOutcomeType.TransferToBackline, type),
  },
  [CallOutcomeType.Administrative]: {
    [CallOutcomeAdministrative.Call]: {
      outcomes: composeName(administrativeCall, 'outcomes'),
      corporateHospitalId: composeName(administrativeCall, corporateHospitalId),
    },
  },
  [CallOutcomeType.Appointment]: {
    type: composeName(callOutcome, CallOutcomeType.Appointment, type),
    [CallOutcomeAppointment.Declined]: {
      reason: composeName(appointmentDeclined, 'reason'),
    },
    [CallOutcomeAppointment.Cancelled]: {
      parentTaskId: composeName(appointmentCancelled, 'parentTaskId'),
    },
    [CallOutcomeAppointment.Scheduled]: {
      datetime: composeName(appointmentScheduled, datetime),
      clientType: composeName(appointmentScheduled, 'clientType'),
      corporateHospitalId: composeName(
        appointmentScheduled,
        corporateHospitalId
      ),
    },
    [CallOutcomeAppointment.Recommended]: {
      recommendation: composeName(appointmentRecommended, 'recommendation'),
      availabilityDays: composeName(appointmentRecommended, 'availabilityDays'),
      availabilityTime: composeName(appointmentRecommended, 'availabilityTime'),
      corporateHospitalId: composeName(
        appointmentRecommended,
        corporateHospitalId
      ),
    },
  },
  [CallOutcomeType.Emergency]: {
    type: composeName(callOutcome, CallOutcomeType.Emergency, type),
    [CallOutcomeEmergency.ReferToPPH]: {
      accepted: composeName(emergencyReferToPPH, accepted),
      corporateHospitalId: composeName(
        emergencyReferToPPH,
        corporateHospitalId
      ),
    },
    [CallOutcomeEmergency.Inbound]: {
      accepted: composeName(emergencyInbound, accepted),
      datetime: composeName(emergencyInbound, datetime),
      doctorId: composeName(emergencyInbound, 'doctorId'),
      corporateHospitalId: composeName(emergencyInbound, corporateHospitalId),
    },
    [CallOutcomeEmergency.ReferToOnCall]: {
      accepted: composeName(emergencyReferToOnCall, accepted),
      datetime: composeName(emergencyReferToOnCall, datetime),
      doctorId: composeName(emergencyReferToOnCall, 'doctorId'),
      corporateHospitalId: composeName(
        emergencyReferToOnCall,
        corporateHospitalId
      ),
    },
    [CallOutcomeEmergency.ReferToEr]: {
      accepted: composeName(emergencyReferToEr, accepted),
      datetime: composeName(emergencyReferToEr, datetime),
      hospitalId: composeName(emergencyReferToEr, 'hospitalId'),
      hospitalRefId: composeName(emergencyReferToEr, 'hospitalRefId'),
      corporateHospitalId: composeName(emergencyReferToEr, corporateHospitalId),
    },
    [CallOutcomeEmergency.ClientDeclined]: {
      accepted: composeName(emergencyClientDeclined, accepted),
      datetime: composeName(emergencyClientDeclined, datetime),
      hospitalId: composeName(emergencyClientDeclined, 'hospitalId'),
      hospitalRefId: composeName(emergencyClientDeclined, 'hospitalRefId'),
      corporateHospitalId: composeName(
        emergencyClientDeclined,
        corporateHospitalId
      ),
    },
  },
};
