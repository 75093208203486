import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { isLogoutAction } from '../auth';

import { CallData } from './types';

const initialState: CallData = {
  isOnCall: false,
  isOutboundCall: false,
};

export const callSlice = createSlice({
  initialState,
  name: 'callSlice',
  reducers: {
    setOnCall: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnCall = payload;
    },
    setOutboundCall: (state, { payload }: PayloadAction<boolean>) => {
      state.isOutboundCall = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isLogoutAction, (state) => {
      Object.entries(initialState).forEach((item) => {
        const [key, value] = item;
        // @ts-ignore
        state[key] = value;
      });
    });
  },
});

const {
  reducer: callReducer,
  actions: { setOnCall, setOutboundCall },
} = callSlice;

const callStore = (store: RootState) => store?.call;

export const selectIsOnCall = () =>
  createSelector([callStore], (store) => store?.isOnCall ?? false);

export const selectIsOutboundCall = () =>
  createSelector([callStore], (store) => store?.isOutboundCall ?? false);

export { setOnCall, callReducer, setOutboundCall };
