import { useFormikContext } from 'formik';
import {
  Row,
  Col,
  InputField,
  SectionHeader,
  RadioFieldGroup,
} from '@gv/triage-components';

import { GreetingType } from 'types';

import { AudioDropzone } from '../audio-dropzone';

import * as Styles from './styles';
import { AudioRecord } from './audio-record';
import { FormValues, RecordingFormProps } from './types';

export const RecordingForm = ({ isRecording }: RecordingFormProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const colProps = { col: isRecording ? 12 : 8 };

  return (
    <Styles.Inner isRecording={isRecording}>
      <Row>
        <Col {...colProps}>
          <InputField label="Name" placeholder="Name" name="greeting_name" />
        </Col>
      </Row>

      <SectionHeader title={isRecording ? 'Recording' : 'Message'} />
      <Row>
        <Col {...colProps}>
          <RadioFieldGroup
            name="type"
            transparent
            items={[
              { value: 'upload', label: 'Upload .mp3 file' },
              {
                value: 'record',
                label: `Record voice ${isRecording ? '' : 'greeting'}`,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col {...colProps}>
          {values.type === GreetingType.Upload && <AudioDropzone />}

          {values.type === GreetingType.Record && (
            <AudioRecord value={values.record} setFieldValue={setFieldValue} />
          )}
        </Col>
      </Row>
    </Styles.Inner>
  );
};
