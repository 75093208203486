import dayjs from 'dayjs';

import { CardBrand } from 'types';
import { CardBrandIcon } from 'types/data';

import * as Styles from './styles';
import { PaymentCardProps } from './types';

const isCardBrand = (brand: string): brand is CardBrand => {
  return Object.values(CardBrand).includes(brand as CardBrand);
};

export const PaymentCard = ({
  card,
  bold,
  inline,
  className,
  numberOnly,
  expirationOnly,
}: PaymentCardProps) => {
  const { brand, last4, exp_year, exp_month, display_brand } = card;
  const cardBrand = display_brand || brand;
  const lastNumbers = `**** ${last4}`;
  const brandIcon = isCardBrand(cardBrand) ? (
    CardBrandIcon[cardBrand]
  ) : (
    <Styles.IconCard />
  );
  return (
    <Styles.Container
      $inline={inline}
      className={`${className} ${bold ? 'semibold' : ''}`}
    >
      {!expirationOnly && brandIcon}
      {!expirationOnly && lastNumbers}
      {!numberOnly && (
        <Styles.ExpiredAt
          $isExpired={dayjs().isAfter(
            dayjs()
              .year(exp_year)
              .month(exp_month - 1)
          )}
        >
          {exp_month > 9 ? exp_month : `0${exp_month}`}/
          {String(exp_year).slice(-2)}
        </Styles.ExpiredAt>
      )}
    </Styles.Container>
  );
};
