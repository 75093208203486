import debounce from 'lodash.debounce';
import { useRef, useState, useEffect, useCallback } from 'react';

import { MediaStream } from 'context/video-comm/types';

import { SizePayload, useSizeCallback } from './useSizeCallback';

export const useCanvasDimension = (mediaStream: MediaStream | null) => {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const videoRef = useRef<HTMLCanvasElement | null>(null);

  const debounceRef = useRef(debounce(setDimension, 300));

  useEffect(() => {
    if (videoRef.current) {
      const { width, height } = videoRef.current.getBoundingClientRect();
      setDimension({ width, height });
    }
  }, []);

  const onCanvasResize = useCallback(
    ({ width, height }: SizePayload) => {
      if (videoRef) {
        debounceRef.current({ width, height });
      }
    },
    [videoRef]
  );

  useSizeCallback(videoRef.current, onCanvasResize);

  useEffect(() => {
    const { width, height } = dimension;
    if (videoRef.current) {
      try {
        videoRef.current.width = width;
        videoRef.current.height = height;
      } catch (e) {
        console.error(e);
        mediaStream?.updateVideoCanvasDimension(
          videoRef.current,
          width,
          height
        );
      }
    }
  }, [mediaStream, dimension, videoRef]);

  return { videoRef, canvasDimension: dimension };
};
