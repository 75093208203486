import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';
import {
  SectionHeader,
  StandardButton,
  PageHeader as TCPageHeader,
  BackButton as TCBackButton,
} from '@gv/triage-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    ${theme.responsive.isMobile &&
    css`
      flex-direction: column;
    `}
  `}
`;

export const PageContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Inner = styled.div<{ paddingTop?: number }>`
  position: relative;
  height: 100%;
  overflow-y: auto;
  ${({ paddingTop }) => css`
    padding: ${paddingTop ? `${paddingTop}px 16px 0 16px` : '0 16px'};
  `}
`;

export const Form = styled(FormikForm)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

export const AddButton = styled(StandardButton)`
  padding-left: 0;
  justify-content: flex-start;
`;

export const Header = styled(SectionHeader)`
  padding-bottom: 16px;
`;

export const RowFormWrap = styled.div<{ borderRadius?: string }>`
  padding: 16px;

  ${({ theme, borderRadius }) => css`
    background-color: ${theme.colors.grey.tertiary.standard};
    border-radius: ${borderRadius || theme.borderRadius};
  `}
`;

export const RowButton = styled(StandardButton)`
  margin-top: 24px;
`;

export const BackButton = styled(TCBackButton)`
  margin-left: -16px;
`;

export const Title = styled.h3`
  font-weight: 600;
  margin-bottom: 16px;
`;

export const PageHeader = styled(TCPageHeader)`
  margin: 24px 16px 12px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled(Container)`
  min-height: 0;
  max-height: 100%;
`;
