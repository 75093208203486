import { useContext } from 'react';
import { ErrorAlert } from '@gv/triage-components';

import { AudioContext } from '.';

export const RecordError = () => {
  const { error } = useContext(AudioContext);

  if (!error) {
    return null;
  }

  return (
    <ErrorAlert hideIcon>
      {error.prefixMessage && <div>{error.prefixMessage}</div>}
      <div>{error.message}</div>
    </ErrorAlert>
  );
};
