import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { CalendarEntryTypes } from 'types';

import { apiQuery } from '../../query';

import { visitHoursApi, visitsHoursTags } from './vitis-hours';
import { clinicHoursApi, clinicHoursTags } from './clinic-hours';
import { appointmentApi, appointmentTags } from './appointments';
import { onCallHoursApi, onCallHoursTags } from './on-call-hours';
import { virtualExamsApi, virtualExamsTags } from './virtual-exams';
import { EditCalendarRequest, DeleteCalendarRequest } from './types';
import { forwardingHoursApi, forwardingHoursTags } from './forwarding-hours';
import { appointmentHoursApi, AppointmentHoursApiTags } from './appointmentHrs';

export {
  visitHoursApi,
  clinicHoursApi,
  onCallHoursApi,
  appointmentApi,
  virtualExamsApi,
  forwardingHoursApi,
  appointmentHoursApi,
};

export const calendarApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'calendarApi',
  endpoints: (build) => ({
    delete: build.mutation<void, DeleteCalendarRequest>({
      query: ({ endDate, doctorId, forGVComm, startDate, hospitalId }) => ({
        method: 'delete',
        url: Utils.Query.addParamsToUrl(
          URL.CALENDAR.replace('{hospital_id}', String(hospitalId)),
          {
            end_date: endDate,
            doctor_id: doctorId,
            start_date: startDate,
            for_gvcomm: forGVComm,
          }
        ),
      }),
      async onCacheEntryAdded(
        { doctorId, forGVComm },
        { dispatch, cacheDataLoaded }
      ) {
        await cacheDataLoaded;
        if (forGVComm) {
          dispatch(forwardingHoursApi.util.invalidateTags(forwardingHoursTags));
        } else if (doctorId) {
          dispatch(onCallHoursApi.util.invalidateTags(onCallHoursTags));
        } else {
          dispatch(clinicHoursApi.util.invalidateTags(clinicHoursTags));
        }
      },
    }),

    edit: build.mutation<void, EditCalendarRequest>({
      async onCacheEntryAdded({ entryType }, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        switch (entryType) {
          case CalendarEntryTypes.ForwardingHours:
            dispatch(
              forwardingHoursApi.util.invalidateTags(forwardingHoursTags)
            );
            break;
          case CalendarEntryTypes.OnCall:
            dispatch(onCallHoursApi.util.invalidateTags(onCallHoursTags));
            break;
          case CalendarEntryTypes.ClinicHours:
            dispatch(clinicHoursApi.util.invalidateTags(clinicHoursTags));
            break;
          case CalendarEntryTypes.VirtualExams:
            dispatch(clinicHoursApi.util.invalidateTags(virtualExamsTags));
            break;
          case CalendarEntryTypes.Appointment:
            dispatch(appointmentApi.util.invalidateTags(appointmentTags));
            break;
          case CalendarEntryTypes.AppointmentHrs:
            dispatch(
              appointmentHoursApi.util.invalidateTags(AppointmentHoursApiTags)
            );
            break;
          default:
            dispatch(visitHoursApi.util.invalidateTags(visitsHoursTags));
            break;
        }
      },
      query: ({
        id,
        title,
        email,
        case_id,
        endDate,
        doctorId,
        startDate,
        timeslots,
        entryType,
        client_id,
        hospitalId,
        description,
      }) => ({
        method: entryType === CalendarEntryTypes.Appointment ? 'put' : 'patch',
        url:
          entryType === CalendarEntryTypes.Appointment
            ? `${URL.APPOINTMENT_LIST}/${id}`
            : URL.CALENDAR.replace('{hospital_id}', id),
        data:
          entryType === CalendarEntryTypes.Appointment
            ? {
                title,
                email,
                case_id,
                client_id,
                doctor_id: doctorId,
                end_date: dayjs(endDate),
                start_date: dayjs(startDate),
                hospital_id: String(hospitalId),
                time_slots: JSON.stringify(timeslots),
                description:
                  description === '' || description === null
                    ? undefined
                    : description,
              }
            : {
                title,
                entryType,
                end_date: endDate,
                doctor_id: doctorId,
                start_date: startDate,
                time_slots: timeslots,
              },
      }),
    }),
  }),
});

export const {
  useListQuery: useVisitHoursQuery,
  useCreateMutation: useVisitHourCreateMutation,
  useDeleteMutation: useVisitHourDeleteMutation,
} = visitHoursApi;

export const {
  useListQuery: useClinicHoursQuery,
  useCreateMutation: useClinicHourCreateMutation,
  useDeleteMutation: useClinicHourDeleteMutation,
} = clinicHoursApi;

export const {
  useListQuery: useOnCallHoursQuery,
  useCreateMutation: useOnCallHourCreateMutation,
  useDeleteMutation: useOnCallHourDeleteMutation,
} = onCallHoursApi;

export const {
  useListQuery: useForwardingHoursQuery,
  useCreateMutation: useForwardingHourCreateMutation,
  useDeleteMutation: useForwardingHourDeleteMutation,
} = forwardingHoursApi;

export const {
  useListQuery: useVirtualExamsQuery,
  useLazyListQuery: useLazyVirtualExamsQuery,
  useCreateMutation: useVirtualExamsCreateMutation,
  useDeleteMutation: useVirtualExamsDeleteMutation,
} = virtualExamsApi;

export const {
  useListQuery: useAppointmentQuery,
  useLazyListQuery: useLazyAppointmentQuery,
  useCreateMutation: useAppointmentCreateMutation,
  useDeleteMutation: useVAppointmentDeleteMutation,
} = appointmentApi;

export const {
  useListQuery: useAppointmentHrsQuery,
  useLazyListQuery: useLazyAppointmentHoursQuery,
  useCreateMutation: useAppointmentHrsCreateMutation,
  useDeleteMutation: useVAppointmentHrsDeleteMutation,
} = appointmentHoursApi;

export const { useEditMutation, useDeleteMutation } = calendarApi;
export type { DeleteCalendarRequest };
