import { Utils, EmptyPlaceholder } from '@gv/triage-components';

import { TaskTypeTitle } from 'types/data';
import { TaskType, TaskClass } from 'types';

import { TaskActionItemProps } from './types';

const { formatDate } = Utils.Date;

export const TaskActionItem = ({
  data,
  taskCase,
  taskClass,
  isERHospital,
}: TaskActionItemProps) => {
  const isEmergencyClass = taskClass === TaskClass.Emergency;
  const isAdministrativeTab = taskClass === TaskClass.Administrative;
  const isTransferToBackline = taskClass === TaskClass.TransferToBackline;
  const isEmergencyInAdministrativeTab =
    isEmergencyClass && !isERHospital && isAdministrativeTab;

  let date: number | string | undefined;
  if (data.voicemail) {
    date = Number(data.voicemail.created_at);
  } else if (taskCase) {
    date = Number(
      taskCase?.submitted_at ? taskCase.submitted_at : taskCase?.updated_at
    );
  } else {
    date = data.createdAt;
  }

  const type = data?.type || TaskType.Appointment;

  const isVoicemailOrPORequest = [
    TaskType.RxRefill,
    TaskType.Voicemail,
    TaskType.RequestMedicalRecords,
  ].includes(type);

  return isAdministrativeTab ||
    isTransferToBackline ||
    isEmergencyInAdministrativeTab ? (
    <div>
      <p className="semibold">
        {!isVoicemailOrPORequest ? 'Administrative call' : TaskTypeTitle[type]}
      </p>
      <span>From: {formatDate(date, 'LL')}</span>
    </div>
  ) : (
    EmptyPlaceholder
  );
};
