import styled, { css } from 'styled-components';
import { Styles, StandardButton } from '@gv/triage-components';

import { HighlightProps } from './types';

export const Table = styled.table`
  border-spacing: 0;
  table-layout: fixed;
`;

const tableData = css`
  padding: 8px;
`;

export const TableRowHead = styled.th`
  ${tableData};
  text-align: left;
  font-weight: 400;
`;

export const TableData = styled.td<HighlightProps>`
  ${tableData};
  text-align: center;
`;

export const LeftBorderTableData = styled(TableData)`
  border-left: 1px solid ${({ theme }) => theme.config.slidePanel.border};
`;

export const SidesBorderTableData = styled(TableData)<
  HighlightProps & { withBottomBorder?: boolean }
>`
  border-style: solid;

  ${({ theme, highlight, withBottomBorder }) =>
    highlight
      ? css`
          border-width: 0 2px;
          border-color: ${theme.colors.blue.primary.standard};

          ${withBottomBorder &&
          css`
            border-bottom-width: 2px;
            border-bottom-left-radius: ${theme.borderRadius};
            border-bottom-right-radius: ${theme.borderRadius};
          `}
        `
      : css`
          border-width: 0 1px;
          border-color: ${theme.config.slidePanel.border};
        `}
`;

export const TableHead = styled.th<HighlightProps>`
  ${tableData};
  text-align: left;
  min-width: 280px;

  ${({ theme, highlight }) =>
    highlight &&
    css`
      border-style: solid;
      border-width: 0 2px;
      border-color: ${theme.colors.blue.primary.standard};
    `}
`;

export const MainTableHead = styled.th`
  padding: 24px;
  font-weight: 400;
  text-align: left;
`;

export const EssentialTableHead = styled(MainTableHead)`
  border-left: 1px solid ${({ theme }) => theme.config.slidePanel.border};
`;

export const AdvancedTableHead = styled(MainTableHead)<HighlightProps>`
  position: relative;
  border-left-style: solid;
  border-right-style: solid;

  ${({ theme, highlight }) => css`
    ${highlight
      ? css`
          border-top-style: solid;
          border-width: 2px 2px 0 2px;
          border-top-left-radius: ${theme.borderRadius};
          border-top-right-radius: ${theme.borderRadius};
          border-color: ${theme.colors.blue.primary.standard};

          &::before {
            ${Styles.Font.captionSemibold};
            top: -10px;
            right: 16px;
            padding: 2px 8px;
            position: absolute;
            content: 'Recommended';
            color: ${theme.config.text.white};
            border-radius: ${theme.borderRadius};
            background-color: ${theme.colors.blue.primary.standard};
          }
        `
      : css`
          border-left-width: 1px;
          border-right-width: 1px;
          border-left-style: solid;
          border-right-style: solid;
          border-color: ${theme.config.slidePanel.border};
        `}
  `}
`;

export const TableHeaderRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.grey.secondary.standard};

  ${({ theme }) => css`
    ${TableHead}:first-child {
      border-top-left-radius: ${theme.borderRadius};
      border-bottom-left-radius: ${theme.borderRadius};
    }

    ${TableHead}:last-child {
      border-top-right-radius: ${theme.borderRadius};
      border-bottom-right-radius: ${theme.borderRadius};
    }
  `}
`;

export const CurrentPlan = styled.div`
  ${Styles.Font.bodySemibold};
  padding: 8px;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue.primary.standard};
`;

export const SubscriptionSuccessButton = styled(StandardButton)`
  min-width: 120px;
  align-self: flex-end;
`;
