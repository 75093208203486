import styled from 'styled-components';
import { ChatCard as GVChatCard } from '@gv/triage-components';

export const ChatCard = styled(GVChatCard)`
  padding: 12px 24px;
`;

export const MessagesCount = styled.p<{ isRed?: boolean }>`
  white-space: nowrap;
  color: ${({ theme, isRed }) =>
    isRed ? theme.config.text.red : theme.config.userInfo.text.gvSubtitle};
`;
