import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from '.';

export const Pause = () => {
  const { state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      icon={<Icon.Pause />}
      disabled={state?.paused}
      onClick={controls?.pause}
      colorType={ButtonColors.LightGreen}
    />
  );
};
