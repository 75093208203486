import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { PaymentLink, HospitalPrice } from 'types';

import {
  hospitalServicesApi,
  hospitalServicesTags,
} from '../hospital-services';

import {
  PaymentLinkRequest,
  HospitalPricingListRequest,
  HospitalPricingCreateRequest,
  HospitalPricingUpdateRequest,
  HospitalPricingDeleteRequest,
  HospitalPricingDetailsRequest,
} from './types';

const tagTypes = ['Hospital pricing'];

export const hospitalPricingApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'hospitalPricing',
  endpoints: (build) => ({
    paymentLink: build.mutation<PaymentLink, PaymentLinkRequest>({
      query: (data) => ({
        data,
        method: 'post',
        url: URL.PAYMENT_LINK,
      }),
    }),

    delete: build.mutation<void, HospitalPricingDeleteRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.UPDATE_HOSPITAL_PRICE, { id }),
      }),
    }),

    update: build.mutation<void, HospitalPricingUpdateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_HOSPITAL_PRICE, { id: data.id }),
      }),
    }),

    list: build.query<HospitalPrice[], HospitalPricingListRequest>({
      providesTags: tagTypes,
      query: ({ hospitalId }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_HOSPITAL_PRICES_LIST, {
          hospitalId,
        });
        return {
          url,
          method: 'get',
        };
      },
    }),

    details: build.query<HospitalPrice[], HospitalPricingDetailsRequest>({
      providesTags: tagTypes,
      query: ({ id, hospitalId }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_HOSPITAL_PRICES_LIST, {
          id,
          hospitalId,
        });
        return {
          url,
          method: 'get',
        };
      },
    }),

    create: build.mutation<void, HospitalPricingCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_HOSPITAL_PRICES_LIST,
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(hospitalServicesApi.util.invalidateTags(hospitalServicesTags));
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  usePaymentLinkMutation,
} = hospitalPricingApi;
