import { selectOptionsWithNone } from 'utils/helpers';
import {
  TechnicalIssue,
  CaseReportReview,
  TechnicalIssueLabel,
  CaseReportReviewLabel,
} from 'types';

export const technicalIssues = selectOptionsWithNone(
  Object.values(TechnicalIssue).map((value) => ({
    value,
    label: TechnicalIssueLabel[value],
  }))
);

export const reviewOptions = selectOptionsWithNone(
  Object.values(CaseReportReview).map((value) => ({
    value,
    label: CaseReportReviewLabel[value],
  }))
);
