import { Styles } from '@gv/triage-components';
import styled, { css } from 'styled-components';

const { flexCenter } = Styles;

export const DropContainer = styled.div<{ withoutBg?: boolean }>`
  position: relative;
  height: 120px;
  border-radius: 16px;
  overflow: hidden;

  ${({ theme, withoutBg }) => css`
    border: 1px dashed ${theme.colors.grey.primary.standard};
    background: ${withoutBg ? 'none' : theme.colors.grey.tertiary.standard};
    span {
      color: ${theme.config.link.default};
    }
  `}
`;

export const Wrapper = styled.div`
  ${flexCenter};
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 24px;

  h4 {
    max-width: 90%;
  }

  svg path {
    fill: ${({ theme }) => theme.config.text.primary};
  }
`;
