import * as Styles from './styles';
import { PaymentCardDetailsProps } from './types';

export const PaymentCardDetails = ({
  gap,
  title,
  asHeader,
  children,
}: PaymentCardDetailsProps) => (
  <Styles.Container gap={gap}>
    {asHeader ? <h3>{title}</h3> : <p className="semibold">{title}</p>}
    {children}
  </Styles.Container>
);

export type { PaymentCardDetailsProps };
