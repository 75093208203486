import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';

import { ChatFile } from 'types';

const { formatDate, getCalendarTime } = Utils.Date;

export const getDate = (key: string, values: ChatFile[]) => {
  const [first] = values;
  const createdAt = first?.createdAt;
  const date = key === 'Today' ? formatDate(createdAt, 'LT') : key;

  return [date, ...values];
};

export const groupFiles = (files?: ChatFile[]) => {
  if (!files?.length) {
    return [];
  }

  const dayGroups = files.reduce(
    (group, file) => {
      const key = getCalendarTime(
        dayjs(file?.createdAt).valueOf() / 1000,
        'MMM DD, YYYY'
      );
      group[key] = group[key] ?? [];
      group[key].push(file);
      return group;
    },
    {} as Record<string, ChatFile[]>
  );

  return Object.entries(dayGroups)
    .map(([key, value]) => getDate(key, value))
    .reduce((sum, current) => sum.concat(current), []);
};
