import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  CaseNotification,
  UpdateCaseNotificationsRequest,
  GetCaseNotificationsByIdsRequest,
  GetCaseNotificationsByIdsResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;

const tagTypes = ['CaseNotifications'];

export const caseNotificationsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'caseNotificationsApi',
  endpoints: (build) => ({
    updateNotifications: build.mutation<void, UpdateCaseNotificationsRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, hospitalId }) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_CASE_NOTIFICATIONS, { hospitalId }),
      }),
    }),

    getNotificationsByIds: build.query<
      CaseNotification[],
      GetCaseNotificationsByIdsRequest
    >({
      providesTags: tagTypes,
      transformResponse: (response: GetCaseNotificationsByIdsResponse) =>
        response.data,
      query: ({ hospitalId, callOutcomeIds = [] }) => {
        const generatedUrl = generateUrl(URL.GET_CASE_NOTIFICATIONS_BY_IDS, {
          hospitalId,
        });
        const url = addParamsToUrl(generatedUrl, {
          callOutcomeIds: callOutcomeIds.join(','),
        });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useGetNotificationsByIdsQuery, useUpdateNotificationsMutation } =
  caseNotificationsApi;
