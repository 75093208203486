import {
  Dialog,
  TextColor,
  ButtonColors,
  StandardButton,
  ActionQueueType,
  ButtonStyleTypes,
} from '@gv/triage-components';

import { HangUpProps } from './types';
import {
  icon,
  text,
  title,
  completeText,
  completeAndSubmitText,
} from './config';

export const HangUpDialog = ({
  task,
  setOpen,
  onHangUp,
  ...props
}: HangUpProps) => {
  const { type, c_name } = task;

  const getTextElement = (): JSX.Element => {
    const clientName = c_name ? (
      <>
        {' '}
        for <span className="semibold">{c_name}</span>
      </>
    ) : (
      ''
    );

    return (
      <>
        {text[type]}
        {clientName}?
      </>
    );
  };

  return (
    <Dialog
      icon={icon[type]}
      title={title[type]}
      text={getTextElement()}
      iconColor={TextColor.Green}
      buttons={(close) => (
        <>
          {type === ActionQueueType.Voice && (
            <StandardButton
              fullWidth
              text={completeText[type]}
              colorType={ButtonColors.Primary}
              onClick={() => {
                close();
                setOpen(false);
                onHangUp(false);
              }}
            />
          )}

          <StandardButton
            fullWidth
            text={completeAndSubmitText[type]}
            onClick={() => {
              close();
              setOpen(false);
              onHangUp(true);
            }}
            colorType={
              type === ActionQueueType.Voice
                ? ButtonColors.Secondary
                : ButtonColors.Primary
            }
          />

          <StandardButton
            fullWidth
            text="Cancel"
            colorType={ButtonColors.Primary}
            styleType={ButtonStyleTypes.Outline}
            onClick={() => {
              close();
              setOpen(false);
            }}
          />
        </>
      )}
      {...props}
    />
  );
};
