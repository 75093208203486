import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';

import { apiQuery } from '../../query';
import {
  CommunicationLogFilesData,
  CommunicationLogFilesRequest,
} from '../communication-log';

import { ExportFormValues } from './types';

export const analyticsExportApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'analyticsExportApi',
  endpoints: (build) => ({
    analyticsExport: build.mutation<void, ExportFormValues>({
      query: ({ end_date, start_date, hospital_id }) => {
        const searchParams = {
          hospital_id,
          end_date: dayjs(end_date).format('YYYY-MM-DD'),
          start_date: dayjs(start_date).format('YYYY-MM-DD'),
        };

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(
            URL.ANALYTICS_EXPORT_STREAM,
            searchParams
          ),
        };
      },
    }),

    files: build.query<CommunicationLogFilesData, CommunicationLogFilesRequest>(
      {
        query: ({
          author,
          endDate,
          page = 1,
          startDate,
          limit = 20,
          ...rest
        }) => {
          const offset = Utils.Helpers.offset(page, limit);

          const searchParams = {
            limit,
            offset,
            author: Utils.Helpers.isSingleSelectValue(author) && author?.value,
            end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
            start_date: startDate
              ? dayjs(startDate).format('YYYY-MM-DD')
              : undefined,
            ...rest,
          };

          return {
            method: 'get',
            url: Utils.Query.addParamsToUrl(URL.ANALYTICS_FILES, searchParams),
          };
        },
      }
    ),
  }),
});

export const { useFilesQuery, useAnalyticsExportMutation } = analyticsExportApi;
export type { ExportFormValues };
