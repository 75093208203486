import { createApi } from '@reduxjs/toolkit/query/react';

import { Discount } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  GetDiscountRequest,
  CreateDiscountRequest,
  DeleteDiscountRequest,
  UpdateDiscountRequest,
} from './types';

const tagTypes = ['Discount'];

export const discountApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'discountApi',
  endpoints: (build) => ({
    list: build.query<Discount[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_DISCOUNT_LIST,
      }),
    }),

    create: build.mutation<void, CreateDiscountRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_DISCOUNT_LIST,
      }),
    }),

    getDiscount: build.query<Discount, GetDiscountRequest>({
      providesTags: tagTypes,
      query: ({ id }) => ({
        method: 'get',
        url: generateUrl(URL.DELETE_DISCOUNT, { id }),
      }),
    }),

    delete: build.mutation<void, DeleteDiscountRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_DISCOUNT, { id }),
      }),
    }),

    update: build.mutation<void, UpdateDiscountRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: generateUrl(URL.DELETE_DISCOUNT, { id: data.id }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useCreateMutation,
  useDeleteMutation,
  useUpdateMutation,
  useGetDiscountQuery,
} = discountApi;
