import { useMemo } from 'react';
import {
  Col,
  Row,
  Info,
  Linkify,
  isMobile,
  FlexLayout,
} from '@gv/triage-components';

import { Config } from 'config';
import { CallOutcomeBadges } from 'components';
import { CallOutcomeTypeTitle } from 'types/data';
import { ExpandableSection } from 'components/cases';

import * as Styles from './styles';
import { CallOutcomeProps } from './types';
import { IncorrectTriage } from './incorrect-triage';

export const CallOutcome = ({
  data,
  clientId,
  timezone,
  asSection,
  hospitalId,
  isBadgeHidden,
  additionalNotes,
}: CallOutcomeProps): JSX.Element | null => {
  const mobile = isMobile();
  const outcomeTitles = useMemo(
    () => ({
      ...CallOutcomeTypeTitle,
      ...(Config.helpers.getCallOutcomeTitles(hospitalId) ?? {}),
    }),
    [hospitalId]
  );

  if (!data && !additionalNotes) {
    return null;
  }

  const { type, caseId, details } = data ?? {};

  const typeName = type && (details?.[type] || type);

  if (!typeName && !additionalNotes) {
    return null;
  }

  const isHospitalPortal = Config.portalType.isHospital;

  const info = (
    <>
      {data && typeName && (
        <>
          <FlexLayout
            gap={8}
            flexDirection={mobile ? 'column' : 'row'}
            alignItems={mobile ? 'flex-start' : 'center'}
            justifyContent={mobile ? undefined : 'space-between'}
          >
            <FlexLayout gap={8}>
              <Styles.CheckIcon />
              {typeName && outcomeTitles[typeName]}
            </FlexLayout>

            {isHospitalPortal && caseId && clientId && (
              <IncorrectTriage
                caseId={caseId}
                clientId={clientId}
                hospitalId={hospitalId}
              />
            )}
          </FlexLayout>

          {!isBadgeHidden && (
            <CallOutcomeBadges data={data} isSelectable timezone={timezone} />
          )}
        </>
      )}

      {additionalNotes && (
        <Row>
          <Col>
            <Info
              noEllipsis
              title="Additional notes"
              value={<Linkify withoutButton>{additionalNotes}</Linkify>}
            />
          </Col>
        </Row>
      )}
    </>
  );
  return asSection ? (
    <ExpandableSection defaultOpened label="Call outcome">
      {info}
    </ExpandableSection>
  ) : (
    info
  );
};
