import {
  Icon,
  OpenStatus,
  BadgeColor,
  AblyChatEvent,
  ActionQueueType,
} from '@gv/triage-components';

import {
  Service,
  TaskType,
  CardBrand,
  RepeatType,
  MemberType,
  DiscardType,
  NurseStatus,
  TaskPriority,
  ClientsGroup,
  PayoutStatus,
  SubscriptionTab,
  AssessmentField,
  CallOutcomeType,
  EntityPortalType,
  PetOwnerTemplate,
  CommLogTypeNumber,
  InternalTaskStatus,
  StripeInvoiceStatus,
  PackageTemplateType,
  WhiteboardConfigKey,
  CallOutcomeEmergency,
  MessageRecipientType,
  CommLogDirectionType,
  InternalTaskPriority,
  CallOutcomeClientType,
  CallOutcomeAppointment,
  CallOutcomeReferTorDVM,
  CallOutcomeDeadlineIssue,
  CallOutcomeAdministrative,
  AppointmentDeclinedReason,
  CallOutcomeTransferToBackline,
  SubscriptionRecurringInterval,
} from 'types';

export const RepeatEveryTitle = {
  [RepeatType.Daily]: 'Day(s)',
  [RepeatType.Weekly]: 'Week(s)',
  [RepeatType.Monthly]: 'Month(s)',
};

export const ActionQueueTypeNumber = {
  [ActionQueueType.SMS]: 2,
  [ActionQueueType.Chat]: 0,
  [ActionQueueType.Voice]: 1,
};

export const CommLogTypeTitle = {
  [CommLogTypeNumber.SMS]: 'SMS',
  [CommLogTypeNumber.Chat]: 'Chat',
  [CommLogTypeNumber.Call]: 'Call',
  [CommLogTypeNumber.VirtualExam]: 'Virtual Exam',
};

export const CommLogDirectionTypeTitle = {
  [CommLogDirectionType.Incoming]: 'Incoming',
  [CommLogDirectionType.Outgoing]: 'Outgoing',
  [CommLogDirectionType.Appointment]: 'Appointment',
};

export const ActionQueueTypeFromNumber: Record<number, ActionQueueType> =
  Object.entries(ActionQueueTypeNumber).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  );

export const DiscardTypeLabel = {
  [DiscardType.Deadline]: 'Deadline',
};

// Don't change order, sorting is based on this
export const NurseStatusTitle = {
  [NurseStatus.Break]: 'Break',
  [NurseStatus.Call]: 'On call',
  [NurseStatus.Chat]: 'On chat',
  [NurseStatus.Coaching]: 'Coaching',
  [NurseStatus.Offline]: 'Not on shift',
  [NurseStatus.Free]: 'Not taking calls',
};

export const NurseStatusBadgeColor = {
  [NurseStatus.Chat]: BadgeColor.Green,
  [NurseStatus.Call]: BadgeColor.Green,
  [NurseStatus.Free]: BadgeColor.Between,
  [NurseStatus.Break]: BadgeColor.Yellow,
  [NurseStatus.Offline]: BadgeColor.Grey,
  [NurseStatus.Coaching]: BadgeColor.Pink,
};

export const NurseStatusIcon = {
  [NurseStatus.Break]: <Icon.Cup />,
  [NurseStatus.Call]: <Icon.Call />,
  [NurseStatus.Free]: <Icon.Transfer />,
  [NurseStatus.Coaching]: <Icon.Team />,
  [NurseStatus.Chat]: <Icon.ChatInProgress />,
  [NurseStatus.Offline]: <Icon.CloseCircleNotFilled />,
};

export const OpenStatusTextOnCall = {
  [OpenStatus.Opened]: 'On call now',
  [OpenStatus.Closed]: 'On call closed',
  [OpenStatus.CloseSoon]: 'until on call ending',
  [OpenStatus.OpenSoon]: 'until on call starting',
};

export const CallOutcomeTitle = {
  [CallOutcomeType.Emergency]: 'Emergency',
  [CallOutcomeType.Appointment]: 'Appointment',
  [CallOutcomeType.ReferTorDVM]: 'Refer to rDVM',
  [CallOutcomeType.DeadlineIssue]: 'Deadline issue',
  [CallOutcomeType.Administrative]: 'Administrative',
  [CallOutcomeType.TransferToBackline]: 'Transfer to backline',
};

export const CallOutcomeAppointmentTitle = {
  [CallOutcomeAppointment.Declined]: 'Appointment declined',
  [CallOutcomeAppointment.Cancelled]: 'Appointment cancelled',
  [CallOutcomeAppointment.Scheduled]: 'Appointment scheduled',
  [CallOutcomeAppointment.Rescheduled]: 'Appointment rescheduled',
  [CallOutcomeAppointment.Recommended]: 'Appointment recommended',
};

export const AppointmentDeclinedReasonTitle = {
  [AppointmentDeclinedReason.AvailabilityIssue]: 'Availability issue',
  [AppointmentDeclinedReason.ZipNotServiced]: 'Zip code not serviced',
  [AppointmentDeclinedReason.PriceServiceIssue]: 'Price / service issue',
};

export const CallOutcomeTransferToBacklineTitle = {
  [CallOutcomeTransferToBackline.CancellationsLess48Hours]:
    'Cancellations < 48 hours',
  [CallOutcomeTransferToBackline.ServiceAvailabilityQuestion]:
    'Service availability question',
  [CallOutcomeTransferToBackline.UrgentAppointmentWorkInNeeded]:
    'Urgent appointment - work-in needed',
  [CallOutcomeTransferToBackline.NeedsTransferPickedUp]:
    'Customer has a question - needs transfer (Picked up)',
  [CallOutcomeTransferToBackline.NeedsTransferNotPickedUp]:
    'Customer has a question - needs transfer (NOT picked up)',
};

export const transferToBacklineTitleValue = [
  {
    value: CallOutcomeTransferToBackline.NeedsTransferPickedUp,
    label: 'Customer has a question - needs transfer (Picked up)',
  },
  {
    value: CallOutcomeTransferToBackline.NeedsTransferNotPickedUp,
    label: 'Customer has a question - needs transfer (NOT picked up)',
  },
  {
    label: "Cancellations < 48 hours'",
    value: CallOutcomeTransferToBackline.CancellationsLess48Hours,
  },
  {
    label: 'Service availability question',
    value: CallOutcomeTransferToBackline.ServiceAvailabilityQuestion,
  },
  {
    label: 'Urgent appointment - work-in needed',
    value: CallOutcomeTransferToBackline.UrgentAppointmentWorkInNeeded,
  },
];

export const CallOutcomeEmergencyTitle = {
  [CallOutcomeEmergency.ReferToEr]: 'Refer to ER',
  [CallOutcomeEmergency.Inbound]: 'Emergency - Inbound',
  [CallOutcomeEmergency.ReferToPPH]: 'Refer to PPH/ASPCA',
  [CallOutcomeEmergency.ReferToOnCall]: 'Refer to on-call',
  [CallOutcomeEmergency.ClientDeclined]: 'Emergency - Client declined',
};

export const CallOutcomeAdministrativeTitle = {
  [CallOutcomeAdministrative.Call]: 'N/A  - Administrative call',
};

export const CallOutcomeDeadlineIssueTitle = {
  [CallOutcomeDeadlineIssue.DeadlineIssue]: 'Deadline issue',
};

export const CallOutcomeRDVMTitle = {
  [CallOutcomeReferTorDVM.ReferTorDVM]: 'Refer to rDVM',
};

export const CallOutcomeTypeTitle = {
  ...CallOutcomeTitle,
  ...CallOutcomeRDVMTitle,
  ...CallOutcomeEmergencyTitle,
  ...CallOutcomeAppointmentTitle,
  ...CallOutcomeDeadlineIssueTitle,
  ...AppointmentDeclinedReasonTitle,
  ...CallOutcomeAdministrativeTitle,
  ...CallOutcomeTransferToBacklineTitle,
};

export const MemberTypeTitle = {
  [MemberType.CSR]: 'CSR',
  [MemberType.CVT]: 'CVT',
  [MemberType.TeamCoach]: 'Team Coach',
};

export const WalkthroughDataAttribute = 'data-walkthrough-step';

export enum WalkthroughStep {
  Intro,
  HospitalProfile,
  Acceptance,
  ActionItems,
  Cases,
  Calendar,
  Analytics,
  HelpCenter,
  MyAccount,
}

export const SpecialityLabel = {
  Mobile: 'Mobile',
  Wellness: 'Wellness',
  Emergency: 'Emergency only',
  Specialist: 'Specialty only',
  'General practice': 'General practice',
  'Emergency & Specialty': 'Emergency & Specialty',
};

export const ServicesLabels: Record<Service, string> = {
  VSCR: 'V.E.T',
  Overflow: 'V.F.D',
  Callback: 'Callback',
  'After hours on call': 'After hours on call',
  'After hours referral only': 'After hours referral only',
};

export const assessmentsFieldsNames = Object.values(AssessmentField);

export const MessageRecipientLabel = {
  [MessageRecipientType.Other]: 'Other',
  [MessageRecipientType.DVM]: 'On-call DVM',
  [MessageRecipientType.PetOwner]: 'Pet owner',
};

export const PetOwnerTemplateLabel = {
  [PetOwnerTemplate.ER]: 'ER info',
  [PetOwnerTemplate.Custom]: 'Custom',
  [PetOwnerTemplate.Payment]: 'Payment info',
  [PetOwnerTemplate.Hotline]: 'Hotline info',
  [PetOwnerTemplate.PaymentLinks]: 'Payment links',
};

export const TaskPriorityTitle = {
  [TaskPriority.Low]: 'Low',
  [TaskPriority.High]: 'High',
  [TaskPriority.Admin]: 'Admin',
  [TaskPriority.Medium]: 'Medium',
};

export const TaskPriorityBadgeColor = {
  [TaskPriority.High]: BadgeColor.Red,
  [TaskPriority.Low]: BadgeColor.Green,
  [TaskPriority.Admin]: BadgeColor.Grey,
  [TaskPriority.Medium]: BadgeColor.Yellow,
};

export const InternalTaskPriorityBadgeColor = {
  [InternalTaskPriority.High]: BadgeColor.Red,
  [InternalTaskPriority.Low]: BadgeColor.Green,
  [InternalTaskPriority.Medium]: BadgeColor.Yellow,
};

export const CallOutcomeClientTypeTitle = {
  [CallOutcomeClientType.New]: 'New client',
  [CallOutcomeClientType.Current]: 'Current client',
  [CallOutcomeClientType.Wellness]: 'Wellness club',
  [CallOutcomeClientType.Corporate]: 'Corporate clients',
};

export const InternalTaskStatusText = {
  [InternalTaskStatus.ToDo]: 'To do',
  [InternalTaskStatus.Completed]: 'Completed',
  [InternalTaskStatus.InProgress]: 'In progress',
};

export const PayoutStatusText = {
  [PayoutStatus.Active]: 'Verified',
  [PayoutStatus.Inactive]: 'Restricted',
  [PayoutStatus.Pending]: 'Pending approval',
};

export const PayoutStatusBadgeText = {
  [PayoutStatus.Active]: '',
  [PayoutStatus.Pending]:
    "Your payout information is pending approval. We'll notify you once it's processed.",
  [PayoutStatus.Inactive]:
    'Your payout data is now restricted. Please open the form and check information that contains errors.',
};

export const ClientsGroupText = {
  [ClientsGroup.AllClients]: 'All clients',
  [ClientsGroup.NewClients]: 'New clients only',
};

export const StripeInvoiceStatusText = {
  [StripeInvoiceStatus.Draft]: 'Draft',
  [StripeInvoiceStatus.Void]: 'Canceled',
  [StripeInvoiceStatus.Refunded]: 'Refunded',
  [StripeInvoiceStatus.Paid]: 'Deposit paid',
  [StripeInvoiceStatus.Open]: 'Pending payment',
  [StripeInvoiceStatus.Uncollectible]: 'Uncollectible',
  [StripeInvoiceStatus.PartiallyRefunded]: 'Partially refunded',
};

export const StripeInvoiceStatusBadgeColor = {
  [StripeInvoiceStatus.Void]: BadgeColor.Grey,
  [StripeInvoiceStatus.Paid]: BadgeColor.Green,
  [StripeInvoiceStatus.Open]: BadgeColor.Yellow,
  [StripeInvoiceStatus.Refunded]: BadgeColor.Red,
  [StripeInvoiceStatus.Uncollectible]: BadgeColor.Grey,
  [StripeInvoiceStatus.PartiallyRefunded]: BadgeColor.Red,
  [StripeInvoiceStatus.Draft]: BadgeColor.TetrieryCerulean,
};

export const TaskTypeTitle = {
  [TaskType.Case]: 'Case',
  [TaskType.OnTheWay]: 'On the way',
  [TaskType.Scheduled]: 'Scheduled',
  [TaskType.Appointment]: 'Appointment',
  [TaskType.Voicemail]: 'New voicemail',
  [TaskType.Recommended]: 'Recommended',
  [TaskType.VirtualExam]: 'Virtual exam',
  [TaskType.RxRefill]: 'PO request rx refill',
  [TaskType.RequestMedicalRecords]: 'PO request medical records',
};

export const EntityPortalTypeColor = {
  [EntityPortalType.Hospital]: BadgeColor.Blue,
  [EntityPortalType.PO]: BadgeColor.TertiaryOrange,
  [EntityPortalType.Team]: BadgeColor.SecondaryPigmentIndigo,
};

export const EntityPortalTypeText = {
  [EntityPortalType.Team]: 'Triage',
  [EntityPortalType.PO]: 'PO portal',
  [EntityPortalType.Hospital]: 'GV-Talk',
};

export const SubscriptionTabTitle = {
  [SubscriptionTab.Discount]: 'Discount',
  [SubscriptionTab.PackageTemplates]: 'Package templates',
};

export const PackageTemplateTypeColor = {
  [PackageTemplateType.Variable]: BadgeColor.Pink,
  [PackageTemplateType.Flat]: BadgeColor.SecondaryCerulean,
};

export const PackageTemplateText = {
  [PackageTemplateType.Flat]: 'Flat',
  [PackageTemplateType.Variable]: 'Variable',
};

export const AblyChatEventExcludeQueue = Object.values(AblyChatEvent).filter(
  (event) => event !== AblyChatEvent.QueueUpdated
);

export const clientTypeOptions = Object.entries(CallOutcomeClientTypeTitle).map(
  ([value, label]) => ({
    value,
    label,
    disabled:
      value === CallOutcomeClientType.New ||
      value === CallOutcomeClientType.Current,
  })
);

export const VirtualExamServiceTypeName = 'Virtual exam';

export const CardBrandIcon = {
  [CardBrand.Visa]: <Icon.Visa />,
  [CardBrand.Discover]: <Icon.Discover />,
  [CardBrand.UnionPay]: <Icon.UnionPay />,
  [CardBrand.Mastercard]: <Icon.Mastercard />,
  [CardBrand.AmericanExpress]: <Icon.AmericanExpress />,
};

export const SubscriptionRecurringIntervalTitle = {
  [SubscriptionRecurringInterval.Month]: 'Monthly',
};

export const WhiteboardConfigTemplate = {
  [WhiteboardConfigKey.Age]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.Eta]: 'minmax(232px, 1fr)',
  [WhiteboardConfigKey.Time]: 'minmax(96px, 1fr)',
  [WhiteboardConfigKey.Date]: 'minmax(120px, 1fr)',
  [WhiteboardConfigKey.Source]: 'minmax(96px, 1fr)',
  [WhiteboardConfigKey.Breed]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.Payment]: 'minmax(200px, 1fr)',
  [WhiteboardConfigKey.PetName]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.AssignTo]: 'minmax(176px, 1fr)',
  [WhiteboardConfigKey.Priority]: 'minmax(120px, 1fr)',
  [WhiteboardConfigKey.FollowUp]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.OwnerName]: 'minmax(176px, 1fr)',
  [WhiteboardConfigKey.ActionItem]: 'minmax(176px, 1fr)',
  [WhiteboardConfigKey.SeenBefore]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.PhoneNumber]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.ChiefComplaint]: 'minmax(200px, 1fr)',
  [WhiteboardConfigKey.RegistrationLink]: 'minmax(136px, 1fr)',
  [WhiteboardConfigKey.AppointmentStatus]: 'minmax(160px, 1fr)',
  [WhiteboardConfigKey.PatientHasArrived]: 'minmax(144px, 1fr)',
};

export const WhiteboardConfigTitle = {
  [WhiteboardConfigKey.Age]: 'Age',
  [WhiteboardConfigKey.Eta]: 'ETA',
  [WhiteboardConfigKey.Date]: 'Date',
  [WhiteboardConfigKey.Time]: 'Time',
  [WhiteboardConfigKey.Breed]: 'Breed',
  [WhiteboardConfigKey.Source]: 'Source',
  [WhiteboardConfigKey.Payment]: 'Payment',
  [WhiteboardConfigKey.PetName]: 'Pet name',
  [WhiteboardConfigKey.Priority]: 'Priority',
  [WhiteboardConfigKey.AssignTo]: 'Assign to',
  [WhiteboardConfigKey.FollowUp]: 'Follow up',
  [WhiteboardConfigKey.OwnerName]: 'Owner name',
  [WhiteboardConfigKey.ActionItem]: 'Action item',
  [WhiteboardConfigKey.SeenBefore]: 'Seen before',
  [WhiteboardConfigKey.PhoneNumber]: 'Phone number',
  [WhiteboardConfigKey.AppointmentStatus]: 'Status',
  [WhiteboardConfigKey.ChiefComplaint]: 'Chief complaint',
  [WhiteboardConfigKey.PatientHasArrived]: 'Patient has arrived?',
  [WhiteboardConfigKey.RegistrationLink]: 'Registration link sent?',
};
