import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from '.';

export const Stop = () => {
  const { state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      icon={<Icon.Stop />}
      disabled={!state?.time}
      colorType={ButtonColors.LightRed}
      onClick={() => {
        controls?.seek(0);
        controls?.pause();
      }}
    />
  );
};
