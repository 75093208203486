import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  PetSpecies,
  SpeciesListResponse,
  CreateSpeciesPayload,
  UpdateSpeciesPayload,
  SpeciesMutationResponse,
} from './types';

const speciesAdapter = createEntityAdapter<PetSpecies, number>({
  selectId: (item) => item.id,
});

const tagType = 'Species';

export const speciesApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'speciesApi',
  endpoints: (build) => ({
    delete: build.mutation<void, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.UPDATE_MASTER_SPECIES, { id }),
      }),
    }),

    list: build.query<PetSpecies[], void>({
      providesTags: (result) => providesList(tagType)(result),
      transformResponse: (response: SpeciesListResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.GET_SPECIES_MASTER_LIST,
      }),
    }),

    create: build.mutation<PetSpecies, CreateSpeciesPayload>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: SpeciesMutationResponse) => response.data,
      query: (data) => ({
        method: 'post',
        url: URL.ADD_MASTER_SPECIES,
        data: {
          ...data,
          hasGender: true,
        },
      }),
    }),

    update: build.mutation<PetSpecies, UpdateSpeciesPayload>({
      transformResponse: (response: SpeciesMutationResponse) => response.data,
      invalidatesTags: (result, error, { species_id }) =>
        cacheByIdArg(tagType)(result, error, species_id),
      query: ({ name, species_id }) => ({
        data: { name },
        method: 'patch',
        url: generateUrl(URL.UPDATE_MASTER_SPECIES, { id: species_id }),
      }),
    }),
  }),
});

export const speciesSlice = createSlice({
  reducers: {},
  name: 'species',
  initialState: speciesAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      speciesApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        speciesAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      speciesApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        speciesAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      speciesApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        speciesAdapter.updateOne(state, {
          changes: payload,
          id: speciesAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      speciesAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = speciesApi;
