import { useState, useEffect } from 'react';
import {
  type NetworkQuality,
  event_network_quality_change,
} from '@zoom/videosdk';

import { ZoomClient } from 'context/video-comm/types';

export function useNetworkQuality(zoomClient: ZoomClient) {
  const [networkQuality, setNetworkQuality] = useState<
    Record<string, NetworkQuality>
  >({});

  useEffect(() => {
    const onNetworkChange: typeof event_network_quality_change = (payload) => {
      const { type, level, userId } = payload;

      setNetworkQuality((currentNetworkQuality) => {
        const updatedNetworkQuality = { ...currentNetworkQuality };

        if (updatedNetworkQuality[userId] !== undefined) {
          if (type === 'uplink') {
            updatedNetworkQuality[userId] = {
              ...updatedNetworkQuality[userId],
              uplink: level,
            };
          } else {
            updatedNetworkQuality[userId] = {
              ...updatedNetworkQuality[userId],
              downlink: level,
            };
          }
        } else {
          updatedNetworkQuality[userId] = {
            [type]: level,
          };
        }
        return updatedNetworkQuality;
      });
    };

    zoomClient.on('network-quality-change', onNetworkChange);
    return () => {
      zoomClient.off('network-quality-change', onNetworkChange);
    };
  }, [zoomClient]);

  return networkQuality;
}
