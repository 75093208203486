import { HospitalTasksCountData } from 'types';

export interface ActionItemsBadgesResponse {
  case_forms: number;
  order_foods: number;
  medical_fefills: number;
}

export interface ActionItemsBadgesProps {
  caseForms: number;
  orderFoods: number;
  medicalRefills: number;
}

export interface BadgesProps {
  onTheWay: number;
  voicemails: number;
  internalTasks: number;
  tasks: HospitalTasksCountData;
  actionItems: ActionItemsBadgesProps;
}

export enum BadgesRequest {
  All,
  Voicemails,
  ActionItems,
  OnTheWay,
  Tasks,
  InternalTasks,
}
