import styled, { css } from 'styled-components';
import { Badge as TCBadge } from '@gv/triage-components';

import { SubmitTimeoutBadgeProps } from './types';

export const Badge = styled(TCBadge)<
  Pick<SubmitTimeoutBadgeProps, 'margin' | 'fullWidth'>
>`
  justify-content: space-between;

  ${({ margin, fullWidth }) => css`
    ${margin &&
    css`
      margin: ${margin};
    `};

    ${fullWidth &&
    css`
      width: 100%;
    `};
  `};
`;
