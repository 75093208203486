import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import { DurationUnitType } from 'dayjs/plugin/duration';

const { getAge, getFormattedAge } = Utils.Date;

export const parseString = (value?: string) => {
  if (!value || !value?.length) {
    return [];
  }
  try {
    return JSON.parse(value);
  } catch {
    return [];
  }
};

export const getDurationMs = (
  value: string | number,
  unit?: DurationUnitType
) => {
  return dayjs.duration(Number(value), unit || 'year').asMilliseconds();
};

export const getDateOfBirth = ({
  days,
  years,
  months,
}: {
  days: string;
  years: string;
  months: string;
}): string => {
  const dateOfBirth =
    Date.now() -
    (getDurationMs(years) +
      getDurationMs(months, 'month') +
      getDurationMs(days, 'day'));

  return String(dateOfBirth);
};

export { getAge, getFormattedAge };
