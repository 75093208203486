import { StripeCardInfo } from 'types';

export const searchPaths = ['card.last4', 'card.display_brand'];
export const titles = ['Card number', 'Expired at', 'Added at'];
export const templates = {
  mobile: '1fr 1fr',
  desktop: 'repeat(3, 1fr) 160px',
  tablet: '96px repeat(2, 1fr) 160px',
};

export const cellsMobile = [
  { col: '1/2', row: '1/2' },
  { col: '2/3', row: '1/2' },
  { col: '1/2', row: '2/3' },
  { col: '2/3', row: '2/3' },
  { col: '1/3', row: '3/4' },
];

export const getSortedCardList = (cards?: StripeCardInfo[]) => {
  if (!cards?.length) {
    return { cards: [] };
  }
  let result: StripeCardInfo[] = [...cards];
  const index = result.findIndex((card) => card.default_payment_method);
  let defaultCardId: string | undefined = undefined;

  if (index !== -1) {
    const [card] = result.splice(index, 1);
    defaultCardId = card.id;
    result.unshift(card);
  }

  return {
    cards: result,
    defaultCardId,
  };
};
