import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockOfficeChatsApiQuery } from 'store/mockQuery';

import { TransferChatRequest } from './types';

export const officeChatsApi = createApi({
  reducerPath: 'officeChatsApi',
  baseQuery: mockOfficeChatsApiQuery,
  endpoints: (build) => ({
    transferChat: build.mutation<void, TransferChatRequest>({
      query: (data) => {
        return {
          data,
          method: 'post',
          url: URL.OFFICE_CHAT_TRANSFER,
        };
      },
    }),
  }),
});

export const { useTransferChatMutation } = officeChatsApi;
