import { useContext } from 'react';
import { ButtonColors } from '@gv/triage-components';

import { AudioContext } from '.';
import * as Styles from './styles';

export const Speed = () => {
  const { ref, state, controls } = useContext(AudioContext);

  return (
    <Styles.Button
      padding="0"
      disabled={!ref?.current}
      colorType={ButtonColors.Secondary}
      text={ref?.current?.playbackRate === 1 ? 'X1' : 'X2'}
      onClick={() => {
        if (!ref?.current) {
          return;
        }
        if (state?.paused) {
          controls?.play();
        }
        ref.current.playbackRate = ref.current.playbackRate === 1 ? 2 : 1;
      }}
    />
  );
};
