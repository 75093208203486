import { useContext } from 'react';
import { VideoCallAvatar } from '@gv/triage-components';

import { convertAudio } from 'utils/helpers';
import { MediaContext, AvatarActionContext } from 'context/video-comm';

import * as Styles from './styles';
import { Pagination } from './components';
import {
  usePagination,
  useActiveVideo,
  useAvatarAction,
  useGalleryLayout,
  useNetworkQuality,
  useCanvasDimension,
} from './hooks';

export const Video = () => {
  const { media, zoomClient } = useContext(MediaContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = media;
  const { videoRef, canvasDimension } = useCanvasDimension(mediaStream);

  const activeVideo = useActiveVideo(zoomClient);

  const { page, setPage, pageSize, totalPage, totalSize } = usePagination(
    zoomClient,
    canvasDimension
  );

  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zoomClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize,
    }
  );

  const avatarActionState = useAvatarAction(zoomClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zoomClient);

  return (
    <Styles.Viewport>
      <Styles.VideoContainer>
        <Styles.VideoCanvas
          width="800"
          height="600"
          ref={videoRef}
          id="video-canvas"
        />
        <AvatarActionContext.Provider value={avatarActionState}>
          <Styles.AvatarList>
            {visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null;
              }
              const dimension = videoLayout[index];
              const { x, y, width, height } = dimension;
              const { height: canvasHeight } = canvasDimension;
              return (
                <VideoCallAvatar
                  key={user.userId}
                  isActive={activeVideo === user.userId}
                  networkQuality={networkQuality[`${user.userId}`]}
                  participant={{ ...user, audio: convertAudio(user.audio) }}
                  style={{
                    left: `${x}px`,
                    width: `${width}px`,
                    height: `${height}px`,
                    top: `${canvasHeight - y - height}px`,
                  }}
                />
              );
            })}
          </Styles.AvatarList>
        </AvatarActionContext.Provider>
      </Styles.VideoContainer>

      {totalPage > 1 && (
        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
      )}
    </Styles.Viewport>
  );
};
