import { Message } from 'ably';
import debounce from 'lodash.debounce';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import {
  AblyChannelName,
  AblyFolderEvent,
  AblySectionEvent,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';
import { useLazySectionsListQuery } from 'store/api/gv-chats';
import { useOnceLoader, useAblyChannel, useAblyEventChannel } from 'hooks';

export const useSectionListEvent = () => {
  const authUser = useAppSelector(selectAuthUser);
  const [trigger, data] = useLazySectionsListQuery();

  const { isError, isFetching, isUninitialized } = data;
  const { isLoading } = useOnceLoader({
    isError,
    isFetching,
    isUninitialized,
  });

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    lastRequest.current?.abort();
    const req = trigger();
    lastRequest.current = req;
  }, []);

  useEffect(refetch, []);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  useEffect(() => {
    return () => debounceRefetch.cancel();
  }, [debounceRefetch]);

  const onEvent = useCallback(
    (msg: Message) => {
      const { profileId } = msg.data;
      if (profileId !== authUser?.id) {
        debounceRefetch();
      }
    },
    [debounceRefetch, authUser?.id]
  );

  const sectionChannel = useAblyChannel(AblyChannelName.Section);
  const folderChannel = useAblyChannel(AblyChannelName.Folder);

  useAblyEventChannel(Object.values(AblyFolderEvent), onEvent, folderChannel);
  useAblyEventChannel(Object.values(AblySectionEvent), onEvent, sectionChannel);

  return { ...data, refetch, isFetching: isLoading };
};
