import { useContext } from 'react';
import { useNavigate, ButtonColors, ThemeContext } from '@gv/triage-components';

import * as Styles from './styles';
import { PetOwnerFooterButtonsProps } from './types';

export const PetOwnerFooterButtons = ({
  leftTo,
  leftText,
  rightText,
  onLeftClick,
  onRightClick,
  rightButtonType,
  isRightDisabled,
}: PetOwnerFooterButtonsProps) => {
  const navigate = useNavigate();
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <Styles.Container>
      <Styles.Button
        to={leftTo}
        text={leftText}
        colorType={
          isDarkTheme ? ButtonColors.BlackWhite : ButtonColors.TetrieryGrey
        }
        onClick={
          leftTo
            ? undefined
            : () => {
                if (onLeftClick) {
                  onLeftClick();
                } else {
                  navigate(-1);
                }
              }
        }
      />
      <Styles.Button
        text={rightText}
        onClick={onRightClick}
        type={rightButtonType}
        disabled={isRightDisabled}
        colorType={ButtonColors.WhiteBlack}
      />
    </Styles.Container>
  );
};
