import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Workflow } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';
import { cacheByIdArg, providesList, invalidatesList } from 'utils/query-cache';

import {
  WorkflowsData,
  ListRequestQuery,
  WorkflowsRequest,
  WorkflowsRequestData,
  WorkflowsListResponse,
  WorkflowsDetailsResponse,
} from './types';

const workflowsAdapter = createEntityAdapter<Workflow, number>({
  selectId: (item) => item.id,
});

const tagType = 'Workflows';

export const workflowsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'workflowsApi',
  endpoints: (build) => ({
    create: build.mutation<Workflow, WorkflowsRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: WorkflowsDetailsResponse) => response.data,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: `${URL.WORKFLOWS.replace('{hospitalId}', id)}`,
      }),
    }),

    delete: build.mutation<Workflow, WorkflowsRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: WorkflowsDetailsResponse) => response.data,
      query: ({ id, workflowId }) => ({
        method: 'delete',
        url: `${URL.WORKFLOWS.replace('{hospitalId}', `${id}`)}/${workflowId}`,
      }),
    }),

    get: build.query<Workflow, WorkflowsRequest>({
      transformResponse: (response: WorkflowsDetailsResponse) => response.data,
      providesTags: (result, error, { workflowId }) =>
        cacheByIdArg(tagType)(result, error, workflowId),
      query: ({ id, workflowId }) => ({
        method: 'get',
        url: `${URL.WORKFLOWS.replace('{hospitalId}', id)}/${workflowId}`,
      }),
    }),

    update: build.mutation<Workflow, WorkflowsRequestData>({
      transformResponse: (response: WorkflowsDetailsResponse) => response.data,
      invalidatesTags: (result, error, { workflowId }) =>
        cacheByIdArg(tagType)(result, error, workflowId),
      query: ({ id, workflowId, ...data }) => ({
        data,
        method: 'patch',
        url: `${URL.WORKFLOWS.replace('{hospitalId}', `${id}`)}/${workflowId}`,
      }),
    }),

    list: build.query<WorkflowsData, ListRequestQuery>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: WorkflowsListResponse) => response.data,
      query: ({ page = 1, hospitalId, limit = 20, searchString }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          searchString,
        };
        const url = Utils.Query.addParamsToUrl(
          URL.WORKFLOWS.replace('{hospitalId}', hospitalId!),
          searchParams
        );
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const workflowsSlice = createSlice({
  reducers: {},
  name: 'workflows',
  initialState: workflowsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      workflowsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        workflowsAdapter.setAll(state, payload.rows);
      }
    );
    builder.addMatcher(
      workflowsApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        workflowsAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(
      workflowsApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        workflowsAdapter.addOne(state, payload);
      }
    );
    builder.addMatcher(
      workflowsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        workflowsAdapter.updateOne(state, {
          changes: payload,
          id: workflowsAdapter.selectId(payload),
        });
      }
    );
    builder.addMatcher(
      workflowsApi.endpoints.delete.matchFulfilled,
      (state, { payload }) => {
        workflowsAdapter.removeOne(state, payload.id);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      workflowsAdapter.removeAll(state);
    });
  },
});

export const {
  useGetQuery,
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = workflowsApi;
