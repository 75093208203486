import {
  PortalType,
  EnvFeature,
  BaseEnvFeature,
  AWSLoggerConfig,
} from './types';

const env = process.env;

export const getPortalType = (): PortalType => {
  const type = env.REACT_APP_PORTAL_NAME;
  if (type && (Object.values(PortalType) as string[]).includes(type)) {
    return type as PortalType;
  } else {
    return PortalType.Team;
  }
};

export const getAWSLogger = (): AWSLoggerConfig => {
  const awsLoggerConfig = env.REACT_APP_AWS_LOGGER;
  if (!awsLoggerConfig) {
    return {
      configured: false,
    };
  }
  const { key, secret } = JSON.parse(awsLoggerConfig);
  return {
    configured: true,
    key: key as string,
    secret: secret as string,
  };
};

export const getRelease = (
  sentry?: string,
  serverEnv?: string,
  buildVersion?: string
) => {
  let release: string | undefined;
  let environment: string | undefined;
  if (sentry) {
    if (serverEnv && buildVersion) {
      switch (serverEnv) {
        case 'prod':
          environment = 'production';
          break;
        case 'stage':
          environment = 'staging';
          break;
        default:
          environment = 'development';
          break;
      }
      release = `${getPortalType()}-${serverEnv}-${buildVersion}`;
    }
  }
  return { release, environment };
};

const featureAvailableForHospital = (
  hospitalId: string | number | undefined,
  serviceName: EnvFeature
): boolean => {
  const hospitals = env[`REACT_APP_${serviceName}_HOSPITALS`];

  if (!hospitals || !hospitalId) {
    return false;
  }

  return hospitals.split(',').includes(String(hospitalId));
};

const featureDisabledForHospital = (
  hospitalId: string | number | undefined,
  serviceName: BaseEnvFeature
): boolean =>
  featureAvailableForHospital(hospitalId, `DISABLED_${serviceName}`);

const getBlockedCallOutcomeTypes = (
  hospitalId?: string | number | undefined
): string[] | undefined => {
  const value = env.REACT_APP_BLOCKED_CALL_OUTCOME_TYPES;
  return value && hospitalId ? JSON.parse(value)[hospitalId] : undefined;
};

const isAppointmentReasonAvailable = (
  hospitalId?: string | number | undefined
) => featureAvailableForHospital(hospitalId, 'APPOINTMENT_DECLINED_REASON');

const getCallOutcomeTitles = (
  hospitalId?: string | number | undefined
): Record<string, string> | undefined => {
  const value = env.REACT_APP_CALL_OUTCOME_TITLES;
  return value && hospitalId ? JSON.parse(value)[hospitalId] : undefined;
};

export const configFunctions = {
  getCallOutcomeTitles,
  featureDisabledForHospital,
  getBlockedCallOutcomeTypes,
  featureAvailableForHospital,
  isAppointmentReasonAvailable,
};
