import styled from 'styled-components';
import { StandardButton } from '@gv/triage-components';

export const Container = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled(StandardButton)`
  width: ${({ theme }) => (!theme.responsive.isMobile ? '164px' : '100%')};
`;
