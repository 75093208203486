import {
  Nullable,
  OfficeMessage,
  AblyMessageEvent,
} from '@gv/triage-components';

import { MessagesListRequest } from 'store/api/gv-messages/types';

export interface MessagesData {
  nextId?: number;
  prevId?: number;
  isLoading: boolean;
  isNewerLoading: boolean;
  messages: OfficeMessage[];
}

export interface GVMessagesState {
  chats: Record<string, MessagesData>;
}

export type SetMessages = MessagesData;
export type AddOldMessages = MessagesData;
export type AddNewMessages = MessagesData;

export interface AddMessage {
  message: OfficeMessage;
}

export interface RemoveMessage {
  id: number;
}

export interface CountReplies {
  id: number;
}

export interface UpdateMessage {
  id: number;
  message: Partial<OfficeMessage>;
}

export interface ReadAllMessages {
  isRead: boolean;
}

export type ChatBaseReducer<T> = {
  data: T;
  chatId: string;
};

export interface HandleEventPayload {
  nextId?: number;
  name: AblyMessageEvent;
  threadId: Nullable<number>;
  data: {
    id: number;
    chatId: number;
    profileId: number;
    isPublic: boolean;
    notifyIds: number[];
    threadedMessageId?: number;
    messageData: Record<string, any>;
  };
}

export interface SetMessagesThunkProps extends MessagesListRequest {
  scrollToMessage?: (id: number, isThread?: boolean) => void;
}

export const types = {
  setMessages: 'messages/setMessages',
  addOldMessages: 'messages/addOldMessages',
  addNewMessages: 'messages/addNewMessages',
  handleAblyMessageEvent: 'messages/handleAblyMessageEvent',
};
