import { useAudio } from 'react-use';
import { ChildrenProps } from '@gv/triage-components';
import { useState, useEffect, createContext } from 'react';

import { useAppDispatch } from 'store';
import { setIdlePause } from 'store/slices/auth';

import { Play } from './play';
import { Time } from './time';
import { Stop } from './stop';
import { Speed } from './speed';
import { Pause } from './pause';
import { Player } from './player';
import { Download } from './download';
import { RecordPlay } from './record-play';
import { RecordError } from './record-error';
import { ProgressBar } from './progress-bar';
import { PlayerContainer } from './player-container';
import {
  AudioPlayerError,
  AudioPlayerProps,
  AudioPlayerContext,
} from './types';

export const AudioContext = createContext<AudioPlayerContext>({
  src: null,
  ref: null,
  state: null,
  error: null,
  controls: null,
  isLoading: true,
});

export const AudioPlayer = ({
  src,
  children,
}: ChildrenProps<AudioPlayerProps>) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AudioPlayerError>();
  const [audio, state, controls, ref] = useAudio({ src });

  const { paused } = state;

  useEffect(() => {
    dispatch(setIdlePause(!paused));
  }, [paused]);

  useEffect(() => {
    const player = ref.current;
    if (!player) {
      return;
    }

    const onError = (event: any) => {
      const mediaError: MediaError = event.target.error;

      console.error('Audio player error:', mediaError);

      const audioPlayerError: AudioPlayerError = {
        message: mediaError.message,
        prefixMessage: "Unfortunately, you can't listen to voicemail: ",
      };

      if (!mediaError.message) {
        switch (mediaError.code) {
          case mediaError.MEDIA_ERR_ABORTED:
            audioPlayerError.message = 'Audio fetching process was aborted';
            break;
          case mediaError.MEDIA_ERR_DECODE:
            audioPlayerError.message = 'Error occurred on audio downloading';
            break;
          case mediaError.MEDIA_ERR_NETWORK:
            audioPlayerError.message = 'Error occurred on audio decoding';
            break;
          case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            audioPlayerError.message =
              'Audio not found or format not supported';
            break;
          default:
            audioPlayerError.message =
              'Something went wrong please restart audio';
            break;
        }
      }

      setIsLoading(false);
      setError(audioPlayerError);
    };

    const onCanPlay = () => setIsLoading(false);

    player.addEventListener('error', onError);
    player.addEventListener('canplay', onCanPlay);

    return () => {
      player.removeEventListener('error', onError);
      player.removeEventListener('canplay', onCanPlay);
    };
  }, [ref.current]);

  return (
    <AudioContext.Provider
      value={{ ref, src, error, state, controls, isLoading }}
    >
      {audio}
      {children}
    </AudioContext.Provider>
  );
};

AudioPlayer.Play = Play;
AudioPlayer.Time = Time;
AudioPlayer.Stop = Stop;
AudioPlayer.Speed = Speed;
AudioPlayer.Pause = Pause;
AudioPlayer.Player = Player;
AudioPlayer.Download = Download;
AudioPlayer.RecordPlay = RecordPlay;
AudioPlayer.RecordError = RecordError;
AudioPlayer.ProgressBar = ProgressBar;
AudioPlayer.PlayerContainer = PlayerContainer;
