import { AppTheme } from '@gv/triage-components';

import { FormValues } from './types';

export const initialValues: FormValues = {
  cvc: undefined,
  expDate: undefined,
  cardNumber: undefined,
};

export const getCardStyles = (theme: AppTheme) => ({
  invalid: {
    color: theme.config.text.red,
    iconColor: theme.config.text.red,
  },
  base: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Mukta, sans-serif',
    color: theme.config.text.primary,
    '::placeholder': {
      color: theme.config.input.placeholder,
    },
  },
});
