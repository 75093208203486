import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  width: 100%;

  > &:first-child {
    margin-bottom: 24px;
  }
`;
