import { useField, useFormikContext } from 'formik';
import { useMemo, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Utils,
  SmsStatus,
  InputField,
  SelectField,
  RadioFieldGroup,
  HospitalClientType,
  HospitalClientTypeTitle,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { isNumber } from 'utils/helpers';
import { isWideScreenHook } from 'hooks';
import { useListScheduleQuery } from 'store/api/clinic-dvms';
import { selectCurrentTask } from 'store/api/action-queue/selectors';
import { useListQuery as useHospitalListQuery } from 'store/api/hospitals';
import {
  Case,
  CallOutcomeType,
  CallOutcomeClientType,
  CallOutcomeAppointment,
} from 'types';

import { useFormEntities, callOutcomeModel } from '../form';

export const HospitalFields = () => {
  const { values } = useFormikContext<Case>();
  const task = useAppSelector(selectCurrentTask);
  const [careHospitalField, , careHospitalHelpers] =
    useField('care_hospital_id');
  const [careDoctorField, , careDoctorHelpers] = useField('care_doctor_id');
  const [, , clientHospitalHelpers] = useField('client_hospital');
  const [currentClientField] = useField('current_client');
  const [, , smsStatusHelpers] = useField('sms_status');
  const [callOutcomeClientTypeField, , callOutcomeClientTypeFieldHelpers] =
    useField(
      callOutcomeModel[CallOutcomeType.Appointment][
        CallOutcomeAppointment.Scheduled
      ].clientType
    );

  const { client } = useFormEntities();
  const isStopSms = client?.sms_status === SmsStatus.Stop;
  const isWideScreen = isWideScreenHook();

  const wideFieldsCol = isWideScreen ? 6 : 12;

  const careHospital = careHospitalField.value;
  const currentClient = currentClientField.value;

  const isCustomHospital = useMemo(() => {
    if (!careHospital?.length) {
      return false;
    }
    return !isNumber(careHospital);
  }, [careHospital]);

  const { data: hospitals, isFetching: isLoadingHospitals } =
    useHospitalListQuery({ page: 1, limit: 10000 });
  const { data: dvms, isFetching: isLoadingDVM } = useListScheduleQuery(
    { all: true, hospitalId: careHospital },
    { skip: !careHospital || careHospital.length === 0 || isCustomHospital }
  );

  useEffect(() => {
    if (task?.place_back_time || isStopSms) {
      smsStatusHelpers.setValue(String(client?.sms_status));
    }
  }, [task?.place_back_time, isStopSms]);

  useEffect(() => {
    if (isCustomHospital && careDoctorField.value) {
      careDoctorHelpers.setValue('');
    }
  }, [isCustomHospital, careDoctorField.value]);

  useEffect(() => {
    if (currentClient === HospitalClientType.Yes && !careHospital) {
      careHospitalHelpers.setValue(String(values.hospital_id));
    }
  }, [currentClient]);

  const hospitalOptions = useMemo(
    () =>
      hospitals
        ? Utils.Helpers.sortSelectOptions(
            hospitals.hospitals.map((item) => ({
              label: item.name,
              value: String(item.id),
            }))
          )
        : [],
    [hospitals]
  );

  const dvmsOptions = useMemo(
    () =>
      dvms
        ? Utils.Helpers.sortSelectOptions(
            dvms.map((item) => ({
              value: String(item.id),
              label: item.name ?? String(item.id),
            }))
          )
        : [],
    [dvms]
  );

  const setCallOutcomeClientType = (
    value: CallOutcomeClientType,
    concurrentValue: CallOutcomeClientType
  ) => {
    callOutcomeClientTypeFieldHelpers.setValue([
      ...(callOutcomeClientTypeField?.value?.filter(
        (v: CallOutcomeClientType) => v !== concurrentValue
      ) ?? []),
      value,
    ]);
  };

  const isValidNewOption = useCallback(
    (inputValue: string) => !!inputValue && !isNumber(inputValue),
    []
  );

  return (
    <>
      <Row>
        <Col>
          <RadioFieldGroup
            name="current_client"
            label="Are you a current client?"
            items={Object.values(HospitalClientType).map((value) => ({
              value,
              label: HospitalClientTypeTitle[value],
            }))}
            onChange={(value) => {
              if (value === HospitalClientType.No) {
                setCallOutcomeClientType(
                  CallOutcomeClientType.New,
                  CallOutcomeClientType.Current
                );
              }

              if (value === HospitalClientType.Yes) {
                careHospitalHelpers.setValue(String(values.hospital_id));
                setCallOutcomeClientType(
                  CallOutcomeClientType.Current,
                  CallOutcomeClientType.New
                );
              } else if (currentClient === 'N') {
                careHospitalHelpers.setValue(null);
              }
            }}
          />
        </Col>
      </Row>

      {currentClient === 'N' && (
        <Row>
          <Col>
            <SelectField
              onlyValue
              isCreatable
              isClearable
              isAutocomplete
              name="care_hospital_id"
              options={hospitalOptions}
              placeholder="Select hospital"
              isDisabled={isLoadingHospitals}
              isValidNewOption={isValidNewOption}
              label="What clinic do you normally use?"
              onChange={async (option) => {
                if (Utils.Helpers.isSingleSelectValue(option)) {
                  const { value, label } = option || {};
                  if (value !== String(careHospital)) {
                    await careDoctorHelpers.setValue('');
                    careDoctorHelpers.setTouched(true);
                  }
                  await clientHospitalHelpers.setValue(label);
                  clientHospitalHelpers.setTouched(true);
                }
              }}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col col={wideFieldsCol}>
          <InputField
            name="last_seen"
            placeholder="Client answer"
            label="When was your last visit?"
          />
        </Col>
        <Col col={wideFieldsCol}>
          <SelectField
            onlyValue
            isCreatable
            isClearable
            isSearchable
            name="care_doctor_id"
            placeholder="Select doctor"
            isValidNewOption={isValidNewOption}
            label="What is the name of your doctor?"
            options={isCustomHospital ? [] : dvmsOptions}
            isDisabled={!careHospital || !careHospital.length || isLoadingDVM}
          />
        </Col>
      </Row>
    </>
  );
};
