import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { AppointmentType } from 'types';
import { generateUrl } from 'utils/helpers';

import {
  HospitalCreateRequest,
  HospitalUpdateRequest,
  HospitalServicesListRequest,
  HospitalServicesDeleteRequest,
} from './types';

const tagTypes = ['Hospital services'];

export const hospitalServicesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'hospitalServices',
  endpoints: (build) => ({
    create: build.mutation<AppointmentType, HospitalCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_HOSPITAL_SERVICES_LIST,
      }),
    }),

    delete: build.mutation<void, HospitalServicesDeleteRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.UPDATE_HOSPITAL_SERVICE, { id }),
      }),
    }),

    details: build.query<AppointmentType, HospitalServicesDeleteRequest>({
      providesTags: tagTypes,
      query: ({ id }) => ({
        method: 'get',
        url: generateUrl(URL.UPDATE_HOSPITAL_SERVICE, { id }),
      }),
    }),

    update: build.mutation<void, HospitalUpdateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_HOSPITAL_SERVICE, { id: data.id }),
      }),
    }),

    list: build.query<AppointmentType[], HospitalServicesListRequest>({
      providesTags: tagTypes,
      query: ({ targets, hospitalId }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_HOSPITAL_SERVICES_LIST, {
          hospitalId,
          targets: targets?.join(','),
        });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useDeleteMutation,
  useUpdateMutation,
  useCreateMutation,
} = hospitalServicesApi;
export { tagTypes as hospitalServicesTags };
