import styled, { css } from 'styled-components';
import { Icon, Input, Details } from '@gv/triage-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: unset;
`;
export const Wrap = styled.div`
  width: 100%;
  height: 100vh;

  ${({ theme }) => css`
    background-color: ${theme.config.background};
  `}
`;

export const Side = styled.aside`
  z-index: 1500;

  ${({ theme }) => css`
    ${theme.responsive.isDesktop &&
    css`
      position: relative;
      flex: 0 0 80px;
    `}
  `}
`;

export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    width: ${theme.responsive.isDesktop ? 'calc(100% - 80px)' : '100%'};
  `}
`;

export const PageContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const Pawprint = styled(Icon.PawprintFilled)`
  ${({ theme }) => css`
    path {
      fill: ${theme.colors.blue.primary.standard};
    }
  `}
`;

export const POContainer = styled(Details)`
  min-height: 0;
  > div {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`;

export const HiddenInput = styled(Input)`
  display: none;
`;
