import { useMemo, useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';

import { CallOutcomeAppointment } from 'types';
import { getSelectedCallOutcome } from 'utils/helpers';
import { useFormEntities } from 'components/cases/form';
import { useListQuery } from 'store/api/hospital-pricing';

export const useSchedulingPaymentForm = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { hospital, hospitalId } = useFormEntities();
  const hasPaymentAccess = hospital?.has_payment_access;

  const queryProps = useListQuery(
    { hospitalId: hospitalId! },
    { skip: !hasPaymentAccess || !hospitalId }
  );
  const { data: hospitalPrices } = queryProps;

  const selectedCallOutcome = useMemo(
    () => values?.callOutcome && getSelectedCallOutcome(values.callOutcome),
    [values?.callOutcome]
  );

  const isActiveHospitalPayment = useMemo(
    () =>
      hasPaymentAccess &&
      hospitalPrices &&
      hospitalPrices.length > 0 &&
      selectedCallOutcome?.subType === CallOutcomeAppointment.Scheduled,
    [hasPaymentAccess, selectedCallOutcome, hospitalPrices?.length]
  );

  useEffect(() => {
    setFieldValue('isActiveHospitalPayment', isActiveHospitalPayment);
  }, [isActiveHospitalPayment]);

  return {
    queryProps,
    hasPaymentAccess,
    selectedCallOutcome,
    isActiveHospitalPayment,
  };
};
