import styled from 'styled-components';
import { OpenStatus } from '@gv/triage-components';

export const Border = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.config.input.border.default};
`;

export const StatusDot = styled.div<{ status: OpenStatus }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme, status }) => theme.config.openStatus[status]};
`;
