import styled from 'styled-components';
import { Styles } from '@gv/triage-components';

const { heading3 } = Styles.Font;

export const Required = styled.span`
  ${heading3};
  color: ${({ theme }) => theme.colors.red.primary.standard};
`;

export const Wrapper = styled.div`
  margin-top: 16px;
`;

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  display: block;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.config.common.border};
`;
