import { ActionQueue, ActionQueueType } from '@gv/triage-components';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import { getQueueItemId } from 'utils/helpers';

export const useConnectionState = (currentTask?: ActionQueue) => {
  const taskId = currentTask ? getQueueItemId(currentTask) : undefined;

  const isTaskConnected = useMemo(() => {
    const connected = currentTask?.isConnectedByMe;
    if (!connected) {
      return false;
    }

    return !currentTask?.call_transfered;
  }, [currentTask?.isConnectedByMe, !currentTask?.call_transfered]);

  const isConnected = useRef(isTaskConnected);

  const [isLostDialogVisible, setLostDialogVisible] = useState(false);
  const [isReconnectDialogVisible, setReconnectialogVisible] = useState(false);
  const [canShowReconnect, setCanShowReconnect] = useState(false);

  useEffect(() => {
    if (taskId) {
      isConnected.current = isTaskConnected;
    }
  }, [taskId]);

  const isConnectionDialogSupported =
    !currentTask?.call_transfered &&
    !currentTask?.isHolding &&
    !currentTask?.$callEndedByUser &&
    currentTask?.type === ActionQueueType.Voice;

  const isReconnectAvailable = useMemo(() => {
    if (isTaskConnected || !currentTask || !isConnectionDialogSupported) {
      return false;
    }
    return (
      !currentTask.$storedLocally &&
      !currentTask.connected &&
      !currentTask.initializing &&
      currentTask.assignTo === ''
    );
  }, [
    isTaskConnected,
    currentTask?.assignTo,
    currentTask?.connected,
    currentTask?.initializing,
    isConnectionDialogSupported,
    currentTask?.$storedLocally,
  ]);

  useEffect(() => {
    if (isConnected.current !== isTaskConnected) {
      isConnected.current = isTaskConnected;
      if (!isTaskConnected) {
        if (isConnectionDialogSupported) {
          setLostDialogVisible(true);
        }
      } else {
        setCanShowReconnect(true);
      }
    }
  }, [isTaskConnected, isConnectionDialogSupported]);

  const setLostDialogVisibleWrap = useCallback<typeof setLostDialogVisible>(
    (isOpen) => {
      if (!isOpen && isReconnectAvailable) {
        setCanShowReconnect(false);
      }
      setLostDialogVisible(isOpen);
    },
    [isReconnectAvailable]
  );

  const setReconnectialogVisibleWrap = useCallback<
    typeof setReconnectialogVisible
  >((isOpen) => {
    setCanShowReconnect(isOpen);
    setReconnectialogVisible(isOpen);
  }, []);

  useEffect(() => {
    if (isLostDialogVisible && isReconnectAvailable) {
      setCanShowReconnect(false);
    }
    if (
      isReconnectAvailable &&
      !isLostDialogVisible &&
      !isReconnectDialogVisible &&
      canShowReconnect
    ) {
      setReconnectialogVisibleWrap(true);
    } else if (!isReconnectAvailable && isReconnectDialogVisible) {
      setReconnectialogVisibleWrap(false);
    } else if (isLostDialogVisible && isReconnectDialogVisible) {
      setReconnectialogVisibleWrap(false);
    }
  }, [
    canShowReconnect,
    isLostDialogVisible,
    isReconnectAvailable,
    isReconnectDialogVisible,
  ]);

  return {
    isLostDialogVisible,
    isReconnectAvailable,
    isReconnectDialogVisible,
    setLostDialogVisible: setLostDialogVisibleWrap,
    setReconnectialogVisible: setReconnectialogVisibleWrap,
  };
};
