const isIPad = () => {
  return /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2;
};
export const isIOSMobile = () => {
  const { userAgent } = navigator;
  const isIOS = /iPad|iPhone|iPod/i.test(userAgent);
  return isIOS || isIPad();
};

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isAndroidBrowser = () => {
  return /android/i.test(navigator.userAgent);
};

export const isAndroidOrIOSBrowser = () => {
  return isAndroidBrowser() || isIOSMobile();
};
