import styled, { css } from 'styled-components';
import {
  Styles,
  ErrorAlert,
  FlexLayout,
  ListCard as ClientCard,
  BackButton as TCBackButton,
} from '@gv/triage-components';

export const Client = styled.div<{ selected: boolean }>`
  cursor: pointer;

  padding: 16px 16px 0 16px;

  border-radius: 16px;
  border: 1px solid transparent;

  ${({ theme, selected }) => css`
    background-color: ${theme.config.table.default.default.bg};

    &:nth-child(odd) {
      background-color: ${theme.config.table.grey.default.bg};
    }

    ${selected &&
    css`
      border-color: ${theme.colors.blue.primary.standard};
    `}

    &:hover {
      background-color: ${theme.config.table.default.hover.bg};

      &:nth-child(odd) {
        background-color: ${theme.config.table.grey.hover.bg};
      }
    }

    &:active {
      background-color: ${theme.config.table.default.action.bg};

      &:nth-child(odd) {
        background-color: ${theme.config.table.default.action.bg};
      }
    }
  `}
`;

export const Name = styled.h4`
  ${({ theme }) => css`
    ${theme.responsive.isMobile &&
    css`
      margin-bottom: 8px;
    `}
  `}
`;

export const Label = styled.span`
  ${Styles.Font.captionSemibold};
  color: ${({ theme }) => theme.config.text.secondary};
`;

export const ListHeader = styled(FlexLayout)`
  justify-content: space-between;
  padding-right: 16px;
`;

export const Error = styled(ErrorAlert)`
  margin: 0 16px 8px;
`;

export const BackButton = styled(TCBackButton)`
  button {
    padding: 12px 12px 12px 16px;
  }
`;

export const ListCard = styled(ClientCard)`
  ${({ theme, selected }) => css`
    ${selected &&
    css`
      border-color: ${theme.colors.blue.primary.standard};

      &:nth-child(odd) {
        border-color: ${theme.colors.blue.primary.standard};
      }
    `}
  `}
`;
