import { Utils, BadgeDot, RadioGroupProps } from '@gv/triage-components';

import { InternalTaskPriority } from 'types';
import { InternalTaskPriorityBadgeColor } from 'types/data';

export const requiredText = '(Required)';

export const priorityOptions: RadioGroupProps['items'] = Object.values(
  InternalTaskPriority
).map((value) => ({
  value,
  label: Utils.String.capitalize(value),
  component: <BadgeDot styleType={InternalTaskPriorityBadgeColor[value]} />,
}));
