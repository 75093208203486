import { useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';

export const useLoggerHook = () => {
  const location = useLocation();
  const windowSize = useWindowSize();

  const { search, pathname } = location;

  useEffect(() => {
    console.log('location changed to', pathname + search);
  }, [search, pathname]);

  useEffect(() => {
    console.log('window size changed to', windowSize);
  }, [windowSize]);
};
