import { FormikValues } from 'formik';
import { useState, useCallback } from 'react';
import {
  Loader,
  useNavigate,
  ButtonTypes,
  AuthPOLayout,
  handleMutation,
} from '@gv/triage-components';

import { PetOwnerFooterButtons } from 'components';
import { useCreatePetByPOMutation } from 'store/api/clients';
import {
  PetFields,
  ageValidate,
  initialValues,
  getBasePayload,
  validationSchema,
} from 'pages/hospitals/details/clients/pet';

import * as Styles from './styles';

export const AddNewPet = () => {
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [create, mutation] = useCreatePetByPOMutation();

  const onSuccess = useCallback(() => navigate(-1), [navigate]);

  handleMutation({ ...mutation, onSuccess });

  const onSubmit = (values: FormikValues) => create(getBasePayload(values));

  return (
    <AuthPOLayout
      validateOnBlur
      title="Add pet"
      validateOnChange
      enableReinitialize
      onSubmit={onSubmit}
      validate={ageValidate}
      initialValues={initialValues}
      validationSchema={validationSchema}
      subtitle="Please add your pet detail, to continue:"
      footer={({ dirty, isSubmitting }) => (
        <PetOwnerFooterButtons
          leftText="Back"
          rightText="Add"
          onLeftClick={() => navigate(-1)}
          rightButtonType={ButtonTypes.Submit}
          isRightDisabled={!dirty || isSubmitting}
        />
      )}
    >
      <Styles.Container>
        <PetFields setLoading={setLoading} />
        <Loader absolutePosition isLoading={isLoading} />
      </Styles.Container>
    </AuthPOLayout>
  );
};
