import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import {
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import {
  ListData,
  OnTheWay,
  ListRequest,
  ListResponse,
  UpdateRequest,
} from './types';

const tagType = 'onTheWay';

export const onTheWayApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'onTheWayApi',
  endpoints: (build) => ({
    update: build.mutation<void, UpdateRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.EDIT_ON_WAY_BOARD.replace('{id}', String(id)),
      }),
    }),
    arrive: build.mutation<void, OnTheWay['id']>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'patch',
        url: URL.COMPLETE_ON_WAY_BOARD.replace('{id}', String(id)),
        data: {
          patientArrivedAt: new Date().toISOString(),
        },
      }),
    }),
    list: build.query<ListData, ListRequest>({
      transformResponse: (response: ListResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.rows),
      query: ({ search, page = 1, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          search,
        };

        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(URL.ON_WAY_BOARD, searchParams),
        };
      },
    }),
  }),
});

export const { useListQuery, useUpdateMutation, useArriveMutation } =
  onTheWayApi;
