import { createApi } from '@reduxjs/toolkit/query/react';

import { Invoice } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  InvoiceRefundRequst,
  InvoiceCancelRequest,
  InvoiceDetailsRequest,
} from './types';

const tagTypes = ['Invoices'];

export const invoicesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'invoicesApi',
  endpoints: (build) => ({
    details: build.query<Invoice, InvoiceDetailsRequest>({
      providesTags: tagTypes,
      query: ({ id }) => {
        return {
          method: 'get',
          url: generateUrl(URL.GET_INVOICE_DETAILS, { id }),
        };
      },
    }),

    refund: build.mutation<void, InvoiceRefundRequst>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => {
        return {
          data,
          method: 'post',
          url: generateUrl(URL.CREATE_REFUND, { id }),
        };
      },
    }),

    cancel: build.mutation<void, InvoiceCancelRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, reason }) => {
        return {
          method: 'post',
          data: { reason },
          url: generateUrl(URL.CANCEL_INVOICE, { id }),
        };
      },
    }),
  }),
});

export const { useDetailsQuery, useRefundMutation, useCancelMutation } =
  invoicesApi;
