import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Hospital, OpenStatus, WorkingHoursUtils } from '@gv/triage-components';

import { OpenStatusTextOnCall } from 'types/data';
import { useOnCallHoursQuery } from 'store/api/calendar';
import { EventsResponse } from 'store/api/calendar/types';

const { getData, getOpenStatus } = WorkingHoursUtils;

const getOnCallStatus = (
  onCallSchedules?: EventsResponse
): ReturnType<typeof getOpenStatus> | undefined => {
  if (!onCallSchedules?.events) {
    return;
  }

  const { events } = onCallSchedules;

  const sortedEvents = [...events].sort(
    (a, b) => a.extendedProps?.startSlot - b.extendedProps?.startSlot
  );

  const statuses = sortedEvents.map(({ extendedProps }) => {
    const { item, timezone } = extendedProps ?? {};

    return getOpenStatus(
      dayjs().tz(timezone?.time_zone_code),
      getData(
        {
          [item.start_date]: item,
        },
        timezone?.time_zone_code
      ).items,
      OpenStatusTextOnCall
    );
  });

  const onCallNow = statuses.find(
    (status) => status?.type === OpenStatus.Opened
  );

  if (onCallNow) {
    return onCallNow;
  }

  return statuses.find((status) => status?.type !== OpenStatus.Closed);
};

export const useCallHoursStatus = (hospital?: Hospital) => {
  const hospitalId = hospital?.id;
  const date = dayjs().format('YYYY/MM/DD');

  const { data } = useOnCallHoursQuery(
    {
      ids: [],
      end: date,
      start: date,
      hospitalId: hospitalId!,
    },
    { skip: !hospitalId }
  );

  const onCallStatus = useMemo(() => getOnCallStatus(data), [data]);

  return onCallStatus;
};
