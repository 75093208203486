import { useState } from 'react';
import {
  Row,
  Col,
  FlexLayout,
  BadgePadding,
  SectionHeaderExpandable,
} from '@gv/triage-components';

import { parseArray } from 'utils/helpers';

import { SectionProps, SectionsProps, ExpandableListProps } from './types';
import {
  Spacing,
  MainContainer,
  TitleContainer,
  StyledIconListItem,
  InnerBlockContainer,
} from './styles';

export const commonBadgeProps = {
  isSelectable: true,
  padding: BadgePadding.Big,
  style: { height: '40px', width: 'fit-content' },
};

export const Sections = ({
  id,
  title,
  gap = 24,
  children,
  ...props
}: SectionsProps) => {
  return (
    <MainContainer id={id} {...props}>
      <>
        {title && (
          <TitleContainer>
            <h3>{title}</h3>
          </TitleContainer>
        )}
        {children && (
          <FlexLayout gap={gap} flexDirection="column">
            {children}
          </FlexLayout>
        )}
      </>
    </MainContainer>
  );
};

export const ExpandableSection = ({
  label,
  gap = 16,
  children,
  withoutSpacing,
  innerBlockProps = {},
  defaultOpened = false,
}: SectionProps) => {
  const [isOpened, setIsOpened] = useState(defaultOpened);

  const spacing = withoutSpacing ? null : <Spacing />;

  return (
    <FlexLayout gap={gap} flexDirection="column">
      <SectionHeaderExpandable
        title={label}
        isOpened={isOpened}
        onToggle={() => setIsOpened(!isOpened)}
      />

      {children && isOpened ? (
        <InnerBlockContainer {...innerBlockProps}>
          {children}
        </InnerBlockContainer>
      ) : (
        spacing
      )}
    </FlexLayout>
  );
};

export const ExpandableList = ({
  value,
  label,
  defaultOpened,
}: ExpandableListProps) => {
  const data: Array<string> = parseArray(value);

  if (data.length === 0) {
    return null;
  }

  return (
    <ExpandableSection label={label} defaultOpened={defaultOpened}>
      <Row>
        {data.map((item, index) => (
          <Col col={6} key={`${item}-${index.toString()}`}>
            <StyledIconListItem title={item} />
          </Col>
        ))}
      </Row>
    </ExpandableSection>
  );
};
