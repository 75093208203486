import { Row, Checkbox, ErrorMessage } from '@gv/triage-components';
import {
  useField,
  FieldMetaProps,
  FieldInputProps,
  FieldHelperProps,
} from 'formik';

import { CallOutcomeOptions } from 'types';

import * as Styles from './styles';

export const selects = (
  formikField: [
    FieldInputProps<string[]>,
    FieldMetaProps<string[]>,
    FieldHelperProps<string[]>,
  ],
  options: Readonly<string[]>,
  optionsElements?: Record<string, JSX.Element>
) => {
  const [field, fieldMeta, fieldHelpers] = formikField;
  const selected = field.value ?? [];
  return (
    <div>
      <Row>
        {options.map((option, idx) => (
          <Styles.Col col={6} key={`${field.name}_${option}_${idx}`}>
            <Checkbox
              label={option}
              id={`${field.name}_${option}`}
              checked={selected.includes(option)}
              onChange={async () => {
                const newValue = [...selected];
                const index = newValue.indexOf(option);
                if (index >= 0) {
                  newValue.splice(index, 1);
                } else {
                  newValue.push(option);
                }
                await fieldHelpers.setValue(newValue);
                fieldHelpers.setTouched(true);
              }}
            />

            {selected.includes(option) && optionsElements?.[option]}
          </Styles.Col>
        ))}
      </Row>
      {fieldMeta.touched && fieldMeta.error && (
        <ErrorMessage>{fieldMeta.error}</ErrorMessage>
      )}
    </div>
  );
};

export const Administrative = ({ name }: { name: string }) => {
  const administrative = useField(name);

  return selects(administrative, CallOutcomeOptions);
};
