import { useMemo, useContext } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { Utils, Nullable, BasePageProps } from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { SidebarContext } from 'context/sidebar';
import { useDetailsQuery } from 'store/api/hospitals';
import { getHospitalSpeciality } from 'utils/storage';
import { selectHospital } from 'store/api/hospitals/selectors';
import { useOnCallProtocolQuery } from 'store/api/on-call-protocol';
import { selectOnCallProtocol } from 'store/api/on-call-protocol/selectors';
import {
  selectAuthUser,
  selectPermissions,
  selectAuthHospital,
} from 'store/slices/auth';

import { generateRequestProps } from './helpers';
import { UseHospitalProps, UseHospitalOptionalProps } from './types';

/**
 * A custom hook for retrieving and formatting hospital-related routing parameters based on the current user's permissions and route parameters.
 *
 * Attention: this hook should only be used on hospital settings pages
 *
 * @returns An object containing navigation parameters and metadata about the user's permission context
 *
 */

export const useHospitalParams = () => {
  const user = useAppSelector(selectAuthUser);
  const permission = useAppSelector(selectPermissions);
  const params = useParams();

  const info = useMemo(() => {
    let basePath: string = '';
    let backPath: string | undefined;

    let data = { ...params };
    const isPreferences = permission.preference && !!user?.hospital_id;

    if (isPreferences && !data.id) {
      basePath = Routes.Hospitals.Details.HomeInfo;
      data.id = user.hospital_id ? String(user.hospital_id) : undefined;
    } else if (data.id) {
      backPath = isPreferences
        ? Routes.Hospitals.Details.HomeInfo
        : Routes.Hospitals.Home;
      basePath = generatePath(Routes.Hospitals.Details.Home, {
        id: data.id,
      });
    }
    data.basePath = basePath;
    data.backPath = backPath;
    data.isPreferences = isPreferences ? 'true' : undefined;
    data.preferenceHospitalId = isPreferences
      ? String(user?.hospital_id)
      : undefined;
    return data;
  }, [params, permission.preference, user?.hospital_id]);

  return info;
};

export const useOptionalHospitalData = (
  hospitalId?: string
): UseHospitalOptionalProps => {
  const { id } = useHospitalParams();
  const currentHospitalId = hospitalId || id;

  const hospital = useAppSelector(selectHospital(currentHospitalId));
  const onCallProtocol = useAppSelector(
    selectOnCallProtocol(currentHospitalId)
  );

  return {
    hospital,
    onCallProtocol,
    id: currentHospitalId,
  };
};

export const useHospitalData = (): UseHospitalProps => {
  const { id, hospital, onCallProtocol } = useOptionalHospitalData();
  return {
    id: id!,
    hospital: hospital!,
    onCallProtocol: onCallProtocol!,
  };
};

export const useHospitalDataApi = (id: string): BasePageProps => {
  const requestProps = generateRequestProps(id);

  const details = useDetailsQuery(id, requestProps);
  const protocol = useOnCallProtocolQuery(id, requestProps);
  const { hospital, onCallProtocol } = useOptionalHospitalData(id);

  return {
    isError: details.isError || protocol.isError,
    isLoading:
      (details.isFetching || protocol.isFetching) &&
      (!hospital || !onCallProtocol),
    error: Utils.Helpers.joinStrings(
      [details.error?.message, protocol.error?.message],
      '\n'
    ),
    refetch: () => {
      if (details.isError) {
        details.refetch();
      }
      if (protocol.isError) {
        protocol.refetch();
      }
    },
  };
};

export const useEditPermission = (): boolean => {
  const { setup } = useAppSelector(selectPermissions);
  return setup ?? false;
};

export const useCalendarPermission = (): boolean => {
  const { links } = useContext(SidebarContext);

  const hasAccess = useMemo(() => {
    const path = Routes.Calendar.Home;
    return (
      links.findIndex((value) => value.path && path.includes(value.path)) !== -1
    );
  }, [links]);

  return hasAccess;
};

export const useHospitalSpeciality = (): Nullable<string> => {
  const hospital = useAppSelector(selectAuthHospital);
  return hospital?.speciality ?? getHospitalSpeciality();
};
