import {
  Utils,
  BadgeColor,
  CardConversationStatus,
} from '@gv/triage-components';

const { isExpired } = Utils.Date;

export const getBadgeColor = (
  started?: number,
  status?: CardConversationStatus
) => {
  const expired = isExpired(started, 4);

  if (expired) {
    return BadgeColor.Red;
  }
  if (status) {
    switch (status) {
      case CardConversationStatus.Holding:
        return BadgeColor.Yellow;
      case CardConversationStatus.Ended:
      case CardConversationStatus.EndedHolding:
        return BadgeColor.Red;
      default:
        return BadgeColor.Green;
    }
  }
  return BadgeColor.Green;
};
