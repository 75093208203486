import * as auth from './auth';
import * as comm from './comm';
import * as cases from './cases';
import * as client from './client';
import * as upload from './upload';
import * as greeting from './greeting';
import * as hospital from './hospital';
import * as payments from './payments';
import * as analytics from './analytics';
import * as onTheWay from './on-the-way';
import * as activation from './activation';
import * as voicemails from './voicemails';
import * as gvMessages from './gv-messages';
import * as videoCalls from './video-calls';
import * as actionItems from './action-items';
import * as hospitalsReport from './hospitals';
import * as googleAuthToken from './google-auth';
import * as hospitalTasks from './hospital-tasks';
import * as internalTasks from './internal-tasks';
import * as customerFeedback from './customer-feedback';
import * as hospitalServices from './hospital-services';
import * as hospitalDashboard from './hospital-dashboard';

export const api = {
  auth,
  comm,
  cases,
  client,
  upload,
  greeting,
  hospital,
  payments,
  onTheWay,
  analytics,
  activation,
  gvMessages,
  voicemails,
  videoCalls,
  actionItems,
  hospitalTasks,
  internalTasks,
  hospitalsReport,
  googleAuthToken,
  hospitalServices,
  customerFeedback,
  hospitalDashboard,
};
