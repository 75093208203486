import { WalkthroughStep } from 'types/data';

import { getTarget } from './helpers';

export const target = {
  [WalkthroughStep.Intro]: 'body',
  [WalkthroughStep.Cases]: getTarget(WalkthroughStep.Cases),
  [WalkthroughStep.Calendar]: getTarget(WalkthroughStep.Calendar),
  [WalkthroughStep.MyAccount]: getTarget(WalkthroughStep.MyAccount),
  [WalkthroughStep.Analytics]: getTarget(WalkthroughStep.Analytics),
  [WalkthroughStep.HelpCenter]: getTarget(WalkthroughStep.HelpCenter),
  [WalkthroughStep.Acceptance]: getTarget(WalkthroughStep.Acceptance),
  [WalkthroughStep.ActionItems]: getTarget(WalkthroughStep.ActionItems),
  [WalkthroughStep.HospitalProfile]: getTarget(WalkthroughStep.HospitalProfile),
};
