import styled, { css } from 'styled-components';

import * as CommonStyles from '../styles';

export const Dropzone = styled.div`
  ${CommonStyles.FileCard};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='${theme.colors.grey.secondary.standard.replace(
      '#',
      '%23'
    )}FF' stroke-width='4' stroke-dasharray='13' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");

    svg path {
      fill: ${theme.config.common.icon};
    }
  `}
`;

export const UploadedFile = styled.div`
  ${CommonStyles.FileCard};
`;
