import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { Role, RolePermission } from 'types';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  RolesListRequest,
  RolesListResponse,
  RoleCreateRequest,
  RoleUpdateRequest,
  RoleDetailsRequest,
  RoleCreateResponse,
  RoleUpdateResponse,
  RolesRawListRequest,
  RoleDetailstResponse,
  RolesRawListResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;

const permissionsAdapter = createEntityAdapter<RolePermission, number>({
  selectId: (item) => item.id,
});

const tagType = 'Roles';

export const permissionsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'permissionsApi',
  endpoints: (build) => ({
    rawList: build.query<Role[], RolesRawListRequest>({
      providesTags: providesList(tagType),
      transformResponse: (response: RolesRawListResponse) => response.data,
      query: (request) => {
        return {
          method: 'get',
          url: addParamsToUrl(URL.GET_ROLES, request),
        };
      },
    }),

    list: build.query<RolePermission[], RolesListRequest>({
      providesTags: providesList(tagType),
      transformResponse: (response: RolesListResponse) => response.data,
      query: ({ hospitalId }) => {
        return {
          method: 'get',
          url: URL.GET_ROLES_PERMISSIONS.replace(':hospitalId', hospitalId),
        };
      },
    }),

    create: build.mutation<RolePermission, RoleCreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: RoleCreateResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.CREATE_ROLE_PERMISSIONS, {
          hospitalId,
        }),
      }),
    }),

    details: build.query<RolePermission, RoleDetailsRequest>({
      transformResponse: (response: RoleDetailstResponse) => response.data,
      providesTags: (result, error, { roleId }) =>
        cacheByIdArg(tagType)(result, error, roleId),
      query: ({ roleId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_ROLE_PERMISSIONS, {
          roleId,
          hospitalId,
        }),
      }),
    }),

    update: build.mutation<RolePermission, RoleUpdateRequest>({
      transformResponse: (response: RoleUpdateResponse) => response.data,
      invalidatesTags: (result, error, { roleId }) =>
        cacheByIdArg(tagType)(result, error, roleId),
      query: ({ type, roleId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_ROLE_PERMISSIONS, {
          roleId,
          hospitalId,
        }),
      }),
    }),
  }),
});

export const permissionsSlice = createSlice({
  reducers: {},
  name: 'permissions',
  initialState: permissionsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      permissionsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        permissionsAdapter.setAll(state, payload);
      }
    );
    builder.addMatcher(
      permissionsApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        permissionsAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      permissionsAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useRawListQuery,
  useCreateMutation,
  useUpdateMutation,
} = permissionsApi;
