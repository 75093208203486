import {
  Utils,
  Hospital,
  WorkingHoursUtils,
  StandardButtonProps,
  OpenStatusTextHospital,
  CaseFormHospitalReferral,
} from '@gv/triage-components';

import * as Styles from './styles';

const { getData, getOpenStatus, getHospitalTime } = WorkingHoursUtils;

export const getShortName = (name?: string): string => {
  return Utils.Helpers.textValue(
    name?.split(' ')?.[0],
    '_value_ - ER',
    ''
  ) as string;
};

export const getTabsOptions = (
  values: CaseFormHospitalReferral[],
  hospital?: Hospital
) => {
  const time = hospital ? getHospitalTime(hospital).time : undefined;
  return values.map((item) => {
    const tab: Partial<StandardButtonProps> = {
      value: String(item.id),
      text: getShortName(item?.name),
    };
    if (time) {
      const schedule = getData(item.clinic_hours).items;
      const status = getOpenStatus(time, schedule, OpenStatusTextHospital);
      if (status) {
        tab.icon = <Styles.StatusDot status={status.type} />;
      }
    }
    return tab;
  });
};
