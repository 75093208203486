import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import { generateItems } from '../helpers';
import {
  EventsResponse,
  ClinicHoursRequest,
  ClinicHoursResponse,
  ClinicHourDeleteRequest,
  ClinicHourCreateRequest,
} from '../types';

const tagTypes = ['ForwardingHours'];

export const forwardingHoursApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'forwardingHoursApi',
  endpoints: (build) => ({
    delete: build.mutation<void, ClinicHourDeleteRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, slot, groupId, hospitalId }) => ({
        method: 'delete',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.DELETE_FORWARDING_SCHEDULE, {
            id,
          }),
          {
            group_id: groupId,
            hospital_id: hospitalId,
            slot: JSON.stringify(slot),
          }
        ),
      }),
    }),

    list: build.query<EventsResponse, ClinicHoursRequest>({
      providesTags: tagTypes,
      transformResponse: (response: ClinicHoursResponse) =>
        generateItems(response?.data),
      query: ({ end, start, hospitalId }) => {
        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(
            URL.GET_FORWARDING_HOURS.replace(
              '{hospital_id}',
              String(hospitalId)
            ),
            {
              end_date: end,
              start_date: start,
            }
          ),
        };
      },
    }),

    create: build.mutation<void, ClinicHourCreateRequest>({
      invalidatesTags: tagTypes,
      query: ({
        slot,
        every,
        endDate,
        startDate,
        hospitalId,
        clinicDays,
        ...props
      }) => ({
        method: 'post',
        url: URL.ADD_FORWARDING_HOURS,
        data: {
          end_date: endDate,
          every: String(every),
          start_date: startDate,
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
          clinic_days: JSON.stringify(clinicDays),
          ...props,
        },
      }),
    }),
  }),
});

export { tagTypes as forwardingHoursTags };
