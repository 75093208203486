import { useState, useLayoutEffect } from 'react';

export const useVisibility = () => {
  const [isVisible, setVisible] = useState(
    document.visibilityState !== 'hidden'
  );

  useLayoutEffect(() => {
    const onVisibilityChange = () =>
      setVisible(document.visibilityState !== 'hidden');

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  return isVisible;
};
