import { MockHandler } from '@gv/triage-components';

export const contactsMockApiHandlers: MockHandler[] = [
  {
    method: 'post',
    url: '/bulk-message',
    responseData: {
      data: {
        status: 'success',
      },
    },
  },
];
