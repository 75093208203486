import { useRef, useCallback } from 'react';

import { AudioControls } from 'types';

export const useListAudio = () => {
  const playerRef = useRef<HTMLAudioElement>();
  const playingControlsRef = useRef<AudioControls>();

  const setPlayingControlsRef = useCallback(
    (ref?: HTMLAudioElement, controls?: AudioControls) => {
      if (playerRef.current === ref) {
        return;
      }
      playingControlsRef.current?.seek(0);
      playingControlsRef.current?.pause();
      playingControlsRef.current = controls;
      playerRef.current = ref;
    },
    [playingControlsRef.current]
  );

  return { setPlayingControlsRef };
};
