import { Utils, FlexLayout, EllipsisText } from '@gv/triage-components';

import { AudioPlayer } from 'components/audio-player';

import * as Styles from './styles';
import { VoicemailAudioProps } from './types';

const { formatDate } = Utils.Date;

export const VoicemailAudio = ({
  voicemail,
  asSidePanel = false,
  ...props
}: VoicemailAudioProps) => {
  return (
    <Styles.Container
      flexDirection="column"
      asSidePanel={asSidePanel}
      {...props}
    >
      <Styles.Content flexDirection="column" asSidePanel={asSidePanel}>
        <FlexLayout justifyContent="space-between">
          <FlexLayout flexDirection="column">
            <h3>Voicemail GV{voicemail.id}</h3>
            <EllipsisText>
              {formatDate(new Date(+voicemail.created_at), 'DD/MM/YYYY - LT')}
            </EllipsisText>
          </FlexLayout>
          <AudioPlayer.Download />
        </FlexLayout>
        <AudioPlayer.PlayerContainer>
          <AudioPlayer.Player />
        </AudioPlayer.PlayerContainer>
      </Styles.Content>
    </Styles.Container>
  );
};
