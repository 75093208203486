import { useMemo } from 'react';
import { useField } from 'formik';
import {
  Utils,
  SelectField,
  SelectOption,
  StandardButton,
  ButtonStyleTypes,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';

import { SelectAssigneeProps } from './types';

const {
  String: { capitalize },
  Helpers: { isSingleSelectValue },
} = Utils;

export const unassignedValue = 'unassigned';
export const unassignedOption = {
  value: unassignedValue,
  label: capitalize(unassignedValue),
};

export const SelectAssignee = ({
  assignOptions,
  assignSelectFieldProps,
  assignToMeDisabled = false,
}: SelectAssigneeProps) => {
  const [, , assigneeHelpers] = useField('assignee');
  const authUser = useAppSelector(selectAuthUser);

  const selectOptions = useMemo(() => {
    const result: SelectOption[] = [unassignedOption];

    if (assignOptions) {
      result.push(
        ...assignOptions.map(({ name, id: memberId, ...rest }) => ({
          value: memberId.toString(),
          label: name || ('email' in rest && rest.email) || 'unknown',
        }))
      );
    }

    return result;
  }, [assignOptions]);

  return (
    <>
      <SelectField
        isClearable
        isSearchable
        name="assignee"
        label="Assign to"
        placeholderColorAsText
        options={selectOptions}
        placeholder={unassignedOption.label}
        {...assignSelectFieldProps}
        onChange={(value) => {
          if (isSingleSelectValue(value)) {
            if (value?.value === unassignedValue) {
              assigneeHelpers.setValue(null);
            }
          }
        }}
      />
      <StandardButton
        transparent
        padding="0"
        text="Assign to me"
        disabled={assignToMeDisabled}
        styleType={ButtonStyleTypes.Ghost}
        onClick={() =>
          assigneeHelpers.setValue({
            label: authUser?.name,
            value: String(authUser?.id),
          })
        }
      />
    </>
  );
};
