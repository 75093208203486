import { createApi } from '@reduxjs/toolkit/query/react';
import { SelectOption, LanguageOption } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import {
  AppVersion,
  VersionResponse,
  PaymentHospital,
  LanguagesListResponse,
  PaymentHospitalsResponse,
} from './types';

export const commonApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'commonApi',
  endpoints: (build) => ({
    languagesList: build.query<LanguageOption[], void>({
      transformResponse: (response: LanguagesListResponse) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.LANGUAGES,
        };
      },
    }),

    version: build.query<AppVersion, void>({
      query: () => {
        return {
          method: 'get',
          url: URL.GET_VERSION,
        };
      },
      transformResponse: (response: VersionResponse) => {
        const result = response.data;
        return result;
      },
    }),

    paymentHospitals: build.query<SelectOption[], void>({
      query: () => ({
        method: 'get',
        url: URL.GET_ALL_HOSPITAL_LIST_FOR_ON_CALL_PROTOCOL,
      }),
      transformResponse: (response: PaymentHospitalsResponse) =>
        response.data?.hospitals?.map((item) => ({
          ...item,
          label: item.name,
          value: String(item.id),
        })) ?? [],
    }),
  }),
});

export const {
  useLazyVersionQuery,
  useLanguagesListQuery,
  usePaymentHospitalsQuery,
} = commonApi;
export type { PaymentHospital };
