import { useContext } from 'react';
import {
  Icon,
  Dialog,
  TextColor,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { CallContext } from '../context';

import { ReconnectProps } from './types';

export const ReconnectDialog = ({
  setOpen,
  onSubmit,
  onReconnect,
  ...props
}: ReconnectProps) => {
  const { form } = useContext(CallContext);

  return (
    <Dialog
      closeOnEscape={false}
      title="Connection error"
      iconColor={TextColor.Red}
      icon={<Icon.Completed />}
      closeOnDocumentClick={false}
      text={
        <>
          Something went wrong and your call with{' '}
          <span className="semibold">{form.values.client_name}</span>was
          disconnected, but it is still in the queue and you can reconnect to it
        </>
      }
      buttons={(close) => (
        <>
          <StandardButton
            fullWidth
            text="Reconnect"
            colorType={ButtonColors.LightGreen}
            onClick={() => {
              close();
              setOpen(false);
              onReconnect();
            }}
          />
          <StandardButton
            fullWidth
            text="Edit case"
            colorType={ButtonColors.Secondary}
            onClick={() => {
              close();
              setOpen(false);
            }}
          />
          <StandardButton
            fullWidth
            text="Submit case"
            onClick={() => {
              close();
              setOpen(false);
              onSubmit();
            }}
          />
        </>
      )}
      {...props}
    />
  );
};
