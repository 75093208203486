import styled, { css } from 'styled-components';

export const Tooltip = styled.div<{ width: string }>`
  padding: 24px;

  ${({ theme, width }) => css`
    width: ${width};
    border-radius: ${theme.borderRadius};
    background-color: ${theme.config.background};
  `}
`;
