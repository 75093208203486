import { useField } from 'formik';
import { useMemo, useState, useEffect } from 'react';

import { CorporateGroupType } from 'types';
import { getIsERHospital } from 'utils/helpers';
import { useGetQuery } from 'store/api/acceptance';
import { useDetailsQuery } from 'store/api/hospitals';
import { useOnCallProtocolQuery } from 'store/api/on-call-protocol';
import { getCorporateGroupOptions } from 'components/cases/helpers';
import { useHospitalTime } from 'components/cases/form/useHospitalTime';
import { useListQuery as useCorporateGroupListQuery } from 'store/api/corporate-group';
import {
  useFormEntities,
  callOutcomeModel,
  CaseFormTextOutProps,
} from 'components/cases/form';

const {
  emergency: { referToEr },
} = callOutcomeModel;

export const useReferToOtherEr = ({
  setReferral,
}: Pick<CaseFormTextOutProps, 'setReferral'>) => {
  const [showReferrals, setShowReferrals] = useState(false);
  const { hospital, referrals, hospitalId, isMainHospital } = useFormEntities();
  const { isHospitalOpen } = useHospitalTime();

  const isERHospital = getIsERHospital(hospital?.speciality);
  const isOpenedERHospital = isHospitalOpen && isERHospital;

  const { data: corporateGroup, ...props } = useCorporateGroupListQuery(
    { hospitalId: hospitalId!, type: CorporateGroupType.All },
    { skip: !hospitalId || !isMainHospital }
  );

  const { referToEr: corporateOptions } = useMemo(
    () => getCorporateGroupOptions(corporateGroup?.grouped) ?? {},
    [corporateGroup?.grouped]
  );

  const [corporate, , corporateHelpers] = useField(
    referToEr.corporateHospitalId
  );
  const [referral, , referralHelpers] = useField(referToEr.hospitalRefId);
  const [, , referralMainHospitalHelpers] = useField(referToEr.hospitalId);

  const { data: selectedCorporateHospital } = useDetailsQuery(
    corporate.value!,
    {
      skip: !corporate.value,
    }
  );

  const isShowMainERHospital = isOpenedERHospital && !showReferrals;
  const acceptanceHospitalId = isShowMainERHospital
    ? hospitalId
    : corporate.value;

  const { data: acceptance } = useGetQuery(
    { hospitalId: String(acceptanceHospitalId) },
    { skip: !acceptanceHospitalId }
  );

  useEffect(() => {
    if (isOpenedERHospital) {
      if (isShowMainERHospital) {
        referralHelpers.setValue(null);
        corporateHelpers.setValue(null);
        referralMainHospitalHelpers.setValue(hospitalId);
      } else {
        corporateHelpers.setValue(null);
        referralHelpers.setValue(hospitalId);
        referralMainHospitalHelpers.setValue(null);
      }
    } else {
      setShowReferrals(true);
    }
  }, [isOpenedERHospital, isShowMainERHospital, hospitalId]);

  const hospitalsOptions = useMemo(
    () =>
      referrals?.map((item) => {
        const id = String(item.id);

        return {
          id,
          value: id,
          label: item.name ?? id,
        };
      }) ?? [],
    [referrals]
  );

  useEffect(() => {
    if (!corporate.value && corporateOptions?.length) {
      corporateHelpers.setValue(String(corporateOptions[0]?.data?.id));
      return;
    }

    if (!referral.value) {
      if (isShowMainERHospital) {
        referralHelpers.setValue(null);
        referralMainHospitalHelpers.setValue(hospitalId);
        return;
      }

      if (hospitalsOptions?.length) {
        referralMainHospitalHelpers.setValue(null);
        referralHelpers.setValue(hospitalsOptions[0].id);
      }
    }
  }, [
    referral.value,
    corporate.value,
    corporateOptions,
    hospitalsOptions,
    isOpenedERHospital,
    isShowMainERHospital,
  ]);

  const referralData = useMemo(() => {
    if (corporate.value) {
      return {
        acceptance,
        hospital: selectedCorporateHospital,
      };
    } else if (referral.value) {
      const data = referrals?.find(({ id }) => id === Number(referral.value));

      return {
        hospital: data,
      };
    }
  }, [
    referrals,
    acceptance,
    referral.value,
    corporate.value,
    selectedCorporateHospital,
  ]);

  const { data: protocol } = useOnCallProtocolQuery(String(hospitalId), {
    skip: !!referralData?.hospital || !hospitalId,
  });

  const payload = useMemo(() => {
    return {
      protocol,
      acceptance,
      setReferral,
      isERHospital,
      referralData,
      showReferrals,
      setShowReferrals,
      data: referralData?.hospital,
      mainHospital: selectedCorporateHospital ?? hospital,
      referToERProps: {
        referToEr,
        isMainHospital,
        hospitalsOptions,
        options: corporateOptions,
        hospitalName: hospital?.name,
      },
    };
  }, [
    hospital,
    protocol,
    referToEr,
    acceptance,
    setReferral,
    referralData,
    isERHospital,
    showReferrals,
    isMainHospital,
    setShowReferrals,
    corporateOptions,
    hospitalsOptions,
    referralData?.hospital,
    selectedCorporateHospital,
  ]);

  return { ...payload, requestProps: props };
};
