import { useCallback } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import * as Styles from './styles';
import { PaginationProps } from './types';

export const Pagination = ({ page, setPage, totalPage }: PaginationProps) => {
  const pageIndication = `${page + 1}/${totalPage}`;

  const toPreviousPage = useCallback(() => {
    if (page > 0) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const toNextPage = useCallback(() => {
    if (page < totalPage - 1) {
      setPage(page + 1);
    }
  }, [page, totalPage, setPage]);

  return (
    <Styles.Container>
      <Styles.Button isLeft>
        <StandardButton
          onClick={toPreviousPage}
          icon={<Icon.ChevronLeft />}
          colorType={ButtonColors.Secondary}
        />
        <p>{pageIndication}</p>
      </Styles.Button>
      <Styles.Button>
        <StandardButton
          onClick={toNextPage}
          icon={<Icon.ChevronRight />}
          colorType={ButtonColors.Secondary}
        />
        <p>{pageIndication}</p>
      </Styles.Button>
    </Styles.Container>
  );
};
