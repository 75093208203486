import { useMemo, useState } from 'react';
import {
  Icon,
  Menu,
  Utils,
  Media,
  Gallery,
  ListCard,
  FileType,
  MenuProps,
  showMessage,
  useDownloadFile,
  AvatarPlaceholderType,
} from '@gv/triage-components';

import * as Styles from './styles';
import { groupFiles } from './config';
import { FilesListProps } from './types';

const {
  Date: { formatDate },
  File: { formatBytes },
  Helpers: { textValue, joinStrings },
} = Utils;

export const FilesList = ({ data }: FilesListProps) => {
  const grouped = useMemo(() => groupFiles(data), [data]);

  const [galleryIndex, setGalleryIndex] = useState<number>();

  const supportedFiles = useMemo(
    () => data?.filter((file) => file.type !== FileType.File) ?? [],
    [data]
  );

  const galleryFiles = useMemo(() => {
    return supportedFiles.map(({ message, ...file }) => ({
      ...file,
      title: message?.author ? (
        <p className="semibold">
          {Utils.Helpers.getChatUserName(
            Utils.Helpers.parseContact(message.author) ?? {}
          )}
        </p>
      ) : undefined,
    }));
  }, [supportedFiles]);

  const { downloadFile } = useDownloadFile();

  const getMenuOptions = (media: Media) => {
    const items: MenuProps['data'] = [
      {
        text: 'Copy link to file',
        onClick: () => {
          navigator.clipboard.writeText(media.url);
          showMessage('Copied to clipboard');
        },
      },
    ];
    return items;
  };

  return (
    <>
      {grouped.map((file) => {
        if (typeof file === 'string') {
          return <Styles.Date key={file}>{file}</Styles.Date>;
        }

        const isVideo = file.type?.includes(FileType.Video);
        const isImage = file.type?.includes(FileType.Image);
        const isFile = !isVideo && !isImage;
        const author = file.message?.author;

        return (
          <ListCard
            key={file.id}
            template="1fr"
            padding="12px 16px"
            onClick={() => {
              if (isFile) {
                downloadFile(file.url, file.name);
              } else {
                const index = supportedFiles.indexOf(file);
                setGalleryIndex(index);
              }
            }}
            values={[
              <Styles.Info
                isHeader
                size={48}
                placeholder={AvatarPlaceholderType.Hospital}
                placeholderIcon={!isFile ? <Icon.FileFilled /> : <Icon.Image />}
                user={{
                  name: String(textValue(file.name)),
                  imageUrl: isFile
                    ? undefined
                    : (file.thumbnailUrl ?? file.url),
                  email: joinStrings(
                    [
                      formatBytes(file.size ?? 0),
                      formatDate(file.createdAt, 'MMM DD, YYYY'),
                      author
                        ? Utils.Helpers.getChatUserName(
                            Utils.Helpers.parseContact(author) ?? {}
                          )
                        : undefined,
                    ],
                    ' • '
                  ),
                }}
              />,
            ]}
          />
        );
      })}

      {typeof galleryIndex === 'number' && (
        <Gallery
          open
          index={galleryIndex}
          files={galleryFiles}
          className="media-gallery"
          close={() => setGalleryIndex(undefined)}
          footerButtons={(file, defaultStyle) => {
            if (!file) {
              return undefined;
            }
            return (
              <>
                <Menu
                  data={getMenuOptions(file)}
                  position={['top left', 'top center']}
                  buttonProps={{ ...defaultStyle, icon: <Icon.More /> }}
                />
              </>
            );
          }}
        />
      )}
    </>
  );
};
