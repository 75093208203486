import { useContext } from 'react';
import { Utils } from '@gv/triage-components';
import { Duration } from 'dayjs/plugin/duration';

import { AudioContext } from '..';

import * as Styles from './styles';

export const formatAudioTime = (value: number): string =>
  Utils.Date.formatDuration(value, (duration: Duration) =>
    duration.format('m:ss')
  );

const Time = (): JSX.Element | null => {
  const { state, value } = useContext(AudioContext);

  if (!value || !state) {
    return null;
  }

  const formattedTime = formatAudioTime(state.time);
  const formattedDuration = formatAudioTime(state.duration);

  return (
    <div>
      {formattedTime}/<Styles.Duration>{formattedDuration}</Styles.Duration>
    </div>
  );
};

export { Time };
