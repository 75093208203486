import dayjs, { Dayjs } from 'dayjs';
import { useState, ComponentProps } from 'react';
import {
  Badge,
  Timer,
  Utils,
  BadgeColor,
  BadgeProps,
} from '@gv/triage-components';

import { TaskClass, AppointmentCase } from 'types';

const {
  Helpers: { getPlural },
} = Utils;

const initialTick = 5000;

interface ArrivalBadgeProps extends BadgeProps {
  pastText?: string;
  taskClass: TaskClass;
  eta: Dayjs | AppointmentCase['eta'];
}

export const ArrivalBadge = ({
  eta,
  taskClass,
  pastText = 'delay',
  ...props
}: ArrivalBadgeProps) => {
  const [tick, setTick] = useState(initialTick);

  const arrivalDate = dayjs(eta);

  const getProps = (): ComponentProps<typeof Badge> => {
    const currentDate = dayjs();

    const minutesDiff = arrivalDate.diff(currentDate, 'minute');
    const absMinutesDiff = Math.abs(minutesDiff);

    let time: string;
    let toArrive: boolean;

    if (absMinutesDiff < 60) {
      time = getPlural('min', absMinutesDiff, { rightCount: true });
      toArrive = minutesDiff >= 0;
    } else {
      const daysDiff = arrivalDate.diff(currentDate, 'day');
      const absDaysDiff = Math.abs(daysDiff);

      if (absDaysDiff < 1) {
        const hoursDiff = arrivalDate.diff(currentDate, 'hour');
        const absHoursDiff = Math.abs(hoursDiff);

        time = getPlural('hour', absHoursDiff, { rightCount: true });
        toArrive = hoursDiff >= 0;
      } else {
        time = getPlural('day', absDaysDiff, { rightCount: true });
        toArrive = daysDiff >= 0;
      }
    }

    const styleType =
      minutesDiff < 0
        ? BadgeColor.Pink
        : minutesDiff < 60
          ? BadgeColor.Red
          : BadgeColor.Green;

    let text = time;

    if (toArrive) {
      if (taskClass === TaskClass.Appointment) {
        text = `In ${time}`;
      } else if (taskClass === TaskClass.Emergency) {
        text = `${time} to arrival`;
      }
    } else {
      text = `${time} ${pastText}`;
    }

    return {
      text,
      styleType,
    };
  };

  const onTick = () => {
    const minutesDiff = arrivalDate.diff(dayjs(), 'minute');
    const absMinutesDiff = Math.abs(minutesDiff);

    setTick(absMinutesDiff >= 60 ? 60000 : initialTick);
  };

  return (
    <Timer tick={tick} started={1} onTick={onTick}>
      {() => <Badge {...getProps()} {...props} />}
    </Timer>
  );
};
