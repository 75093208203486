import { Nullable } from '@gv/triage-components';

import { ExportFormValues } from 'store/api/analytics-export';

export enum AnalyticsExportStatus {
  Error = 'error',
  Ready = 'ready',
  Initial = 'initial',
  Processing = 'processing',
  FileCreated = 'fileCreated',
  DataPrepared = 'dataPrepared',
}

export interface AnalyticsExportState {
  caseId: any;
  hospitalId: any;
  file: Nullable<string>;
  isButtonHide?: boolean;
  isShowFeedback?: boolean;
  status: AnalyticsExportStatus;
  formValues: Nullable<ExportFormValues>;
}
