export const getRequiredFields = (
  initialRequired?: string[],
  calcRequired?: string[]
) => {
  const requiredFields: string[] = [];

  if (!calcRequired?.length) {
    return requiredFields;
  }
  calcRequired.forEach((item) => {
    if (initialRequired && initialRequired.includes(item)) {
      requiredFields.push(item);
    }
  });
  return requiredFields;
};

export const hasNoneOption = (name?: string) => {
  if (!name) {
    return false;
  }
  return ['medications_list'].includes(name);
};
