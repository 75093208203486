import { useMemo } from 'react';
import {
  Icon,
  Utils,
  ListCard,
  FlexLayout,
  EllipsisText,
  ButtonColors,
  ListCardStyle,
  StandardButton,
} from '@gv/triage-components';

import * as Styles from './styles';
import { BillingDetailsCardProps } from './types';

const { textValue } = Utils.Helpers;

export const BillingDetailsCard = ({ data }: BillingDetailsCardProps) => {
  const options = useMemo(
    () => [
      { title: 'Account number', value: data?.account_number },
      { title: 'Account holder', value: data?.account_holder },
    ],
    [data?.account_number, data?.account_holder]
  );

  const placeholder = (
    <Styles.Placeholder>
      <Icon.CreditCard />
      <p>Your first invoice will appear here after the first payment.</p>
      <StandardButton
        disabled //TODO: add path
        text="Add billing details"
        colorType={ButtonColors.Secondary}
      />
    </Styles.Placeholder>
  );

  const header = (
    <FlexLayout alignItems="center" justifyContent="space-between">
      <EllipsisText as="h3">Billing details</EllipsisText>
      {data && (
        <StandardButton
          disabled //TODO: add path
          transparent
          padding="0"
          text="Manage"
          icon={<Icon.Settings />}
          colorType={ButtonColors.BlackWhite}
        />
      )}
    </FlexLayout>
  );

  const main = options.map(({ title, value }) => (
    <FlexLayout flexDirection="column">
      <EllipsisText>{title}</EllipsisText>
      <EllipsisText className="semibold">{textValue(value)}</EllipsisText>
    </FlexLayout>
  ));

  return (
    <ListCard
      selected
      rowGap={16}
      template="1fr"
      odd={ListCardStyle.Default}
      values={[header, ...(data ? main : [placeholder])]}
    />
  );
};
