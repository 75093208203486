import { useContext } from 'react';
import {
  Utils,
  Dialog,
  TextColor,
  ButtonColors,
  StandardButton,
  ActionQueueType,
  ButtonStyleTypes,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { SubmitTimeoutBadge } from 'components';
import { selectHasInQueue } from 'store/api/action-queue/selectors';

import { CallContext } from '../context';

import * as Styles from './styles';
import { ConnectionLostProps } from './types';

export const ConnectionLostDialog = ({
  task,
  setOpen,
  onSubmit,
  onCallback,
  onReconnect,
  ...props
}: ConnectionLostProps) => {
  const { form } = useContext(CallContext);
  const { type, disconnected, disconnected_at, willAutosubmitAt } = task ?? {};
  const showTimer =
    disconnected &&
    disconnected_at &&
    willAutosubmitAt &&
    type === ActionQueueType.Voice;

  const hasInQueue = useAppSelector(selectHasInQueue(task));

  return (
    <Dialog
      closeOnEscape={false}
      iconColor={TextColor.Red}
      title="Connection is lost"
      icon={<Styles.CallIcon />}
      closeOnDocumentClick={false}
      text={
        <Styles.TextContainer>
          <p>
            Connection with
            <span className="semibold">
              {` ${form.values.client_name} - ${Utils.Helpers.formatPhone(
                form.values.client_phone
              )} `}
            </span>
            is lost.
            {showTimer
              ? ' If you need continue call, please callback to the client. If you need just complete case, you have time limit:'
              : ''}
          </p>

          {showTimer && (
            <>
              <SubmitTimeoutBadge
                fullWidth
                margin="8px 0"
                disconnectedAt={disconnected_at}
                willAutosubmitAt={willAutosubmitAt}
              />
              <p>Otherwise it will be submitted automatically.</p>
            </>
          )}
        </Styles.TextContainer>
      }
      buttons={(close) => (
        <>
          {onReconnect && (
            <StandardButton
              fullWidth
              text="Reconnect"
              styleType={ButtonStyleTypes.Outline}
              onClick={() => {
                close();
                setOpen(false);
                onReconnect();
              }}
            />
          )}
          {hasInQueue && (
            <StandardButton
              fullWidth
              text="Callback"
              colorType={ButtonColors.LightGreen}
              onClick={() => {
                close();
                setOpen(false);
                onCallback?.();
              }}
            />
          )}
          <StandardButton
            fullWidth
            text="Edit case"
            colorType={ButtonColors.Secondary}
            onClick={() => {
              close();
              setOpen(false);
            }}
          />
          <StandardButton
            fullWidth
            text="Submit case"
            onClick={() => {
              close();
              setOpen(false);
              onSubmit();
            }}
          />
        </>
      )}
      {...props}
    />
  );
};
