import { useMemo, useEffect, useContext } from 'react';
import Joyride, { STATUS, EVENTS, Callback } from 'react-joyride';
import {
  Utils,
  isDesktop,
  useNavigate,
  handleMutation,
} from '@gv/triage-components';

import { Routes } from 'config';
import { WalkthroughStep } from 'types/data';
import { SidebarContext } from 'context/sidebar';
import { useAppDispatch, useAppSelector } from 'store';
import { useUpdateMutation } from 'store/api/user-info';
import { selectAuthUser, selectPagePermissions } from 'store/slices/auth';
import {
  runWalkthrough,
  pauseWalkthrough,
  startWalkthrough,
  selectWalkthrough,
  completeWalkthrough,
  nextWalkthroughStep,
} from 'store/slices/walkthrough';

import { target } from './config';
import { Tooltip } from './tooltip';
import { getSteps } from './helpers';

const { waitForElementToExist } = Utils.Helpers;

const {
  Hospitals: {
    Details: { HomeInfo: Hospital },
  },
} = Routes;

export const Walkthrough = () => {
  const desktop = isDesktop();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthUser);
  const walkthrough = useAppSelector(selectWalkthrough);
  const permissions = useAppSelector(selectPagePermissions);

  const { isOpened: isSidebarOpened, setOpened: setSidebarOpened } =
    useContext(SidebarContext);

  const [updateUser, mutation] = useUpdateMutation();
  handleMutation(mutation);

  useEffect(() => {
    if (!user?.walkthrough_completed) {
      dispatch(startWalkthrough());
    }
  }, [user?.walkthrough_completed]);

  useEffect(() => {
    if (
      walkthrough?.completed &&
      user?.walkthrough_completed === false &&
      user?.name
    ) {
      updateUser({
        name: user.name,
        walkthrough_completed: true,
      });
    }
  }, [walkthrough?.completed, user?.walkthrough_completed, user?.name]);

  const onChange: Callback = async (state) => {
    const { type, index, status } = state;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      dispatch(completeWalkthrough());
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + 1;

      if (nextStepIndex === WalkthroughStep.Acceptance) {
        dispatch(pauseWalkthrough());

        if (!location.pathname.includes(Hospital)) {
          navigate(Hospital);
        }

        setSidebarOpened(false);
        await waitForElementToExist(target[WalkthroughStep.Acceptance]);

        dispatch(nextWalkthroughStep());
        dispatch(runWalkthrough());
      } else if (
        !desktop &&
        !isSidebarOpened &&
        nextStepIndex !== WalkthroughStep.Intro
      ) {
        dispatch(pauseWalkthrough());

        setSidebarOpened(true);

        setTimeout(() => {
          dispatch(nextWalkthroughStep());
          dispatch(runWalkthrough());
        }, 300);
      } else {
        dispatch(nextWalkthroughStep());
      }
    }
  };

  const steps = useMemo(
    () => getSteps(permissions, desktop),
    [
      desktop,
      permissions.cases,
      permissions.hospital,
      permissions.calendar,
      permissions.analytics,
      permissions.actionItems,
    ]
  );

  return (
    <Joyride
      continuous
      steps={steps}
      disableScrolling
      disableCloseOnEsc
      disableOverlayClose
      callback={onChange}
      spotlightPadding={0}
      run={walkthrough?.run}
      tooltipComponent={Tooltip}
      stepIndex={walkthrough?.stepIndex}
      styles={{
        options: {
          zIndex: 10000,
        },
        spotlight: {
          borderRadius: 0,
        },
      }}
      floaterProps={{
        styles: {
          arrow: {
            length: 8,
            spread: 16,
          },
        },
      }}
    />
  );
};
