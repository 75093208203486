import styled, { css } from 'styled-components';
import { ButtonTabs } from '@gv/triage-components';

export const Tabs = styled(ButtonTabs)`
  gap: 16px;
`;
export const FileCard = css`
  padding: 18px;
  min-height: 120px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.grey.tertiary.standard};
`;
