import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from './index';

const Pause = () => {
  const { state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      transparent
      padding="0"
      icon={<Icon.Stop />}
      disabled={state?.paused}
      onClick={controls?.pause}
      colorType={ButtonColors.Transparent}
    />
  );
};

export { Pause };
