import { Utils, StandardButton, ButtonStyleTypes } from '@gv/triage-components';

import { PastePhoneButtonProps } from './types';

export const PastePhoneButton = ({ onPaste }: PastePhoneButtonProps) => {
  const pasteNumber = async () => {
    try {
      const clipboard = await navigator.clipboard?.readText();
      const phone = clipboard?.replace(/\D/g, '');
      const transformedPhone = Utils.Helpers.transformPhone(phone);

      if (
        transformedPhone &&
        Utils.Validation.isValidPhoneNumber(transformedPhone)
      ) {
        onPaste(transformedPhone);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StandardButton
      transparent
      text="Paste number"
      onClick={pasteNumber}
      styleType={ButtonStyleTypes.Ghost}
    />
  );
};
