import {
  Row,
  Col,
  FlexLayout,
  InputField,
  SelectField,
} from '@gv/triage-components';

import { reviewOptions, technicalIssues } from './config';

export const InternalReportField = () => (
  <FlexLayout fullWidth flexDirection="column">
    <Row>
      <Col col={6}>
        <SelectField
          onlyValue
          isClearable
          clearToNull
          name="technical_issue"
          options={technicalIssues}
          placeholder="Selection option"
          label="Did you experience any technical issue:"
        />
      </Col>
      <Col col={6}>
        <SelectField
          onlyValue
          isClearable
          clearToNull
          label="Please review:"
          options={reviewOptions}
          name="case_report_review"
          placeholder="Selection option"
        />
      </Col>
    </Row>

    <InputField
      label="Notes"
      name="internal_notes"
      placeholder="Add note"
      type="textarea-autosize"
    />
  </FlexLayout>
);
