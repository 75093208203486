import { useState, Dispatch, SetStateAction } from 'react';

export interface UseListCheckboxesReturn {
  selectedIds: Set<number>;
  onSelect: (id: number) => void;
  setSelectedIds: Dispatch<SetStateAction<Set<number>>>;
}

export const useListCheckboxes = (): UseListCheckboxesReturn => {
  const [selectedIds, setSelectedIds] = useState(new Set<number>());

  const onSelect = (id: number) => {
    const newSet = new Set(selectedIds);

    if (selectedIds.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }

    setSelectedIds(newSet);
  };

  return { onSelect, selectedIds, setSelectedIds };
};
