import * as Yup from 'yup';

import { ReactComponent as ExcelFile } from 'assets/images/excel-file.svg';
import { AnalyticsExportStatus } from 'store/slices/analytics-export/types';
import { ReactComponent as ExcelFileRed } from 'assets/images/excel-file-red.svg';

const commonExportReportCardConfig = {
  icon: <ExcelFile />,
  title: 'Report is on the processing',
  subtitle:
    'You will be able download file by click after the report will be ready',
};

export const exportReportCard = {
  [AnalyticsExportStatus.Processing]: {
    ...commonExportReportCardConfig,
    progress: '30%',
  },
  [AnalyticsExportStatus.FileCreated]: {
    ...commonExportReportCardConfig,
    progress: '90%',
  },
  [AnalyticsExportStatus.DataPrepared]: {
    ...commonExportReportCardConfig,
    progress: '60%',
  },
  [AnalyticsExportStatus.Ready]: {
    progress: '100%',
    icon: <ExcelFile />,
    title: 'You report is ready!',
    subtitle: 'Please click download button to export CSV file ',
  },
  [AnalyticsExportStatus.Error]: {
    progress: '30%',
    icon: <ExcelFileRed />,
    title: 'Ooops... something went wrong',
    subtitle: 'There are unexpected server error, please restart you process',
  },
};

export const validationSchema = Yup.object().shape({
  end_date: Yup.date().required('To is a required field'),
  start_date: Yup.date().required('From is a required field'),
});
