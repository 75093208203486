import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';

import { CaseLog, CaseLogsResponse } from './types';

const caseLogsAdapter = createEntityAdapter<CaseLog, number>({
  selectId: (item) => item.caseId,
});

export const caseLogsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'caseLogsApi',
  endpoints: (build) => ({
    list: build.query<CaseLog[], number>({
      transformResponse: (response: CaseLogsResponse) => {
        const result = response.data;
        return result;
      },
      query: (id) => {
        const url = generateUrl(URL.CASE_LOGS, { id });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const caseLogsSlice = createSlice({
  reducers: {},
  name: 'caseLogs',
  initialState: caseLogsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      caseLogsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        caseLogsAdapter.setAll(state, payload);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      caseLogsAdapter.removeAll(state);
    });
  },
});

export const { useListQuery } = caseLogsApi;
