import { Utils } from '@gv/triage-components';

import { URL } from '../constants';
import { apiInstance } from '../instance';

export const getUnreadCount = (hospitalId: number) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.UNREAD_ACTION_ITEMS_COUNT, {
      hospital_id: hospitalId,
    })
  );
