import { useMemo } from 'react';
import { mapQuery, OfficeChatType } from '@gv/triage-components';

import { ChatSection } from 'types';
import { useSectionsListQuery } from 'store/api/gv-chats';

export const getDefaultFolder = (
  data?: ChatSection[],
  type = OfficeChatType.Chat
) => {
  if (!data?.length) {
    return undefined;
  }
  const section = type === OfficeChatType.Chat ? data[0] : data[1];
  const folder = section?.folders?.find((item) => !!item.isSystem);
  return folder;
};

interface DefaulFolderProps {
  skip?: boolean;
  type?: OfficeChatType;
}

export const useDefaultFolder = (props?: DefaulFolderProps) => {
  const { skip, type = OfficeChatType.Chat } = props || {};
  const { data, ...rest } = useSectionsListQuery({}, { skip });

  const defaultFolderId = useMemo(
    () => getDefaultFolder(data, type)?.id,
    [data, type]
  );

  return { ...mapQuery(rest), defaultFolderId };
};
