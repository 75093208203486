import { Message } from 'ably';
import debounce from 'lodash.debounce';
import { AblyChannelName } from '@gv/triage-components';
import { useRef, useMemo, useEffect, useCallback } from 'react';

import { useAppSelector } from 'store/hooks';
import { selectAuthUser } from 'store/slices/auth';
import { AblyChatEventExcludeQueue } from 'types/data';
import { useLazyDetailsQuery } from 'store/api/gv-chats';
import { ChatDetailsRequest } from 'store/api/gv-chats/types';
import { useOnceLoader, useAblyChannel, useAblyEventChannel } from 'hooks';

export const useChatDetailsEvent = (
  props: ChatDetailsRequest,
  skip?: boolean
) => {
  const { id: chatId } = props || {};

  const authUser = useAppSelector(selectAuthUser);
  const [trigger, data] = useLazyDetailsQuery();
  const { isError, isFetching, isUninitialized } = data;

  const { isLoading } = useOnceLoader({
    skip,
    isError,
    isFetching,
    isUninitialized,
    resetTrigger: chatId,
  });

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    if (!skip) {
      lastRequest.current?.abort();
      const req = trigger(props);
      lastRequest.current = req;
    }
  }, [props, skip]);

  useEffect(refetch, [JSON.stringify(props), skip]);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  useEffect(() => {
    return () => debounceRefetch.cancel();
  }, [debounceRefetch]);

  const onEvent = useCallback(
    (msg: Message) => {
      const { profileId, profileIds, id: msgChatId } = msg.data;
      if (
        msgChatId === Number(chatId) &&
        (profileId !== authUser?.id || Array.isArray(profileIds))
      ) {
        debounceRefetch();
      }
    },
    [debounceRefetch, chatId, authUser?.id]
  );

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(AblyChatEventExcludeQueue, onEvent, channel);

  return { ...data, refetch, isFetching: isLoading };
};
