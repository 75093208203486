import { Utils } from '@gv/triage-components';

import * as Styles from './styles';
import { SummaryOptionProps } from './types';

const { formatPrice } = Utils.Helpers;

export const SummaryOption = ({
  title,
  value,
  isBold,
  isSummary,
}: SummaryOptionProps) => {
  const isValueNumber = typeof value === 'number';
  return (
    <>
      {isSummary && <Styles.Line />}
      <Styles.ValuesWrapper isBold={isBold} isSummary={isSummary}>
        <p>{title}</p>
        <Styles.Dots />
        <p className={isValueNumber ? undefined : 'semibold'}>
          {isValueNumber ? formatPrice(value, '$0.00') : value}
        </p>
      </Styles.ValuesWrapper>
    </>
  );
};
