import {
  isAnyOf,
  createSlice,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { saveWalkthrough } from 'utils/storage';

const initialState = {
  run: false,
  stepIndex: 0,
  completed: false,
};

const walkthroughSlice = createSlice({
  initialState,
  name: 'walkthrough',
  reducers: {
    hydrateWalkthrough: (state, action) => action.payload,
    runWalkthrough: (state) => {
      state.run = true;
    },
    startWalkthrough: (state) => {
      state.run = true;
    },
    pauseWalkthrough: (state) => {
      state.run = false;
    },
    completeWalkthrough: () => ({
      ...initialState,
      completed: true,
    }),
    nextWalkthroughStep: (state) => {
      state.stepIndex = state.stepIndex + 1;
    },
  },
});

const {
  reducer: walkthroughReducer,
  actions: {
    runWalkthrough,
    pauseWalkthrough,
    startWalkthrough,
    hydrateWalkthrough,
    nextWalkthroughStep,
    completeWalkthrough,
  },
} = walkthroughSlice;

const selectWalkthrough = (store: RootState) => store?.walkthrough;

const walkthroughListenerMiddleware = createListenerMiddleware();
walkthroughListenerMiddleware.startListening({
  effect: (action, listenerApi) =>
    saveWalkthrough(
      JSON.stringify((listenerApi.getState() as RootState)?.walkthrough)
    ),
  matcher: isAnyOf(
    runWalkthrough,
    startWalkthrough,
    pauseWalkthrough,
    nextWalkthroughStep,
    completeWalkthrough
  ),
});

export {
  runWalkthrough,
  pauseWalkthrough,
  startWalkthrough,
  selectWalkthrough,
  walkthroughReducer,
  hydrateWalkthrough,
  nextWalkthroughStep,
  completeWalkthrough,
  walkthroughListenerMiddleware,
};
