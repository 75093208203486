import { Fragment } from 'react';
import { UserRoleType } from '@gv/triage-components';

import { MemberType } from 'types';
import { useAppSelector } from 'store';
import { selectUserRole } from 'store/slices/auth';
import { useListQuery } from 'store/api/case-greeting';

import { useFormEntities } from '../use-form-entities';

const getMemberType = (role?: UserRoleType): MemberType | undefined => {
  if (!role) {
    return undefined;
  }
  switch (role) {
    case UserRoleType.CSR:
    case UserRoleType.CSR2:
      return MemberType.CSR;
    case UserRoleType.CVT:
      return MemberType.CVT;
    case UserRoleType.TeamCoach:
      return MemberType.TeamCoach;
    default:
      return MemberType.TeamCoach;
  }
};

export const Greetings = () => {
  const { hospitalId } = useFormEntities();
  const role = useAppSelector(selectUserRole);

  const memberType = getMemberType(role);

  const { data = [] } = useListQuery(
    { id: String(hospitalId), teamMember: memberType },
    { skip: hospitalId === undefined || memberType === undefined }
  );

  if (!role || !hospitalId || !data.length) {
    return null;
  }

  return (
    <>
      {data.map(({ id, greeting }) => (
        <Fragment key={id}>{greeting}</Fragment>
      ))}
    </>
  );
};
