import { useEffect } from 'react';

import { useDetailsQuery } from 'store/api/gv-contacts';

import { useChatParams } from './useChatParams';
import { useChatNavigate } from './useChatNavigate';

export const useContactChat = () => {
  const navigate = useChatNavigate();
  const { chatId, contactId } = useChatParams();

  const { data } = useDetailsQuery(
    { id: contactId! },
    { skip: !contactId || !!chatId }
  );

  useEffect(() => {
    if (
      !chatId &&
      contactId &&
      data?.chatId &&
      contactId === String(data?.profile?.id) &&
      !data?.profile.clientId // we should create new chat for pet owner every time
    ) {
      navigate(data.chatId);
    }
  }, [chatId, contactId, data]);
};
