import debounce from 'lodash.debounce';
import { AblyChannelName } from '@gv/triage-components';
import { useRef, useMemo, useEffect, useCallback } from 'react';

import { useLazyListQuery } from 'store/api/gv-chats';
import { AblyChatEventExcludeQueue } from 'types/data';
import { ChatsListRequest } from 'store/api/gv-chats/types';
import { useOnceLoader, useAblyChannel, useAblyEventChannel } from 'hooks';

export const useChatListEvents = (props: ChatsListRequest, skip?: boolean) => {
  const [trigger, data] = useLazyListQuery();

  const { isError, isFetching, isUninitialized } = data;
  const { isLoading } = useOnceLoader({
    skip,
    isError,
    isFetching,
    isUninitialized,
  });

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    if (!skip) {
      lastRequest.current?.abort();
      const req = trigger(props);
      lastRequest.current = req;
    }
  }, [props, skip]);

  useEffect(refetch, [JSON.stringify(props), skip]);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  useEffect(() => {
    return () => debounceRefetch.cancel();
  }, [debounceRefetch]);

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(AblyChatEventExcludeQueue, debounceRefetch, channel);

  return { ...data, refetch, isFetching: isLoading };
};
