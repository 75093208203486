import { Message } from 'ably';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAlert,
  useNavigate,
  AblyChatEvent,
  AblyChannelName,
} from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { joinRoutes } from 'utils/helpers';
import { selectAuthForGVTalk } from 'store/slices/auth';
import { useTalkHome, useAblyChannel, useAblyEventChannel } from 'hooks';

const { Home } = Routes.Office.Inbox;

export const useChatDeleteEvent = () => {
  const home = useTalkHome();
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { showErrorAlert } = useAlert();
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);

  const onEvent = useCallback(
    (event: Message) => {
      const { id, profileId } = event?.data;
      if (Number(chatId) === id && gvTalkAuth.authProfileId !== profileId) {
        navigate(joinRoutes([home, Home]));
        showErrorAlert('This chat has been deleted by the owner');
      }
    },
    [home, chatId, navigate, gvTalkAuth.authProfileId]
  );

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(AblyChatEvent.ChatDeleted, onEvent, channel);
};
