import { Form as FormikForm } from 'formik';
import { Styles } from '@gv/triage-components';
import styled, { css } from 'styled-components';

export const Inner = styled.div<{ $asPage?: boolean }>`
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ $asPage }) => css`
    padding: 16px ${$asPage ? 16 : 0}px;
  `}
`;

export const Form = styled(FormikForm)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

export const Label = styled.label`
  ${Styles.Font.bodySemibold};
  gap: 4px;
  display: flex;
  color: ${({ theme }) => theme.config.input.label.default};
`;

export const InputContainer = styled.div<{ $error?: boolean }>`
  height: 40px;
  padding: 8px 16px;
  ${({ theme, $error }) => css`
    color: ${theme.config.input.text};
    background: ${theme.config.input.bg.default};
    border-radius: ${theme.borderRadius};
    border: 1px solid
      ${$error
        ? theme.config.input.border.error
        : theme.config.input.border.default};

    &::placeholder {
      color: ${theme.config.input.placeholder};
    }
  `}
`;
