import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  RingGroupsData,
  RingGroupDetails,
  CreateRingGroups,
  UpdateRingGroups,
  RingGroupsListRequest,
  RingGroupsListResponse,
  RingGroupDeitailsResponse,
} from './types';

const tagTypes = ['RingGroup'];

export const ringGroupsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'ringGroupsApi',
  endpoints: (build) => ({
    delete: build.mutation<void, number>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.GET_RING_GROUP_DETAILS, { id }),
      }),
    }),

    create: build.mutation<void, CreateRingGroups>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_RING_GROUPS_LIST,
      }),
    }),

    details: build.query<RingGroupDetails, number>({
      providesTags: tagTypes,
      transformResponse: (response: RingGroupDeitailsResponse) => response.data,
      query: (id) => ({
        method: 'get',
        url: generateUrl(URL.GET_RING_GROUP_DETAILS, { id }),
      }),
    }),

    update: build.mutation<void, UpdateRingGroups>({
      invalidatesTags: tagTypes,
      query: ({ id, name, waiting_record_id }) => ({
        method: 'patch',
        url: generateUrl(URL.GET_RING_GROUP_DETAILS, { id }),
        data: {
          name,
          waiting_record_id,
        },
      }),
    }),

    list: build.query<RingGroupsData, RingGroupsListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: RingGroupsListResponse) => response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_RING_GROUPS_LIST, {
          limit,
          search,
          offset: Utils.Helpers.offset(page, limit),
        });

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = ringGroupsApi;
