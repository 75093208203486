import { URL } from 'api/constants';
import { PaymentLink } from 'types';
import { apiInstance } from 'api/instance';
import { generateUrl } from 'utils/helpers';

const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getInvoiceDetailsWithRetries = (
  invoiceId?: number,
  data?: Record<string, any>,
  retries = MAX_RETRIES
): Promise<PaymentLink | null> =>
  new Promise(async (resolve, reject) => {
    const retryRequest = async (id: number) => {
      if (retries === 0) {
        return resolve(null);
      }

      const CURRENT_DELAY =
        (RETRY_DELAY * MAX_RETRIES - RETRY_DELAY * retries) * 3;
      await delay(CURRENT_DELAY);

      getInvoiceDetailsWithRetries(id, data, retries - 1)
        .then(resolve)
        .catch(reject);
    };

    apiInstance
      .get(generateUrl(URL.GET_INVOICE_DETAILS, { id: invoiceId }))
      .then(async (response) => {
        if (response?.data?.url === null) {
          retryRequest(response.data.id);
        } else {
          resolve(response.data);
        }
      })
      .catch(retryRequest);
  });
