import { FlexLayout } from '@gv/triage-components';

import { AudioPlayer } from '.';

export const Player = () => (
  <AudioPlayer.PlayerContainer>
    <FlexLayout justifyContent="space-between">
      <FlexLayout gap={16}>
        <AudioPlayer.RecordPlay />
        <AudioPlayer.Stop />
      </FlexLayout>
      <AudioPlayer.Speed />
    </FlexLayout>
    <FlexLayout gap={8} flexDirection="column">
      <AudioPlayer.ProgressBar />
      <AudioPlayer.Time />
    </FlexLayout>
  </AudioPlayer.PlayerContainer>
);
