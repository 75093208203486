import { URL } from '../constants';
import { apiInstance } from '../instance';

export const getAuthEmail = () => apiInstance.get(URL.GOOGLE_AUTH_TOKEN);

export const getAuthCalendarWatch = () => apiInstance.get(URL.CALENDAR_WATCH);

export const genrateGoogleAuthToken = (
  access_token: string,
  refresh_token: string,
  expire_at: string,
  auth_email: string
) => {
  return apiInstance.post(URL.GOOGLE_AUTH_TOKEN, {
    expire_at,
    auth_email,
    access_token,
    refresh_token,
  });
};
