import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 4px 16px;
  cursor: pointer;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.config.text.white};
    background-color: ${theme.colors.blue.primary.action};
  `}
`;
