import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { CorporateGroup, CorporateGroupType } from 'types';

import { groupData } from './helpers';
import {
  GetCorporateGroup,
  CorporateGroupRequest,
  CorporateMainHospital,
  GetCorporateGroupResponse,
  EditCorporateGroupRequest,
  BaseCorporateGroupRequest,
  CorporateGroupListResponse,
  CorporateGroupResponseData,
  CreateCorporateGroupRequest,
  CorporateMainHospitalResponse,
} from './types';

const tagTypes = ['Corporate'];

export const corporateGroupApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'corporateGroupApi',
  endpoints: (build) => ({
    delete: build.mutation<void, GetCorporateGroup>({
      invalidatesTags: tagTypes,
      query: ({ groupId, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.GET_CORPORATE_INFO, { groupId, hospitalId }),
      }),
    }),

    create: build.mutation<void, CreateCorporateGroupRequest>({
      invalidatesTags: tagTypes,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.GET_CORPORATE_LIST, { hospitalId }),
      }),
    }),

    update: build.mutation<void, EditCorporateGroupRequest>({
      invalidatesTags: tagTypes,
      query: ({ groupId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.GET_CORPORATE_INFO, { groupId, hospitalId }),
      }),
    }),

    get: build.query<CorporateGroup, GetCorporateGroup>({
      providesTags: tagTypes,
      transformResponse: (response: GetCorporateGroupResponse) => response.data,
      query: ({ groupId, hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_CORPORATE_INFO, { groupId, hospitalId }),
      }),
    }),

    getMainHospital: build.query<
      CorporateMainHospital,
      BaseCorporateGroupRequest
    >({
      transformResponse: (response: CorporateMainHospitalResponse) =>
        response.data,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.GET_CORPORATE_MAIN_HOSPITAL, { hospitalId }),
      }),
    }),

    list: build.query<CorporateGroupResponseData, CorporateGroupRequest>({
      providesTags: tagTypes,
      transformResponse: (response: CorporateGroupListResponse) => ({
        raw: response.data,
        grouped: groupData(response.data),
      }),
      query: ({ type, hospitalId }) => {
        const params = {
          type: type === CorporateGroupType.All ? null : type,
        };

        const url = Utils.Query.addParamsToUrl(
          generateUrl(URL.GET_CORPORATE_LIST, { hospitalId }),
          params
        );

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
  useGetMainHospitalQuery,
} = corporateGroupApi;
