import { Config } from 'config';
import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';
import { selectCaseDetails } from 'store/api/cases';
import { selectTaskByCaseId } from 'store/api/action-queue/selectors';

export const useCaseEditAccess = (
  caseId?: number | string
): {
  canEditCase?: boolean;
  hasEditAccess?: boolean;
  hasAddendAccess?: boolean;
  caseNotCompleted?: boolean;
} => {
  const authUser = useAppSelector(selectAuthUser);
  const taskInQueue = useAppSelector(selectTaskByCaseId(caseId));
  const currentCase = useAppSelector(selectCaseDetails(String(caseId)));

  const canEditCase = !taskInQueue;
  const caseNotCompleted = !currentCase?.is_submitted;
  const hasAddendAccess =
    Config.portalType.isTeam ||
    authUser?.hospital_id === currentCase?.hospital_id;

  return {
    canEditCase,
    hasAddendAccess,
    caseNotCompleted,
    hasEditAccess: canEditCase && caseNotCompleted && hasAddendAccess,
  };
};
