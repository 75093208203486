import { useCallback } from 'react';
import {
  Client,
  Messages,
  useAlert,
  handleMutation,
} from '@gv/triage-components';

import { api } from 'api';
import { useAppDispatch } from 'store';
import { HospitalTaskClient } from 'types';
import { sendSMS } from 'store/api/action-queue/thunks';
import { usePaymentLinkMutation } from 'store/api/hospital-pricing';
import { PaymentLinkRequest } from 'store/api/hospital-pricing/types';

interface CreatePaymentLinkProps {
  messageTemplate: string;
  payload: PaymentLinkRequest;
  client: Client | HospitalTaskClient;
}

export const useCreatePaymentLink = () => {
  const dispatch = useAppDispatch();
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const [generatePaymentLink, paymentMutation] = usePaymentLinkMutation();

  handleMutation(paymentMutation);

  const createPaymentLink = useCallback(
    async ({ client, payload, messageTemplate }: CreatePaymentLinkProps) => {
      const clientPhone = client?.phone;
      const clientPhones = client?.phones;
      if (!clientPhone || !clientPhones?.length) {
        return showErrorAlert(Messages.ACTION_UNAVAILABLE_CLIENT_NO_PHONES);
      }
      try {
        let paymentLink = await generatePaymentLink(payload).unwrap();
        const paymentError = () =>
          showErrorAlert('The payment link was not created, try again');

        if (!paymentLink?.url) {
          const retryRequest = await api.payments.getInvoiceDetailsWithRetries(
            paymentLink.id,
            payload
          );
          if (!retryRequest) {
            return paymentError();
          }
          paymentLink = retryRequest;
        }

        if (!paymentLink?.url) {
          return paymentError();
        }

        const { failed, successful } = await dispatch(
          sendSMS({
            phones: clientPhones || [clientPhone],
            message: messageTemplate.replace('{paymentLink}', paymentLink.url),
          })
        ).unwrap();
        if (failed) {
          showErrorAlert(`Payment link failed to sent to:\n${failed}`);
        }
        if (successful) {
          showSuccessAlert('Payment link successfully sent');
        }
        return paymentLink;
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  return createPaymentLink;
};
