import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';

import {
  Chat,
  ChatsData,
  ChatsRequest,
  ChatsResponse,
  ChatPDFResponse,
} from './types';

const chatsAdapter = createEntityAdapter<Chat, number>({
  selectId: (item) => item.id,
});

export const chatsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'chatsApi',
  endpoints: (build) => ({
    html: build.query<string, string>({
      query: (id) => {
        return {
          method: 'get',
          url: URL.GET_CHAT_HTML.replace(':id', id),
        };
      },
    }),

    pdf: build.query<string, string>({
      transformResponse: (response: ChatPDFResponse) => {
        return response.data;
      },
      query: (id) => {
        return {
          method: 'get',
          url: URL.GET_CHAT_PDF.replace(':id', id),
        };
      },
    }),

    list: build.query<ChatsData, ChatsRequest>({
      transformResponse: (response: ChatsResponse) => {
        const result = response.data;
        return result;
      },
      query: ({ page, limit = 20, ...request }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...request,
        };
        const url = Utils.Query.addParamsToUrl(URL.CHATS, searchParams);
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const chatsSlice = createSlice({
  reducers: {},
  name: 'chats',
  initialState: chatsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      chatsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        chatsAdapter.setAll(state, payload.chats);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      chatsAdapter.removeAll(state);
    });
  },
});

export const { usePdfQuery, useHtmlQuery, useListQuery } = chatsApi;
