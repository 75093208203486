import { FormikValues } from 'formik';
import { CorporateType, ActionQueueType } from '@gv/triage-components';

import { useAppSelector } from 'store';
import { CorporateGroupType } from 'types';
import { useDetailsQuery } from 'store/api/hospitals';
import { selectCurrentTask } from 'store/api/action-queue/selectors';
import { getCallOutcomePayload, getSelectedCallOutcome } from 'utils/helpers';
import { useListQuery as useCorporateGroupListQuery } from 'store/api/corporate-group';

export const useShowProtocols = (formValues: FormikValues) => {
  const { hospital_id, callOutcome } = formValues;
  const currentTask = useAppSelector(selectCurrentTask);

  const hospitalId = String(hospital_id);
  const queryProps = { skip: !hospital_id };

  const { data: hospital } = useDetailsQuery(hospitalId, queryProps);

  const { data: corporateGroup } = useCorporateGroupListQuery(
    { hospitalId, type: CorporateGroupType.All },
    queryProps
  );

  const isMainHospital = hospital?.corporate_type === CorporateType.Main;
  const selectedCallOutcome = getSelectedCallOutcome(callOutcome);
  const selectedCorporateId =
    getCallOutcomePayload(callOutcome)?.corporate_hospital_id;
  const isTaskVoiceType = currentTask?.type === ActionQueueType.Voice;

  return (
    isTaskVoiceType &&
    !(
      isMainHospital &&
      !selectedCorporateId &&
      selectedCallOutcome?.subType &&
      corporateGroup?.grouped?.[selectedCallOutcome.subType]
    )
  );
};
