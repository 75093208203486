import * as Yup from 'yup';
import { Nullable } from '@gv/triage-components';

import { AssignValues } from 'types';

export const validationSchema = Yup.object().shape({
  assignee: Yup.object().nullable(),
});

export const getInitialValues = (assignValues: Nullable<AssignValues>) => ({
  assignee: assignValues?.selectedAssignee
    ? {
        label: assignValues.selectedAssignee.name || '',
        value: assignValues.selectedAssignee.id.toString(),
      }
    : null,
});
