import { Acceptance } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';

import { apiQuery } from '../../query';

import { Response, GetRequest, CreateOrUpdateRequest } from './types';

const tagTypes = ['Acceptance'];

export const acceptanceApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'acceptanceApi',
  endpoints: (build) => ({
    set: build.mutation<Acceptance, CreateOrUpdateRequest>({
      invalidatesTags: tagTypes,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: URL.ACCEPTANCE.replace('{hospitalId}', hospitalId),
      }),
    }),
    get: build.query<Acceptance | undefined, GetRequest>({
      providesTags: tagTypes,
      transformResponse: ({ data }: Response) =>
        Object.keys(data).length > 0 ? data : undefined,
      query: ({ caseId, hospitalId }) => ({
        method: 'get',
        url: hospitalId
          ? URL.ACCEPTANCE.replace('{hospitalId}', hospitalId)
          : caseId
            ? URL.CASE_ACCEPTANCE.replace('{caseId}', caseId)
            : '',
      }),
    }),
  }),
});

export const { useGetQuery, useSetMutation } = acceptanceApi;
export { tagTypes as acceptanceTags };
