import dayjs from 'dayjs';
import { useContext } from 'react';
import {
  Icon,
  Badge,
  Timer,
  isMobile,
  BadgeColor,
} from '@gv/triage-components';

import { MediaContext } from 'context/video-comm';

export const TimerBadge = () => {
  const mobile = isMobile();

  const { expiredAt } = useContext(MediaContext);

  if (!expiredAt) {
    return <div />;
  }

  return (
    <Timer onlySeconds started={1} finished={expiredAt}>
      {() => {
        let text = '';
        const diff = dayjs(expiredAt).diff(dayjs(), 'second');
        if (diff <= 0) {
          text = 'Time passed';
        } else {
          text = mobile ? '' : 'Time left: ';
          const diffAbsolute = Math.max(0, Math.round(diff));
          const seconds = diffAbsolute % 60;
          const minutes = Math.floor(diffAbsolute / 60);

          text += `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }

        return (
          <Badge
            text={text}
            icon={<Icon.Clock />}
            styleType={BadgeColor.Blue}
          />
        );
      }}
    </Timer>
  );
};
