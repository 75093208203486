import { useMemo } from 'react';

import { useListQuery as useServicesListQuery } from 'store/api/er-services';
import { useFormEntities, CaseFormTextOutProps } from 'components/cases/form';

export const useReferToPPH = ({
  setService,
}: Pick<CaseFormTextOutProps, 'setService'>) => {
  const { hospitalId } = useFormEntities();
  const { data: services = [], ...props } = useServicesListQuery(
    String(hospitalId),
    { skip: !hospitalId }
  );

  const servicesOptions = useMemo(
    () =>
      services?.map((item) => ({
        text: item.name,
        value: String(item.id),
      })),
    [services]
  );

  const toxicityProps = useMemo(
    () => ({
      toxicityProps: {
        services,
        setService,
        servicesOptions,
      },
    }),
    [services, setService, servicesOptions]
  );

  return { ...toxicityProps, requestProps: props };
};
