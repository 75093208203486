import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { CaseGreeting } from 'types';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  GreetingRequest,
  ListRequestQuery,
  GreetingResponse,
  DetailsRequestQuery,
  GreetingDetailsResponse,
} from './types';

const greetingAdapter = createEntityAdapter<CaseGreeting, string>({
  selectId: (item) => item.id,
});

const tagType = 'Greeting';

export const greetingApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'greetingApi',
  endpoints: (build) => ({
    create: build.mutation<CaseGreeting, GreetingRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: GreetingDetailsResponse) => response.data,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: `${URL.CASE_GREETING.replace('{hospitalId}', String(id))}`,
      }),
    }),
    delete: build.mutation<CaseGreeting, DetailsRequestQuery>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: GreetingDetailsResponse) => response.data,
      query: ({ id, greetingId }) => ({
        method: 'delete',
        url: `${URL.CASE_GREETING.replace(
          '{hospitalId}',
          `${id}`
        )}/${greetingId}`,
      }),
    }),
    list: build.query<CaseGreeting[], ListRequestQuery>({
      providesTags: providesList(tagType),
      transformResponse: (response: GreetingResponse) => response.data,
      query: ({ id, teamMember, searchString }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(
          URL.CASE_GREETING.replace('{hospitalId}', id),
          { teamMember, searchString }
        ),
      }),
    }),
    get: build.query<CaseGreeting, DetailsRequestQuery>({
      transformResponse: (response: GreetingDetailsResponse) => response.data,
      providesTags: (result, error, { greetingId }) =>
        cacheByIdArg(tagType)(result, error, greetingId),
      query: ({ id, greetingId }) => ({
        method: 'get',
        url: `${URL.CASE_GREETING.replace(
          '{hospitalId}',
          `${id}`
        )}/${greetingId}`,
      }),
    }),
    update: build.mutation<CaseGreeting, GreetingRequest>({
      transformResponse: (response: GreetingDetailsResponse) => response.data,
      invalidatesTags: (result, error, { greetingId }) =>
        cacheByIdArg(tagType)(result, error, greetingId),
      query: ({ id, greetingId, ...data }) => ({
        data,
        method: 'patch',
        url: `${URL.CASE_GREETING.replace(
          '{hospitalId}',
          `${id}`
        )}/${greetingId}`,
      }),
    }),
  }),
});

export const greetingSlice = createSlice({
  reducers: {},
  name: 'greeting',
  initialState: greetingAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      greetingApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        greetingAdapter.setAll(state, payload);
      }
    );
    builder.addMatcher(
      greetingApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        greetingAdapter.setOne(state, payload);
      }
    );
    builder.addMatcher(
      greetingApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        greetingAdapter.addOne(state, payload);
      }
    );
    builder.addMatcher(
      greetingApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        greetingAdapter.updateOne(state, {
          changes: payload,
          id: greetingAdapter.selectId(payload),
        });
      }
    );
    builder.addMatcher(
      greetingApi.endpoints.delete.matchFulfilled,
      (state, { payload }) => {
        greetingAdapter.removeOne(state, payload.id);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      greetingAdapter.removeAll(state);
    });
  },
});

export const {
  useGetQuery,
  useListQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
} = greetingApi;
