import { useContext } from 'react';
import { VideoCallAvatar } from '@gv/triage-components';

import { convertAudio } from 'utils/helpers';
import { MediaContext, AvatarActionContext } from 'context/video-comm';

import * as Styles from './styles';
import { SELF_VIDEO_ID } from './constants';
import { SelfView, Pagination } from './components';
import {
  usePagination,
  useActiveVideo,
  useAvatarAction,
  useGalleryLayout,
  useNetworkQuality,
  useCanvasDimension,
} from './hooks';

export const VideoNonSAB = () => {
  const { media, zoomClient } = useContext(MediaContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = media;
  const { videoRef, canvasDimension } = useCanvasDimension(mediaStream);

  const activeVideo = useActiveVideo(zoomClient);
  const { page, setPage, pageSize, totalPage, totalSize } = usePagination(
    zoomClient,
    canvasDimension
  );
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zoomClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize,
    }
  );

  /**
   * position for self video
   */
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zoomClient.getCurrentUserInfo()?.userId
  );
  let selfVideoLayout = null;
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = {
        ...item,
        y: canvasDimension.height - item.y - item.height,
      };
    }
  }
  const avatarActionState = useAvatarAction(zoomClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zoomClient);

  return (
    <Styles.Viewport>
      <Styles.VideoContainer>
        <Styles.VideoCanvas
          width="800"
          height="600"
          ref={videoRef}
          id="video-canvas"
        />

        <SelfView
          id={SELF_VIDEO_ID}
          mediaStream={mediaStream}
          style={
            selfVideoLayout
              ? {
                  display: 'block',
                  pointerEvents: 'none',
                  top: `${selfVideoLayout.y}px`,
                  left: `${selfVideoLayout.x}px`,
                  width: `${selfVideoLayout.width}px`,
                  height: `${selfVideoLayout.height}px`,
                }
              : undefined
          }
        />

        <AvatarActionContext.Provider value={avatarActionState}>
          {visibleParticipants.map((user, index) => {
            if (index > videoLayout.length - 1) {
              return null;
            }
            const dimension = videoLayout[index];
            const { x, y, width, height } = dimension;
            const { height: canvasHeight } = canvasDimension;
            return (
              <VideoCallAvatar
                key={user.userId}
                isActive={activeVideo === user.userId}
                networkQuality={networkQuality[`${user.userId}`]}
                participant={{ ...user, audio: convertAudio(user.audio) }}
                style={{
                  left: `${x}px`,
                  width: `${width}px`,
                  height: `${height}px`,
                  top: `${canvasHeight - y - height}px`,
                }}
              />
            );
          })}
        </AvatarActionContext.Provider>
      </Styles.VideoContainer>

      {totalPage > 1 && (
        <Pagination page={page} setPage={setPage} totalPage={totalPage} />
      )}
    </Styles.Viewport>
  );
};
