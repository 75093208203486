import { useMemo } from 'react';
import { Icon, Utils, FlexLayout, ListCardStyle } from '@gv/triage-components';

import * as Styles from './styles';
import { getSections } from './config';
import { SummaryBillProps } from './types';
import { SummaryOption } from './summary-option';

const { formatPrice } = Utils.Helpers;

const format = '$0.00';

const SummaryBill = ({ data }: SummaryBillProps) => {
  const sections = useMemo(() => getSections(data), [data]);
  const summaryAmount = useMemo(() => {
    if (!data?.length) {
      return 0;
    }
    return data.reduce(
      (sum, { total_amount }) => sum + Number(total_amount),
      0
    );
  }, [data]);

  const placeholder = (
    <Styles.Placeholder>
      <Icon.Billing />
      <p>Your first invoice will appear here after the first payment.</p>
    </Styles.Placeholder>
  );

  const sectionsValues = sections?.map(([key, summaryRows], idx) => (
    <FlexLayout gap={8} key={idx} fullWidth flexDirection="column">
      {sections.length > 1 && <Styles.Title>{key}</Styles.Title>}
      {summaryRows.map((row) => (
        <SummaryOption {...row} key={row.title} />
      ))}
    </FlexLayout>
  ));

  return (
    <Styles.Card
      template="1fr"
      odd={ListCardStyle.Default}
      values={[
        <h3>Summary bill</h3>,
        ...(sectionsValues
          ? [<h1>{formatPrice(summaryAmount, format)}</h1>, ...sectionsValues]
          : [placeholder]),
      ]}
    />
  );
};

export { SummaryBill, SummaryOption };
