import { useMemo } from 'react';
import { Acceptance } from '@gv/triage-components';

import { useHospitalTime } from 'components/cases/form/useHospitalTime';

export const useOutcomeRulesValues = ({
  acceptance,
  onCallDocsCount,
}: {
  acceptance?: Acceptance;
  onCallDocsCount?: number;
}) => {
  const { isHospitalOpen } = useHospitalTime();
  const { willSee, waitTime, willNotSee, traumaLevel } = acceptance ?? {};

  const ruleValues = useMemo(
    () => ({
      willSee,
      waitTime,
      willNotSee,
      traumaLevel,
      outsideOfWorkingHours: !isHospitalOpen,
      onCallDoctorsAvailable: !!onCallDocsCount,
    }),
    [
      willSee,
      waitTime,
      willNotSee,
      traumaLevel,
      isHospitalOpen,
      onCallDocsCount,
    ]
  );

  return { ruleValues };
};
