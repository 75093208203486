import { useEffect } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import { FlexLayout, InputField, CheckboxField } from '@gv/triage-components';

import { useAppDispatch } from 'store';
import { CallOutcomeType } from 'types';
import { useShowProtocols } from 'components/cases';
import { setIsButtonHide } from 'store/slices/analytics-export';
import { useFormEntities, callOutcomeModel } from 'components/cases/form';

import * as Styles from './styles';
import { CheckProtocolsInfoProps } from './types';

export const CheckProtocolsInfo = ({
  children,
  hideNotes,
  hideCheckProtocols,
  hospitalType = 'main',
}: CheckProtocolsInfoProps) => {
  const { values } = useFormikContext<FormikValues>();
  const [confirm] = useField('checkProtocolsConfirm');
  const [checkbox1] = useField('checkbox1');
  const [checkbox2] = useField('checkbox2');
  const [checkbox3] = useField('checkbox3');
  const { isMainHospital } = useFormEntities();
  const showProtocols = useShowProtocols(values);
  const dispatch = useAppDispatch();
  const [type] = useField(callOutcomeModel.type);

  const isEmergency = type.value === CallOutcomeType.Emergency;
  const isAppointment = type.value === CallOutcomeType.Appointment;

  useEffect(() => {
    dispatch(setIsButtonHide(!confirm.value));
  }, [confirm.value]);

  useEffect(() => {
    dispatch(setIsButtonHide(!checkbox1.value));
  }, [checkbox1.value]);
  useEffect(() => {
    dispatch(setIsButtonHide(!checkbox2.value));
  }, [checkbox2.value]);
  useEffect(() => {
    dispatch(setIsButtonHide(!checkbox3.value));
  }, [checkbox3.value]);

  const notesField =
    hospitalType === 'main' && !hideNotes ? (
      <InputField
        label="Notes"
        placeholder="Add note"
        name="additional_notes"
        type="textarea-autosize"
      />
    ) : null;

  if (
    showProtocols &&
    !hideCheckProtocols &&
    ((!isMainHospital && hospitalType === 'other') ||
      (isMainHospital && hospitalType === 'main'))
  ) {
    return (
      <FlexLayout gap={24} flexDirection="column">
        <Styles.Info>
          <FlexLayout flexDirection="column">
            <FlexLayout justifyContent="space-between">
              <p className="semibold">Check hospital protocols</p>
            </FlexLayout>

            <p>Please follow the hospital protocols, on the right sidebar.</p>
          </FlexLayout>

          <CheckboxField
            name="checkProtocolsConfirm"
            label="Please confirm that you checked hospital protocols."
          />
        </Styles.Info>

        <Styles.ChildrenWrapper disabled={!confirm.value}>
          {children}
          {notesField}
        </Styles.ChildrenWrapper>
        {(isEmergency || isAppointment) && (
          <>
            <CheckboxField
              name="checkbox1"
              label="I told the pet owner what conditions the pet could have based on the symptoms they described to me (I understand we do not have a VCPR and I cannot give a diagnosis) and explained why the issue requires either an Appointment or an ER visit"
            />
            <CheckboxField
              name="checkbox2"
              label="I explained what tests the Pet O can expect to have done when they arrive at the veterinary practice"
            />
            <CheckboxField
              name="checkbox3"
              label="I recapped my findings with the Pet O and have addressed any other of their potential concerns to the best of my ability"
            />
          </>
        )}
      </FlexLayout>
    );
  } else {
    return (
      <>
        {children}
        {notesField}
      </>
    );
  }
};
