import { useMemo, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { ReferToErAcceptancePath } from '@gv/triage-components';

import { getIsERHospital } from 'utils/helpers';
import { useGetQuery } from 'store/api/acceptance';
import { useDetailsQuery } from 'store/api/hospitals';
import { useOnCallProtocolQuery } from 'store/api/on-call-protocol';
import { useHospitalTime } from 'components/cases/form/useHospitalTime';
import {
  useFormEntities,
  callOutcomeModel,
  CaseFormTextOutProps,
} from 'components/cases/form';

import { useCorporateOptions } from './useCorporateOptions';

const {
  emergency: { referToEr },
} = callOutcomeModel;

export const useReferToCurrentEr = ({
  setReferral,
}: Pick<CaseFormTextOutProps, 'setReferral'>) => {
  const { setFieldValue } = useFormikContext();
  const {
    hospitalId,
    isMainHospital,
    hospital: currentHospital,
  } = useFormEntities();
  const [{ value: departmentId }] = useField(referToEr.corporateHospitalId);
  const { corporateGroupOptions } = useCorporateOptions(hospitalId);

  const { isHospitalOpen } = useHospitalTime();
  const isERHospital = getIsERHospital(currentHospital?.speciality);
  const showCurrentHospital = isHospitalOpen && isERHospital;

  const id = showCurrentHospital ? hospitalId : departmentId;

  const { data: hospital } = useDetailsQuery(id!, {
    skip: !id,
  });

  const {
    isError,
    isLoading,
    data: acceptance,
  } = useGetQuery(
    {
      hospitalId: id!,
    },
    {
      skip: !id,
    }
  );

  useEffect(() => {
    Object.values(ReferToErAcceptancePath).forEach((name) =>
      setFieldValue(name, '')
    );
  }, [id]);

  const { data: protocol } = useOnCallProtocolQuery(String(id), {
    skip: !id,
  });

  const payload = useMemo(() => {
    return {
      protocol,
      acceptance,
      setReferral,
      data: hospital,
      isRadioVisible: true,
      referToERProps: !showCurrentHospital
        ? {
            referToEr,
            isMainHospital,
            hospitalName: hospital?.name,
            options: corporateGroupOptions?.referToEr,
          }
        : undefined,
    };
  }, [
    protocol,
    hospital,
    acceptance,
    setReferral,
    isMainHospital,
    showCurrentHospital,
    corporateGroupOptions?.referToEr,
  ]);

  const requiredFields = useMemo(() => {
    const fields: string[] = [
      showCurrentHospital
        ? referToEr.hospitalId
        : referToEr.corporateHospitalId,
    ];

    const data = payload?.data as Record<string, any>;
    const { waitTime, traumaLevel } = acceptance ?? {};
    const schedule = protocol?.clinicHours ?? hospital?.clinic_hours;

    if (schedule) {
      fields.push(ReferToErAcceptancePath.WorkingHours);
    }
    if (traumaLevel) {
      fields.push(ReferToErAcceptancePath.TraumaRadio);
    }
    if (waitTime) {
      fields.push(ReferToErAcceptancePath.WaitTimesRadio);
    }
    if (data?.fee) {
      fields.push(ReferToErAcceptancePath.EmergencyFeeRadio);
    }
    if (
      (acceptance?.species && acceptance.species?.length > 0) ||
      data?.species_array?.length
    ) {
      fields.push(ReferToErAcceptancePath.SpeciesSeenRadio);
    }

    return fields;
  }, [acceptance, payload, showCurrentHospital]);

  return { ...payload, isError, isLoading, requiredFields };
};
