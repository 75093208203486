import {
  Dialog,
  EntityStatus,
  ButtonColors,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { ActivateUserDialogProps } from './types';

export const ActivateUserDialog = ({
  name,
  status,
  onActivation,
}: ActivateUserDialogProps) => {
  const isActive = status === EntityStatus.Active;
  const action = isActive ? 'Deactivate' : 'Activate';
  const actionStyle = isActive
    ? ButtonColors.LightRed
    : ButtonColors.LightGreen;

  return (
    <Dialog
      confirmText={action}
      title={`${action} user`}
      confirmStyle={actionStyle}
      onConfirm={() => onActivation(!isActive)}
      trigger={<StandardButtonWithRef text={action} colorType={actionStyle} />}
      text={
        <>
          You are going to {action}
          <span className="semibold"> {name}</span>. Are you sure you want to do
          that?
        </>
      }
    />
  );
};
