import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import {
  FeedbacksStats,
  FeedbacksRequest,
  FeedbacksResponse,
  FeedbacksResponseData,
  FeedbacksResponseStats,
} from './types';

const { Query, Object, Helpers } = Utils;

export const feedbackApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'feedbackApi',
  endpoints: (build) => ({
    stats: build.query<FeedbacksStats, void>({
      transformResponse: (response: FeedbacksResponseStats) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.FEEDBACKS_STATS,
        };
      },
    }),

    list: build.query<FeedbacksResponseData, FeedbacksRequest>({
      transformResponse: (response: FeedbacksResponse) => response.data,
      query: ({ search, filters, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);

        const searchParams = {
          limit,
          offset,
          search,
          filters: JSON.stringify(Object.filterEmptyFields(filters)),
        };

        const url = Query.addParamsToUrl(URL.FEEDBACKS, searchParams);

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useListQuery, useStatsQuery } = feedbackApi;
