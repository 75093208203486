import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const hospitalsStore = (store: RootState) => store?.hospitals;

export const selectHospital = (hospitalId?: string) =>
  createSelector([hospitalsStore], (store) =>
    hospitalId ? store?.entities[Number(hospitalId)] : undefined
  );
