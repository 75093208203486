import dayjs from 'dayjs';
import { BadgePadding } from '@gv/triage-components';

import { getTaskDate } from 'utils/helpers';

import { ArrivalBadge } from '../arrival-badge';

import * as Styles from './styles';
import { TaskEtaProps } from './types';

export const TaskEta = ({
  date,
  localDate,
  taskClass,
  hasTimezone,
}: TaskEtaProps) => {
  const isToday = date ? dayjs(date).isToday() : false;
  return (
    <Styles.Container>
      <Styles.Eta $isToday={isToday}>
        {getTaskDate(date, {
          showTimezone: hasTimezone,
        })}
      </Styles.Eta>

      <ArrivalBadge
        pastText="ago"
        eta={localDate}
        taskClass={taskClass}
        padding={BadgePadding.WideMedium}
      />
    </Styles.Container>
  );
};
