import { Badge, Badges } from '@gv/triage-components';

import { getBadgeProps } from '../helpers';

import { MedicationsProps } from './types';

export const Medications = ({
  medications,
  selectedIndex,
  setSelectedIndex,
}: MedicationsProps) => (
  <Badges label="Medications/Food">
    {medications.map((medication, index) => {
      const { medications_brand_name: name } = medication;

      return (
        <Badge
          key={`${name}-${index.toString()}`}
          {...getBadgeProps({
            name,
            index,
            medications,
            selected: selectedIndex,
            onClick: () => setSelectedIndex?.(index),
          })}
        />
      );
    })}
  </Badges>
);
