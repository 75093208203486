import { generatePath } from 'react-router-dom';
import { axiosBaseQuery } from '@gv/triage-components';

import {
  apiInstance,
  apiLoaderInstance,
  apiLongTimeoutInstance,
} from 'api/instance';

export const apiQuery = axiosBaseQuery({
  instance: apiInstance,
});

export const apiLoaderQuery = axiosBaseQuery({
  instance: apiLoaderInstance,
});

export const apiLongTimeoutQuery = axiosBaseQuery({
  instance: apiLongTimeoutInstance,
});

export const generateAPIPath = (...args: Parameters<typeof generatePath>) => {
  const originalPath = args[0];
  if (!originalPath.startsWith('http')) {
    return generatePath(...args);
  }
  let newArgs: Parameters<typeof generatePath> = [...args];
  const url = new URL(originalPath).origin;
  newArgs[0] = originalPath.substring(url.length + 1);
  return `${url}/${generatePath(...newArgs)}`;
};
