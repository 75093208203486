import {
  Icon,
  FlexLayout,
  DeleteButton,
  ButtonColors,
  StandardButton,
  AudioRecordField,
} from '@gv/triage-components';

import * as Styles from '../styles';
import { AudioPlayer } from '../audio-player';

import { AudioRecordProps } from './types';

const AudioRecord = ({
  value,
  setFieldValue,
}: AudioRecordProps): JSX.Element => {
  return (
    <AudioPlayer value={value}>
      <AudioRecordField name="record">
        {({ stop, start, isRecording }) => (
          <Styles.UploadedFile>
            <FlexLayout gap={16} flexDirection="column">
              <FlexLayout
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <FlexLayout gap={8} alignItems="flex-start">
                  <StandardButton
                    transparent
                    padding="0"
                    disabled={Boolean(value?.file)}
                    onClick={isRecording ? stop : start}
                    colorType={ButtonColors.Transparent}
                    icon={isRecording ? <Icon.Stop /> : <Icon.Record />}
                  />
                  <AudioPlayer.RecordPlay />
                  <AudioPlayer.Info
                    label={isRecording ? 'Recording' : 'Start recording'}
                  />
                </FlexLayout>
                <DeleteButton
                  disabled={!value?.src}
                  onClick={() => setFieldValue('record', null)}
                />
              </FlexLayout>

              <AudioPlayer.Time />
            </FlexLayout>
          </Styles.UploadedFile>
        )}
      </AudioRecordField>
    </AudioPlayer>
  );
};

export { AudioRecord };
