import * as Yup from 'yup';

import { FormValues } from './types';

export const initialValues: FormValues = {
  message: '',
  shouldCreateChat: false,
};

export const validationSchema = Yup.object().shape({
  message: Yup.string().required(),
});
