import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from './index';

const Play = () => {
  const { state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      transparent
      padding="0"
      icon={<Icon.Play />}
      onClick={controls?.play}
      disabled={!state?.paused}
      colorType={ButtonColors.Transparent}
    />
  );
};

export { Play };
