import styled from 'styled-components';
import { Styles, UserInfo, TextColor } from '@gv/triage-components';

const { bodySemibold } = Styles.Font;

export const Info = styled(UserInfo)`
  div:first-child {
    border: 0;
    border-radius: 8px;

    > div {
      border-radius: 8px !important;
    }
  }
`;

export const Date = styled.p`
  ${bodySemibold};
  margin: 16px 0 8px;
  color: ${({ theme }) => theme.config.text[TextColor.Secondary]};
`;
