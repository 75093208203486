import { useMemo } from 'react';
import { useInterval } from 'react-use';
import {
  OpenStatus,
  WorkingHoursUtils,
  OpenStatusTextHospital,
} from '@gv/triage-components';

import { useRefresh } from 'hooks/refresh';
import { useFormEntities } from 'components/cases/form';

const { getData, getOpenStatus, getHospitalTime } = WorkingHoursUtils;

export const useHospitalTime = () => {
  const { hospital, onCallProtocol } = useFormEntities();

  const { refresh, refreshValue } = useRefresh();
  useInterval(refresh, 30000);

  const isHospitalOpen = useMemo(() => {
    if (!hospital || !onCallProtocol) {
      return undefined;
    }

    const { time } = getHospitalTime(hospital);
    const schedule = getData(
      onCallProtocol.clinicHours,
      hospital.timezone?.time_zone_code
    ).items;
    const openStatus = getOpenStatus(time, schedule, OpenStatusTextHospital);

    return (
      openStatus?.type === OpenStatus.Opened ||
      openStatus?.type === OpenStatus.CloseSoon
    );
  }, [hospital, refreshValue, onCallProtocol]);

  return { isHospitalOpen, onCallProtocol };
};
