import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';

import { apiLongTimeoutQuery } from '../../query';

import {
  FilterValues,
  CommunicationLogData,
  CommunicationLogItem,
  CommunicationLogRequest,
  CommunicationLogResponse,
  CommunicationLogFilesData,
  CommunicationLogFilesRequest,
  CommunicationLogFilesFilters,
} from './types';

export const communicationLogApi = createApi({
  baseQuery: apiLongTimeoutQuery,
  reducerPath: 'communicationLogApi',
  endpoints: (build) => ({
    get: build.query<CommunicationLogData, CommunicationLogRequest>({
      transformResponse: (response: CommunicationLogResponse) => response.data,
      query: ({ page = 1, limit = 20, hospital_id, ...rest }) => {
        const offset = Utils.Helpers.offset(page, limit);

        const searchParams = {
          limit,
          offset,
          hospital_id: hospital_id ? `[${hospital_id}]` : '[]',
          ...rest,
        };

        return {
          method: 'get',
          timeout: 60000,
          url: Utils.Query.addParamsToUrl(
            URL.GET_COMMUNICATION_LOGS,
            searchParams
          ),
        };
      },
    }),
  }),
});

export const { useGetQuery } = communicationLogApi;
export type {
  FilterValues,
  CommunicationLogData,
  CommunicationLogItem,
  CommunicationLogResponse,
  CommunicationLogFilesData,
  CommunicationLogFilesFilters,
  CommunicationLogFilesRequest,
};
