import * as Yup from 'yup';
import { Utils } from '@gv/triage-components';

import { FormValues } from './types';

export const maxLength = 1600;

export const initialValues: FormValues = {
  text: '',
  phones: [],
};

export const validationSchema = Yup.object().shape({
  text: Yup.string().trim().required().max(maxLength),
  phones: Yup.array()
    .of(Utils.Validation.phoneNumber('phone'))
    .required()
    .min(1),
});
