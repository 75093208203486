import styled from 'styled-components';
import { StandardButton } from '@gv/triage-components';

export const PetsNumber = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary.standard};
`;

export const AddNewPetButton = styled(StandardButton)`
  flex-shrink: 0;
  align-self: flex-start;
`;
