import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

import * as CommonStyle from '../styles';

export const Form = styled(FormikForm)`
  width: 100%;
`;

export const Inner = styled.div<{ isRecording?: boolean }>`
  height: 100%;
  overflow-y: auto;
  position: relative;
  ${({ isRecording }) => css`
    ${!isRecording && 'padding: 16px;'};
    ${isRecording && 'overflow-x: hidden;'};
  `};
`;

export const UploadedFile = styled.div`
  ${CommonStyle.FileCard};
`;
