import { useLocation } from 'react-router-dom';
import { QueueTab, useAlert } from '@gv/triage-components';
import { useRef, RefObject, useEffect, useContext, useCallback } from 'react';

import { Routes } from 'config';
import { useAblyEvent } from 'hooks';
import { useAppSelector } from 'store';
import { UserActionsContext } from 'context/user-actions';
import {
  selectActionQueue,
  selectCurrentTaskId,
} from 'store/api/action-queue/selectors';

import { Audio } from './styles';
import { NotifierProps } from './types';

const audioURL =
  'https://guardianvet-prod-files.s3.us-east-2.amazonaws.com/new-call-sound.mp3';
const newMessageAudioURL =
  'https://guardianvet-dev-files.s3.us-east-2.amazonaws.com/1586437343050notification_sound.mp3.mp3';

export const Notifier = ({ user, isOnCall }: NotifierProps): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const msgAudioRef = useRef<HTMLAudioElement>(null);
  const { isUserClickHappened } = useContext(UserActionsContext);

  const { showSuccessAlert } = useAlert();
  const location = useLocation();
  const currentTaskId = useAppSelector(selectCurrentTaskId);

  const stateRef = useRef({
    taskId: currentTaskId,
    path: location.pathname,
  });

  useEffect(() => {
    stateRef.current = {
      taskId: currentTaskId,
      path: location.pathname,
    };
  }, [location.pathname, currentTaskId]);

  const queueItems = useAppSelector(selectActionQueue(QueueTab.Queue));
  const queueCount = queueItems.length;

  const queueCountRef = useRef(queueCount);

  const playRef = useCallback(
    (ref: RefObject<HTMLAudioElement>, message: string) => {
      if (!ref.current || !isUserClickHappened) {
        return;
      }
      const player = ref.current;
      player.pause();
      player.currentTime = 0;
      if (isOnCall) {
        showSuccessAlert(message);
      } else {
        try {
          player.play();
        } catch (error) {
          console.error(error);
        }
      }
    },
    [isOnCall, showSuccessAlert, isUserClickHappened]
  );

  const playNotif = useCallback(
    () => playRef(audioRef, 'New Call/Chat is in queue'),
    [playRef]
  );

  const playMessageNotif = useCallback(
    () => playRef(msgAudioRef, 'New Message Received'),
    [playRef]
  );

  useEffect(() => {
    if (queueCount > queueCountRef.current) {
      playNotif();
    }
    queueCountRef.current = queueCount;
  }, [queueCount, playNotif]);

  const onNewMessage = useCallback(
    (message: any) => {
      const { channelName } = message;
      const { path, taskId } = stateRef.current;
      if (channelName) {
        if (
          channelName !== taskId ||
          !path.includes(Routes.ActionCenter.Comm)
        ) {
          playMessageNotif();
        }
      }
    },
    [playMessageNotif]
  );

  useAblyEvent(`incoming-message:${user.id}`, onNewMessage);

  // TODO: handle this in future
  // if (prevProps.newCheckInCount < newCheckInCount) {
  //   playNotif();
  // }

  return (
    <>
      <Audio controls ref={msgAudioRef}>
        <source type="audio/mp3" src={newMessageAudioURL} />
      </Audio>
      <Audio controls ref={audioRef}>
        <source src={audioURL} type="audio/mp3" />
      </Audio>
    </>
  );
};
