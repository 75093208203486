import styled, { css } from 'styled-components';
import { Styles, FlexLayout } from '@gv/triage-components';

export const Container = styled(FlexLayout)`
  gap: 16px;
  width: 100%;
  padding: 24px;
  flex-direction: column;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.config.fieldCard.shadow};

    ${!theme.responsive.isMobile &&
    css`
      width: auto;
    `}
  `}
`;

export const PlanCardSection = styled(FlexLayout)`
  gap: 4px;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.config.slidePanel.border};
  }
`;

export const Title = styled.p`
  ${Styles.Font.bodySemibold};
  color: ${({ theme }) => theme.config.input.placeholder};
`;

export const Dashes = styled.span`
  flex: 1;
  position: relative;

  &::before {
    top: 50%;
    content: '';
    width: 100%;
    position: absolute;
    transform: translateY(-50%);
    border-bottom: 1px dashed
      ${({ theme }) => theme.colors.grey.primary.standard};
  }
`;
