import { Badge, Timer, BadgeColor } from '@gv/triage-components';

import { CallStatus } from 'types';

import * as Styles from './styles';
import { CallStatusBadgeProps } from './types';

export const CallStatusBadge = ({
  status,
  startedAt,
}: CallStatusBadgeProps) => {
  return (
    <Styles.Container>
      {(status === CallStatus.Initiated || status === CallStatus.Ringing) && (
        <Badge smallFont text="Connecting" styleType={BadgeColor.Green} />
      )}
      {status === CallStatus.InProgress && startedAt && (
        <Timer started={startedAt.getTime()}>
          {(seconds) => (
            <Badge
              smallFont
              text={String(seconds)}
              styleType={BadgeColor.Green}
            />
          )}
        </Timer>
      )}
    </Styles.Container>
  );
};
