import { Fragment } from 'react';
import {
  Utils,
  FlexLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { SubscriptionPlanType } from 'types';
import { Routes, subscriptionPlan, subscriptionPlanPrice } from 'config';

import * as Styles from './styles';

const TableHeading = ({
  link,
  plan,
  currentPlan,
}: {
  link?: string;
  plan: SubscriptionPlanType;
  currentPlan: SubscriptionPlanType;
}) => {
  return (
    <FlexLayout gap={16} flexDirection="column">
      <div>
        <p className="semibold">{Utils.String.capitalize(plan)}</p>
        <p>Everything your need for you internal team communication</p>
      </div>

      <div>
        <h2>{subscriptionPlanPrice[plan]}</h2>
        <p>per user per month month</p>
      </div>

      {currentPlan === plan ? (
        <Styles.CurrentPlan>Your current plan</Styles.CurrentPlan>
      ) : (
        <StandardButton
          to={link}
          text="Choose plan"
          colorType={ButtonColors.Secondary}
        />
      )}
    </FlexLayout>
  );
};

export const SubscriptionPlansTable = ({
  currentPlan,
}: {
  currentPlan: SubscriptionPlanType;
}) => {
  const advancedRecommended = currentPlan === 'essential';

  return (
    <Styles.Table>
      <tbody>
        <tr>
          <th></th>
          <Styles.EssentialTableHead>
            <TableHeading
              currentPlan={currentPlan}
              plan={SubscriptionPlanType.Essential}
            />
          </Styles.EssentialTableHead>

          <Styles.AdvancedTableHead highlight={advancedRecommended}>
            <TableHeading
              currentPlan={currentPlan}
              plan={SubscriptionPlanType.Advanced}
              link={Routes.Dashboard.Upgrade.Advanced}
            />
          </Styles.AdvancedTableHead>

          <Styles.MainTableHead>
            <TableHeading
              currentPlan={currentPlan}
              plan={SubscriptionPlanType.Professional}
              link={Routes.Dashboard.Upgrade.Professional}
            />
          </Styles.MainTableHead>
        </tr>

        {Object.entries(subscriptionPlan).map(
          ([title, services], planIndex, plansArray) => (
            <Fragment key={title}>
              <Styles.TableHeaderRow>
                <Styles.TableHead>{title}</Styles.TableHead>
                <Styles.TableHead />
                <Styles.TableHead highlight={advancedRecommended} />
                <Styles.TableHead />
              </Styles.TableHeaderRow>

              {Object.entries(services).map(
                ([serviceTitle, values], serviceIndex, servicesArray) => {
                  const isLastRow =
                    planIndex === plansArray.length - 1 &&
                    serviceIndex === servicesArray.length - 1;

                  return (
                    <tr key={serviceTitle}>
                      <Styles.TableRowHead>{serviceTitle}</Styles.TableRowHead>
                      <Styles.LeftBorderTableData>
                        {values.essential}
                      </Styles.LeftBorderTableData>

                      <Styles.SidesBorderTableData
                        withBottomBorder={isLastRow}
                        highlight={advancedRecommended}
                      >
                        {values.advanced}
                      </Styles.SidesBorderTableData>
                      <Styles.TableData>{values.professional}</Styles.TableData>
                    </tr>
                  );
                }
              )}
            </Fragment>
          )
        )}
      </tbody>
    </Styles.Table>
  );
};
