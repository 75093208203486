import {
  Icon,
  Utils,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as CommonStyles from 'components/communication-log/styles';

import { SelectedEventPageProps } from '../types';

import * as Styles from './styles';

export const ChatEvent = ({
  event,
  onClose,
}: SelectedEventPageProps): JSX.Element => {
  const channelName =
    event.details &&
    'channelName' in event.details &&
    event.details.channelName;

  return (
    <Styles.Container>
      <CommonStyles.Header>
        <StandardButton
          padding="0"
          onClick={onClose}
          icon={<Icon.ChevronLeft />}
          colorType={ButtonColors.Transparent}
        />

        <div>
          <h3>Chat {event?.callId}</h3>
          <p className="caption-regular">
            {Utils.Date.formatDate(event.timestamp, 'MMMM D, YYYY LT')}
          </p>
        </div>
      </CommonStyles.Header>

      {channelName && (
        <Styles.StyledChat
          hideInput
          initialLoadByComponent
          task={{ channelName }}
        />
      )}
    </Styles.Container>
  );
};
