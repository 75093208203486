import { useMemo } from 'react';
import {
  List,
  Utils,
  ListCard,
  isMobile,
  FlexLayout,
} from '@gv/triage-components';

import { MatrixConfigKey } from 'types';

import * as Styles from './styles';
import { SchedulingMatrixTableProps } from './types';
import { weekDays, getTableProps, getDurationTitle } from './config';

const {
  Object: { getValueByPath },
  Helpers: { textValue, formatPrice },
} = Utils;

const mobileTemplate = 'repeat(2, 1fr)';

export const SchedulingMatrixTable = ({
  widgetView,
  matrices = [],
  matrixConfig = {},
  ...props
}: SchedulingMatrixTableProps) => {
  const mobile = isMobile();

  const { titles, template, filtered } = useMemo(
    () => getTableProps({ matrixConfig }),
    [matrixConfig]
  );

  const mainWrapperStyles = useMemo(
    () =>
      widgetView
        ? { padding: '8px 0', minWidth: mobile ? 0 : '900px' }
        : undefined,
    [mobile, widgetView]
  );

  if (widgetView && !matrices?.length) {
    return null;
  }

  const table = (
    <List
      horizontalScroll
      count={matrices?.length ?? 0}
      mainWrapperStyles={mainWrapperStyles}
      header={
        matrices?.length ? (
          <Styles.ListHeader
            titles={titles}
            padding="8px 16px"
            template={template}
            tabletTitles={titles}
            widgetView={widgetView}
            tabletTemplate={template}
          />
        ) : undefined
      }
      {...props}
    >
      {matrices.map((matrix, idx) => (
        <ListCard
          key={idx}
          template={template}
          padding="12px 16px"
          mobileHeaders={titles}
          mobileTemplate={mobileTemplate}
          verticalAlign={mobile ? 'flex-start' : undefined}
          values={filtered.map(({ key, bold, valuePath, timeTitles }) => {
            const value = getValueByPath(matrix, valuePath);
            if (Array.isArray(value)) {
              return (
                <FlexLayout fullWidth minWidth={0} flexDirection="column">
                  {value.map(({ name, SchedulingMatrixDoctors }, docIdx) => (
                    <Styles.Ellipsis key={docIdx}>
                      {textValue(
                        key === MatrixConfigKey.Doctor
                          ? name
                          : SchedulingMatrixDoctors?.dayOfWorks
                              ?.map((item: number) => weekDays[item])
                              .join(', ')
                      )}
                    </Styles.Ellipsis>
                  ))}
                </FlexLayout>
              );
            } else {
              const tableValue = textValue(value);
              return key === MatrixConfigKey.SpecialInstructions ? (
                <p>{tableValue}</p>
              ) : (
                <Styles.Ellipsis bold={bold}>
                  {value && key === MatrixConfigKey.Price
                    ? formatPrice(value, '$0,0[.]00')
                    : timeTitles
                      ? getDurationTitle(value)
                      : tableValue}
                </Styles.Ellipsis>
              );
            }
          })}
        />
      ))}
    </List>
  );

  return !widgetView ? (
    table
  ) : (
    <FlexLayout gap={8} flexDirection="column">
      <p className="semibold">Please review the hospital scheduling matrix</p>
      <Styles.Container>{table}</Styles.Container>
    </FlexLayout>
  );
};
