import { Formik } from 'formik';
import {
  Row,
  Col,
  Dialog,
  isMobile,
  InputField,
  DialogType,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as Styles from './styles';
import { AppointmentTypesFormProps } from './types';
import { getInitialValues, validationSchema } from './config';

export const AppointmentTypesForm = ({
  data,
  isEdit,
  trigger,
  onSubmit,
}: AppointmentTypesFormProps) => {
  const mobile = isMobile();

  return (
    <Dialog
      trigger={trigger}
      title={`${isEdit ? 'Edit' : 'Add new'} appointment type`}
    >
      {(close, _, Buttons) => (
        <Formik
          validationSchema={validationSchema}
          initialValues={getInitialValues(data)}
          onSubmit={(values) => {
            onSubmit(values);
            close();
          }}
        >
          {({ dirty, isSubmitting }) => (
            <Styles.Form>
              <Row>
                <Col>
                  <InputField
                    name="name"
                    label="Name"
                    placeholder="Add appointment type"
                  />
                </Col>
              </Row>

              <Buttons type={DialogType.Plain}>
                <StandardButton
                  text="Cancel"
                  onClick={close}
                  fullWidth={mobile}
                  colorType={ButtonColors.Secondary}
                />
                <StandardButton
                  fullWidth={mobile}
                  type={ButtonTypes.Submit}
                  text={isEdit ? 'Edit' : 'Save'}
                  disabled={!dirty || isSubmitting}
                />
              </Buttons>
            </Styles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
