import styled from 'styled-components';
import { PriorityType } from '@gv/triage-components';

export const Dot = styled.p<{ type: PriorityType }>`
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background: ${({ type, theme }) => theme.config.mood.bg[type]};
`;
