import {
  Row,
  Col,
  List,
  Utils,
  mapQuery,
  ListCard,
  FlexLayout,
  useNavigate,
  RadioButton,
  useSearchPagination,
} from '@gv/triage-components';

import { useSubscribersQuery } from 'store/api/marketing';

import * as Styles from './styles';
import { MarketingSubscribersListProps } from './types';

const { getPlural } = Utils.Helpers;

export const MarketingSubscribersList = ({
  selected,
  isListInDialog,
  selectSubscribers,
}: MarketingSubscribersListProps) => {
  const navigate = useNavigate();
  const pageData = useSearchPagination();

  const { debounceQuery } = pageData;

  const { data = [], ...props } = useSubscribersQuery({
    search: debounceQuery,
  });

  return (
    <List
      {...mapQuery(props)}
      {...pageData}
      count={data.length}
      searchContainerStyle={isListInDialog ? { margin: '16px 0' } : undefined}
      mainWrapperStyles={
        isListInDialog ? { padding: 'unset', overflowX: 'hidden' } : undefined
      }
    >
      <Row>
        {data.map((item) => (
          <Col col={4} key={item.id} colTablet={6} colMobile={12}>
            <ListCard
              selected
              isOdd={false}
              template="1fr"
              padding="16px"
              onClick={
                isListInDialog
                  ? () => {
                      selectSubscribers?.(item);
                    }
                  : () => navigate(String(item.id))
              }
              values={[
                <Styles.Card>
                  <FlexLayout justifyContent="space-between">
                    <FlexLayout flexDirection="column">
                      <h3 className="semibold">{item.title}</h3>
                      <Styles.Subtitle>
                        {getPlural('contact', item.contacts.length, {
                          rightCount: true,
                        })}
                      </Styles.Subtitle>
                    </FlexLayout>

                    {isListInDialog && (
                      <RadioButton
                        value={String(item.id)}
                        checked={item.id === selected}
                        onChange={() => selectSubscribers?.(item)}
                      />
                    )}
                  </FlexLayout>

                  <Styles.Contact>{item.contacts.join(', ')}</Styles.Contact>
                </Styles.Card>,
              ]}
            />
          </Col>
        ))}
      </Row>
    </List>
  );
};
