import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { DynamicFormListItem } from 'types';
import { apiQuery, generateAPIPath } from 'store/query';

import {
  WidgetsData,
  GetListRequest,
  GetFormRequest,
  GetFormResponse,
  GetListResponse,
  CreateFormRequest,
  UpdateFormRequest,
  GetFormByIdRequest,
  WidgetsListRequest,
  GetListResponseData,
  GetFormResponseData,
  WidgetsListResponse,
  GetGeneralListResponse,
} from './types';

const tagTypes = ['DynamicForms'];

const { addParamsToUrl } = Utils.Query;

export const dynamicFormsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'dynamicFormsApi',
  endpoints: (build) => ({
    delete: build.mutation<void, GetFormByIdRequest>({
      invalidatesTags: tagTypes,
      query: ({ formId, hospitalId }) => ({
        method: 'delete',
        url: generateAPIPath(URL.UPDATE_HOSPITAL_DYNAMIC_CASE_FORM, {
          formId,
          hospitalId,
        }),
      }),
    }),

    generalList: build.query<DynamicFormListItem[], void>({
      providesTags: tagTypes,
      transformResponse: (response: GetGeneralListResponse) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.GET_GENERAL_DYNAMIC_FORM_LIST,
        };
      },
    }),

    createForm: build.mutation<GetFormResponseData, CreateFormRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, hospitalId }) => {
        return {
          data,
          method: 'post',
          url: generateAPIPath(URL.CREATE_HOSPITAL_DYNAMIC_CASE_FORM, {
            hospitalId,
          }),
        };
      },
    }),

    getForm: build.query<GetFormResponseData, GetFormRequest>({
      providesTags: tagTypes,
      transformResponse: (response: GetFormResponse) => response.data,
      query: ({ hospitalId }) => {
        return {
          method: 'get',
          url: generateAPIPath(URL.GET_HOSPITAL_DYNAMIC_CASE_FORM, {
            hospitalId,
          }),
        };
      },
    }),

    updateForm: build.mutation<GetFormResponseData, UpdateFormRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, formId, isGeneral, hospitalId }) => {
        return {
          data,
          method: 'patch',
          url: generateAPIPath(
            isGeneral
              ? URL.UPDATE_GENERAL_DYNAMIC_FORM
              : URL.UPDATE_HOSPITAL_DYNAMIC_CASE_FORM,
            {
              formId,
              hospitalId: isGeneral ? undefined : hospitalId,
            }
          ),
        };
      },
    }),

    getFormById: build.query<GetFormResponseData, GetFormByIdRequest>({
      providesTags: tagTypes,
      transformResponse: (response: GetFormResponse) => response.data,
      query: ({ formId, isGeneral, hospitalId }) => {
        return {
          method: 'get',
          url: generateAPIPath(
            isGeneral
              ? URL.GET_GENERAL_DYNAMIC_FORM
              : URL.GET_HOSPITAL_DYNAMIC_CASE_FORM_BY_ID,
            {
              formId,
              hospitalId: isGeneral ? undefined : hospitalId,
            }
          ),
        };
      },
    }),

    widgetsList: build.query<WidgetsData, WidgetsListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: WidgetsListResponse) => response.data,
      query: ({ isGeneral, hospitalId, componentType }) => {
        return {
          method: 'get',
          url: isGeneral
            ? URL.GET_GENERAL_DYNAMIC_FORM_WIDGETS_LIST
            : addParamsToUrl(
                generateAPIPath(URL.GET_HOSPITAL_DYNAMIC_FORM_WIDGETS_LIST, {
                  hospitalId,
                }),
                { componentType }
              ),
        };
      },
    }),

    list: build.query<GetListResponse, GetListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: GetListResponseData) => response.data,
      query: ({ types, search, page = 1, limit = 20, hospitalId }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          types,
          limit,
          offset,
          search,
        };
        const url = Utils.Query.addParamsToUrl(
          generateAPIPath(URL.GET_HOSPITAL_DYNAMIC_FORM_LIST, { hospitalId }),
          searchParams
        );

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useGetFormQuery,
  useDeleteMutation,
  useGeneralListQuery,
  useGetFormByIdQuery,
  useWidgetsListQuery,
  useCreateFormMutation,
  useUpdateFormMutation,
} = dynamicFormsApi;
