import { generateUrl } from 'utils/helpers';
import { UpdateClientPayload } from 'store/api/clients';

import { URL } from '../constants';
import { apiInstance } from '../instance';

export const getAuthClientDetails = () => apiInstance.get(URL.PO_PROFILE);

export const updateClient = ({ client_id, ...payload }: UpdateClientPayload) =>
  apiInstance.put(generateUrl(URL.UPDATE_CLIENT, { id: client_id }), payload);
