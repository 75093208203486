import { createSelector } from 'reselect';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { RootState } from 'store/store';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { apiQuery, generateAPIPath } from 'store/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import {
  Pet,
  Client,
  ClientData,
  GVTalkProfile,
  ClientRequest,
  StripeCardInfo,
  ClientsRequest,
  ClientResponse,
  BasePetRequest,
  PaymentCustomer,
  GetClientResponse,
  PetsUpdateRequest,
  PetUpdateResponse,
  PetCreateResponse,
  PetsCreateRequest,
  ProfileGetResponse,
  CommonClientPayload,
  UpdateClientPayload,
  CreateClientPayload,
  SetupIntentsResponse,
  ClientDeleteResponse,
  UpdateClientPayloadV2,
  CommonClientPayloadV2,
  GetClientCardListResponse,
} from './types';

const clientsAdapter = createEntityAdapter<Client, number>({
  selectId: (item) => item.id,
});

const tagType = 'Clients';
export const cardsTag = 'Cards';
export const paymentClientTag = 'PaymentClients';

export const clientsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'clientsApi',
  tagTypes: [tagType, cardsTag, paymentClientTag],
  endpoints: (build) => ({
    setupIntents: build.query<SetupIntentsResponse, void>({
      keepUnusedDataFor: 1,
      query: () => ({
        method: 'post',
        url: URL.SETUP_CLIENT_INTENTS,
      }),
    }),

    paymentProfile: build.query<PaymentCustomer, void>({
      providesTags: [paymentClientTag],
      query: () => ({
        method: 'get',
        url: URL.PAYMENTS_CLIENT_PROFILE,
      }),
    }),

    deleteCard: build.mutation<void, string>({
      invalidatesTags: [cardsTag],
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_CLIENT_CARD, { id }),
      }),
    }),

    setDefaultCard: build.mutation<void, string>({
      invalidatesTags: [cardsTag],
      query: (paymentMethodId) => ({
        method: 'post',
        data: { paymentMethodId },
        url: URL.ADD_DEFAULT_CLIENT_CARD,
      }),
    }),

    profile: build.query<GVTalkProfile, number>({
      transformResponse: (response: ProfileGetResponse) => response.data,
      query: (id) => ({
        method: 'get',
        url: generateAPIPath(URL.GET_CLIENT_PROFILE_BY_ID, { id }),
      }),
    }),

    listCard: build.query<StripeCardInfo[], void>({
      providesTags: [cardsTag],
      transformResponse: (response: GetClientCardListResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.GET_CLIENT_CARDS,
      }),
    }),

    current: build.query<Client, void>({
      keepUnusedDataFor: 1,
      providesTags: [{ type: tagType, id: 'current' }],
      transformResponse: (response: GetClientResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.PO_PROFILE,
      }),
    }),

    create: build.mutation<Client, CreateClientPayload>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: GetClientResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_NEW_CLIENT,
      }),
    }),

    delete: build.mutation<any, number>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: ClientDeleteResponse) => response.data,
      query: (client_id) => ({
        method: 'delete',
        url: generateUrl(URL.UPDATE_CLIENT, { id: client_id }),
      }),
    }),

    createPetByPO: build.mutation<Client, BasePetRequest>({
      transformResponse: (response: GetClientResponse) => response.data,
      invalidatesTags: (result, error) =>
        cacheByIdArg(tagType)(result, error, 'current'),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.PO_ADD_PET,
      }),
    }),

    complete: build.mutation<Client, CommonClientPayloadV2>({
      transformResponse: (response: GetClientResponse) => response.data,
      invalidatesTags: (result, error) =>
        cacheByIdArg(tagType)(result, error, 'current'),
      query: (data) => ({
        data,
        method: 'put',
        url: URL.PO_SIGN_UP,
      }),
    }),

    updateCurrent: build.mutation<Client, UpdateClientPayloadV2>({
      transformResponse: (response: GetClientResponse) => response.data,
      invalidatesTags: (result, error) =>
        cacheByIdArg(tagType)(result, error, 'current'),
      query: (data) => ({
        data,
        method: 'put',
        url: URL.PO_PROFILE,
      }),
    }),

    createPet: build.mutation<Pet, PetsCreateRequest>({
      transformResponse: (response: PetCreateResponse) => response.data,
      invalidatesTags: (result, error, { client_id }) =>
        cacheByIdArg(tagType)(result, error, client_id),
      query: ({ client_id, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.ADD_NEW_PET, { id: client_id }),
      }),
    }),

    update: build.mutation<Client, UpdateClientPayload>({
      transformResponse: (response: GetClientResponse) => response.data,
      invalidatesTags: (result, error, { client_id }) =>
        cacheByIdArg(tagType)(result, error, client_id),
      query: ({ client_id, ...data }) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_CLIENT, { id: client_id }),
      }),
    }),

    updatePet: build.mutation<Pet, PetsUpdateRequest>({
      transformResponse: (response: PetUpdateResponse) => response.data,
      invalidatesTags: (result, error, { client_id }) =>
        cacheByIdArg(tagType)(result, error, client_id),
      query: ({ pet_id, client_id, ...data }) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_PET, { id: client_id, petId: pet_id }),
      }),
    }),

    details: build.query<Client, ClientRequest>({
      keepUnusedDataFor: 1,
      providesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: GetClientResponse) => response.data,
      query: ({ id, hospitalId, allowDeleted }) => {
        const url = Utils.Query.addParamsToUrl(
          generateAPIPath(URL.GET_CLIENT_INFO_BY_ID, { id }),
          {
            hospitalId,
            allowDeleted,
          }
        );
        return {
          url,
          method: 'get',
        };
      },
    }),

    list: build.query<ClientData, ClientsRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: ClientResponse) => response.data,
      query: ({ search, page = 1, limit = 20, hospitalId }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          search,
          hospitalId,
        };
        const url = Utils.Query.addParamsToUrl(
          URL.GET_CLIENT_LIST,
          searchParams
        );

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const clientsSlice = createSlice({
  reducers: {},
  name: 'clients',
  initialState: clientsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      clientsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.setAll(state, payload.rows);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.updateOne(state, {
          changes: payload,
          id: clientsAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.current.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.complete.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.updateCurrent.matchFulfilled,
      (state, { payload }) => {
        clientsAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.updatePet.matchFulfilled,
      (state, { payload }) => {
        const clientId = payload.client_id;
        if (clientId) {
          const client = clientsAdapter
            .getSelectors()
            .selectById(state, clientId);
          if (client) {
            const pets = client.pets.map((pet) => {
              if (!payload?.id) {
                return pet;
              }
              return {
                ...pet,
                ...payload,
              };
            });
            clientsAdapter.updateOne(state, {
              id: clientId,
              changes: {
                pets,
              },
            });
          }
        }
      }
    );

    builder.addMatcher(
      clientsApi.endpoints.delete.matchFulfilled,
      (state, { meta }) => {
        clientsAdapter.removeOne(state, meta.arg.originalArgs);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      clientsAdapter.removeAll(state);
    });
  },
});

const clientsStore = (store: RootState) => store?.clients;

export const selectClient = (id: string | undefined) =>
  createSelector([clientsStore], (store) => {
    return id ? store?.entities[Number(id)] : undefined;
  });

export const selectClients = createSelector(
  [clientsStore],
  (store) => store?.entities
);

export const {
  useListQuery,
  useProfileQuery,
  useCurrentQuery,
  useDetailsQuery,
  useListCardQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useCompleteMutation,
  useSetupIntentsQuery,
  useCreatePetMutation,
  useUpdatePetMutation,
  useDeleteCardMutation,
  usePaymentProfileQuery,
  useUpdateCurrentMutation,
  useCreatePetByPOMutation,
  useSetDefaultCardMutation,
  useLazyPaymentProfileQuery,
} = clientsApi;

export type {
  BasePetRequest,
  PetsCreateRequest,
  PetsUpdateRequest,
  CreateClientPayload,
  CommonClientPayload,
  UpdateClientPayload,
  CommonClientPayloadV2,
};

export { tagType as clientsTags };
