import styled from 'styled-components';
import {
  Col as TCCol,
  Row as TCRow,
  CheckboxField as TCCheckboxField,
} from '@gv/triage-components';

export const Row = styled(TCRow)`
  display: flex;
  margin-bottom: -24px;
  align-items: flex-end;
`;

export const Col = styled(TCCol)`
  margin-bottom: 16px;

  label {
    align-items: flex-start;

    span {
      white-space: pre;
    }
  }
`;

export const CheckboxField = styled(TCCheckboxField)`
  margin-top: 8px;
`;
