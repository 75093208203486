import { EventType } from '@gv/triage-components';

import { useAppSelector } from 'store';
import { selectCaseLogEvent } from 'store/api/case-logs/selectors';

import { CallEvent } from './call';
import { ChatEvent } from './chat';
import * as Styles from './styles';
import { TextOutEvent } from './text-out';
import { FeedbackEvent } from './feedback';
import { SelectedEventProps } from './types';

export const SelectedEvent = ({
  event,
  currentCase,
  relatedEvents,
  onSelectEvent,
}: SelectedEventProps): JSX.Element => {
  const caseLogEvent = useAppSelector(
    selectCaseLogEvent(currentCase?.id, event.id)
  );

  const onClose = () => onSelectEvent(undefined);

  const getContent = () => {
    if (!caseLogEvent) {
      return null;
    }
    switch (event.type) {
      case EventType.Call:
        return (
          <CallEvent
            onClose={onClose}
            parsedEvent={event}
            event={caseLogEvent}
            relatedEvents={relatedEvents}
            onSelectEvent={onSelectEvent}
          />
        );
      case EventType.Chat:
        return (
          <ChatEvent
            onClose={onClose}
            parsedEvent={event}
            event={caseLogEvent}
          />
        );
      case EventType.TextOut:
        return (
          <TextOutEvent
            onClose={onClose}
            parsedEvent={event}
            event={caseLogEvent}
          />
        );
      case EventType.Feedback:
        return (
          <FeedbackEvent
            onClose={onClose}
            parsedEvent={event}
            event={caseLogEvent}
          />
        );
      default:
        return null;
    }
  };

  return <Styles.Content>{getContent()}</Styles.Content>;
};
