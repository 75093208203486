import { Utils, BaseResponse } from '@gv/triage-components';

import { URL } from '../constants';
import { apiInstance } from '../instance';

import { GetVideoCallToken, VideoCallTokenResponse } from './types';

export const getToken = ({ chatId }: GetVideoCallToken) =>
  apiInstance.get<BaseResponse<VideoCallTokenResponse>>(
    Utils.Query.addParamsToUrl(URL.VIDEO_CALL_TOKEN, {
      chatId,
    })
  );
