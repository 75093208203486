import { useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import {
  Col,
  Row,
  Utils,
  InputField,
  SelectField,
  RadioGridInputField,
} from '@gv/triage-components';

import { assessmentsRadioGridInputProps } from 'utils/config';
import { useListQuery as useMedicationsQuery } from 'store/api/medications';

import * as Styles from './styles';
import { StabilityAssessmentProps } from './types';

export const StabilityAssessment = ({ isEdit }: StabilityAssessmentProps) => {
  const { data, isFetching } = useMedicationsQuery({});
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const assessmentsString = useMemo(() => {
    let assessmentsValues: any[] = [];
    for (const row of assessmentsRadioGridInputProps.rows) {
      const { fieldName } = row;
      assessmentsValues.push(values[fieldName]);
    }
    return assessmentsValues.join(',');
  }, [values]);

  const medicationOptions = useMemo(() => {
    if (!data?.rows) {
      return [];
    }
    let options = Utils.Helpers.sortSelectOptions(
      data.rows.map((item) => ({
        value: item.name,
        label: item.name,
        category: item.category,
      }))
    );
    const foodIndex = options.findIndex(
      (item) => item.category.name === 'Food'
    );
    if (foodIndex >= 0) {
      const foodOpt = options.splice(foodIndex, 1);
      options = [...foodOpt, ...options];
    }
    return options;
  }, [data]);

  const assessments = useMemo(() => {
    return (
      <Row>
        <Col>
          <RadioGridInputField
            {...assessmentsRadioGridInputProps}
            requiredText="(Required)"
            label="Stability assessment"
            errorText="Please fill out the stability assessment table"
          />
        </Col>
      </Row>
    );
  }, [assessmentsString]);

  const onRemoveCheckbox = (name: string) =>
    values[name] && setFieldValue(name, false);

  const medications = useMemo(
    () => (
      <Row>
        <Col>
          <SelectField
            isMulti
            onlyValue
            isCreatable
            isClearable
            isSearchable
            isDisabled={isFetching}
            name="medications_list"
            placeholder="Start typing"
            label="Current medications"
            options={medicationOptions}
            onChange={() => onRemoveCheckbox('checkedMedications')}
          />

          {!isEdit && (
            <Styles.CheckboxField
              label="None"
              id="checkedMedications"
              name="checkedMedications"
              onChange={(e) =>
                e.target.checked && setFieldValue('medications_list', [])
              }
            />
          )}
        </Col>
      </Row>
    ),
    [isFetching, medicationOptions, values.checkedMedications]
  );

  const notes = useMemo(
    () => (
      <Row>
        <Col>
          <InputField
            placeholder="Add note"
            type="textarea-autosize"
            name="chronial_medical_issues"
            label="Current medical conditions"
            onChange={() => onRemoveCheckbox('checkedConditions')}
          />

          {!isEdit && (
            <Styles.CheckboxField
              label="None"
              id="checkedConditions"
              name="checkedConditions"
              onChange={(e) =>
                e.target.checked && setFieldValue('chronial_medical_issues', '')
              }
            />
          )}
        </Col>
      </Row>
    ),
    [values.checkedConditions]
  );

  return (
    <div>
      {assessments}

      {medications}

      {notes}
    </div>
  );
};
