import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { useDebounceValue } from 'usehooks-ts';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Logger } from '@ibrightsider/cloudwatch-front-logger';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import {
  Utils,
  Dialog,
  NotFound,
  Suspense,
  useAlert,
  DialogType,
  ProtectedRoute,
  StandardButton,
  HeaderContextProvider,
} from '@gv/triage-components';

import { OutdatedBanner } from 'components';
import { getBrowserState } from 'utils/support';
import { CommContextProvider } from 'context/comm';
import { Config, Routes as RoutePaths } from 'config';
import { useAppDispatch, useAppSelector } from 'store';
import { SidebarContextProvider } from 'context/sidebar';
import { OutboundContextProvider } from 'context/outbound';
import {
  setAuthPath,
  setAuthError,
  selectAuthUser,
  selectAuthError,
  selectIsLoggedIn,
  selectAuthClient,
} from 'store/slices/auth';

import { useLoggerHook } from './useLoggerHook';
import { AppProps, Component, ComponentWithOptions } from './types';

function isComponentWithOptions(
  value: Component | ComponentWithOptions
): value is ComponentWithOptions {
  return (
    (value as ComponentWithOptions).allowedForLoggedIn !== undefined ||
    (value as ComponentWithOptions).allowedOptions !== undefined
  );
}

const { raw: portalType } = Config.portalType;

Sentry.setTag('portal', portalType);

const App = ({
  homeRoute,
  authRoute,
  PrivateApp,
  mappedRoutes,
  savePathOnAuthRedirect,
}: AppProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [browserState] = useState(getBrowserState());
  const authorizedIdRef = useRef<string | undefined>(undefined);

  const { showErrorAlert } = useAlert();
  const dispatch = useAppDispatch();

  useLoggerHook();

  const user = useAppSelector(selectAuthUser);
  const client = useAppSelector(selectAuthClient);
  const authorizedId = user?.id
    ? String(user.id)
    : client
      ? String(client.id)
      : undefined;

  const authError = useAppSelector(selectAuthError);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [errorDebounce] = useDebounceValue(error, 1000);
  const location = useLocation();

  useEffect(() => {
    if (authError) {
      setError(Utils.Error.getErrorMessage(authError));
      dispatch(setAuthError(undefined));
    }
  }, [authError]);

  const savePath = useCallback(() => {
    dispatch(setAuthPath({ path: location.pathname + location.search }));
  }, [dispatch, location.pathname, location.search]);

  useEffect(() => {
    if (errorDebounce) {
      showErrorAlert(errorDebounce);
      setError(undefined);
    }
  }, [errorDebounce]);

  useEffect(() => {
    document.body.classList.add(`portal-${portalType}`);
  }, [portalType]);

  useEffect(() => {
    if (!user && !client) {
      Sentry.setUser(null);
      return;
    }
    if (user) {
      Sentry.setUser({
        id: authorizedId,
        email: authorizedId,
        username: user.name,
      });
    } else if (client) {
      Sentry.setUser({
        id: authorizedId,
        email: authorizedId,
        username: client.name,
      });
    }
  }, [authorizedId, user?.name, client?.name]);

  const loggerInstance = useMemo(() => {
    const {
      serverEnvironment,
      sentry: { release },
      aws: { logger: awsLogger },
    } = Config;
    if (awsLogger.configured && serverEnvironment) {
      const session = Utils.String.replaceAll(uuidv4(), '-', '');
      const logger = new Logger(
        awsLogger.key,
        awsLogger.secret,
        'us-east-2',
        awsLogger.group
      );
      logger.setLevels(['debug', 'error', 'info', 'log', 'warn']);
      logger.install({
        logStreamNameResolver: () => authorizedIdRef.current ?? 'anonymous',
        messageFormatter: (e, info) => {
          const { args, level, originalMessage } = info ?? {};
          const converToString = (value: any) =>
            !(value instanceof Error) && typeof value === 'object'
              ? JSON.stringify(value)
              : value;
          let msg = converToString(originalMessage ?? e.message);
          if (args && Array.isArray(args)) {
            msg = [msg, ...args.map(converToString)].join(' ');
          }
          return `[${level ?? 'log'}] [${session}] ${msg}`;
        },
      });

      console.log(
        `--------- STARTED ${session} ${release ? `${release}` : ' '}---------`
      );
      return logger;
    }
    return undefined;
  }, []);

  useEffect(() => {
    authorizedIdRef.current = authorizedId;
    if (loggerInstance) {
      loggerInstance.resetLogStreamName();
    }
  }, [authorizedId, loggerInstance]);

  return (
    <>
      {!isLoggedIn && <OutdatedBanner polling />}
      <Routes>
        <Route
          path={RoutePaths.NotFound}
          element={
            <Suspense>
              <NotFound homePath={homeRoute} />
            </Suspense>
          }
        />
        {Object.keys(mappedRoutes).map((path) => {
          const route = mappedRoutes[path];
          const hasOptions = isComponentWithOptions(route);
          const Page = hasOptions ? route.Component : route;
          let redirect = homeRoute;
          let isAllowed = !isLoggedIn;
          if (hasOptions) {
            if (route.allowedOptions) {
              isAllowed = route.allowedOptions.allowed;
              if (route.allowedOptions.redirectTo) {
                redirect = route.allowedOptions.redirectTo;
              }
            } else if (route.allowedForLoggedIn !== undefined) {
              isAllowed = route.allowedForLoggedIn;
            }
          }
          return (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute redirect={redirect} allowed={isAllowed}>
                  <Suspense>
                    <Page />
                  </Suspense>
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <ProtectedRoute
              allowed={isLoggedIn}
              redirect={authRoute}
              onWillRedirect={savePathOnAuthRedirect ? savePath : undefined}
            >
              <Suspense>
                <CommContextProvider>
                  <OutboundContextProvider>
                    <SidebarContextProvider>
                      <HeaderContextProvider>
                        <PrivateApp />
                      </HeaderContextProvider>
                    </SidebarContextProvider>
                  </OutboundContextProvider>
                </CommContextProvider>
              </Suspense>
            </ProtectedRoute>
          }
        />
      </Routes>

      {browserState.outdated && (
        <Dialog
          open
          type={DialogType.Plain}
          title="Update your browser"
          closeOnDocumentClick={false}
          buttons={() => (
            <div style={{ width: '100%' }}>
              <StandardButton
                text="How to update web browser"
                onClick={() =>
                  window.open(
                    'https://www.wikihow.com/Update-Your-Browser',
                    '_blank'
                  )
                }
              />
            </div>
          )}
          text={
            <>
              <p>
                In order to ensure the correct operation of the GV-Triage
                platform update your browser to the latest version.
              </p>
              <p>
                To find out how to update your browser, please open the link
                below and follow the instructions.
              </p>
            </>
          }
        />
      )}
    </>
  );
};

export default App;
