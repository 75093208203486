import { Utils, UserRoleType } from '@gv/triage-components';

import { cvtRoles } from 'config';
import { useAppSelector } from 'store';
import { selectHasRoles } from 'store/slices/auth';
import { useListQuery } from 'store/api/hospital-referrals';
import { useListQuery as useServicesListQuery } from 'store/api/er-services';

import { useOptionalHospitalData } from '../hospital';

import { UseEmergencyDataApiProps } from './types';

export const useEmergencyDataApi = (
  id: string,
  refetchOnMountOrArgChange = true,
  typedError: boolean = false
): UseEmergencyDataApiProps => {
  const skip = id === 'null' || id === 'undefined';
  const props = { skip, refetchOnMountOrArgChange };
  const referrals = useListQuery(id, props);
  const services = useServicesListQuery(id, props);

  const isFetching = referrals.isFetching || services.isFetching;
  const data = {
    services: services.data,
    referrals: referrals.data,
  };
  const error = Utils.Helpers.joinStrings(
    [referrals.error?.message, services.error?.message],
    '\n'
  );

  return {
    ...data,
    data,
    isFetching,
    isLoading: isFetching,
    error: typedError ? new Error(error) : error,
    isError: referrals.isError || services.isError,
    refetch: () => {
      if (referrals.isError) {
        referrals.refetch();
      }
      if (services.isError) {
        services.refetch();
      }
    },
  };
};

export const useEmergencyHint = (
  hospitalId?: string,
  dynamicFlow?: boolean
): { showHint: boolean; sharedOnCall: boolean; afterHoursOnCall: boolean } => {
  const hasNeededRole = useAppSelector(
    selectHasRoles([UserRoleType.CSR, ...cvtRoles])
  );

  const { hospital, onCallProtocol } = useOptionalHospitalData(hospitalId);

  const afterHoursOnCall = Boolean(
    hospital?.services?.includes('After hours on call')
  );
  const sharedOnCall = Boolean(
    onCallProtocol?.onCallServices.call_schedule_with_other_clinic
  );
  const showHint = dynamicFlow
    ? false
    : hasNeededRole && (afterHoursOnCall || sharedOnCall);

  return {
    showHint,
    sharedOnCall,
    afterHoursOnCall,
  };
};
