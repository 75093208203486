import { Message } from 'ably';
import debounce from 'lodash.debounce';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import {
  Media,
  Utils,
  ReadFlow,
  useAlert,
  Nullable,
  OfficeChat,
  ChatMessage,
  showMessage,
  OfficeMessage,
  ResponseStatus,
  OfficeChatType,
  AblyChannelName,
  AblyMessageEvent,
  UseMessageSendProps,
  GVSendMessageValues,
} from '@gv/triage-components';

import { Routes } from 'config';
import { uploadMedia } from 'api/upload';
import { useAppDispatch, useAppSelector } from 'store';
import { useCreateMutation } from 'store/api/gv-chats';
import { selectAuthForGVTalk } from 'store/slices/auth';
import { joinRoutes, getMentions } from 'utils/helpers';
import { CreateMessageRequest } from 'store/api/gv-messages/types';
import { HandleEventPayload } from 'store/slices/gv-messages/types';
import {
  useTalkHome,
  useAblyChannel,
  useChatNavigate,
  useDefaultFolder,
  useAblyEventChannel,
} from 'hooks';
import {
  setMessagesThunk,
  addNewMessagesThunk,
  addOldMessagesThunk,
  handleAblyMessageEvent,
} from 'store/slices/gv-messages/thunks';
import {
  addMessage,
  countReplies,
  updateMessage,
  removeMessage,
  deleteMessages,
  readAllMessages,
  selectGvMessages,
} from 'store/slices/gv-messages';
import {
  useReactMutation,
  useReadMessageMutation,
  useDeleteMediaMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useUpdateMessageMutation,
  useReadUntilMessageMutation,
} from 'store/api/gv-messages';

import { useChatParams } from '../useChatParams';

import { getUploadMessage } from './helpers';

const { convertHtmlToDeltaAndText } = Utils.Quill;

const maxResults = 15;

export const useMessageSend = ({
  chat,
  chatId,
  nextId,
  contactId,
  threadedMessageId,
}: UseMessageSendProps) => {
  const { defaultFolderId } = useDefaultFolder();
  const dispatch = useAppDispatch();
  const chatNavigate = useChatNavigate();
  const { handleError } = useAlert();

  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);
  const { authMember } = Utils.Helpers.getOfficeChatProps(chat, gvTalkAuth);

  const failedMessagesInput = useRef<Record<string, GVSendMessageValues>>({});

  const [createChat] = useCreateMutation();
  const [createMessage] = useCreateMessageMutation();

  const { members = [] } = chat || {};
  const threadId = threadedMessageId ? `${chatId}_${threadedMessageId}` : null;

  const allMembersIds = useMemo(
    () =>
      members
        ?.map((member) => member.id)
        .filter((id) => id && id !== gvTalkAuth.authProfileId) || [],
    [members, gvTalkAuth.authProfileId]
  );

  const handleSendMessage = async (
    values: GVSendMessageValues,
    resetForm?: () => void,
    onError?: (error: any) => void
  ) => {
    const sendingId = Date.now();
    let usedChatId: string | undefined;
    let sendingMessage: OfficeMessage | undefined;

    try {
      let newChatId: number | undefined = undefined;
      const { files, message, forwardedMessage } = values;
      const newMessage: CreateMessageRequest = {
        chatId: Number(chatId),
        mentions: getMentions(message, allMembersIds),
      };

      if (threadedMessageId) {
        newMessage.threadedMessageId = threadedMessageId;
      }
      if (!chatId && contactId) {
        console.log('create chat');
        const newChat = await createChat({
          type: OfficeChatType.Chat,
          folderId: defaultFolderId,
          members: [{ profileId: Number(contactId) }],
        }).unwrap();
        newChatId = newChat?.id;
        newMessage.chatId = newChat?.id;
      }
      usedChatId = newChatId
        ? String(newChatId)
        : chatId
          ? String(chatId)
          : undefined;
      sendingMessage = getUploadMessage(sendingId, authMember!, values);
      if (forwardedMessage) {
        sendingMessage.forwardedMessage = {
          ...forwardedMessage,
          chat: {
            workspaceId: 0,
            folder: {
              section: {},
              id: defaultFolderId,
            },
          },
        };
      }

      if (message && message.length) {
        const { text, delta } = convertHtmlToDeltaAndText(message);
        if (text.length) {
          const formatting = JSON.parse(JSON.stringify(delta));
          newMessage.text = text;
          newMessage.formatting = formatting;

          sendingMessage.text = text;
          sendingMessage.formatting = formatting;
        }
      }

      resetForm?.();
      if (nextId && usedChatId) {
        await dispatch(
          setMessagesThunk({
            maxResults,
            threadedMessageId,
            chatId: usedChatId,
            prevId: chat?.lastMessageId!,
          })
        ).unwrap();
      }

      if (!contactId && usedChatId) {
        dispatch(
          addMessage({
            data: sendingMessage,
            chatId: threadId ?? usedChatId,
          })
        );
      }

      if (forwardedMessage) {
        newMessage.forwardedMessageId = Number(forwardedMessage.id);
      }

      const media = await uploadMedia(files);
      if (media) {
        newMessage.medias = media;
      }

      const result = await createMessage(newMessage).unwrap();

      if (newChatId && !chatId) {
        return chatNavigate(newChatId);
      }

      if (usedChatId) {
        await dispatch(
          updateMessage({
            chatId: threadId ?? usedChatId,
            data: { id: sendingId, message: { ...result, isSending: false } },
          })
        );
        if (threadId) {
          dispatch(
            countReplies({
              chatId: usedChatId,
              data: { id: threadedMessageId! },
            })
          );
        }
      }
    } catch (error) {
      handleError(error);
      failedMessagesInput.current[sendingId] = values;
      if (sendingId && sendingMessage) {
        if (usedChatId) {
          dispatch(
            updateMessage({
              chatId: threadId ?? usedChatId,
              data: {
                id: sendingId,
                message: { ...sendingMessage, isError: true, isSending: false },
              },
            })
          );
        }
      }
      onError?.(error);
    }
  };

  const onRetry = async (data: ChatMessage) => {
    if (chatId) {
      const { id } = data;
      dispatch(
        removeMessage({
          data: { id: Number(id) },
          chatId: threadId ?? chatId,
        })
      );

      const values = failedMessagesInput.current[id] ?? {
        files: [],
        message: data.msg_text,
      };
      await handleSendMessage(values);
      delete failedMessagesInput.current[id];
    }
  };

  return { onRetry, allMembersIds, handleSendMessage };
};

export const useMessages = (
  chat?: OfficeChat,
  threadedMessageId?: Nullable<number>,
  onThreadReply?: (val: number) => void
) => {
  const { id, lastMessageId, lastReadMessageId } = chat || {};
  const threadId = threadedMessageId ? `${id}_${threadedMessageId}` : null;
  const messagesId = threadId ?? id;

  const dispatch = useAppDispatch();
  const { handleError } = useAlert();
  const home = useTalkHome();
  const {
    chatId,
    contactId,
    threadId: initialThreadId,
    messageId: initialMessageId,
  } = useChatParams();
  const defaultScrollToBottomRef = useRef<boolean>(!initialMessageId);
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);
  const { authMember } = Utils.Helpers.getOfficeChatProps(chat, gvTalkAuth);
  const {
    prevId,
    nextId,
    isLoading,
    isNewerLoading,
    messages: dataMessages,
  } = useAppSelector(selectGvMessages(messagesId));

  const [readMessage] = useReadMessageMutation();
  const [deleteMedia] = useDeleteMediaMutation();
  const [editMessage] = useUpdateMessageMutation();
  const [react, reactMutation] = useReactMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [readUntilMessage] = useReadUntilMessageMutation();

  const isReactInProgress = reactMutation.status === 'pending';

  const lastId = useMemo(() => {
    if (!dataMessages?.length) {
      return undefined;
    }
    const copy = [...dataMessages];
    const last = copy
      .reverse()
      .find((msg) => !!(msg.id && !msg.isError && !msg.isSending));

    return last?.id;
  }, [dataMessages]);

  useEffect(() => {
    if (!initialMessageId || (initialThreadId && initialMessageId)) {
      defaultScrollToBottomRef.current = true;
    }
  }, [chatId]);

  const onEvent = useCallback(
    (event: Message) => {
      const authProfileId = gvTalkAuth.authProfileId;
      const {
        isPublic,
        isSystem,
        profileId,
        notifyIds,
        chatId: eventChatId,
      } = event.data;

      if (
        chatId &&
        authProfileId &&
        (isSystem || authProfileId !== profileId) &&
        Number(chatId) === eventChatId &&
        (isPublic || notifyIds.includes(authProfileId))
      ) {
        try {
          dispatch(
            handleAblyMessageEvent({
              nextId,
              threadId: threadedMessageId,
              name: event.name as AblyMessageEvent,
              data: event.data as HandleEventPayload['data'],
            })
          ).unwrap();
        } catch (error) {
          handleError(error);
        }
      }
    },
    [dispatch, chatId, gvTalkAuth.authProfileId, threadedMessageId, nextId]
  );

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(Object.values(AblyMessageEvent), onEvent, channel);

  const { onRetry, allMembersIds, handleSendMessage } = useMessageSend({
    chat,
    nextId,
    chatId,
    contactId,
    threadedMessageId,
  });

  const scrollToMessage = useCallback(
    (messageId: number | string, isThread?: boolean) => {
      const prefix = threadId || isThread ? 'thread' : 'message';
      const message = document.getElementById(`${prefix}-${messageId}`);
      if (message) {
        scrollIntoView(message, { block: 'start', behavior: 'smooth' });
      }
      return !!message;
    },
    [threadId]
  );

  const debounceScrollTo = useMemo(
    () => debounce(scrollToMessage, 1000),
    [scrollToMessage]
  );

  const onReact = useCallback(
    async (value: string, msg: ChatMessage) => {
      if (isReactInProgress) {
        return;
      }
      if (chatId) {
        try {
          const msgId = Number(msg.id);
          const isThreaded = !!msg.threadedMessageId;
          const reaction = await react({
            reaction: value,
            chatId: chatId!,
            messageId: msgId,
          }).unwrap();
          let reactions = msg.reactions ? [...msg.reactions] : [];
          if (reaction.status === ResponseStatus.Created) {
            reactions.push(reaction.messageReaction);
          } else {
            const index = reactions.findIndex(
              (val) => val.id === reaction.messageReaction.id
            );
            if (index !== -1) {
              reactions.splice(index, 1);
            }
          }
          dispatch(
            updateMessage({
              chatId: threadId && isThreaded ? threadId : chatId,
              data: {
                id: msgId,
                message: {
                  reactions,
                },
              },
            })
          );
        } catch (error) {
          handleError(error);
        }
      }
    },
    [chatId, chatId, authMember, isReactInProgress, threadId]
  );

  const onMarkUnread = async (msg: ChatMessage) => {
    if (chatId) {
      try {
        const isThreaded = !!msg.threadedMessageId;
        const { status } = await readMessage({
          chatId: chatId!,
          messageId: Number(msg?.id),
        }).unwrap();

        const isRead = status === ResponseStatus.Created;

        dispatch(
          updateMessage({
            chatId: threadId && isThreaded ? threadId : chatId,
            data: { id: Number(msg?.id), message: { isRead } },
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const readUntil = async () => {
    if (chatId && lastId) {
      try {
        const payload = {
          chatId,
          messageId: lastId,
          flow: ReadFlow.Main,
        };
        if (threadedMessageId) {
          payload.flow = ReadFlow.Thread;
          payload.messageId = threadedMessageId;
        }
        await readUntilMessage(payload).unwrap();
        dispatch(
          readAllMessages({
            data: { isRead: true },
            chatId: threadId ? threadId : chatId,
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onDeleteFile = async (file: Media, msg: ChatMessage) => {
    const isThreaded = !!msg.threadedMessageId;
    const sameFile = msg?.groupedMessages?.find(
      (item) => item?.media?.id === file.id
    );

    if (chatId && sameFile) {
      try {
        const deletedMessage = await deleteMedia({
          chatId,
          mediaId: file.id!,
          messageId: sameFile.id,
        }).unwrap();

        dispatch(
          updateMessage({
            chatId: threadId && isThreaded ? threadId : chatId,
            data: {
              id: Number(msg?.id),
              message: deletedMessage,
            },
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onDeleteMessage = async (msg: ChatMessage) => {
    if (chatId) {
      try {
        const isThreaded = !!msg.threadedMessageId;
        const deletedMessage = await deleteMessage({
          chatId,
          messageId: Number(msg?.id),
        }).unwrap();

        dispatch(
          updateMessage({
            chatId: threadId && isThreaded ? threadId : chatId,
            data: { id: Number(msg?.id), message: deletedMessage },
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onPinMessage = async (msg: ChatMessage) => {
    if (chatId) {
      try {
        const isThreaded = !!msg.threadedMessageId;
        const pinned = await editMessage({
          chatId,
          isPinned: !msg.isPinned,
          messageId: Number(msg?.id),
        }).unwrap();

        dispatch(
          updateMessage({
            chatId: threadId && isThreaded ? threadId : chatId,
            data: {
              message: pinned,
              id: Number(msg?.id),
            },
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onEditMessage = async (value: string, msg: ChatMessage) => {
    if (chatId) {
      try {
        const isThreaded = !!msg.threadedMessageId;
        const { text, delta } = convertHtmlToDeltaAndText(value);
        const formatting = JSON.parse(JSON.stringify(delta));
        await editMessage({
          text,
          chatId,
          formatting,
          messageId: Number(msg?.id),
          mentions: getMentions(value, allMembersIds),
        }).unwrap();
        dispatch(
          updateMessage({
            chatId: threadId && isThreaded ? threadId : chatId,
            data: {
              id: Number(msg?.id),
              message: {
                text,
                formatting,
                updatedAt: new Date().toString(),
              },
            },
          })
        );
      } catch (error) {
        handleError(error);
      }
    }
  };

  const onCopyMessageLink = ({
    id: messageId,
    threadedMessageId: thread,
  }: ChatMessage) => {
    const { Office } = Routes;
    navigator.clipboard.writeText(
      Utils.Query.addParamsToUrl(
        window.location.origin +
          joinRoutes(
            [
              home,
              Office.Inbox.Home,
              Office.Inbox.Chat.Home,
              Office.Inbox.Chat.Details,
            ],
            { chatId: chatId! }
          ),
        { threadId: thread, messageId: messageId }
      )
    );
    showMessage('Copied to clipboard');
  };

  const onTopReached = useCallback(() => {
    if (prevId && !isLoading) {
      const payload = {
        prevId,
        maxResults,
        chatId: chatId!,
        threadedMessageId,
      };

      try {
        dispatch(addOldMessagesThunk(payload)).unwrap();
      } catch (error) {
        handleError(error);
      }
    }
  }, [prevId, chatId, isLoading, threadedMessageId]);

  const onBottomReached = useCallback(() => {
    if (nextId && !isLoading && !isNewerLoading) {
      const payload = {
        nextId,
        maxResults,
        chatId: chatId!,
        threadedMessageId,
      };

      try {
        dispatch(addNewMessagesThunk(payload)).unwrap();
      } catch (error) {
        handleError(error);
      }
    }
  }, [nextId, chatId, isLoading, threadedMessageId, isNewerLoading]);

  useEffect(() => {
    if (!chatId || threadId) {
      return;
    }
    return () => {
      dispatch(deleteMessages(chatId));
    };
  }, [chatId]);

  useEffect(() => {
    if (!threadId) {
      return;
    }
    return () => {
      dispatch(deleteMessages(threadId));
    };
  }, [threadId]);

  useEffect(() => {
    if (id && !isLoading && !threadedMessageId) {
      try {
        const midIdInit =
          initialMessageId && !initialThreadId
            ? Number(initialMessageId)
            : undefined;
        const prevIdInit = !midIdInit
          ? lastMessageId || lastReadMessageId
          : undefined;
        dispatch(
          setMessagesThunk({
            maxResults,
            chatId: id,
            midId: midIdInit,
            prevId: prevIdInit,
            scrollToMessage: debounceScrollTo,
          })
        ).unwrap();
      } catch (error) {
        handleError(error);
      }
    }
  }, [id, chatId, contactId]);

  useEffect(() => {
    if (id && !isLoading && threadedMessageId) {
      try {
        const mid = initialMessageId ? Number(initialMessageId) : undefined;
        dispatch(
          setMessagesThunk({
            maxResults,
            chatId: id,
            midId: mid,
            threadedMessageId,
            scrollToMessage: debounceScrollTo,
          })
        ).unwrap();
      } catch (error) {
        handleError(error);
      }
    }
  }, [id, threadedMessageId]);

  useEffect(() => {
    setTimeout(readUntil, 2000);
  }, [dataMessages.length]);

  const onViewMessage = useCallback(
    async (msg: ChatMessage) => {
      const { id: forwardId, threadedMessageId: forwardThread } =
        msg?.forwardedMessage || {};
      const msgThread = forwardThread || msg.threadedMessageId || null;
      const messageId = Number(forwardId || msg.id);

      if (msgThread && onThreadReply) {
        onThreadReply(msgThread);
      }
      if (!scrollToMessage(messageId)) {
        try {
          await dispatch(
            setMessagesThunk({
              chatId: chatId!,
              midId: messageId,
              threadedMessageId: msgThread,
              scrollToMessage: debounceScrollTo,
            })
          ).unwrap();
        } catch (error) {
          handleError(error);
        }
      }
    },
    [chatId]
  );

  return {
    prevId,
    onRetry,
    onReact,
    isLoading,
    onDeleteFile,
    onPinMessage,
    onTopReached,
    onMarkUnread,
    onEditMessage,
    onViewMessage,
    isNewerLoading,
    onBottomReached,
    onDeleteMessage,
    onCopyMessageLink,
    handleSendMessage,
    messages: dataMessages,
    defaultScrollToBottom: defaultScrollToBottomRef.current,
  };
};
