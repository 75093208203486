import { PortalType } from './types';

export const PortalDomain = {
  [PortalType.Team]: 'team-v3',
  [PortalType.Hospital]: 'enterprise',
  [PortalType.PetOwner]: 'myveterinaryhospital',
};

export const PortalLoggerGroup = {
  [PortalType.PetOwner]: 'client-pet-owner',
  [PortalType.Team]: 'client-team-hospital',
  [PortalType.Hospital]: 'client-team-hospital',
};
