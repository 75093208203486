import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, OfficeChat } from '@gv/triage-components';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { saveReplaceChatBanner } from 'utils/storage';

import {
  ChatFile,
  ChatSection,
  ChatListData,
  EndChatRequest,
  MuteChatRequest,
  UnreadCountData,
  VideoCallStatus,
  ChatsListRequest,
  MuteChatResponse,
  FilesListRequest,
  MentionsListData,
  ChatsListResponse,
  CreateChatRequest,
  EditFolderRequest,
  UpdateChatRequest,
  AddMembersRequest,
  FilesListResponse,
  ChatDetailsRequest,
  CreateChatResponse,
  EditSectionRequest,
  UpdateChatResponse,
  AddMembersResponse,
  UnreadCountRequest,
  VideoStatusRequest,
  SectionListResponse,
  ChatDetailsResponse,
  CreateFolderRequest,
  DeleteFolderRequest,
  MentionsListRequest,
  UnreadCountResponse,
  VideoStatusResponse,
  RemoveMembersRequest,
  MentionsListResponse,
  RemoveMembersResponse,
  UpdateMemberRoleRequest,
  CustomerQueueListResponse,
  CustomersQueueCountResponse,
} from './types';

const { Query, Helpers } = Utils;

const gvChatsAdapter = createEntityAdapter<ChatSection, number>({
  selectId: (item) => item.id,
});

const tagTypes = ['GVChats'];

export const gvChatsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'gvChatsApi',
  endpoints: (build) => ({
    delete: build.mutation<void, number>({
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_CHAT, { id }),
      }),
    }),

    customersQueueCount: build.query<CustomersQueueCountResponse, void>({
      providesTags: tagTypes,
      query: () => ({ method: 'get', url: URL.GET_CUSTOMERS_QUEUE_COUNT }),
    }),

    placeBack: build.mutation<void, number>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'post',
        url: generateUrl(URL.PLACE_BACK_CHAT_IN_QUEUE, { id }),
      }),
    }),

    takeChat: build.mutation<void, number>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'post',
        url: generateUrl(URL.TAKE_CHAT_FROM_CUSTOMERS_QUEUE, { id }),
      }),
    }),

    customersQueueList: build.query<OfficeChat[], void>({
      providesTags: tagTypes,
      query: () => ({ method: 'get', url: URL.GET_CUSTOMERS_QUEUE_LIST }),
      transformResponse: (response: CustomerQueueListResponse) => response.data,
    }),

    sectionsList: build.query<ChatSection[], {} | void>({
      providesTags: tagTypes,
      transformResponse: (response: SectionListResponse) => response.data,
      query: () => {
        return {
          method: 'get',
          url: URL.GET_GV_TALK_SECTIONS,
        };
      },
    }),

    filesList: build.query<ChatFile[], FilesListRequest>({
      transformResponse: (response: FilesListResponse) => response.data,
      query: (data) => {
        return {
          method: 'get',
          url: generateUrl(URL.GET_GV_TALK_CHAT_FILES, { chatId: data.id }),
        };
      },
    }),

    endChat: build.mutation<OfficeChat, EndChatRequest>({
      invalidatesTags: tagTypes,
      transformResponse: (response: MuteChatResponse) => response.data,
      query: ({ id }) => ({
        method: 'patch',
        url: generateUrl(URL.END_GV_TALK_CHAT, { chatId: String(id) }),
      }),
    }),

    videoStatus: build.query<VideoCallStatus, VideoStatusRequest>({
      transformResponse: (response: VideoStatusResponse) => response.data,
      query: ({ chatId }) => {
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.VIDEO_CALL_STATUS, {
            chatId,
          }),
        };
      },
    }),

    editSection: build.mutation<void, EditSectionRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      query: ({ id, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: generateUrl(URL.UPDATE_GV_TALK_SECTION, {
            sectionId: String(id),
          }),
        };
      },
    }),

    deleteFolder: build.mutation<void, DeleteFolderRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      query: ({ id, section }) => {
        return {
          method: 'delete',
          url: generateUrl(URL.DELETE_GV_TALK_FOLDER, {
            folderId: id,
            sectionId: section,
          }),
        };
      },
    }),

    createFolder: build.mutation<void, CreateFolderRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      query: ({ section, ...rest }) => {
        return {
          data: rest,
          method: 'post',
          url: generateUrl(URL.CREATE_GV_TALK_FOLDER, {
            sectionId: String(section),
          }),
        };
      },
    }),

    editFolder: build.mutation<void, EditFolderRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      query: ({ id, section, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: generateUrl(URL.UPDATE_GV_TALK_FOLDER, {
            folderId: id,
            sectionId: section,
          }),
        };
      },
    }),

    unreadCount: build.query<UnreadCountData, UnreadCountRequest>({
      transformResponse: (response: UnreadCountResponse) => response.data,
      query: ({ page, chatId } = {}) => {
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.GET_GV_TALK_CHAT_UNREAD_COUNT, {
            page: page,
            chatId: chatId,
          }),
        };
      },
    }),

    mute: build.mutation<OfficeChat, MuteChatRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: MuteChatResponse) => response.data,
      query: ({ chatId }) => {
        return {
          method: 'patch',
          url: generateUrl(URL.MUTE_GV_TALK_CHAT, {
            chatId: String(chatId),
          }),
        };
      },
    }),

    details: build.query<OfficeChat, ChatDetailsRequest>({
      keepUnusedDataFor: 1,
      providesTags: tagTypes,
      transformResponse: (response: ChatDetailsResponse) => response.data,
      query: ({ id }) => {
        return {
          method: 'get',
          url: generateUrl(URL.GET_GV_TALK_CHAT_DETAILS, {
            chatId: String(id),
          }),
        };
      },
    }),

    updateMemberRole: build.mutation<void, UpdateMemberRoleRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      query: ({ role, chatId, profileId }) => {
        return {
          data: { role },
          method: 'patch',
          url: generateUrl(URL.UPDATE_GV_TALK_CHAT_MEMBER_ROLE, {
            chatId: chatId,
            profileId: profileId,
          }),
        };
      },
    }),

    update: build.mutation<OfficeChat, UpdateChatRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: UpdateChatResponse) => response.data,
      query: ({ chatId, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: generateUrl(URL.UPDATE_GV_TALK_CHAT, {
            chatId: String(chatId),
          }),
        };
      },
    }),

    addMembers: build.mutation<OfficeChat, AddMembersRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: AddMembersResponse) => response.data,
      query: ({ chatId, ...rest }) => {
        return {
          data: rest,
          method: 'post',
          url: generateUrl(URL.ADD_GV_TALK_CHAT_MEMBERS, {
            chatId: String(chatId),
          }),
        };
      },
    }),

    removeMembers: build.mutation<OfficeChat, RemoveMembersRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: RemoveMembersResponse) => response.data,
      query: ({ chatId, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: generateUrl(URL.REMOVE_GV_TALK_CHAT_MEMBERS, {
            chatId: String(chatId),
          }),
        };
      },
    }),

    mentionsList: build.query<MentionsListData, MentionsListRequest>({
      keepUnusedDataFor: 1,
      transformResponse: (response: MentionsListResponse) => response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);

        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.GET_GV_TALK_CHAT_MENTIONS, {
            limit,
            offset,
            search,
          }),
        };
      },
    }),

    create: build.mutation<OfficeChat, CreateChatRequest>({
      invalidatesTags: (result) => (result ? tagTypes : []),
      transformResponse: (response: CreateChatResponse) => response.data,
      query: (body) => {
        return {
          data: body,
          method: 'post',
          url: URL.CREATE_GV_TALK_CHAT,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            saveReplaceChatBanner(data);
          }
        } catch {}
      },
    }),

    list: build.query<ChatListData, ChatsListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: ChatsListResponse) => response.data,
      query: ({ type, search, folderId, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);
        const searchParams: Record<string, any> = {
          type,
          limit,
          offset,
          search,
          folderId,
        };
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.GET_GV_TALK_CHATS, searchParams),
        };
      },
    }),
  }),
});

export const gvChatsSlice = createSlice({
  reducers: {},
  name: 'gvChats',
  initialState: gvChatsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      gvChatsApi.endpoints.sectionsList.matchFulfilled,
      (state, { payload }) => {
        gvChatsAdapter.setAll(state, payload);
      }
    );
  },
});

export const {
  useListQuery,
  useMuteMutation,
  useDetailsQuery,
  useLazyListQuery,
  useFilesListQuery,
  useDeleteMutation,
  useCreateMutation,
  useUpdateMutation,
  useEndChatMutation,
  useLazyDetailsQuery,
  useTakeChatMutation,
  usePlaceBackMutation,
  useMentionsListQuery,
  useSectionsListQuery,
  useEditFolderMutation,
  useAddMembersMutation,
  useEditSectionMutation,
  useLazyVideoStatusQuery,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useLazyUnreadCountQuery,
  useLazySectionsListQuery,
  useRemoveMembersMutation,
  useCustomersQueueListQuery,
  useCustomersQueueCountQuery,
  useUpdateMemberRoleMutation,
  useLazyCustomersQueueListQuery,
  useLazyCustomersQueueCountQuery,
} = gvChatsApi;
