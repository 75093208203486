import { Call } from '@twilio/voice-sdk';
import { NumPadKey } from '@gv/triage-components';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { CallStatus } from 'types';

const MappedStatuses = {
  [Call.State.Open]: CallStatus.InProgress,
  [Call.State.Ringing]: CallStatus.Ringing,
  [Call.State.Closed]: CallStatus.Completed,
  [Call.State.Pending]: CallStatus.Initiated,
  [Call.State.Connecting]: CallStatus.Ringing,
  [Call.State.Reconnecting]: CallStatus.Initiated,
};

export const useCallStatus = (call?: Call, onError?: (error: any) => void) => {
  const [status, setStatus] = useState<CallStatus | undefined>();
  const [startedAt, setStartedAt] = useState<Date | undefined>();

  const setCallState = useCallback((state?: Call.State) => {
    if (!state) {
      setStatus(undefined);
      return;
    }
    setStatus(MappedStatuses[state]);
  }, []);

  useEffect(() => {
    if (!call) {
      setStatus(undefined);
      return;
    }
    setCallState(call.status());
    const intervalId = setInterval(() => {
      setCallState(call?.status());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [call, setCallState]);

  useEffect(() => {
    if (!call) {
      return;
    }
    const onAccept = () => setStatus(CallStatus.InProgress);
    const onReject = () => setStatus(CallStatus.Completed);
    const onDisconnect = () => setStatus(CallStatus.Completed);
    const handleError = (error: any) => {
      onError?.(error);
    };
    call.on('accept', onAccept);
    call.on('reject', onReject);
    call.on('error', handleError);
    call.on('disconnect', onDisconnect);
    return () => {
      call.off('accept', onAccept);
      call.off('reject', onReject);
      call.off('error', handleError);
      call.off('disconnect', onDisconnect);
    };
  }, [call]);

  useEffect(() => {
    if (status === CallStatus.InProgress) {
      setStartedAt(new Date());
    } else if (!status) {
      setStartedAt(undefined);
    }
  }, [status]);

  const props = useMemo(() => {
    const active = status === CallStatus.InProgress;
    const buttons: NumPadKey[] = active ? [] : Array.from(['*', '#']);
    return {
      inProgress: active,
      disabledButtons: buttons,
    };
  }, [status]);

  return { status, startedAt, ...props };
};
