import styled from 'styled-components';
import { Icon, FlexLayout } from '@gv/triage-components';

export const CustomWaitTimesContainer = styled(FlexLayout)`
  gap: 16px;
  padding: 16px;
  flex-direction: column;
`;

export const WaitTimesIcon = styled(Icon.Clock)`
  path {
    fill: ${({ theme }) => theme.config.common.icon};
  }
`;
