import styled, { css } from 'styled-components';

import { PetsList } from '../pets';

export const Pets = styled(PetsList)<{ withMargin?: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 24px;
    `}
`;

export const ListWrap = styled.div`
  overflow-y: hidden;
`;
