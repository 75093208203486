import * as Yup from 'yup';
import {
  Nullable,
  Messages,
  CurrentChatAction,
  CurrentChatActionProps,
} from '@gv/triage-components';

import { DialogFormValues } from './types';

export const suggestedNames = ['Pet owners', 'Consultations', 'Clients'];

export const getInitialValues = (
  activeDialog: CurrentChatActionProps
): DialogFormValues => {
  const { event, folder, section } = activeDialog;

  return event === CurrentChatAction.EditSection
    ? { name: section?.name, icon: section?.icon ?? undefined }
    : { icon: undefined, name: folder?.name };
};

export const getValidationSchema = (activeDialog: CurrentChatActionProps) => {
  const nameSchema = Yup.string().required();

  if (activeDialog.event === CurrentChatAction.DeleteFolder) {
    return undefined;
  }

  return Yup.object().shape(
    activeDialog.event === CurrentChatAction.EditSection
      ? { name: nameSchema, icon: Yup.string().required() }
      : { name: nameSchema }
  );
};

export const getMessage = (event: Nullable<CurrentChatAction>) => {
  switch (event) {
    case CurrentChatAction.AddFolder:
      return `Folder ${Messages.ADDED_SUCCESSFULLY}`;
    case CurrentChatAction.EditFolder:
      return `Folder ${Messages.UPDATED_SUCCESSFULLY}`;
    case CurrentChatAction.DeleteFolder:
      return `Folder ${Messages.DELETED_SUCCESSFULLY}`;
    case CurrentChatAction.EditSection:
      return `Section ${Messages.UPDATED_SUCCESSFULLY}`;
    default:
      return '';
  }
};
