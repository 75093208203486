import { useContext } from 'react';
import { Icon, ButtonColors, StandardButton } from '@gv/triage-components';

import { AudioContext } from '.';

export const RecordPlay = () => {
  const { src, state, controls } = useContext(AudioContext);

  return (
    <StandardButton
      disabled={!src}
      icon={state?.paused ? <Icon.Play /> : <Icon.Pause />}
      onClick={state?.paused ? controls?.play : controls?.pause}
      colorType={
        state?.paused ? ButtonColors.LightGreen : ButtonColors.LightYellow
      }
    />
  );
};
