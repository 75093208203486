import { useField } from 'formik';
import { useMemo, useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import {
  Switch,
  Checkbox,
  FlexLayout,
  useNavigate,
  ButtonColors,
  ErrorMessage,
  ActionQueueType,
} from '@gv/triage-components';

import { Routes } from 'config';
import { isWideScreenHook } from 'hooks';
import { PetsListGlobal } from 'components/pets';

import * as Styles from './styles';
import { PetFieldProps } from './types';

export const PetField = ({
  name,
  task,
  client,
  basePath,
  editPath,
  checkbox,
  medicalRecordsPath,
  createHospitalTaskView,
}: PetFieldProps) => {
  const navigate = useNavigate();
  const isWideScreen = isWideScreenHook();

  const [petsField, petsMeta, petsHelpers] = useField<number[]>(name);

  const petsValue = useMemo(() => {
    if (petsField.value) {
      return petsField.value;
    }
    return [];
  }, [petsField.value]);

  const getPath = useCallback(
    (path: string, id: number) =>
      generatePath(`${basePath}${path}`, {
        petId: String(id),
      }),
    []
  );

  const list = useMemo(() => {
    if (!client) {
      return null;
    }
    return (
      <PetsListGlobal
        data={client.pets}
        clientId={String(client.id)}
        editPath={(petId) => getPath(editPath, petId)}
        createHospitalTaskView={createHospitalTaskView}
        forceMobileView={
          task && (task.type !== ActionQueueType.Voice || !isWideScreen)
        }
        medicalRecordsPath={
          medicalRecordsPath
            ? (petId) => getPath(medicalRecordsPath, petId)
            : undefined
        }
        prefixRow={{
          header: [''],
          template: checkbox ? '24px' : '48px',
          values: (pet) => {
            const commonProps = {
              checked: petsValue.includes(pet.id),
              key: `${checkbox ? 'checkbox' : 'switch'}-${pet.id}`,
              onChange: async () => {
                if (petsValue.includes(pet.id)) {
                  await petsHelpers.setValue(
                    petsValue.filter((id) => id !== pet.id)
                  );
                } else {
                  await petsHelpers.setValue([...petsValue, pet.id]);
                }
              },
            };
            return [
              checkbox ? (
                <Checkbox {...commonProps} />
              ) : (
                <Switch {...commonProps} />
              ),
            ];
          },
        }}
      />
    );
  }, [getPath, client?.pets, petsValue]);

  if (!client) {
    return null;
  }

  const addButton = (
    <Styles.AddNewPetButton
      text="Add new pet"
      colorType={ButtonColors.Secondary}
      transparent={createHospitalTaskView}
      padding={createHospitalTaskView ? undefined : '8px 34px'}
      onClick={() =>
        navigate(
          `${basePath}${
            createHospitalTaskView
              ? `/${Routes.Clients.Details.New}`
              : Routes.ActionCenter.InComm.NewPet
          }`
        )
      }
    />
  );

  return (
    <FlexLayout gap={24} flexDirection="column">
      <div>
        <FlexLayout
          gap={8}
          minWidth={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <p className="semibold">
            Pets associated with the {createHospitalTaskView ? 'task' : 'case'}{' '}
            (<Styles.PetsNumber>{petsValue.length}</Styles.PetsNumber>)
          </p>

          {createHospitalTaskView && addButton}
        </FlexLayout>
        {list}
        {petsMeta.touched && petsMeta.error && (
          <ErrorMessage>Please select at least one pet</ErrorMessage>
        )}
      </div>

      {!createHospitalTaskView && addButton}
    </FlexLayout>
  );
};
