import { FlexLayout } from '@gv/triage-components';

import { subscriptionPlan } from 'config';

import * as Styles from './styles';
import { BillCardProps } from './types';

export const BillCard = ({
  price,
  headerSlot,
  currentPlan,
  priceSubtitle,
}: BillCardProps) => {
  return (
    <Styles.Container>
      <FlexLayout gap={16} flexDirection="column">
        {headerSlot}

        <div>
          <h1>{price}</h1>
          {priceSubtitle && <p>{priceSubtitle}</p>}
        </div>
      </FlexLayout>

      {Object.entries(subscriptionPlan).map(([title, items]) => (
        <Styles.PlanCardSection key={title}>
          <Styles.Title>{title}</Styles.Title>

          <FlexLayout gap={4} flexDirection="column">
            {Object.entries(items).map(([serviceTitle, plans]) => (
              <FlexLayout gap={8} key={serviceTitle} alignItems="center">
                <span>{serviceTitle}</span>

                <Styles.Dashes />

                <span className="semibold">
                  {plans[currentPlan] === '-'
                    ? 'Not included'
                    : plans[currentPlan]}
                </span>
              </FlexLayout>
            ))}
          </FlexLayout>
        </Styles.PlanCardSection>
      ))}
    </Styles.Container>
  );
};
