import { Badge, BadgePadding } from '@gv/triage-components';

import { EntityPortalTypeText, EntityPortalTypeColor } from 'types/data';

import { TaskSourceBadgeProps } from './types';

export const TaskSourceBadge = ({
  isBig,
  portalType,
}: TaskSourceBadgeProps) => (
  <div>
    <Badge
      text={EntityPortalTypeText[portalType]}
      styleType={EntityPortalTypeColor[portalType]}
      padding={isBig ? BadgePadding.Big : BadgePadding.Default}
    />
  </div>
);
