import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: { isLoading: false },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

const {
  reducer: loaderReducer,
  actions: { setLoading },
} = loaderSlice;

const loaderStore = (store: RootState) => store?.loader;

const selectIsLoading = createSelector(
  [loaderStore],
  (store) => store?.isLoading ?? false
);

export { setLoading, loaderReducer, selectIsLoading };
