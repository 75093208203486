import { useField } from 'formik';
import { useMemo, useEffect } from 'react';
import {
  Row,
  Col,
  AddClient,
  ClientForm,
  useNavigate,
  ErrorMessage,
  ClientFormProps,
} from '@gv/triage-components';

import { Routes } from 'config';
import { CallTab } from 'types';
import { clientAddress, getClientEmails } from 'utils/helpers';

import { FieldProps } from './types';
import { useFormEntities } from './use-form-entities';

export const ClientField = ({ task }: FieldProps) => {
  const { client } = useFormEntities();
  const navigate = useNavigate();

  const basePath = `${Routes.ActionCenter.Comm}/${CallTab.Consultation}`;

  const [nameField, nameMeta, nameHelpers] = useField('isNameConfirm');
  const [phoneField, phoneMeta, phoneHelpers] = useField('isNumberConfirm');
  const [, clientMeta] = useField('client_id');
  const [, hasClientZipCodeMeta, hasClientZipCodeHelpers] =
    useField('hasClientZipCode');
  const error =
    (clientMeta.touched && clientMeta.error) ||
    (nameMeta.touched && nameMeta.error) ||
    (phoneMeta.touched && phoneMeta.error) ||
    (hasClientZipCodeMeta.touched && hasClientZipCodeMeta.error);

  const isNameConfirmed = nameField.value ?? false;
  const isPhoneConfirmed = phoneField.value ?? false;

  useEffect(() => {
    hasClientZipCodeHelpers.setValue(!!client?.zip_code);
  }, [client?.zip_code]);

  const clientProps = useMemo<Partial<ClientFormProps>>(() => {
    if (!client) {
      return {};
    }
    return {
      name: client.name,
      phones: client.phones,
      mainPhone: client.phone,
      homePhones: client.home_phones,
      emails: getClientEmails(client),
      address: clientAddress(client) ?? undefined,
    };
  }, [client]);

  const clientNode = useMemo(() => {
    return task?.c_id && task.c_id > 0 ? (
      <ClientForm
        {...clientProps}
        isNameConfirmed={isNameConfirmed}
        isPhoneConfirmed={isPhoneConfirmed}
        onEdit={() =>
          navigate(`${basePath}${Routes.ActionCenter.InComm.EditClient}`)
        }
        setNameConfirmed={async (value) => {
          await nameHelpers.setValue(value);
          nameHelpers.setTouched(true);
        }}
        setPhoneConfirmed={async (value) => {
          await phoneHelpers.setValue(value);
          phoneHelpers.setTouched(true);
        }}
      />
    ) : (
      <AddClient
        phone={task?.from ?? ''}
        onAdd={() =>
          navigate(`${basePath}${Routes.ActionCenter.InComm.AddClient}`)
        }
        onSearch={() =>
          navigate(`${basePath}${Routes.ActionCenter.InComm.SearchClient}`)
        }
      />
    );
  }, [
    navigate,
    task?.c_id,
    task?.from,
    clientProps,
    isNameConfirmed,
    isPhoneConfirmed,
  ]);

  return (
    <Row>
      <Col>
        {clientNode}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Col>
    </Row>
  );
};
