import {
  Dialog,
  useAlert,
  ChildrenProps,
  StandardButton,
} from '@gv/triage-components';
import {
  useMemo,
  Dispatch,
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
  createContext,
} from 'react';
import ZoomVideo, {
  ConnectionState,
  ReconnectReason,
  MediaSDKEncDecPayload,
  ConnectionChangePayload,
} from '@zoom/videosdk';

import { UserActionsContext } from 'context/user-actions';
import { SELF_VIDEO_ID } from 'components/video-call/constants';

import { isIOSMobile } from './helpers';
import { mediaShape, mediaReducer } from './media-reducer';
import {
  ZoomClient,
  MediaStream,
  ConnectionStatus,
  MediaContextProps,
  AvatarContextProps,
  ZoomMediaProviderProps,
} from './types';

export const MediaContext = createContext<MediaContextProps>(null as any);

export const ZoomContext = createContext<ZoomClient>(null as any);

export const AvatarActionContext = createContext<{
  dispatch: Dispatch<any>;
  avatarActionState: AvatarContextProps;
}>(null as any);

export const isVideoAvailable = () =>
  ZoomVideo.checkSystemRequirements().video &&
  ZoomVideo.checkSystemRequirements().audio;

export const ZoomContextProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const [zoomClient] = useState<ZoomClient>(ZoomVideo.createClient());

  useEffect(() => {
    return () => {
      ZoomVideo.destroyClient();
    };
  }, []);

  return (
    <ZoomContext.Provider value={zoomClient}>{children}</ZoomContext.Provider>
  );
};

export const ZoomMediaProvider = ({
  children,
  onEndedByHost,
}: ChildrenProps<ZoomMediaProviderProps>): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Connecting...');
  const [status, setStatus] = useState<ConnectionStatus>('closed');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] =
    useState<boolean>(true);
  const [isUserActionRequired, setUserActionRequired] = useState(false);
  const [isMicrophoneForbidden, setMicrophoneForbidden] = useState(false);
  const [isCameraForbidden, setCameraForbidden] = useState(false);
  const [expiredAt, setExpiredAt] = useState(0);

  const enforceGalleryView = false;
  const galleryViewWithoutSAB =
    enforceGalleryView && !window.crossOriginIsolated;

  const { isUserClickHappened } = useContext(UserActionsContext);
  const zoomClient = useContext(ZoomContext);

  const [isStartedAudio, setStartedAudio] = useState(
    zoomClient.getCurrentUserInfo() &&
      zoomClient.getCurrentUserInfo().audio !== ''
  );

  const [isStartedVideo, setStartedVideo] = useState(
    zoomClient.getCurrentUserInfo()?.bVideoOn
  );

  const { handleError } = useAlert();

  const handleZoomError = useCallback(
    (error: unknown) => {
      console.error(error);
      let errorData: any;
      if (error && typeof error === 'object' && 'reason' in error) {
        errorData = error.reason;
      } else {
        errorData = error;
      }
      handleError(errorData);
    },
    [handleError]
  );

  const onLeaveSession = useCallback(async () => {
    console.log('leave call', status);
    if (status === 'connected') {
      if (zoomClient.isHost()) {
        await zoomClient.leave(true);
      } else {
        await zoomClient.leave();
      }
    }
  }, [status, zoomClient]);

  useEffect(() => {
    if (isUserActionRequired && isUserClickHappened) {
      setUserActionRequired(false);
    }
  }, [isUserClickHappened, isUserActionRequired]);

  const startVideo = useCallback(async () => {
    console.log('start video');
    try {
      const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
      if (
        mediaStream?.isRenderSelfViewWithVideoElement() &&
        !temporaryException
      ) {
        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`
        ) as HTMLVideoElement;
        if (videoElement) {
          await mediaStream?.startVideo({ videoElement });
        }
      } else {
        const startVideoOptions = {
          hd: true,
          ptz: mediaStream?.isBrowserSupportPTZ(),
        };
        await mediaStream?.startVideo(startVideoOptions);

        if (!mediaStream?.isSupportMultipleVideos()) {
          const canvasElement = document.querySelector(
            `#${SELF_VIDEO_ID}`
          ) as HTMLCanvasElement;
          try {
            await mediaStream?.renderVideo(
              canvasElement,
              zoomClient.getSessionInfo().userId,
              canvasElement.width,
              canvasElement.height,
              0,
              0,
              3
            );
          } catch (error) {
            console.error(error);
          }
        }
        setStartedVideo(true);
      }
    } catch (error: any) {
      if (error.type === 'VIDEO_USER_FORBIDDEN_CAPTURE') {
        setCameraForbidden(true);
      } else {
        handleZoomError(error);
      }
    }
  }, [zoomClient, mediaStream, handleZoomError]);

  const startAudio = useCallback(async () => {
    if (!mediaStream) {
      return;
    }
    try {
      await mediaStream.startAudio();
      setStartedAudio(true);
    } catch (error: any) {
      if (
        error.type === 'INSUFFICIENT_PRIVILEGES' &&
        error.reason === 'USER_FORBIDDEN_MICROPHONE'
      ) {
        setMicrophoneForbidden(true);
      } else {
        handleZoomError(error);
      }
    }
  }, [mediaStream, handleZoomError]);

  useEffect(() => {
    if (!mediaStream || !isUserClickHappened) {
      return;
    }
    startVideo();
  }, [mediaStream, startVideo]);

  useEffect(() => {
    if (!mediaStream) {
      return;
    }

    if (!isUserClickHappened) {
      setUserActionRequired(true);
      return;
    }

    if (!(mediaState.audio.encode && mediaState.audio.decode)) {
      return;
    }

    try {
      console.log('start audio', mediaState.audio);
      startAudio();
    } catch (error) {
      console.error(error);
    }
  }, [
    startAudio,
    mediaStream,
    startVideo,
    isUserClickHappened,
    mediaState.audio.encode,
    mediaState.audio.decode,
  ]);

  useEffect(() => {
    if (!mediaStream) {
      return;
    }
    return () => {
      const disconnect = async () => {
        try {
          await mediaStream.stopAudio();
        } catch (error) {
          console.error(error);
        }

        try {
          await mediaStream.stopVideo();
        } catch (error) {
          console.error(error);
        }
      };
      disconnect();
    };
  }, [mediaStream]);

  const join = useCallback(
    async (topic: string, signature: string, name: string) => {
      console.log('join call');
      await zoomClient.init('en-US', 'CDN', {
        stayAwake: true,
        webEndpoint: 'zoom.us',
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: galleryViewWithoutSAB,
      });
      setLoading(true);
      setLoadingText('Joining the session...');
      await zoomClient.join(topic, signature, name);
      const stream = zoomClient.getMediaStream();
      setMediaStream(stream);

      setIsSupportGalleryView(stream.isSupportMultipleVideos());
      setLoading(false);
    },
    [zoomClient, galleryViewWithoutSAB]
  );

  const mediaContext = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream]
  );

  const onConnectionChange = useCallback(
    (payload: ConnectionChangePayload) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected, Try to reconnect');
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setLoading(false);
        }

        console.log('getSessionInfo', zoomClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          onEndedByHost();
        }
      }
    },
    [isFailover, zoomClient, onEndedByHost]
  );

  const onMediaSDKChange = useCallback((payload: MediaSDKEncDecPayload) => {
    const { type, action, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  useEffect(() => {
    zoomClient.on('media-sdk-change', onMediaSDKChange);
    zoomClient.on('connection-change', onConnectionChange);
    return () => {
      zoomClient.off('media-sdk-change', onMediaSDKChange);
      zoomClient.off('connection-change', onConnectionChange);
    };
  }, [zoomClient, onMediaSDKChange, onConnectionChange]);

  return (
    <MediaContext.Provider
      value={{
        join,
        isLoading,
        expiredAt,
        zoomClient,
        startVideo,
        loadingText,
        setExpiredAt,
        onLeaveSession,
        isStartedAudio,
        isStartedVideo,
        handleZoomError,
        setStartedAudio,
        setStartedVideo,
        isCameraForbidden,
        media: mediaContext,
        isSupportGalleryView,
        isMicrophoneForbidden,
        galleryViewWithoutSAB,
      }}
    >
      {children}

      {isUserActionRequired && (
        <Dialog
          open
          closeOnEscape={false}
          closeOnConfirm={false}
          title="Action required"
          closeOnDocumentClick={false}
          onClose={() => setUserActionRequired(false)}
          text="Click the button below to enable audio"
          buttons={(close) => (
            <StandardButton fullWidth onClick={close} text="Enable audio" />
          )}
        />
      )}
    </MediaContext.Provider>
  );
};
