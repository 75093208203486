import { Config } from 'config';

/* eslint-disable perfectionist/sort-objects */
let URL = {
  GUARDIANVETS_SUPPORT: 'https://guardianvets.com/contact-us/',

  LOGIN: `guardianvets/v1/authentication/login`,
  ONE_LOGIN: `guardianvets/v1/authentication/onelogin`,
  FORGOT_PASSWORD: `guardianvets/v1/authentication/forget`,
  RECOVER_PASSWORD: `guardianvets/v1/authentication/recover`,

  CHANGE_PASSWORD: 'guardianvets/v3/authentication/password',

  GET_CITIES: `guardianvets/v1/preferences/cities/{id}`,
  GET_ROLES: 'guardianvets/v3/common/roles',
  GET_VERSION: 'guardianvets/v3/build-version?source=triage',

  UPLOAD_MULTIMEDIA: `guardianvets/v1/preferences/uploadMultiMedia`,

  VERIFY_OTP: `guardianvets/v1/authentication/verify-otp`,
  SEND_VERIFICATION_OTP: `guardianvets/v1/authentication/send-verification-otp`,

  GET_HOSPITAL_INFO_BY_TOKEN: `guardianvets/v1/common/hospital/info/token`,
  UPDATE_HOSPITAL_OVERVIEW: `guardianvets/v1/preferences/general/overview`,

  ENABLE_GV_COMM: `guardianvets/v1/preferences/gv/enabled`,

  CHECK_SLACK_INTEGRATION:
    'guardianvets/v1/preferences/general/overview/slack-msg',
  CHECK_SLACK_FILE_SHARING:
    'guardianvets/v1/preferences/general/overview/slack-file',
  VERIFY_PHONE_NUMBER:
    'guardianvets/v1/preferences/general/overview/verify-callerid',

  GET_HOSPITAL_INFO_REFERAL: `guardianvets/v1/preferences/referral/info`,
  ADD_HOSPITAL_INFO_REFERAL: 'guardianvets/v1/preferences/referral/info',
  UPDATE_HOSPITAL_INFO_REFERAL: 'guardianvets/v1/preferences/referral/info',
  DELETE_HOSPITAL_ER_REFERAL: `guardianvets/v1/preferences/referral/:id`,

  // hospital emergency services
  GET_ER_SERVICES: 'guardianvets/v3/hospitals/:hospitalId/emergency/services',
  GET_ER_SERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId',
  CREATE_ER_SERVICE: 'guardianvets/v3/hospitals/:hospitalId/emergency/services',
  UPDATE_ER_SERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId',
  DELETE_ER_SERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId',

  GET_ER_SUBSERVICES:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId/services',
  GET_ER_SUBSERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId/services/:subId',
  CREATE_ER_SUBSERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId/services',
  UPDATE_ER_SUBSERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId/services/:subId',
  DELETE_ER_SUBSERVICE:
    'guardianvets/v3/hospitals/:hospitalId/emergency/services/:serviceId/services/:subId',

  GET_HOSPITAL_TRANSFER_OPTIONS:
    'guardianvets/v3/hospitals/:hospitalId/transfer-options',

  // call flows
  GET_CALL_FLOWS: 'guardianvets/v1/hospital/:hospitalId/call-flows',
  GET_CALL_FLOW: 'guardianvets/v1/hospital/:hospitalId/call-flows/:flowId',
  CREATE_CALL_FLOW: 'guardianvets/v1/hospital/:hospitalId/call-flows',
  UPDATE_CALL_FLOW: 'guardianvets/v1/hospital/:hospitalId/call-flows/:flowId',
  DELETE_CALL_FLOW: 'guardianvets/v1/hospital/:hospitalId/call-flows/:flowId',

  // role permissions

  GET_ROLES_PERMISSIONS:
    'guardianvets/v3/hospitals/:hospitalId/team/permissions',
  GET_ROLE_PERMISSIONS:
    'guardianvets/v3/hospitals/:hospitalId/team/permissions/:roleId',
  CREATE_ROLE_PERMISSIONS:
    'guardianvets/v3/hospitals/:hospitalId/team/permissions',
  UPDATE_ROLE_PERMISSIONS:
    'guardianvets/v3/hospitals/:hospitalId/team/permissions/:roleId',

  // practice management
  GET_PIMS_ID: `guardianvets/v1/preferences/pims?hospital_id={hospital_id}`,
  SET_PIMS_ID_SYNC: `guardianvets/v1/preferences/pims`,

  // for telemedicine in preference tab
  GET_CONSULTATION_TYPE:
    'preferences/telemedicine/consultancy?hospital_id={hospital_id}',
  ADD_CONSULTATION_TYPE: 'guardianvets/v1/preferences/telemedicine/consultancy',
  UPDATE_CONSULTATION_TYPE:
    'guardianvets/v1/preferences/telemedicine/consultancy',
  ADD_VCPR: 'guardianvets/v1/preferences/vcpr',
  UPDATE_VCPR: 'guardianvets/v1/preferences/vcpr',
  GET_VCPR: 'guardianvets/v1/preferences/vcpr?hospital_id={hospital_id}',
  GET_PIMS_EDITABLE_RESOURCES:
    'guardianvets/v1/common/preference/editable-pims-sources',

  // preferences on call contact tab
  GET_PREFERENCES_ON_CALL_PROTOCOL: `guardianvets/v1/preferences/on-call-protocol?hospital_id={hospital_id}`,
  UPDATE_PREFERENCES_ON_CALL_PROTOCOL: `guardianvets/v1/preferences/on-call-protocol`,

  DEVICE_CAPABILITY_TOKEN: `guardianvets/v1/gvComm/capability/token`,

  SEND_SMS_NORMAL: `guardianvets/v1/gvComm/send/custom/sms`,

  GET_ALL_DVMS: `guardianvets/v1/hospital/dvm?hospital_id={hospital_id}`,

  CHAT_CLIENT_TOKEN: `guardianvets/v1/gvComm/chat/token?email={email}&room_name={room_name}`,

  CHAT_PICKED: `guardianvets/v1/gvComm/chat/channel/join`,

  SEARCH_CONVERSATION: `guardianvets/v1/gvComm/chat/channel/search?keyword={keyword}&user_id={user_id}&status={status}&offset={offset}&limit={limit}`,

  PLACE_CHAT_IN_QUEUE: `guardianvets/v1/gvComm/chat/placeBack`,

  PLACE_SMS_IN_QUEUE: `guardianvets/v1/gvComm/sms/placeBack`,

  SEARCH_CLIENTS: `guardianvets/v1/gvComm/client?search_value={search_value}&option={option}&hospital_id={hospital_id}&offset={offset}&limit={limit}`,

  CREATE_NEW_CLIENT: 'guardianvets/v3/clients',

  FETCH_CASES_BASED_ON_FILTER: `guardianvets/v1/gvComm/case?search_value={search_value}&option={option}&type={type}`,

  CREATE_CASE: 'guardianvets/v3/cases',
  UPDATE_CASE: 'guardianvets/v3/cases/:caseId',
  SUBMIT_CASE: 'guardianvets/v3/cases/:caseId/submit',
  UPDATE_CASE_CHAT_CHANNEL: 'guardianvets/v3/cases/update-chat-channel',
  WRONG_TRIAGE: 'guardianvets/v3/cases/:caseId/wrong-triage',

  GET_ALL_DOCTORS: `guardianvets/v1/hospital/dvm?hospital_id={hospital_id}`,

  // on-call-doctors
  GET_HOSPITAL_ON_CALL_DOCTORS: `guardianvets/v1/hospital/:hospitalId/on-call-doctors`,
  CREATE_HOSPITAL_ON_CALL_DOCTOR: `guardianvets/v1/hospital/:hospitalId/on-call-doctors`,
  HOSPITAL_ON_CALL_DOCTOR: `guardianvets/v1/hospital/:hospitalId/on-call-doctors/:doctorId`,
  GET_HOSPITAL_ON_CALL_DOCTOR_DVM_USERS: `guardianvets/v1/hospital/:hospitalId/on-call-doctors/dvm-users`,
  DVM_USERS_ON_CALL:
    'guardianvets/v1/hospital/:hospitalId/on-call-doctors/dvm-users-on-call',
  DVM_USERS_ON_CALL_SCHEDULE:
    'guardianvets/v1/hospital/:hospitalId/on-call-doctors/dvm-users-on-call-schedule',

  GET_EVENTS_FOR_DOCTOR: `guardianvets/v1/calendar/call/availability?doctor_ids={doctor_id}&start_date={start_date}&end_date={end_date}`,
  GET_CLINIC_HOURS: `guardianvets/v1/calendar/clinichours/{hospital_id}`,
  CALENDAR: 'guardianvets/v3/calendar/clinichours/{hospital_id}',
  GET_FORWARDING_HOURS: `guardianvets/v1/hospital/clinichours/{hospital_id}`,
  DELETE_FORWARDING_SCHEDULE: `guardianvets/v1/hospital/general/clinichours/:id`,
  ADD_ON_CALL_AVAILABILITY: `guardianvets/v1/calendar/call/availability`,
  GET_ALL_CALL_CONTACTS: `guardianvets/v1/calendar/get-doctors-list`,
  DELETE_ON_CALL_SCHEDULE: `guardianvets/v1/calendar/doctor/schedule`,
  ADD_ON_CALL_CONTACT: `guardianvets/v1/calendar/call/contact`,
  ADD_CLINIC_HOURS: `guardianvets/v1/calendar/clinichours`,
  ADD_APPOINTMENT_HOURS: `guardianvets/v3/hospital/appointment-hours`,
  ADD_FORWARDING_HOURS: `guardianvets/v1/hospital/clinichours`,
  END_CALL_EXPORT: `guardianvets/v1/gvComm/conference/end`,

  END_CHAT_EXPORT: `guardianvets/v1/gvComm/chat/channel/end`,

  GET_APPOINTMENT: `guardianvets/v1/calendar/virtual-visit/availability`,
  ADD_APPOINTMENT: `guardianvets/v1/calendar/virtual-visit/set-availability`,
  DELETE_VIRTUAL_VISIT: `guardianvets/v1/calendar/virtual-visit/delete-availability `,
  END_SMS_EXPORT: `guardianvets/v1/gvComm/sms/channel/end`,

  // to get the admin role for the permission in preference tab

  GET_ADMIN_ROLE:
    'guardianvets/v1/preferences/role?role_type=1&hospital_id={hospital_id}',

  ADD_ROLE: 'guardianvets/v1/preferences/role',

  UPDATE_PERMISSION: 'guardianvets/v1/preferences/role/permission',

  GET_HOSPITAL_ROLE_LIST: `guardianvets/v1/preferences/roleWithPermission?hospital_id={hospital_id}&role_type=1`,

  GET_PET_LIST: 'guardianvets/v1/gvComm/client/{id}',

  ADD_NEW_PET: 'guardianvets/v3/clients/:id/pets',

  UPDATE_PET: 'guardianvets/v3/clients/:id/pets/:petId',

  GET_TAG: 'guardianvets/v1/gvComm/tag?type={type}&tag_name={tag_name}',

  ADD_TAG: 'guardianvets/v1/gvComm/tag',

  // GET_CASES_LIST:                   "hospital/case_form_by_atendent_id?all_cases={all_cases}&limit={limit}&offset={offset}",

  GET_HOSPITAL_INFO_REFERAL_FROM_CASE: `guardianvets/v1/gvComm/referral/info`,

  GET_HOSPITAL_EMERGENCY_REFERRAL:
    'gvComm/emergency/referral?hospital_id={hospital_id}',

  GET_CLINIC_HOURS_FOR_GVCOMM: `guardianvets/v1/gvComm/hospitalInfoAndSchedule/{hospital_id}`,

  GET_GVCOMM_ON_CALL_PROTOCOL: `guardianvets/v1/gvComm/on-call-protocol?hospital_id={hospital_id}`,

  GET_NOTES_FOR_GVCOMM: `guardianvets/v1/gvComm/notes?hospital_id={hospital_id}`,

  // for notes tab in preference page
  GET_NOTES: `guardianvets/v1/preferences/notes?hospital_id={hospital_id}`,

  ADD_NOTES: `guardianvets/v1/preferences/notes`,

  // for greetings tab in preferences page
  GET_GREETINGS_AUDIO: `guardianvets/v1/preferences/greetings?hospital_id={hospital_id}&greeting_type=0`,
  GET_GREETINGS_TEXT: `guardianvets/v1/preferences/greetings?hospital_id={hospital_id}&greeting_type=1`,

  GREETINGS: `guardianvets/v1/preferences/greetings`,

  ENABLE_GREETING: `guardianvets/v1/preferences/greetings/enable`,

  // case greeting
  CASE_GREETING: `guardianvets/v1/hospital/{hospitalId}/case-greetings`,

  //pricing
  PRICING: 'guardianvets/v1/hospital/:hospitalId/pricing/groups',
  PRICING_SERVICE:
    'guardianvets/v1/hospital/:hospitalId/pricing/groups/:groupId/services',

  // scheduling
  WORKFLOWS: 'guardianvets/v1/hospital/{hospitalId}/scheduling-workflows',

  // for Marketplace tab in preference page
  GET_MARKETPLACE: `guardianvets/v1/preferences/marketplace?hospital_id={hospital_id}`,

  EDIT_MARKETPLACE: `guardianvets/v1/preferences/marketplace`,
  DISABLE_MARKETPLACE: `guardianvets/v1/preferences/marketplaceDisable`,

  // for FollowUps tab in preference page
  GET_GVFOLLOWUPS: `guardianvets/v1/preferences/followup?hospital_id={hospital_id}`,

  ADD_GVFOLLOWUPS: `guardianvets/v1/preferences/followup`,

  FETCH_ALL_SMS:
    'gvComm/sms/load?channelName={channelName}&limit={limit}&offset={offset}',

  SEND_SMS: `guardianvets/v1/gvComm/sms/send`,

  GET_CASES_LIST_MY_CASES: 'guardianvets/v1/myCases/case_form_by_atendent_id',

  GET_CASES_LIST_ALL_CASES: 'guardianvets/v1/allCases/case_form_by_atendent_id',
  GET_CASES_LIST_INCOMPLETE_CASES: 'guardianvets/v1/incompletecases/list',

  GET_ALL_CASES: `guardianvets/v1/allCases`,

  UPDATE_INCOMPLETE_CASE: 'guardianvets/v1/incompletecases/update',

  GET_HOSPITAL_DEPARTMENT_INCOMPLETE_CASES:
    'incompletecases/department?hospital_id={hospital_id}',

  ADD_ADDENDUM_MY_CASES: 'guardianvets/v1/myCases/case/addendum',

  ADD_ADDENDUM_ALL_CASES: 'guardianvets/v1/allCases/case/addendum',

  GET_HOSPITAL_DEPARTMENT_All_CASES:
    'allCases/department?hospital_id={hospital_id}',

  GET_HOSPITAL_DEPARTMENT_MY_CASES:
    'myCases/department?hospital_id={hospital_id}',

  ADD_ADDENDUM_INCOMPLETECASES: 'guardianvets/v1/incompletecases/case/addendum',

  GET_CASE_DETAILS: 'guardianvets/v3/cases/:id',
  ADD_CASE_ADDENDA: 'guardianvets/v3/cases/:id/addenda',
  DISCARD_CASE: 'guardianvets/v3/cases/discard',
  DELETE_CASE: 'guardianvets/v3/cases/:id',
  GET_CASE_PDF: 'guardianvets/v1/allCases/case/:id/pdf', // TODO: use v3 api
  GET_CASE_HTML: 'guardianvets/v1/allCases/case/:id/html',

  // clients
  GET_CLIENT_LIST: 'guardianvets/v3/clients',

  GET_CLIENT_INFO_BY_ID: 'guardianvets/v3/clients/:id',

  GET_CLIENT_PROFILE_BY_ID: 'guardianvets/v3/clients/:id/profile',

  SETUP_CLIENT_INTENTS: 'payments/clients/me/setup-intents',

  GET_CLIENT_CARDS: 'payments/clients/me/cards',

  DELETE_CLIENT_CARD: 'payments/clients/me/cards/:id',

  ADD_DEFAULT_CLIENT_CARD: 'payments/clients/me/cards/default',

  PAYMENTS_CLIENT_PROFILE: 'payments/clients/me',

  UPDATE_CLIENT: 'guardianvets/v3/clients/:id',

  SEND_CALL_NOTIFICATION: 'guardianvets/v1/gvComm/notify/calling-to-petowner',

  GET_ON_CALL_SERVICES:
    'guardianvets/v1/preferences/newOnCallServices?hospital_id={hospital_id}',

  UPDATE_ON_CALL_SERVICES: 'guardianvets/v1/preferences/newOnCallServices',

  DELETE_CLINIC_HOURS: 'guardianvets/v1/calendar/general/clinichours/:id',
  DELETE_CALENDAR_HOURS: 'guardianvets/v3/hospital/appointment-hours/:id',
  TOGGLE_ON_CALL_SERVICES:
    'guardianvets/v1/preferences/enable/newOnCallServices',
  ADD_ON_CALL_SERVICES: 'guardianvets/v1/preferences/newOnCallServices',

  // to get the case info for client

  GET_CASE_INFO_BY_ID_CLIENT:
    'clients/case?option={option}&is_submitted={is_submitted}&search_value={value}',

  GET_CASE_INFO_BY_ID_ALL_CASES:
    'allCases/case?option={option}&is_submitted={is_submitted}&search_value={value}',

  GET_CASE_INFO_BY_ID_MY_CASES:
    'myCases/case?option={option}&is_submitted={is_submitted}&search_value={value}',

  GET_CASE_INFO_BY_ID_INCOMPLETE_CASES:
    'incompleteCases/case?option={option}&is_submitted={is_submitted}&search_value={value}',

  GET_CASE_INFO_BY_ID_ACTION_ITEMS:
    'actionItems/caseForm?option={option}&is_submitted={is_submitted}&search_value={value}',

  GET_CASE_INFO_BY_ID_GV_COMM:
    'gvComm/case?is_submitted={is_submitted}&option={option}&search_value={value}',

  PROCESS_VIDEO_RECORDING: 'guardianvets/v1/gvComm/video/recording',

  GET_MY_CASES_CHAT_HISTORY:
    'guardianvets/v1/myCases/ChatConversation?case_id={case_id}',

  GET_ALL_CASES_CHAT_HISTORY:
    'guardianvets/v1/allCases/ChatConversation?case_id={case_id}',

  GET_GV_COMM_CHAT_HISTORY:
    'guardianvets/v1/gvComm/ChatConversation?case_id={case_id}',

  // GET_VIDEO_RECORDING_URL: 'guardianvets/v1/gvComm/video/recording/request?CompositionSid={CompositionSid}',

  GET_DOCTORS:
    'guardianvets/v1/calendar/get-doctors-list?hospital_id={hospital_id}',

  ADD_DOCTOR_SCHEDULE: `guardianvets/v1/calendar/doctor/schedule`,

  GET_EVENTS_FOR_ON_CALL_CALENDAR: `guardianvets/v1/calendar/doctor/schedule`,
  // get all dvms for action center
  GET_DVM: `guardianvets/v1/gvComm/get-filtered-doctors-list?hospital_id={hospital_id}`,

  GET_VIDEO_RECORDING_URL:
    'gvComm/video/recording/request?CompositionSid={CompositionSid}',

  // request for mrging with dvm
  // MERGE_WITH_DVM: "gvComm/transfer/dvm",

  UNHOLD_CLIENT: `guardianvets/v1/gvComm/client/unhold`,

  // get medical records for a client
  GET_CLIENT_MEDICAL_RECORDS: `guardianvets/v1/gvComm/client/medical/history?client_id={client_id}&pet_id={pet_id}&search={{search}}`,

  GET_RX_FORM_INFO: `guardianvets/v1/gvComm/get-rxform-info?rxform_id={rxFormId}`,

  CREATE_APPOINTMENT_WEB_LINK: `guardianvets/v1/gvComm/generateUrl`,

  SEND_VOICEMAIL_ON_CONFERENCE: `guardianvets/v1/gvComm/conference/voicemail/send`,

  DISCONNECT_DVM: `guardianvets/v1/gvComm/dvm/disconnect`,

  GET_PHONE_LINE_TESTING:
    'phoneline/phonelineTesting?limit={limit}&offset={offset}&search={search}',

  SEND_PHONELINE_NOT_WORKING_NOTIFICATION:
    'guardianvets/v1/phoneline/phonelinenotworking',

  SEND_INDICATE_STOP_FORWARDING_NOTIFICATION:
    'phoneline/indicatestopforwarding',

  GET_ACTION_ITEM_LISTING: 'guardianvets/v1/actionItems/action_items',

  GET_ORDERED_FOOD_LISTING:
    'actionItems/ordered-foods?limit={limit}&offset={offset}',

  GET_MEDICENE_REFILL_LISTING:
    'actionItems/medication-refills?limit={limit}&offset={offset}',

  COMPLETE_ORDERED_FOOD: 'guardianvets/v1/actionItems/complete-ordered-foods',

  COMPLETE_MEDICENE_REFILL:
    'guardianvets/v1/actionItems/complete-medication-refills',

  DELETE_ACTION_ITEMS: 'guardianvets/v1/actionItems/action_items',

  GET_ACTION_ITEM_CHAT_HISTORY:
    'actionItems/ChatConversation?case_id={case_id}',

  GET_UNREAD_ACTION_ITEMS_COUNT: `guardianvets/v1/actionItems/get-pending-actionitems`,

  CHANGE_VOICEMAIL_READ_STATUS: 'guardianvets/v1/voicemail/read/status',

  UPDATE_VOICEMAIL: 'guardianvets/v1/voicemail',

  ADD_ADDENDUM_VOICEMAILS: 'guardianvets/v1/voicemail/case_addendum',

  GET_UNREAD_VOICE_MAIL_COUNT: `guardianvets/v1/voicemail/count?hospital_id={hospital_id}`,

  // Greeting In GV COMM greeting_type === 0 -audio 1 -text
  GET_GREETINGS:
    'gvComm/greetings?hospital_id={hospital_id}&greeting_type={greeting_type}',

  COMMON_CHAT_TOKEN: `guardianvets/v1/common/chat/token?email={email}&&room_name={room_name}`,

  SEND_CALL_DISCONNECT_NOTIFICATION:
    'gvComm/notify/disconnect-calling-to-petowner',

  // vendor
  GET_BASIC_DETAILS_VENDOR:
    'guardianvets/v1/setup/hospital/hospitalTwilioDetails?id={id}',
  GET_BASIC_DETAILS_VENDOR_REGENERATE:
    'guardianvets/v1/vendor/regenerateTwilioKeys',
  SET_VENDOR_FIREBASE_KEY: 'guardianvets/v1/vendor/firebase/key',

  // Feedback API endpoints

  GET_FEEDBACK_LIST:
    'guardianvets/v1/feedback/list?filter_user_id={filter_user_id}&limit={limit}&type={type}&offset={offset}',

  GET_FEEDBACK_NURSES_LIST: 'guardianvets/v1/feedback/nurse-list',

  GET_FEEDBACK_MEMBERS_LIST: 'guardianvets/v1/feedback/members-list',

  // corporate group
  GET_CORPORATE_LIST: 'guardianvets/v3/hospitals/:hospitalId/corporate',
  GET_CORPORATE_INFO:
    'guardianvets/v3/hospitals/:hospitalId/corporate/:groupId',
  GET_CORPORATE_MAIN_HOSPITAL:
    'guardianvets/v3/hospitals/:hospitalId/corporate/main-hospital',

  GET_PROMOCODE_LISTING:
    'guardianvets/v1/promocode?limit={limit}&offset={offset}',
  ADD_NEW_PROMOCODE: 'guardianvets/v1/promocode/add-new',
  UPDATE_PROMOCODE: 'guardianvets/v1/promocode/update',

  GET_PROMOCODE_DISCOUNT_REDEEMED_COUNT:
    'guardianvets/v1/promocode/discount-redeemed',
  GET_TOTAL_GV_FEE_PERCENT: 'guardianvets/v1/common/gv-fee',

  // on call protocol
  GET_ON_CALL_PROTOCOL: `guardianvets/v1/adminOnCallProtocol/on-call-protocol?hospital_id={hospital_id}`,
  GET_ALL_HOSPITAL_LIST_FOR_ON_CALL_PROTOCOL: `guardianvets/v1/dashboard/hospital/listing`,

  // hospital analytics apis
  GET_DIRECT_REVENUE_COUNT: 'guardianvets/v1/analytics/direct-revenue?',
  GET_INDIRECT_REVENUE_COUNT: 'guardianvets/v1/analytics/indirect-revenue?',
  GET_NEW_CLIENTS: 'guardianvets/v1/analytics/new-clients?',
  GET_CONSULTATION_COUNT: 'guardianvets/v1/analytics/consultation-counts?',
  GET_SCHEDULED_APPOINTMENT_COUNT:
    'guardianvets/v1/analytics/scheduled-appointment-counts?',
  GET_ER_REF_COUNT: 'guardianvets/v1/analytics/er-ref-counts?',

  GET_APP_STATS: 'guardianvets/v1/analytics/app-stats?',
  GET_ACTIVE_PETOWNER_STATS: 'guardianvets/v1/analytics/active-petowner-stats?',

  GET_TOP_PERFORMING_DVM:
    '/analytics/top-performing-dvm?limit={limit}&offset={offset}',
  GET_TOP_PERFORMING_HOSPITALS:
    '/analytics/top-performing-hospitals?limit={limit}&offset={offset}',
  GET_DIRECT_REVENUE_LIST:
    '/analytics/direct-revenue-list?limit={limit}&offset={offset}&' +
    'start_date={start_date}&end_date={end_date}&hospital_id={hospital_id}',
  GET_INDIRECT_REVENUE_LIST:
    '/analytics/indirect-revenue-list?limit={limit}&offset={offset}&' +
    'start_date={start_date}&end_date={end_date}&hospital_id={hospital_id}',
  GET_AVERAGE_FEEDBACK_DVM: 'guardianvets/v1/analytics/average-feedback-dvm?',

  // help rating/ service rating, interaction rating
  GET_FEEDBACK_AGGREGATE_DVM:
    'guardianvets/v1/analytics/feedback-aggregate-dvm?',
  GET_PERCENTAGE_CLIENT:
    'guardianvets/v1/analytics/client-feedback-percentage?',
  GET_FEEDBACK_DVM_LIST:
    'guardianvets/v1/analytics/feedback-dvm-list?offset={offset}',
  GET_VOLUME_BY_DAY: 'guardianvets/v1/analytics/volume-by-day?',
  GET_VOLUME_BY_HOUR: 'guardianvets/v1/analytics/volume-by-hour?',
  GET_TOP_COMPLAINTS: 'guardianvets/v1/analytics/top-complaints?',
  GET_REVENUE_BY_HOSPITAl: 'guardianvets/v1/analytics/revenue-by-hospital?',

  // nurse analytics
  GET_NURSE_TOTAL_CALLS: 'guardianvets/v1/analytics/nurse-total-calls?',

  GET_NURSE_TOTAL_FORMS: 'guardianvets/v1/analytics/nurse-total-forms?',
  GET_NURSE_TOTAL_FORMS_ALL: 'guardianvets/v1/analytics/nurse-total-forms-all?',
  GET_NURSE_AVG_CALL_LENGTH: 'guardianvets/v1/analytics/nurse-avg-call-length?',
  GET_NURSE_RECOMMENDATION_TYPE:
    'guardianvets/v1/analytics/nurse-recommendation-type?',
  GET_NURSE_FEEDBACK: 'guardianvets/v1/analytics/nurse-feedbacks?',
  GET_NURSE_AVG_HOLD_TIME: 'guardianvets/v1/analytics/avg-hold-time?',
  GET_NURSE_TOP_COMPLAINTS: 'guardianvets/v1/analytics/nurse-top-complaints?',
  GET_NURSE_CLIENTS_FEEDBACK:
    'guardianvets/v1/analytics/nurse-clients-feedbacks?',
  GET_NURSE_CALLS_BY_HOUR: 'guardianvets/v1/analytics/nurse-call-by-hour?',
  GET_NURSE_CALLS_BY_DAY: 'guardianvets/v1/analytics/nurse-call-by-day?',
  GET_NURSE_CALLS_BY_MONTH: 'guardianvets/v1/analytics/nurse-call-by-month?',
  GET_SERVICE_LEVEL_BY_HOUR: 'guardianvets/v1/analytics/service-level-by-hour?',
  GET_ABANDONED_RATE_BY_HOUR:
    'guardianvets/v1/analytics/abandoned-rate-by-hour?',
  GET_AVG_HOLD_TIME_BY_HOUR: 'guardianvets/v1/analytics/avg-hold-time-by-hour?',

  GET_AVG_CALLS_HANDLED_PER_HOUR:
    'guardianvets/v1/analytics/avg-calls-handled-per-hour?', // CORMAC

  GET_NURSE_FEEDBACK_PERCENTAGE:
    'guardianvets/v1/analytics/client-feedback-nurse-percentage?',
  GET_NURSE_AVG_FEEDBACK: 'guardianvets/v1/analytics/average-feedback-nurse?',
  GET_APPOINTMENTS_COUNT: 'guardianvets/v1/analytics/appointments-counts?',
  GET_CALLS_COUNT: 'guardianvets/v1/analytics/calls-counts?',
  GET_UPCOMING_APPOINTMENTS_LIST:
    '/analytics/upcoming-appointments-list?without_paginate=0',
  GET_CHECK_INS_LIST:
    'guardianvets/v1/analytics/check-ins-list?without_paginate=0',
  GET_CONSULTATION_BY_TYPE:
    'guardianvets/v1/analytics/triage-consultation-by-type?',
  GET_TOTAL_REVENUE_GENERATED:
    'guardianvets/v1/analytics/total-revenue-generated?',
  GET_TEXT_COUNT: 'guardianvets/v1/analytics/texts-counts?',

  // nurse dashboard
  GET_AVG_FORM_PER_HOUR_COUNT: 'guardianvets/v1/analytics/avg-forms?',
  GET_AVG_FEEDBACK_SCORE_FOR_NURSE_DVM:
    '/analytics/average-feedback-score-for-nurse?',
  GET_AVG_HOLD_TIME_FOR_ANSWERED_Q_AND_UNANSWERED_Q:
    '/analytics/average-of-answered-unanswered-que?',

  ROI_REPORTS_LIST:
    '/roireport?limit={limit}&offset={offset}&searchKeyword={search}&isCorporate={isCorporate}',
  ROI_REPORTS: 'guardianvets/v1/roireport',

  GET_ONE_LOGIN_PASSWORD:
    'guardianvets/v1/common/preferences/onelogin-password',
  UPDATE_ONE_LOGIN_PASSWORD:
    'guardianvets/v1/common/preferences/onelogin-password',
  GET_RING_GROUPS_ALL: `guardianvets/v1/ringGroup`,
  GET_AUDIO_LIST: `guardianvets/v1/recording/getAudio`,
  GET_HOSPITAL_ALL: `guardianvets/v1/hospital`,

  ADD_HOSPITAL: `guardianvets/v1/setup/hospital/add/basic`,
  SET_HOSPITAL_TYPE: `guardianvets/v1/setup/hospital/add/type`,
  SET_GV_TRIAGE_STATUS: `guardianvets/v1/setup/hospital/add/type`,
  SEARCH_NUMBER: `guardianvets/v1/setup/hospital/add/search/number`,
  PURCHASE_NUMBER: `guardianvets/v1/setup/hospital/add/number`,
  ADD_INITIAL_RING: `guardianvets/v1/hospital/link/ringgroup`,
  ADD_INITIAL_IVR: `guardianvets/v1/hospital/link/ivr`,
  SAVE_DOCUMENT_HOSPITAL: `guardianvets/v1/setup/hospital/add/docc/setting`,
  UPLOAD_HOSPITAL_LOGO: `guardianvets/v3/hospitals/:hospitalId/upload-logo`,
  GET_BASIC_HOSPITAL_INFO: `guardianvets/v3/hospitals/:hospitalId/basic-info`,

  ADD_VENDOR: `guardianvets/v1/vendor/add/basic`,
  SEARCH_NUMBER_VENDOR: `guardianvets/v1/vendor/add/search/number`,
  PURCHASE_NUMBER_VENDOR: `guardianvets/v1/vendor/add/number`,

  GET_HOSPITAL_LIMIT: `guardianvets/v1/hospital`,
  DELETE_HOSPITAL: `guardianvets/v1/hospital/delete/:id`,
  UPDATE_HOSPITAL_STATUS: `guardianvets/v1/hospital/update/status`,
  SEARCH_HOSPITAL: `guardianvets/v1/hospital/search`,
  GET_HOSPITAL_BASIC_INFO: `guardianvets/v1/hospital/basic/{id}`,
  UPDATE_INITIAL_IVR: `guardianvets/v1/hospital/update/ivr`,
  UPDATE_INITIAL_RING: `guardianvets/v1/hospital/update/ringgroup`,
  SEND_WELCOME_EMAIL: `guardianvets/v1/hospital/sendWelcomeEmail`,
  INITIATE_AMOUNT_TRANSFER: `guardianvets/v1/hospital/pending/transfer?hospital_id={hospital_id}`,
  HOSPITAL_GV_TALK_STATUS: 'guardianvets/v1/hospital/gvtalk/:hospitalId',
  GET_ADMIN_HOSPITAL_LIST: `guardianvets/v3/admin/hospitals`,
  EXPORT_REPORT_ADMIN_HOSPITAL: 'guardianvets/v3/admin/hospitals/download',
  EXPORT_REPORT_TEAM_HOSPITAL: 'guardianvets/v1/hospital/search/download',
  EXPORT_REPORT_CLIENT_HOSPITAL: 'guardianvets/v3/clients/download',
  HOSPITAL_RESUBSCRIBE_EMAILS:
    'guardianvets/v3/hospitals/:hospitalId/resubscribe-emails',
  HOSPITAL_UNSUBSCRIBED_EMAILS:
    'guardianvets/v3/hospitals/:hospitalId/unsubscribed-emails',
  GET_HOSPITAL_WHITEBOARD_CONFIG:
    'guardianvets/v3/hospitals/:hospitalId/whiteboard-config',

  GET_IVR_LIMIT: `guardianvets/v1/ivr?limit={limit}&offset={offset}`,
  CREATE_IVR: `guardianvets/v1/ivr`,
  SEARCH_IVR: `guardianvets/v1/ivr/search?searchKeyword={searchedData}`,
  IVR_BY_ID: `guardianvets/v1/ivr/{id}`,
  UPDATE_GLOBAL_IVR: `guardianvets/v1/ivr/mark-global`,

  GET_RING_GROUP_LIMIT: `guardianvets/v1/ringGroup?limit={limit}&offset={offset}`,
  CREATE_RING: `guardianvets/v1/ringGroup`,
  RING_BY_ID: `guardianvets/v1/ringGroup/{id}`,

  GET_RECORDINGS_LIMIT: `guardianvets/v1/recording/getAudio?limit={limit}&offset={offset}`,

  GET_CHAIN_LIMIT:
    'guardianvets/v1/chain?limit={limit}&offset={offset}&search={search}',
  SEARCH_CHAIN: 'guardianvets/v1/chain/search?searchKeyword={searchedData}',
  CREATE_CHAIN: 'guardianvets/v1/​chain',
  GET_CHAIN_BY_ID: 'guardianvets/v1/chain/{id}?id={getById}',
  UPDATE_CHAIN: 'guardianvets/v1/chain',
  GET_HOSPITAL_CHAIN: 'guardianvets/v1/chain/get-hospitals',
  GET_HOSPITAL_CHAIN_EDIT:
    'guardianvets/v1/chain/get-hospitals?chain_id={chain_id}',

  GET_NURSE_LIST: `guardianvets/v3/admin/nurses`,
  CREATE_NURSE: `guardianvets/v3/admin/nurses`,
  GET_NURSE_BY_ID: `guardianvets/v3/admin/nurses/:id`,
  UPDATE_NURSE: `guardianvets/v3/admin/nurses/:id`,
  DELETE_NURSE: `guardianvets/v3/admin/nurses/:id`,
  UPDATE_NURSE_STATUS: `guardianvets/v3/admin/nurses/:id/toggle-status`,

  CREATE_ROLE: `guardianvets/v1/rolePermission/role`,
  GET_ROLE_LIST: `guardianvets/v1/rolePermission/role/list`,

  GET_ALL_ROLES_WITH_PERM: `guardianvets/v1/rolePermission/role`,

  PUT_POST_PERMISSIONS: `guardianvets/v1/rolePermission/role/permission`,

  GET_NURSE_ROLES_WITH_PERM: `guardianvets/v1/rolePermission/roleWithPermission?role_type=2`,

  // GV Fee api urls
  GET_GV_FEE: `guardianvets/v1/hospital/gvfee`,
  ADD_GV_FEE: `guardianvets/v1/hospital/gvfee`,

  // hospital clinic hours for hospital
  ADD_CLINIC_HOURS_FOR_HOSPITAL: `guardianvets/v1/hospital/hospital-clinichours`,
  GET_CLINIC_HOURS_FOR_HOSPITAL: `guardianvets/v1/hospital/hospital-clinichours?hospital_id={hospital_id}&start_date={start_date}&end_date={end_date}`,
  DELETE_CLINIC_HOURS_FOR_HOSPITAL: `guardianvets/v1/hospital/general/hospital-clinichours/{id}?hospital_id={hospital_id}&slot={slot}`,
  SET_SIMPLE_CLINIC_HOURS:
    'guardianvets/v1/hospital/hospital-clinichours/simple',

  // communication logs
  GET_COMMUNICATION_LOGS: 'guardianvets/v1/commLogs',
  CALL_METRICS: 'guardianvets/v1/gvComm/voice/call/get/metrics/',
  GET_VIDEO_FROM_COMPOSITION_ID:
    'commLogs/video/recording?CompositionSid={{CompositionSid}}',
  GET_VOICEMAIL_FOR_CONF:
    'guardianvets/v1/commLogs/conference/recording?call_sid={{call_sid}}',

  // call notifications
  GET_NOTIFYING_PERSONS: 'guardianvets/v3/admin/notifying-persons',
  ADD_NOTIFYING_PERSON: 'guardianvets/v3/admin/notifying-persons',
  UPDATE_NOTIFYING_PERSON: 'guardianvets/v3/admin/notifying-persons/:id',
  DELETE_NOTIFYING_PERSON: 'guardianvets/v3/admin/notifying-persons/:id',

  // call notification minutes
  GET_ADMIN_PREFERENCES: 'guardianvets/v3/admin/preferences',
  UPDATE_ADMIN_PREFERENCES: 'guardianvets/v3/admin/preferences',

  //case notifications
  GET_CASE_NOTIFICATIONS:
    'guardianvets/v3/hospitals/:hospitalId/caseEmailNotificationSettings',
  UPDATE_CASE_NOTIFICATIONS:
    'guardianvets/v3/hospitals/:hospitalId/case-notifications/email/settings',
  GET_CASE_NOTIFICATIONS_BY_IDS:
    'guardianvets/v3/hospitals/:hospitalId/case-notifications/email/settings',

  // on call protocol end points
  UPDATE_ON_CALL_PROTOCOL: `guardianvets/v1/adminOnCallProtocol/on-call-protocol`,

  // states master api
  GET_STATES_MASTER_LIST: `guardianvets/v3/common/states`,
  ADD_MASTER_STATE: `guardianvets/v3/common/states`,
  UPDATE_MASTER_STATE: `guardianvets/v3/common/states/:id`,
  DELETE_MASTER_STATE: `guardianvets/v3/common/states/:id`,

  // timezones master api
  GET_TIMEZONES_MASTER_LIST: `guardianvets/v3/common/timezones`,
  ADD_MASTER_TIMEZONE: `guardianvets/v3/common/timezones`,
  UPDATE_MASTER_TIMEZONE: `guardianvets/v3/common/timezones/:id`,
  DELETE_MASTER_TIMEZONE: `guardianvets/v3/common/timezones/:id`,

  // species master api
  GET_SPECIES_MASTER_LIST: `guardianvets/v3/common/species`,
  ADD_MASTER_SPECIES: `guardianvets/v3/common/species`,
  UPDATE_MASTER_SPECIES: `guardianvets/v3/common/species/:id`,
  DELETE_MASTER_SPECIES: `guardianvets/v3/common/species/:id`,

  // breeds master api
  GET_BREEDS_MASTER_LIST: `guardianvets/v3/common/breeds`,
  ADD_MASTER_BREED: `guardianvets/v3/common/breeds`,
  UPDATE_MASTER_BREED: `guardianvets/v3/common/breeds/:id`,
  DELETE_MASTER_BREED: `guardianvets/v3/common/breeds/:id`,

  // genders master api
  GET_GENDERS_MASTER_LIST: `guardianvets/v3/common/genders`,
  ADD_MASTER_GENDER: `guardianvets/v3/common/genders`,
  UPDATE_MASTER_GENDER: `guardianvets/v3/common/genders/:id`,
  DELETE_MASTER_GENDER: `guardianvets/v3/common/genders/:id`,

  // chief complaints master api
  GET_CHIEF_COMPLAINTS_MASTER_LIST: `guardianvets/v3/common/chief-complaints`,
  ADD_MASTER_CHIEF_COMPLAINT: `guardianvets/v3/common/chief-complaints`,
  UPDATE_MASTER_CHIEF_COMPLAINT: `guardianvets/v3/common/chief-complaints/:id`,
  DELETE_MASTER_CHIEF_COMPLAINT: `guardianvets/v3/common/chief-complaints/:id`,

  // ring groups api
  GET_RING_GROUPS_LIST: 'guardianvets/v3/ring-group',
  GET_RING_GROUP_DETAILS: 'guardianvets/v3/ring-group/:id',

  // recordings api
  GET_RECORDINGS_LIST: 'guardianvets/v1/recording/getAudio',
  CREATE_RECORDING: 'guardianvets/v1/recording/uploadMultiMedia',
  DELETE_RECORDING: 'guardianvets/v1/recording/MultiMedia/:id/:name',

  // ivr api
  GET_IVR_LIST: 'guardianvets/v1/ivr',
  DELETE_IVR: 'guardianvets/v1/ivr/:id',
  UPDATE_IVR_GLOBAL: 'guardianvets/v1/ivr/mark-global',

  // new dashboard(analytics) apis
  FETCH_PETCO_WEB_VISIT_ANALYTICS: `guardianvets/v1/analytics/user-visits-typed-n-ask-ques-count?`,
  FETCH_CHAT_LENGTH_CUSTOMER_LEFT_BEFORE_NURSE_ANSWERED: `guardianvets/v1/analytics/chat-length-customer-left-before-nurse-answered?`,
  FETCH_LENGTH_OF_SESSION_ONCE_NURSE_JOINS: `guardianvets/v1/analytics/lengths-of-sessions-once-nurse-joins?`,
  FETCH_WAIT_TIME_UNTIL_NURSE_JOIN_CALL: `guardianvets/v1/analytics/wait-time-until-nurse-join-call?`,
  FETCH_LENGTH_OF_SESSION_TOTAL: `guardianvets/v1/analytics/lengths-of-sessions-total?`,

  GET_ABLY_TOKEN: 'guardianvets/v3/gvComm/token/ably',

  GET_ACTION_QUEUE: 'guardianvets/v3/gvComm/queue',
  PICKUP_CALL: 'guardianvets/v3/gvComm/pickup-call',
  DISCONNECT_CALL: 'guardianvets/v3/gvComm/disconnect',
  HOLD_CALL: 'guardianvets/v3/gvComm/hold',
  DEVICE_ERROR: 'guardianvets/v3/gvComm/device-error',
  CALL_DVM: 'guardianvets/v3/gvComm/call/dvm',
  RESUME_CALL: 'guardianvets/v3/gvComm/resume-call',

  MEDICATIONS: 'guardianvets/v3/medications',
  UPDATE_MEDICATIONS: 'guardianvets/v3/medications/:id',
  GET_MEDICATION_CATEGORIES: 'guardianvets/v3/medications/categories',

  PHONE_TREE_LIST: 'guardianvets/v1/hospital/:hospitalId/phone-tree',
  PHONE_TREE: 'guardianvets/v1/hospital/:hospitalId/phone-tree/:branchId',
  PHONE_TREE_CLEAR:
    'guardianvets/v1/hospital/:hospitalId/phone-tree/:branchId/clear',

  CHATS: 'guardianvets/v3/gvComm/history',
  CHAT: 'guardianvets/v3/gvComm/chats/{channelName}',
  CHAT_MESSAGES: 'guardianvets/v3/gvComm/chats/{channelName}/messages',
  TAKE_CHAT_FROM_QUEUE: 'guardianvets/v3/gvComm/chats/{channelName}/take',
  RETURN_CHAT_TO_QUEUE: 'guardianvets/v3/gvComm/chats/{channelName}/return',
  END_CHAT: 'guardianvets/v3/gvComm/chats/{channelName}/end',
  GET_CHAT_PDF: 'guardianvets/v1/allCases/chat/:id/pdf',
  GET_CHAT_HTML: 'guardianvets/v1/allCases/chat/:id/html',

  ACCEPTANCE: 'guardianvets/v3/hospitalAcceptance/{hospitalId}',
  CASE_ACCEPTANCE: 'guardianvets/v3/hospitalAcceptance/cases/{caseId}',

  CASES: 'guardianvets/v3/cases',
  CASES_LATELY_SUBMITTED: 'guardianvets/v3/cases/lately-submitted',

  CASE_LOGS: 'guardianvets/v3/cases/:id/logs',

  VOICEMAILS: 'guardianvets/v3/voicemails',
  VOICEMAIL: 'guardianvets/v3/voicemails/{id}',

  FEEDBACKS: 'guardianvets/v3/feedbacks',
  FEEDBACKS_STATS: 'guardianvets/v3/feedbacks/stats',

  LANGUAGES: 'guardianvets/v3/common/languages',

  TEAM_USERS: 'guardianvets/v3/hospitals/{hospitalId}/team/users',

  USERS_LIST: 'guardianvets/v3/team',
  USERS_ONLINE: 'guardianvets/v3/user-statuses/online',
  USER_STATUS: 'guardianvets/v3/user-statuses/current',
  USER_STATUS_UPDATE: 'guardianvets/v3/user-statuses',
  USER: 'guardianvets/v3/users/{id}',

  ACTION_ITEMS: 'guardianvets/v3/actionItems',
  UNREAD_ACTION_ITEMS_COUNT: 'guardianvets/v3/actionItems/pending',
  COMPLETE_ACTION_ITEMS: 'guardianvets/v3/actionItems/complete',

  ON_WAY_BOARD: 'guardianvets/v3/on-way-board',
  EDIT_ON_WAY_BOARD: 'guardianvets/v3/on-way-board/{id}',
  COMPLETE_ON_WAY_BOARD: 'guardianvets/v3/on-way-board/{id}/arrive',
  UNREAD_ON_WAY_BOARD: 'guardianvets/v3/on-way-board/pending',

  PROFILE: 'guardianvets/v3/profile',

  LOGOUT: `guardianvets/v3/authentication/logout`,
  REFRESH: `guardianvets/v3/authentication/refresh`,

  TEXT_OUT: 'guardianvets/v3/gvComm/text-out',

  ANALYTICS_HOSPITAL: 'analytics/v1/:hospitalId/analytics',
  ANALYTICS_CORPORATE: 'analytics/v1/:hospitalId/corporate',
  ANALYTICS_TEAM: 'analytics/v1/team',
  ANALYTICS_TEAM_DOWNLOAD_XLSX: 'analytics/v1/download',
  ANALYTICS_HOSPITAL_DOWNLOAD_XLSX: 'analytics/v1/:hospitalId/download',
  ANALYTICS_CORPORATE_DOWNLOAD_XLSX:
    'analytics/v1/:hospitalId/downloadCorporate',
  NEW_ANALYTICS_HOSPITAL: 'analytics/v1/:hospitalId/hospital-analytics',
  UPDATE_ROISTATUS: 'analytics/v1/:hospitalId//hospital-analytics-roi',

  // Marketing
  // TODO: change by real url
  GET_MARKETING_LIST: '/marketing',
  EDIT_MARKETING: '/marketing/edit',
  CREATE_MARKETING: '/marketing/create',
  GET_MARKETING_DETAILS: '/marketing/:id',
  GET_MARKETING_SUBSCRIBERS: '/marketing/surscribers',
  GET_MARKETING_SUBSCRIBERS_DETAILS: '/marketing/surscribers/:id',

  // TODO: replace by real data
  END_OFFICE_CHAT: 'office-chat/end',
  OFFICE_CHAT_TRANSFER: 'office-chat/transfer',
  HOSPITAL_BILLING_HISTORY: 'billing/:hospitalId/history',
  BILLING_ACCOUNT_DETAILS: 'billing/account',
  ACTIVE_SUBSCRIPTIONS_LIST: 'billing/subscriptions/active',
  AVAILABLE_SUBSCRIPTIONS_LIST: 'billing/subscriptions/available',
  GET_APPOINTMENT_TYPES_LIST: 'billing/appointment-type',
  GET_HOSPITAL_PRICE_DETAILS: 'billing/hospital-price/:priceId',
  GET_PAYMENT_INFO: 'payments/info',
  GET_EXAM_DOCTORS: 'exam/doctors',

  // Payments subscription
  GET_DISCOUNT_LIST: 'payments/discounts',
  DELETE_DISCOUNT: 'payments/discounts/:id',
  GET_DISCOUNT_DETAILS: 'payment/discount/details',
  GET_PACKAGES_LIST: 'payments/packages',
  DELETE_PACKAGE: 'payments/packages/:id',

  // Payments account
  GET_PAYOUT_DETAILS: 'payments/accounts',
  DELETE_PAYOUT_DETAILS: 'payments/accounts/:id',
  GET_ACCOUNT_STATUS: 'payments/accounts/getStatus',
  UPLOAD_STRIPE_FILE: 'payments/accounts/upload-file',

  // Pricing
  GET_HOSPITAL_PRICES_LIST: 'payments/prices',
  UPDATE_HOSPITAL_PRICE: 'payments/prices/:id',
  PAYMENT_LINK: 'payments/invoices',
  GET_INVOICE_DETAILS: 'payments/invoices/:id',
  CANCEL_INVOICE: 'payments/invoices/:id/cancel',
  CREATE_REFUND: 'payments/invoices/:id/refund',

  // Services
  GET_HOSPITAL_SERVICES_LIST: 'payments/services',
  UPDATE_HOSPITAL_SERVICE: 'payments/services/:id',

  //GV-Talk
  GET_GV_TALK_CHATS: 'talk/v1/chats',
  CREATE_GV_TALK_CHAT: 'talk/v1/chats',
  GET_GV_TALK_SECTIONS: 'talk/v1/sections',
  UPDATE_GV_TALK_CHAT: 'talk/v1/chats/:chatId',
  END_GV_TALK_CHAT: 'talk/v1/chats/:chatId/end',
  MUTE_GV_TALK_CHAT: 'talk/v1/chats/:chatId/mute',
  GET_GV_TALK_CHAT_DETAILS: 'talk/v1/chats/:chatId',
  GET_GV_TALK_CHAT_FILES: 'talk/v1/chats/:chatId/media',
  UPDATE_GV_TALK_SECTION: 'talk/v1/sections/:sectionId',
  GET_GV_TALK_CHAT_UNREAD_COUNT: 'talk/v1/chats/unread/count',
  CREATE_GV_TALK_FOLDER: 'talk/v1/sections/:sectionId/folders',
  UPDATE_GV_TALK_FOLDER: 'talk/v1/sections/:sectionId/folders/:folderId',
  DELETE_GV_TALK_FOLDER: 'talk/v1/sections/:sectionId/folders/:folderId',

  DELETE_CHAT: 'talk/v1/chats/:id',
  ADD_GV_TALK_CHAT_MEMBERS: 'talk/v1/chats/:chatId/members',
  REMOVE_GV_TALK_CHAT_MEMBERS: 'talk/v1/chats/:chatId/members',
  UPDATE_GV_TALK_CHAT_MEMBER_ROLE: 'talk/v1/chats/:chatId/members/:profileId',

  GET_GV_TALK_MESSAGES: 'talk/v1/messages',
  CREATE_GV_TALK_MESSAGE: 'talk/v1/messages',
  GET_GV_TALK_MESSAGE: 'talk/v1/messages/:messageId',
  READ_UNTIL_GV_TALK_MESSAGE: 'talk/v1/messages/read',
  UPDATE_GV_TALK_MESSAGE: 'talk/v1/messages/:messageId',
  DELETE_GV_TALK_MESSAGE: 'talk/v1/messages/:messageId',
  READ_GV_TALK_MESSAGE: 'talk/v1/messages/:messageId/read',
  REACT_GV_TALK_MESSAGE: 'talk/v1/messages/:messageId/react',
  DELETE_GV_TALK_MEDIA: 'talk/v1/messages/:messageId/medias/:mediaId',

  GET_GV_TALK_FILE_SERVER: 'talk/v1/files',

  GET_GV_TALK_CONTACTS: 'talk/v1/contacts',
  GET_GV_TALK_CONTACT_DETAILS: 'talk/v1/contacts/:id',

  GET_GV_TALK_WORKSPACE: 'talk/v1/workspaces',

  GET_GV_TALK_CHAT_MENTIONS: 'talk/v1/messages/mentions',

  TASKS: 'guardianvets/v3/tasks',
  TASKS_COMPLETE: 'guardianvets/v3/tasks/complete',
  TASK: 'guardianvets/v3/tasks/:id',
  TASKS_COUNT_BY_CLASSES: 'guardianvets/v3/tasks/count',

  NOTIFICATIONS_SUBSCRIBE: 'talk/v1/notifications/subscribe',
  NOTIFICATIONS_UNSUBSCRIBE: 'talk/v1/notifications/unsubscribe',

  GET_CUSTOMERS_QUEUE_LIST: 'talk/v1/chats/queue',
  GET_CUSTOMERS_QUEUE_COUNT: 'talk/v1/chats/queue/count',
  TAKE_CHAT_FROM_CUSTOMERS_QUEUE: 'talk/v1/chats/queue/:id/take',
  PLACE_BACK_CHAT_IN_QUEUE: 'talk/v1/chats/queue/:id/return',

  GET_CALLOUTCOME_TYPES: 'guardianvets/v3/call-outcome-types/',
  GET_SMS_NOTIFICATION:
    'guardianvets/v3/hospitals/:hospitalId/case-notifications/sms/templates',
  DELETE_SMS_NOTIFICATION:
    'guardianvets/v3/hospitals/:hospitalId/case-notifications/sms/templates/:id',

  ANALYTICS_EXPORT_STREAM: 'analytics/v1/stream',
  ANALYTICS_FILES: 'analytics/v1/files',

  INTERNAL_TASKS: 'guardianvets/v3/internal-tasks',
  INTERNAL_TASK: 'guardianvets/v3/internal-tasks/:id',
  INTERNAL_TASK_RESOLVE: 'guardianvets/v3/internal-tasks/resolve',
  INTERNAL_TASK_PENDING: 'guardianvets/v3/internal-tasks/pending',
  INTERNAL_TASKS_ASSIGNEE: 'guardianvets/v3/internal-tasks/assignee',
  INTERNAL_TASK_C0MMENTS: 'guardianvets/v3/internal-tasks/:id/comments',
  INTERNAL_TASKS_PENDING_COUNT: 'guardianvets/v3/internal-tasks/pending-count',
  DELETE_INTERNAL_TASK_C0MMENT:
    'guardianvets/v3/internal-tasks/:id/comments/:commentId',
  UPDATE_INTERNAL_TASK_C0MMENT:
    'guardianvets/v3/internal-tasks/:id/comments/:commentId',

  // dynamic-flow
  GET_GENERAL_DYNAMIC_FORM_LIST: 'guardianvets/v3/dynamic-flows',
  GET_GENERAL_DYNAMIC_FORM: 'guardianvets/v3/dynamic-flows/:formId',
  UPDATE_GENERAL_DYNAMIC_FORM: 'guardianvets/v3/dynamic-flows/:formId',
  GET_GENERAL_DYNAMIC_FORM_WIDGETS_LIST:
    'guardianvets/v3/dynamic-flows/widgets',
  GET_HOSPITAL_DYNAMIC_FORM_LIST:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flows',
  GET_HOSPITAL_DYNAMIC_CASE_FORM:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flows/active',
  CREATE_HOSPITAL_DYNAMIC_CASE_FORM:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flows',
  UPDATE_HOSPITAL_DYNAMIC_CASE_FORM:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flows/:formId',
  GET_HOSPITAL_DYNAMIC_CASE_FORM_BY_ID:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flows/:formId',
  GET_HOSPITAL_DYNAMIC_FORM_WIDGETS_LIST:
    'guardianvets/v3/hospitals/:hospitalId/dynamic-flow-widgets',

  // scheduling-matrix
  GET_HOSPITAL_SCHEDULING_APPOINTMENT_TYPES:
    'guardianvets/v3/hospitals/:hospitalId/appointment-types',
  GET_HOSPITAL_SCHEDULING_MATRIX_CONFIG:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrix-config',
  UPDATE_HOSPITAL_SCHEDULING_MATRIX_CONFIG:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrix-config',
  GET_HOSPITAL_SCHEDULING_MATRICES:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrices',
  GET_HOSPITAL_SCHEDULING_MATRIX:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrices/:matrixId',
  CREATE_HOSPITAL_SCHEDULING_MATRIX:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrices',
  UPDATE_HOSPITAL_SCHEDULING_MATRIX:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrices/:matrixId',
  DELETE_HOSPITAL_SCHEDULING_MATRIX:
    'guardianvets/v3/hospitals/:hospitalId/scheduling-matrices/:matrixId',

  // pet owner

  PO_AUTH_REFRESH: 'talk/v1/clientauth/auth/refresh',
  PO_AUTH_SMS_CODE: 'talk/v1/clientauth/sendcode/:hospitalId',
  PO_AUTH_LOGIN: 'talk/v1/clientauth/auth/:hospitalId',
  PO_SIGN_UP: 'guardianvets/v3/client/current/complete',
  PO_SIGN_OUT: 'guardianvets/v3/client/current/logout',

  PO_PROFILE: 'guardianvets/v3/client/current',
  PO_ADD_PET: 'guardianvets/v3/client/current/add-pet',

  // video calls
  VIDEO_CALL_TOKEN: 'talk/v1/video-call/token',
  VIDEO_CALL_STATUS: 'talk/v1/video-call/status',

  // virtual exams
  VIRTUAL_EXAMS_SCHEDULE: 'guardianvets/v3/calendar/virtual-exam/schedule',

  // appointment booking
  APPOINTMENT_SCHEDULE: 'guardianvets/v3/hospital/create-calendar-appointment',
  APPOINTMENT_DELETE: 'guardianvets/v3/hospital/calendar-appointment',
  APPOINTMENT_LIST: 'guardianvets/v3/hospital/calendar-appointment',

  // subscriptions
  SUBSCRIPTION_PRODUCTS: 'payments/products',
  SUBSCRIPTION_PRODUCT: 'payments/products/:id',
  SUBSCRIPTION_PRODUCT_ACTIVE: 'payments/products/:id/activate',
  SUBSCRIPTIONS: 'payments/me/subscriptions',
  UNSUBSCRIBE: 'payments/me/subscriptions/:id',
  UNSUBSCRIBE_BY_HOSPITAL: 'payments/hospitals/:hospitalId/subscriptions/:id',
  GET_HOSPITAL_SUBSCRIBERS: 'payments/hospitals/:hospitalId/subscribers',
  HOSPITAL_PAYMENT_SETTINGS: 'payments/hospitals/:hospitalId/settings',

  //Customer-feedback
  GET_HOSPITAL_LIST: 'guardianvets/v3/feedback-form/get-hospitals',
  GET_CUSTOMER_FEEDBACK_LIST: 'guardianvets/v3/feedback-form/analytics',
  SEND_TYPE_FORM: 'guardianvets/v3/feedback-form/send-feedback-form',
  ASSIGN_TYPE_FORM: 'guardianvets/v3/feedback-form/assign-type-form',
  CHECK_ASSIGN_HOSPITAL:
    'guardianvets/v3/feedback-form/check-type-form-hospital',
  CHECK_FEEDBACK: 'guardianvets/v3/feedback-form/verify-token',
  GET_EXPORT_REPORT: 'guardianvets/v3/feedback-form/download',

  //Activation
  ACTIVATION_STATUS: 'guardianvets/v1/hospital/update/status/last-update',

  //Google-Auth
  GOOGLE_AUTH_TOKEN: 'guardianvets/v3/hospital/calendar-appointment/oauth',
  CALENDAR_WATCH: 'guardianvets/v3/hospital/calendar-watch/create',

  //Master-Records

  CHEIF_COMPLAINTS: 'guardianvets/v3/cases/complaints-category',
};

const { base, talk, payments, analytics, longQueries } = Config.apiOverride;
if (base || talk || payments || analytics || longQueries) {
  const entries = URL as Record<string, string>;
  Object.entries(entries).forEach(([key, value]) => {
    if (base && value.startsWith('guardianvets')) {
      entries[key] = value.replace('guardianvets/v', base + 'v');
    }
    if (longQueries && longQueries.some((query) => value.startsWith(query))) {
      entries[key] = value.replace('guardianvets/', '');
    }
    if (talk && value.startsWith('talk')) {
      entries[key] = value.replace('talk/v', talk + 'v');
    }
    if (analytics && value.startsWith('analytics')) {
      entries[key] = value.replace('analytics/v', analytics + 'v');
    }
    if (payments && value.startsWith('payments')) {
      entries[key] = value.replace('payments/', payments);
    }
    if (key === 'GET_CASE_NOTIFICATIONS' || key === 'DEVICE_CAPABILITY_TOKEN') {
      console.log(key, value, entries[key]);
    }
  });

  URL = entries as typeof URL;
}

export { URL };
