import { useField, FieldArray } from 'formik';
import { FlexLayout, InputField } from '@gv/triage-components';

import * as Styles from './styles';
import { FormFieldArrayProps } from './types';

export const FormFieldArray = ({
  name,
  type,
  label,
  addLabel,
  placeholder,
  disableDeleteLast,
}: FormFieldArrayProps) => {
  const [{ value }] = useField<string[]>(name);

  return (
    <FlexLayout fullWidth flexDirection="column">
      {label && <h4>{label}</h4>}

      <FieldArray
        name={name}
        render={({ push, remove }) => (
          <div>
            <FlexLayout fullWidth flexDirection="column">
              {value.map((_, index) => (
                <Styles.FieldContainer
                  key={`input-field-array-${name}-${index}`}
                  $margin={
                    index !== value.length - 1 ? `0px 0px 8px 0px` : undefined
                  }
                >
                  <InputField
                    type={type}
                    placeholder={placeholder}
                    name={`${name}[${index}]`}
                  />
                  <Styles.DeleteButton
                    onClick={() => remove(index)}
                    disabled={disableDeleteLast ? value.length < 2 : undefined}
                  />
                </Styles.FieldContainer>
              ))}
            </FlexLayout>

            <Styles.AddButton text={addLabel} onClick={() => push('')} />
          </div>
        )}
      />
    </FlexLayout>
  );
};
