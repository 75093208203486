import { Case, CaseFormStep, ConsultationStep } from 'types';
import { baseSteps, discardSteps } from 'store/slices/case-form-step/config';

export const getInitialStepState = (
  formInitialStep?: Case['current_step'],
  formMaxOpenedStep?: Case['max_opened_step'],
  hasTask?: boolean
) => {
  console.log('getInitialStepState', {
    hasTask,
    formInitialStep,
    formMaxOpenedStep,
  });
  let stepsList: CaseFormStep['stepsList'] = [...baseSteps];

  if (!hasTask) {
    stepsList.splice(0, 1);
  }

  if (!formInitialStep || !hasTask) {
    const defaultStep = stepsList[0];
    return {
      currentStepIndex: 0,
      stepsList: stepsList,
      currentStep: defaultStep,
      maxOpenedStep: defaultStep,
    };
  }

  if (
    formInitialStep === ConsultationStep.DeadlineIssue ||
    (formInitialStep === ConsultationStep.Greeting &&
      formMaxOpenedStep === ConsultationStep.DeadlineIssue)
  ) {
    stepsList = discardSteps;
  }

  const index = stepsList.indexOf(formInitialStep);

  return {
    stepsList,
    currentStepIndex: index,
    currentStep: formInitialStep,
    maxOpenedStep: formMaxOpenedStep ?? formInitialStep,
  };
};
