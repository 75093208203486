import styled from 'styled-components';
import { FlexLayout, StandardButton } from '@gv/triage-components';

export const Container = styled(FlexLayout)`
  width: 100%;
`;

export const Button = styled(StandardButton)`
  width: 40px;
`;
