import { CorporateGroup, CallOutcomeDetailsType } from 'types';

import { CorporateGroupedData } from './types';

export const groupData = (
  data: CorporateGroup[] | undefined
): CorporateGroupedData | undefined => {
  return data?.reduce<CorporateGroupedData>((result, current) => {
    const fieldName = current.subType as CallOutcomeDetailsType;

    if (fieldName in result) {
      result[fieldName]?.push(current.departmentHospital);
    } else {
      result[fieldName] = [current.departmentHospital];
    }

    return result;
  }, {});
};
