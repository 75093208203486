import dayjs from 'dayjs';
import { useMemo, useEffect } from 'react';
import { useField, FormikValues, useFormikContext } from 'formik';
import {
  Utils,
  Messages,
  useAlert,
  isMobile,
  FlexLayout,
  InputField,
  DateTimeField,
  StandardButton,
  ButtonStyleTypes,
  CheckboxFieldGroup,
  HospitalClientType,
  CustomInvoiceOption,
} from '@gv/triage-components';

import { Config } from 'config';
import { PaymentCardDetails, AppointmentTypeField } from 'components';
import {
  TextOutStep,
  ClientsGroup,
  PetOwnerTemplate,
  MessageRecipientType,
} from 'types';
import {
  useFormEntities,
  DepartmentSelect,
  callOutcomeModel,
  CheckProtocolsInfo,
} from 'components/cases/form';

import * as Styles from './styles';
import { PriceFieldProps, AppointmentScheduledProps } from './types';

const { appointment } = callOutcomeModel;
const { formatPrice, formatPhone } = Utils.Helpers;

const priceFormat = '$0.00';
const requiredText = '(Required)';

const PriceField = ({ name, value }: PriceFieldProps) => (
  <FlexLayout gap={8} alignItems="center">
    <p>{name}</p> <Styles.Dots /> <p className="semibold">{value}</p>
  </FlexLayout>
);

export const AppointmentScheduled = ({
  hideNotes,
  setPayment,
  hospitalPrices,
  corporateOptions,
  clientTypeOptions,
  hideCheckProtocols,
  isCorporateFetching,
  isActiveHospitalPayment,
}: AppointmentScheduledProps) => {
  const mobile = isMobile();
  const { showErrorAlert } = useAlert();
  const { client, hospital, hospitalId } = useFormEntities();
  const { values, isValid } = useFormikContext<FormikValues>();
  const [, , currentInvoiceHelpers] = useField('currentInvoice');
  const [, , emailForInvoiceHelpers] = useField('email_for_invoice');

  const appointmentTypeValue = values?.deposit_invoice_id;
  const isCustomOption = appointmentTypeValue === CustomInvoiceOption.Custom;

  const currentPrice = useMemo(
    () =>
      appointmentTypeValue &&
      hospitalPrices?.find(
        (value) => value.id.toString() === appointmentTypeValue
      ),
    [hospitalPrices, appointmentTypeValue]
  );

  const isNewClientDeposit = useMemo(
    () =>
      values?.current_client === HospitalClientType.Yes &&
      currentPrice?.clientsGroup === ClientsGroup.NewClients,
    [values?.current_client, currentPrice?.clientsGroup]
  );

  const isPriceWithDeposit = useMemo(
    () => currentPrice?.depositFixed || currentPrice?.depositPercentage,
    [currentPrice?.depositFixed, currentPrice?.depositPercentage]
  );

  const hasAccessToClientType = useMemo(
    () =>
      Config.helpers.featureAvailableForHospital(
        hospitalId,
        'CALL_OUTCOME_CLIENT_TYPE'
      ),
    [hospitalId]
  );

  useEffect(() => {
    if (currentPrice?.id) {
      currentInvoiceHelpers.setValue(currentPrice);
    }
  }, [currentPrice]);

  useEffect(() => {
    if (client?.email) {
      emailForInvoiceHelpers.setValue(client.email);
    }
  }, [client?.email]);

  const depositValue =
    currentPrice?.depositFixed / 100 ||
    (currentPrice?.totalPrice / 100) * (currentPrice?.depositPercentage / 100);

  const dateTimeField = (
    <DateTimeField
      dateLabel="Date"
      timeLabel="Time"
      timezone={hospital?.timezone}
      name={appointment.scheduled.datetime}
    />
  );

  return (
    <>
      <DepartmentSelect
        options={corporateOptions}
        isDisabled={isCorporateFetching}
        name={appointment.scheduled.corporateHospitalId}
      />

      <CheckProtocolsInfo
        hideNotes={hideNotes}
        hideCheckProtocols={hideCheckProtocols}
      >
        {isActiveHospitalPayment ? (
          <FlexLayout gap={16} fullWidth flexDirection="column">
            <FlexLayout gap={24} flexDirection={mobile ? 'column' : 'row'}>
              <FlexLayout flex={1} gap={16} flexDirection="column">
                <AppointmentTypeField
                  name="deposit_invoice_id"
                  hospitalPrices={hospitalPrices}
                />
                {dateTimeField}

                {isCustomOption && (
                  <InputField
                    name="customService"
                    requiredText={requiredText}
                    placeholder="Add appointment"
                    label="What appointment client needs?"
                  />
                )}
              </FlexLayout>

              <PaymentCardDetails title="Payment details">
                {!!appointmentTypeValue && !isCustomOption ? (
                  <FlexLayout flexDirection="column">
                    <p>Please confirm details with clients before schedule.</p>
                    <PriceField
                      name="Total price"
                      value={formatPrice(
                        currentPrice?.totalPrice / 100,
                        priceFormat
                      )}
                    />
                    {!isNewClientDeposit && (
                      <>
                        {(currentPrice?.depositFixed ||
                          currentPrice?.depositPercentage) && (
                          <PriceField
                            name="Deposit amount"
                            value={formatPrice(depositValue, priceFormat)}
                          />
                        )}
                        {!!currentPrice?.paymentTerm && (
                          <PriceField
                            name="Payment terms"
                            value={dayjs
                              .duration({
                                milliseconds: +currentPrice.paymentTerm,
                              })
                              .asHours()}
                          />
                        )}
                      </>
                    )}
                    {(isNewClientDeposit || !isPriceWithDeposit) && (
                      <p>
                        Please notify client that hospital contact and charge
                        for this appointment after.
                      </p>
                    )}
                  </FlexLayout>
                ) : (
                  <p>
                    {isCustomOption
                      ? 'Please notify client that hospital contact and charge for this appointment after.'
                      : 'Please select appointment type to see the fee and payment terms details.'}
                  </p>
                )}
              </PaymentCardDetails>
            </FlexLayout>

            <FlexLayout flexDirection="column">
              <p>
                Please ensure that the client's email is added if a payment link
                is required in the email. The email will be automatically sent
                after the case submission.{' '}
                <Styles.Required className="semibold">
                  {requiredText}
                </Styles.Required>
              </p>
              <InputField placeholder="Add email" name="email_for_invoice" />
            </FlexLayout>

            {!isCustomOption && !isNewClientDeposit && isPriceWithDeposit && (
              <div>
                <StandardButton
                  disabled={!isValid}
                  text="Text payment link"
                  styleType={ButtonStyleTypes.Outline}
                  onClick={() => {
                    const clientPhones = client?.phones;
                    if (!clientPhones?.length) {
                      return showErrorAlert(
                        Messages.ACTION_UNAVAILABLE_CLIENT_NO_PHONES
                      );
                    }
                    setPayment?.({
                      name: client?.name,
                      phones: clientPhones,
                      textOutStep: TextOutStep.TemplateInfo,
                      value: [formatPhone(clientPhones[0])],
                      petOwnerTemplate: PetOwnerTemplate.Payment,
                      selectedRecipientType: MessageRecipientType.PetOwner,
                    });
                  }}
                />
              </div>
            )}
          </FlexLayout>
        ) : (
          <>
            {hasAccessToClientType && (
              <CheckboxFieldGroup
                label="Client type"
                items={clientTypeOptions}
                name={appointment.scheduled.clientType}
              />
            )}

            {dateTimeField}
          </>
        )}
      </CheckProtocolsInfo>
    </>
  );
};
