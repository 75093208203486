import styled, { css } from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Pet = styled(FlexLayout)<{ $selected: boolean }>`
  gap: 16px;
  padding: 24px;
  cursor: pointer;
  align-items: center;

  ${({ theme, $selected }) => css`
    border-radius: ${theme.borderRadius};
    box-shadow: inset 0 0 0 ${$selected ? 2 : 1}px
      ${theme.config.numberCard.border};

    background-color: ${$selected
      ? theme.config.authPO.sidebar.background
      : 'transparent'};

    &:hover {
      background-color: ${theme.config.table.grey.hover.bg};
    }

    &:active {
      background-color: ${theme.config.table.grey.action.bg};
    }

    svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${theme.config.text.primary};
      }
    }
  `}
`;
