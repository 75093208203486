import styled, { css } from 'styled-components';

export const EmergencyScript = styled.div`
  padding: 16px;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    background-color: ${theme.colors.pigmentIndigo.secondary.standard};
  `}
`;

export const EmergencyText = styled.div`
  margin-bottom: 16px;
`;
