import { Icon } from '@gv/triage-components';

import { WalkthroughStep } from 'types/data';

import { target } from '../config';

export const data = {
  [target[WalkthroughStep.Analytics]]: {
    title: 'Analytics',
    items: [
      {
        icon: <Icon.Pie />,
        text: 'return on Investment (ROI)',
      },
      {
        icon: <Icon.Column />,
        text: 'calls by type',
      },
      {
        text: 'call volume',
        icon: <Icon.Line />,
      },
    ],
  },
  [target[WalkthroughStep.Cases]]: {
    title: 'Cases',
    items: [
      {
        icon: <Icon.FileList />,
        text: 'review all cases',
      },
      {
        icon: <Icon.Search />,
        text: 'search for a case',
      },
      {
        icon: <Icon.FileInfo />,
        text: 'view case details and listen to recordings',
      },
    ],
  },
  [target[WalkthroughStep.Calendar]]: {
    title: 'Calendar',
    items: [
      {
        icon: <Icon.Hospital />,
        text: 'manage clinic hours and on call hours',
      },
      {
        icon: <Icon.Call />,
        text: 'manage on call hours',
      },
      {
        icon: <Icon.Clock />,
        text: 'request additional coverage',
      },
    ],
  },
  [target[WalkthroughStep.HelpCenter]]: {
    title: 'Help Center',
    items: [
      {
        text: 'contact support',
        icon: <Icon.ChatIncoming />,
      },
      {
        icon: <Icon.Calendar />,
        text: 'submit additional coverage request',
      },
      {
        icon: <Icon.Help />,
        text: 'find additional information about platform work',
      },
    ],
  },
  [target[WalkthroughStep.ActionItems]]: {
    title: 'Tasks',
    items: [
      {
        icon: <Icon.SecondaryList />,
        text: 'view all tasks associated with new cases',
      },
      {
        icon: <Icon.OnTheWay />,
        text: 'see most recent clients on their way to the clinic',
      },
      { icon: <Icon.Voicemail />, text: 'review and listen all voicemails' },
    ],
  },
  [target[WalkthroughStep.MyAccount]]: {
    title: 'My account',
    items: [
      {
        text: 'sign in/out.',
        icon: <Icon.Logout />,
      },
      {
        icon: <Icon.Sun />,
        text: 'define preferred, appearance',
      },
      {
        icon: <Icon.User />,
        text: 'update profile information',
      },
      {
        icon: <Icon.Lock />,
        text: 'reset your password',
      },
    ],
  },
  [target[WalkthroughStep.Acceptance]]: {
    title: 'Acceptance board',
    items: [
      {
        icon: <Icon.Team />,
        text: 'set up your capacity status and wait times now',
      },
      {
        icon: <Icon.Eye />,
        text: 'update us on what species and cases types you see now',
      },
      {
        icon: <Icon.OnTheWay />,
        text: 'if you want us to send walk-ins or drop-offs',
      },
    ],
  },
  [target[WalkthroughStep.HospitalProfile]]: {
    title: 'Hospital profile',
    items: [
      {
        icon: <Icon.Hospital />,
        text: 'update information on protocols, hospital, and emergency',
      },
      {
        icon: <Icon.Team />,
        text: 'review and manage users',
      },
      {
        icon: <Icon.Smile />,
        text: 'set up greetings',
      },
      {
        icon: <Icon.Pawprint />,
        text: 'review and manage clients',
      },
    ],
  },
};
