import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { TwilioPhoneNumber } from 'types';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import {
  cacheByIdArg,
  providesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { sortWhiteboardConfig } from './helpers';
import {
  Hospital,
  SetIVRRequest,
  POHospitalInfo,
  WhiteboardConfig,
  HospitalListData,
  GVTalkStatusData,
  SetRingGroupRequest,
  HospitalListRequest,
  UpdateStatusRequest,
  PhoneNumbersResponse,
  HospitalListResponse,
  HospitalBasicResponse,
  AdminHospitalListData,
  BuyPhoneNumberRequest,
  UnsubscribedEmailsData,
  POHospitalInfoResponse,
  HospitalDetailsResponse,
  GetGVTalkStatusResponse,
  WhiteboardConfigResponse,
  SearchPhoneNumberRequest,
  AdminHospitalListRequest,
  ResubscribeEmailsRequest,
  SetGVTriageStatusPayload,
  UpdateGVTalkStatusRequest,
  AdminHospitalListResponse,
  UnsubscribedEmailsRequest,
  UnsubscribedEmailsResponse,
  HospitalBasicUpdatePayload,
  HospitalBasicCreatePayload,
  UpdateWhiteboardConfigRequest,
} from './types';

const {
  Helpers: { offset },
  Query: { addParamsToUrl },
} = Utils;

const hospitalsAdapter = createEntityAdapter<Hospital, number>({
  selectId: (item) => item.id,
});

const tagType = 'Hospitals';
const configTagType = 'WhiteboardConfig';
const emailsTagType = 'UnsubscribedEmails';

export const hospitalsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'hospitalsApi',
  tagTypes: [tagType, configTagType, emailsTagType],
  endpoints: (build) => ({
    sendWelcomeMail: build.mutation<void, number>({
      query: (id) => ({
        data: { id },
        method: 'post',
        url: URL.SEND_WELCOME_EMAIL,
      }),
    }),

    delete: build.mutation<string, number>({
      invalidatesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_HOSPITAL, { id }),
      }),
    }),

    create: build.mutation<Hospital, HospitalBasicCreatePayload>({
      transformResponse: (response: HospitalBasicResponse) =>
        response.data.Hospital,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ADD_HOSPITAL,
      }),
    }),

    basicInfo: build.query<POHospitalInfo, number>({
      transformResponse: (response: POHospitalInfoResponse) => response.data,
      query: (hospitalId) => ({
        method: 'get',
        url: generateUrl(URL.GET_BASIC_HOSPITAL_INFO, { hospitalId }),
      }),
    }),

    updateGVTalkStatus: build.mutation<void, UpdateGVTalkStatusRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, status }) => ({
        method: 'post',
        data: { status },
        url: generateUrl(URL.HOSPITAL_GV_TALK_STATUS, { hospitalId: id }),
      }),
    }),

    details: build.query<Hospital, string>({
      providesTags: cacheByIdArg(tagType),
      transformResponse: (response: HospitalDetailsResponse) =>
        response.data.hospital,
      query: (id) => ({
        method: 'get',
        url: URL.GET_HOSPITAL_BASIC_INFO.replace('{id}', id),
      }),
    }),

    setGVTriageStatus: build.mutation<Hospital, SetGVTriageStatusPayload>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        method: 'post',
        url: URL.SET_GV_TRIAGE_STATUS,
        data: {
          hospital_id: id,
          ...data,
        },
      }),
    }),

    getGVTalkStatus: build.query<GVTalkStatusData, string>({
      providesTags: cacheByIdArg(tagType),
      transformResponse: (response: GetGVTalkStatusResponse) => response.data,
      query: (id) => ({
        method: 'get',
        url: generateUrl(URL.HOSPITAL_GV_TALK_STATUS, { hospitalId: id }),
      }),
    }),

    update: build.mutation<Hospital, HospitalBasicUpdatePayload>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: HospitalBasicResponse) =>
        response.data.Hospital,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.ADD_HOSPITAL,
      }),
    }),

    getWhiteboardConfig: build.query<WhiteboardConfig, number>({
      providesTags: [configTagType],
      transformResponse: (response: WhiteboardConfigResponse) =>
        sortWhiteboardConfig(response.data),
      query: (id) => ({
        method: 'get',
        url: generateUrl(URL.GET_HOSPITAL_WHITEBOARD_CONFIG, {
          hospitalId: id,
        }),
      }),
    }),

    purchasePhoneNumber: build.mutation<void, BuyPhoneNumberRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, hospital, ...props }) => ({
        method: 'post',
        url: hospital ? URL.PURCHASE_NUMBER : URL.PURCHASE_NUMBER_VENDOR,
        data: {
          hospital,
          hospital_id: id,
          ...props,
        },
      }),
    }),

    unsubscribedEmails: build.query<
      UnsubscribedEmailsData,
      UnsubscribedEmailsRequest
    >({
      providesTags: [emailsTagType],
      transformResponse: (response: UnsubscribedEmailsResponse) =>
        response.data,
      query: ({ hospitalId }) => ({
        method: 'get',
        url: generateUrl(URL.HOSPITAL_UNSUBSCRIBED_EMAILS, { hospitalId }),
      }),
    }),

    resubscribeEmails: build.mutation<void, ResubscribeEmailsRequest>({
      invalidatesTags: [emailsTagType],
      query: ({ emails, hospitalId }) => {
        const url = generateUrl(URL.HOSPITAL_RESUBSCRIBE_EMAILS, {
          hospitalId,
        });

        return {
          method: 'post',
          url: addParamsToUrl(url, { emails: emails.join(',') }),
        };
      },
    }),

    updateWhiteboardConfig: build.mutation<
      WhiteboardConfig,
      UpdateWhiteboardConfigRequest
    >({
      invalidatesTags: [configTagType],
      transformResponse: (response: WhiteboardConfigResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.GET_HOSPITAL_WHITEBOARD_CONFIG, { hospitalId }),
      }),
    }),

    updateStatus: build.mutation<Hospital, UpdateStatusRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: HospitalDetailsResponse) =>
        response.data.hospital,
      query: ({ id, status, ...rest }) => ({
        method: 'put',
        url: URL.UPDATE_HOSPITAL_STATUS,
        data: {
          status,
          hospitalId: id,
          ...rest,
        },
      }),
    }),

    setIVR: build.mutation<void, SetIVRRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, isNew, ivrId, oldIvrId }) => ({
        method: isNew ? 'post' : 'put',
        url: isNew ? URL.ADD_INITIAL_IVR : URL.UPDATE_INITIAL_IVR,
        data: {
          hospital_id: id,
          ...(isNew
            ? { ivr_master_id: ivrId }
            : { newIvr_id: ivrId, oldIvr_id: oldIvrId }),
        },
      }),
    }),

    phoneNumbers: build.query<TwilioPhoneNumber[], SearchPhoneNumberRequest>({
      transformResponse: (response: PhoneNumbersResponse) =>
        response.data.numbers,
      query: ({ hospital, ...props }) => {
        const data: Record<string, any> = {
          hospital,
          ...props,
        };
        return {
          data,
          method: 'post',
          url: hospital ? URL.SEARCH_NUMBER : URL.SEARCH_NUMBER_VENDOR,
        };
      },
    }),

    setRingGroup: build.mutation<void, SetRingGroupRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, isNew, ringGroupId, oldRingGroupId }) => ({
        method: isNew ? 'post' : 'put',
        url: isNew ? URL.ADD_INITIAL_RING : URL.UPDATE_INITIAL_RING,
        data: {
          hospital_id: id,
          ...(isNew
            ? { ring_group_id: ringGroupId }
            : {
                newRingGroup_id: ringGroupId,
                oldRingGroup_id: oldRingGroupId,
              }),
        },
      }),
    }),

    list: build.query<HospitalListData, HospitalListRequest>({
      providesTags: (result) => providesList(tagType)(result?.hospitals),
      transformResponse: (response: HospitalListResponse) => response.data,
      query: ({
        page,
        limit = 20,
        searchKeyword,
        hospital = true,
        ...props
      }) => {
        const data: Record<string, any> = {
          limit,
          hospital,
          searchKeyword,
          offset: offset(page!, limit),
          ...props,
        };
        return {
          data,
          method: 'post',
          url: searchKeyword ? URL.SEARCH_HOSPITAL : URL.GET_HOSPITAL_ALL,
        };
      },
    }),

    adminList: build.query<AdminHospitalListData, AdminHospitalListRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: AdminHospitalListResponse) => response.data,
      query: ({
        page,
        state,
        search,
        status,
        services,
        speciality,
        limit = 20,
        protocolLevel,
      }) => {
        const url = addParamsToUrl(URL.GET_ADMIN_HOSPITAL_LIST, {
          limit,
          search,
          status,
          offset: offset(page, limit),
          state: state.length === 0 ? null : state,
          speciality: speciality.length === 0 ? null : speciality,
          protocol_level: protocolLevel.length === 0 ? null : protocolLevel,
          filters: services.length === 0 ? null : JSON.stringify({ services }),
        });

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const hospitalsSlice = createSlice({
  reducers: {},
  name: 'hospitals',
  initialState: hospitalsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      hospitalsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        hospitalsAdapter.upsertMany(state, payload.hospitals);
      }
    );

    builder.addMatcher(
      hospitalsApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        hospitalsAdapter.upsertOne(state, payload);
      }
    );

    builder.addMatcher(
      hospitalsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        hospitalsAdapter.updateOne(state, {
          changes: payload,
          id: hospitalsAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(
      hospitalsApi.endpoints.delete.matchFulfilled,
      (state, { meta }) => {
        hospitalsAdapter.removeOne(state, meta.arg.originalArgs);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      hospitalsAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useAdminListQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
  useSetIVRMutation,
  useBasicInfoQuery,
  usePhoneNumbersQuery,
  useGetGVTalkStatusQuery,
  useUpdateStatusMutation,
  useSetRingGroupMutation,
  useLazyPhoneNumbersQuery,
  useUnsubscribedEmailsQuery,
  useSendWelcomeMailMutation,
  useGetWhiteboardConfigQuery,
  useResubscribeEmailsMutation,
  useSetGVTriageStatusMutation,
  useUpdateGVTalkStatusMutation,
  usePurchasePhoneNumberMutation,
  useUpdateWhiteboardConfigMutation,
} = hospitalsApi;

export { tagType as hospitalTagType };
