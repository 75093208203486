import { Messages } from '@gv/triage-components';

import { api } from 'api';
import { GetChatMessages } from 'api/comm/types';
import { createAppAsyncThunk } from 'store/helpers';
import { selectCurrentTask } from 'store/api/action-queue/selectors';

import { types } from './types';
import { addMessage, addOldMessages } from '.';

export const sendChatMessage = createAppAsyncThunk(
  types.sendChatMessage,
  async ({ message }: { message: string }, { dispatch, getState }) => {
    const state = getState();
    const task = selectCurrentTask(state);

    if (!task?.channelName) {
      return Promise.reject(new Error(Messages.NO_TASK));
    }

    try {
      const sendMessageResponse = await api.comm.sendChatMessage({
        text: message,
        channelName: task.channelName,
      });

      const { smsMessage } = sendMessageResponse.data.data;
      dispatch(
        addMessage({ message: smsMessage, channelName: task.channelName })
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const loadOldMessages = createAppAsyncThunk(
  types.loadOldMessages,
  async (props: GetChatMessages, { dispatch }) => {
    try {
      const oldMessagesResponse = await api.comm.getChatMessages({
        ...props,
      });
      dispatch(
        addOldMessages({
          channelName: props.channelName,
          data: oldMessagesResponse.data.data,
        })
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
