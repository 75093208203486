import { BasePageProps } from '@gv/triage-components';

import { useHospitalDataApi } from 'hooks';
import { useDetailsQuery as useCaseQuery } from 'store/api/cases';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';

export const useCaseDetailsApi = (id: string): BasePageProps => {
  const props = { refetchOnMountOrArgChange: true };
  const caseDetails = useCaseQuery({ id }, props);
  const { client_id, hospital_id } = caseDetails?.data || {};

  const clientDetails = useClientQuery(
    {
      allowDeleted: true,
      id: String(client_id),
      hospitalId: hospital_id ? String(hospital_id) : undefined,
    },
    {
      skip: !client_id,
      ...props,
    }
  );

  const hospitalDetails = useHospitalDataApi(String(hospital_id));

  const isErrror =
    caseDetails?.isError || clientDetails?.isError || hospitalDetails?.isError;

  const error =
    caseDetails?.error?.message ||
    clientDetails?.error?.message ||
    hospitalDetails?.error;

  const isLoading =
    (caseDetails?.isFetching && !caseDetails?.data) ||
    (clientDetails?.isFetching && !clientDetails?.data) ||
    hospitalDetails?.isLoading;

  return {
    error,
    isError: isErrror,
    isLoading: isLoading,
    refetch: () => {
      if (caseDetails?.isError) {
        caseDetails.refetch();
      }
      if (clientDetails?.isError) {
        clientDetails.refetch();
      }
      if (hospitalDetails?.isError) {
        hospitalDetails.refetch();
      }
    },
  };
};
