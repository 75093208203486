import styled, { css } from 'styled-components';
import { FlexLayout, EllipsisText } from '@gv/triage-components';
import { bodySemibold } from '@gv/triage-components/dist/styles/mixins/fonts';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CaseInner = styled(FlexLayout)`
  gap: 24px;
  padding: 16px;
  flex-direction: column;
`;

export const ButtonsGrid = styled.div<{ hasTask: boolean }>`
  display: grid;
  grid-gap: 24px;

  ${({ theme, hasTask }) => css`
    grid-template: ${hasTask ? '1fr 1fr / 1fr 1fr' : '1fr'};

    &::before {
      background-color: ${theme.colors.blue.primary.standard};
    }
  `}
`;

export const Inner = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

export const Para = styled.h2`
  font-size: 20px;
  font-weight: 600;
`;

export const ParaDynamic = styled.h2`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
`;

export const Para2 = styled.h2`
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0px;
`;

export const Container = styled.div`
  border: 1px solid #bdb4b4;
  border-radius: 8px;
  padding: 20px 20px 5px 20px;
  margin: 10px 0;
`;

export const Title = styled(EllipsisText)`
  ${bodySemibold};
`;

export const Tooltip = styled.div`
  padding: 8px;
  max-width: 260px;
`;
