import { CaseMedication } from 'types';
import { parseArray } from 'utils/helpers';
import { commonBadgeProps } from 'components/cases';

import { AssessmentBadgeProps } from './types';

export const parseMedications = (list?: string) => {
  return parseArray(list) as CaseMedication[];
};

export const parseNotes = (medication?: CaseMedication): string[] => {
  const notes: string[] = [];

  if (medication) {
    const {
      medications_type: type,
      medications_strenght: strenght,
      medications_frequency: frequency,
    } = medication;

    if (strenght) {
      notes.push(`Strength: ${strenght}`);
    }
    if (frequency) {
      notes.push(`Freguency: ${frequency}`);
    }
    if (type) {
      notes.push(`Type: ${type}`);
    }
  }

  return notes;
};

export const getBadgeProps = (props: AssessmentBadgeProps) => {
  const { style, ...commonProps } = commonBadgeProps;
  const { name, index, onClick, selected, medications } = props;

  const isClickable = parseNotes(medications[index]).length > 0;

  return {
    ...commonProps,
    text: name,
    onClick: isClickable ? onClick : undefined,
    ...(selected !== index && { transparent: true }),
    style: { ...style, cursor: isClickable ? 'pointer' : 'default' },
  };
};
