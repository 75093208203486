import { useContext } from 'react';
import { FlexLayout, EllipsisText } from '@gv/triage-components';

import { AudioContext } from '.';
import { formatAudioTime } from './helpers';

export const Time = () => {
  const { src, state } = useContext(AudioContext);

  if (!src || !state) {
    return null;
  }

  const formattedTime = formatAudioTime(state.time);
  const formattedDuration = formatAudioTime(state.duration);

  return (
    <FlexLayout justifyContent="space-between">
      <EllipsisText>{formattedTime}</EllipsisText>
      <EllipsisText>{formattedDuration}</EllipsisText>
    </FlexLayout>
  );
};
