import { lazy } from 'react';

const Login = lazy(() => import('./login'));
const Verifyotp = lazy(() => import('./verify-otp'));
const LoginPO = lazy(() => import('./login-po'));
const SignUpPO = lazy(() => import('./sign-up-po'));
const ResetPassword = lazy(() => import('./reset-password'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const SubmitFeedback = lazy(() => import('./submit-feedback'));

export {
  Login,
  LoginPO,
  SignUpPO,
  Verifyotp,
  ResetPassword,
  ForgotPassword,
  SubmitFeedback,
};
