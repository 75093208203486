import dayjs from 'dayjs';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import {
  Utils,
  Hospital,
  PriorityType,
  CorporateType,
  RadioGridBaseProps,
  HospitalOnCallInfo,
} from '@gv/triage-components';

import { Config } from 'config';
import { assessmentsFieldsNames } from 'types/data';
import {
  AssessmentField,
  CallOutcomeType,
  FormCallOutcome,
  CallOutcomeEmergency,
  CallOutcomeAdministrative,
} from 'types';

import {
  getSelectedCallOutcome,
  transformNestedObjectToFlat,
  transformFlatObjectToNested,
} from './helpers';

export const assessmentsRadioGridInputProps: RadioGridBaseProps = {
  cols: [
    {
      title: 'Normal',
      value: 'Normal',
    },
    {
      title: 'Abnormal',
      value: 'Abnormal',
    },
    {
      smallTitle: 'Cannot assess',
      value: 'Owner cannot assess',
      title: 'Owner cannot assess',
    },
  ],
  rows: [
    {
      title: 'General Stability',
      fieldName: AssessmentField.GeneralStability,
    },
    {
      title: 'Responsiveness',
      fieldName: AssessmentField.NeurologicalStability,
    },
    {
      title: 'Breathing',
      fieldName: AssessmentField.RespiratoryStability,
    },
    {
      title: 'Pain',
      fieldName: AssessmentField.PainLevelAssessedByCaller,
    },
    {
      title: 'Activity',
      fieldName: AssessmentField.ActivityLevel,
    },
    {
      title: 'Appetite',
      fieldName: AssessmentField.Appetite,
    },
  ],
};

const requiredArray = Yup.array().min(1).required();

const validateCallOutcomeSubType = (subType: CallOutcomeType) =>
  Yup.object().shape({
    type: Yup.string().test(
      'subTypeValidation',
      'Please select option',
      (value, context) => {
        const contextGeneric = context as unknown as {
          from: { value?: Record<string, unknown> }[];
        };
        const callOutcomeValue = contextGeneric.from[1].value;
        return !(callOutcomeValue?.type === subType && !value);
      }
    ),
  });

const confirmDVMFieldSchema = (
  onCallInfoField: 'erExamFees' | 'speciesSeenOnCall'
) =>
  Yup.boolean().when(['callOutcome', 'selectedDVMOnCallInfo'], {
    then: (booleanSchema) => booleanSchema.oneOf([true], 'Please confirm'),
    is: (
      callOutcome: FormCallOutcome,
      selectedDVMOnCallInfo: Pick<
        HospitalOnCallInfo,
        'erExamFees' | 'speciesSeenOnCall'
      >
    ) => {
      const { subType, mainType } = getSelectedCallOutcome(callOutcome) ?? {};

      return Boolean(
        mainType === CallOutcomeType.Emergency &&
          subType === CallOutcomeEmergency.ReferToOnCall &&
          selectedDVMOnCallInfo?.[onCallInfoField]
      );
    },
  });

export const assessmentValidationShape = Object.fromEntries(
  assessmentsFieldsNames.map((fieldName) => [
    fieldName,
    Yup.string().when('priority_of_concern', {
      is: (value: PriorityType) => value && value !== PriorityType.DVM,
      then: (stringSchema) =>
        stringSchema.required('Please fill out the stability assessment table'),
    }),
  ])
);

export const caseFormValidationShape = {
  ...Utils.Validation.schedulePaymentValidation,
  pets: requiredArray,
  chief_complaints: requiredArray,
  priority_of_concern: Yup.string(),
  isWarningModalShown: Yup.boolean(),
  isActiveHospitalPayment: Yup.boolean(),
  problem_summary: Yup.string().trim().required().min(10),
  is_emergency_fee_confirm: confirmDVMFieldSchema('erExamFees'),
  is_species_seen_confirm: confirmDVMFieldSchema('speciesSeenOnCall'),

  client_id: Yup.number()
    .required('Please select client')
    .moreThan(0, 'Please select client')
    .typeError('Please select client'),
  callOutcome: Yup.object().shape({
    type: Yup.string().required('Please select call outcome'),
    [CallOutcomeType.Emergency]: validateCallOutcomeSubType(
      CallOutcomeType.Emergency
    ),
    [CallOutcomeType.Appointment]: validateCallOutcomeSubType(
      CallOutcomeType.Appointment
    ),
    [CallOutcomeType.TransferToBackline]: validateCallOutcomeSubType(
      CallOutcomeType.TransferToBackline
    ),
    [CallOutcomeType.Administrative]: Yup.object().when('type', {
      otherwise: (schema) => schema.notRequired(),
      is: (value: CallOutcomeType) => value === CallOutcomeType.Administrative,
      then: (schema) =>
        schema.shape({
          [CallOutcomeAdministrative.Call]: Yup.object().shape({
            outcomes: Yup.array()
              .min(
                1,
                'Administrative call option field must have at least 1 items'
              )
              .required('Please select outcome'),
          }),
        }),
    }),
  }),
};

const assessmentShouldBeFilled = (isCVT: boolean, hospitalId: Hospital['id']) =>
  isCVT && !Config.helpers.featureDisabledForHospital(hospitalId, 'ASSESSMENT');

export const getIsSmsInputVisible = (hospital?: Hospital, isEdit?: boolean) => {
  const hasTextOutTemplatesAccess = Config.helpers.featureAvailableForHospital(
    Number(hospital?.id),
    'TEXT_OUT_TEMPLATES'
  );
  return !isEdit && hasTextOutTemplatesAccess && !!hospital?.has_sms_template;
};

export const getCaseFormValidationSchema = ({
  isCVT,
  isEdit,
  hospital,
  isShowValidationEmail,
}: {
  isCVT: boolean;
  isEdit?: boolean;
  hospital?: Hospital;
  isShowValidationEmail?: boolean;
}) => {
  let resultShape: Yup.ObjectShape = caseFormValidationShape;

  const isZipCodeRequired = Config.helpers.featureAvailableForHospital(
    hospital?.id,
    'REQUIRED_CLIENT_ZIP_CODE'
  );

  resultShape = {
    ...resultShape,
    survey: Yup.object().shape({
      feedback_email: isShowValidationEmail
        ? Yup.string()
            .email('Invalid email address')
            .required('Feedback Email is required')
        : Yup.string(),
    }),
  };

  const isSmsInputVisible = getIsSmsInputVisible(hospital, isEdit);
  if (isSmsInputVisible) {
    resultShape = {
      ...resultShape,
      sms_status: Yup.string().required('Required field'),
    };
  }

  if (hospital && assessmentShouldBeFilled(isCVT, hospital.id)) {
    resultShape = { ...resultShape, ...assessmentValidationShape };
  }

  if (hospital && hospital.corporate_type !== CorporateType.Main) {
    resultShape = {
      ...resultShape,
      current_client: Yup.string().required('Required field'),
    };
  }

  if (isZipCodeRequired) {
    resultShape = {
      ...resultShape,
      hasClientZipCode: Yup.boolean()
        .oneOf([true], "The client doesn't have a zip code")
        .required("The client doesn't have a zip code"),
    };
  }

  return Yup.object().shape(resultShape);
};

export const validateFormCallOutcome = (
  form: FormikValues,
  timezone?: string
) => {
  const { callOutcome } = form;
  let errors: Record<string, string> = {};
  if (callOutcome) {
    const data = transformNestedObjectToFlat(callOutcome);
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('datetime')) {
        let valueDate = dayjs(value);
        let now = dayjs();
        if (timezone) {
          valueDate = valueDate.tz(timezone);
          now = now.tz(timezone);
        }
        if (valueDate.isBefore(now, 'day')) {
          errors = {
            ...transformFlatObjectToNested({
              [`callOutcome.${key}`]: "Date can't be past",
            }),
          };
        }
      }
    }
  }
  return errors;
};

export const validateWalkinDropoff = (prefix: string) =>
  Yup.string().test(
    `${prefix}_status`,
    'Please select an option',
    function (value) {
      if (
        value === 'true' &&
        !this.parent[`${prefix}_new_client`] &&
        !this.parent[`${prefix}_current_client`]
      ) {
        return false;
      }
      return true;
    }
  );

export const walkinDropoffValidationSchema = Yup.object().shape({
  walkin_status: validateWalkinDropoff('walkin'),
  dropoff_status: validateWalkinDropoff('dropoff'),
});

export const userPersonalInfoShape = {
  lastName: Yup.string().required(),
  firstName: Yup.string().required(),
  phone: Utils.Validation.phoneNumber('phone'),
};

export const detailsHoursFields = [
  { name: 'er_hours', title: 'Emergency hours' },
  { name: 'urgent_care_hours', title: 'Urgent care hours' },
];

export const hoursFields = [
  { name: 'clinic_hours', title: 'Hours of operation' },
  ...detailsHoursFields,
];

export const assessmentRequired = (
  values: FormikValues,
  isCVT: boolean
): boolean =>
  values.priority_of_concern &&
  values.priority_of_concern !== PriorityType.DVM &&
  assessmentShouldBeFilled(isCVT, values.hospital_id);

export const caseFormModel = {
  cvtPlan: {
    label: 'Plan for CVT',
  },
  assessmentNote: {
    label: 'Assessment',
  },
  problemSummary: {
    label: 'Subjective / Problem summary',
  },
};

const weekdays = dayjs.weekdays();
const shortWeekdays = dayjs.weekdaysShort();

export const weekdayLongName = Object.fromEntries(
  shortWeekdays.map((weekday, index) => [weekday, weekdays[index]])
);

const validateAudioField = (concurrentFieldName: string) =>
  Yup.object()
    .shape({
      src: Yup.string(),
    })
    .nullable()
    .test(
      'oneOfRequiredAudio',
      'Audio is a required field',
      (value, context) => value?.src || context.parent[concurrentFieldName]?.src
    );

export const audioRecordingsValidationSchema = Yup.object().shape({
  record: validateAudioField('upload'),
  upload: validateAudioField('record'),
  greeting_name: Yup.string().trim().required(),
});

export const allowedHospitalUpdateFields = [
  'id',
  'name',
  'email',
  'phone',
  'address',
  'icon_url',
  'websites',
  'services',
  'er_hours',
  'zip_code',
  'state_id',
  'faq_links',
  'city_name',
  'speciality',
  'clinic_hours',
  'product_type',
  'internal_notes',
  'protocol_level',
  'slack_channels',
  'corporate_type',
  'backline_phones',
  'additional_info',
  'email_voice_notif',
  'urgent_care_hours',
  'has_payment_access',
  'email_consult_notif',
  'has_pet_owner_portal',
  'is_dynamic_flow_enabled',
  'accepted_appointment_types',
  'species_seen_business_hour',
  'is_pet_owner_subscriptions_enabled',
];
