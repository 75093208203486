import { useMemo, useEffect } from 'react';
import { FormikValues, useFormikContext, FormikContextType } from 'formik';
import {
  Col,
  Row,
  InputField,
  FlexLayout,
  SelectField,
  ErrorMessage,
  SectionHeader,
} from '@gv/triage-components';

import { useListQuery as useBreedsQuery } from 'store/api/breeds';
import { useListQuery as useGendersQuery } from 'store/api/genders';
import { useListQuery as useSpeciesQuery } from 'store/api/species';

import * as Styles from '../styles';

import { PetFieldsProps } from './types';
import { getSelectOptions } from './config';

const PetFields = ({ setLoading }: PetFieldsProps): JSX.Element => {
  const {
    errors,
    touched,
    setTouched,
    isSubmitting,
    isValidating,
    initialValues,
    setFieldValue,
    setFieldTouched,
    values: { breed, species },
  }: FormikContextType<FormikValues> = useFormikContext();

  const showError = Boolean(errors?.years);

  const { data: petSpecies = [], isFetching: speciesFetching } =
    useSpeciesQuery();

  const { data: petBreeds = [], isFetching: breedsFetching } = useBreedsQuery();

  const { data: petGenders = [], isFetching: gendersFetching } =
    useGendersQuery();

  useEffect(() => {
    if (isSubmitting || isValidating) {
      return;
    }
    const fields = ['months', 'days', 'years'];
    let someTouched = false;
    let allTouched = true;
    for (const field of fields) {
      const result = touched[field];
      if (result) {
        someTouched = true;
      } else {
        allTouched = false;
      }
    }

    if (someTouched && !allTouched) {
      const newTouched = { ...touched };
      for (const field of fields) {
        newTouched[field] = true;
      }
      setTouched(newTouched, true);
    }
  }, [touched, isSubmitting, isValidating]);

  const speciesOptions = useMemo(
    () => getSelectOptions({ values: petSpecies }),
    [petSpecies]
  );

  const breedOptions = useMemo(
    () =>
      getSelectOptions({
        otherOption: true,
        initialValue: initialValues?.breed,
        values: species
          ? petBreeds.filter((breedItem) => breedItem.species?.name === species)
          : [],
      }),
    [petBreeds, initialValues, species]
  );

  const genderOptions = useMemo(
    () =>
      getSelectOptions({
        initialValue: initialValues?.gender,
        values: species
          ? petGenders.filter(
              (genderItem) => String(genderItem.species?.name) === species
            )
          : [],
      }),
    [petGenders, initialValues, species]
  );

  useEffect(() => {
    if (!setLoading) {
      return;
    }
    if (!speciesFetching && !gendersFetching && !breedsFetching) {
      setLoading(false);
    }
  }, [setLoading, breedsFetching, gendersFetching, speciesFetching]);

  return (
    <>
      <Row>
        <Col col={4}>
          <InputField name="name" label="Pet name" placeholder="Pet name" />
        </Col>
      </Row>

      <SectionHeader title="Details" />

      <Row>
        <Col col={4}>
          <SelectField
            onlyValue
            isSearchable
            name="species"
            value={species}
            label="Select species"
            options={speciesOptions}
            placeholder="Select species"
            isDisabled={speciesFetching}
            onChange={() => {
              const resetFields = ['breed', 'gender', 'otherBreed'];
              resetFields.forEach((field) => {
                setFieldTouched(field, false);
                setFieldValue(field, '');
              });
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col col={4}>
          <FlexLayout gap={24} flexDirection="column">
            <SelectField
              onlyValue
              name="breed"
              isSearchable
              label="Select breed"
              options={breedOptions}
              placeholder="Select breed"
              isDisabled={!species || speciesFetching || breedsFetching}
            />

            {breed === 'Other' && (
              <InputField name="otherBreed" placeholder="Enter breed" />
            )}
          </FlexLayout>
        </Col>

        <Col col={4}>
          <SelectField
            onlyValue
            isSearchable
            name="gender"
            label="Select gender"
            options={genderOptions}
            placeholder="Select gender"
            isDisabled={!species || speciesFetching || gendersFetching}
          />
        </Col>
      </Row>

      <Styles.StyledTitle>Age</Styles.StyledTitle>

      <Row>
        <Col col={8}>
          <FlexLayout gap={16}>
            <Styles.AgeField
              number
              name="years"
              label="Years"
              placeholder="Years"
              showError={showError}
            />

            <Styles.AgeField
              number
              name="months"
              label="Months"
              placeholder="Months"
              showError={showError}
            />

            <Styles.AgeField
              number
              name="days"
              label="Days"
              placeholder="Days"
              showError={showError}
            />
          </FlexLayout>

          {errors?.years && touched?.years && (
            <ErrorMessage>{errors?.years}</ErrorMessage>
          )}
        </Col>
      </Row>
    </>
  );
};

export { PetFields };
