import styled, { css } from 'styled-components';
import { Icon, Badges as TCBadges } from '@gv/triage-components';

export const RightBadges = styled(TCBadges)`
  justify-content: flex-end;
`;

export const Time = styled.span`
  white-space: nowrap;
`;

export const icon = css`
  width: 16px;
  height: 16px;
  path {
    fill: ${({ theme }) => theme.config.common.icon};
  }
`;

export const UserIcon = styled(Icon.User)`
  ${icon};
`;

export const HospitalIcon = styled(Icon.Hospital)`
  ${icon};
`;
