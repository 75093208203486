import dayjs from 'dayjs';
import { useMemo, useEffect, useCallback } from 'react';
import {
  Utils,
  Dialog,
  useAlert,
  Messages,
  isMobile,
  mapQuery,
  useFilter,
  ListHeader,
  FlexLayout,
  useNavigate,
  ButtonColors,
  EllipsisText,
  handleMutation,
  useSearchQuery,
  ButtonStyleTypes,
  DeleteButtonWithRef,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { Routes } from 'config';
import { StripeCardInfo } from 'types';
import { PaymentCard, NewEntityButton } from 'components';
import {
  useListCardQuery,
  useDeleteCardMutation,
  useSetDefaultCardMutation,
} from 'store/api/clients';

import * as Styles from './styles';
import { ListProps } from './types';
import {
  titles,
  templates,
  cellsMobile,
  searchPaths,
  getSortedCardList,
} from './config';

const { formatDate } = Utils.Date;

export const List = ({
  asPage,
  onPressAdd,
  onSelectCardId,
  selectedCardId,
}: ListProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();

  const [deleteCard, deleteMutation] = useDeleteCardMutation();
  const [setDefaultCard, setDefaultCardMutation] = useSetDefaultCardMutation();

  const { data, ...props } = useListCardQuery();

  const onSuccessDelete = useCallback(
    () => showSuccessAlert(`Payment card ${Messages.DELETED_SUCCESSFULLY}`),
    []
  );
  const onSuccessSetDefault = useCallback(
    () => showSuccessAlert('Payment card is set as default'),
    []
  );
  handleMutation({ ...deleteMutation, onSuccess: onSuccessDelete });
  handleMutation({ ...setDefaultCardMutation, onSuccess: onSuccessSetDefault });

  const { cards, defaultCardId } = useMemo(
    () => getSortedCardList(data),
    [data]
  );

  const { debounceQuery, ...search } = useSearchQuery();
  const filteredData = useFilter<StripeCardInfo>({
    data: cards,
    paths: searchPaths,
    query: debounceQuery,
  });

  useEffect(() => {
    if (defaultCardId) {
      onSelectCardId?.(defaultCardId);
    }
  }, [defaultCardId]);

  return (
    <Styles.List
      {...mapQuery(props)}
      {...search}
      $asPage={asPage}
      count={filteredData?.length}
      header={<ListHeader titles={titles} template={templates.desktop} />}
      searchBarRightSlot={
        <NewEntityButton
          text="Add new card"
          onClick={() =>
            onPressAdd ? onPressAdd() : navigate(Routes.Profile.Cards.New)
          }
        />
      }
    >
      {filteredData?.map((value) => {
        const isDefaultCard = !!value.default_payment_method;
        const isSelectedCard = selectedCardId === value.id;
        const deleteDialog = (
          <Dialog
            title="Delete card"
            confirmText="Delete"
            onConfirm={() => deleteCard(value.id)}
            text={
              <>
                You are going to delete card&nbsp;
                <PaymentCard bold inline numberOnly card={value.card} />. Are
                you sure you want to do that?
              </>
            }
            trigger={
              <DeleteButtonWithRef
                fullWidth={mobile}
                transparent={!mobile}
                padding={mobile ? '4px 0' : '0'}
                text={mobile ? 'Delete card' : undefined}
                colorType={mobile ? ButtonColors.LightRed : ButtonColors.Red}
                styleType={
                  mobile ? ButtonStyleTypes.Standard : ButtonStyleTypes.Ghost
                }
              />
            }
          />
        );

        const values = [
          <PaymentCard numberOnly card={value.card} />,
          <PaymentCard expirationOnly card={value.card} />,
          formatDate(dayjs.unix(value.created), 'll'),
          <FlexLayout gap={16} justifyContent={mobile ? undefined : 'flex-end'}>
            {isDefaultCard ? (
              <EllipsisText className="semibold">Default card</EllipsisText>
            ) : (
              <Dialog
                title="Set card as default"
                confirmStyle={ButtonColors.Primary}
                onConfirm={() => setDefaultCard(value.id)}
                text={
                  <>
                    You are going to set this card as default&nbsp;
                    {<PaymentCard bold inline numberOnly card={value.card} />}.
                    Are you sure you want to do that?
                  </>
                }
                trigger={
                  <StandardButtonWithRef
                    padding="0"
                    transparent
                    fullWidth={mobile}
                    text="Make default"
                    styleType={ButtonStyleTypes.Ghost}
                    justifyContent={mobile ? 'flex-start' : undefined}
                  />
                }
              />
            )}
            {!mobile && deleteDialog}
          </FlexLayout>,
        ];

        if (mobile && !onSelectCardId) {
          values.push(deleteDialog);
        }

        return (
          <Styles.Card
            key={value.id}
            nativeIconColor
            values={values}
            mobileHeaders={titles}
            cellsMobile={cellsMobile}
            template={templates.desktop}
            tabletTemplate={templates.tablet}
            mobileTemplate={templates.mobile}
            onClick={() => onSelectCardId?.(value.id)}
            isDefault={selectedCardId ? isSelectedCard : isDefaultCard}
          />
        );
      })}
    </Styles.List>
  );
};

export type { ListProps };
