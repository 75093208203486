import { Route, Routes, Navigate } from 'react-router-dom';
import { lazy, useMemo, Suspense, useEffect, useLayoutEffect } from 'react';
import {
  Details,
  NotFound,
  mapQuery,
  useNavigate,
} from '@gv/triage-components';

import { LoginPO } from 'pages/auth';
import { Routes as RoutePaths } from 'config';
import { useAppSelector, useAppDispatch } from 'store';
import { useBasicInfoQuery } from 'store/api/hospitals';
import { usePetOwnerHomePath, usePOAuthRedirectPath } from 'hooks';
import { selectIsSubscriptionActivationRequired } from 'store/slices/subscriptions/selectors';
import {
  removeAuthPath,
  selectIsLoggedIn,
  selectAuthClient,
} from 'store/slices/auth';

import App from './app';
import * as Styles from './styles';
import { AppProps } from './types';
import { AppContainer } from './app-container';

const Subscribe = lazy(() => import('pages/subscribe/products'));
const PetOwnerDashboard = lazy(() => import('pages/pet-owner-dashboard/home'));

const AppPrivate = lazy(() => import('./app-pet-owner-private'));

export const AppPetOwner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { homePath, hospitalId } = usePetOwnerHomePath();
  const restorePath = usePOAuthRedirectPath();

  const { data, ...props } = useBasicInfoQuery(+hospitalId!, {
    skip: !hospitalId,
  });

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isSubscriptionActivationRequired = useAppSelector(
    selectIsSubscriptionActivationRequired
  );
  const client = useAppSelector(selectAuthClient);

  const isCompleted = client ? !client.inactive : undefined;

  useEffect(() => {
    if (isCompleted === false) {
      navigate(homePath + RoutePaths.Auth.SignUp);
    }
  }, [isCompleted, navigate]);

  useLayoutEffect(() => {
    if (restorePath) {
      dispatch(removeAuthPath());
      navigate(restorePath, { replace: true });
    }
  }, [dispatch, restorePath]);

  const homeRoute = restorePath || homePath || RoutePaths.NotFound;

  const mappedRoutes = useMemo(() => {
    const routes: AppProps['mappedRoutes'] = {
      [RoutePaths.Auth.Login]: LoginPO,
    };
    if (isSubscriptionActivationRequired && !isLoggedIn) {
      const subscribePath =
        homePath + '/' + RoutePaths.PetOwnerDashboard.Subscribe.Home;
      routes['/' + RoutePaths.PetOwnerDashboard.Subscribe.Home] = {
        Component: Subscribe,
        allowedOptions: {
          redirectTo: homePath,
          allowed: isSubscriptionActivationRequired,
        },
      };
      routes['/*'] = {
        Component: PetOwnerDashboard,
        allowedOptions: {
          redirectTo: subscribePath,
          allowed: !isSubscriptionActivationRequired,
        },
      };
    } else if (!isLoggedIn) {
      routes['/'] = PetOwnerDashboard;
    }
    return routes;
  }, [homePath, isLoggedIn, isSubscriptionActivationRequired]);

  return (
    <AppContainer>
      <Details {...mapQuery(props)}>
        <Styles.POContainer>
          <Routes>
            <Route
              path={RoutePaths.PetOwnerDashboard.base + '/*'}
              element={
                <App
                  homeRoute={homeRoute}
                  savePathOnAuthRedirect
                  PrivateApp={AppPrivate}
                  mappedRoutes={mappedRoutes}
                  authRoute={homeRoute + RoutePaths.Auth.Login}
                />
              }
            />
            <Route
              path={RoutePaths.NotFound}
              element={
                <Suspense>
                  <NotFound homePath="" />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate replace to={homeRoute} />} />
          </Routes>
        </Styles.POContainer>
      </Details>
    </AppContainer>
  );
};
