import { useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import {
  Details,
  FlexLayout,
  mapQueries,
  RadioFieldGroup,
  CheckboxFieldGroup,
} from '@gv/triage-components';

import { Config } from 'config';
import { clientTypeOptions } from 'types/data';
import { getCaseFormSelectedPets } from 'utils/helpers';
import { CallOutcomeEmergency, CallOutcomeAppointment } from 'types';
import { useListQuery as useCallFlowsListQuery } from 'store/api/call-flows';
import {
  ClientTasksList,
  useClientTasksProps,
  AppointmentScheduled,
} from 'components';
import {
  useReferToPPH,
  useReferToOnCall,
  useReferToOtherEr,
  useSchedulingPaymentForm,
} from 'hooks/refer';
import {
  useFormEntities,
  callOutcomeModel,
  CheckProtocolsInfo,
  CaseFormTextOutProps,
  CorporateOptionsProps,
} from 'components/cases/form';

import { DeclinedReason } from '../declined-reason';
import { DepartmentSelect } from '../department-select';

import { Queries } from './types';
import * as Styles from './styles';
import { ReferToEr } from './refer-to-er';
import { ReferToPPH } from './refer-to-pph';
import { ReferToOnCall } from './refer-to-on-call';
import {
  availabilityDaysItems,
  availabilityTimeItems,
  appointmentRecommendationItems,
} from './config';

const { appointment } = callOutcomeModel;

export const CallOutcomeRefer = ({
  setDVM,
  setService,
  setPayment,
  setReferral,
  corporateOptions,
  isCorporateFetching,
}: CaseFormTextOutProps & CorporateOptionsProps) => {
  const { values } = useFormikContext<FormikValues>();
  const { client, hospital, hospitalId, isMainHospital } = useFormEntities();
  const clientTasksProps = useClientTasksProps();
  const { queryProps, selectedCallOutcome, isActiveHospitalPayment } =
    useSchedulingPaymentForm();

  const { toxicityProps, requestProps: referToPPHRequestProps } = useReferToPPH(
    {
      setService,
    }
  );
  const { onCallDocProps, requestProps: referOnCallRequestProps } =
    useReferToOnCall({
      setDVM,
    });
  const { requestProps: referToOtherErRequestProps, ...otherErProps } =
    useReferToOtherEr({
      setReferral,
    });

  const selectedCallOutcomeCorporate =
    selectedCallOutcome?.details?.corporate_hospital_id;

  const isReasonAvailable = useMemo(
    () => Config.helpers.isAppointmentReasonAvailable(hospitalId),
    [hospitalId]
  );

  const { callFlows, hospitalPrices, ...props } = mapQueries<Queries>({
    hospitalPrices: queryProps,
    referToPPH: referToPPHRequestProps,
    referOnCall: referOnCallRequestProps,
    referToOther: referToOtherErRequestProps,
    callFlows: useCallFlowsListQuery(
      {
        page: 1,
        limit: 10000,
        hideDuringCall: false,
        hospitalId: String(hospitalId),
      },
      {
        skip:
          (hospital && !('corporate_type' in hospital)) ||
          isMainHospital ||
          (!isMainHospital && !hospitalId),
      }
    ),
  });

  const selectedPets = useMemo(
    () => getCaseFormSelectedPets(client?.pets, values.pets, values.pet_id),
    [client?.pets, values.pets, values.pet_id]
  );

  const selectedPetsNames = selectedPets?.map((item) => item.name)?.join(', ');
  const hospitalName = hospital?.name;

  const content = useMemo(() => {
    if (selectedCallOutcome) {
      switch (selectedCallOutcome.subType) {
        case CallOutcomeAppointment.Recommended:
          return (
            <>
              <DepartmentSelect
                isDisabled={isCorporateFetching}
                options={corporateOptions?.recommended}
                name={appointment.recommended.corporateHospitalId}
              />

              <CheckProtocolsInfo>
                <Styles.Script>
                  <span className="semibold">{selectedPetsNames}</span> is not
                  experiencing an emergency but does need to be seen. Are there
                  2 times that work for you this week or next to be seen at the{' '}
                  <span className="semibold">{hospitalName}</span>?
                </Styles.Script>

                <RadioFieldGroup
                  label="Select option"
                  items={appointmentRecommendationItems}
                  name={appointment.recommended.recommendation}
                />

                <FlexLayout gap={8} flexDirection="column">
                  <p className="semibold">Which is your availability?</p>

                  <FlexLayout gap={16} flexDirection="column">
                    <CheckboxFieldGroup
                      label="Days:"
                      items={availabilityDaysItems}
                      name={appointment.recommended.availabilityDays}
                      labelStyle={{
                        fontWeight: 400,
                      }}
                    />

                    <CheckboxFieldGroup
                      label="Time:"
                      items={availabilityTimeItems}
                      name={appointment.recommended.availabilityTime}
                      labelStyle={{
                        fontWeight: 400,
                      }}
                    />
                  </FlexLayout>
                </FlexLayout>
              </CheckProtocolsInfo>
            </>
          );
        case CallOutcomeAppointment.Scheduled:
          return (
            <AppointmentScheduled
              setPayment={setPayment}
              hospitalPrices={hospitalPrices}
              clientTypeOptions={clientTypeOptions}
              isCorporateFetching={isCorporateFetching}
              corporateOptions={corporateOptions?.scheduled}
              isActiveHospitalPayment={isActiveHospitalPayment}
            />
          );
        case CallOutcomeAppointment.Cancelled:
          return (
            <CheckProtocolsInfo>
              <ClientTasksList {...clientTasksProps} />
            </CheckProtocolsInfo>
          );
        case CallOutcomeAppointment.Declined:
          return (
            <CheckProtocolsInfo hideNotes={isReasonAvailable}>
              {isReasonAvailable && <DeclinedReason />}
            </CheckProtocolsInfo>
          );
        case CallOutcomeAppointment.Rescheduled:
          return <CheckProtocolsInfo />;
        case CallOutcomeEmergency.ReferToOnCall:
          return (
            <ReferToOnCall
              {...onCallDocProps}
              callFlows={callFlows}
              isQueriesLoading={props.isLoading}
              options={corporateOptions?.referToOnCall}
              isCorporateFetching={isCorporateFetching}
            />
          );
        case CallOutcomeEmergency.ReferToEr:
          return (
            <ReferToEr
              {...otherErProps}
              {...otherErProps.referToERProps}
              setReferral={setReferral}
            />
          );
        case CallOutcomeEmergency.ReferToPPH:
          return (
            <ReferToPPH
              toxicityProps={toxicityProps}
              options={corporateOptions?.referToPPH}
              isCorporateFetching={isCorporateFetching}
            />
          );
      }
    }

    return <></>;
  }, [
    setDVM,
    hospital,
    callFlows,
    setService,
    setReferral,
    otherErProps,
    toxicityProps,
    onCallDocProps,
    props.isLoading,
    corporateOptions,
    selectedPetsNames,
    isReasonAvailable,
    isCorporateFetching,
    selectedCallOutcome?.subType,
    selectedCallOutcomeCorporate,
  ]);

  return (
    <Details {...props} loaderAbsolutePosition>
      <FlexLayout gap={24} flexDirection="column">
        {content}
      </FlexLayout>
    </Details>
  );
};
