import { useMemo } from 'react';
import {
  List,
  Icon,
  Utils,
  Checkbox,
  ListCard,
  isMobile,
  isDesktop,
  EditButton,
  FlexLayout,
  useNavigate,
  ButtonColors,
  EllipsisText,
  handleMutation,
  StandardButton,
} from '@gv/triage-components';

import { useHospitalParams } from 'hooks';
import { useUpdatePetMutation } from 'store/api/clients';

import * as Styles from './styles';
import { PetsCells, PetsTemplate } from './config';
import { PetsListProps, PetListGlobalProps } from './types';

const {
  Helpers: { textValue },
  Date: { getFormattedAge },
} = Utils;

const mobileTitles = ['Species', 'Age', 'Breed', 'Gender'];

export const PetsListGlobal = ({
  data,
  clientId,
  editPath,
  prefixRow,
  className,
  forceMobileView,
  medicalRecordsPath,
  createHospitalTaskView,
  hasEditPermission = true,
}: PetListGlobalProps) => {
  const mobile = isMobile();
  const desktop = isDesktop();
  const navigate = useNavigate();
  const [update, mutation] = useUpdatePetMutation();

  handleMutation(mutation);

  const hasMedicalRecords = !!medicalRecordsPath;

  const { titles, template, cellsMobile } = useMemo(() => {
    /* eslint-disable @typescript-eslint/no-shadow */
    let titles = ['Pet', 'Species', 'Age', 'Breed', 'Gender', 'Deceased', ''];
    let template = PetsTemplate.default.desktop;
    let cellsMobile = PetsCells.mobile;
    /* eslint-enable */

    if (hasMedicalRecords) {
      template = template.replace('24px', '64px');

      const [first, ...rest] = cellsMobile;
      cellsMobile = [{ ...first, col: '1/5' }, ...rest];
    }

    if (prefixRow?.header) {
      titles = [...prefixRow.header, ...titles];
    }
    if (createHospitalTaskView) {
      template = PetsTemplate.petOwner.desktop;
      cellsMobile = PetsCells.petOwner;
    }
    if (prefixRow?.template) {
      template = `${prefixRow.template} ${template}`;
    }

    return { titles, template, cellsMobile };
  }, [
    hasMedicalRecords,
    prefixRow?.header,
    prefixRow?.template,
    createHospitalTaskView,
  ]);

  const isMobileView = forceMobileView || mobile;
  const mobProps = isMobileView ? { gap: 16, padding: '16px' } : {};

  return (
    <Styles.ListWrap className={className}>
      <List
        count={data?.length || 0}
        mainWrapperStyles={{ padding: '0' }}
        header={
          createHospitalTaskView || forceMobileView ? undefined : (
            <Styles.Header titles={titles} template={template} />
          )
        }
      >
        {!!data?.length &&
          data.map((pet, index) => {
            const name = (
              <FlexLayout gap={8}>
                {prefixRow?.values &&
                  (isMobileView || !desktop) &&
                  prefixRow.values(pet)}
                <EllipsisText className="semibold">
                  {textValue(pet.name)}
                </EllipsisText>
              </FlexLayout>
            );
            const species = textValue(pet?.species);

            const buttons = (
              <FlexLayout gap={16} justifyContent="flex-end">
                {hasEditPermission && (
                  <EditButton
                    disabled={pet.is_deceased || !editPath}
                    onClick={
                      editPath ? () => navigate(editPath(pet.id)) : undefined
                    }
                  />
                )}

                {medicalRecordsPath && (
                  <StandardButton
                    padding="0"
                    icon={<Icon.Case />}
                    tooltip="Medical Records"
                    colorType={ButtonColors.Transparent}
                    onClick={() => navigate(medicalRecordsPath(pet.id))}
                  />
                )}
              </FlexLayout>
            );

            let values = [name, species, buttons];

            if (!createHospitalTaskView) {
              const age = getFormattedAge(pet?.date_of_birth);
              const breed = textValue(pet?.breed);
              const gender = textValue(pet?.gender);

              const deceased = (
                <Checkbox
                  id={`deceased-${pet.id}`}
                  checked={pet?.is_deceased}
                  disabled={mutation.isLoading || !hasEditPermission}
                  label={!desktop || forceMobileView ? 'Deceased' : ''}
                  onChange={() => {
                    update({
                      pet_id: pet.id,
                      client_id: Number(clientId),
                      is_deceased: !pet.is_deceased,
                    });
                  }}
                />
              );

              values.splice(2, 0, age, breed, gender, deceased);
            }

            if (prefixRow?.values && !forceMobileView && desktop) {
              values = [...prefixRow.values(pet), ...values];
            }

            const mobileTemplate = createHospitalTaskView
              ? PetsTemplate.petOwner.mobile
              : PetsTemplate.default.mobile;

            return (
              <ListCard
                {...mobProps}
                isPropagation
                values={values}
                template={template}
                key={pet.id || index}
                cellsMobile={cellsMobile}
                cellsTablet={cellsMobile}
                disabled={pet?.is_deceased}
                mobileTemplate={mobileTemplate}
                tabletTemplate={mobileTemplate}
                forceMobileView={forceMobileView}
                gap={createHospitalTaskView ? 16 : undefined}
                padding={createHospitalTaskView ? '16px' : undefined}
                mobileHeaders={
                  createHospitalTaskView
                    ? undefined
                    : prefixRow
                      ? ['', ...mobileTitles]
                      : ['Name', ...mobileTitles]
                }
              />
            );
          })}
      </List>
    </Styles.ListWrap>
  );
};

export const PetsList = (props: PetsListProps) => {
  const { clientId } = useHospitalParams();
  return <PetsListGlobal clientId={clientId} {...props} />;
};
