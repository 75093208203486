import { ListCard, FlexLayout } from '@gv/triage-components';

import { AudioCardProps } from './types';
import { AudioPlayer } from './audio-player';

export const AudioCard = ({
  src,
  values,
  padding,
  template,
  setPlayingControlsRef,
}: AudioCardProps) => (
  <ListCard
    isPropagation
    values={values}
    padding={padding}
    template={template}
    innerFooter={
      <AudioPlayer
        value={{ src }}
        setPlayingControlsRef={setPlayingControlsRef}
      >
        <FlexLayout justifyContent="space-between">
          <FlexLayout gap={8} alignItems="flex-start">
            <AudioPlayer.Play />
            <AudioPlayer.Pause />
          </FlexLayout>

          <AudioPlayer.Time />
        </FlexLayout>
      </AudioPlayer>
    }
  />
);
