import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, IVRDetails } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  IVRData,
  CreateRequest,
  IVRListRequest,
  IVRListResponse,
  DeleteIVRRequest,
  IVRDetailsResponse,
  UpdateGlobalIVRRequest,
} from './types';

const tagTypes = ['IVR'];

export const ivrApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'ivrApi',
  endpoints: (build) => ({
    create: build.mutation<void, CreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_IVR_LIST,
      }),
    }),

    delete: build.mutation<void, DeleteIVRRequest>({
      invalidatesTags: tagTypes,
      query: ({ id }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_IVR, { id }),
      }),
    }),

    updateGlobal: build.mutation<void, UpdateGlobalIVRRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.UPDATE_IVR_GLOBAL,
      }),
    }),

    update: build.mutation<void, CreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: generateUrl(URL.DELETE_IVR, { id: data.id }),
      }),
    }),

    details: build.query<IVRDetails, DeleteIVRRequest>({
      providesTags: tagTypes,
      transformResponse: (response: IVRDetailsResponse) => response.data.ivr,
      query: ({ id }) => ({
        method: 'get',
        url: generateUrl(URL.DELETE_IVR, { id }),
      }),
    }),

    list: build.query<IVRData, IVRListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: IVRListResponse) => response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_IVR_LIST, {
          limit,
          search,
          offset: Utils.Helpers.offset(page, limit),
        });
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
  useUpdateGlobalMutation,
} = ivrApi;
