import { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import {
  Col,
  Row,
  Details,
  Messages,
  mapQuery,
  useAlert,
  FormFooter,
  useNavigate,
  ButtonTypes,
  ButtonColors,
  ErrorMessage,
  ThemeContext,
  StandardButton,
} from '@gv/triage-components';

import { useAppDispatch } from 'store';
import { cardsTag, clientsApi, useSetupIntentsQuery } from 'store/api/clients';

import * as Styles from './styles';
import { FormValues, CreateCardProps } from './types';
import { initialValues, getCardStyles } from './config';

export const Create = ({ asPage, footer, onCreate }: CreateCardProps) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { theme } = useContext(ThemeContext);
  const { handleError, showSuccessAlert } = useAlert();

  const { data, ...props } = useSetupIntentsQuery();

  const onSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>
  ) => {
    try {
      if (!stripe || !elements || !data?.client_secret) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      const { error, setupIntent } = await stripe.confirmCardSetup(
        data.client_secret,
        {
          payment_method: { card: cardElement! },
        }
      );

      if (error) {
        return setFieldError('cardNumber', error.message);
      }

      showSuccessAlert(`Payment card ${Messages.ADDED_SUCCESSFULLY}`);
      dispatch(clientsApi.util.invalidateTags([cardsTag]));
      if (onCreate && typeof setupIntent?.payment_method === 'string') {
        onCreate(setupIntent.payment_method);
      } else {
        navigate(-1);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Details {...mapQuery(props)}>
      <Formik<FormValues> onSubmit={onSubmit} initialValues={initialValues}>
        {({
          dirty,
          errors,
          values,
          isSubmitting,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => {
          const cardProps = {
            options: { style: getCardStyles(theme) },
          };
          const onChange = async (
            fieldName: keyof FormValues,
            value:
              | StripeCardCvcElementChangeEvent
              | StripeCardNumberElementChangeEvent
              | StripeCardExpiryElementChangeEvent
          ) => {
            await setFieldTouched(fieldName, true);
            await setFieldValue(fieldName, value.complete);
            if (value.complete) {
              setFieldError(fieldName, undefined);
            } else if (value.error) {
              setFieldError(fieldName, value.error?.message);
            }
          };
          const areAllFieldsCompleted =
            values.cardNumber && values.expDate && values.cvc;
          const isSubmitDisabled =
            isSubmitting || !dirty || !areAllFieldsCompleted;
          return (
            <Styles.Form>
              <Styles.Inner $asPage={asPage}>
                <Row>
                  <Col>
                    <Styles.Label>Card number</Styles.Label>
                    <Styles.InputContainer>
                      <CardNumberElement
                        {...cardProps}
                        onChange={(value) => onChange('cardNumber', value)}
                      />
                    </Styles.InputContainer>
                    {errors.cardNumber && (
                      <ErrorMessage>{errors.cardNumber}</ErrorMessage>
                    )}
                  </Col>
                  <Col col="6">
                    <Styles.Label>Exp. date</Styles.Label>
                    <Styles.InputContainer>
                      <CardExpiryElement
                        {...cardProps}
                        onChange={(value) => onChange('expDate', value)}
                      />
                    </Styles.InputContainer>
                    {errors.expDate && (
                      <ErrorMessage>{errors.expDate}</ErrorMessage>
                    )}
                  </Col>
                  <Col col="6">
                    <Styles.Label>CVV</Styles.Label>
                    <Styles.InputContainer>
                      <CardCvcElement
                        {...cardProps}
                        onChange={(value) => onChange('cvc', value)}
                      />
                    </Styles.InputContainer>
                    {errors.cvc && <ErrorMessage>{errors.cvc}</ErrorMessage>}
                  </Col>
                </Row>
              </Styles.Inner>

              {footer ? (
                footer(isSubmitDisabled)
              ) : (
                <FormFooter
                  right={
                    <>
                      <StandardButton
                        text="Cancel"
                        onClick={() => navigate(-1)}
                        colorType={ButtonColors.Secondary}
                      />
                      <StandardButton
                        text="Create"
                        type={ButtonTypes.Submit}
                        disabled={
                          isSubmitting || !dirty || !areAllFieldsCompleted
                        }
                      />
                    </>
                  }
                />
              )}
            </Styles.Form>
          );
        }}
      </Formik>
    </Details>
  );
};

export type { CreateCardProps };
