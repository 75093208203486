import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Utils } from '@gv/triage-components';

import { InternalTask } from 'types';
import { CreateRequest, UpdateRequest } from 'store/api/internal-team-tasks';

import { FormValues } from '../types';

export const getInitialValues = (data?: InternalTask): FormValues => ({
  title: data?.title || undefined,
  priority: data?.priority || undefined,
  description: data?.description || undefined,
  dueAt: data?.dueAt ? dayjs(data?.dueAt).toDate() : undefined,
  assignee: data?.assignee?.id
    ? { label: data.assignee.name!, value: data.assignee.id.toString() }
    : undefined,
});

export const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  priority: Yup.string().required(),
});

export const getPayload = (data: FormValues): CreateRequest | UpdateRequest => {
  const { dueAt, assignee } = data;

  const payload = {
    ...data,
    ...{ assigneeId: assignee?.value },
    ...{
      dueAt: dueAt ? Utils.Date.toLocaleISOString(dueAt) : null,
    },
  };

  delete payload.assignee;

  return payload;
};
