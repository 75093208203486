import { createSelector } from 'reselect';

import { RootState } from 'store/store';

const chatsStore = (store: RootState) => store?.caseLogs;

export const selectCaseLogEvent = (caseId?: number, id?: number) =>
  createSelector([chatsStore], (store) => {
    if (!caseId || !id || !store?.entities) {
      return undefined;
    }
    return store.entities[caseId]?.events.find((event) => event.id === id);
  });
