import { Message } from 'ably';
import debounce from 'lodash.debounce';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { OfficeChat, TypingFlow, AblyChannelName } from '@gv/triage-components';

import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';

import { useAblyChannel } from './useAblyChannel';
import { useAblyEventChannel } from './useAblyEvent';

const channelName = 'members:typing';

export const useTyping = (chat?: OfficeChat) => {
  const [isTyping, setIsTyping] = useState(false);
  const [typersIds, setTypersIds] = useState<number[]>([]);

  const { profileId: authProfileId } = useAppSelector(selectAuthUser) || {};
  const { id, isPublic, members = [] } = chat || {};

  const debounceEndRef = useRef<ReturnType<typeof debounce> | null>(null);

  const typers = useMemo(() => {
    if (!members.length || !typersIds.length || !authProfileId) {
      return [];
    }
    const typingMembers = members.filter(
      ({ ChatMember }) =>
        typersIds.includes(ChatMember.profileId) &&
        ChatMember.profileId !== authProfileId
    );
    return typingMembers.map(({ user }) => String(user?.name || user?.phone));
  }, [typersIds, authProfileId]);

  const membersIds = useMemo(() => {
    if (!members.length) {
      return [];
    }
    return members.map((member) => member.ChatMember.profileId);
  }, [members]);

  const getPayload = useCallback(
    (flow = TypingFlow.Started) => ({
      flow,
      isPublic,
      chatId: id,
      notifyIds: membersIds,
      profileId: authProfileId,
    }),
    [isPublic, id, membersIds, authProfileId]
  );

  const onEvent = (event: Message) => {
    const {
      flow,
      chatId,
      profileId,
      notifyIds,
      isPublic: isChatPublic,
    } = event.data;
    if (
      chatId !== id ||
      profileId === authProfileId ||
      (isChatPublic ? false : !notifyIds?.includes(profileId))
    ) {
      return;
    }

    const filtered = typersIds.filter((item) => item !== profileId);
    switch (flow) {
      case TypingFlow.Started:
        return setTypersIds([...filtered, profileId]);
      case TypingFlow.Ended:
        return setTypersIds(filtered);
      default:
        return;
    }
  };

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(channelName, onEvent, channel);

  const endTyping = () => {
    if (channel) {
      channel.publish(channelName, getPayload(TypingFlow.Ended));
      setIsTyping(false);
    }
  };

  const debounceTyping = debounce(() => setIsTyping(false), 1500);

  const startTyping = () => {
    debounceEndRef?.current?.cancel?.();
    const debounceEnd = debounce(endTyping, 2000);
    debounceEndRef.current = debounceEnd;
    debounceEnd();

    if (authProfileId && id && channel && !isTyping) {
      channel.publish(channelName, getPayload());
      setIsTyping(true);
      debounceTyping();
    }
  };

  useEffect(() => {
    return () => {
      endTyping();
    };
  }, [chat?.id, channel]);

  return { typers, onType: startTyping };
};
