import styled, { css } from 'styled-components';
import {
  Styles,
  ListCard,
  FlexLayout,
  EllipsisText,
} from '@gv/triage-components';

import { SummaryOptionProps } from './types';

const { heading3, heading2, bodyRegular, bodySemibold } = Styles.Font;

export const Card = styled(ListCard)`
  box-shadow: ${({ theme }) => theme.config.infoBlock.shadow};
`;

export const Dots = styled(FlexLayout)`
  flex: 1;
  border-top: 1px dashed ${({ theme }) => theme.colors.grey.secondary.standard};
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.secondary.standard};
`;

export const ValuesWrapper = styled(FlexLayout)<
  Pick<SummaryOptionProps, 'isBold' | 'isSummary'>
>`
  align-items: center;
  gap: 8px;

  ${({ isBold, isSummary }) => css`
    p:first-child {
      ${isBold ? bodySemibold : isSummary ? heading3 : bodyRegular};
    }

    p:last-child {
      ${isBold ? heading3 : isSummary ? heading2 : bodySemibold};
    }
  `}
`;

export const Title = styled(EllipsisText)`
  ${bodySemibold};
  color: ${({ theme }) => theme.config.table.head.title};
`;

export const Placeholder = styled(FlexLayout)`
  flex-direction: column;
  gap: 8px;

  svg path {
    fill: ${({ theme }) => theme.config.table.head.title};
  }
`;
