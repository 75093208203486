import { ChildrenProps } from '@gv/triage-components';
import { useState, useEffect, ReactNode, createContext } from 'react';

interface UserActionsContextProps {
  isUserClickHappened: boolean;
}

const getInitialState = (): UserActionsContextProps => ({
  isUserClickHappened: false,
});

export const UserActionsContext =
  createContext<UserActionsContextProps>(getInitialState());

export const UserActionsContextProvider = ({
  children,
}: ChildrenProps): ReactNode => {
  const [isUserClickHappened, setUserClickHappened] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    let unsubscribe: (() => void) | undefined = undefined;

    const happened = () => {
      setUserClickHappened(true);
      unsubscribe?.();
      isSubscribed = false;
    };

    const events = ['click', 'mousedown', 'touchstart', 'keypress'];
    for (const event of events) {
      document.addEventListener(event, happened);
    }
    unsubscribe = () => {
      for (const event of events) {
        document.removeEventListener(event, happened);
      }
    };

    return () => {
      if (isSubscribed) {
        unsubscribe?.();
      }
    };
  }, []);

  return (
    <UserActionsContext.Provider value={{ isUserClickHappened }}>
      {children}
    </UserActionsContext.Provider>
  );
};
