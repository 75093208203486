import { useContext } from 'react';
import { ChildrenProps } from '@gv/triage-components';

import { AudioContext } from '.';
import * as Styles from './styles';
import { RecordError } from './record-error';

export const PlayerContainer = ({ children }: ChildrenProps) => {
  const { error } = useContext(AudioContext);

  if (error) {
    return <RecordError />;
  }

  return (
    <Styles.Container gap={16} flexDirection="column">
      {children}
    </Styles.Container>
  );
};
