import { Formik } from 'formik';
import { useMemo, useCallback } from 'react';
import {
  Icon,
  Popup,
  Dialog,
  useAlert,
  FlexLayout,
  InputField,
  DialogType,
  EmojiPicker,
  ButtonTypes,
  ButtonColors,
  handleMutation,
  StandardButton,
  CurrentChatAction,
  StandardButtonWithRef,
  GVTalkAsideDialogProps,
} from '@gv/triage-components';

import {
  useEditFolderMutation,
  useEditSectionMutation,
  useCreateFolderMutation,
  useDeleteFolderMutation,
} from 'store/api/gv-chats';

import * as Styles from './styles';
import { DialogFormValues } from './types';
import {
  getMessage,
  suggestedNames,
  getInitialValues,
  getValidationSchema,
} from './config';

export const defaultCurentAction = {
  event: null,
  folder: null,
  section: null,
};

export const AsideDialogs = ({
  onClose,
  currentAction,
}: GVTalkAsideDialogProps) => {
  const { showSuccessAlert } = useAlert();
  const [editFolder, editFolderMutation] = useEditFolderMutation();
  const [editSection, editSectionMutation] = useEditSectionMutation();
  const [createFolder, createFolderMutation] = useCreateFolderMutation();
  const [deleteFolder, deleteFolderMutation] = useDeleteFolderMutation();

  const { event, folder, section } = currentAction;
  const isEditFolder = event === CurrentChatAction.EditFolder;
  const isCreateFolder = event === CurrentChatAction.AddFolder;
  const isEditSection = event === CurrentChatAction.EditSection;
  const isDeleteEvent = event === CurrentChatAction.DeleteFolder;
  const isOpen =
    !!event &&
    event !== CurrentChatAction.FolderDetails &&
    event !== CurrentChatAction.DeleteFolder;

  const onSuccess = useCallback(() => {
    showSuccessAlert(getMessage(event));
    onClose();
  }, [onClose, event]);

  handleMutation({ ...editFolderMutation, onSuccess });
  handleMutation({ ...editSectionMutation, onSuccess });
  handleMutation({ ...deleteFolderMutation, onSuccess });
  handleMutation({ ...createFolderMutation, onSuccess });

  const folderTitles = useMemo(() => {
    const names = section?.folders?.map((item) => item.name);
    return isEditSection ? suggestedNames : names;
  }, [isEditSection, section]);

  const onSubmit = async ({ name, icon } = {} as DialogFormValues) => {
    const folderId = folder?.id;
    const sectionId = section?.id;

    if (isEditFolder && folderId) {
      await editFolder({ name: name!, id: folderId, section: sectionId! });
    }
    if (isCreateFolder && sectionId) {
      await createFolder({ name: name!, section: sectionId! });
    }
    if (isEditSection && sectionId) {
      await editSection({ name: name!, icon: icon!, id: sectionId! });
    }
  };

  return (
    <>
      <Dialog
        title={event}
        onClose={onClose}
        open={isDeleteEvent}
        confirmText="Delete"
        closeOnConfirm={false}
        onConfirm={() =>
          deleteFolder({ id: folder?.id!, section: section?.id! })
        }
        text={
          <div>
            You are going to delete
            <span className="semibold">{` ${folder?.name} `}</span>
            folder. All your conversations will be moved to
            <span className="semibold"> General </span>folder. Are you sure you
            want to do this?
          </div>
        }
      />

      <Dialog
        nested
        open={isOpen}
        title={event}
        onClose={onClose}
        closeOnDocumentClick={false}
      >
        {(_, __, Buttons) => (
          <Formik<DialogFormValues>
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={getInitialValues(currentAction)}
            validationSchema={getValidationSchema(currentAction)}
          >
            {({ dirty, values, isSubmitting, setFieldValue }) => (
              <Styles.StyledForm>
                <FlexLayout gap={24} fullWidth flexDirection="column">
                  <FlexLayout gap={16}>
                    {isEditSection && (
                      <Popup
                        position="bottom left"
                        trigger={
                          <StandardButtonWithRef
                            nativeColorText
                            padding="4px 8px"
                            originalColorIcon
                            icon={<h2>{values?.icon}</h2>}
                            colorType={ButtonColors.LightGrey}
                          />
                        }
                      >
                        <Styles.PickerWrap>
                          <EmojiPicker
                            perLine={10}
                            maxFrequentRows={0}
                            previewPosition="none"
                            onEmojiSelect={(emoji: { native: string }) => {
                              setFieldValue('icon', emoji.native);
                            }}
                          />
                        </Styles.PickerWrap>
                      </Popup>
                    )}

                    <InputField
                      name="name"
                      placeholder={`${
                        isEditSection ? 'Section' : 'Folder'
                      } name`}
                    />
                  </FlexLayout>
                  <Styles.ListWrap>
                    <p className="semibold">
                      {isEditSection
                        ? 'Suggested names:'
                        : 'Your current folders:'}
                    </p>

                    {!!folderTitles &&
                      folderTitles.map((text, i) => (
                        <FlexLayout gap={8} key={text + i}>
                          {isEditSection ? <Icon.Info /> : <Icon.Folder />}
                          <p>{text}</p>
                        </FlexLayout>
                      ))}
                  </Styles.ListWrap>

                  <Buttons type={DialogType.Plain}>
                    <StandardButton
                      fullWidth
                      text="Cancel"
                      onClick={onClose}
                      colorType={ButtonColors.Secondary}
                    />
                    <StandardButton
                      fullWidth
                      text="Save"
                      type={ButtonTypes.Submit}
                      disabled={!dirty || isSubmitting}
                    />
                  </Buttons>
                </FlexLayout>
              </Styles.StyledForm>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
};
