export const handleFieldArray = (fieldArray?: string[]) =>
  fieldArray && fieldArray.length ? fieldArray : [''];

export const uniquePhoneValidator = (
  value: string,
  phones: string[],
  comparePhones: string[]
): boolean =>
  value.length > 0 &&
  (phones.indexOf(value) !== phones.lastIndexOf(value) ||
    comparePhones.includes(value));

export const getUniquePhoneError =
  (phones: string[], comparePhones: string[]) => (phone: string) =>
    uniquePhoneValidator(phone, phones, comparePhones)
      ? 'Phone number must be unique'
      : undefined;

export const getRequiredPhoneError = () => 'Phone is a required field';
