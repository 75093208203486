import styled, { css } from 'styled-components';
import { AnalyticsInfo } from '@gv/triage-components';

export const Card = styled(AnalyticsInfo)`
  flex: 1;
  border-radius: 16px;
  ${({ theme }) => css`
    box-shadow: ${theme.config.link.background.shadow};
  `}
`;
