import { NavLink, generatePath } from 'react-router-dom';
import { FlexLayout, EmptyPlaceholder } from '@gv/triage-components';

import { Routes } from 'config';
import { useAppSelector } from 'store';
import { selectPermissions } from 'store/slices/auth';

import * as Styles from './styles';
import { CaseIdProps } from './types';

export const CaseId = ({ caseId, bold = false }: CaseIdProps) => {
  const permissions = useAppSelector(selectPermissions);
  const caseAllowed = !!permissions.case;

  if (!caseId) {
    return <div>{EmptyPlaceholder}</div>;
  }

  return (
    <FlexLayout gap={6}>
      <Styles.Title bold={bold}>Case ID:</Styles.Title>
      {caseAllowed ? (
        <NavLink
          to={generatePath(Routes.Cases.Details.Home, {
            caseId: String(caseId),
          })}
        >
          {caseId}
        </NavLink>
      ) : (
        <p>{caseId}</p>
      )}
    </FlexLayout>
  );
};
