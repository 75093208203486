import * as Styles from './styles';
import { MenuCardProps } from './types';

export const MenuCard = ({
  template = '1fr',
  padding = '12px 24px',
  ...props
}: MenuCardProps) => {
  return (
    <Styles.Card
      isOdd={false}
      padding={padding}
      template={template}
      {...props}
    />
  );
};
