import { Utils } from '@gv/triage-components';
import { FormikValues, useFormikContext } from 'formik';

import * as Styles from './styles';

const { textValue } = Utils.Helpers;

export const Header = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <Styles.Header className="semibold">
      <Styles.IDContainer>
        <Styles.ID>Call ID / Case ID:</Styles.ID>
        <p>{textValue(values.id, 'GV_value_')}</p>
      </Styles.IDContainer>
    </Styles.Header>
  );
};
