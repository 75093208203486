import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  BaseRequest,
  TeamAnalytics,
  AnalyticsRequest,
  AnalyticsResponse,
  AnalyticsPageData,
  TeamAnalyticsResponse,
  AnalyticsCorporateRequest,
} from './types';

const tagTypes = ['Analytics'];

export const analyticsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'analyticsApi',
  endpoints: (build) => ({
    team: build.query<TeamAnalytics, BaseRequest>({
      transformResponse: (response: TeamAnalyticsResponse) => response.data,
      query: ({ end, start }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(URL.ANALYTICS_TEAM, { end, start }),
      }),
    }),

    hospital: build.query<AnalyticsPageData, AnalyticsRequest>({
      providesTags: tagTypes,
      transformResponse: (response: AnalyticsResponse) => response.data,
      query: ({ end, start, hospitalId }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.ANALYTICS_HOSPITAL, { hospitalId }),
          { end, start }
        ),
      }),
    }),

    corporate: build.query<AnalyticsPageData, AnalyticsCorporateRequest>({
      providesTags: tagTypes,
      transformResponse: (response: AnalyticsResponse) => response.data,
      query: ({ end, start, hospitalId, departmentIds }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.ANALYTICS_CORPORATE, { hospitalId }),
          { end, start, departmentIds }
        ),
      }),
    }),
  }),
});

export const { useTeamQuery, useHospitalQuery, useCorporateQuery } =
  analyticsApi;
