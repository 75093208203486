import { Badge } from '@gv/triage-components';

import { TaskPriority } from 'types';
import { TaskPriorityTitle, TaskPriorityBadgeColor } from 'types/data';

import { TaskPriorityBadgeProps } from './types';

const TaskPriorityBadge = ({
  priority = TaskPriority.Admin,
}: TaskPriorityBadgeProps) => (
  <Badge
    text={TaskPriorityTitle[priority!]}
    styleType={TaskPriorityBadgeColor[priority!]}
  />
);

export { TaskPriorityBadge };
