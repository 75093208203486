import { Fragment, ReactNode } from 'react';
import {
  Col,
  Row,
  Info,
  Badge,
  Utils,
  Badges,
  BadgeColor,
  ErrorAlert,
  ErrorColor,
  FlexLayout,
  InfoBordered,
  CorporateType,
  PriorityBadge,
  FormCardTypes,
  SingleOptions,
  WidgetQuestion,
  FormCardFields,
  HospitalClientType,
  HospitalClientTypeTitle,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { parseArray } from 'utils/helpers';
import { caseFormModel } from 'utils/config';
import { DiscardTypeLabel } from 'types/data';
import { useGetFormByIdQuery } from 'store/api/dynamic-forms';
import { selectHospital } from 'store/api/hospitals/selectors';
import {
  PetInfo,
  Sections,
  ClientInfo,
  commonBadgeProps,
  ExpandableSection,
} from 'components/cases';

import * as Styles from './styles';
import { CaseSummaryProps } from './types';
import { Medications } from './medications';
import { parseMedications } from './helpers';
import { CaseActions } from './case-actions';
import { AddendumsInfo } from './addendum-info';
import { StabilityAssessment } from './assesment';

const {
  Helpers: { textValue },
  Object: { getValueByPath },
  Date: { formatTimezoneSpecific },
} = Utils;

export const ConsultationCase = ({
  data,
  header,
  shortVersion,
  addendumChildren,
  ...props
}: CaseSummaryProps) => {
  const { own_fields, hospital_id } = data ?? {};
  const { $flowId, $passedSteps } = own_fields ?? {};
  const hospital = useAppSelector(selectHospital(String(hospital_id)));

  const { data: template } = useGetFormByIdQuery(
    { formId: String($flowId), hospitalId: hospital_id! },
    { skip: !hospital_id || !$flowId }
  );

  if (!data) {
    return null;
  }

  const {
    pets,
    pet_id,
    last_seen,
    addendums,
    client_id,
    callReason,
    updated_at,
    attendedBy,
    careDoctor,
    client_name,
    discardCase,
    careHospital,
    classification,
    payment_intent,
    payment_status,
    problem_summary,
    medications_list,
    chief_complaints,
    custom_care_doctor,
    priority_of_concern,
    custom_care_hospital,
    is_discarded: isDiscarded,
    specify_administrative_issue,
  } = data;

  const { name, timezone, corporate_type } = hospital ?? {};
  const isDynamicFlow = $passedSteps?.length && template?.data;

  const isMainCorporate = corporate_type === CorporateType.Main;

  const clientInfo = (
    <ClientInfo
      clientId={client_id ? String(client_id) : undefined}
      hospitalId={hospital_id ? String(hospital_id) : undefined}
    />
  );

  const petInfo = (
    <PetInfo
      pets={pets}
      petId={pet_id}
      clientId={client_id}
      hospitalId={hospital_id}
    />
  );

  const careDoctorInfo = (
    <Badges label="What is the name of your doctor?">
      <Badge
        styleType={BadgeColor.Transparent}
        text={textValue(
          custom_care_doctor || careDoctor?.name,
          null,
          'Owner did not answer'
        )}
        {...commonBadgeProps}
      />
    </Badges>
  );

  const chiefComplaintsInfo = (
    <Badges label="Chief complaint">
      {chief_complaints?.map((complaint, index) => (
        <Badge
          text={complaint}
          styleType={BadgeColor.Grey}
          key={`${complaint}-${index.toString()}`}
          {...commonBadgeProps}
        />
      ))}
    </Badges>
  );

  const priorityOfConcern = (
    <Badges label="Level of concern">
      <PriorityBadge priority={priority_of_concern} {...commonBadgeProps} />
    </Badges>
  );

  const commonInfo = (
    <FlexLayout gap={24} flexDirection="column">
      {!isDynamicFlow && <StabilityAssessment caseItem={data} />}

      <CaseActions {...data} timezone={timezone} />
      <AddendumsInfo
        addendums={addendums}
        hospitalId={hospital_id}
        children={addendumChildren}
        defaultOpened={!!addendumChildren}
      />

      {(payment_status || payment_intent) && (
        <ExpandableSection
          defaultOpened
          withoutSpacing
          label="Payment info"
          innerBlockProps={{ gap: 0 }}
        >
          <Row>
            <Col col={6}>
              <Info noEllipsis title="Payment status" value={payment_status} />
            </Col>

            <Col col={6}>
              <Info noEllipsis title="Payment id" value={payment_intent} />
            </Col>
          </Row>
        </ExpandableSection>
      )}
    </FlexLayout>
  );

  const getElement = (node: ReactNode, withoutMargin?: boolean) => (
    <Styles.Col withoutMargin={withoutMargin}>{node}</Styles.Col>
  );

  const getDynamicCase = () => {
    const elements: ReactNode[] = [];
    const { steps = {} } = template?.data ?? {};
    $passedSteps?.forEach((stepId: string) => {
      const currentStep = steps[stepId];
      if (currentStep) {
        currentStep?.widgets?.forEach(({ id, questions }) => {
          switch (id) {
            case FormCardTypes.ClientInfo:
              elements.push(getElement(clientInfo, true));
              break;
            case FormCardTypes.PetInfo:
              elements.push(getElement(petInfo, true));
              break;
            case SingleOptions.NameOfYourDoctor:
              elements.push(getElement(careDoctorInfo));
              break;
            case SingleOptions.ChiefComplaints:
              elements.push(getElement(chiefComplaintsInfo));
              break;
            case SingleOptions.CaseStatus:
              elements.push(getElement(priorityOfConcern));
              break;
            case SingleOptions.Triage:
              elements.push(
                getElement(
                  <StabilityAssessment isDynamicFlow caseItem={data} />
                )
              );
              break;
            case SingleOptions.CallReason:
              elements.push(
                getElement(
                  <Badges label="Call reason">
                    <Badge text={textValue(callReason)} {...commonBadgeProps} />
                  </Badges>
                )
              );
              break;
            case SingleOptions.Medications:
              elements.push(
                getElement(
                  <Medications
                    medications={parseMedications(medications_list)}
                  />
                )
              );
              break;
            case SingleOptions.SpecifyAdministrativeIssue:
              elements.push(
                getElement(
                  <Badges label={SingleOptions.SpecifyAdministrativeIssue}>
                    {parseArray(specify_administrative_issue).map(
                      (value: string, index: number) => (
                        <Badge
                          text={value}
                          key={`${value}-${index}`}
                          styleType={BadgeColor.Transparent}
                          {...commonBadgeProps}
                        />
                      )
                    )}
                  </Badges>
                )
              );
              break;

            default: {
              questions?.forEach((question: WidgetQuestion) => {
                question.fields?.forEach((field: FormCardFields) => {
                  const { label, name: fieldName } = field.formFieldProps ?? {};
                  if (
                    data &&
                    fieldName &&
                    !fieldName.startsWith('callOutcome.')
                  ) {
                    let fieldValue = getValueByPath(data, fieldName);
                    if (fieldName === 'current_client' && fieldValue) {
                      fieldValue =
                        HospitalClientTypeTitle[
                          fieldValue as HospitalClientType
                        ];
                    }

                    if (fieldValue || label) {
                      const element = (
                        <Badges label={label}>
                          {Array.isArray(fieldValue) ? (
                            fieldValue?.map((item, index) => (
                              <Badge
                                key={index}
                                text={textValue(item)}
                                {...commonBadgeProps}
                              />
                            ))
                          ) : (
                            <Badge
                              styleType={BadgeColor.Transparent}
                              text={textValue(fieldValue && String(fieldValue))}
                              {...commonBadgeProps}
                            />
                          )}
                        </Badges>
                      );
                      elements.push(getElement(element));
                    }
                  }
                });
              });
              break;
            }
          }
        });
      }
    });

    return (
      <Row>
        {elements.map((node, nodeIdx) => (
          <Fragment key={nodeIdx}>{node}</Fragment>
        ))}
      </Row>
    );
  };

  return (
    <Sections gap={0} {...props}>
      {!shortVersion && isDiscarded && (
        <Row>
          <Col>
            <ErrorAlert color={ErrorColor.Grey} icon={<Styles.Discard />}>
              Other
            </ErrorAlert>
          </Col>
        </Row>
      )}

      {header}

      {!shortVersion && attendedBy && (
        <Row>
          <Col>
            <FlexLayout gap={8} flexDirection="column">
              <p>
                Dear <span className="semibold">{name}</span>, <br />
                On{' '}
                {formatTimezoneSpecific(
                  updated_at,
                  timezone?.time_zone_code,
                  'lll'
                )}{' '}
                your client {client_name} here contacted the clinic. Below is
                the consultation record.
              </p>
              {attendedBy.map((item) => (
                <p key={item.id}>
                  <span className="semibold">{item.role}:</span> {item.name}
                </p>
              ))}
            </FlexLayout>
          </Col>
        </Row>
      )}

      {isDynamicFlow ? (
        <>
          {getDynamicCase()}
          {commonInfo}
        </>
      ) : (
        <>
          {clientInfo}
          {!shortVersion && isDiscarded ? (
            <>
              {discardCase?.type && (
                <Row>
                  <Col>
                    <Styles.Info
                      label="Reason of discard"
                      subtitle={discardCase?.other ?? ''}
                      title={DiscardTypeLabel[discardCase.type]}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Info title="Notes" value={textValue(discardCase?.note)} />
                </Col>
              </Row>
            </>
          ) : (
            <>
              {(careHospital?.address ||
                custom_care_hospital ||
                careHospital?.name) &&
                !isMainCorporate && (
                  <Row>
                    <Col>
                      <InfoBordered
                        subtitle={textValue(careHospital?.address) as string}
                        label="Where do you take your pet for veterinary care?"
                        title={
                          textValue(
                            custom_care_hospital || careHospital?.name
                          ) as string
                        }
                      />
                    </Col>
                  </Row>
                )}

              {petInfo}

              {last_seen && !isMainCorporate && (
                <Row>
                  <Col col={6}>
                    <Badges label="When was your last visit?">
                      <Badge
                        styleType={BadgeColor.Transparent}
                        text={textValue(last_seen) as string}
                        {...commonBadgeProps}
                      />
                    </Badges>
                  </Col>
                </Row>
              )}

              <Row>
                {!isMainCorporate && <Col col={6}>{careDoctorInfo}</Col>}

                {chief_complaints && chief_complaints.length > 0 && (
                  <Col col={6}>{chiefComplaintsInfo}</Col>
                )}
              </Row>

              <Row>
                <Col>{priorityOfConcern}</Col>
              </Row>

              {problem_summary && (
                <Row>
                  <Col>
                    <Info
                      noEllipsis
                      value={problem_summary}
                      title={caseFormModel.problemSummary.label}
                    />
                  </Col>
                </Row>
              )}

              {classification && (
                <Row>
                  <Col>
                    <Info
                      noEllipsis
                      title="Classification"
                      value={classification}
                    />
                  </Col>
                </Row>
              )}

              {commonInfo}
            </>
          )}
        </>
      )}
    </Sections>
  );
};
