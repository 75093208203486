import styled, { css } from 'styled-components';
import { StandardButton } from '@gv/triage-components';

export const Header = styled.header`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
`;

export const Step = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary.standard};
`;

export const Next = styled(StandardButton)`
  min-width: 120px;
`;

export const List = styled.ul`
  gap: 8px;
  display: flex;
  margin: 8px 0;
  flex-direction: column;
`;

export const ListItem = styled.li`
  gap: 8px;
  display: flex;
  min-height: 48px;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: relative;

  &::before {
    bottom: 0;
    left: 50%;
    width: 2px;
    content: '';
    height: 45%;
    position: absolute;
    transform: translateX(-50%);
  }

  ${({ theme }) => css`
    svg path {
      fill: ${theme.colors.blue.primary.standard};
    }

    &::before {
      background-color: ${theme.colors.blue.secondary.action};
    }
  `}
`;

export const Text = styled.p`
  margin-bottom: 8px;
`;
