import styled from 'styled-components';
import { StandardButton, StandardButtonWithRef } from '@gv/triage-components';

export const CompleteTask = styled(StandardButtonWithRef)`
  min-width: 168px;
`;

export const CompleteDialogBtn = styled(StandardButton)`
  min-width: 119px;
`;
