import { ActionQueue, ActionQueueType } from '@gv/triage-components';

import { UserShorted } from 'types';
import { isMineTask } from 'utils/helpers';

import { TasksCount } from './types';

export const getUsersTasksCount = (
  tasks: Record<string, ActionQueue> | undefined,
  users?: UserShorted[]
) =>
  (Object.values(tasks ?? {}) as ActionQueue[]).reduce<
    Record<string, TasksCount>
  >((result, task) => {
    if (task.disconnected) {
      return result;
    }
    const taskUser = users?.find((user) => isMineTask(task, user.id));

    if (taskUser) {
      const count = result[taskUser.id] ?? { calls: 0, chats: 0 };

      if (task.type === ActionQueueType.Voice) {
        return {
          ...result,
          [taskUser.id]: { ...count, calls: count.calls + 1 },
        };
      } else if (task.type === ActionQueueType.SMS) {
        return {
          ...result,
          [taskUser.id]: { ...count, chats: count.chats + 1 },
        };
      }
    }

    return result;
  }, {});
