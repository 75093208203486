import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ChildrenProps } from '@gv/triage-components';

import { Config } from 'config';

let stripePromise: ReturnType<typeof loadStripe> | undefined = undefined;

const getStripePromise = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(Config.stripeKey!);
  }
  return stripePromise;
};

export const StripeElements = ({ children }: ChildrenProps) => {
  const [stripe] = useState(getStripePromise());
  return (
    <Elements stripe={stripe} options={{ locale: 'en' }}>
      {children}
    </Elements>
  );
};
