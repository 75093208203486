import styled, { css } from 'styled-components';
import { List, FormFooter, StandardButton } from '@gv/triage-components';

import { StylesProps } from './types';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  ${({ theme }) => theme.responsive.isMobile && 'flex-direction: column;'};
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) =>
    !theme.responsive.isMobile &&
    css`
      width: 400px;
    `};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  height: 100%;
  max-height: 100%;
`;

export const BackButton = styled(StandardButton)`
  padding: 8px;
  ${({ theme }) =>
    !theme.responsive.isMobile &&
    css`
      min-width: 0 !important;
    `}
`;

export const Footer = styled(FormFooter)<StylesProps>`
  flex-direction: row;
  div {
    width: 100%;
  }

  ${({ theme, isChat }) =>
    !theme.responsive.isMobile &&
    css`
      padding: 16px 0;
      margin: auto ${isChat ? 60 : 120}px 0 ${isChat ? 60 : 120}px;

      div {
        width: auto;
      }
    `};
`;

export const StyledList = styled(List)`
  min-height: 0;
  height: 100%;
  max-height: 100%;
`;

export const Header = styled.div<StylesProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ isChat }) =>
    `${isChat ? '16px 60px 0 60px' : '16px 120px 0 120px'}`};
`;

export const Script = styled.p`
  white-space: pre-line;
`;

export const ParaDynamic = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 0;
  margin-bottom: -10px;
`;
