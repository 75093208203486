import {
  Icon,
  FlexLayout,
  ButtonColors,
  StandardButton,
  ButtonStyleTypes,
} from '@gv/triage-components';

import * as Styles from './styles';
import { PetOwnerPetsProps } from './types';

export const PetOwnerPets = ({
  client,
  values,
  addPetPath,
  setFieldValue,
}: PetOwnerPetsProps) => (
  <FlexLayout gap={16} flexDirection="column">
    {client?.pets?.map((pet) => (
      <Styles.Pet
        key={pet.id}
        $selected={values.petIds.includes(pet.id)}
        onClick={() => {
          const ids = [...values.petIds];
          const index = ids.indexOf(pet.id);
          if (index >= 0) {
            ids.splice(index, 1);
          } else {
            ids.push(pet.id);
          }
          setFieldValue('petIds', ids);
        }}
      >
        <Icon.Pawprint />
        <div>
          <p className="semibold">{pet.name}</p>
          <p>{pet.species}</p>
        </div>
      </Styles.Pet>
    ))}

    <div>
      <StandardButton
        to={addPetPath}
        text="Add new pet"
        colorType={ButtonColors.WhiteBlack}
        styleType={ButtonStyleTypes.Outline}
      />
    </div>
  </FlexLayout>
);
