import { useContext } from 'react';
import { Link } from '@gv/triage-components';

import { AudioContext } from '.';

export const Download = () => {
  const { src, error } = useContext(AudioContext);

  if (error || !src) {
    return null;
  }

  return <Link download url={src} text="Download" className="semibold" />;
};
