import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { IvrGreeting } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';

import {
  IvrGreetingAddRequest,
  IvrGreetingEditRequest,
  IvrGreetingsListRequest,
  IvrGreetingsListResponse,
  IvrGreetingUpdateRequest,
  IvrGreetingEnableRequest,
  IvrGreetingEnableResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;

const tagTypes = ['Greetings'];

export const ivrGreetingsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'ivrGreetingsApi',
  endpoints: (build) => ({
    addIvrGreeting: build.mutation<IvrGreeting, IvrGreetingAddRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GREETINGS,
      }),
    }),
    updateIvrGreeting: build.mutation<IvrGreeting, IvrGreetingUpdateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.GREETINGS,
      }),
    }),
    enableIvrGreeting: build.mutation<
      IvrGreetingEnableResponse,
      IvrGreetingEnableRequest
    >({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.ENABLE_GREETING,
      }),
    }),
    deleteIvrGreeting: build.mutation<IvrGreeting, IvrGreetingEditRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, hospital_id }) => ({
        method: 'delete',
        url: addParamsToUrl(URL.GREETINGS, {
          id,
          hospital_id,
        }),
      }),
    }),
    ivrGreetingsList: build.query<IvrGreeting[], IvrGreetingsListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: IvrGreetingsListResponse) =>
        response.data.greetings,
      query: ({ hospital_id, greeting_type = '0' }) => {
        return {
          method: 'get',
          url: addParamsToUrl(URL.GREETINGS, {
            hospital_id,
            greeting_type,
          }),
        };
      },
    }),
  }),
});

export const {
  useIvrGreetingsListQuery,
  useAddIvrGreetingMutation,
  useUpdateIvrGreetingMutation,
  useDeleteIvrGreetingMutation,
  useEnableIvrGreetingMutation,
} = ivrGreetingsApi;

export type {
  IvrGreetingAddRequest,
  IvrGreetingEditRequest,
  IvrGreetingUpdateRequest,
};
