import {
  isMobile,
  SpeciesLabels,
  SpeciesOptions,
  CheckboxFieldGroup,
} from '@gv/triage-components';

import { SpeciesCheckboxGroupProps } from './types';

const SpeciesCheckboxGroup = ({
  name,
  block,
  colorType,
  grid = {
    gap: '16px 32px',
    template: 'repeat(3, 40px) / repeat(3, minmax(auto, 141px))',
  },
  ...props
}: SpeciesCheckboxGroupProps): JSX.Element => {
  const mobile = isMobile();
  return (
    <CheckboxFieldGroup
      {...props}
      name={name}
      checkAllValue={SpeciesOptions}
      grid={mobile ? undefined : grid}
      commonProps={{
        colorType,
        block: block === undefined ? true : block,
      }}
      items={Object.entries(SpeciesLabels).map(([value, label]) => ({
        value,
        label,
        id: `${name}-${value}`,
      }))}
    />
  );
};

export { SpeciesCheckboxGroup };
