import { OfficeMessage } from '@gv/triage-components';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import {
  SetMessages,
  MessagesData,
  CountReplies,
  UpdateMessage,
  RemoveMessage,
  AddOldMessages,
  AddNewMessages,
  ChatBaseReducer,
  GVMessagesState,
  ReadAllMessages,
} from './types';

const getInitialChatState = (): MessagesData => ({
  isLoading: false,
  prevId: undefined,
  nextId: undefined,
  isNewerLoading: false,
  messages: new Array<OfficeMessage>(),
});

const initialState: GVMessagesState = {
  chats: {},
};

const initiateIfNeeded = (state: any, chatId: string) => {
  if (!state.chats[chatId]) {
    state.chats[chatId] = getInitialChatState();
  }
};

export const gvMessagesSlice = createSlice({
  initialState,
  name: 'gvMessages',
  reducers: {
    deleteMessages: (state, { payload }: PayloadAction<string>) => {
      delete state.chats[payload];
    },
    setLoading: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<boolean>>
    ) => {
      const { data, chatId } = payload;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].isLoading = data;
    },
    addMessage: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<OfficeMessage>>
    ) => {
      const { data, chatId } = payload;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].messages.push(data);
    },
    setNewerLoading: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<boolean>>
    ) => {
      const { data, chatId } = payload;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].isNewerLoading = data;
    },
    readAllMessages: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<ReadAllMessages>>
    ) => {
      const { data, chatId } = payload;
      const { isRead } = data;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].messages = state.chats[chatId].messages.map(
        (msg) => ({ ...msg, isRead })
      );
    },
    setMessages: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<SetMessages>>
    ) => {
      const { data, chatId } = payload;
      const { nextId, prevId, messages } = data;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].nextId = nextId;
      state.chats[chatId].prevId = prevId;
      state.chats[chatId].messages = messages;
    },
    removeMessage: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<RemoveMessage>>
    ) => {
      const { data, chatId } = payload;

      initiateIfNeeded(state, chatId);

      const index = state.chats[chatId].messages.findIndex(
        (msg) => msg.id === data.id
      );
      if (index !== -1) {
        state.chats[chatId].messages.splice(index, 1);
      }
    },
    addNewMessages: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<AddNewMessages>>
    ) => {
      const { data, chatId } = payload;
      const { nextId, messages } = data;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].nextId = nextId;
      state.chats[chatId].messages = [
        ...state.chats[chatId].messages,
        ...messages,
      ];
    },
    addOldMessages: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<AddOldMessages>>
    ) => {
      const { data, chatId } = payload;
      const { prevId, messages } = data;

      initiateIfNeeded(state, chatId);

      state.chats[chatId].prevId = prevId;
      state.chats[chatId].messages = [
        ...messages,
        ...state.chats[chatId].messages,
      ];
    },
    countReplies: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<CountReplies>>
    ) => {
      const { data, chatId } = payload;
      const { id } = data;

      initiateIfNeeded(state, chatId);

      const index = state.chats[chatId].messages.findIndex(
        (msg) => msg.id === id
      );
      if (index !== -1) {
        state.chats[chatId].messages[index].threadedMessagesCount =
          (state.chats[chatId].messages[index]?.threadedMessagesCount ?? 0) + 1;
      }
    },
    updateMessage: (
      state,
      { payload }: PayloadAction<ChatBaseReducer<UpdateMessage>>
    ) => {
      const { data, chatId } = payload;
      const { id, message } = data;

      initiateIfNeeded(state, chatId);

      const index = state.chats[chatId].messages.findIndex(
        (msg) => msg.id === id
      );
      if (index !== -1) {
        state.chats[chatId].messages[index] = {
          ...state.chats[chatId].messages[index],
          ...message,
        };
      }
      const forwardIndex = state.chats[chatId].messages.findIndex(
        (msg) => msg.forwardedMessage?.id === id
      );
      if (forwardIndex !== -1) {
        const msg = state.chats[chatId].messages[forwardIndex];
        state.chats[chatId].messages[forwardIndex] = {
          ...msg,
          forwardedMessage: {
            ...msg.forwardedMessage!,
            ...message,
          },
        };
      }
    },
  },
});

const {
  reducer: gvMessagesReducer,
  actions: {
    setLoading,
    addMessage,
    setMessages,
    countReplies,
    removeMessage,
    updateMessage,
    deleteMessages,
    addOldMessages,
    addNewMessages,
    setNewerLoading,
    readAllMessages,
  },
} = gvMessagesSlice;

const chatStore = (store: RootState) => store?.gvMessages;

export const selectGvMessages = (id?: number | string) =>
  createSelector(
    [chatStore],
    (store) => (id && store?.chats[id]) || getInitialChatState()
  );

export const selectGvMessage = (chatId: number | string, messageId: number) =>
  createSelector([chatStore], (store) => {
    const messages = store?.chats[chatId]?.messages;
    if (!messages) {
      return undefined;
    }

    const index = messages.findIndex((msg) => msg.id === messageId);
    if (index === -1) {
      return undefined;
    }

    return messages[index];
  });

export {
  addMessage,
  setLoading,
  setMessages,
  countReplies,
  updateMessage,
  removeMessage,
  deleteMessages,
  addOldMessages,
  addNewMessages,
  setNewerLoading,
  readAllMessages,
  gvMessagesReducer,
};
