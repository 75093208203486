import dayjs from 'dayjs';
import {
  List,
  Icon,
  Utils,
  ListCard,
  ListHeader,
  FlexLayout,
  useNavigate,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { Routes } from 'config';
import { generateUrl } from 'utils/helpers';

import { PaymentStatusBadge } from '../payment-status-badge';

import * as Styles from './styles';
import { InvoiceListProps } from './types';

const { Home, Details } = Routes.Invoices;
const {
  Date: { formatDate },
  Helpers: { formatPrice },
} = Utils;

const titles = ['Status', 'Number', 'Amount', 'Recepient', 'Due date'];
const templates = {
  mobile: '1fr 1fr',
  desktop: 'repeat(5, 1fr) 64px',
  tablet: '1fr 72px 72px 1fr 1fr 64px',
};

const commonButtonProps = {
  padding: '0',
  transparent: true,
  colorType: ButtonColors.BlackWhite,
};

export const InvoiceList = ({ invoices }: InvoiceListProps) => {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <List
        count={invoices?.length || 0}
        header={
          <ListHeader
            titles={titles}
            template={templates.desktop}
            tabletTemplate={templates.tablet}
          />
        }
      >
        {invoices?.map((value) => (
          <ListCard
            key={value.id}
            nativeIconColor
            mobileHeaders={titles}
            template={templates.desktop}
            tabletTemplate={templates.tablet}
            mobileTemplate={templates.mobile}
            values={[
              <PaymentStatusBadge status={value.status} />,
              <p className="semibold">#{value.id}</p>,
              formatPrice((value.total / 100).toFixed(2), '$0.00'),
              value?.customer?.name,
              formatDate(dayjs.unix(value?.dueDate), 'll'),
              <FlexLayout gap={16}>
                <StandardButton
                  {...commonButtonProps}
                  icon={<Icon.Eye />}
                  onClick={() =>
                    navigate(
                      generateUrl(`${Home}/${Details}`, {
                        id: value.id,
                      })
                    )
                  }
                />
                {value?.pdf && (
                  <StandardButton
                    to={value.pdf}
                    {...commonButtonProps}
                    icon={<Icon.Download />}
                  />
                )}
              </FlexLayout>,
            ]}
          />
        ))}
      </List>
    </Styles.Container>
  );
};
