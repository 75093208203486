import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { TeamMemberUser } from 'types';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';
import {
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
  cacheListAndByIdArgProperty,
} from 'utils/query-cache';

import { onCallHoursApi, onCallHoursTags } from '../calendar/on-call-hours';
import { virtualExamsApi, virtualExamsTags } from '../calendar/virtual-exams';
import {
  clinicDvmsApi,
  clinicDvmsTag,
  dvmCalendarTagType,
} from '../clinic-dvms';

import { insertArgsIntoUrl } from './helpers';
import {
  ListData,
  GetResponse,
  ListResponse,
  UpdateRequest,
  CreateRequest,
  CreateResponse,
  BaseItemRequest,
  ListRequestQuery,
  ActivationRequest,
} from './types';

const { addParamsToUrl } = Utils.Query;

const tagType = 'Team';

const teamAdapter = createEntityAdapter<TeamMemberUser, number>({
  selectId: (item) => item.id,
});

export const teamApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'teamApi',
  endpoints: (build) => ({
    get: build.query<TeamMemberUser, BaseItemRequest>({
      providesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: GetResponse) => response.data,
      query: (args) => ({
        method: 'get',
        url: insertArgsIntoUrl(args),
      }),
    }),
    activation: build.mutation<TeamMemberUser, ActivationRequest>({
      invalidatesTags: cacheListAndByIdArgProperty(tagType),
      query: ({ isActive, ...args }) => ({
        method: 'post',
        data: {
          isActive,
        },
        url: insertArgsIntoUrl(args) + '/toggle-status',
      }),
    }),
    update: build.mutation<TeamMemberUser, UpdateRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: insertArgsIntoUrl({ id, hospitalId }),
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(onCallHoursApi.util.invalidateTags(onCallHoursTags));
        dispatch(virtualExamsApi.util.invalidateTags(virtualExamsTags));
        dispatch(
          clinicDvmsApi.util.invalidateTags([clinicDvmsTag, dvmCalendarTagType])
        );
      },
    }),
    create: build.mutation<TeamMemberUser, CreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CreateResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: insertArgsIntoUrl({ hospitalId: hospitalId! }),
      }),
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(onCallHoursApi.util.invalidateTags(onCallHoursTags));
        dispatch(virtualExamsApi.util.invalidateTags(virtualExamsTags));
        dispatch(
          clinicDvmsApi.util.invalidateTags([clinicDvmsTag, dvmCalendarTagType])
        );
      },
    }),
    list: build.query<ListData, ListRequestQuery>({
      transformResponse: (response: ListResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.users),
      query: ({ search, statuses, page = 1, hospitalId, limit = 20 }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams: Record<string, any> = {
          limit,
          offset,
          search_keyword: search,
        };
        if (statuses && statuses.length) {
          searchParams.statuses = statuses;
        }
        const url = addParamsToUrl(
          insertArgsIntoUrl({ hospitalId }),
          searchParams
        );

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const teamSlice = createSlice({
  reducers: {},
  name: 'team',
  initialState: teamAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      teamApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        teamAdapter.setAll(state, payload.users);
      }
    );

    builder.addMatcher(
      teamApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        teamAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      teamApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        teamAdapter.updateOne(state, {
          changes: payload,
          id: teamAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(
      teamApi.endpoints.activation.matchFulfilled,
      (state, { payload }) => {
        teamAdapter.updateOne(state, {
          changes: payload,
          id: teamAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      teamAdapter.removeAll(state);
    });
  },
});

export const {
  useGetQuery,
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useActivationMutation,
} = teamApi;
