import { useMemo } from 'react';

import { CorporateGroupType } from 'types';
import { useListQuery } from 'store/api/corporate-group';
import { getCorporateGroupOptions } from 'components/cases/helpers';

export const useCorporateOptions = (hospitalId?: string | number) => {
  const { data: corporateGroup, isFetching: isCorporateGroupFetching } =
    useListQuery(
      { type: CorporateGroupType.All, hospitalId: String(hospitalId) },
      { skip: !hospitalId }
    );

  const corporateGroupOptions = useMemo(
    () => getCorporateGroupOptions(corporateGroup?.grouped),
    [corporateGroup]
  );

  return { corporateGroupOptions, isCorporateGroupFetching };
};
