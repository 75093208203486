import { Mutex as AsyncMutex } from 'async-mutex';

export default class Mutex {
  static mutexes: Record<string, { counter: number; mutex: AsyncMutex }> = {};

  static async acquire(id: string) {
    if (!this.mutexes[id]) {
      this.mutexes[id] = { counter: 0, mutex: new AsyncMutex() };
    }

    const entry = this.mutexes[id];
    entry.counter++;
    const release = await entry.mutex.acquire();

    return async () => {
      release();
      entry.counter--;

      if (entry.counter === 0) {
        delete this.mutexes[id];
      }
    };
  }
}
