import { useRef, useEffect } from 'react';

import * as Styles from './styles';
import { SelfViewProps } from './types';

const getStyleAttributeNumericalValue = (attr: string | number | undefined) => {
  if (!attr) {
    return 0;
  }
  if (typeof attr === 'number') {
    return attr;
  }
  const v = /(\d+)/.exec(attr)?.[1];
  return v ? Number(v) : 0;
};

export const SelfView = ({ mediaStream, ...restProps }: SelfViewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const isRenderSelfViewWithVideoElement =
    !!mediaStream?.isRenderSelfViewWithVideoElement();

  const { style } = restProps;

  useEffect(() => {
    if (!isRenderSelfViewWithVideoElement && canvasRef.current && style) {
      const width = getStyleAttributeNumericalValue(style.width);
      const height = getStyleAttributeNumericalValue(style.height);
      try {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      } catch (e) {
        mediaStream?.updateVideoCanvasDimension(
          canvasRef.current,
          width,
          height
        );
      }
    }
  }, [isRenderSelfViewWithVideoElement, style, mediaStream]);

  return isRenderSelfViewWithVideoElement ? (
    <Styles.Video {...restProps} />
  ) : (
    <Styles.Canvas ref={canvasRef} {...restProps} />
  );
};
