import { useAppSelector } from 'store';
import {
  selectAuthClient,
  selectIsLoggedIn,
  selectAuthRestorePath,
} from 'store/slices/auth';

export const usePOAuthRedirectPath = () => {
  const restorePath = useAppSelector(selectAuthRestorePath);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const client = useAppSelector(selectAuthClient);

  const isCompleted = client ? !client.inactive : undefined;
  const isRedirectRequired = restorePath && isCompleted && isLoggedIn;

  return isRedirectRequired ? restorePath : undefined;
};
