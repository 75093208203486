import { useMemo } from 'react';
import { mapQueries, Acceptance } from '@gv/triage-components';

import { PetSpecies } from 'types';
import { useListQuery } from 'store/api/species';
import { useGetQuery } from 'store/api/acceptance';

type SpeciesQuery = {
  acceptance: Acceptance;
  speciesList: PetSpecies[];
};

export const useSpeciesProps = (hospitalId?: number | string) => {
  const { acceptance, speciesList, ...rest } = mapQueries<SpeciesQuery>({
    speciesList: useListQuery(),
    acceptance: useGetQuery(
      { hospitalId: String(hospitalId) },
      { skip: !hospitalId }
    ),
  });

  const acceptableSpecies = useMemo(
    () => acceptance?.species?.map((item) => item.name) ?? [],
    [acceptance?.species]
  );

  const notAcceptableSpecies = useMemo(() => {
    const options: string[] = [];

    if (speciesList?.length) {
      speciesList.forEach(({ name }) => {
        if (name && !acceptableSpecies.includes(name)) {
          options.push(name);
        }
      });
    }

    return options;
  }, [speciesList, acceptableSpecies]);

  return { acceptableSpecies, notAcceptableSpecies, ...rest };
};
