import dayjs from 'dayjs';
import { Utils, ButtonColors, ButtonStyleTypes } from '@gv/triage-components';

import { getTaskDate } from 'utils/helpers';
import { TaskClass, HospitalTask, TaskEventType } from 'types';

import { GetButtonsOptions } from './types';

const {
  Date: { formatDate },
  Helpers: { joinStrings },
} = Utils;

export const cells = [
  { row: '1/2', col: '1/2' },
  { row: '2/3', col: '1/2' },
  { row: '3/4', col: '1/2' },
  { row: '4/5', col: '1/2' },
  { row: '5/6', col: '1/2' },
  { row: '6/7', col: '1/2' },
];

export const getButtonsOptions = ({
  task,
  onClick,
  hasAccess,
  assignedToMe,
}: GetButtonsOptions) => {
  const disabled = !hasAccess;
  const firstButtonEvent = assignedToMe
    ? TaskEventType.AssignTo
    : TaskEventType.AssignToMe;

  const secondButtonEvent = assignedToMe
    ? TaskEventType.MoveToUnassigned
    : TaskEventType.AssignTo;

  return [
    {
      disabled,
      text: firstButtonEvent,
      colorType: ButtonColors.Secondary,
      onClick: () => onClick({ task, event: firstButtonEvent }),
    },
    {
      disabled,
      text: secondButtonEvent,
      styleType: ButtonStyleTypes.Ghost,
      onClick: () => onClick({ task, event: secondButtonEvent }),
    },
  ];
};

export const taskClassTitle = {
  [TaskClass.Emergency]: 'Follow up with pet owner needed',
  [TaskClass.Appointment]: 'Follow up with pet owner needed',
  [TaskClass.Administrative]: 'Follow up with pet owner needed',
};

export const getTaskProps = (task: HospitalTask) => {
  const {
    type,
    dueAt,
    priority,
    createdAt,
    case: taskCase,
    class: taskClass,
  } = task;

  const { availabilityDays, availabilityTime } =
    taskCase?.callOutcome?.details ?? {};

  const options = {
    title: 'Follow up with pet owner needed',
    dateString: priority
      ? `From: ${formatDate(createdAt, 'MMM DD, YYYY')}`
      : dueAt
        ? getTaskDate(dayjs(dueAt), {
            separator: ' • ',
          })
        : joinStrings(
            [availabilityDays?.join(', '), availabilityTime?.join(', ')],
            ' • '
          ),
  };

  if (taskClass === TaskClass.Emergency) {
    options.title = 'Emergency on the way';
  }

  if (taskClass === TaskClass.Appointment) {
    options.title = `Appointment ${type ?? ''}`;
  }
  return options;
};
