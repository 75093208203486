import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Pet, Utils, EventInput, TeamMemberColor } from '@gv/triage-components';

type Events = EventInput[];

const { joinStrings } = Utils.Helpers;

dayjs.extend(utc);

dayjs.extend(timezone);

export const generateItems = (
  clinicHours?: any,
  isAppointment?: boolean,
  dynamicstartDate?: any
) => {
  const {
    Timezone,
    schedule,
    clinichours,
    availabilities,
    clinicHoursAppointment,
    timezone: originalTimeZone,
  } = clinicHours ?? {};
  const useTimezone = originalTimeZone ?? Timezone;
  const timezoneOptions = {
    timeZone: useTimezone && useTimezone.time_zone_code,
  };
  const events: Events = [];
  const items: any[] = clinichours ?? schedule ?? availabilities?.rows ?? [];
  const appointments: any[] = clinicHoursAppointment ?? [];

  // Process both items and clinicHoursAppointment arrays
  items.forEach((item) => {
    const {
      client,
      doctor,
      status,
      case_id,
      group_id,
      client_id,
      doctor_id,
      dvm_user_id,
      appointment,
      description,
      end: endDates,
      start: startDates,
      time_slots: timeslots,
    } = item;

    if (startDates && endDates) {
      startDates.forEach((start: number[], index: number) => {
        const end = endDates[index];
        const startDate = new Date(Number(start)).toLocaleString(
          'en-US',
          timezoneOptions
        );
        const endDate = new Date(Number(end)).toLocaleString(
          'en-US',
          timezoneOptions
        );
        const extendedProps: Record<string, any> = {
          item,
          timeslots,
          end: endDate,
          endSlot: end,
          start: startDate,
          startSlot: start,
          groupId: group_id,
          isGrouped: !!group_id,
          timezone: useTimezone,
          isClinicAppointment: false,
          color: TeamMemberColor.Green,
        };
        if (doctor) {
          extendedProps.docName = doctor.name;
        }
        if (appointment) {
          extendedProps.chatId = appointment.createdChatId;
        }
        if (case_id) {
          extendedProps.case_id = case_id;
        }
        if (status != null || status !== undefined) {
          extendedProps.status = Number(status);
        }
        if (isAppointment) {
          extendedProps.isAppointment = true;
        }
        if (client_id) {
          extendedProps.clientId = client_id;
          if (client) {
            extendedProps.client = {
              name: client.name,
              phone: client.phone,
              email: client?.email,
              description: description,
              pets: appointment?.task?.pets?.map((pet: Pet) =>
                joinStrings([pet?.name, pet?.species], ', ')
              ),
            };
          }
        }
        if (startDate && dayjs(startDate).isBefore(dayjs())) {
          extendedProps.isPastSlots = true;
        } else {
          extendedProps.isPastSlots = false;
        }
        if (doctor_id) {
          extendedProps.docId = doctor_id;
        } else if (dvm_user_id) {
          extendedProps.docId = dvm_user_id;
        }
        const event: EventInput = {
          extendedProps,
          title: item.title,
          id: String(item.id),
        };
        events.push(event);
      });
    }
  });

  if (isAppointment) {
    const appointmentsByDate: Record<string, any[]> = {};

    appointments.forEach((item) => {
      const { start } = item;
      const appointmentDate = dayjs(start[0])
        .tz(useTimezone.time_zone_code)
        .format('YYYY-MM-DD');

      if (!appointmentsByDate[appointmentDate]) {
        appointmentsByDate[appointmentDate] = [];
      }
      appointmentsByDate[appointmentDate].push(item);
    });

    const dynamicDateData = dynamicstartDate
      ? dynamicstartDate.replace(/\//g, '-')
      : '2024-08-16';

    // New code: Check from Sunday of the current week till Saturday
    const today = dayjs(dynamicDateData).tz(useTimezone.time_zone_code);

    const sunday = today.startOf('week').day(0);

    const weekRange = [];
    for (let i = 0; i < 7; i++) {
      weekRange.push(sunday.clone().add(i, 'day').format('YYYY-MM-DD'));
    }

    weekRange.forEach((date) => {
      if (!appointmentsByDate[date]) {
        const startOfDay = dayjs
          .tz(`${date} 00:00`, useTimezone.time_zone_code)
          .format('YYYY-MM-DD HH:mm:ss');
        const endOfDay = dayjs
          .tz(`${date} 23:59`, useTimezone.time_zone_code)
          .format('YYYY-MM-DD HH:mm:ss');

        const newSlot = {
          timeslots: [],
          end: endOfDay,
          isBlock: false,
          start: startOfDay,
          timezone: useTimezone,
          isClinicAppointment: true,
          color: TeamMemberColor.Red,
        };

        events.push({
          extendedProps: newSlot,
          id: `${date}-full-day`,
          title: 'Booking Not Available',
        });
      }
    });

    // Continue with existing logic for processing appointments and slots
    Object.keys(appointmentsByDate).forEach((date) => {
      const appointmentsOnDate = appointmentsByDate[date];

      appointmentsOnDate.sort((a, b) => a.start[0] - b.start[0]);

      let startOfDay = dayjs
        .tz(`${date} 00:00`, useTimezone.time_zone_code)
        .valueOf();
      const endOfDay = dayjs
        .tz(`${date} 23:59`, useTimezone.time_zone_code)
        .valueOf();

      appointmentsOnDate.forEach((item) => {
        const { end, start } = item;

        const startSlot = dayjs(start[0])
          .tz(useTimezone.time_zone_code)
          .valueOf();
        const endSlot = dayjs(end[0]).tz(useTimezone.time_zone_code).valueOf();

        if (startSlot > startOfDay) {
          const newSlot = {
            endSlot,
            startSlot,
            timeslots: [],
            isBlock: false,
            timezone: useTimezone,
            isClinicAppointment: true,
            color: TeamMemberColor.Red,
            end: dayjs(startSlot)
              .tz(useTimezone.time_zone_code)
              .format('YYYY-MM-DD HH:mm:ss'),
            start: dayjs(startOfDay)
              .tz(useTimezone.time_zone_code)
              .format('YYYY-MM-DD HH:mm:ss'),
          };

          events.push({
            extendedProps: newSlot,
            color: TeamMemberColor.Red,
            id: `${date}-${startOfDay}`,
            title: 'Booking Not Available',
          });
        }
        startOfDay = endSlot;
      });

      if (startOfDay < endOfDay) {
        const newSlot = {
          timeslots: [],
          isBlock: false,
          timezone: useTimezone,
          isClinicAppointment: true,
          color: TeamMemberColor.Red,
          end: dayjs(endOfDay)
            .tz(useTimezone.time_zone_code)
            .format('YYYY-MM-DD HH:mm:ss'),
          start: dayjs(startOfDay)
            .tz(useTimezone.time_zone_code)
            .format('YYYY-MM-DD HH:mm:ss'),
        };

        events.push({
          extendedProps: newSlot,
          id: `${date}-${startOfDay}`,
          title: 'Booking Not Available',
        });
      }
    });
  }

  return { events, timezone: useTimezone };
};
