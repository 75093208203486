import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  ChiefComplaint,
  CreateChiefComplaintPayload,
  UpdateChiefComplaintPayload,
  ChiefComplaintMutationResponse,
} from './types';

const chiefComplaintsAdapter = createEntityAdapter<ChiefComplaint, number>({
  selectId: (item) => item.id,
});

const tagType = 'ChiefComplaints';

export const chiefComplaintsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'chiefComplaintsApi',
  endpoints: (build) => ({
    delete: build.mutation<ChiefComplaint, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_MASTER_CHIEF_COMPLAINT, { id }),
      }),
    }),

    list: build.query<ChiefComplaint[], void>({
      providesTags: (result) => providesList(tagType)(result),
      transformResponse: (response: any) => response.data.rows,
      query: () => ({
        method: 'get',
        url: URL.GET_CHIEF_COMPLAINTS_MASTER_LIST,
      }),
    }),

    listSystemAndCategory: build.query<any, void>({
      providesTags: (result) => providesList(tagType)(result),
      query: () => ({
        method: 'get',
        url: URL.CHEIF_COMPLAINTS,
      }),
      transformResponse: (response: any) =>
        [response.data.systemData, response.data.categoryData] || [],
    }),

    create: build.mutation<ChiefComplaint, CreateChiefComplaintPayload>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: ChiefComplaintMutationResponse) =>
        response.data,
      query: (data) => ({
        method: 'post',
        url: URL.ADD_MASTER_CHIEF_COMPLAINT,
        data: {
          name: data.name,
          category_id: data.category,
          system_id: data?.system.map((system: any) => system.value),
        },
      }),
    }),

    update: build.mutation<ChiefComplaint, UpdateChiefComplaintPayload>({
      transformResponse: (response: ChiefComplaintMutationResponse) =>
        response.data,
      invalidatesTags: (result, error, { chief_complaint_id }) =>
        cacheByIdArg(tagType)(result, error, chief_complaint_id),
      query: ({ name, system_id, category_id, chief_complaint_id }) => ({
        method: 'patch',
        data: { name, system_id, category_id },
        url: generateUrl(URL.UPDATE_MASTER_CHIEF_COMPLAINT, {
          id: chief_complaint_id,
        }),
      }),
    }),
  }),
});

export const chiefComplaintsSlice = createSlice({
  reducers: {},
  name: 'chiefComplaints',
  initialState: chiefComplaintsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      chiefComplaintsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        chiefComplaintsAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      chiefComplaintsApi.endpoints.listSystemAndCategory.matchFulfilled,
      (state, { payload }) => {
        chiefComplaintsAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      chiefComplaintsApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        chiefComplaintsAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      chiefComplaintsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        chiefComplaintsAdapter.updateOne(state, {
          changes: payload,
          id: chiefComplaintsAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      chiefComplaintsAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useListSystemAndCategoryQuery,
} = chiefComplaintsApi;
