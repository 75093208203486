import * as Yup from 'yup';
import { Messages } from '@gv/triage-components';

export const initialValues = {
  selectedClientId: undefined,
};

export const validationSchema = Yup.object().shape({
  selectedClientId: Yup.number().required(Messages.PLEASE_SELECT_CLIENT),
});
