import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import {
  SetActiveStep,
  SetStepPayload,
  SetPassedSteps,
  DynamicStepState,
} from './types';

const initialState: DynamicStepState = {
  passedSteps: [],
  activeStep: undefined,
};

export const dynamicFormStepSlice = createSlice({
  initialState,
  name: 'dynamicFormStepSlice',
  reducers: {
    setActive: (state, { payload }: PayloadAction<SetActiveStep>) => {
      state.activeStep = payload;
    },
    setPassed: (state, { payload }: PayloadAction<SetPassedSteps>) => {
      state.passedSteps = payload;
    },
    setStep: (state, { payload }: PayloadAction<SetStepPayload>) => {
      const { activeStep, passedSteps } = payload;
      state.activeStep = activeStep;
      state.passedSteps = passedSteps;
    },
  },
});

const {
  reducer: dynamicFormStepReducer,
  actions: { setStep, setActive, setPassed },
} = dynamicFormStepSlice;

const dynamicFormStepStore = (store: RootState) => store?.dynamicFormStep;

export const selectDynamicFormStep = () =>
  createSelector([dynamicFormStepStore], (store) => {
    return { ...store } as DynamicStepState;
  });

export { setStep, setActive, setPassed, dynamicFormStepReducer };
