import { Utils } from '@gv/triage-components';

import { URL } from '../constants';
import { apiInstance } from '../instance';

import {
  AuthComm,
  TakeChat,
  PickupCall,
  PlaceBackChat,
  EndConference,
  OnHoldPayload,
  DisconnectCall,
  TextOutPayload,
  GetChatMessages,
  SendChatMessage,
  CallHoldPayload,
  CallbackPayload,
  CustomSMSPayload,
  CallToDVMPayload,
  DisconnectDVMPayload,
} from './types';

export const getAblyToken = (clientId: string) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.GET_ABLY_TOKEN, {
      clientId,
    })
  );

export const getTwilioVoiceToken = (clientId: string, ttl?: number) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.DEVICE_CAPABILITY_TOKEN, {
      ttl,
      name: clientId,
    })
  );

export const pickupCall = (data: PickupCall) =>
  apiInstance.post(URL.PICKUP_CALL, { data });

export const placeBackCall = (data: DisconnectCall) =>
  apiInstance.post(URL.DISCONNECT_CALL, { data });

export const deviceError = (data: AuthComm, error?: any) =>
  apiInstance.post(URL.DEVICE_ERROR, { data, error });

export const endConference = (data: EndConference) =>
  apiInstance.post(URL.END_CALL_EXPORT, data);

export const takeChat = ({ channelName, ...body }: TakeChat) =>
  apiInstance.post(
    URL.TAKE_CHAT_FROM_QUEUE.replace('{channelName}', channelName),
    body
  );

export const getChat = (channelName: string) =>
  apiInstance.get(URL.CHAT.replace('{channelName}', channelName));

export const getChatMessages = ({
  limit = 20,
  channelName,
  ...rest
}: GetChatMessages) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(
      URL.CHAT_MESSAGES.replace('{channelName}', channelName),
      {
        limit,
        ...rest,
      }
    )
  );

export const placeBackChat = ({ channelName, ...body }: PlaceBackChat) =>
  apiInstance.post(
    URL.RETURN_CHAT_TO_QUEUE.replace('{channelName}', channelName),
    body
  );

export const sendChatMessage = ({ channelName, ...body }: SendChatMessage) =>
  apiInstance.post(
    URL.CHAT_MESSAGES.replace('{channelName}', channelName),
    body
  );

export const endChat = (channelName: string) =>
  apiInstance.post(URL.END_CHAT.replace('{channelName}', channelName));

export const sendCustomSMS = (data: CustomSMSPayload) =>
  apiInstance.post(URL.SEND_SMS_NORMAL, data);

export const onHold = (data: OnHoldPayload) =>
  apiInstance.post(URL.UNHOLD_CLIENT, data);

export const callToDvm = (data: CallToDVMPayload) =>
  apiInstance.post(URL.CALL_DVM, { data });

export const disconnectDVM = (data: DisconnectDVMPayload) =>
  apiInstance.post(URL.DISCONNECT_DVM, data);

export const onHoldCall = (data: CallHoldPayload) =>
  apiInstance.post(URL.HOLD_CALL, data);

export const callback = (data: CallbackPayload) =>
  apiInstance.post(URL.RESUME_CALL, data);

export const textOut = (data: TextOutPayload) =>
  apiInstance.post(URL.TEXT_OUT, data);
