import styled from 'styled-components';
import { ListHeader } from '@gv/triage-components';

export const Header = styled(ListHeader)`
  margin: unset;
`;

export const ListWrap = styled.div`
  overflow-y: hidden;
`;
