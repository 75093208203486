import { Utils } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiInstance } from 'api/instance';
import { generateUrl } from 'utils/helpers';

export const getHospitalDashboardList = (payload: any) => {
  const url = Utils.Query.addParamsToUrl(
    generateUrl(URL.NEW_ANALYTICS_HOSPITAL, {
      hospitalId: payload.hospitalId,
    }),
    { end: payload.end, start: payload.start }
  );
  return apiInstance.get(url).then((response) => {
    const data = response.data.data;
    return data;
  });
};

export const updateRoiStatus = (payload: any, roiValue: number) => {
  const url = Utils.Query.addParamsToUrl(
    generateUrl(URL.UPDATE_ROISTATUS, {
      hospitalId: payload.hospitalId,
    }),
    { end: payload.end, caseCost: roiValue, start: payload.start }
  );
  return apiInstance.get(url).then((response) => {
    const data = response.data.data;
    return data;
  });
};
