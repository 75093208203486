import styled, { css } from 'styled-components';
import { Details, FlexLayout } from '@gv/triage-components';

export const Container = styled.div<{
  isChat?: boolean;
  withLeftBorder?: boolean;
}>`
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
  ${({ theme, withLeftBorder }) => css`
    border-left: ${withLeftBorder
      ? `1px solid ${theme.config.tab.shadow}`
      : 'none'};
  `}
`;

export const EventsContainer = styled(Container)`
  flex-basis: 50%;
`;

export const EventContainer = styled.div`
  flex: 1;
  display: flex;
  flex-basis: 50%;
  overflow-y: auto;
  ${({ theme }) => css`
    border-left: 1px solid ${theme.config.tab.shadow};
  `};
`;

export const Placeholder = styled.p`
  padding: 16px;
`;

export const TabletContainer = styled(FlexLayout)`
  min-height: 0;
  height: 100%;
`;

export const LogsDetails = styled(Details)`
  width: unset;

  > div {
    overflow-y: hidden;
  }
`;

export const Header = styled(FlexLayout)`
  gap: 8px;
  display: flex;
  align-content: center;
  padding: ${({ theme }) =>
    theme.responsive.isDesktop ? '0 16px 16px 16px' : '16px'};
`;

export const EventContent = styled.div`
  flex: 1;
  display: flex;
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  flex-direction: column;
`;
