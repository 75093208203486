import { Utils, ButtonColors, StandardButton } from '@gv/triage-components';

import * as Styles from './styles';
import { SelectAllSectionProps } from './types';

export const SelectAllSection = ({
  tab,
  pageCount,
  totalCount,
  allSelected,
  setAllSelected,
}: SelectAllSectionProps) => {
  let content;

  if (allSelected) {
    content = (
      <>
        All <span className="semibold">{totalCount}</span> item are selected.{' '}
        <StandardButton
          padding="0"
          transparent
          text="Clear selection"
          colorType={ButtonColors.Secondary}
          onClick={() => setAllSelected(false)}
        />
      </>
    );
  } else {
    content = (
      <>
        All <span className="semibold">{pageCount}</span> on this page are
        selected.{' '}
        <StandardButton
          padding="0"
          transparent
          colorType={ButtonColors.Secondary}
          onClick={() => setAllSelected(true)}
          text={`Select all ${totalCount} items`}
        />
        {tab && ` in ${Utils.String.capitalize(tab)}`}
      </>
    );
  }

  return <Styles.AllItemsSelected>{content}</Styles.AllItemsSelected>;
};

export type { SelectAllSectionProps };
