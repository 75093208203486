import { FlexLayout, BadgeColor, BadgePadding } from '@gv/triage-components';

import { CallOutcomeTypeTitle } from 'types/data';

import { CallOutcomeBadges } from '../call-outcome-badges';

import { CallOutcomeItemsProps } from './types';

export const CallOutcomeItems = ({
  data,
  timezone,
  headerLeftItem,
}: CallOutcomeItemsProps) => {
  const { type, details } = data;

  const typeName = details && type ? details[type] : undefined;

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout className="semibold">
        {headerLeftItem}
        {typeName && CallOutcomeTypeTitle[typeName]}
      </FlexLayout>

      <CallOutcomeBadges
        data={data}
        timezone={timezone}
        styleType={BadgeColor.LightGrey}
        padding={BadgePadding.WideMedium}
      />
    </FlexLayout>
  );
};
