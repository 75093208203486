import { Icon, Image, FileType, EllipsisText } from '@gv/triage-components';

import * as Styles from './styles';
import { DropZoneContentProps } from './types';

export const DropZoneContent = ({ file: dropFile }: DropZoneContentProps) => {
  const { src, file } = dropFile || {};
  const { name, type } = file || {};
  const isImage = type?.includes(FileType.Image);

  const title = src ? (
    (name ?? type ?? '')
  ) : (
    <>
      Drag your image here, or <span>browse</span>
    </>
  );

  return (
    <Styles.DropContainer withoutBg={isImage}>
      {isImage && <Image src={src} clickable alt={src} />}

      <Styles.Wrapper>
        {src && file && !isImage ? <Icon.FileFilled /> : <Icon.Image />}
        <h4>
          <EllipsisText>{title}</EllipsisText>
        </h4>
      </Styles.Wrapper>
    </Styles.DropContainer>
  );
};
