import { Formik } from 'formik';
import { useMemo, useState, useCallback } from 'react';
import {
  Col,
  Row,
  Utils,
  Dialog,
  isMobile,
  useAlert,
  InputField,
  DialogType,
  SelectField,
  ButtonTypes,
  ButtonColors,
  StandardButton,
  handleMutation,
} from '@gv/triage-components';

import { useDetailsQuery } from 'store/api/clients';
import { useWrongTriageMutation } from 'store/api/cases';

import * as Styles from './styles';
import { FormValues, IncorrectTriageProps } from './types';
import { maxLength, initialValues, validationSchema } from './config';

export const IncorrectTriage = ({
  caseId,
  clientId,
  hospitalId,
}: IncorrectTriageProps) => {
  const [isOpened, setOpened] = useState(false);

  const title = 'Incorrect triage assessment?';

  const onClose = useCallback(() => setOpened(false), []);

  const mobile = isMobile();
  const { showSuccessAlert } = useAlert();

  const [wrongTriage, mutation] = useWrongTriageMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert('Incorrect triage report was sent');

    onClose();
  }, [onClose]);

  handleMutation({ ...mutation, onSuccess });

  const { data: client } = useDetailsQuery({
    hospitalId,
    allowDeleted: true,
    id: String(clientId),
  });

  const phones = useMemo(() => {
    const uniquePhones = Array.from(
      new Set(
        [
          client?.phone,
          client?.last_login_phone,
          ...(client?.phones ? client.phones : []),
        ].filter((phone) => !!phone) as string[]
      )
    );
    return uniquePhones.map((value) => ({
      value,
      label: Utils.Helpers.formatPhone(value),
    }));
  }, [client]);

  if (!phones.length) {
    return null;
  }

  const onSubmit = (values: FormValues) => wrongTriage({ caseId, ...values });

  return (
    <>
      <StandardButton
        padding="0"
        transparent
        text={title}
        onClick={() => setOpened(true)}
        colorType={ButtonColors.LightRed}
      />
      <Dialog
        title={title}
        open={isOpened}
        onClose={onClose}
        width={mobile ? '90vw' : '720px'}
      >
        {(close, __, Buttons) => (
          <Formik<FormValues>
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ dirty, isSubmitting }) => (
              <Styles.Form>
                <Row>
                  <Col>
                    <SelectField
                      isMulti
                      onlyValue
                      name="phones"
                      label="Phones"
                      options={phones}
                      placeholder="Select phones to send SMS"
                    />
                  </Col>

                  <Col>
                    <InputField
                      name="text"
                      label="Message"
                      type="textarea"
                      placeholder="Message"
                      maxLength={maxLength}
                    />
                  </Col>
                </Row>

                <Buttons type={DialogType.Plain}>
                  <StandardButton
                    fullWidth
                    text="Cancel"
                    onClick={close}
                    colorType={ButtonColors.Secondary}
                  />
                  <StandardButton
                    fullWidth
                    text="Send"
                    type={ButtonTypes.Submit}
                    disabled={!dirty || isSubmitting}
                  />
                </Buttons>
              </Styles.Form>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
};
