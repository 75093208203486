import { useEffect, useContext } from 'react';
import { handleMutation } from '@gv/triage-components';

import { Config } from 'config';
import { UserActionsContext } from 'context/user-actions';
import { usePushSubscribeMutation } from 'store/api/users';

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const useWebPush = () => {
  const { isUserClickHappened } = useContext(UserActionsContext);

  const [subscribe, subscribeMutation] = usePushSubscribeMutation();
  handleMutation(subscribeMutation);

  useEffect(() => {
    const registerPushNotifications = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          '/web-push-sw.js',
          {
            scope: '/',
          }
        );
        await registration.update();

        const result = await Notification.requestPermission();
        if (result === 'denied') {
          console.error('The user explicitly denied the permission request.');
          return;
        }
        if (result === 'granted') {
          console.info('The user accepted the permission request.');
        }

        const subscribed = await registration.pushManager.getSubscription();
        if (subscribed) {
          await subscribe(subscribed);
          return;
        }

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(Config.vapidKey!),
        });
        await subscribe(subscription);
      } catch (error) {
        console.error(error);
      }
    };
    if (
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      isUserClickHappened &&
      Config.vapidKey
    ) {
      registerPushNotifications();
    }
  }, [isUserClickHappened]);
};
