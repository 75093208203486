import { createSlice } from '@reduxjs/toolkit';

import { Subscription } from 'types';
import { clientsApi } from 'store/api/clients';
import { hospitalsApi } from 'store/api/hospitals';
import { subscriptionsApi } from 'store/api/subscriptions';

import { isLogoutAction } from '../auth';

import { SubscriptionsSliceProps } from './types';

const initialState: SubscriptionsSliceProps = {
  isSubscribed: false,
  expiration: undefined,
  isSubscriptionRequired: false,
  subscribeToProductIdAfterSignUp: undefined,
};

const updateState = (
  state: SubscriptionsSliceProps,
  subscriptions?: Subscription[]
) => {
  const subscription = subscriptions?.[0];
  if (subscription) {
    state.isSubscribed = true;
    state.expiration = subscription.currentPeriodEnd;
  } else {
    state.isSubscribed = false;
    state.expiration = undefined;
  }
};

export const subscriptionsSlice = createSlice({
  initialState,
  name: 'subscriptions',
  reducers: {
    removeSubscribeToProductIdAfterSignUp(state) {
      state.subscribeToProductIdAfterSignUp = undefined;
    },
    setSubscribeToProductIdAfterSignUp(state, action) {
      state.subscribeToProductIdAfterSignUp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      clientsApi.endpoints.paymentProfile.matchFulfilled,
      (state, { payload }) => {
        updateState(state, payload.subscriptions);
      }
    );

    builder.addMatcher(
      subscriptionsApi.endpoints.subscriptions.matchFulfilled,
      (state, { payload }) => {
        updateState(state, payload);
      }
    );

    builder.addMatcher(
      hospitalsApi.endpoints.basicInfo.matchFulfilled,
      (state, { payload }) => {
        state.isSubscriptionRequired = payload.isSubscriptionRequired || false;
      }
    );

    builder.addMatcher(isLogoutAction, () => {
      return initialState;
    });
  },
});

const {
  actions: {
    setSubscribeToProductIdAfterSignUp,
    removeSubscribeToProductIdAfterSignUp,
  },
} = subscriptionsSlice;

export {
  setSubscribeToProductIdAfterSignUp,
  removeSubscribeToProductIdAfterSignUp,
};
