import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { AnalyticsExportState, AnalyticsExportStatus } from './types';

const initialState: AnalyticsExportState = {
  caseId: 0,
  file: null,
  formValues: null,
  hospitalId: false,
  isButtonHide: false,
  isShowFeedback: false,
  status: AnalyticsExportStatus.Initial,
};

const analyticsExportSlice = createSlice({
  initialState,
  name: 'analyticsExport',
  reducers: {
    reset: () => initialState,
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCaseId: (state, action) => {
      state.caseId = action.payload;
    },
    setHospitalId: (state, action) => {
      state.hospitalId = action.payload;
    },
    setIsButtonHide: (state, action) => {
      state.isButtonHide = action.payload;
    },
    setIsShowFeedback: (state, action) => {
      state.isShowFeedback = action.payload;
    },
    fileProcessed: (state, action) => {
      state.status = AnalyticsExportStatus.Ready;
      state.file = action.payload;
    },
    setFormValues: (state, action) => {
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      };
    },
  },
});

const {
  reducer: analyticsExportReducer,
  actions: {
    reset,
    setStatus,
    setCaseId,
    setHospitalId,
    fileProcessed,
    setFormValues,
    setIsButtonHide,
    setIsShowFeedback,
  },
} = analyticsExportSlice;

const selectAnalyticsExport = (state: RootState) => state.analyticsExport;

export {
  reset,
  setStatus,
  setCaseId,
  setHospitalId,
  fileProcessed,
  setFormValues,
  setIsButtonHide,
  setIsShowFeedback,
  AnalyticsExportStatus,
  selectAnalyticsExport,
  analyticsExportReducer,
};

export type { AnalyticsExportState };
