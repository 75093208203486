import { RealtimeChannel } from 'ably';
import { useState, useEffect, useContext } from 'react';

import { getToken } from 'utils/storage';
import { CommContext } from 'context/comm';
import { useGetWorkspaceQuery } from 'store/api/gv-workspace';

export const useAblyChannel = (name?: string, presence?: boolean) => {
  const { ablyClient } = useContext(CommContext);
  const [ablyChannel, setAblyChannel] = useState<RealtimeChannel | undefined>(
    undefined
  );

  const isWorkspaceNeeded = name ? name.includes('${workspaceId}') : false;
  const { data } = useGetWorkspaceQuery({}, { skip: !isWorkspaceNeeded });
  const id = data?.id;

  useEffect(() => {
    if (ablyClient && name && (id || !isWorkspaceNeeded)) {
      const channelName =
        isWorkspaceNeeded && id
          ? name.replace('${workspaceId}', String(id))
          : name;
      const channel = ablyClient.channels.get(channelName);
      setAblyChannel(channel);

      if (presence) {
        channel.presence.enter({
          token: getToken(),
        });

        return () => {
          channel.presence.leave({
            token: getToken(),
          });
        };
      }
    } else {
      setAblyChannel(undefined);
    }
  }, [name, isWorkspaceNeeded, ablyClient, id]);

  return ablyChannel;
};
