import styled, { css } from 'styled-components';
import { Select, Details, FlexLayout } from '@gv/triage-components';

import { CommonProps } from './types';

export const Container = styled(Details)<CommonProps>`
  ${({ disableMainScroll }) =>
    disableMainScroll
      ? css`
          > div {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
          }
        `
      : css`
          display: flex;
          flex-direction: column;
          padding: 16px;

          > div {
            display: flex;
            overflow: unset;
            flex-direction: column;
          }
        `}
`;

export const Header = styled(FlexLayout)<CommonProps>`
  gap: 16px;
  flex-direction: column;
  padding-bottom: 24px;

  ${({ theme, disableMainScroll }) => css`
    ${disableMainScroll && 'margin: 16px 16px 0;'};
    border-bottom: 1px solid ${theme.config.common.border};
  `};
`;

export const SelectStatus = styled(Select)`
  min-width: 168px;
`;

export const Main = styled(FlexLayout)<CommonProps>`
  flex-direction: column;

  ${({ disableMainScroll }) =>
    disableMainScroll
      ? css`
          position: relative;
          width: 100%;
          min-height: 0;
          height: 100%;
          max-height: 100%;
          overflow: hidden;
        `
      : css`
          flex: 1;
          gap: 16px;
          padding: 24px 0;
        `}
`;
