import { SummaryOptionProps } from './types';

export const getSections = (services?: Record<string, any>[]) => {
  if (!services?.length) {
    return;
  }

  const parsedServices = services.reduce(
    (
      sum,
      {
        name,
        calls,
        messages,
        total_amount,
        monthly_plan,
        phone_numbers,
        consultations_fee,
      }
    ) => {
      const options = [];
      if (messages) {
        options.push({ value: messages, title: 'Messages' });
      }
      if (calls) {
        options.push({ value: calls, title: 'Calls' });
      }
      if (consultations_fee) {
        options.push({
          value: consultations_fee,
          title: 'Consultations fee',
        });
      }
      if (phone_numbers) {
        options.push({ value: phone_numbers, title: 'Phone numbers' });
      }
      if (monthly_plan) {
        options.push({ value: monthly_plan, title: 'Monthly plan' });
      }
      if (total_amount) {
        options.push({ isBold: true, title: 'Subtotal', value: total_amount });
      }
      return { ...sum, [name]: options };
    },
    {}
  );

  return Object.entries(parsedServices) as Array<
    [string, SummaryOptionProps[]]
  >;
};
