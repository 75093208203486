/* eslint-disable perfectionist/sort-objects */
import { UserRoleType, EmptyPlaceholder } from '@gv/triage-components';

import { SubscriptionPlanType } from 'types';

export { Routes } from './routes';
export * as Analytics from './analytics';
export { Config, PortalType } from './env';

export const dotSymbol = '•';

export const adminRoles = [
  UserRoleType.TeamCoach,
  UserRoleType.SuperAdmin,
  UserRoleType.Admin,
  UserRoleType.HospitalAdmin,
  UserRoleType.AllPermissions,
  UserRoleType.SecondaryHospitalAdmin,
];

export const cvtRoles = [
  UserRoleType.CVT,
  UserRoleType.TeamCoach,
  UserRoleType.SuperAdmin,
  UserRoleType.DynamicFlowManager,
];

export const walkinItems = [
  { label: 'Yes', value: 'true', id: 'walk-in-yes' },
  { label: 'No', value: 'false', id: 'walk-in-no' },
];

export const dropoffItems = [
  { label: 'Yes', value: 'true', id: 'drop-off-yes' },
  { label: 'No', value: 'false', id: 'drop-off-no' },
];

export const subscriptionPlan = {
  'GV-Talk Internal': {
    "DM's": {
      advanced: 'Free, unlimited',
      essential: 'Free, unlimited',
      professional: 'Free, unlimited',
    },
    Channels: {
      advanced: 'Free, unlimited',
      essential: 'Free, unlimited',
      professional: 'Free, unlimited',
    },
  },
  'GV-Talk External': {
    SMS: {
      advanced: 'Included',
      professional: 'Included',
      essential: EmptyPlaceholder,
    },
    Payments: {
      essential: EmptyPlaceholder,
      advanced: '2% GV service fee',
      professional: '2% GV service fee',
    },
    'Phone numbers': {
      advanced: '0.39 cents/min',
      essential: EmptyPlaceholder,
      professional: '0.39 cents/min',
    },
    Templates: {
      professional: 'Included',
      advanced: EmptyPlaceholder,
      essential: EmptyPlaceholder,
    },
    Marketing: {
      professional: 'Included',
      advanced: EmptyPlaceholder,
      essential: EmptyPlaceholder,
    },
  },
  Bonuses: {
    'GV-Talk app': {
      essential: 'Included',
      advanced: 'Included',
      professional: 'Included',
    },
    'GV-Explore': {
      essential: 'Included',
      advanced: 'Included',
      professional: 'Included',
    },
  },
};

export const subscriptionPlanPrice: Record<SubscriptionPlanType, string> = {
  essential: 'Free',
  advanced: '$8.99',
  professional: '$14.99',
};

export const gvFee = 7.1;
export const subscriptionsGvFee = 50;
