import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { generateUrl } from 'utils/helpers';
import { apiLoaderQuery } from 'store/query';
import { SmsTemplate, SmsCalloutcomeType } from 'types';

import {
  GetTemplateRequest,
  GetTemplateResponse,
  UpdateTemplateRequest,
  DeleteTemplateRequest,
  CreateTemplateRequest,
  GetCallOutcomeTypesResponse,
} from './types';

const { addParamsToUrl } = Utils.Query;

const tagTypes = ['SmsNotification'];

export const smsNotificationApi = createApi({
  tagTypes,
  baseQuery: apiLoaderQuery,
  reducerPath: 'smsNotificationApi',
  endpoints: (build) => ({
    delete: build.mutation<void, DeleteTemplateRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, hospitalId }) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_SMS_NOTIFICATION, { id, hospitalId }),
      }),
    }),

    create: build.mutation<void, CreateTemplateRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, hospitalId }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.GET_SMS_NOTIFICATION, { hospitalId }),
      }),
    }),

    getCalloutcomeTypes: build.query<SmsCalloutcomeType[], void>({
      providesTags: tagTypes,
      transformResponse: (response: GetCallOutcomeTypesResponse) =>
        response.data,
      query: () => ({
        method: 'get',
        url: URL.GET_CALLOUTCOME_TYPES,
      }),
    }),

    update: build.mutation<void, UpdateTemplateRequest>({
      invalidatesTags: tagTypes,
      query: ({ data, reason, hospitalId, callOutcomeId }) => ({
        data,
        method: 'put',
        url: addParamsToUrl(
          generateUrl(URL.GET_SMS_NOTIFICATION, { hospitalId }),
          { reason, callOutcomeId }
        ),
      }),
    }),

    get: build.query<SmsTemplate[], GetTemplateRequest>({
      providesTags: tagTypes,
      transformResponse: (response: GetTemplateResponse) => response.data,
      query: ({ id, reason, hospitalId }) => ({
        method: 'get',
        url: addParamsToUrl(
          generateUrl(URL.GET_SMS_NOTIFICATION, {
            hospitalId,
          }),
          { reason, callOutcomeId: id }
        ),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useCreateMutation,
  useDeleteMutation,
  useUpdateMutation,
  useGetCalloutcomeTypesQuery,
} = smsNotificationApi;
