import {
  Row,
  Col,
  Icon,
  useSearch,
  InputField,
  FlexLayout,
  ButtonColors,
  StandardButton,
  RadioFieldGroup,
  DatePickerField,
} from '@gv/triage-components';

import { useHospitalParams } from 'hooks';
import { useListQuery } from 'store/api/team';
import { SelectAssignee } from 'components/select-assignee';
import { useAssigneeQuery } from 'store/api/internal-team-tasks';

import { InternalTaskFormFieldProps } from './types';
import { requiredText, priorityOptions } from './config';

export const TaskFormField = ({
  values,
  children,
  showName,
  isCompact,
  showDueAt,
  setFieldValue,
  showAllAssignee,
  onlyHospitalTeam,
  isAssigneeRequired,
}: InternalTaskFormFieldProps) => {
  const { id } = useHospitalParams();
  const { searchQuery, setSearchQuery, debounceQuery: search } = useSearch('');

  const commonQuery = {
    search,
    page: 1,
    limit: showAllAssignee ? 10000 : undefined,
  };

  const commonSkip = showAllAssignee ? false : !search;

  const { isFetching, data: userData } = useAssigneeQuery(commonQuery, {
    skip: commonSkip || onlyHospitalTeam,
  });

  const { data: hospitalTeam, isFetching: isHospitalTeamFetching } =
    useListQuery(
      { ...commonQuery, hospitalId: id! },
      { skip: commonSkip || !onlyHospitalTeam }
    );

  return (
    <>
      {showName && (
        <Row>
          <Col>
            <InputField
              name="title"
              label="Task name"
              requiredText={requiredText}
              placeholder="Add task name"
            />
          </Col>
        </Row>
      )}

      {children}

      <Row>
        <Col>
          <InputField
            type="textarea"
            maxLength={3000}
            name="description"
            label="Description"
            placeholder="Add description"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RadioFieldGroup
            name="priority"
            label="Priority"
            items={priorityOptions}
            requiredText={requiredText}
          />
        </Col>
      </Row>
      {showDueAt && (
        <Row>
          <Col col={6}>
            <FlexLayout gap={16} alignItems="flex-end">
              <DatePickerField
                name="dueAt"
                label="Due date"
                minDate={new Date()}
                placeholderText="Select date"
              />

              {values?.dueAt && (
                <div>
                  <StandardButton
                    padding="0"
                    transparent
                    icon={<Icon.Close />}
                    colorType={ButtonColors.BlackWhite}
                    onClick={() => setFieldValue('dueAt', null)}
                  />
                </div>
              )}
            </FlexLayout>
          </Col>
        </Row>
      )}
      <Row>
        <Col col={isCompact ? 12 : 6}>
          <SelectAssignee
            assignOptions={userData?.users || hospitalTeam?.users}
            assignSelectFieldProps={{
              inputValue: searchQuery,
              onInputChange: setSearchQuery,
              isLoading: isFetching || isHospitalTeamFetching,
              requiredText: isAssigneeRequired ? requiredText : undefined,
            }}
          />
        </Col>
      </Row>
    </>
  );
};
