import { useContext } from 'react';
import {
  Utils,
  FlexLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { getCallPhone } from 'utils/helpers';
import { OutboundContext } from 'context/outbound';

import * as Styles from './styles';
import { OutboundBadgePopupProps } from './types';

const { formatPhone } = Utils.Helpers;

export const OutboundBadgePopup = ({
  close,
  onClick,
}: OutboundBadgePopupProps) => {
  const { activeCall } = useContext(OutboundContext);

  return (
    <Styles.Container>
      <FlexLayout flexDirection="column">
        <Styles.Name>Outbound Call</Styles.Name>
        <p>{formatPhone(getCallPhone(activeCall))}</p>
      </FlexLayout>

      <StandardButton
        fullWidth
        text="Back to outbound"
        colorType={ButtonColors.Secondary}
        onClick={() => {
          onClick();
          if (close) {
            close();
          }
        }}
      />
    </Styles.Container>
  );
};
