import * as Yup from 'yup';
import { Utils, HospitalReferral } from '@gv/triage-components';

import { Case } from 'types';

import { FormValues } from './types';

export const validationSchema = Yup.object().shape({
  message: Yup.string().trim().required('Message is a required field'),
  phone: Utils.Validation.phoneNumber('Phone').required(
    'Phone is a required field'
  ),
});

export const getInitialValues = ({
  caseData,
}: {
  caseData?: Case;
  referral?: HospitalReferral;
}): FormValues => ({
  message: '',
  phone: caseData?.client_phone ?? caseData?.additional_phone ?? '',
});
