import { useState, useEffect } from 'react';
import {
  event_video_active_change,
  event_audio_active_speaker,
} from '@zoom/videosdk';

import { ZoomClient } from 'context/video-comm/types';

export const useActiveVideo = (zoomClient: ZoomClient) => {
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [activeSpeaker, setActiveSpeaker] = useState<number>(0);

  useEffect(() => {
    const onVideoActiveChange: typeof event_video_active_change = (payload) => {
      const { state, userId } = payload;
      if (state === 'Active') {
        setActiveVideo(userId);
      } else if (state === 'Inactive') {
        setActiveVideo(0);
      }
    };

    const onActiveSpeakerChange: typeof event_audio_active_speaker = (
      payload
    ) => {
      if (Array.isArray(payload) && payload.length > 0) {
        const { userId } = payload[0];
        setActiveSpeaker(userId);
      }
    };

    zoomClient.on('video-active-change', onVideoActiveChange);
    zoomClient.on('active-speaker', onActiveSpeakerChange);
    return () => {
      zoomClient.off('video-active-change', onVideoActiveChange);
      zoomClient.off('active-speaker', onActiveSpeakerChange);
    };
  }, [zoomClient]);

  return activeVideo || activeSpeaker;
};
