import { AnalyticsExportState } from 'store/slices/analytics-export';

export const getInitialValues = (
  hydratedFormValues: AnalyticsExportState['formValues']
) => ({
  hospital_id: hydratedFormValues?.hospital_id ?? '',
  end_date: hydratedFormValues?.end_date
    ? new Date(hydratedFormValues.end_date)
    : null,
  start_date: hydratedFormValues?.start_date
    ? new Date(hydratedFormValues.start_date)
    : null,
});
