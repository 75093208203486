import { lazy } from 'react';

import { Routes as RoutePaths } from 'config';
import {
  Login,
  Verifyotp,
  ResetPassword,
  ForgotPassword,
  SubmitFeedback,
} from 'pages/auth';

import App from './app';

const AppPrivate = lazy(() => import('./app-private'));

const mappedRoutes = {
  [RoutePaths.Auth.Login]: Login,
  [RoutePaths.Auth.VerifyOtp]: Verifyotp,
  [RoutePaths.Auth.ForgotPassword]: ForgotPassword,
  [`${RoutePaths.Auth.SubmitFeedback}/:id/:caseId`]: SubmitFeedback,
  [RoutePaths.Auth.ResetPassword]: {
    allowedForLoggedIn: true,
    Component: ResetPassword,
  },
};

export const AppTeamHospital = () => (
  <App
    PrivateApp={AppPrivate}
    mappedRoutes={mappedRoutes}
    homeRoute={RoutePaths.Home}
    authRoute={RoutePaths.Auth.Login}
  />
);
