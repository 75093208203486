import { useCallback } from 'react';
import {
  useAlert,
  Messages,
  handleMutation,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { useCreateMutation } from 'store/api/internal-team-tasks';

import { getPayload } from './config';
import { InternalForm } from './form';

export const InternalTaskCreate = () => {
  const { showSuccessAlert } = useAlert();
  const [create, createMutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Internal task ${Messages.ADDED_SUCCESSFULLY}`);
  }, []);

  handleMutation({ ...createMutation, onSuccess });

  return (
    <InternalForm
      onSubmit={(values) => create(getPayload(values))}
      trigger={<StandardButtonWithRef text="Create task" />}
    />
  );
};
