import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';

import {
  HospitalReferral,
  HospitalReferralListResponse,
  HospitalReferralCreateResponse,
  HospitalReferralDeleteResponse,
  HospitalReferralUpdateResponse,
} from './types';

const hospitalReferralsAdapter = createEntityAdapter<HospitalReferral, number>({
  selectId: (item) => item.id,
});

const tagTypes = ['HospitalsReferrals'];

export const hospitalsReferralsApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'hospitalsReferralsApi',
  endpoints: (build) => ({
    update: build.mutation<HospitalReferral, HospitalReferral>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'put',
        url: URL.UPDATE_HOSPITAL_INFO_REFERAL,
      }),
      transformResponse: (response: HospitalReferralUpdateResponse) => {
        const result = response.data.data;
        return result;
      },
    }),
    create: build.mutation<HospitalReferral, HospitalReferral>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ADD_HOSPITAL_INFO_REFERAL,
      }),
      transformResponse: (response: HospitalReferralCreateResponse) => {
        const result = response.data.HospitalReferralInfo;
        return result;
      },
    }),
    list: build.query<HospitalReferral[], string>({
      providesTags: tagTypes,
      query: (id) => {
        return {
          method: 'get',
          url: `${URL.GET_HOSPITAL_INFO_REFERAL}/${id}`,
        };
      },
      transformResponse: (response: HospitalReferralListResponse) => {
        const result = response.data.hospitalReferral;
        return result;
      },
    }),
    delete: build.mutation<HospitalReferral, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_HOSPITAL_ER_REFERAL, {
          id,
        }),
      }),
      transformResponse: (response: HospitalReferralDeleteResponse) => {
        const result = response.data.hospitalReferral;
        return result;
      },
    }),
  }),
});

export const hospitalReferralsSlice = createSlice({
  reducers: {},
  name: 'hospitalsReferrals',
  initialState: hospitalReferralsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      hospitalsReferralsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        hospitalReferralsAdapter.setAll(state, payload);
      }
    );
    builder.addMatcher(
      hospitalsReferralsApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        hospitalReferralsAdapter.addOne(state, payload);
      }
    );
    builder.addMatcher(
      hospitalsReferralsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        hospitalReferralsAdapter.updateOne(state, {
          changes: payload,
          id: hospitalReferralsAdapter.selectId(payload),
        });
      }
    );
    builder.addMatcher(
      hospitalsReferralsApi.endpoints.delete.matchFulfilled,
      (state, { payload }) => {
        hospitalReferralsAdapter.removeOne(state, payload.id);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      hospitalReferralsAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useUpdateMutation,
  useCreateMutation,
  useDeleteMutation,
} = hospitalsReferralsApi;
