import { CaseFilters, HoldTimeRequest, TotalTimeRequest } from './types';

export const parseTimeFilter = (
  name: 'holdTime' | 'totalTime',
  filter?: CaseFilters['holdTime'] | CaseFilters['totalTime']
): HoldTimeRequest | TotalTimeRequest | undefined => {
  if (filter) {
    const [option, time] = filter.split('_');

    return {
      [name]: Number(time),
      [`${name}Options`]: option,
    };
  }
};
