import styled, { css } from 'styled-components';
import { Styles, EllipsisText } from '@gv/triage-components';

const { bodySemibold } = Styles.Font;

export const Title = styled(EllipsisText)<{ bold: boolean }>`
  ${({ bold }) => css`
    ${bold &&
    css`
      ${bodySemibold};
    `}
  `}
`;
