import {
  Icon,
  FlexLayout,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import { data } from './config';
import * as Styles from './styles';
import { StepPopupProps } from './types';

export const StepPopup = ({
  size,
  step,
  index,
  skipProps,
  isLastStep,
  primaryProps,
}: StepPopupProps): JSX.Element => {
  const stepData =
    typeof step.target === 'string' ? data[step.target] : undefined;

  const { title, items } = stepData || {};

  return (
    <>
      <Styles.Header>
        <h3>{title}</h3>

        <StandardButton
          padding="0"
          icon={<Icon.Close />}
          colorType={ButtonColors.Transparent}
          {...skipProps}
        />
      </Styles.Header>
      <p>Here you can:</p>
      <Styles.List>
        {items?.map(({ text, icon }, itemIndex) => (
          <Styles.ListItem key={itemIndex}>
            <Styles.IconWrapper>{icon}</Styles.IconWrapper>

            <Styles.Text>{text}</Styles.Text>
          </Styles.ListItem>
        ))}
      </Styles.List>

      <FlexLayout alignItems="center" justifyContent="space-between">
        <span>
          Step <Styles.Step>{index}</Styles.Step> of {size - 1}
        </span>

        <Styles.Next {...primaryProps} text={isLastStep ? 'Finish' : 'Next'} />
      </FlexLayout>
    </>
  );
};
