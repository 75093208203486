import { Utils } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiInstance } from 'api/instance';
import { generateUrl } from 'utils/helpers';
import { MessagesListRequest } from 'store/api/gv-messages/types';

import { GetMessageRequest } from './types';

const { addParamsToUrl } = Utils.Query;

export const getGvMessages = ({
  midId,
  nextId,
  prevId,
  chatId,
  isPinned,
  maxResults,
  threadedMessageId,
}: MessagesListRequest) =>
  apiInstance.get(
    addParamsToUrl(URL.GET_GV_TALK_MESSAGES, {
      midId,
      nextId,
      prevId,
      chatId,
      isPinned,
      maxResults,
      threadedMessageId,
    })
  );

export const getGvMessage = ({ chatId, messageId }: GetMessageRequest) =>
  apiInstance.get(
    addParamsToUrl(generateUrl(URL.GET_GV_TALK_MESSAGE, { messageId }), {
      chatId,
    })
  );
