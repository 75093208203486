import {
  Dialog,
  DialogType,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as Styles from './styles';
import { CompleteDialogProps } from './types';

export const CompleteDialog = ({
  textSlot,
  onComplete,
  triggerProps,
  completeText,
  ...props
}: CompleteDialogProps) => {
  return (
    <Dialog
      width="472px"
      title="Complete task"
      trigger={
        <Styles.CompleteTask
          {...triggerProps}
          colorType={ButtonColors.LightGreen}
        />
      }
      {...props}
    >
      {(close, Text, Buttons) => (
        <>
          <Text type={DialogType.Plain}>{textSlot}</Text>
          <Buttons type={DialogType.Plain}>
            <StandardButton
              fullWidth
              text="Cancel"
              onClick={close}
              colorType={ButtonColors.Secondary}
            />
            <Styles.CompleteDialogBtn
              fullWidth
              text={completeText}
              colorType={ButtonColors.Green}
              onClick={() => {
                onComplete();
                close();
              }}
            />
          </Buttons>
        </>
      )}
    </Dialog>
  );
};

export type { CompleteDialogProps };
