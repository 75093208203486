import { useState } from 'react';
import {
  BadgeDot,
  Nullable,
  FlexLayout,
  ButtonColors,
} from '@gv/triage-components';

import { AssignToModal, TaskSourceBadge } from 'components';
import { AssignValues, InternalTask, HospitalTask } from 'types';

import { StatusBadge } from '../styles';
import { AssignButton } from '../assign-button';
import { CompleteDialog } from '../complete-dialog';
import { InternalTaskButtons } from '../internal-task-buttons';

import * as Styles from './styles';
import { DetailsContainerProps } from './types';

const isHospitalTask = (
  data?: HospitalTask | InternalTask
): data is HospitalTask => (data as HospitalTask)?.portalType !== undefined;

export const DetailsContainer = ({
  data,
  title,
  dateSlot,
  onAssign,
  children,
  isLoading,
  isCompleted,
  isEditAccess,
  headerAction,
  assignOptions,
  headerRightSlot,
  statusRightSlot,
  statusTextConfig,
  statusColorConfig,
  statusSelectProps,
  assignButtonProps,
  disableMainScroll,
  assignToMeDisabled,
  completeDialogProps,
  disableStatusSelect,
  followUpSectionProps,
  assignSelectFieldProps,
  ...props
}: DetailsContainerProps) => {
  const [assignValues, setAssignValues] = useState<Nullable<AssignValues>>();

  const { id, status, assignee } = data ?? {};

  const { disabled: assignButtonDisabled, ...restAssignButtonProps } =
    assignButtonProps ?? {};

  return (
    <>
      <Styles.Container {...props} disableMainScroll={disableMainScroll}>
        <Styles.Header disableMainScroll={disableMainScroll}>
          <FlexLayout
            gap={16}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <FlexLayout gap={16} alignItems="center">
                <h1>{title}</h1>

                {headerRightSlot}
              </FlexLayout>

              {dateSlot}
            </div>

            {headerAction}
            {isEditAccess && (
              <InternalTaskButtons
                isEditDenied={isCompleted}
                data={data as InternalTask}
              />
            )}
          </FlexLayout>

          <FlexLayout gap={16} flexWrap="wrap" justifyContent="space-between">
            <FlexLayout gap={16} flexWrap="wrap">
              {isHospitalTask(data) && data?.portalType && (
                <TaskSourceBadge isBig portalType={data.portalType} />
              )}
              {disableStatusSelect && status ? (
                <StatusBadge
                  showDot
                  text={statusTextConfig[status]}
                  styleType={statusColorConfig[status]}
                />
              ) : (
                <Styles.SelectStatus
                  {...statusSelectProps}
                  formatOptionLabel={({ label, value }) => (
                    <FlexLayout gap={8} alignItems="center">
                      {value && (
                        <BadgeDot
                          styleType={
                            statusColorConfig[
                              value as keyof typeof statusColorConfig
                            ]
                          }
                        />
                      )}

                      {label}
                    </FlexLayout>
                  )}
                />
              )}

              <AssignButton
                assignee={assignee}
                disabled={assignButtonDisabled}
                colorType={assignee ? undefined : ButtonColors.Secondary}
                onClick={() =>
                  setAssignValues({
                    selectedTask: Number(id),
                    selectedAssignee: assignee,
                  })
                }
                {...restAssignButtonProps}
              />

              {statusRightSlot}
            </FlexLayout>

            {!isCompleted && <CompleteDialog {...completeDialogProps} />}
          </FlexLayout>
        </Styles.Header>

        <Styles.Main disableMainScroll={disableMainScroll}>
          {children({ setAssignValues })}
        </Styles.Main>
      </Styles.Container>

      <AssignToModal
        onAssign={onAssign}
        assignValues={assignValues}
        open={Boolean(assignValues)}
        assignOptions={assignOptions}
        onClose={() => setAssignValues(null)}
        assignToMeDisabled={assignToMeDisabled}
        assignSelectFieldProps={assignSelectFieldProps}
      />
    </>
  );
};
