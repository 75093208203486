import * as Yup from 'yup';
import { Utils, Client } from '@gv/triage-components';

import { removeEmptyStringsInArray } from 'utils/helpers';
import { UpdateClientPayloadV2 } from 'store/api/clients/types';
import { handleFieldArray } from 'pages/hospitals/details/clients/form/helpers';

import { FormValues } from './types';

const { filterEmptyFields } = Utils.Object;

export const getInitialValues = (client?: Client): FormValues => ({
  emails: handleFieldArray(client?.emails),
});

export const validationSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string()
      .trim()
      .email('Email must be a valid')
      .required('Email is a required field')
  ),
});

export const getClientPayload = (
  values: FormValues,
  client?: Client
): UpdateClientPayloadV2 | undefined => {
  if (!client) {
    return undefined;
  }
  const emails = removeEmptyStringsInArray(values.emails);
  const payload: UpdateClientPayloadV2 = {
    emails,
    email: emails[0],
    city: client.city,
    phones: client.phones,
    address: client.address,
    zip_code: client.zip_code,
    addresses: client.addresses,
    last_name: client.last_name,
    first_name: client.first_name,
    home_phones: client.home_phones,
  };

  return filterEmptyFields<UpdateClientPayloadV2>(payload);
};
