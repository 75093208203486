import { useMemo } from 'react';
import { SelectField } from '@gv/triage-components';

import { AppointmentTypeFieldProps } from './types';
import { getHospitalPricesOptions } from './config';

export const AppointmentTypeField = ({
  name,
  isCustomHidden,
  hospitalPrices,
}: AppointmentTypeFieldProps) => {
  const priceOptions = useMemo(
    () => getHospitalPricesOptions(hospitalPrices, isCustomHidden),
    [hospitalPrices, isCustomHidden]
  );

  return (
    <SelectField
      onlyValue
      name={name}
      options={priceOptions}
      label="Appointment type"
      requiredText="(Required)"
    />
  );
};
