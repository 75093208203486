import styled, { css } from 'styled-components';

export const Container = styled.div`
  left: 0;
  top: 50%;
  z-index: 2;
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  pointer-events: none;
  transform: translateY(-50%);
  justify-content: space-between;
`;

export const Button = styled.div<{ isLeft?: boolean }>`
  gap: 8px;
  border: 0;
  display: flex;
  padding: 16px;
  background: #0000001f;
  align-items: center;
  pointer-events: auto;
  flex-direction: column;
  justify-content: center;

  ${({ theme, isLeft }) => css`
    background-color: ${theme.colors.black.secondary.hover};
    border-top-left-radius: ${isLeft ? '0' : theme.borderRadius};
    border-bottom-left-radius: ${isLeft ? '0' : theme.borderRadius};
    border-top-right-radius: ${isLeft ? theme.borderRadius : '0'};
    border-bottom-right-radius: ${isLeft ? theme.borderRadius : '0'};
  `}
`;
