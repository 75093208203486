import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockMarketingApiQuery } from 'store/mockQuery';
import { Marketing, Subscriber, MarketingDetails } from 'types';

import {
  ListRequest,
  MarketingListResponse,
  MarketingCreateRequest,
  SubscribersListResponse,
  MarketingDetailsRequest,
  SubscribersDetailsRequest,
  SubscribersDetailsResponse,
} from './types';

const tagTypes = ['Marketing'];

export const marketingApi = createApi({
  tagTypes,
  reducerPath: 'marketingApi',
  baseQuery: mockMarketingApiQuery,
  endpoints: (build) => ({
    edit: build.mutation<void, MarketingCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'patch',
        url: URL.EDIT_MARKETING,
      }),
    }),

    create: build.mutation<void, MarketingCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_MARKETING,
      }),
    }),

    details: build.query<MarketingDetails, MarketingDetailsRequest>({
      providesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'get',
        // TODO: add generatePath by id
        url: URL.GET_MARKETING_DETAILS,
      }),
    }),

    list: build.query<Marketing[], ListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: MarketingListResponse) => response.data,
      query: (data) => ({
        data,
        method: 'get',
        url: URL.GET_MARKETING_LIST,
      }),
    }),

    subscribers: build.query<Subscriber[], ListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: SubscribersListResponse) => response.data,
      query: (data) => ({
        data,
        method: 'get',
        url: URL.GET_MARKETING_SUBSCRIBERS,
      }),
    }),

    subscribersDetails: build.query<
      SubscribersDetailsResponse,
      SubscribersDetailsRequest
    >({
      providesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'get',
        // TODO: add generatePath by id
        url: URL.GET_MARKETING_SUBSCRIBERS_DETAILS,
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useEditMutation,
  useCreateMutation,
  useSubscribersQuery,
  useSubscribersDetailsQuery,
} = marketingApi;
