import { PriorityType } from '@gv/triage-components';

import { PriorityTypeSort } from 'store/api/action-queue/types';

import * as Styles from './styles';

export const priorities = [
  {
    label: 'Admin',
    value: PriorityType.Admin,
    icon: <Styles.Dot type={PriorityType.Admin} />,
  },
  ...Object.values(PriorityType)
    .filter((type: PriorityType) => type !== PriorityType.Admin)
    .sort((typeA: PriorityType, typeB: PriorityType) => {
      const valueA = typeA === PriorityType.Admin ? 0 : PriorityTypeSort[typeA];
      const valueB = typeB === PriorityType.Admin ? 0 : PriorityTypeSort[typeB];
      return valueA < valueB ? 1 : -1;
    })
    .map((type: PriorityType) => ({
      value: type,
      label: type,
      icon: <Styles.Dot type={type} />,
    })),
];
