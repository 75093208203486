import { WeekdayNames } from 'dayjs';
import { useAudio } from 'react-use';
import { FormikValues } from 'formik';
import { SerializedError } from '@reduxjs/toolkit';
import {
  Pet,
  Step,
  Media,
  Client,
  Nullable,
  RoleType,
  IdObject,
  TimeZone,
  RingGroup,
  EventType,
  GVService,
  UserStatus,
  WorkingDay,
  UploadFile,
  Acceptance,
  TriageUser,
  StateShort,
  OpenStatus,
  ActionQueue,
  ChatMessage,
  VisitReason,
  ReachOutType,
  EntityStatus,
  UserRoleType,
  PriorityType,
  SelectOption,
  OfficeMessage,
  Recommendation,
  BaseChatEntity,
  CaseTypeString,
  VeterinaryLicense,
  AcceptanceSpecies,
  Hospital as HospitalType,
} from '@gv/triage-components';

import { PortalType } from 'config';

export interface PaginationRequest {
  page: number;
  limit?: number;
}

export interface SearchRequest {
  search?: string;
}

export interface FiltersRequest<T> {
  filters: T;
}

export type PaginationData<K extends string, T> = { count: number } & {
  [key in K]: T[];
};

export interface Tokens {
  token?: string;
  refreshToken?: string;
}

export type TokenKey = 'accessToken' | 'refreshToken';

export const EntityStatusText = {
  [EntityStatus.Block]: 'Block',
  [EntityStatus.Active]: 'Active',
  [EntityStatus.Deleted]: 'Deleted',
  [EntityStatus.Inactive]: 'Inactive',
  [EntityStatus.EmailNotVerified]: 'Email not verified',
  [EntityStatus.UnverifiedAccount]: 'Unverified account',
};

export const RolePortalType = {
  [PortalType.Team]: RoleType.Team,
  [PortalType.PetOwner]: RoleType.Team,
  [PortalType.Hospital]: RoleType.Hospital,
};

export enum PermissionItem {
  IVR = 'ivr',
  Case = 'case',
  Team = 'team',
  Setup = 'setup',
  Report = 'report',
  GVTalk = 'gvtalk',
  Hospital = 'hospital',
  Calendar = 'calendar',
  Feedback = 'feedback',
  Analytic = 'analytic',
  Voicemail = 'voicemail',
  Recording = 'recording',
  Dashboard = 'dashboard',
  Preference = 'preference',
  ActionItem = 'actionItem',
  Payments = 'paymentsWrite',
  Communication = 'communication',
  RolePermission = 'rolePermission',
  DynamicFlowsWrite = 'dynamicFlowsWrite',
  Custmoerfeedback = 'customerFeedback',
}

export interface Permission {
  [PermissionItem.IVR]?: boolean;
  [PermissionItem.Case]?: boolean;
  [PermissionItem.Team]?: boolean;
  [PermissionItem.Setup]?: boolean;
  [PermissionItem.Report]?: boolean;
  [PermissionItem.GVTalk]?: boolean;
  [PermissionItem.Payments]?: boolean;
  [PermissionItem.Calendar]?: boolean;
  [PermissionItem.Analytic]?: boolean;
  [PermissionItem.Hospital]?: boolean;
  [PermissionItem.Feedback]?: boolean;
  [PermissionItem.Voicemail]?: boolean;
  [PermissionItem.Dashboard]?: boolean;
  [PermissionItem.Recording]?: boolean;
  [PermissionItem.ActionItem]?: boolean;
  [PermissionItem.Preference]?: boolean;
  [PermissionItem.Communication]?: boolean;
  [PermissionItem.RolePermission]?: boolean;
  [PermissionItem.Custmoerfeedback]?: boolean;
  [PermissionItem.DynamicFlowsWrite]?: boolean;
}

export interface AuthResponse {
  user: TriageUser;
  permissions_v3: Permission;
}

export enum DiscardType {
  Deadline = 'Deadline',
}

export enum CallOutcomeType {
  Emergency = 'emergency',
  Appointment = 'appointment',
  Administrative = 'administrative',
  ReferTorDVM = 'referTorDVM',
  TransferToBackline = 'transferToBackline',
  DeadlineIssue = 'deadlineIssue',
}

export enum SurveyType {
  Yes = 'Yes',
  No = 'No',
}

export enum CallOutcomeAppointment {
  Scheduled = 'scheduled',
  Recommended = 'recommended',
  Rescheduled = 'rescheduled',
  Cancelled = 'cancelled',
  Declined = 'declined',
}

export enum CallOutcomeEmergency {
  ReferToEr = 'referToEr',
  ReferToPPH = 'referToPPH',
  ReferToOnCall = 'referToOnCall',
  Inbound = 'inbound',
  ClientDeclined = 'clientDeclined',
}

export enum CallOutcomeAdministrative {
  Call = 'call',
}

export enum CallOutcomeDeadlineIssue {
  DeadlineIssue = 'deadlineIssue',
}

export enum CallOutcomeReferTorDVM {
  ReferTorDVM = 'referTorDVM',
}

export enum AppointmentDeclinedReason {
  PriceServiceIssue = 'priceServiceIssue',
  AvailabilityIssue = 'availabilityIssue',
  ZipNotServiced = 'zipNotServiced',
}

export enum CallOutcomeTransferToBackline {
  NeedsTransferNotPickedUp = 'needsTransferNotPickedUp',
  NeedsTransferPickedUp = 'needsTransferPickedUp',
  UrgentAppointmentWorkInNeeded = 'urgentAppointmentWorkInNeeded',
  ServiceAvailabilityQuestion = 'serviceAvailabilityQuestion',
  CancellationsLess48Hours = 'cancellationsLess48Hours',
}

export const CallOutcomeDetailsTypes = [
  ...Object.values(CallOutcomeAppointment),
  ...Object.values(CallOutcomeEmergency),
  ...Object.values(CallOutcomeAdministrative),
  ...Object.values(CallOutcomeTransferToBackline),
  ...Object.values(CallOutcomeDeadlineIssue),
  ...Object.values(CallOutcomeReferTorDVM),
];
export type CallOutcomeDetailsType =
  | CallOutcomeEmergency
  | CallOutcomeAppointment
  | CallOutcomeReferTorDVM
  | CallOutcomeDeadlineIssue
  | CallOutcomeAdministrative
  | CallOutcomeTransferToBackline;

export const AvailabilityTime = ['Morning', 'Afternoon', 'Evening'] as const;
export type AvailabilityTimeUnion = (typeof AvailabilityTime)[number];

export interface CallOutcomeDatetime {
  hours?: string | number;
  minutes?: string | number;
  datetime?: string | number;
}

export interface CallOutcomeAppointmentRecommended {
  availabilityDays?: WeekdayNames;
  recommendation?: Recommendation;
  availabilityTime?: AvailabilityTimeUnion[];
}

export interface CallOutcomeAdministrativeCall {
  outcomes?: string[];
  customOutcome?: string;
}

export interface CallOutcomeEmergencyOnTheWay {
  STAT?: boolean;
}

export interface CallOutcomeDetails
  extends CallOutcomeAppointmentRecommended,
    CallOutcomeDatetime,
    CallOutcomeAdministrativeCall,
    CallOutcomeEmergencyOnTheWay {
  accepted?: boolean;
  reason?: AppointmentDeclinedReason;
  clientType?: CallOutcomeClientType[];
  [CallOutcomeType.Emergency]?: CallOutcomeEmergency;
  [CallOutcomeType.Appointment]?: CallOutcomeAppointment;
  [CallOutcomeType.ReferTorDVM]?: CallOutcomeReferTorDVM;
  [CallOutcomeType.DeadlineIssue]?: CallOutcomeDeadlineIssue;
  [CallOutcomeType.Administrative]?: CallOutcomeAdministrative;
  [CallOutcomeType.TransferToBackline]?: CallOutcomeTransferToBackline;
}

export enum CallOutcomeStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export const CallOutcomeEmergencyTitle = {
  [CallOutcomeEmergency.ReferToEr]: 'Refer to ER',
  [CallOutcomeEmergency.ReferToPPH]: 'Refer to PPH',
  [CallOutcomeEmergency.Inbound]: 'Emergency - Inbound',
  [CallOutcomeEmergency.ReferToOnCall]: 'Refer to on call',
  [CallOutcomeEmergency.ClientDeclined]: 'Emergency - Client declined',
};

export interface ContactInfoParsed {
  id: number;
  roleId?: UserRoleType;
  role?: Nullable<string>;
  name?: Nullable<string>;
  phone?: Nullable<string>;
  email?: Nullable<string>;
  profile_pic?: Nullable<string>;
}

export interface ExploreUser {
  id: number;
  name: string;
  role: string;
  address: string;
  location: string;
  status?: UserStatus;
  specialities: string[];
}

export interface CallOutcome {
  id: number;
  caseId: number;
  createdAt: string;
  updatedAt: string;
  parentTaskId: number;
  type: CallOutcomeType;
  doctorId: Nullable<number>;
  details: CallOutcomeDetails;
  hospitalId: Nullable<number>;
  hospitalRefId: Nullable<number>;
  corporate_hospital_id?: Nullable<number>;
  doctor: Nullable<{ id: number; name: string }>;
  hospital: Nullable<{ id: number; name: string }>;
  mainHospital: Nullable<{ id: number; name: string }>;
}

export interface FormCallOutcomeAccepted {
  accepted?: string;
}

export interface FormCallOutcomeDetails
  extends CallOutcomeAppointmentRecommended,
    CallOutcomeDatetime,
    FormCallOutcomeAccepted,
    CallOutcomeEmergencyOnTheWay,
    CallOutcomeAdministrativeCall {
  doctorId?: string;
  hospitalId?: string;
  serviceType?: string;
  parentTaskId?: string;
  hospitalRefId?: string;
  email_for_invoice?: string;
  corporate_hospital_id?: string;
  clientType?: CallOutcomeClientType[];
  reason?: '' | AppointmentDeclinedReason;
}

export interface FormCallOutcome {
  type?: '' | CallOutcomeType;
  [CallOutcomeType.ReferTorDVM]: {
    type?: '' | CallOutcomeReferTorDVM;
  };
  [CallOutcomeType.DeadlineIssue]: {
    type?: '' | CallOutcomeDeadlineIssue;
  };
  [CallOutcomeType.TransferToBackline]: {
    type?: '' | CallOutcomeTransferToBackline;
  };
  [CallOutcomeType.Administrative]: {
    type: CallOutcomeDetailsType;
    [CallOutcomeAdministrative.Call]?: FormCallOutcomeDetails;
  };

  [CallOutcomeType.Emergency]: {
    type?: '' | CallOutcomeDetailsType;
    [CallOutcomeEmergency.ReferToEr]?: FormCallOutcomeDetails;
    [CallOutcomeEmergency.ReferToPPH]?: FormCallOutcomeDetails;
    [CallOutcomeEmergency.ReferToOnCall]?: FormCallOutcomeDetails;
  };
  [CallOutcomeType.Appointment]: {
    type?: '' | CallOutcomeDetailsType;
    [CallOutcomeAppointment.Declined]?: FormCallOutcomeDetails;
    [CallOutcomeAppointment.Scheduled]?: FormCallOutcomeDetails;
    [CallOutcomeAppointment.Recommended]?: FormCallOutcomeDetails;
  };
}

export interface UserMin
  extends Pick<TriageUser, 'id' | 'name' | 'profile_pic'> {
  role: string;
}

export type AssigneeUser = Pick<UserMin, 'id' | 'name'>;

export interface CaseOwnFields extends Record<string, any> {
  $flowId?: number;
  $passedSteps?: string[];
}

export interface Case {
  id: number;
  name?: string;
  u_id?: number;
  email?: string;
  pet_id?: number;
  call_id?: number;
  species?: string;
  pdf_url?: string;
  client_id?: number;
  updated_at: string;
  created_at: string;
  case_type?: number;
  last_seen?: string;
  invoiceId?: number;
  callReason?: string;
  chat_channel?: Chat;
  hospital_id?: number;
  app_language: string;
  channel_sid?: string;
  invoices?: Invoice[];
  client_name?: string;
  parentTaskId?: number;
  attendedBy: UserMin[];
  call_outcome?: string;
  is_submitted: boolean;
  client_phone?: string;
  receiver_id: number[];
  channel_name?: string;
  is_discarded: boolean;
  GV_team_member: string;
  addendums?: Addendum[];
  is_incompleted: boolean;
  classification?: string;
  careDoctor?: TriageUser;
  problem_summary?: string;
  pms_record_type?: string;
  client_hospital?: string;
  follow_up_steps?: string;
  recording_url?: string[];
  medications_list?: string;
  additional_phone?: string;
  additional_notes?: string;
  pets?: Nullable<number[]>;
  chief_complaints: string[];
  record_created_at?: string;
  appetite: Nullable<string>;
  own_fields?: CaseOwnFields;
  price_id?: Nullable<number>;
  careHospital?: HospitalType;
  referring_practice?: string;
  custom_care_doctor?: string;
  GV_team_member_role: string;
  cvt_plan?: Nullable<string>;
  csr_plan?: Nullable<string>;
  was_auto_submitted?: boolean;
  custom_care_hospital?: string;
  triage_recommendation?: string;
  current_step: ConsultationStep;
  veterinary_service_id?: number;
  chronial_medical_issues?: string;
  activity_level: Nullable<string>;
  is_species_seen_confirm: boolean;
  payment_status?: Nullable<string>;
  payment_intent?: Nullable<string>;
  is_emergency_fee_confirm: boolean;
  priority_of_concern?: PriorityType;
  callOutcome: Nullable<CallOutcome>;
  assessment_note?: Nullable<string>;
  max_opened_step?: ConsultationStep;
  pet?: Pick<Pet, 'name' | 'species'>;
  general_stability: Nullable<string>;
  client_denied_er_form_notes?: string;
  respiratory_stability: Nullable<string>;
  recommendation_Level_Of_Concern?: string;
  neurological_stability: Nullable<string>;
  corporate_hospital_id?: Nullable<number>;
  specify_administrative_issue?: Nullable<string>;
  pain_level_assessed_by_caller: Nullable<string>;
  updatedBy: {
    id: number;
    name: string;
    role?: Nullable<string>;
  };
  client?: {
    name: string;
    phone: string;
    hospital?: {
      timezone?: TimeZone;
    };
  };
  discardCase?: {
    note: Nullable<string>;
    other: Nullable<string>;
    type: Nullable<DiscardType>;
  };
}

export interface ActionItem extends Case {
  handled_by?: string;
  call_outcome: string;
}

export interface Addendum {
  id: number;
  user_id: number;
  case_id: number;
  created_at: string;
  addendum_msg: string;
  user?: {
    name: string;
  };
}

export const ReachOutOptions = [ReachOutType.Text, ReachOutType.Call] as const;

export const SpecialityShortName = {
  Mobile: 'Mobile',
  Wellness: 'Wellness',
  Emergency: 'ER only',
  Specialist: 'Speciality only',
  'General practice': 'General practice',
  'Emergency & Specialty': 'ER & Specialty',
};

export const ServiceOptions = [
  'After hours referral only',
  'After hours on call',
  'VSCR',
  'Callback',
  'Overflow',
] as const;
export type Service = (typeof ServiceOptions)[number];

export enum HospitalStatus {
  Active = 3,
  NotActive = 6,
}

export enum NurseStatus {
  Free = 'online',
  Chat = 'chat',
  Call = 'call',
  Break = 'break',
  Offline = 'offline',
  Coaching = 'coaching',
}

export enum MemberType {
  CVT = 'CVT',
  CSR = 'CSR',
  TeamCoach = 'Team Coach',
}

export interface CaseGreeting {
  id: string;
  greeting: string;
  updatedAt: string;
  teamMember: MemberType;
}

export interface Doctor {
  id: number;
  email: string;
  notes: string;
  userId: number;
  user: TriageUser;
  etaOfDvm: string;
  isShared: boolean;
  updatedAt: string;
  photoUrl?: string;
  erExamFees: string;
  phoneNumber: string;
  phoneConsultFees: string;
  locationOfServices: string;
  firstReachOut: ReachOutType;
  speciesSeenOnCall: string[];
  secondReachOut: ReachOutType;
  speciesSeenDuringBusinessHours: string[];
}

export interface PetSpecies extends AcceptanceSpecies {
  created_at: string;
  updated_at: string;
  species_id?: number;
  status: EntityStatus;
}

export interface PetBreed {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  species_id?: number;
  status: EntityStatus;
  species?: Pick<PetSpecies, 'id' | 'name'>;
}

export interface PetGender {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  species_id?: number;
  status: EntityStatus;
  species?: Pick<PetSpecies, 'id' | 'name'>;
}

export interface IvrGreeting {
  id: number;
  enable: boolean;
  hospital_id: number;
  status: EntityStatus;
  greeting_name: string;
  greeting_type: number;
  greeting_file_url: string;
}

export enum TechnicalIssue {
  CallDroping = 'callDroping',
  AudioQuality = 'audioQuality',
  Static = 'static',
  Lagging = 'lagging',
  Other = 'other',
}

export const TechnicalIssueLabel = {
  [TechnicalIssue.Other]: 'Other',
  [TechnicalIssue.Static]: 'Static',
  [TechnicalIssue.Lagging]: 'Lagging',
  [TechnicalIssue.CallDroping]: 'Call dropping',
  [TechnicalIssue.AudioQuality]: 'Audio quality',
};

export enum CaseReportReview {
  GreatCall = 'greatCall',
  DifficultClient = 'difficultClient',
  GoodForTraining = 'goodForTraining',
  ProtocolQuestion = 'protocolQuestion',
  TriageQuestion = 'triageQuestion',
}

export const CaseReportReviewLabel = {
  [CaseReportReview.GreatCall]: 'Great call',
  [CaseReportReview.TriageQuestion]: 'Triage question',
  [CaseReportReview.DifficultClient]: 'Difficult client',
  [CaseReportReview.GoodForTraining]: 'Good for training',
  [CaseReportReview.ProtocolQuestion]: 'Protocol question',
};

export const CallOutcomeOptions = [
  'Vaccination records',
  'Returning call from DVM',
  'Online pharmacy calling for authorization',
  'Deceased pet',
  'Stray/wildlife',
  'Lost pet / Found pet',
  'Pickup pet',
  'RX/Food prescription request',
  'Billing concern',
  'Deadline issue',
  'Patient Update',
  'General Question',
] as const;

export interface ChiefComplaint {
  id: number;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
  ChiefComplaintsSystemCategory: any;
  ChiefComplaintsCategory: {
    id: number;
    name: string;
  };
}

export interface MedicationCategory {
  id: number;
  name: string;
}

export enum ApplicationMethods {
  Oral = 'Oral',
  Injectable = 'Injectable',
  TopicalOrPatch = 'Topical or Patch',
  Inhalants = 'Inhalants',
  Heartgard = 'Heartgard',
  Other = 'Other',
}

export interface Medication {
  id: number;
  name: string;
  description: string;
  category: MedicationCategory;
  application_methods: string[];
}

export interface CaseMedication {
  medications_type?: string;
  medications_strenght?: string;
  medications_frequency?: string;
  medications_brand_name?: string;
}

export interface SavedCaseForm {
  data: any;
  userId: number;
  roomId: string;
  expiredAt: number;
}

export interface PricingService {
  id: number;
  name: string;
  comment: string;
  maxPrice: number;
  minPrice: number;
  paymentForm: string;
  group?: {
    id: number;
    name: string;
  };
}

export interface PricingGroup {
  id: string;
  name: string;
}

export interface Pricing extends PricingGroup {
  services: PricingService[];
}

export interface WorkflowCreds {
  link?: string;
  password?: string;
  username?: string;
}

export interface Workflow {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  hospitalId?: number;
  actionsAllowed?: string[];
  userCreds?: WorkflowCreds;
  actionsNotAllowed?: string[];
  hospitalCreds?: WorkflowCreds;
  appointmentsAllowed?: string[];
  appointmentsNotAllowed?: string[];
  criticalSchedulingInstructions?: string[];
}

export interface CasesFilterValues {
  type?: string;
  endDate?: string;
  issues?: string[];
  holdTime?: string;
  caseType?: string;
  startDate?: string;
  totalTime?: string;
  hospitalId?: string;
  client_name?: string;
  wasAutoSubmitted?: string;
}

export enum CallStatus {
  Initiated = 'initiated',
  Ringing = 'ringing',
  InProgress = 'in-progress',
  Completed = 'completed',
}

export interface CallFlow {
  id: number;
  name: string;
  index?: number;
  steps: string[];
  position?: number;
  updatedAt: string;
  isSystem?: boolean;
  hideDuringCall?: boolean;
}

export interface CalendarDoc {
  id: number;
  name: string;
  phone: string;
  color: string;
  email: string;
  species?: string[];
}

export enum CommMobileTab {
  Chat = 'Chat',
  Case = 'Case',
}

export interface Role {
  id: number;
  color: string;
  level: number;
  role_name: string;
  updated_at: string;
  role_type: RoleType;
  hospital_id?: number;
}

export interface RolePermission extends Role {
  permission: Permission;
}

export const PermissionTitles = {
  [PermissionItem.IVR]: 'IVR',
  [PermissionItem.Case]: 'Case',
  [PermissionItem.Team]: 'Team',
  [PermissionItem.Setup]: 'Setup',
  [PermissionItem.Report]: 'Report',
  [PermissionItem.Hospital]: 'Hospital',
  [PermissionItem.Calendar]: 'Calendar',
  [PermissionItem.Feedback]: 'Feedback',
  [PermissionItem.Payments]: 'Payments',
  [PermissionItem.Analytic]: 'Analytics',
  [PermissionItem.Voicemail]: 'Voicemail',
  [PermissionItem.Recording]: 'Recording',
  [PermissionItem.Dashboard]: 'Dashboard',
  [PermissionItem.Preference]: 'Preference',
  [PermissionItem.Communication]: 'Gv Comm',
  [PermissionItem.ActionItem]: 'Action Items',
  [PermissionItem.RolePermission]: 'Role Permission',
};

export enum TransferContactType {
  DVM = 'dvm',
  Hospital = 'emergency-hospital',
}

export interface TransferContact {
  id: number;
  name: string;
  userId?: number;
  avatar?: string;
  phones: string[];
  subtitle: string;
  isOnCall: boolean;
  type: TransferContactType;
}

export interface TeamMemberUser extends Omit<TriageUser, 'roles'> {
  role: Role;
  color: string;
  updated_at?: string;
  roles: RolePermission[];
  email_verified: boolean;
}

export interface Voicemail {
  id: number;
  from: string;
  case_form: Case;
  audio_url: string;
  client_id: number;
  created_at: string;
  updated_at: string;
  read_status: number;
  issue: Nullable<string>;
  urgency: Nullable<string>;
  recording_duration: string;
  handled_by: Nullable<number>;
  action_item: Nullable<string>;
  activity_type: Nullable<string>;
  pet: Nullable<Omit<Pet, 'pms_id' | 'client_id' | 'is_deceased'>>;
  client: Nullable<
    { hospital: Pick<HospitalType, 'id' | 'name' | 'timezone'> } & Pick<
      Client,
      'id' | 'name' | 'phone' | 'email' | 'phones' | 'address' | 'home_phones'
    >
  >;
}

export interface UseQueryReturnProps<T = unknown> {
  data?: T;
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
  error?: Error | SerializedError;
}

export interface Visit
  extends Pick<WorkingDay, 'end' | 'start' | 'time_slots'> {
  id: number;
  title: string;
  start_date: number;
  dvm_user_id: number;
  hospital_id: number;
  user: {
    id: number;
    name: string;
    email: string;
    phone: string;
  };
}

export interface OnCallDay extends WorkingDay {
  doctor_id: number;
  contact_id?: number;
}

export interface VirtualExamDay extends OnCallDay {
  client_id: number;
}

export enum RepeatType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

// TODO: remove
export interface OnTheWayCase
  extends Pick<
    Case,
    'id' | 'created_at' | 'chief_complaints' | 'corporate_hospital_id'
  > {
  pet: Pet;
  client: Client;
  last_seen: string;
  eta: Nullable<string>;
  GV_team_member: string;
}

export interface AppointmentCase
  extends Pick<
    Case,
    'id' | 'created_at' | 'chief_complaints' | 'corporate_hospital_id'
  > {
  pet: Pet;
  client: Client;
  last_seen: string;
  eta: Nullable<string>;
  GV_team_member: string;
}

export interface OnTheWay {
  id: number;
  caseId: number;
  createdAt: string;
  case: OnTheWayCase;
  notes: Nullable<string>;
  updatedAt: Nullable<string>;
  patientArrivedAt: Nullable<string>;
}

export interface Appointment {
  id: number;
  caseId: number;
  createdAt: string;
  case: AppointmentCase;
  notes: Nullable<string>;
  updatedAt: Nullable<string>;
  patientArrivedAt: Nullable<string>;
}

export enum UserActivityStatus {
  Active = 'active',
  Blocked = 'blocked',
  Deleted = 'deleted',
  Unverified = 'unverified',
  Deactivated = 'deactivated',
  EmailNotVerified = 'emailNotVerified',
}

export const UserActivityStatusTitle = {
  [UserActivityStatus.Active]: 'Active',
  [UserActivityStatus.Deactivated]: 'Deactivated',
};

export interface UserShorted {
  id: number;
  name: string;
  isActive: boolean;
  status?: NurseStatus;
  answeredCalls: number;
  submittedCases: number;
  last_online_at?: string;
  averageDuration: number;
  profile_pic: Nullable<string>;
  role: {
    id: number;
    name: string;
  };
}

export interface WalkinDropoffFormValues {
  walkin_status: string;
  dropoff_status: string;
}

export interface WalkinDropoffStatusesRequest {
  walkin_accepted?: Nullable<boolean>;
  dropoff_accepted?: Nullable<boolean>;
}

export enum ChatEndStatus {
  Active,
  Ended,
}

export interface Chat
  extends Pick<
    ActionQueue,
    | 'to'
    | 'u_id'
    | 'c_id'
    | 'from'
    | 'is_dvm'
    | 'is_tele'
    | 'case_id'
    | 'end_time'
    | 'is_triage'
    | 'is_vendor'
    | 'start_time'
    | 'is_digi_com'
    | 'receiver_id'
    | 'channel_name'
    | 'hold_time_sec'
    | 'place_back_time'
    | 'picked_timestamp'
  > {
  id: number;
  type: number;
  name?: string;
  image?: string;
  updated_by: number;
  created_at?: string;
  updated_at?: string;
  channel_sid?: string;
  hospital_id?: number;
  total_time_sec: number;
  transaction_id?: number;
  active_receiver?: number;
  client?: Partial<Client>;
  end_status: ChatEndStatus;
  last_message?: ChatMessage;
  is_picked?: number | boolean;
  room_id: ActionQueue['roomId'];
  vendor_client_unique_id?: number;
  hospital?: Partial<HospitalType>;
  for_appointment: number | boolean;
}

export interface Attendant {
  id: number;
  name: string;
  role: UserRoleType;
}

export interface FeedbackCaseForm {
  id: number;
  hospital_id: number;
  attend_by: Attendant[];
  hospital: { name: string };
  chat_channel?: { id: string };
  client: { name: string; phone: string };
}

export interface Feedback {
  id: number;
  note?: string;
  caseId: number;
  rating: number;
  case_id: number;
  createdAt: string;
  isPublic: boolean;
  updatedAt: string;
  callId: number | null;
  case_form: FeedbackCaseForm;
}

export interface PagePermissions {
  ivr?: boolean;
  team?: boolean;
  help?: boolean;
  queue?: boolean;
  cases?: boolean;
  tasks?: boolean;
  office?: boolean;
  nurses?: boolean;
  clients?: boolean;
  payment?: boolean;
  hospital?: boolean;
  outbound?: boolean;
  calendar?: boolean;
  dashboard?: boolean;
  hospitals?: boolean;
  feedbacks?: boolean;
  analytics?: boolean;
  ringGroups?: boolean;
  voicemails?: boolean;
  recordings?: boolean;
  actionItems?: boolean;
  internalTasks?: boolean;
  teamAnalytics?: boolean;
  masterRecords?: boolean;
  paymentsWrite?: boolean;
  subscriptions?: boolean;
  Custmoerfeedback?: boolean;
  callNotifications?: boolean;
  dynamicFlowsWrite?: boolean;
}

export interface VeterinaryLicensePayload
  extends Omit<VeterinaryLicense, 'id' | 'state' | 'expiresAt'> {
  id?: number;
  stateId: number;
  expiresAt: Date;
}

export interface TeamMemberFormVeterinaryLicense
  extends Omit<VeterinaryLicensePayload, 'stateId'> {
  state: string;
}

export interface TeamMemberPayload
  extends Pick<
    TeamMemberUser,
    'name' | 'email' | 'phone' | 'color' | 'profile_pic' | 'isActiveOnCall'
  > {
  role: number;
  timezone_id?: number;
  languages?: number[];
  profile_link?: string;
  contact_number?: string;
  veterinary_licenses?: VeterinaryLicensePayload[];
}

export interface TeamMemberFormValues
  extends Pick<
    TeamMemberPayload,
    | 'email'
    | 'color'
    | 'phone'
    | 'profile_link'
    | 'isActiveOnCall'
    | 'contact_number'
  > {
  role: string;
  lastName: string;
  firstName: string;
  languages: string[];
  timezone_id?: string;
  profile_pic?: string | UploadFile;
  veterinary_licenses: Partial<TeamMemberFormVeterinaryLicense>[];
}

export enum CalendarEntryTypes {
  OnCall = 'onCall',
  ClinicHours = 'clinicHours',
  Availability = 'availability',
  ForwardingHours = 'blockingHours',
  VirtualAvailability = 'virtualAvailability',
  VirtualExams = 'virtualExams',
  Appointment = 'appointment',
  AppointmentHrs = 'appointmentHrs',
}

export enum AssessmentField {
  Appetite = 'appetite',
  ActivityLevel = 'activity_level',
  GeneralStability = 'general_stability',
  RespiratoryStability = 'respiratory_stability',
  NeurologicalStability = 'neurological_stability',
  PainLevelAssessedByCaller = 'pain_level_assessed_by_caller',
}

export enum CallFlowFolderName {
  Urgent = 'Medical: Urgent/ER',
  Scheduling = 'Admin: Scheduling',
}

export enum CorporateGroupStatus {
  Active = 'active',
  Pending = 'pending',
  Inactive = 'inactive',
}

export interface DepartmentHospital {
  id: number;
  name: string;
  address: string;
  icon_url?: string;
  zip_code?: string;
  city_name?: string;
  HospitalAcceptance?: Pick<
    Acceptance,
    'waitTime' | 'traumaLevel' | 'waitTimeRange' | 'customWaitTimes'
  >;
}

export enum CorporateGroupType {
  All = 'all',
}

export interface CorporateGroup {
  id: number;
  subType: string;
  createdAt: string;
  updatedAt: string;
  type: CallOutcomeType;
  status: CorporateGroupStatus;
  flows: { id: number; name: string }[];
  departmentHospital: DepartmentHospital;
}

export interface ChatFolder extends BaseChatEntity {
  isSystem: boolean;
  createdAt: string;
  updatedAt: string;
  sectionId: number;
}

export interface ChatSection extends BaseChatEntity {
  createdAt: string;
  updatedAt: string;
  workspaceId?: number;
  folders: ChatFolder[];
}

export interface NurseInfo {
  id: number;
  user_id: number;
  is_test: boolean;
  hired_date: string;
  is_deleted: boolean;
  worker_sid?: string;
  states_allowed?: number[] | StateShort[];
}

export interface Nurse
  extends Pick<
    TriageUser,
    'id' | 'name' | 'phone' | 'email' | 'status' | 'profile_pic'
  > {
  nurse: NurseInfo;
  roles?: number[];
}

export interface ChatFile extends Media {
  key: string;
  messageId: number;
  createdAt: string;
  updatedAt: string;
  message?: Pick<OfficeMessage, 'id' | 'text' | 'author' | 'isDeleted'>;
}

export enum MarketingType {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Completed = 'complited',
}

//TODO: change by real data
export interface Marketing {
  id: number;
  end?: number;
  name: string;
  start?: number;
  clickRate?: number;
  type: MarketingType;
  subscribers?: number;
  responseRate?: number;
}

//TODO: change by real data
export interface Subscriber {
  id: number;
  title: string;
  contacts: string[];
}

export enum PriorityOfConcern {
  Low = 'Low',
  High = 'High',
  Admin = 'Admin',
  Other = 'Other',
  Medium = 'Medium',
  Referred = 'Referred',
  Voicemail = 'Voicemail',
}

//TODO: update by real data
export interface OfficeTask {
  id: number;
  created_at: number;
  assigned_to: Nullable<number>;
  priority_of_concern: PriorityOfConcern;
  created_by: {
    id: number;
    name: string;
  };
  client?: {
    name?: string;
    phone: string;
  };
}

export enum TaskEventType {
  AssignTo = 'Assign to',
  AssignToMe = 'Assign to me',
  MoveToUnassigned = 'Move to unassigned',
}

export enum PetOwnerTemplate {
  ER = 'er',
  Payment = 'payment',
  Hotline = 'hotline',
  Custom = 'custom',
  PaymentLinks = 'paymentLinks',
}

export enum MessageRecipientType {
  DVM = 'on-call',
  PetOwner = 'pet owner',
  Other = 'other',
}

export type CaseLogDetailsAddendum = {
  note: string;
};

export type CaseLogDetailsTransfer = {
  phone: string;
};

export type CaseLogDetailsRecording = {
  recording: string;
};

export type CaseLogDetailsChat = {
  channelName: string;
};

export type CaseLogDetailsTextOut = {
  body: string;
  note: string;
  phone: string;
  petOwnerType?: PetOwnerTemplate;
  textOutType?: MessageRecipientType;
};

export type CaseLogDetailsSubmit = {
  wasAutoSubmitted?: boolean;
};

export type CaseLogEvent = {
  id: number;
  callId: number;
  caseId: number;
  type: EventType;
  timestamp: string;
  receiver?: UserMin;
  initiator?: UserMin;
  client?: Pick<Client, 'id' | 'name' | 'phone'>;
  feedback?: Pick<Feedback, 'id' | 'note' | 'rating'>;
  details?:
    | CaseLogDetailsChat
    | CaseLogDetailsSubmit
    | CaseLogDetailsTextOut
    | CaseLogDetailsAddendum
    | CaseLogDetailsTransfer
    | CaseLogDetailsRecording;
};

export type CaseLog = {
  caseId: number;
  callId: number;
  created_at: string;
  type: CaseTypeString;
  events: CaseLogEvent[];
  attendedBy?: UserMin[];
};

//TODO: change by real data
export interface SubscriberDetails {
  id: number;
  type: string;
  name: string;
  phone: string;
  click?: boolean;
  response?: boolean;
}

//TODO: change by real data
export interface MarketingDetails extends Omit<Marketing, 'subscribers'> {
  messages?: ChatMessage[];
  subscribers?: SubscriberDetails[];
}

export enum MarketingCampaignType {
  Single = 'single',
  Multiply = 'multiply',
}

export enum MarketingDelay {
  ThreeDays = '3',
  FiveDays = '5',
  SevenDays = '7',
  Custom = 'Custom',
}

export interface TotalProps {
  total: number;
}

export interface CallOutcomeRecommended
  extends TotalProps,
    Record<Recommendation, TotalProps> {}

export interface ReferTo extends TotalProps {
  stat?: TotalProps;
  nonStat?: TotalProps;
  accepted?: TotalProps;
  declined?: TotalProps;
}

export interface ReferToOTW extends TotalProps {
  stat?: TotalProps;
  nonStat?: TotalProps;
}

export interface AppointmentScheduledAnalytics extends TotalProps {
  new?: TotalProps;
  current?: TotalProps;
  wellness?: TotalProps;
  corporate?: TotalProps;
}

export interface AppointmentDeclinedAnalytics
  extends TotalProps,
    Record<AppointmentDeclinedReason, TotalProps> {}

export interface AppointmentActivity extends TotalProps {
  notSet?: TotalProps;
  [CallOutcomeAppointment.Cancelled]?: TotalProps;
  [CallOutcomeAppointment.Rescheduled]?: TotalProps;
  [CallOutcomeAppointment.Recommended]?: CallOutcomeRecommended;
  [CallOutcomeAppointment.Declined]?: AppointmentDeclinedAnalytics;
  [CallOutcomeAppointment.Scheduled]?: AppointmentScheduledAnalytics;
}

export interface EmergencyActivity extends TotalProps {
  [CallOutcomeEmergency.ReferToEr]: ReferTo;
  [CallOutcomeEmergency.ReferToPPH]: ReferTo;
  [CallOutcomeEmergency.ReferToOnCall]: ReferTo;
}

export interface AdministrativeActivity extends TotalProps {
  totalOutcomes?: number;
  totalWithoutOutcomes?: number;
  topOutcomes: {
    name: string;
    quantity: number;
  }[];
}

export interface TransferToBacklineAnalytics
  extends TotalProps,
    Record<CallOutcomeTransferToBackline, TotalProps> {
  other?: TotalProps;
  byDay: { date: string; count: number }[];
}

export interface SubmitedCases extends TotalProps {
  other?: TotalProps;
  [CallOutcomeType.Emergency]?: EmergencyActivity;
  [CallOutcomeType.Appointment]?: AppointmentActivity;
  [CallOutcomeType.Administrative]?: AdministrativeActivity;
  [CallOutcomeType.TransferToBackline]?: TransferToBacklineAnalytics;
}

export interface OtherTotalProps {
  other?: TotalProps;
}

export type EmergencyOtcomesClients = TotalProps &
  OtherTotalProps &
  Record<CallOutcomeEmergency, TotalProps>;

export type TransferOtcomesClients = TotalProps &
  OtherTotalProps &
  Record<CallOutcomeTransferToBackline, TotalProps>;

export type AppointmentOtcomesClients = TotalProps &
  OtherTotalProps &
  Record<CallOutcomeAppointment, TotalProps>;

export interface NewClientsOutcomes extends TotalProps {
  [CallOutcomeType.Emergency]?: EmergencyOtcomesClients;
  [CallOutcomeType.Appointment]?: AppointmentOtcomesClients;
  [CallOutcomeType.TransferToBackline]?: TransferOtcomesClients;
}

export type AnalyticsPageData = {
  timeSaved: number;
  totalIssues: number;
  lastUpdated?: string;
  revenueGenerated: number;
  totalCallsHandled: number;
  isReasonAvailable?: boolean;
  casesHandled: SubmitedCases;
  blockedCallOutcomes?: string[];
  isAdministrativeVisible?: boolean;
  callsByDay: Record<string, number>;
  callsByHour: Record<string, number>;
  isTransferToBacklineVisible?: boolean;
  callOutcomeTitles?: Record<string, string>;
  topIssues: {
    name: string;
    quantity: number;
  }[];
  acceptedAppointments?: HospitalType['accepted_appointment_types'];
  clients: {
    total?: number;
    newClients?: number;
    newClientsOutcomes?: NewClientsOutcomes;
    newClientsByDates?: { date: string; count: number }[];
  };
};

export interface TeamAnalyticsTotal {
  total: number;
}

export interface TeamAnalyticsBaseData extends TeamAnalyticsTotal {
  duration: number;
  totalOnShift: number;
  totalSubmitted: number;
  casesSubmittedPerHour: number;
  autoSubmittedByReceiver: number;
  totalByHour: Record<string, Record<string, number>>;
  AVG: {
    count: {
      perDay: number;
      perHour: number;
    };
    duration: {
      perDay: number;
      perCall: number;
      perHour: number;
      perCallAllTime: number;
    };
  };
}

export interface UserLastHour {
  avgCallTime: number;
  casesSubmitted: number;
  timeOnShiftVsTimeOnCall: {
    timeOnCall: number;
    timeOnShift: number;
  };
}

export interface TeamAnalyticsUser
  extends TeamAnalyticsBaseData,
    Pick<TriageUser, 'name'> {
  role: string;
  timeOnline: number;
  logoutCount: number;
  lastHour: UserLastHour;
  userId: TriageUser['id'];
  avatar: TriageUser['profile_pic'];
  statuses: Record<NurseStatus, number>;
}

export interface TeamAnalyticsData extends TeamAnalyticsBaseData {
  totalAutoSubmitted?: number;
  totalSubmitsByUsers?: number;
  answered?: TeamAnalyticsTotal;
  missed?: TeamAnalyticsTotal & {
    hospitals: ({ iconUrl: string } & Pick<HospitalType, 'name' | 'address'> & {
        hospitalId: number;
        totalCalls: number;
        totalMissedCalls: number;
      })[];
  };
}

export interface TeamAnalytics {
  team: TeamAnalyticsData;
  users: TeamAnalyticsUser[];
}

export interface RingGroupDetails extends RingGroup {
  status: number;
  created_at: number;
  updated_at: number;
  app_language: string;
}

export interface Recording {
  id: number;
  url: string;
  name: string;
}

export enum ConsultationStep {
  Greeting = 'greeting',
  PetOwner = 'petOwner',
  ProblemSummary = 'problemSummary',
  CallOutcome = 'callOutcome',
  DeadlineIssue = 'deadlineIssue',
}

export enum CallTab {
  Consultation = 'consultation',
  Summary = 'summary',
  CallProtocols = 'call-protocols',
  Hospital = 'hospital',
  Calendar = 'calendar',
  Cases = 'cases',
  Schedule = 'scheduling-matrix',
}

export interface CaseFormStep {
  currentStep: ConsultationStep;
  stepsList: ConsultationStep[];
  maxOpenedStep: ConsultationStep;
}

export type AudioControls = ReturnType<typeof useAudio>[2];

export enum GreetingType {
  Upload = 'upload',
  Record = 'record',
}

export type UploadedAudio = Nullable<Partial<UploadFile>>;

export interface IVR {
  id: number;
  name: string;
  is_global: boolean;
}

export interface NotifyUser {
  id: number;
  name: string;
  emails: string;
  contacts: string;
  created_at?: string;
  updated_at?: string;
  status: EntityStatus;
}

export enum SuperAdminSettingsKey {
  HoldTimeReport = 'hold-time-report',
  PickUpTimeReport = 'pickup-time-report',
  LowCallsVolumeReport = 'low-calls-volume-report',
}

export interface SuperAdminSettings {
  id: number;
  value: string;
  key: SuperAdminSettingsKey;
}

export enum ChatRequestType {
  All = 'all',
  Queue = 'queue',
  Folder = 'folder',
  Channel = 'channel',
  GroupChat = 'group-chat',
  PrivateChat = 'private-chat',
}

export enum ChatMemberRole {
  Admin = 'admin',
  Member = 'member',
}

export enum IVRMessageType {
  Message,
  Audio,
}

export enum IVRAudioType {
  Upload = 'Upload',
  SelectUploaded = 'Select from uploaded',
}

export enum IVRWelcomeMessage {
  Yes,
  No,
}

export enum IVRActions {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  RingGroup,
  IVR,
  Voicemail,
  ForwardToNumber,
}

export const IVRActionsLabel = {
  [IVRActions.IVR]: 'IVR',
  [IVRActions.Voicemail]: 'Voicemail',
  [IVRActions.RingGroup]: 'Ring group',
  [IVRActions.ForwardToNumber]: 'Forward to another number',
};

export enum TaskClass {
  Emergency = 'emergency',
  Appointment = 'appointment',
  Administrative = 'administrative',
  TransferToBackline = 'transferToBackline',
}

export const ExtendedTaskClass = {
  ...TaskClass,
  VirtualExam: 'virtualExam',
  VirtualExamWithoutPayment: 'virtualExamWithoutPayment',
  AppointmentWithoutPayment: 'appointmentWithoutPayment',
};

export enum TaskStatus {
  Review = 'review',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Reviewing = 'reviewing',
}

export enum AppointmentTaskStatus {
  Canceled = 'Canceled',
  Review = 'For Review',
  Confirmed = 'Confirmed',
}

export enum TaskType {
  Case = 'case',
  RxRefill = 'rx refill',
  OnTheWay = 'on the way',
  Voicemail = 'voicemail',
  Scheduled = 'scheduled',
  Appointment = 'appointment',
  Recommended = 'recommended',
  VirtualExam = 'virtual exam',
  RequestMedicalRecords = 'request medical records',
}

export enum TaskPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Admin = 'admin',
}

export enum UnreadCountPage {
  Queue = 'queue',
  Mentions = 'mentions',
}

export enum OrderBy {
  Name = 'name',
  Role = 'role',
  Date = 'date',
  UserId = 'userId',
  DateTotal = 'dateTotal',
}

export enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

export interface TaskFilterValues {
  endDate?: string;
  startDate?: string;
  types: Set<TaskType>;
  isCompleted: Set<'true'>;
  statuses: Set<TaskStatus>;
  priorities: Set<TaskPriority>;
  portalType: Set<EntityPortalType>;
  assigneeId?: string | 'unassigned';
}

export type HospitalTaskClient = Pick<
  Client,
  'id' | 'name' | 'email' | 'phone' | 'emails' | 'phones' | 'last_login_phone'
>;

export type HospitalTaskPet = Pick<
  Pet,
  'id' | 'name' | 'breed' | 'gender' | 'species' | 'date_of_birth'
>;

export enum StripeInvoiceStatus {
  Open = 'open',
  Paid = 'paid',
  Void = 'void',
  Draft = 'draft',
  Refunded = 'refunded',
  Uncollectible = 'uncollectible',
  PartiallyRefunded = 'partially_refunded',
}

export interface InvoiceCustomer {
  id: number;
  name: string;
  phone: string;
  email?: string;
  client_id?: number;
  account_id?: number;
  hospital_id?: number;
  stripe_customer_id?: number;
}

export interface Invoice {
  id: number;
  url: string;
  pdf: string;
  petId: number;
  paid: boolean;
  total: number;
  gvFee: number;
  caseId: number;
  priceId: number;
  dueDate: number;
  type: InvoiceType;
  accountId: number;
  stripeFee: number;
  refunded: boolean;
  createdAt: string;
  updatedAt: string;
  hospitalId: number;
  customerId: number;
  attempted: boolean;
  finalizedAt: number;
  description?: string;
  servicePrice: number;
  attemptCount: number;
  cancelReason?: string;
  price?: HospitalPrice;
  refundAmount?: number;
  stripeInvoiceId: string;
  refundedAmount?: string;
  customer: InvoiceCustomer;
  status: StripeInvoiceStatus;
  refundReason?: Nullable<string>;
  stripeChargeId?: Nullable<number>;
}

export interface HospitalTaskCase extends Omit<Case, 'pets' | 'client'> {
  pet?: HospitalTaskPet;
  submitted_at?: string;
  pets?: HospitalTaskPet[];
  client?: HospitalTaskClient;
}

export interface HospitalVoicemail extends Omit<Voicemail, 'pet' | 'client'> {
  pet?: HospitalTaskPet;
  client?: HospitalTaskClient;
}

export enum EntityPortalType {
  PO = 'po',
  Team = 'team',
  Hospital = 'hospital',
}

export interface AppointmentDate {
  id: number;
  date: string;
  createdAt: string;
  createdBy: number;
  appointmentId: number;
  note?: Nullable<string>;
  portalType: EntityPortalType;
  status: AppointmentTaskStatus;
}

export interface HospitalAppointment {
  id: number;
  reason: string;
  taskId: number;
  doctorId?: number;
  createdChatId?: number;
  visitReason?: VisitReason;
  visitReasonDetails?: string[];
  status: AppointmentTaskStatus;
  appointmentDates: AppointmentDate[];
}

export interface HospitalTask extends IdObject<number> {
  type: TaskType;
  class: TaskClass;
  createdAt: string;
  status: TaskStatus;
  description?: string;
  isCompleted: boolean;
  invoices?: Invoice[];
  dueAt: Nullable<string>;
  case?: HospitalTaskCase;
  childTasks: HospitalTask[];
  hasPatientArrived?: boolean;
  client?: HospitalTaskClient;
  parentTasks: HospitalTask[];
  updatedAt: Nullable<string>;
  assignee: Nullable<UserMin>;
  portalType: EntityPortalType;
  voicemail?: HospitalVoicemail;
  hasRegistrationLink?: boolean;
  completedAt: Nullable<string>;
  priority: Nullable<TaskPriority>;
  appointment?: HospitalAppointment;
  pets?: Nullable<HospitalTaskPet[]>;
  hospital: Pick<HospitalType, 'id' | 'name' | 'timezone'>;
}

export enum InternalTaskStatus {
  ToDo = 'to do',
  InProgress = 'in progress',
  Completed = 'completed',
}

export enum InternalTaskPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface InternalTask extends IdObject<number> {
  title: string;
  author: UserMin;
  createdAt: string;
  updatedAt: string;
  resolvedAt: string;
  isResolved: boolean;
  entityStatus: number;
  dueAt: Nullable<string>;
  status: InternalTaskStatus;
  assignee: Nullable<UserMin>;
  description: Nullable<string>;
  priority: InternalTaskPriority;
}

export interface AssignValues {
  selectedTask: HospitalTask['id'];
  selectedAssignee: Nullable<UserMin>;
}

export enum BillStatus {
  Paid = 'paid',
  Missed = 'missed',
}
// replace on real data
export type InvoiceReceiver = Pick<
  HospitalType,
  | 'name'
  | 'phone'
  | 'address'
  | 'zip_code'
  | 'city_name'
  | 'email_consult_notif'
>;
// replace on real data
export interface BillInvoice {
  id: number;
  tax: number;
  dueAt: string;
  total: number;
  amount: number;
  quantity: number;
  subtotal: number;
  createdAt: string;
  status: BillStatus;
  bill_number: string;
  description: string;
  to?: InvoiceReceiver;
  from: InvoiceReceiver;
  total_excl_tax: number;
}

// replace on real data
export enum SubscriptionPlanType {
  Advanced = 'advanced',
  Essential = 'essential',
  Professional = 'professional',
}
// replace on real data
export interface SubscriptionPlan {
  id: number;
  calls?: number;
  name: GVService;
  messages?: number;
  per_user?: number;
  editable?: boolean;
  per_number?: number;
  total_amount: number;
  description?: string;
  fee_percent?: number;
  active_users?: number;
  monthly_plan?: number;
  phone_numbers?: number;
  cents_per_number?: number;
  next_billing_date?: string;
  consultations_fee?: number;
  plan_type?: SubscriptionPlanType;
}
// replace on real data
export interface BillingAccount {
  account_number: string;
  account_holder: string;
}

export enum CallOutcomeClientType {
  New = 'new',
  Current = 'current',
  Wellness = 'wellness',
  Corporate = 'corporate',
}

export enum CommLogTypeNumber {
  Call,
  SMS,
  Chat,
  VirtualExam,
}

export enum CommLogDirectionType {
  Incoming,
  Outgoing,
  Appointment,
}

export enum TemplatesCalloutcomes {
  AppointmentSheduled = 'Appointment scheduled',
  AppointmentRecommended = 'Appointment recommended',
  AppointmentCanceled = 'Appointment canceled',
  ClientDeclineAppointment = 'Client decline appointment',
  RefereER = 'Refere to ER',
  RefersOnCall = 'Refers to on-call',
  RefersPPH = 'Refers to PPH/ASPCA',
  Administrative = 'Administrative',
  TransferBackline = 'Transfer to backline',
}

export enum TemplateMessageType {
  Instance = 'instance',
  Custom = 'custom',
}

export interface SmsTemplate {
  id: number;
  text: string;
  delay: number;
  createdAt: string;
  updatedAt: string;
  hospitalId: number;
  callOutcomeId: number;
  reason: Nullable<string>;
  deletedAt: Nullable<string>;
}

export interface SmsCalloutcomeTypeReasons {
  reasons: Record<string, any>;
}

export interface SmsCalloutcomeType {
  id: number;
  type: string;
  isBeta: boolean;
  details: string;
  displayName: string;
  hospitalSpecific: Nullable<Record<string, SmsCalloutcomeTypeReasons>>;
}

export interface CaseNotification {
  id?: number;
  enabled: boolean;
  hospitalId: number;
  createdAt?: string;
  updatedAt?: string;
  callOutcomeId: number;
  hospitalSpecific: Nullable<Record<string, boolean>>;
}

export type CommonBadgeCount = { total: number };
export type CommonAction<T> = React.Dispatch<React.SetStateAction<T>>;

export interface TransferToBacklineTaskCount extends CommonBadgeCount {
  [TaskType.Case]?: number;
}

export interface EmergencyTaskCount extends CommonBadgeCount {
  [TaskType.Case]?: number;
}

export interface AdministrativeTaskCount extends CommonBadgeCount {
  [TaskType.Case]?: number;
  [TaskType.RxRefill]?: number;
  [TaskType.RequestMedicalRecords]?: number;
}

export interface AppointmentTaskCount extends CommonBadgeCount {
  [TaskType.Case]?: number;
  [TaskType.Scheduled]?: number;
  [TaskType.Recommended]?: number;
  [TaskType.Appointment]?: number;
  [TaskType.VirtualExam]?: number;
}

export interface HospitalTasksCountData extends Record<TaskClass, any> {
  [TaskClass.Emergency]: EmergencyTaskCount;
  [TaskClass.Appointment]: AppointmentTaskCount;
  [TaskClass.Administrative]: AdministrativeTaskCount;
  [TaskClass.TransferToBackline]: TransferToBacklineTaskCount;
}

export interface AppointmentType {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  hospitalId?: number;
  price?: HospitalPrice;
}

export enum DepositType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export enum ClientsGroup {
  NewClients = 'newClient',
  AllClients = 'allClients',
}

export interface AdvancedFee {
  amount?: number;
  percentage?: number;
}

export interface AdvancedFees {
  virtualExam?: AdvancedFee;
}

export interface HospitalPriceDetails {
  id: number;
  serviceId: number;
  gvFee?: Nullable<number>;
  isRequiredDeposit?: boolean;
  advancedFees?: AdvancedFees;
  stripeFee?: Nullable<number>;
  totalPrice?: Nullable<number>;
  paymentTerm?: Nullable<number>;
  servicePrice?: Nullable<number>;
  depositFixed?: Nullable<number>;
  depositType?: Nullable<DepositType>;
  depositPercentage?: Nullable<number>;
  clientsGroup?: Nullable<ClientsGroup>;
  depositPercentageResult?: Nullable<number>;
}

export interface HospitalPrice extends HospitalPriceDetails {
  createdAt: string;
  deletedAt?: string;
  updatedAt?: string;
  hospitalId: number;
  service: AppointmentType;
}

export interface PassedStep extends Step {
  id: string;
  hideStep?: boolean;
  stateCopy?: string;
  fieldsState?: FormikValues;
}

export interface DynamicStepState {
  activeStep?: string;
  passedSteps: PassedStep[];
}

export interface StepSection {
  id: string;
  title?: string;
  steps?: string[];
  initial?: boolean;
}

export interface DynamicFormData {
  sectionsOrder: string[];
  steps: Record<string, Step>;
  sections: Record<string, StepSection>;
}

export enum PayoutStatus {
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
}

export interface PayoutDetails {
  id: number;
  url: string;
  email: string;
  country?: string;
  hospitalId: number;
  companyName: string;
  bankCountry?: string;
  companyPhone: string;
  companyTaxId: string;
  status: PayoutStatus;
  bankCurrency?: string;
  bankAccountNumber: string;
  bankRoutingNumber?: string;
  companyAddressCity?: string;
  companyAddressLine1: string;
  companyAddressLine2?: string;
  companyAddressState?: string;
  bankAccountHolderName: string;
  companyAddressPostalCode?: string;
  bankVerificationDocumentId: string;
}

export interface TwilioPhoneNumber {
  lata: string;
  beta: boolean;
  region: string;
  locality: string;
  latitude: number;
  longitude: number;
  rateCenter: string;
  postalCode: string;
  isoCountry: string;
  phoneNumber: string;
  friendlyName: string;
  addressRequirements: string;
  capabilities: {
    SMS: boolean;
    MMS: boolean;
    voice: boolean;
  };
}

export enum DFWidgetComponentType {
  Widget = 'widget',
  Outcome = 'outcome',
}

export enum DynamicFormListItemStatus {
  New = 'new',
  Active = 'active',
  Inactive = 'inactive',
}

export enum DynamicFlowTypes {
  General = 'general',
  Hospital = 'hospital',
}

export interface DynamicFormListItem {
  id: number;
  name: string;
  created_at: string;
  hospitalId: number;
  created_by: number;
  groupNumber?: number;
  type: DynamicFlowTypes;
  $isStoredLocally?: boolean;
  description?: Nullable<string>;
  status: DynamicFormListItemStatus;
}

export interface PaymentInfo {
  fee: number;
  type: string;
  date: string;
  deposit: number;
  serviceFee: number;
}

export enum InvoiceType {
  Remain = 'remain',
  Deposit = 'deposit',
  Postpaid = 'postpaid',
  Prepayment = 'prepayment',
}

export interface PaymentLink {
  id: number;
  url: string;
  pdf: string;
  petId: number;
  paid: boolean;
  total: number;
  caseId: number;
  priceId: number;
  type: InvoiceType;
  accountId: number;
  refunded: boolean;
  hospitalId: number;
  customerId: number;
  attempted: boolean;
  refundReason: null;
  createdAt?: string;
  updatedAt?: string;
  stripeChargeId: null;
  attemptCount: number;
  finalizedAt?: number;
  stripeInvoiceId: string;
}

export enum TextOutStep {
  Recipient,
  Number,
  TemplateInfo,
}

export interface UploadHospitalLogo {
  id: number;
  url: string;
  created_at: string;
  updated_at: string;
  name: Nullable<string>;
  user_id: Nullable<number>;
  created_by: Nullable<number>;
  updated_by: Nullable<number>;
}

export enum RefundType {
  Full = 'full',
  Partial = 'partial',
}

export enum CaseSubmitRules {
  CreateTask = 'createTask',
  SendEmail = 'sendEmail',
  SendTextOut = 'sendTextOut',
}

export interface SubmitCaseTask
  extends Partial<
    Pick<HospitalTask, 'priority' | 'portalType' | 'description'>
  > {
  assigneeId?: number;
}
export interface SubmitCaseAppointment {
  date: string;
  note?: string;
  reason?: string;
  paymentPriceId?: number;
  createdByChatId?: number;
}

export interface SubmitCaseData {
  task?: SubmitCaseTask;
  rules: CaseSubmitRules[];
  createdByChatId?: number;
  appointment?: SubmitCaseAppointment;
}

export interface LocalUnsavedTemplate {
  chunkName: string;
  chunksCount: number;
}

export interface LocalTemplateChunk {
  id: string;
  chunk: string;
}

export enum WillSeeAcceptance {
  CurrentClients = 'Current clients',
  NonClients = 'Non-clients',
  Strays = 'Strays',
  Wildlife = 'Wildlife',
  UnambeToPay = 'Unable to pay',
}

export interface MatrixAppointmentType {
  id?: number;
  name?: string;
  hospitalId?: number;
}

export interface AppointmentMatrixConfig {
  id?: number;
  price?: boolean;
  doctor?: boolean;
  examRoom?: boolean;
  dayOfWork?: boolean;
  timeBlock?: boolean;
  colorCode?: boolean;
  appointment?: boolean;
  appointmentLength?: boolean;
  specialInstructions?: boolean;
}

export interface SchedulingMatrixDoctor {
  id: number;
  userId?: number;
  dayOfWorks?: number[];
  schedulingMatrixId?: number;
  SchedulingMatrixId?: number;
}

export interface SchedulingDoctor {
  id: number;
  name: string;
  SchedulingMatrixDoctors: SchedulingMatrixDoctor;
}

export interface SchedulingMatrixItem {
  id: number;
  hospitalId?: number;
  price: Nullable<string>;
  examRoom: Nullable<string>;
  appointmentTypeId?: number;
  timeBlock: Nullable<number>;
  colorCode: Nullable<string>;
  doctors: SchedulingDoctor[];
  appointmentLength?: Nullable<number>;
  specialInstructions: Nullable<string>;
  appointmentType: MatrixAppointmentType;
}

export interface GVTalkProfile {
  id: number;
  userId?: number;
  clientId?: number;
  workspaceId?: number;
}

export enum AppointmentTypeTarget {
  GVTalk = 'gvTalk',
  CaseForm = 'caseForm',
  VirtualExam = 'virtualExam',
}

export enum SubscriptionTab {
  Discount = 'discount',
  PackageTemplates = 'packageTemplates',
}

export interface Discount {
  id: number;
  name: string;
  type: DepositType;
  amount?: Nullable<number>;
  percentage?: Nullable<number>;
}

export enum ProfileUserType {
  User = 'user',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Client = 'client',
}

export interface StorageTemplateParams {
  hideLinkageTooltip: boolean;
}

export enum MatrixConfigKey {
  Price = 'price',
  Doctor = 'doctor',
  ExamRoom = 'examRoom',
  ColorCode = 'colorCode',
  TimeBlock = 'timeBlock',
  DaysOfWork = 'dayOfWork',
  Appointment = 'appointment',
  Apptlength = 'appointmentLength',
  SpecialInstructions = 'specialInstructions',
}

export interface Discount {
  id: number;
  name: string;
  amountFixed?: number;
  amountPercentage?: number;
}

export enum PackageTemplateType {
  Flat = 'flat',
  Variable = 'variable',
}

export interface PackageSettings {
  smsPrice?: Nullable<number>;
  callPrice?: Nullable<number>;
  chatPrice?: Nullable<number>;
  monthPrice?: Nullable<number>;
}

export interface PackageTemplate {
  id: number;
  name: string;
  type: PackageTemplateType;
  packageSettings: PackageSettings;
}

export enum TaskAppointmentType {
  Virtual = 'virtual',
  Physical = 'physical',
}

export type PartiallyRequired<T, K extends keyof T> = Partial<Omit<T, K>> &
  Required<Pick<T, K>>;

export interface VideoCallStatus {
  hostOnCall: boolean;
  sessionStarted: boolean;
  sessionFinished: boolean;
}

export type CorporateOptions = Partial<
  Record<CallOutcomeDetailsType, SelectOption[]>
>;

export interface StripeAddress {
  city: Nullable<string>;
  line1: Nullable<string>;
  line2: Nullable<string>;
  state: Nullable<string>;
  country: Nullable<string>;
  postal_code: Nullable<number>;
}

export interface StripeBillingDetails {
  name: Nullable<string>;
  address: StripeAddress;
  email: Nullable<string>;
  phone: Nullable<string>;
}

export interface StripeCardChecks {
  cvc_check: string;
  address_line1_check: Nullable<string>;
  address_postal_code_check: Nullable<string>;
}

export interface StripeCard {
  brand: string;
  last4: string;
  country: string;
  funding: string;
  exp_year: number;
  exp_month: number;
  fingerprint: string;
  display_brand: string;
  checks: StripeCardChecks;
}

export interface StripeCardInfo {
  id: string;
  type: string;
  created: number;
  card: StripeCard;
  customer: string;
  livemode: boolean;
  default_payment_method?: boolean;
  billing_details: StripeBillingDetails;
}

export interface StripeInvoiceSettings {
  footer: Nullable<string>;
  custom_fields: Nullable<string>;
  rendering_options: Nullable<string>;
  default_payment_method: Nullable<string>;
}

export enum SubscriptionCancellationReason {
  CancellationRequested = 'cancellation_requested',
  PaymentDisputed = 'payment_disputed',
  PaymentFailed = 'payment_failed',
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export interface Subscription {
  id: number;
  stripeId: string;
  productId: number;
  createdAt: string;
  updatedAt: string;
  customerId: number;
  stripePriceId: string;
  currentPeriodEnd: string;
  status: SubscriptionStatus;
  cancelAtPeriodEnd: boolean;
  currentPeriodStart: string;
  endedAt?: Nullable<string>;
  cancelAt?: Nullable<string>;
  stripePaymentMethodId: string;
  canceledAt?: Nullable<string>;
  product?: SubscriptionPackage;
  cancellationReason?: Nullable<SubscriptionCancellationReason>;
}

export interface PaymentCustomer {
  id: number;
  clientId: number;
  createdAt: string;
  updatedAt: string;
  name: Nullable<string>;
  phone: Nullable<string>;
  email: Nullable<string>;
  stripeCustomerId: string;
  accountId?: Nullable<string>;
  deletedAt?: Nullable<string>;
  subscriptions: Subscription[];
}

export enum CardBrand {
  Visa = 'visa',
  Discover = 'discover',
  UnionPay = 'unionpay',
  AmericanExpress = 'amex',
  Mastercard = 'mastercard',
}

export enum SubscriptionRecurringInterval {
  Month = 'month',
}

export interface SubscriptionPackage {
  id: number;
  name: string;
  price: number;
  gvFee: number;
  stripeFee: number;
  isActive: boolean;
  hospitalId: number;
  features: string[];
  description: string;
  customerPrice: number;
  hospitalEarning: number;
  recurringInterval: SubscriptionRecurringInterval;
}

export type CardInfo = Pick<
  StripeCard,
  'brand' | 'last4' | 'exp_year' | 'exp_month' | 'display_brand'
>;

export interface SubscriptionState extends Subscription {
  paidBy: StripeCardInfo;
  product: SubscriptionPackage;
}

export interface WhiteboardConfig {
  id: number;
  eta?: boolean;
  age?: boolean;
  date?: boolean;
  time?: boolean;
  breed?: boolean;
  source?: boolean;
  petName?: boolean;
  createdAt: string;
  updatedAt: string;
  payment?: boolean;
  priority?: boolean;
  hospitalId: number;
  assignTo?: boolean;
  followUp?: boolean;
  ownerName?: boolean;
  actionItem?: boolean;
  seenBefore?: boolean;
  phoneNumber?: boolean;
  chiefComplaint?: boolean;
  registrationLink?: boolean;
  appointmentStatus?: boolean;
  patientHasArrived?: boolean;
}

export enum WhiteboardConfigKey {
  Eta = 'eta',
  Date = 'date',
  Time = 'time',
  Source = 'source',
  Priority = 'priority',
  ActionItem = 'actionItem',
  OwnerName = 'ownerName',
  PhoneNumber = 'phoneNumber',
  PetName = 'petName',
  Breed = 'breed',
  Age = 'age',
  ChiefComplaint = 'chiefComplaint',
  SeenBefore = 'seenBefore',
  Payment = 'payment',
  AppointmentStatus = 'appointmentStatus',
  PatientHasArrived = 'patientHasArrived',
  RegistrationLink = 'registrationLink',
  AssignTo = 'assignTo',
  FollowUp = 'followUp',
}

export interface PaymentHospitalSetting {
  gvSubscriptionsFee: number;
}

export interface TransferMergeFormValues {
  search: string;
  summary: string;
  inputPhone: string;
  isTransfer?: boolean;
  sendSummary?: boolean;
  dvmId?: Nullable<string>;
  dvmName?: Nullable<string>;
  selectedPhone?: Nullable<string>;
}

export interface CallHoursStatus {
  title: string;
  type: OpenStatus;
}
