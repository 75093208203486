import { Utils } from '@gv/triage-components';

import { useAppSelector } from 'store';
import { useChatNavigate } from 'hooks';
import { selectAuthForGVTalk } from 'store/slices/auth';

import * as Styles from './styles';
import { ChatOptionProps } from './types';

const { getPlural } = Utils.Helpers;

export const ChatOption = ({ chat, ...props }: ChatOptionProps) => {
  const chatNavigate = useChatNavigate();
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);

  const { id, count, unread } = chat;
  const number = unread || count || 0;
  const message = getPlural(`${unread ? 'new ' : ''}message`, number, {
    rightCount: true,
  });

  return (
    <Styles.ChatCard
      isGV
      key={id}
      onClick={() => chatNavigate(id)}
      rightSlot={
        number ? (
          <Styles.MessagesCount isRed={!!unread}>
            {message}
          </Styles.MessagesCount>
        ) : undefined
      }
      {...Utils.GVTalk.getChatCardProps(chat, gvTalkAuth)}
      {...props}
    />
  );
};
