import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Addendum } from 'types';
import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { RootState } from 'store/store';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArgProperty } from 'utils/query-cache';
import {
  CreateCaseRequest,
  SubmitCaseRequest,
  UpdateCaseRequest,
} from 'api/cases/types';

import { parseTimeFilter } from './helpers';
import {
  Case,
  CaseData,
  CaseRequest,
  CaseResponse,
  AddendumRequest,
  CasePDFResponse,
  AddendumResponse,
  CaseCreateResponse,
  CaseDiscardRequest,
  WrongTriageRequest,
  CaseDetailsResponse,
  GetAllCasesResponse,
  GetLatelySubmittedRequest,
} from './types';

const { Query, Helpers } = Utils;

const casesAdapter = createEntityAdapter<Case, number>({
  selectId: (item) => item.id,
});
const tagType = 'Case';

export const casesApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'casesApi',
  endpoints: (build) => ({
    html: build.query<string, string>({
      query: (id) => ({
        method: 'get',
        url: URL.GET_CASE_HTML.replace(':id', id),
      }),
    }),

    delete: build.mutation<void, number>({
      query: (caseId) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_CASE, { id: String(caseId) }),
      }),
    }),

    discard: build.mutation<void, CaseDiscardRequest>({
      query: (data) => {
        return {
          data,
          method: 'post',
          url: URL.DISCARD_CASE,
        };
      },
    }),

    wrongTriage: build.mutation<void, WrongTriageRequest>({
      query: ({ caseId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.WRONG_TRIAGE, { caseId: String(caseId) }),
      }),
    }),

    create: build.mutation<Case, CreateCaseRequest>({
      invalidatesTags: [tagType],
      transformResponse: (response: CaseCreateResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_CASE,
      }),
    }),

    listLatelySubmitted: build.query<Case[], GetLatelySubmittedRequest>({
      providesTags: (result) => providesList(tagType)(result),
      transformResponse: (response: GetAllCasesResponse) => response.data,
      query: (request) => ({
        method: 'get',
        url: Query.addParamsToUrl(URL.CASES_LATELY_SUBMITTED, request),
      }),
    }),

    submit: build.mutation<Case, SubmitCaseRequest>({
      transformResponse: (response: CaseCreateResponse) => response.data,
      invalidatesTags: (_, __, args) => [{ type: tagType, id: args.caseId }],
      query: ({ data, caseId }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.SUBMIT_CASE, { caseId }),
      }),
    }),

    update: build.mutation<Case, UpdateCaseRequest>({
      transformResponse: (response: CaseCreateResponse) => response.data,
      invalidatesTags: (_, __, args) => [{ type: tagType, id: args.caseId }],
      query: ({ data, caseId }) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_CASE, { caseId }),
      }),
    }),

    createAddendum: build.mutation<Addendum, AddendumRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: AddendumResponse) => {
        return response.data;
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.ADD_CASE_ADDENDA, { id: String(id) }),
      }),
    }),

    pdf: build.query<string, string>({
      transformResponse: (response: CasePDFResponse) => {
        return response.data;
      },
      query: (id) => ({
        method: 'get',
        url: URL.GET_CASE_PDF.replace(':id', id),
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: url } = await queryFulfilled;
          dispatch(
            casesApi.util.updateQueryData('details', id, (draft) => {
              Object.assign(draft, {
                pdf_url: url,
              });
            })
          );
        } catch {}
      },
    }),

    details: build.query<Case, any>({
      providesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: CaseDetailsResponse) => {
        const { addenda, caseForm } = response?.data;
        if (!caseForm) {
          return {} as Case;
        }
        return { ...caseForm, addendums: addenda ?? [] };
      },
      query: ({
        id,
        allowNotFound,
      }: {
        id: string;
        allowNotFound?: boolean;
      }) => ({
        method: 'get',
        url: URL.GET_CASE_DETAILS.replace(':id', id),
        headers: allowNotFound
          ? {
              'allow-not-found': 'true',
            }
          : undefined,
      }),
    }),

    list: build.query<CaseData, CaseRequest>({
      keepUnusedDataFor: 1,
      transformResponse: (response: CaseResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.cases),
      query: ({ search, filters, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);

        const {
          type,
          endDate,
          holdTime,
          startDate,
          totalTime,
          wasAutoSubmitted,
          ...restFilters
        } = filters || {};

        const searchParams = {
          limit,
          search,
          offset,
          filters: JSON.stringify({
            type,
            ...parseTimeFilter('holdTime', holdTime),
            ...parseTimeFilter('totalTime', totalTime),
            wasAutoSubmitted: wasAutoSubmitted
              ? Utils.Helpers.stringToBoolean(wasAutoSubmitted)
              : undefined,
            ...(type !== 'client' &&
              type !== 'pet' && {
                period: {
                  to: dayjs(endDate).endOf('D').format(),
                  from: dayjs(startDate).startOf('D').format(),
                },
              }),
            ...Utils.Object.filterEmptyFields(restFilters),
          }),
        };

        const url = Query.addParamsToUrl(URL.CASES, searchParams);
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const casesSlice = createSlice({
  reducers: {},
  name: 'cases',
  initialState: casesAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      casesApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        casesAdapter.upsertMany(state, payload.cases);
      }
    );

    builder.addMatcher(
      casesApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        casesAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      casesAdapter.removeAll(state);
    });
  },
});

const casesStore = (store: RootState) => store?.cases;

export const selectCaseDetails = (caseId: string | undefined) =>
  createSelector([casesStore], (store) => {
    return caseId ? store?.entities[Number(caseId)] : undefined;
  });

export const {
  usePdfQuery,
  useListQuery,
  useHtmlQuery,
  useLazyPdfQuery,
  useDetailsQuery,
  useLazyListQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
  useSubmitMutation,
  useDiscardMutation,
  useLazyDetailsQuery,
  useWrongTriageMutation,
  useCreateAddendumMutation,
  useListLatelySubmittedQuery,
} = casesApi;

export { tagType as caseTagType };
