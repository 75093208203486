import { useState, useEffect } from 'react';
import {
  List,
  Utils,
  Checkbox,
  Nullable,
  FlexLayout,
  ListHeader,
  ButtonColors,
  FilterButton,
  UnknownObject,
} from '@gv/triage-components';

import { AssignValues } from 'types';
import { useListCheckboxes } from 'hooks';
import { AssignToModal } from 'components';

import { SelectAllSection } from '../select-all-section';

import * as Styles from './styles';
import { TasksListProps } from './types';

export const TasksList = <
  TFilterValues extends Record<string, any>,
  TData = UnknownObject | undefined,
>({
  tab,
  data,
  header,
  titles,
  template,
  onAssign,
  children,
  pageCount,
  isLoading,
  count = 0,
  allPageIds,
  filterValues,
  tabletTitles,
  assignOptions,
  tabletTemplate,
  setIsFilterOpen,
  horizontalScroll,
  searchBarRightSlot,
  assignToMeDisabled,
  assignSelectFieldProps,
  headerCheckboxDisabled = false,
  ...props
}: TasksListProps<TFilterValues, TData>) => {
  const [assignValues, setAssignValues] = useState<Nullable<AssignValues>>();
  const [allSelected, setAllSelected] = useState(false);

  const { onSelect, selectedIds, setSelectedIds } = useListCheckboxes();

  useEffect(() => {
    setSelectedIds(new Set());
    setAllSelected(false);
  }, [data]);

  const filtersApplied = Utils.Helpers.areSomeFiltersApplied(filterValues);
  const selectedIdsCount = selectedIds.size;
  const allItemsOnPageSelected = Boolean(
    pageCount &&
      pageCount > 0 &&
      selectedIds.size > 0 &&
      selectedIds.size === allPageIds?.length
  );

  const childrenRenderProps = {
    onSelect,
    isLoading,
    selectedIds,
    allSelected,
    assignValues,
    setAllSelected,
    setAssignValues,
    selectedIdsCount,
    totalCount: count,
  };

  return (
    <>
      <List
        {...props}
        count={count}
        isLoading={isLoading}
        horizontalScroll={horizontalScroll}
        searchBarRightSlot={
          <FlexLayout gap={24}>
            {searchBarRightSlot}

            <FilterButton
              nativeColorIcon={false}
              applied={filtersApplied}
              colorType={ButtonColors.Secondary}
              onButtonClick={() => setIsFilterOpen(true)}
            />
          </FlexLayout>
        }
        header={
          <>
            <Styles.ListHeaderContainer horizontalScroll={horizontalScroll}>
              {allItemsOnPageSelected && pageCount && count > pageCount && (
                <SelectAllSection
                  tab={tab}
                  totalCount={count}
                  allSelected={allSelected}
                  setAllSelected={setAllSelected}
                  pageCount={allPageIds?.length ?? 0}
                />
              )}

              {header?.(childrenRenderProps)}
            </Styles.ListHeaderContainer>

            <ListHeader
              gap="16px"
              padding="16px"
              template={template}
              tabletTemplate={tabletTemplate}
              titles={[
                <FlexLayout gap={16}>
                  <Checkbox
                    checked={!isLoading && allItemsOnPageSelected}
                    onChange={() =>
                      setSelectedIds(
                        allItemsOnPageSelected ? new Set() : new Set(allPageIds)
                      )
                    }
                    disabled={
                      isLoading ||
                      !allPageIds ||
                      !allPageIds.length ||
                      headerCheckboxDisabled
                    }
                  />
                  {(tabletTitles || titles)[0]}
                </FlexLayout>,
                ...(tabletTitles || titles).slice(1),
              ]}
            />
          </>
        }
      >
        {children(childrenRenderProps)}
      </List>

      <AssignToModal
        onAssign={onAssign}
        assignValues={assignValues}
        open={Boolean(assignValues)}
        assignOptions={assignOptions}
        onClose={() => setAssignValues(null)}
        assignToMeDisabled={assignToMeDisabled}
        assignSelectFieldProps={assignSelectFieldProps}
      />
    </>
  );
};
