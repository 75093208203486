import styled from 'styled-components';

import { Chat } from 'components/chat';

export const Container = styled.div`
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
`;

export const StyledChat = styled(Chat)`
  flex: 1;
  min-height: 0;
`;
