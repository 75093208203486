import { useMemo } from 'react';
import { FieldArray, FormikConfig } from 'formik';
import {
  Row,
  Col,
  useAlert,
  AddButton,
  Responsive,
  InputField,
  FlexLayout,
  ButtonTypes,
  AuthPOLayout,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { selectAuthClient } from 'store/slices/auth';
import { useUpdateCurrentMutation } from 'store/api/clients';
import {
  PetOwnerCarousel,
  RemoveFieldButton,
  PetOwnerFooterButtons,
} from 'components';

import * as Styles from './styles';
import { FormValues, ClientEmailsConfigProps } from './types';
import { getClientPayload, getInitialValues, validationSchema } from './config';

export const ClientEmailsConfig = ({
  side,
  onBack,
  onSend,
  isAppointmentCard,
}: ClientEmailsConfigProps) => {
  const client = useAppSelector(selectAuthClient);

  const { handleError } = useAlert();

  const [updateClient] = useUpdateCurrentMutation();

  const initialValues = useMemo(() => getInitialValues(client), [client]);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = async (
    values,
    { setSubmitting }
  ) => {
    const payload = getClientPayload(values, client);
    if (!payload) {
      setSubmitting(false);
      return;
    }
    try {
      await updateClient(payload).unwrap();
      await onSend();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <AuthPOLayout<FormValues>
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={onSubmit}
      title="Almost there"
      initialValues={initialValues}
      side={side ?? <PetOwnerCarousel />}
      validationSchema={validationSchema}
      isAppointmentCard={isAppointmentCard}
      subtitle="Please provide your email, so we can send you a summary afterward."
      footer={({ values, isSubmitting }) => {
        let isSubmitDisabled = isSubmitting;
        if (!isSubmitDisabled) {
          isSubmitDisabled = !values.emails.filter(Boolean).length;
        }
        return (
          <PetOwnerFooterButtons
            leftText="Back"
            onLeftClick={onBack}
            rightText="Send request"
            isRightDisabled={isSubmitDisabled}
            rightButtonType={ButtonTypes.Submit}
          />
        );
      }}
    >
      {({ values }) => (
        <Styles.Container>
          <FieldArray
            name="emails"
            render={({ push, remove }) => {
              const moreThenOneEmail = values.emails.length > 1;
              return (
                <Row>
                  <Col col={8}>
                    {values.emails.map((_: string, idx: number) => {
                      const isFirstField = idx === 0;

                      return (
                        <Row key={`email_${idx}`}>
                          <Col>
                            <FlexLayout gap={8}>
                              <InputField
                                name={`emails.${idx}`}
                                placeholder={`Email ${idx + 1}`}
                                label={idx === 0 ? 'Email' : undefined}
                              />

                              {moreThenOneEmail && (
                                <div>
                                  {isFirstField && (
                                    <Responsive.Desktop>
                                      <p>&nbsp;</p>
                                    </Responsive.Desktop>
                                  )}

                                  <RemoveFieldButton
                                    onClick={() => remove(idx)}
                                  />
                                </div>
                              )}
                            </FlexLayout>
                          </Col>
                        </Row>
                      );
                    })}

                    <AddButton
                      asRow
                      text="Add new email"
                      onClick={() => push('')}
                    />
                  </Col>
                </Row>
              );
            }}
          />
        </Styles.Container>
      )}
    </AuthPOLayout>
  );
};
