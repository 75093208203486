import styled from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Placeholder = styled(FlexLayout)`
  flex-direction: column;
  gap: 8px;

  svg path {
    fill: ${({ theme }) => theme.config.table.head.title};
  }

  button {
    align-self: start;
    margin-top: 8px;
  }
`;
