import styled from 'styled-components';
import { Styles, FlexLayout, EllipsisText } from '@gv/triage-components';

const { messagesSmallSemibold } = Styles.Font;

export const Separator = styled.div`
  width: 1px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.grey.secondary.standard};
`;

export const UserRow = styled(EllipsisText)`
  color: ${({ theme }) => theme.config.userInfo.text.gvSubtitle};
`;

export const Wrapper = styled(FlexLayout)`
  flex-direction: column;
  min-width: 0;
  margin-bottom: 8px;
`;

export const BadgeWrap = styled.div`
  margin-bottom: 8px;
`;

export const Label = styled(UserRow)`
  ${messagesSmallSemibold};
`;
