import { useField } from 'formik';
import {
  Utils,
  ListCard,
  isMobile,
  ListHeader,
  FlexLayout,
  ButtonColors,
  StandardButton,
  ListWithShowMore,
  ButtonStyleTypes,
} from '@gv/triage-components';

import { TaskDueDate } from 'components/tasks';
import { TasksData } from 'store/api/hospital-tasks';
import { callOutcomeModel } from 'components/cases/form';
import { TaskClass, CallOutcomeType, CallOutcomeAppointment } from 'types';

import * as Styles from './styles';

const template = '32px 1fr 1fr 1fr';
const titles = ['', 'Time', 'Pet', 'Chief complaint'];

export const ClientTasksList = ({ data }: { data?: TasksData }) => {
  const mobile = isMobile();

  const fieldName =
    callOutcomeModel[CallOutcomeType.Appointment][
      CallOutcomeAppointment.Cancelled
    ].parentTaskId;

  const [field, , { setValue }] = useField(fieldName);

  const resetButtonProps = mobile
    ? { colorType: ButtonColors.Secondary }
    : {
        padding: '0',
        transparent: true,
        styleType: ButtonStyleTypes.Ghost,
      };

  return (
    <Styles.TasksList
      count={data?.count ?? 0}
      header={
        <>
          <FlexLayout
            gap={8}
            justifyContent="space-between"
            flexDirection={mobile ? 'column' : 'row'}
          >
            <span className="semibold">
              <span>
                Select an appointment that needs to be canceled by the hospital
              </span>{' '}
              <Styles.OptionalLabel>(Optional)</Styles.OptionalLabel>
            </span>

            <StandardButton
              {...resetButtonProps}
              text="Reset"
              onClick={() => setValue('')}
            />
          </FlexLayout>

          <ListHeader
            titles={titles}
            template={template}
            tabletTitles={titles}
            tabletTemplate={template}
          />
        </>
      }
    >
      {data?.rows.map((task) => {
        const id = String(task.id);

        return (
          <ListCard
            key={task.id}
            isPropagation
            template={template}
            mobileHeaders={titles}
            mobileTemplate="1fr 1fr"
            onClick={() => setValue(id)}
            values={[
              <Styles.StyledRadioButton
                id={id}
                readOnly
                value={id}
                name={fieldName}
                checked={field.value === id}
              />,
              <TaskDueDate data={task} taskClass={TaskClass.Appointment} />,
              Utils.Helpers.textValue(task.case?.pet?.species),
              <ListWithShowMore value={task.case?.chief_complaints} />,
            ]}
          />
        );
      })}
    </Styles.TasksList>
  );
};
