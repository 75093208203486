import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
  cacheListAndByIdArgProperty,
} from 'utils/query-cache';

import {
  Nurse,
  NursesData,
  NurseRequest,
  NursesRequest,
  NursesResponse,
  GetNurseResponse,
  ActivationRequest,
  NurseCreateRequest,
  NurseUpdateRequest,
} from './types';

const nursesAdapter = createEntityAdapter<Nurse, number>({
  selectId: (item) => item.id,
});

const tagType = 'Nurses';

export const nursesApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'nursesApi',
  endpoints: (build) => ({
    delete: build.mutation<string, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_NURSE, { id }),
      }),
    }),

    activation: build.mutation<Nurse, ActivationRequest>({
      invalidatesTags: cacheListAndByIdArgProperty(tagType),
      query: ({ id, isActive }) => ({
        method: 'post',
        data: { isActive },
        url: generateUrl(URL.UPDATE_NURSE_STATUS, { id }),
      }),
    }),

    create: build.mutation<Nurse, NurseCreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: GetNurseResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CREATE_NURSE,
      }),
    }),

    details: build.query<Nurse, NurseRequest>({
      providesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: GetNurseResponse) => response.data,
      query: ({ id }) => {
        return {
          method: 'get',
          url: generateUrl(URL.GET_NURSE_BY_ID, { id }),
        };
      },
    }),

    update: build.mutation<Nurse, NurseUpdateRequest>({
      transformResponse: (response: GetNurseResponse) => response.data,
      invalidatesTags: (result, error, { id }) =>
        cacheByIdArg(tagType)(result, error, id),
      query: ({ id, ...data }) => ({
        data,
        method: 'put',
        url: generateUrl(URL.UPDATE_NURSE, { id }),
      }),
    }),

    list: build.query<NursesData, NursesRequest>({
      providesTags: (result) => providesList(tagType)(result?.rows),
      transformResponse: (response: NursesResponse) => {
        const result = response.data;
        return result;
      },
      query: ({ page, limit = 20, ...request }) => {
        const offset = Utils.Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          ...request,
        };
        const url = Utils.Query.addParamsToUrl(
          URL.GET_NURSE_LIST,
          searchParams
        );
        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const nursesSlice = createSlice({
  reducers: {},
  name: 'nurses',
  initialState: nursesAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      nursesApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        nursesAdapter.setAll(state, payload.rows);
      }
    );

    builder.addMatcher(
      nursesApi.endpoints.details.matchFulfilled,
      (state, { payload }) => {
        nursesAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      nursesApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        nursesAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      nursesApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        nursesAdapter.updateOne(state, {
          changes: payload,
          id: nursesAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(
      nursesApi.endpoints.delete.matchFulfilled,
      (state, { meta }) => {
        nursesAdapter.removeOne(state, meta.arg.originalArgs);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      nursesAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useDetailsQuery,
  useUpdateMutation,
  useDeleteMutation,
  useCreateMutation,
  useActivationMutation,
} = nursesApi;
