import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery, generateAPIPath } from 'store/query';

import {
  SuperAdminSettings,
  CallNotificationsData,
  UpdateSettingsRequest,
  NotifySettingsResponse,
  CreateCallNotification,
  UpdateCallNotifications,
  CallNotificationsListRequest,
  CallNotificationsListResponse,
} from './types';

const tagTypes = ['CallNotifications'];
const settingsTags = ['CallNotificationsSettings'];

export const callNotificationsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'callNotificationsApi',
  tagTypes: [...tagTypes, ...settingsTags],
  endpoints: (build) => ({
    create: build.mutation<void, CreateCallNotification>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ADD_NOTIFYING_PERSON,
      }),
    }),

    updateSettings: build.mutation<void, UpdateSettingsRequest>({
      invalidatesTags: settingsTags,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.UPDATE_ADMIN_PREFERENCES,
      }),
    }),

    delete: build.mutation<void, number>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generateAPIPath(URL.DELETE_NOTIFYING_PERSON, {
          id,
        }),
      }),
    }),

    update: build.mutation<void, UpdateCallNotifications>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'put',
        url: generateAPIPath(URL.UPDATE_NOTIFYING_PERSON, { id }),
      }),
    }),

    settings: build.query<SuperAdminSettings[], void>({
      providesTags: settingsTags,
      transformResponse: (response: NotifySettingsResponse) => response.data,
      query: () => {
        const url = Utils.Query.addParamsToUrl(URL.GET_ADMIN_PREFERENCES);

        return {
          url,
          method: 'get',
        };
      },
    }),

    list: build.query<CallNotificationsData, CallNotificationsListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: CallNotificationsListResponse) =>
        response.data,
      query: ({ search, page = 1, limit = 20 }) => {
        const url = Utils.Query.addParamsToUrl(URL.GET_NOTIFYING_PERSONS, {
          limit,
          search,
          offset: Utils.Helpers.offset(page, limit),
        });

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useSettingsQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useUpdateSettingsMutation,
} = callNotificationsApi;
