import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, BaseResponse } from '@gv/triage-components';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { Pricing, PricingGroup, PricingService } from 'types';
import { cacheByIdArg, providesList, invalidatesList } from 'utils/query-cache';

import {
  ListResponse,
  GroupResponse,
  ServiceResponse,
  ListRequestQuery,
  GroupRequestQuery,
  CreateGroupResponse,
  ServiceRequestQuery,
  GroupRequestMutation,
  ServiceRequestMutation,
  UpdateGroupRequestMutation,
} from './types';

const pricingAdapter = createEntityAdapter<Pricing, string>({
  selectId: (item) => item.id,
});

const priceServicesAdapter = createEntityAdapter<PricingService, number>({
  selectId: (item) => item.id,
});

const tagType = 'Pricing';

export const pricingApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'pricingApi',
  endpoints: (build) => ({
    deleteGroup: build.mutation<string, GroupRequestQuery>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: BaseResponse<string>) => response.data,
      query: ({ groupId, hospitalId }) => ({
        method: 'delete',
        url: `${generateUrl(URL.PRICING, { hospitalId })}/${groupId}`,
      }),
    }),
    getService: build.query<PricingService, ServiceRequestQuery>({
      transformResponse: (response: ServiceResponse) => response.data,
      query: ({ groupId, serviceId, hospitalId }) => ({
        method: 'get',
        url: `${generateUrl(URL.PRICING_SERVICE, {
          groupId,
          hospitalId,
        })}/${serviceId}`,
      }),
    }),
    createGroup: build.mutation<
      PricingGroup,
      Omit<GroupRequestMutation, 'groupId'>
    >({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CreateGroupResponse) => response.data,
      query: ({ hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.PRICING, { hospitalId }),
      }),
    }),
    getGroup: build.query<Pricing, GroupRequestQuery>({
      transformResponse: (response: GroupResponse) => response.data,
      providesTags: (result, error, { groupId }) =>
        cacheByIdArg(tagType)(result, error, groupId),
      query: ({ groupId, hospitalId }) => ({
        method: 'get',
        url: `${generateUrl(URL.PRICING, { hospitalId })}/${groupId}`,
      }),
    }),
    list: build.query<Pricing[], ListRequestQuery>({
      providesTags: providesList(tagType),
      transformResponse: (response: ListResponse) => response.data,
      query: ({ hospitalId, searchString }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.PRICING, { hospitalId }),
          {
            searchString,
          }
        ),
      }),
    }),
    createService: build.mutation<PricingService, ServiceRequestMutation>({
      transformResponse: (response: ServiceResponse) => response.data,
      invalidatesTags: (result, error, { groupId }) =>
        cacheByIdArg(tagType)(result, error, groupId),
      query: ({ groupId, hospitalId, ...data }) => ({
        data,
        method: 'post',
        url: generateUrl(URL.PRICING_SERVICE, {
          groupId,
          hospitalId,
        }),
      }),
    }),
    deleteService: build.mutation<string, ServiceRequestQuery>({
      transformResponse: (response: BaseResponse<string>) => response.data,
      invalidatesTags: (result, error, { groupId }) =>
        cacheByIdArg(tagType)(result, error, groupId),
      query: ({ groupId, serviceId, hospitalId }) => ({
        method: 'delete',
        url: `${generateUrl(URL.PRICING_SERVICE, {
          groupId,
          hospitalId,
        })}/${serviceId}`,
      }),
    }),
    updateGroup: build.mutation<string, UpdateGroupRequestMutation>({
      transformResponse: (response: BaseResponse<string>) => response.data,
      invalidatesTags: (result, error, { groupId }) =>
        cacheByIdArg(tagType)(result, error, groupId),
      query: ({ groupId, hospitalId, ...data }) => ({
        data,
        method: 'patch',
        url: `${generateUrl(URL.PRICING, { hospitalId })}/${groupId}`,
      }),
      async onQueryStarted(
        { groupId, hospitalId, ...data },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          pricingApi.util.updateQueryData(
            'getGroup',
            { groupId, hospitalId },
            (draft) => {
              Object.assign(draft, data);
            }
          )
        );
        try {
          await queryFulfilled;

          dispatch(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            updateGroup({
              id: groupId,
              changes: {
                name: data.name,
              },
            })
          );
        } catch {
          updateResult.undo();
        }
      },
    }),
    updateService: build.mutation<string, ServiceRequestQuery>({
      transformResponse: (response: BaseResponse<string>) => response.data,
      invalidatesTags: (result, error, { groupId }) =>
        cacheByIdArg(tagType)(result, error, groupId),
      query: ({ groupId, serviceId, hospitalId, ...data }) => ({
        method: 'patch',
        data: Utils.Object.replaceFieldsValue(data, {
          value: '',
          replacer: null,
        }),
        url: `${generateUrl(URL.PRICING_SERVICE, {
          groupId,
          hospitalId,
        })}/${serviceId}`,
      }),
      async onQueryStarted(
        { groupId, serviceId, hospitalId, ...data },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          pricingApi.util.updateQueryData(
            'getService',
            { groupId, serviceId, hospitalId },
            (draft) => {
              Object.assign(draft, data);
            }
          )
        );
        try {
          await queryFulfilled;

          dispatch(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            updateService({
              id: serviceId,
              changes: data,
            })
          );
        } catch {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState: pricingAdapter.getInitialState(),
  reducers: {
    updateGroup(state, action) {
      pricingAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pricingApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        pricingAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      pricingApi.endpoints.getGroup.matchFulfilled,
      (state, { payload }) => {
        pricingAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      pricingAdapter.removeAll(state);
    });
  },
});

export const priceServicesSlice = createSlice({
  name: 'priceServices',
  initialState: priceServicesAdapter.getInitialState(),
  reducers: {
    updateService(state, action) {
      priceServicesAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pricingApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        const items = payload.map((group) => group.services).flat();
        priceServicesAdapter.setAll(state, items);
      }
    );
    builder.addMatcher(
      pricingApi.endpoints.getGroup.matchFulfilled,
      (state, { payload }) => {
        priceServicesAdapter.setMany(state, payload.services);
      }
    );
    builder.addMatcher(isLogoutAction, (state) => {
      priceServicesAdapter.removeAll(state);
    });
  },
});

const { updateGroup } = pricingSlice.actions;
const { updateService } = priceServicesSlice.actions;

export const {
  useListQuery,
  useGetGroupQuery,
  useGetServiceQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = pricingApi;
