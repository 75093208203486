import { Config, Routes } from 'config';

import { usePetOwnerHomePath } from './usePetOwnerHomePath';

const { Home } = Routes.Office;

export const useTalkHome = () => {
  const { isPetOwner } = Config.portalType;

  const { homePath } = isPetOwner
    ? usePetOwnerHomePath()
    : { homePath: undefined };

  return homePath || Home;
};
