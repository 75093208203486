import styled, { css } from 'styled-components';
import { List, RadioButton } from '@gv/triage-components';

export const OptionalLabel = styled.span`
  color: ${({ theme }) => theme.config.table.head.title};
`;

export const TasksList = styled(List)`
  ${({ theme }) =>
    theme.responsive.isMobile &&
    css`
      gap: 8px;
    `}
`;

export const StyledRadioButton = styled(RadioButton)`
  display: inline-flex;
`;
