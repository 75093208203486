import { Form, Formik } from 'formik';
import { useMemo, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Utils,
  useAlert,
  FlexLayout,
  SelectField,
  ButtonTypes,
  ButtonColors,
  StandardButton,
  handleMutation,
  DatePickerField,
} from '@gv/triage-components';

import { useAppSelector, useAppDispatch } from 'store';
import { useFiltersOptions } from 'pages/analytics/team/communication-log/use-filters-options';
import {
  ExportFormValues,
  useAnalyticsExportMutation,
} from 'store/api/analytics-export';
import {
  reset,
  setStatus,
  setFormValues,
  AnalyticsExportStatus,
  selectAnalyticsExport,
} from 'store/slices/analytics-export';

import * as Styles from './styles';
import { getInitialValues } from './helpers';
import { ExportCommunicationReportProps } from './types';
import { exportReportCard, validationSchema } from './config';

export const ExportCommunicationReport = ({
  hospital,
  className,
}: ExportCommunicationReportProps) => {
  const dispatch = useAppDispatch();
  const { handleError } = useAlert();
  const { id, name } = hospital ?? {};
  const isFeatureAvailable = !!hospital;

  const {
    file,
    status,
    formValues: hydratedFormValues,
  } = useAppSelector(selectAnalyticsExport);

  const { hospitals, isHospitalsFetching } = useFiltersOptions();

  const selectOptions = useMemo(
    () =>
      isFeatureAvailable
        ? [{ value: String(id), label: String(name) }]
        : hospitals,
    [hospitals, isFeatureAvailable, id, name]
  );

  const setHospitalIfAvailable = () => {
    if (isFeatureAvailable) {
      dispatch(setFormValues({ hospital_id: String(id) }));
    }
  };

  useEffect(() => {
    setHospitalIfAvailable();
  }, [id]);

  const [exportAnalytics, mutation] = useAnalyticsExportMutation();

  const onError = useCallback((errorMessage: any, error: any) => {
    handleError(errorMessage ?? error);
    dispatch(setStatus(AnalyticsExportStatus.Error));
  }, []);

  handleMutation({ ...mutation, onError });

  const onDownload = async () => {
    if (file) {
      const element = document.createElement('a');
      element.href = file;
      element.target = '_blank';
      document.body.appendChild(element);
      element.click();

      await dispatch(reset());
      setHospitalIfAvailable();

      document.body.removeChild(element);
    }
  };

  return (
    <Styles.ExportCard status={status} className={className}>
      {status === AnalyticsExportStatus.Initial ? (
        <>
          <h3>Export communication log</h3>

          <Formik<ExportFormValues>
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={getInitialValues(hydratedFormValues)}
            onSubmit={async (values) => {
              dispatch(setStatus(AnalyticsExportStatus.Processing));
              await exportAnalytics(values);
            }}
          >
            {({ values }) => (
              <Form>
                <Row>
                  <Col col={6}>
                    <SelectField
                      onlyValue
                      name="hospital_id"
                      options={selectOptions}
                      placeholder="Select hospital"
                      isClearable={!isFeatureAvailable}
                      isSearchable={!isFeatureAvailable}
                      isDisabled={isHospitalsFetching || isFeatureAvailable}
                      onChange={(value) => {
                        dispatch(
                          setFormValues({
                            hospital_id:
                              value && Utils.Helpers.isSingleSelectValue(value)
                                ? String(value.id)
                                : '',
                          })
                        );
                      }}
                    />
                  </Col>

                  <Col col={2}>
                    <DatePickerField
                      name="start_date"
                      placeholderText="From"
                      maxDate={values.end_date ?? new Date()}
                      onChange={(value) =>
                        dispatch(
                          setFormValues({
                            start_date: value.toString(),
                          })
                        )
                      }
                    />
                  </Col>

                  <Col col={2}>
                    <DatePickerField
                      name="end_date"
                      placeholderText="To"
                      maxDate={new Date()}
                      minDate={values.start_date}
                      onChange={(value) =>
                        dispatch(
                          setFormValues({
                            end_date: value.toString(),
                          })
                        )
                      }
                    />
                  </Col>

                  <Col col={2}>
                    <StandardButton
                      fullWidth
                      text="Export report"
                      type={ButtonTypes.Submit}
                      disabled={isHospitalsFetching}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <FlexLayout alignItems="center" justifyContent="space-between">
          <FlexLayout gap={16}>
            {exportReportCard[status].icon}

            <div>
              <h3>{exportReportCard[status].title}</h3>
              <p>{exportReportCard[status].subtitle}</p>
            </div>
          </FlexLayout>

          {status === AnalyticsExportStatus.Error && (
            <StandardButton
              text="Restart process"
              onClick={() => dispatch(setStatus(AnalyticsExportStatus.Initial))}
            />
          )}

          {status === AnalyticsExportStatus.Ready && file && (
            <StandardButton
              onClick={onDownload}
              text="Download report"
              colorType={ButtonColors.LightGreen}
            />
          )}
        </FlexLayout>
      )}

      <Styles.ProgressBarContainer>
        <Styles.ProgressBar status={status} />
      </Styles.ProgressBarContainer>
    </Styles.ExportCard>
  );
};
