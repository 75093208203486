import { Utils, Contact } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import {
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import {
  ListRequest,
  ContactsData,
  ListResponse,
  CreateRequest,
  GVTalkProfile,
  DetailsRequest,
  CreateResponse,
  DetailsResponse,
  ContactDetailsData,
} from './types';

const { Query, Helpers } = Utils;

const tagType = 'gvContacts';

const gvContactsAdapter = createEntityAdapter<Contact, number>({
  selectId: (item) => item.id,
});

export const gvContactsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'gvContactsApi',
  endpoints: (build) => ({
    create: build.mutation<GVTalkProfile, CreateRequest>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: CreateResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_GV_TALK_CONTACTS,
      }),
    }),

    details: build.query<ContactDetailsData, DetailsRequest>({
      providesTags: cacheByIdArgProperty(tagType),
      transformResponse: (response: DetailsResponse) => response.data,
      query: ({ id }) => {
        return {
          method: 'get',
          url: generateUrl(URL.GET_GV_TALK_CONTACT_DETAILS, { id }),
        };
      },
    }),

    list: build.query<ContactsData, ListRequest>({
      transformResponse: (response: ListResponse) => response.data,
      providesTags: (result) => providesList(tagType)(result?.rows),
      query: ({ search, page = 1, limit = 20 }) => {
        const offset = Helpers.offset(page, limit);
        const searchParams: Record<string, any> = {
          limit,
          offset,
          search,
        };
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.GET_GV_TALK_CONTACTS, searchParams),
        };
      },
    }),
  }),
});

export const gvContactsSlice = createSlice({
  reducers: {},
  name: 'contacts',
  initialState: gvContactsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      gvContactsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        gvContactsAdapter.addMany(state, payload.rows);
      }
    );
  },
});

export const { useListQuery, useDetailsQuery, useCreateMutation } =
  gvContactsApi;
