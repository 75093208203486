import { useEffect } from 'react';
import {
  Col,
  Row,
  List,
  Utils,
  mapQuery,
  AddButton,
  FlexLayout,
  ListHeader,
  EllipsisText,
  useElementSize,
  ListHeaderWrap,
  useSearchPagination,
} from '@gv/triage-components';

import { useListQuery } from 'store/api/clients';

import * as Styles from './styles';
import { ClientListProps, SelectClientFormValues } from './types';

export * from './config';
export type { SelectClientFormValues };

const { textValue, formatPhone } = Utils.Helpers;

export const ClientList = ({
  formik,
  backTitle,
  hospitalId,
  newClientPath,
  template = '19% 19% 14% 1fr',
}: ClientListProps) => {
  const pageData = useSearchPagination();
  const { errors, values, resetForm, setFieldValue } = formik;

  const [listWrapperRef, { width }] = useElementSize();

  const { currentPage: page, debounceQuery: search } = pageData;

  const { data, ...props } = useListQuery({
    page,
    search,
    hospitalId: String(hospitalId),
  });

  const { selectedClientId } = values;

  useEffect(() => {
    if (
      selectedClientId &&
      !data?.rows.some((client) => client.id === +selectedClientId)
    ) {
      resetForm();
    }
  }, [data, selectedClientId, resetForm]);

  return (
    <List
      {...mapQuery(props)}
      count={data?.count}
      listWrapperRef={listWrapperRef}
      searchContainerStyle={{
        marginTop: 0,
      }}
      topHeader={
        <Styles.ListHeader>
          <Styles.BackButton text={backTitle} />
          <AddButton text="New client" to={newClientPath} />
        </Styles.ListHeader>
      }
      header={
        <ListHeaderWrap>
          {(scrollProps) => (
            <>
              {errors.selectedClientId && (
                <Styles.Error>{errors.selectedClientId as string}</Styles.Error>
              )}

              {width > 800 && (
                <ListHeader
                  template={template}
                  titles={['Client', 'Phone', 'Pet', 'Address']}
                  {...scrollProps}
                />
              )}
            </>
          )}
        </ListHeaderWrap>
      }
      {...pageData}
    >
      {data?.rows.map((client) => {
        const { id, pets, name, phone, address } = client;
        const formattedPhone = formatPhone(phone);

        const commonProps = {
          key: id,
          onClick: () => setFieldValue('selectedClientId', id),
          selected: selectedClientId ? id === selectedClientId : false,
        };

        return width > 800 ? (
          <Styles.ListCard
            gap={24}
            template={template}
            values={[
              <Styles.Name className="semibold">{name}</Styles.Name>,
              formattedPhone,
              pets?.[0]?.name,
              address,
            ]}
            {...commonProps}
          />
        ) : (
          <Styles.Client {...commonProps}>
            <Styles.Name>{name}</Styles.Name>
            <Row>
              <Col col={6}>
                <FlexLayout flexDirection="column">
                  <Styles.Label>Phone</Styles.Label>
                  <EllipsisText>{formattedPhone}</EllipsisText>
                </FlexLayout>
              </Col>
              <Col col={6}>
                <FlexLayout flexDirection="column">
                  <Styles.Label>Pet</Styles.Label>
                  <EllipsisText>{textValue(pets?.[0]?.name)}</EllipsisText>
                </FlexLayout>
              </Col>
            </Row>
            <Row>
              <Col>
                <FlexLayout flexDirection="column">
                  <Styles.Label>Address</Styles.Label>
                  <EllipsisText>{textValue(address)}</EllipsisText>
                </FlexLayout>
              </Col>
            </Row>
          </Styles.Client>
        );
      })}
    </List>
  );
};
