import styled, { css } from 'styled-components';
import { IconListItem } from '@gv/triage-components';

import { InnerBlockProps } from './types';

const flex = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  ${flex};
  padding: 16px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const InnerBlockContainer = styled.div<InnerBlockProps>`
  ${flex};
  ${({ gap, margin }) => css`
    gap: ${gap ?? 16}px;
    ${margin &&
    css`
      margin: ${margin};
    `}
  `};
`;

export const StyledIconListItem = styled(IconListItem)`
  margin: 0;
  padding: 0;
`;

export const Spacing = styled.div`
  height: 24px;
`;
