import * as Yup from 'yup';

import { AppointmentType } from 'types';

export const getInitialValues = (data?: AppointmentType) => ({
  name: data?.name || '',
});

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
});
