import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { isLogoutAction } from 'store/slices/auth';
import { providesList, cacheByIdArg, invalidatesList } from 'utils/query-cache';

import {
  PetGender,
  GendersListResponse,
  CreateGenderPayload,
  UpdateGenderPayload,
  GenderMutationResponse,
} from './types';

const gendersAdapter = createEntityAdapter<PetGender, number>({
  selectId: (item) => item.id,
});

const tagType = 'Genders';

export const gendersApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'gendersApi',
  endpoints: (build) => ({
    delete: build.mutation<PetGender, number>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: generateUrl(URL.DELETE_MASTER_GENDER, { id }),
      }),
    }),

    list: build.query<PetGender[], void>({
      providesTags: (result) => providesList(tagType)(result),
      transformResponse: (response: GendersListResponse) => response.data,
      query: () => ({
        method: 'get',
        url: URL.GET_GENDERS_MASTER_LIST,
      }),
    }),

    create: build.mutation<PetGender, CreateGenderPayload>({
      invalidatesTags: invalidatesList(tagType),
      transformResponse: (response: GenderMutationResponse) => response.data,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.ADD_MASTER_GENDER,
      }),
    }),

    update: build.mutation<PetGender, UpdateGenderPayload>({
      transformResponse: (response: GenderMutationResponse) => response.data,
      invalidatesTags: (result, error, { gender_id }) =>
        cacheByIdArg(tagType)(result, error, gender_id),
      query: (data) => ({
        data,
        method: 'patch',
        url: generateUrl(URL.UPDATE_MASTER_GENDER, { id: data.gender_id }),
      }),
    }),
  }),
});

export const gendersSlice = createSlice({
  reducers: {},
  name: 'genders',
  initialState: gendersAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      gendersApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        gendersAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      gendersApi.endpoints.create.matchFulfilled,
      (state, { payload }) => {
        gendersAdapter.addOne(state, payload);
      }
    );

    builder.addMatcher(
      gendersApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        gendersAdapter.updateOne(state, {
          changes: payload,
          id: gendersAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      gendersAdapter.removeAll(state);
    });
  },
});

export const {
  useListQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
} = gendersApi;
