import { useRef, useEffect, useCallback } from 'react';
import { ParticipantPropertiesPayload } from '@zoom/videosdk';
import { Icon, Utils, useAlert, Messages } from '@gv/triage-components';

import { ZoomClient, Participant } from 'context/video-comm/types';

const { joinStrings } = Utils.Helpers;

export const useParticipantsChange = (
  zoomClient: ZoomClient,
  fn: (participants: Participant[], updatedParticipants?: Participant[]) => void
) => {
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const callback = useCallback(
    (updatedParticipants?: Participant[]) => {
      const participants = zoomClient.getAllUser();
      fnRef.current?.(participants, updatedParticipants);
    },
    [zoomClient]
  );

  const onUserEvent = useCallback(
    (participants: ParticipantPropertiesPayload[], isAdd?: boolean) => {
      const displayNames = participants.map(({ displayName }) => displayName);
      const message = joinStrings(
        [
          joinStrings(displayNames, ', ', ''),
          isAdd ? Messages.JOINED_THE_SESSION : Messages.LEFT_THE_SESSION,
        ],
        ' '
      );
      if (isAdd) {
        showSuccessAlert(message, {
          title: 'User joined',
          icon: <Icon.AddUser />,
        });
      } else {
        showErrorAlert(message, {
          timeout: 3000,
          title: 'User left',
          icon: <Icon.UserBlocked />,
        });
      }
    },
    []
  );

  const onUserAdded = useCallback(
    (participants: ParticipantPropertiesPayload[]) =>
      onUserEvent(participants, true),
    [onUserEvent]
  );

  useEffect(() => {
    zoomClient.on('user-updated', callback);
    zoomClient.on('user-added', onUserAdded);
    zoomClient.on('user-removed', onUserEvent);
    return () => {
      zoomClient.off('user-updated', callback);
      zoomClient.off('user-added', onUserAdded);
      zoomClient.off('user-removed', onUserEvent);
    };
  }, [zoomClient, callback, onUserAdded, onUserEvent]);

  useEffect(() => {
    callback();
  }, []);
};
