import { useRef, useEffect } from 'react';

import { useAppSelector } from 'store';
import { selectTaskByCSID } from 'store/api/action-queue/selectors';

import { TerminationProps } from './types';

export const Termination = ({
  activeCall,
  setActiveCall,
}: TerminationProps) => {
  const callSID = activeCall?.parameters.CallSid;
  const activeCallTask = useAppSelector(selectTaskByCSID(callSID));
  const isTaskConnected =
    activeCallTask &&
    (activeCallTask.isConnectedByMe ||
      activeCallTask.isHolding ||
      activeCallTask.call_transfered);

  const isConnectedRef = useRef(isTaskConnected);

  useEffect(() => {
    if (!isTaskConnected && isConnectedRef.current && activeCall) {
      console.log('server ended this call', callSID);
      activeCall.disconnect();
      setActiveCall(undefined);
    }
    if (isTaskConnected !== isConnectedRef.current) {
      isConnectedRef.current = isTaskConnected;
    }
  }, [activeCall, isTaskConnected]);

  return null;
};
