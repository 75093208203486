import styled from 'styled-components';
import { Icon } from '@gv/triage-components';

export const CallIcon = styled(Icon.CallLost)`
  path {
    fill: ${({ theme }) => theme.colors.red.primary.standard};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    text-align: start;
  }
`;
