import dayjs from 'dayjs';
import { Utils, EmptyPlaceholder } from '@gv/triage-components';

import { MatrixConfigKey, AppointmentMatrixConfig } from 'types';

const { getPlural, joinStrings } = Utils.Helpers;

export const matrixConfigTitle = {
  [MatrixConfigKey.Price]: 'Price',
  [MatrixConfigKey.ExamRoom]: 'Exam room',
  [MatrixConfigKey.ColorCode]: 'Color code',
  [MatrixConfigKey.TimeBlock]: 'Time between',
  [MatrixConfigKey.Apptlength]: 'Appt length',
  [MatrixConfigKey.DaysOfWork]: 'Days of work',
  [MatrixConfigKey.Doctor]: 'Doctors who will see',
  [MatrixConfigKey.Appointment]: 'Appointment type',
  [MatrixConfigKey.SpecialInstructions]: 'Special instructions',
};

export const configItems = [
  MatrixConfigKey.Appointment,
  MatrixConfigKey.Doctor,
  MatrixConfigKey.DaysOfWork,
  MatrixConfigKey.ExamRoom,
  MatrixConfigKey.TimeBlock,
  MatrixConfigKey.Apptlength,
  MatrixConfigKey.ColorCode,
  MatrixConfigKey.SpecialInstructions,
  MatrixConfigKey.Price,
];

export const wiewMatrixConfigTitles = {
  ...matrixConfigTitle,
  [MatrixConfigKey.Appointment]: 'Name',
};

const columnItems = {
  [MatrixConfigKey.Doctor]: {
    valuePath: 'doctors',
    template: 'minmax(224px, 1fr)',
  },
  [MatrixConfigKey.DaysOfWork]: {
    valuePath: 'doctors',
    template: 'minmax(136px, 1fr)',
  },
  [MatrixConfigKey.Price]: {
    template: 'minmax(96px, 1fr)',
    valuePath: MatrixConfigKey.Price,
  },
  [MatrixConfigKey.ExamRoom]: {
    template: 'minmax(96px, 1fr)',
    valuePath: MatrixConfigKey.ExamRoom,
  },
  [MatrixConfigKey.ColorCode]: {
    template: 'minmax(96px, 1fr)',
    valuePath: MatrixConfigKey.ColorCode,
  },
  [MatrixConfigKey.Appointment]: {
    bold: true,
    template: 'minmax(136px, 1fr)',
    valuePath: 'appointmentType.name',
  },
  [MatrixConfigKey.SpecialInstructions]: {
    template: 'minmax(144px, 1fr)',
    valuePath: MatrixConfigKey.SpecialInstructions,
  },
  [MatrixConfigKey.TimeBlock]: {
    timeTitles: true,
    template: 'minmax(96px, 1fr)',
    valuePath: MatrixConfigKey.TimeBlock,
  },
  [MatrixConfigKey.Apptlength]: {
    timeTitles: true,
    template: 'minmax(96px, 1fr)',
    valuePath: MatrixConfigKey.Apptlength,
  },
};

export const getTableProps = ({
  matrixConfig,
}: {
  matrixConfig?: AppointmentMatrixConfig;
}) => {
  const filtered: Array<Record<string, any>> = [];
  const titles: string[] = [];
  let template: string = '';

  configItems.forEach((key) => {
    if (matrixConfig?.[key]) {
      const column = columnItems[key];
      const title = wiewMatrixConfigTitles[key];
      filtered.push({ ...column, key, title });
      titles.push(title);
      template = joinStrings([template, column.template], ' ');
    }
  });

  return { titles, template, filtered };
};

export const weekDays = dayjs.weekdaysShort();

export const getDurationTitle = (minutes?: number) => {
  if (typeof minutes === 'number') {
    if (!minutes) {
      return 'No time';
    }
    const params = { rightCount: true };
    const mins = minutes % 60;
    const hours = Math.floor(minutes / 60);
    return joinStrings(
      [
        hours ? getPlural('hour', hours, params) : undefined,
        mins ? getPlural('min', mins, params) : undefined,
      ],
      ' '
    );
  } else {
    return EmptyPlaceholder;
  }
};
