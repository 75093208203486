import styled, { css } from 'styled-components';
import { ChatCard } from '@gv/triage-components';

import { ChatHeaderProps } from './types';

export const Card = styled(ChatCard)<Pick<ChatHeaderProps, 'isDetails'>>`
  ${({ isDetails }) =>
    isDetails &&
    css`
      padding: 0 0 24px;
      margin: 0 16px;
    `}
`;
