import dayjs from 'dayjs';
import {
  Icon,
  Timer,
  FlexLayout,
  BadgeColor,
  EllipsisText,
} from '@gv/triage-components';

import * as Styles from './styles';
import { SubmitTimeoutBadgeProps } from './types';

/**
 * Displays a countdown timer within a badge to indicate the remaining time until a case must be submitted.
 */
export const SubmitTimeoutBadge = ({
  margin,
  fullWidth,
  disconnectedAt,
  willAutosubmitAt,
}: SubmitTimeoutBadgeProps) => (
  <Styles.Badge
    margin={margin}
    fullWidth={fullWidth}
    icon={<Icon.Clock />}
    styleType={BadgeColor.SecondaryYellow}
    text={
      <FlexLayout gap={8} fullWidth justifyContent="space-between">
        <EllipsisText>Case submission in</EllipsisText>
        <Timer
          started={disconnectedAt}
          finished={willAutosubmitAt}
          format={(durationValue) => {
            return dayjs(
              willAutosubmitAt -
                (durationValue.asMilliseconds() + disconnectedAt)
            ).format('m:ss');
          }}
        >
          {(seconds) => <p className="semibold">{seconds}</p>}
        </Timer>
      </FlexLayout>
    }
  />
);
