import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { api } from 'api';
import { RootState } from 'store/store';

import {
  selectAuthUser,
  // selectPermissions,
  selectPagePermissions,
} from '../auth';

import { BadgesRequest } from './types';
import { setTasks, setInternalTasks } from '.';

export { BadgesRequest };

export const fetchBadges =
  (
    type: BadgesRequest = BadgesRequest.All
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state) {
      return;
    }

    const user = selectAuthUser(state);
    if (!user) {
      return;
    }

    // const permissions = selectPermissions(state);
    const pagePermissions = selectPagePermissions(state);

    const { hospital_id } = user;

    const isAll = type === BadgesRequest.All;

    // TODO: add in future getAllCountsForSidebar(data);

    // if (permissions.voicemail && (isAll || type === BadgesRequest.Voicemails)) {
    //   try {
    //     const response = await api.voicemails.getUnreadCount(hospital_id);
    //     dispatch(setVoicemail(response.data.data.count));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    //
    // if (
    //   permissions.actionItem &&
    //   (isAll || type === BadgesRequest.ActionItems)
    // ) {
    //   try {
    //     const response = await api.actionItems.getUnreadCount(hospital_id);
    //     dispatch(setActionItems(response.data.data));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    // if (
    //   permissions.case &&
    //   permissions.preference &&
    //   (isAll || type === BadgesRequest.OnTheWay)
    // ) {
    //   try {
    //     const response = await api.onTheWay.getUnreadCount();
    //     dispatch(setOnTheWay(response.data.data.onWayBoardCount));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    if (
      hospital_id &&
      pagePermissions.tasks &&
      (isAll || type === BadgesRequest.Tasks)
    ) {
      try {
        const response = await api.hospitalTasks.getHospitalTasksCount({
          hospitalId: hospital_id,
        });
        dispatch(setTasks(response.data.data));
      } catch (error) {
        console.error(error);
      }
    }

    if (
      pagePermissions.internalTasks &&
      (isAll || type === BadgesRequest.InternalTasks)
    ) {
      try {
        const response = await api.internalTasks.getInternalTasksCount();
        dispatch(setInternalTasks(response.data.data));
      } catch (error) {
        console.error(error);
      }
    }
  };
