import { FlexLayout } from '@gv/triage-components';

import * as Styles from './styles';
import { SectionProps } from './types';

export const Section = ({
  title,
  subtitle,
  children,
  isRequired,
  isDividerHidden,
}: SectionProps) => (
  <FlexLayout flexDirection="column">
    <h3>
      {title} {isRequired && <Styles.Required>*</Styles.Required>}
    </h3>
    {subtitle && <p>{subtitle}</p>}

    <Styles.Wrapper>{children}</Styles.Wrapper>

    {!isDividerHidden && <Styles.Divider />}
  </FlexLayout>
);
