import { useMemo, useState } from 'react';
import {
  Info,
  Icon,
  Utils,
  Details,
  BackButton,
  BadgeColor,
  useNavigate,
  GeneralInfo,
  SpeciesList,
  WorkingHours,
  StandardButton,
  ButtonStyleTypes,
  AvatarPlaceholderType,
  CaseFormHospitalReferral,
} from '@gv/triage-components';

import { useAppSelector } from 'store';
import { ERInfoDialog } from 'components';
import { isAlwaysOpen } from 'utils/helpers';
import { useListQuery } from 'store/api/hospital-referrals';
import { useEditPermission, useHospitalParams } from 'hooks';

import { ERProtocolDetailsGlobalProps } from '../types';

import { ERBadges } from './badges';
import { AddressInfo } from './address-info';
// import { ContactInfo } from './contact-info';
// import { ManagerInfo } from './contact-info/types';

const { textValue, formatPhone, joinStrings, transformPhone } = Utils.Helpers;

const ERProtocolDetailsGlobal = ({
  id,
  hospital,
  referalId,
  casesFlow,
  onSendInfo,
  showOpenStatus,
  selectedReferral,
}: ERProtocolDetailsGlobalProps) => {
  const navigate = useNavigate();
  const hasEditPermission = useEditPermission();

  const { error, isFetching, ...props } = useListQuery(id!, { skip: !id });
  const storeReferral = useAppSelector((state) =>
    referalId
      ? state?.hospitalsReferrals.entities[Number(referalId)]
      : undefined
  );

  // TODO:
  // const managers: ManagerInfo[] = [];

  const data = selectedReferral ?? storeReferral;

  const schedule = useMemo(() => {
    if (!data) {
      return undefined;
    }
    if (!data.clinic_hours) {
      return <Info title="Hours of operation" />;
    }
    if (isAlwaysOpen(data.clinic_hours)) {
      return <Info value="24/7 shedule" title="Hours of operation" />;
    }

    return (
      <WorkingHours
        days={data.clinic_hours}
        title="Hours of operation"
        position={['top right', 'bottom right']}
      />
    );
  }, [data?.clinic_hours]);

  return (
    <div>
      {!casesFlow && <BackButton text="Referring hospitals" />}

      <Details {...props} error={error?.message} isLoading={isFetching}>
        {data && (
          <GeneralInfo
            withoutPhoto
            disableSticky
            name={data.name}
            placeholder={AvatarPlaceholderType.Hospital}
            onEdit={
              hasEditPermission && !casesFlow
                ? () => navigate('edit')
                : undefined
            }
            header={
              showOpenStatus && hospital ? (
                <ERBadges referral={data} hospital={hospital} />
              ) : undefined
            }
            rightSlot={
              onSendInfo ? (
                <StandardButton
                  padding="0"
                  transparent
                  icon={<Icon.Chat />}
                  text="Send hospital info"
                  onClick={() => onSendInfo(data)}
                  styleType={ButtonStyleTypes.Ghost}
                />
              ) : undefined
            }
            sections={[
              {
                title: 'General information',
                rows: [
                  [
                    {
                      col: 4,
                      colTablet: 6,
                      children: (
                        <AddressInfo
                          title="Clinic address"
                          distance={
                            'distance' in data ? data.distance : undefined
                          }
                          address={joinStrings(
                            [
                              data.address,
                              data.city_name,
                              data.state?.name,
                              data.zip_code,
                            ],
                            ', '
                          )}
                        />
                      ),
                    },
                    {
                      col: 4,
                      colTablet: 6,
                      children: schedule,
                    },
                  ],
                  [
                    {
                      col: 4,
                      colTablet: 6,
                      title: 'Phone',
                      value: formatPhone(transformPhone(data.phone)),
                    },
                    {
                      col: 4,
                      colTablet: 6,
                      title: 'Emergency fee',
                      value:
                        'fee' in data
                          ? textValue(data.fee, '$_value_')
                          : undefined,
                    },
                  ],
                  [
                    {
                      col: 4,
                      colTablet: 6,
                      noEllipsis: true,
                      children: 'species_array' in data && (
                        <SpeciesList
                          title="Species"
                          list={data.species_array}
                          style={BadgeColor.TetrieryCerulean}
                        />
                      ),
                    },
                  ],
                ],
              },
              // {
              //   title: 'Contact info',
              //   children: <ContactInfo managers={managers} />,
              // },
              {
                title: 'Additional notes',
                children: 'notes' in data ? textValue(data.notes) : undefined,
              },
            ]}
          />
        )}
      </Details>
    </div>
  );
};

const ERProtocolDetails = () => {
  const { id, ref } = useHospitalParams();
  const [referral, setReferral] = useState<
    CaseFormHospitalReferral | undefined
  >();

  return (
    <>
      <ERProtocolDetailsGlobal
        id={id!}
        referalId={ref!}
        onSendInfo={setReferral}
      />
      {referral && (
        <ERInfoDialog
          referral={referral}
          onClose={() => setReferral(undefined)}
        />
      )}
    </>
  );
};

export { ERProtocolDetails, ERProtocolDetailsGlobal };
