import { useState } from 'react';
import { Events } from '@gv/triage-components';

import { Routes } from 'config';

import * as Styles from './styles';
import { CaseEventsProps } from './types';

export const CaseEvents = ({
  logs,
  setSelectedEvent,
}: CaseEventsProps): JSX.Element => {
  const [openedEvents, setOpenedEvents] = useState<number[]>(
    logs.length > 0 ? [logs[0].caseId!] : []
  );

  return (
    <Styles.Content>
      {logs.length > 0 ? (
        <Events
          cases={logs}
          opened={openedEvents}
          setOpened={setOpenedEvents}
          baseCaseLink={Routes.Cases.Home + '/'}
          onClick={(event) => setSelectedEvent(event)}
        />
      ) : (
        'No events yet'
      )}
    </Styles.Content>
  );
};
