import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { acceptanceApi, acceptanceTags } from 'store/api/acceptance';

import { generateItems } from '../helpers';
import {
  EventsResponse,
  DocsHoursRequest,
  DocHourCreateRequest,
  DocHourDeleteRequest,
} from '../types';

import { OnCallHoursResponse } from './types';

const tagTypes = ['OnCallHours'];

export const onCallHoursApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'onCallHoursApi',
  endpoints: (build) => ({
    list: build.query<EventsResponse, DocsHoursRequest>({
      providesTags: tagTypes,
      transformResponse: (response: OnCallHoursResponse) =>
        generateItems(response?.data),
      query: ({ ids, end, start, hospitalId }) => {
        return {
          method: 'get',
          url: Utils.Query.addParamsToUrl(URL.GET_EVENTS_FOR_ON_CALL_CALENDAR, {
            end_date: end,
            start_date: start,
            hospital_id: hospitalId,
            doctor_ids: JSON.stringify(ids),
          }),
        };
      },
    }),

    delete: build.mutation<void, DocHourDeleteRequest>({
      invalidatesTags: tagTypes,
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(acceptanceApi.util.invalidateTags(acceptanceTags));
      },
      query: ({ id, slot, docId, groupId }) => ({
        method: 'delete',
        url: Utils.Query.addParamsToUrl(URL.DELETE_ON_CALL_SCHEDULE, {
          id,
          doctor_id: docId,
          group_id: groupId,
          slot: JSON.stringify(slot),
        }),
      }),
    }),

    create: build.mutation<void, DocHourCreateRequest>({
      invalidatesTags: tagTypes,
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(acceptanceApi.util.invalidateTags(acceptanceTags));
      },
      query: ({
        slot,
        docId,
        every,
        endDate,
        startDate,
        hospitalId,
        clinicDays,
        ...props
      }) => ({
        method: 'post',
        url: URL.ADD_DOCTOR_SCHEDULE,
        data: {
          doctor_id: docId,
          end_date: endDate,
          every: String(every),
          start_date: startDate,
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
          clinic_days: JSON.stringify(clinicDays),
          ...props,
        },
      }),
    }),
  }),
});

export { tagTypes as onCallHoursTags };
