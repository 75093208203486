import { useMemo } from 'react';
import { useParams, useLocation, generatePath } from 'react-router-dom';

import { Config, Routes } from 'config';
import { isNumber } from 'utils/helpers';

export const usePetOwnerHomePath = () => {
  const { hospitalId: paramsHospitalId } = useParams();
  const { pathname } = useLocation();
  const hospitalId = useMemo(() => {
    if (paramsHospitalId) {
      return paramsHospitalId;
    }
    // used for cases when this hook called not in a Route
    const pathParts = pathname.split('/');
    if (pathParts.length > 1 && isNumber(pathParts[1])) {
      return pathParts[1];
    }
    return Config.basePetOwnerPortalHospitalId;
  }, [pathname, paramsHospitalId]);

  const homePath = useMemo(() => {
    if (hospitalId) {
      return generatePath(Routes.PetOwnerDashboard.base, {
        hospitalId,
      });
    } else {
      return undefined;
    }
  }, [hospitalId]);

  return { homePath, hospitalId };
};
