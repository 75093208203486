import styled from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Subtitle = styled.p`
  text-align: center;
`;

export const TitleContainer = styled(FlexLayout)`
  gap: 10px;
  padding: 8px 24px 0;
  align-items: center;
  margin-bottom: 16px;
  flex-direction: column;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
`;
