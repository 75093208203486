import { toast } from 'react-toastify';
import { useState, useCallback } from 'react';
import { Utils, useAlert, showMessage } from '@gv/triage-components';

import { api } from 'api';
import { downloadXLSXRequest } from 'api/analytics/types';

const { joinStrings } = Utils.Helpers;

export const useDownloadXLSX = () => {
  const [isDownloading, setDownloading] = useState(false);
  const { handleError } = useAlert();

  const downloadXLSX = useCallback((props: downloadXLSXRequest) => {
    const { end, start } = props;
    const fileName = `analytics-${joinStrings([start, end], '-')}.xlsx`;

    setDownloading(true);
    const id = showMessage(`Downloading file ${fileName}...`, {
      toast: { autoClose: false },
    });

    api.analytics
      .downloadXLSX(props)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setDownloading(false);
        toast.dismiss(id);
      });
  }, []);

  return { downloadXLSX, isDownloading };
};
