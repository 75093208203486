import { useMemo } from 'react';
import {
  Badge,
  isMobile,
  BadgeColor,
  BadgePadding,
  OpenStatusBadge,
  BadgeBorderRadius,
} from '@gv/triage-components';

import { ServicesLabels } from 'types/data';

import { AcceptanceBadges } from '../acceptance-badges';

import * as Styles from './styles';
import { HospitalHeaderBadgesProps } from './types';

/**
 * This component displays a collection of badges related to the hospital.
 * It includes badges for the services offered by the hospital, the on-call hours status, and the acceptance status.
 */
export const HospitalHeaderBadges = ({
  hospital,
  acceptance,
  onCallHours,
}: HospitalHeaderBadgesProps) => {
  const mobile = isMobile();

  const badges = useMemo(() => {
    if (!hospital && !onCallHours) {
      return [];
    }
    const items = [];

    if (hospital) {
      if (hospital.services && !mobile) {
        items.push(
          ...hospital.services.map((service) => ({
            styleType: BadgeColor.Blue,
            text: ServicesLabels[service],
          }))
        );
      }
    }

    if (onCallHours) {
      items.push({
        text: onCallHours.title,
        styleType: OpenStatusBadge[onCallHours.type],
      });
    }

    return items;
  }, [hospital, onCallHours, mobile]);

  return (
    <Styles.Badges>
      <AcceptanceBadges data={acceptance} />

      {badges.map((badge, index) => (
        <Badge
          key={index}
          padding={BadgePadding.WideMedium}
          borderRadius={BadgeBorderRadius.Small}
          {...badge}
        />
      ))}
    </Styles.Badges>
  );
};
