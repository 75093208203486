import { useMemo } from 'react';
import { useSearch } from '@gv/triage-components';

import { usePaymentHospitalsQuery } from 'store/api/common';
import { useListQuery as useUsersListQuery } from 'store/api/users';

export const useFiltersOptions = () => {
  const answeredBySearch = useSearch('');

  const { data: hospitals, isFetching: isHospitalsFetching } =
    usePaymentHospitalsQuery();

  const { data: usersData, isFetching: isUsersFetching } = useUsersListQuery(
    {
      page: 1,
      search: answeredBySearch.debounceQuery,
    },
    { skip: !answeredBySearch.debounceQuery }
  );

  const answeredByUsersOptions = useMemo(
    () =>
      usersData?.users.map((user) => ({
        label: user.name,
        value: String(user.id),
      })) ?? [],
    [usersData?.users]
  );

  return {
    hospitals,
    isUsersFetching,
    answeredBySearch,
    isHospitalsFetching,
    answeredByUsersOptions,
  };
};
