import styled, { css } from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const ListHeaderContainer = styled(FlexLayout)<{
  horizontalScroll?: boolean;
}>`
  gap: 16px;
  margin: 0 16px 0 16px;
  flex-direction: column;

  ${({ horizontalScroll }) =>
    horizontalScroll &&
    css`
      top: 0;
      left: 16px;
      position: sticky;
    `}
`;
