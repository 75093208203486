import { TriageUser } from '@gv/triage-components';
import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { Config } from 'config';
import { Permission, AuthResponse } from 'types';
import {
  getUser,
  clearAll,
  saveUser,
  getPhone,
  getClient,
  getMatrix,
  savePhone,
  saveMatrix,
  saveClient,
  clearPhone,
  savePortalType,
  getLastPortalType,
  getHospitalSpeciality,
} from 'utils/storage';

import { types, AuthState, AuthClient } from './types';
import { login, logout, loginPetOwner } from './thunks';

const getInitialValue = (): AuthState => {
  let authUser: TriageUser | undefined;
  let authClient: AuthClient | undefined;
  let permission: string | undefined;
  let phone: string | undefined;
  const loadAuth = () => {
    authUser = getUser();
    authClient = getClient();
    permission = getMatrix();
    phone = getPhone();
  };
  if (Config.isNodeProduction) {
    loadAuth();
  } else {
    const lastPortalType = getLastPortalType();
    const { raw: portalType } = Config.portalType;
    if (lastPortalType && lastPortalType !== portalType) {
      clearAll();
    } else {
      loadAuth();
    }
    savePortalType(portalType);
  }

  return {
    user: authUser,
    path: undefined,
    error: undefined,
    client: authClient,
    pauseIdleTimer: false,
    authPhoneNumber: phone,
    nurseStatus: undefined,
    subscriptionPath: undefined,
    speciality: getHospitalSpeciality(),
    permission: permission ? JSON.parse(permission) : {},
    isLoggedIn: authUser !== undefined || authClient !== undefined,
  };
};

const parsePermissions = (data?: AuthResponse): Permission => {
  let permission: Permission = {};
  if (data && data.permissions_v3) {
    permission = data.permissions_v3;
  }

  saveMatrix(JSON.stringify(permission));

  return permission;
};

const reset = (state: AuthState) => {
  state.permission = {};
  state.user = undefined;
  state.client = undefined;
  state.isLoggedIn = false;
  state.pauseIdleTimer = false;
  state.nurseStatus = undefined;
  state.authPhoneNumber = undefined;
  state.subscriptionPath = undefined;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialValue(),
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { data, isLoggedIn } = action.payload ?? {};
      const { user } = data;
      state.user = user;
      state.nurseStatus = undefined;
      state.isLoggedIn = isLoggedIn;
      state.permission = parsePermissions(data);
    });
    builder.addCase(login.rejected, (state) => {
      reset(state);

      clearAll();
    });

    builder.addCase(loginPetOwner.fulfilled, (state, action) => {
      const { client, isLoggedIn } = action.payload ?? {};
      state.isLoggedIn = isLoggedIn;
      state.client = client;
      state.permission = {}; // TODO: maybe set some client permissions
    });

    builder.addCase(loginPetOwner.rejected, (state) => {
      reset(state);
      clearAll();
    });

    builder.addCase(logout.fulfilled, (state) => {
      reset(state);
    });
    builder.addCase(logout.rejected, (state) => {
      reset(state);
    });
  },
  reducers: {
    clearAuth: (state) => {
      reset(state);
    },
    removeAuthPath: (state) => {
      state.path = undefined;
    },
    setIdlePause: (state, { payload }) => {
      state.pauseIdleTimer = payload;
    },
    removeSubscriptionPath: (state) => {
      state.subscriptionPath = undefined;
    },
    setNurseStatus: (state, { payload }) => ({
      ...state,
      nurseStatus: payload,
    }),
    setAuthPath: (state, { payload }) => {
      if (payload.path) {
        state.path = payload.path;
      } else {
        state.path = window.location.pathname + window.location.search;
      }
    },
    updateAuthUser: (state, { payload }) => {
      const updatedUser: TriageUser = { ...state.user, ...payload };

      saveUser(updatedUser);

      return {
        ...state,
        user: updatedUser,
      };
    },
    setAuthPhone: (state, { payload }) => {
      const { phoneNumber } = payload;
      state.authPhoneNumber = phoneNumber;
      if (phoneNumber) {
        savePhone(phoneNumber);
      } else {
        clearPhone();
      }
    },
    updateAuthClient: (state, { payload }) => {
      const updatedClient: AuthClient = { ...state.client, ...payload };

      saveClient(updatedClient);

      return {
        ...state,
        client: updatedClient,
      };
    },
    setSubscriptionPath: (state, { payload }) => {
      if (payload.path) {
        state.subscriptionPath = payload.path;
      } else {
        state.subscriptionPath =
          window.location.pathname + window.location.search;
      }
    },
    setAuthError: (state, { payload }) => {
      const { message, restorePath } = payload || {};
      return {
        ...state,
        error: message,
        path:
          restorePath && message
            ? window.location.pathname + window.location.search
            : undefined,
      };
    },
  },
});

function isLogoutAction(action: AnyAction) {
  return (
    action.type === types.logout + '/fulfilled' ||
    action.type === types.logout + '/rejected'
  );
}

const {
  reducer: authReducer,
  actions: {
    clearAuth,
    setAuthPath,
    setAuthError,
    setIdlePause,
    setAuthPhone,
    removeAuthPath,
    setNurseStatus,
    updateAuthUser,
    updateAuthClient,
    setSubscriptionPath,
    removeSubscriptionPath,
  },
} = authSlice;

export {
  login,
  types,
  logout,
  clearAuth,
  setAuthPath,
  authReducer,
  setAuthError,
  setIdlePause,
  setAuthPhone,
  isLogoutAction,
  removeAuthPath,
  setNurseStatus,
  updateAuthUser,
  updateAuthClient,
  setSubscriptionPath,
  removeSubscriptionPath,
};

export * from './selectors';
