import { Slider, ButtonColors } from '@gv/triage-components';
import { useEffect, useContext, ChangeEventHandler } from 'react';

import { Audio } from './types';
import { AudioContext } from '.';

const Precision = 1_000_000;

const prepareProps = (audio: Audio) => ({
  max: audio!.duration * Precision,
  value: (audio!.currentTime * Precision).toString(),
});

export const ProgressBar = () => {
  const { ref, controls } = useContext(AudioContext);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
    controls?.seek(target.valueAsNumber / Precision);

  useEffect(() => {
    const player = ref?.current;

    const shouldResetPlayer =
      player &&
      player.duration &&
      player.currentTime &&
      player.duration === player.currentTime;

    if (!shouldResetPlayer) {
      return;
    }

    const timer = setTimeout(() => controls?.seek(0), 100);

    return () => clearTimeout(timer);
  }, [ref?.current?.currentTime]);

  return (
    <Slider
      withoutInput
      withoutThumb
      color={ButtonColors.Green}
      onChange={onChangeHandler}
      {...(ref?.current?.duration ? prepareProps(ref.current) : {})}
    />
  );
};
