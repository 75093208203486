import { useContext } from 'react';
import { Header, HeaderContext, CommStatusEnum } from '@gv/triage-components';

import { Config } from 'config';
import { CommContext } from 'context/comm';

import { HeaderWrapProps } from './types';
import { useOnlineBadge, useConverstationBadges } from './hooks';

export const HeaderWrap = ({
  onMenu,
  userInfoProps,
  userInfoTriggerProps,
  ...props
}: HeaderWrapProps): JSX.Element => {
  const { pageState } = useContext(HeaderContext);
  const { retry, status, hasAccess } = useContext(CommContext);

  const conversationBadges = useConverstationBadges();
  const onlineBadges = useOnlineBadge();

  const badges = pageState?.badges?.length
    ? pageState.badges
    : [...conversationBadges, ...onlineBadges];

  return (
    <Header
      {...pageState}
      {...props}
      badges={badges}
      handleBurger={onMenu}
      userInfoProps={userInfoProps}
      version={Config.buildVersion}
      status={hasAccess ? status : undefined}
      userInfoTriggerProps={userInfoTriggerProps}
      onStatusClick={
        hasAccess && status !== CommStatusEnum.Ready ? retry : undefined
      }
    />
  );
};
