import { useCallback } from 'react';
import { FormikValues } from 'formik';
import { useNavigate, handleMutation } from '@gv/triage-components';

import { useHospitalParams } from 'hooks';
import { useCreatePetMutation } from 'store/api/clients';

import { PetForm } from './form';
import { PetFormPage } from './types';
import { initialValues, getCreatePayload } from './config';

const PetNewGlobal = ({ clientId }: PetFormPage): JSX.Element => {
  const navigate = useNavigate();
  const [create, mutation] = useCreatePetMutation();

  const onSuccess = useCallback(() => navigate(-1), []);
  handleMutation({ ...mutation, onSuccess });

  const onSubmit = (values: FormikValues) =>
    create(
      getCreatePayload({
        values,
        clientId,
      })
    );

  return <PetForm onSubmit={onSubmit} initialValues={initialValues} />;
};

const PetNew = (): JSX.Element => {
  const { clientId } = useHospitalParams();
  return <PetNewGlobal clientId={clientId!} />;
};

export { PetNew, PetNewGlobal };
