import {
  Icon,
  Avatar,
  isDesktop,
  FlexLayout,
  ButtonStyleTypes,
  StandardButtonProps,
  AvatarPlaceholderType,
} from '@gv/triage-components';

import { HospitalTask } from 'store/api/hospital-tasks';

import * as Styles from './styles';

export const AssignButton = ({
  assignee,
  disabled,
  ...props
}: StandardButtonProps & Pick<HospitalTask, 'assignee'>) => {
  const desktop = isDesktop();

  if (assignee) {
    const { name, profile_pic } = assignee;

    if (disabled) {
      return (
        <FlexLayout gap={8} alignItems="center">
          {desktop ? (
            <Avatar
              url={profile_pic}
              placeholder={AvatarPlaceholderType.Stethoscope}
            />
          ) : undefined}
          <p className="semibold">{name}</p>
        </FlexLayout>
      );
    }

    return (
      <Styles.Button
        {...props}
        text={name}
        transparent
        padding="0"
        styleType={ButtonStyleTypes.Ghost}
        icon={
          desktop ? (
            <Avatar
              url={profile_pic}
              placeholder={AvatarPlaceholderType.Stethoscope}
            />
          ) : undefined
        }
      />
    );
  }

  if (disabled) {
    return (
      <FlexLayout gap={8} alignItems="center">
        {desktop ? <Styles.UserIcon /> : undefined}
        <p className="semibold">Unassigned</p>
      </FlexLayout>
    );
  }

  return (
    <Styles.Button
      text="Unassigned"
      icon={desktop ? <Icon.User /> : undefined}
      {...props}
    />
  );
};
