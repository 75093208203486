import { Utils, DVMUser } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { URL } from 'api/constants';
import { CalendarDoc } from 'types';
import { apiQuery } from 'store/query';
import { isLogoutAction } from 'store/slices/auth';
import { cacheByIdArg, providesList } from 'utils/query-cache';

import {
  GetDvmRequest,
  GetDvmResponse,
  UpdateDvmRequest,
  ListCalendarRequest,
  ClinicDvmsListRequest,
  OnCallDvmsListRequest,
  ClinicDvmsListResponse,
  GetCalendarDocsResponse,
} from './types';

const {
  Helpers,
  Query: { addParamsToUrl },
} = Utils;

const clinicDvmsAdapter = createEntityAdapter<DVMUser, number>({
  selectId: (item) => item.id,
});

const tagType = 'Dvms';
const calendarTagType = 'DvmsCalendar';

export const clinicDvmsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'clinicDvmsApi',
  tagTypes: [tagType, calendarTagType],
  endpoints: (build) => ({
    update: build.mutation<DVMUser, UpdateDvmRequest>({
      invalidatesTags: (result, error, { doctorId }) =>
        cacheByIdArg(tagType)(result, error, doctorId),
      query: ({ data, doctorId, hospitalId }) => ({
        data,
        method: 'patch',
        url: URL.HOSPITAL_ON_CALL_DOCTOR.replace(
          ':hospitalId',
          hospitalId
        ).replace(':doctorId', doctorId),
      }),
    }),

    listCalendar: build.query<CalendarDoc[], ListCalendarRequest>({
      providesTags: [calendarTagType],
      transformResponse: (response: GetCalendarDocsResponse) => response.data,
      query: ({ hospitalId, isVirtualExam }) => {
        const url = addParamsToUrl(URL.GET_ALL_CALL_CONTACTS, {
          isVirtualExam,
          hospital_id: hospitalId,
        });

        return {
          url,
          method: 'get',
        };
      },
    }),

    get: build.query<DVMUser, GetDvmRequest>({
      transformResponse: (response: GetDvmResponse) => response.data,
      providesTags: (result, error, { doctorId }) =>
        cacheByIdArg(tagType)(result, error, doctorId),
      query: ({ doctorId, hospitalId }) => {
        const url = URL.HOSPITAL_ON_CALL_DOCTOR.replace(
          ':hospitalId',
          hospitalId!
        ).replace(':doctorId', doctorId);

        return {
          url,
          method: 'get',
        };
      },
    }),

    listSchedule: build.query<DVMUser[], OnCallDvmsListRequest>({
      transformResponse: (response: ClinicDvmsListResponse) =>
        [...response.data].sort((doc) =>
          'doctorOnCallSchedule' in doc ? -1 : 1
        ),
      query: ({ all, hospitalId }) => {
        const searchParams = {
          type: all ? 'all' : undefined,
        };
        const url = addParamsToUrl(
          URL.DVM_USERS_ON_CALL_SCHEDULE.replace(':hospitalId', hospitalId!),
          searchParams
        );
        return {
          url,
          method: 'get',
        };
      },
    }),

    list: build.query<DVMUser[], ClinicDvmsListRequest>({
      providesTags: providesList(tagType),
      transformResponse: (response: ClinicDvmsListResponse) => response.data,
      query: ({ search, page = 1, limit = 20, hospitalId, isVirtualExam }) => {
        const offset = Helpers.offset(page, limit);
        const searchParams = {
          limit,
          offset,
          isVirtualExam,
          searchString: search,
        };

        const url = addParamsToUrl(
          URL.DVM_USERS_ON_CALL.replace(':hospitalId', hospitalId!),
          searchParams
        );

        return {
          url,
          method: 'get',
        };
      },
    }),
  }),
});

export const clinicDvmsSlice = createSlice({
  reducers: {},
  name: 'clinicDvms',
  initialState: clinicDvmsAdapter.getInitialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      clinicDvmsApi.endpoints.list.matchFulfilled,
      (state, { payload }) => {
        clinicDvmsAdapter.setAll(state, payload);
      }
    );

    builder.addMatcher(
      clinicDvmsApi.endpoints.get.matchFulfilled,
      (state, { payload }) => {
        clinicDvmsAdapter.setOne(state, payload);
      }
    );

    builder.addMatcher(
      clinicDvmsApi.endpoints.update.matchFulfilled,
      (state, { payload }) => {
        clinicDvmsAdapter.updateOne(state, {
          changes: payload,
          id: clinicDvmsAdapter.selectId(payload),
        });
      }
    );

    builder.addMatcher(isLogoutAction, (state) => {
      clinicDvmsAdapter.removeAll(state);
    });
  },
});

export const {
  useGetQuery,
  useListQuery,
  useUpdateMutation,
  useListScheduleQuery,
  useListCalendarQuery,
} = clinicDvmsApi;
export { tagType as clinicDvmsTag, calendarTagType as dvmCalendarTagType };
