import styled, { css } from 'styled-components';

export const AllItemsSelected = styled.div`
  padding: 8px 16px;
  text-align: center;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    background-color: ${theme.colors.blue.secondary.standard};
  `}
`;
