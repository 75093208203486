import dayjs from 'dayjs';
import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';
import { acceptanceApi, acceptanceTags } from 'store/api/acceptance';

import { generateItems } from '../helpers';
import {
  EventsResponse,
  AppointmentListRequest,
  AppointmentHourCreateRequest,
} from '../types';

import { AppointmentListResponse } from './types';

const tagTypes = ['AppointmentHours'];
export const appointmentHoursApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'appointmentHoursApi',
  endpoints: (build) => ({
    delete: build.mutation<void, any>({
      invalidatesTags: tagTypes,
      query: ({ id, slot, groupId, endDate, startDate, hospitalId }) => ({
        method: 'delete',
        url: Utils.Query.addParamsToUrl(
          generateUrl(URL.DELETE_CALENDAR_HOURS, {
            id,
          }),
          {
            group_id: groupId,
            end_date: endDate,
            start_date: startDate,
            hospital_id: hospitalId,
            slot: JSON.stringify(slot),
          }
        ),
      }),
    }),

    list: build.query<EventsResponse, AppointmentListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: AppointmentListResponse, meta, arg) => {
        const data: any = response?.data || {};
        if (arg.localTimezone) {
          data.timezone = { time_zone_code: dayjs.tz.guess() };
        }
        return generateItems(data, true, arg.end);
      },
      query: ({ ids, end, start, hospitalId, includeDoctorInfo }) => ({
        method: 'get',
        url: Utils.Query.addParamsToUrl(URL.APPOINTMENT_LIST, {
          end_date: end,
          start_date: start,
          includeDoctorInfo,
          hospital_id: hospitalId,
          doctor_ids: JSON.stringify(ids),
        }),
      }),
    }),

    create: build.mutation<void, AppointmentHourCreateRequest>({
      invalidatesTags: tagTypes,
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded;
        dispatch(acceptanceApi.util.invalidateTags(acceptanceTags));
      },
      query: ({
        slot,
        every,
        endDate,
        startDate,
        hospitalId,
        clinicDays,
        ...props
      }) => ({
        method: 'post',
        url: URL.ADD_APPOINTMENT_HOURS,
        data: {
          end_date: endDate,
          every: String(every),
          start_date: startDate,
          hospital_id: String(hospitalId),
          time_slots: JSON.stringify(slot),
          clinic_days: JSON.stringify(clinicDays),
          ...props,
        },
      }),
    }),
  }),
});

export { tagTypes as AppointmentHoursApiTags };
