import { useField } from 'formik';
import { SwitchField } from '@gv/triage-components';

import { InternalReportField } from 'components/internal-report-field';

import * as Styles from './styles';

export const InternalReporting = () => {
  const [showReportingFields] = useField('showInternalReporting');

  return (
    <Styles.Container>
      <SwitchField
        label="Internal reporting"
        name="showInternalReporting"
        labelStyle={{ fontWeight: 700, fontSize: '16px' }}
      />

      {showReportingFields.value && <InternalReportField />}
    </Styles.Container>
  );
};
