import { AblyMessageEvent } from '@gv/triage-components';

import { api } from 'api';
import { createAppAsyncThunk } from 'store/helpers';
import { MessagesListRequest } from 'store/api/gv-messages/types';
import { gvMessagesApi, gvMessagesTags } from 'store/api/gv-messages';
import {
  setLoading,
  addMessage,
  setMessages,
  updateMessage,
  addNewMessages,
  addOldMessages,
  deleteMessages,
  setNewerLoading,
} from 'store/slices/gv-messages';

import { types, HandleEventPayload, SetMessagesThunkProps } from './types';

export const addOldMessagesThunk = createAppAsyncThunk(
  types.addOldMessages,
  async (props: MessagesListRequest, { dispatch }) => {
    const { chatId: id, threadedMessageId } = props;
    const chatId = threadedMessageId
      ? `${id}_${threadedMessageId}`
      : String(id);

    try {
      dispatch(
        setLoading({
          chatId,
          data: true,
        })
      );
      const response = await api.gvMessages.getGvMessages(props);
      const { messages, adjacent } = response.data.data;
      dispatch(
        addOldMessages({
          chatId,
          data: {
            messages,
            ...adjacent,
          },
        })
      );
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );
    } catch (error) {
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );
      return Promise.reject(error);
    }
  }
);

export const addNewMessagesThunk = createAppAsyncThunk(
  types.addNewMessages,
  async (props: MessagesListRequest, { dispatch }) => {
    const { chatId: id, threadedMessageId } = props;
    const chatId = threadedMessageId
      ? `${id}_${threadedMessageId}`
      : String(id);

    try {
      dispatch(
        setNewerLoading({
          chatId,
          data: true,
        })
      );
      const response = await api.gvMessages.getGvMessages(props);
      const { messages, adjacent } = response.data.data;
      dispatch(
        addNewMessages({
          chatId,
          data: {
            messages,
            ...adjacent,
          },
        })
      );
      dispatch(
        setNewerLoading({
          chatId,
          data: false,
        })
      );
    } catch (error) {
      dispatch(
        setNewerLoading({
          chatId,
          data: false,
        })
      );
      return Promise.reject(error);
    }
  }
);

export const setMessagesThunk = createAppAsyncThunk(
  types.setMessages,
  async (props: SetMessagesThunkProps, { dispatch }) => {
    const { chatId: id, scrollToMessage, threadedMessageId } = props;
    const chatId = threadedMessageId
      ? `${id}_${threadedMessageId}`
      : String(id);

    try {
      dispatch(deleteMessages(chatId));
      dispatch(
        setLoading({
          chatId,
          data: true,
        })
      );
      const response = await api.gvMessages.getGvMessages(props);
      const { messages, adjacent } = response.data.data;
      dispatch(
        setMessages({
          chatId,
          data: { messages, ...adjacent },
        })
      );
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );

      let scrollTo = props?.midId || threadedMessageId;

      if (!scrollTo && Array.isArray(messages) && messages.length > 0) {
        scrollTo = messages.at(messages.length - 1)?.id;
      }

      if (scrollTo && scrollToMessage) {
        scrollToMessage(scrollTo, !!threadedMessageId);
      }
    } catch (error) {
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );
      return Promise.reject(error);
    }
  }
);

export const handleAblyMessageEvent = createAppAsyncThunk(
  types.handleAblyMessageEvent,
  async (props: HandleEventPayload, { dispatch }) => {
    const eventNames = Object.values(AblyMessageEvent);
    const { name, data, nextId, threadId } = props;
    const { id, threadedMessageId } = data;

    const chatId = threadId
      ? `${data.chatId}_${threadId}`
      : String(data.chatId);

    if (
      !name ||
      !eventNames.includes(name) ||
      name === AblyMessageEvent.Typing ||
      (threadedMessageId && !threadId) ||
      (threadId && threadId !== threadedMessageId) ||
      (name === AblyMessageEvent.Created && nextId)
    ) {
      return;
    }

    try {
      dispatch(
        setLoading({
          chatId,
          data: true,
        })
      );

      const response = await api.gvMessages.getGvMessage({
        messageId: id,
        chatId: data.chatId,
      });
      const message = response.data.data;

      if (name === AblyMessageEvent.Created) {
        dispatch(addMessage({ chatId, data: message }));
      } else if (
        name === AblyMessageEvent.Updated ||
        name === AblyMessageEvent.Reacted ||
        name === AblyMessageEvent.Read
      ) {
        if (
          name === AblyMessageEvent.Updated &&
          Object.keys(data.messageData).includes('isPinned')
        ) {
          dispatch(gvMessagesApi.util.invalidateTags(gvMessagesTags));
        }

        dispatch(
          updateMessage({
            chatId,
            data: {
              id,
              message,
            },
          })
        );
      }
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );
    } catch (error) {
      dispatch(
        setLoading({
          chatId,
          data: false,
        })
      );
      return Promise.reject(error);
    }
  }
);
