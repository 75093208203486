/**
 * Represents the configuration options for an AWS logger.
 * This type allows for specifying whether the logger is configured or not,
 * and if configured, requires AWS credentials including a key and a secret.
 */
export type AWSLoggerConfig =
  /** Represents an unconfigured state of the logger. */
  | { configured: false }
  /** Represents a fully configured logger with necessary AWS credentials. */
  | { key: string; secret: string; configured: true };

export enum PortalType {
  Team = 'team',
  Hospital = 'hospital',
  PetOwner = 'pet-owner',
}

/**
 * Represents the type constructor that allows a string type `T` to be either itself or prefixed with `DISABLED_` to indicate a disabled state.
 * @template T - The base string type representing the feature.
 */
type WithDisabledPrefix<T extends string> = T | `DISABLED_${T}`;

/**
 * Defines the base set of environment features that can be toggled on or off.
 * These features are used to control various functionalities across the environment.
 */
export type BaseEnvFeature =
  /** Feature to show/hide place back from a case form. */
  | 'PLACE_BACK'
  /** Feature to make required/non-required assessment data. */
  | 'ASSESSMENT'
  /** Feature to show/hide export call logs from hospital analytics. */
  | 'EXPORT_CALL_LOGS'
  /** Feature to show/hide banner when the client don't want triage assistance. */
  | 'TRIAGE_ASSISTANCE'
  /** Feature to show/hide screen with setup of text out templates. */
  | 'TEXT_OUT_TEMPLATES'
  /** Feature for show/hide beta items in the case notifications settings. */
  | 'BETA_CASE_NOTIFICATIONS'
  /** Feature to show/hide ability to select client type in the appointment scheduled Call Outcome. */
  | 'CALL_OUTCOME_CLIENT_TYPE'
  /** Feature to require a client's zip code for case form. */
  | 'REQUIRED_CLIENT_ZIP_CODE'
  /** Feature to show/hide input for reason of appointment decline. */
  | 'APPOINTMENT_DECLINED_REASON'
  /** Feature to show/hide Call Outcome transfer to backline. */
  | 'CALL_OUTCOME_TRANSFER_TO_BACKLINE';

/**
 * Represents the extended set of environment features, including each base feature and its disabled counterpart.
 * This allows for dynamic enabling or disabling of features through configuration.
 */
export type EnvFeature = WithDisabledPrefix<BaseEnvFeature>;
