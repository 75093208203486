import { Utils } from '@gv/triage-components';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { TransferContact } from 'types';

import { GetTransferOptions } from './types';

const { transformPhone } = Utils.Helpers;

export const transferOptionsApi = createApi({
  baseQuery: apiQuery,
  reducerPath: 'transferOptionsApi',
  endpoints: (build) => ({
    list: build.query<TransferContact[], string>({
      query: (id) => ({
        method: 'get',
        url: URL.GET_HOSPITAL_TRANSFER_OPTIONS.replace(':hospitalId', id),
      }),
      transformResponse: (response: GetTransferOptions) =>
        response.data.map((contact) => ({
          ...contact,
          phones: contact.phones.map((phone) => {
            return transformPhone(phone)!;
          }),
        })),
    }),
  }),
});

export const { useListQuery } = transferOptionsApi;
