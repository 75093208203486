import { useAppDispatch } from 'store';
import { updateTask, removeTask } from 'store/api/action-queue';

import { AutosubmitHandlerProps } from './types';
import { useAutosubmitHandler } from './useAutosubmitHandler';

export const AutosubmitHandler = ({ user }: AutosubmitHandlerProps) => {
  const dispatch = useAppDispatch();

  useAutosubmitHandler({
    userId: user?.id,
    onSubmit: ({ caseId }) => dispatch(removeTask({ user, caseId })),
    onStart: ({ caseId, willAutosubmitAt }) =>
      dispatch(updateTask({ caseId, changes: { willAutosubmitAt } })),
    onStop: ({ caseId }) =>
      dispatch(
        updateTask({ caseId, changes: { willAutosubmitAt: undefined } })
      ),
  });

  return <></>;
};
