import dayjs from 'dayjs';
import { Utils, AuthEmail } from '@gv/triage-components';

import { Config } from 'config';
import { generateAPIPath } from 'store/query';

import { URL } from '../constants';
import { apiInstance, apiLoaderInstance } from '../instance';

import {
  Logout,
  AuthGmail,
  VerifyOtp,
  AuthPetOwner,
  ResetPassword,
  ForgotPassword,
  AuthPetOwnerSMS,
  SendVerificationOtp,
} from './types';

export const login = (payload: AuthEmail | AuthGmail) =>
  apiLoaderInstance.post(URL.LOGIN, {
    ...payload,
    portal: Config.portalType.raw,
  });

export const logout = (data: Logout) =>
  apiLoaderInstance.post(URL.LOGOUT, data);

export const forgotPassword = (data: ForgotPassword) => {
  const payload = { ...data, portal: Config.portalType.raw };
  return apiLoaderInstance.post(URL.FORGOT_PASSWORD, payload);
};

export const resetPassword = (data: ResetPassword) =>
  apiLoaderInstance.post(URL.RECOVER_PASSWORD, {
    password: data.password,
    forget_token: data.forgetToken,
    confirm_password: data.passwordRepeat,
  });

export const pushUnsubscribe = (data: PushSubscription) =>
  apiInstance.post(URL.NOTIFICATIONS_UNSUBSCRIBE, data);

export const petOwnerSMSCode = ({ phone, hospitalId }: AuthPetOwnerSMS) =>
  apiInstance.post(
    Utils.Query.addParamsToUrl(
      generateAPIPath(URL.PO_AUTH_SMS_CODE, { hospitalId }),
      { phone }
    )
  );

export const petOwnerLogin = ({ code, phone, hospitalId }: AuthPetOwner) =>
  apiLoaderInstance.post(generateAPIPath(URL.PO_AUTH_LOGIN, { hospitalId }), {
    phone,
    smsCode: code,
    timezone: dayjs.tz.guess(),
  });

export const petOwnerLogout = (data: Logout) =>
  apiLoaderInstance.post(URL.PO_SIGN_OUT, data);

export const sendVerificationOtp = (payload: SendVerificationOtp) =>
  apiLoaderInstance.post(URL.SEND_VERIFICATION_OTP, {
    ...payload,
  });

export const verifyOtp = (payload: VerifyOtp) =>
  apiLoaderInstance.post(URL.VERIFY_OTP, {
    ...payload,
  });
