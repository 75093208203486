import { useMemo } from 'react';
import {
  Menu,
  Utils,
  FlexLayout,
  useNavigate,
  ButtonColors,
  StandardButton,
  OfficeChatPuprose,
} from '@gv/triage-components';

import { useChatParams } from 'hooks';
import { useAppSelector } from 'store';
import { Config, Routes } from 'config';
import { ContactOption } from 'components';
import { selectAuthForGVTalk } from 'store/slices/auth';
import { useDetailsQuery } from 'store/api/gv-contacts';

import * as Styles from './styles';
import { ChatHeaderProps } from './types';

const { Info } = Routes.Office.Inbox.Chat;

const buttonProps = {
  colorType: ButtonColors.Secondary,
};

export const ChatHeader = ({ chat, buttons, isDetails }: ChatHeaderProps) => {
  const navigate = useNavigate();
  const { chatId, contactId } = useChatParams();
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);

  const { data: contact } = useDetailsQuery(
    { id: contactId! },
    { skip: !contactId }
  );

  const parsed = useMemo(() => {
    if (!chat) {
      return undefined;
    }
    const props = Utils.GVTalk.getChatCardProps(chat, gvTalkAuth);
    const { firstMemberName } = props.chat;
    const { purpose } = chat;
    return {
      ...props,
      chat: {
        ...props.chat,
        title: firstMemberName || props.chat.title,
      },
      customSubtitle:
        !firstMemberName && purpose === OfficeChatPuprose.FrontDesk
          ? 'Queue'
          : undefined,
    };
  }, [chat, JSON.stringify(gvTalkAuth)]);

  return (
    <>
      {chatId && chat && parsed && (
        <Styles.Card
          isGV
          large={isDetails}
          isDetails={isDetails}
          onClick={
            isDetails || Config.portalType.isPetOwner
              ? undefined
              : () => navigate(Info)
          }
          rightSlot={
            <FlexLayout gap={16} onClick={(e) => e.stopPropagation()}>
              {buttons?.map((value, idx) =>
                'data' in value ? (
                  <Menu
                    data={value.data!}
                    key={`btn_${idx}`}
                    position={['bottom right']}
                    buttonProps={{ ...buttonProps, ...value }}
                  />
                ) : (
                  <StandardButton
                    key={`btn_${idx}`}
                    {...buttonProps}
                    {...value}
                  />
                )
              )}
            </FlexLayout>
          }
          {...parsed}
        />
      )}

      {contactId && contact && (
        <ContactOption isHeader contact={contact?.profile} />
      )}
    </>
  );
};
