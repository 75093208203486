import { useMemo, useState, useEffect, createContext } from 'react';
import { Icon, LinkProps, ChildrenProps } from '@gv/triage-components';

import { Routes as RoutePaths } from 'config';
import { useHospitalSpeciality } from 'hooks';
import { useDetailsQuery } from 'store/api/hospitals';
import { useAppDispatch, useAppSelector } from 'store';
import { saveHospitalSpeciality } from 'utils/storage';
import { fetchBadges } from 'store/slices/badges/thunks';
import { getHospitalTasksTabsProps } from 'utils/helpers';
import { WalkthroughStep, WalkthroughDataAttribute } from 'types/data';
import {
  selectTasksBadgeCount,
  selectInternalTasksBadgeCount,
} from 'store/slices/badges';
import {
  selectAuthHospital,
  selectAuthHospitalId,
  selectPagePermissions,
} from 'store/slices/auth';

// import {
//   selectOnTheWayBadge,
//   selectVoicemailBadge,
//   selectActionItemsCasesBadge,
// } from 'store/slices/badges';

interface SidebarContextProps {
  isOpened: boolean;
  links: LinkProps[];
  setOpened: (isOpened: boolean) => void;
}

const getInitialState = (): SidebarContextProps => ({
  links: [],
  isOpened: false,
  setOpened: () => {},
});

export const SidebarContext =
  createContext<SidebarContextProps>(getInitialState());

export const SidebarContextProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpened, setOpened] = useState(getInitialState().isOpened);

  useEffect(() => {
    dispatch(fetchBadges());
  }, []);

  const hospitalSpeciality = useHospitalSpeciality();
  const hospital = useAppSelector(selectAuthHospital);
  const hospitalId = useAppSelector(selectAuthHospitalId);
  const permissions = useAppSelector(selectPagePermissions);
  // const onTheWayCount = useAppSelector(selectOnTheWayBadge);
  // const voicemailsCount = useAppSelector(selectVoicemailBadge);
  // const tasksCount = useAppSelector(selectActionItemsCasesBadge);
  const taskCounts = useAppSelector(selectTasksBadgeCount);
  const internalTasksCount = useAppSelector(selectInternalTasksBadgeCount);
  const { totalCount } = getHospitalTasksTabsProps({
    hospital,
    taskCounts,
    hospitalSpeciality,
  });

  useDetailsQuery(String(hospitalId), {
    skip: !hospitalId,
  });
  const speciality = hospital?.speciality;

  useEffect(() => {
    if (speciality) {
      saveHospitalSpeciality(speciality);
    }
  }, [speciality]);

  const links = useMemo(() => {
    const options: LinkProps[] = [];

    if (permissions.dashboard) {
      options.push({
        title: 'Dashboard',
        icon: <Icon.ActionCenter />,
        path: RoutePaths.Dashboard.Home,
      });
    }

    if (permissions.tasks) {
      options.push({
        count: totalCount,
        title: 'Whiteboard',
        icon: <Icon.List />,
        path: RoutePaths.Tasks.Home,
        [WalkthroughDataAttribute]: WalkthroughStep.ActionItems,
      });
    }

    if (permissions.queue) {
      options.push({
        title: 'Queue',
        icon: <Icon.ActionCenter />,
        path: RoutePaths.ActionCenter.Home,
      });
    }

    if (permissions.hospital) {
      options.push({
        title: 'Hospital info',
        icon: <Icon.Hospital />,
        path: RoutePaths.Hospitals.Details.HomeInfo,
        [WalkthroughDataAttribute]: WalkthroughStep.HospitalProfile,
      });
    }

    if (permissions.cases) {
      options.push({
        title: 'Cases',
        icon: <Icon.Case />,
        path: RoutePaths.Cases.Home,
        [WalkthroughDataAttribute]: WalkthroughStep.Cases,
      });
    }

    if (permissions.internalTasks) {
      options.push({
        icon: <Icon.List />,
        count: internalTasksCount,
        title: 'Internal team tasks',
        path: RoutePaths.InternalTeamTasks.Home,
      });
    }

    if (permissions.clients) {
      options.push({
        title: 'Clients',
        icon: <Icon.Pawprint />,
        path: RoutePaths.Clients.Index,
      });
    }

    if (permissions.outbound) {
      options.push({
        title: 'Outbound',
        icon: <Icon.Chat />,
        path: RoutePaths.Outbound.Home,
      });
    }

    if (permissions.hospitals) {
      options.push({
        title: 'Hospitals',
        icon: <Icon.Hospital />,
        path: RoutePaths.Hospitals.Home,
      });
    }

    if (permissions.calendar) {
      options.push({
        title: 'Calendar',
        icon: <Icon.Calendar />,
        path: RoutePaths.Calendar.Home,
        [WalkthroughDataAttribute]: WalkthroughStep.Calendar,
      });
    }

    // if (permissions.phoneLineTesting) {
    //   options.push({
    //     icon: <Icon.CallCompleted />,
    //     title: 'Line testing',
    //     path: RoutePaths.LineTesting.Home,
    //   });
    // }

    if (permissions.team || permissions.nurses) {
      options.push({
        title: 'Team',
        icon: <Icon.Team />,
        path: RoutePaths.Team.Home,
      });
    }

    if (permissions.feedbacks) {
      options.push({
        title: 'Feedback',
        icon: <Icon.StarEmpty />,
        path: RoutePaths.Feedback.Home,
      });
    }

    if (permissions.analytics || permissions.teamAnalytics) {
      options.push({
        title: 'Analytics',
        icon: <Icon.Analytics />,
        path: RoutePaths.Analytics.Home,
        [WalkthroughDataAttribute]: WalkthroughStep.Analytics,
      });
    }

    if (permissions.masterRecords || permissions.dynamicFlowsWrite) {
      options.push({
        title: 'Master Records',
        icon: <Icon.FileFilled />,
        path: RoutePaths.MasterRecords.Home,
      });
    }

    if (permissions.recordings) {
      options.push({
        title: 'Recordings',
        icon: <Icon.FolderVideo />,
        path: RoutePaths.Recordings.Home,
      });
    }

    if (permissions.ringGroups) {
      options.push({
        title: 'Ring groups',
        icon: <Icon.CallIncoming />,
        path: RoutePaths.RingGroups.Home,
      });
    }

    if (permissions.ivr) {
      options.push({
        title: 'IVR',
        icon: <Icon.Dialpad />,
        path: RoutePaths.IVR.Home,
      });
    }

    if (permissions.callNotifications) {
      options.push({
        title: 'Call Notifications',
        icon: <Icon.MailFavourite />,
        path: RoutePaths.CallNotifications.Home,
      });
    }

    // if (permissions.actionItems || permissions.voicemails) {
    //   let count = 0;
    //   if (permissions.actionItems) {
    //     if (
    //       speciality === 'Emergency' &&
    //       permissions.cases &&
    //       permissions.hospital
    //     ) {
    //       count = onTheWayCount;
    //     } else {
    //       count = onTheWayCount + tasksCount;
    //     }
    //   }
    //   if (permissions.voicemails) {
    //     count += voicemailsCount;
    //   }
    //   options.push({
    //     count,
    //     title: 'Action Items',
    //     icon: <Icon.FolderArchive />,
    //     path: RoutePaths.ActionItems.Home,
    //   });
    // }

    if (permissions.office) {
      options.push({
        title: 'GV-Talk',
        icon: <Icon.GVTalk />,
        path: RoutePaths.Office.Home,
      });
    }

    if (permissions.subscriptions) {
      options.push({
        title: 'Subscriptions',
        icon: <Icon.CoinDollarNotFilled />,
        path: RoutePaths.Subscriptions.Home,
      });
    }

    if (permissions.cases) {
      options.push({
        icon: <Icon.Star />,
        title: 'Customer Survey',
        path: RoutePaths.CustomerFeedback.Home,
      });
    }

    if (permissions.help) {
      options.push({
        title: 'Help center',
        icon: <Icon.ShieldQuestion />,
        path: RoutePaths.HelpCenter.Home,
        [WalkthroughDataAttribute]: WalkthroughStep.HelpCenter,
      });
    }

    return options;
  }, [permissions, totalCount, internalTasksCount]);

  const value = {
    links,
    isOpened,
    setOpened,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
