import { isMobile as isMobileHook } from '@gv/triage-components';

import * as Styles from './styles';

interface AllCheckBoxProps {
  checked: boolean;
  onChange: () => void;
}

export const SelectAllCheckbox = ({ checked, onChange }: AllCheckBoxProps) => {
  const isMobile = isMobileHook();
  return (
    <Styles.Checkbox block={isMobile} checked={checked} onChange={onChange} />
  );
};
