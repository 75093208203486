import { Message } from 'ably';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import {
  Icon,
  Utils,
  UserRoleType,
  handleMutation,
  RadioButtonProps,
} from '@gv/triage-components';

import { Config } from 'config';
import { NurseStatus } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { useLazyStatusQuery, useUpdateStatusMutation } from 'store/api/users';
import {
  selectAuthId,
  selectHasRoles,
  setNurseStatus,
  selectNurseStatus,
} from 'store/slices/auth';

import { useAblyEvent } from './';

export const useNurseStatus = () => {
  const dispatch = useAppDispatch();
  const authId = useAppSelector(selectAuthId);
  const nurseStatus = useAppSelector(selectNurseStatus);
  const isTeamCoach = useAppSelector(selectHasRoles([UserRoleType.TeamCoach]));

  const { isTeam } = Config.portalType;

  const [trigger, data] = useLazyStatusQuery();
  const [updateStatus, mutation] = useUpdateStatusMutation();

  const serverCurrentStatus = data.data?.status;

  handleMutation(mutation);

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refreshStatus = useCallback(() => {
    lastRequest.current?.abort();
    const req = trigger();
    lastRequest.current = req;
  }, [trigger]);

  const statusChanged = useCallback(
    (message: Message) => {
      if (message.data.userId === authId) {
        console.log(message);
        refreshStatus();
      }
    },
    [authId, refreshStatus]
  );

  useAblyEvent(['online-status-changed', 'user-status-changed'], statusChanged);

  const changeStatus = useCallback(
    (status: NurseStatus) => {
      console.log('change status to', status);
      dispatch(setNurseStatus(status));
    },
    [dispatch]
  );

  const sendStatusToServer = useCallback(
    (status: NurseStatus) => {
      const update = async () => {
        try {
          await updateStatus(status);
          changeStatus(status);
        } catch (error) {
          console.error(
            "can't update user status",
            Utils.Error.parseAxiosError(error)
          );
        }
      };
      update();
    },
    [changeStatus]
  );

  useEffect(() => {
    if (!isTeam) {
      return;
    }
    refreshStatus();
  }, [isTeam, refreshStatus]);

  useEffect(() => {
    if (
      isTeam &&
      serverCurrentStatus !== nurseStatus &&
      serverCurrentStatus !== NurseStatus.Offline
    ) {
      console.log('status changed to status from server', serverCurrentStatus);
      dispatch(setNurseStatus(serverCurrentStatus));
    }
  }, [isTeam, serverCurrentStatus, nurseStatus]);

  const currentStatus = useMemo(() => {
    switch (nurseStatus) {
      case NurseStatus.Break:
        return NurseStatus.Break;
      case NurseStatus.Coaching:
        return NurseStatus.Coaching;
      default:
        return NurseStatus.Free;
    }
  }, [nurseStatus]);

  const options = useMemo(() => {
    if (isTeam) {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as NurseStatus;
        sendStatusToServer(value);
      };

      const statusOptions: RadioButtonProps[] = [
        {
          label: 'Online',
          icon: <Icon.Monitor />,
          value: NurseStatus.Free,
        },
        {
          label: 'On break',
          icon: <Icon.Cup />,
          value: NurseStatus.Break,
        },
      ];

      if (isTeamCoach) {
        statusOptions.splice(1, 0, {
          label: 'Coaching',
          icon: <Icon.Team />,
          value: NurseStatus.Coaching,
        });
      }

      return statusOptions.map((item) => ({
        ...item,
        onChange,
        checked: item.value === currentStatus,
      }));
    }

    return undefined;
  }, [isTeam, dispatch, isTeamCoach, currentStatus, sendStatusToServer]);

  return { options, nurseStatus };
};
