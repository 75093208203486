import { useEffect, useContext } from 'react';
import { Message, RealtimeChannel } from 'ably';

import { CommContext } from 'context/comm';

export const useAblyEventChannel = (
  event: string | string[] | undefined,
  onMessage: (message: Message) => void,
  ablyChannel?: RealtimeChannel
) => {
  useEffect(() => {
    if (!ablyChannel || !event) {
      return;
    }
    const handler = onMessage;
    if (Array.isArray(event)) {
      ablyChannel.subscribe(event, handler);
      return () => ablyChannel.unsubscribe(event, handler);
    } else {
      ablyChannel.subscribe(event, handler);
      return () => ablyChannel.unsubscribe(event, handler);
    }
  }, [ablyChannel, JSON.stringify(event), onMessage]);
};

export const useAblyEvent = (
  event: string | string[] | undefined,
  onMessage: (message: Message) => void
) => {
  const { ablyChannel } = useContext(CommContext);

  useAblyEventChannel(event, onMessage, ablyChannel);
};
