import styled from 'styled-components';
import { Icon, StandardButton } from '@gv/triage-components';

export const Button = styled(StandardButton)`
  width: fit-content;
`;

export const UserIcon = styled(Icon.User)`
  path {
    fill: ${({ theme }) => theme.config.common.icon};
  }
`;
