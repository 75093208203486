import styled, { css } from 'styled-components';

const baseStyle = css`
  display: none;
  position: absolute;
`;

export const Video = styled.video`
  ${baseStyle}
`;

export const Canvas = styled.canvas`
  ${baseStyle}
`;
