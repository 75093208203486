import { useContext } from 'react';
import { Utils, FlexLayout } from '@gv/triage-components';

import { AudioContext } from '..';

import { InfoProps } from './types';

const Info = ({ label }: InfoProps): JSX.Element | null => {
  const { value } = useContext(AudioContext);

  return (
    <FlexLayout flexDirection="column">
      <span>{value?.file?.name || label}</span>
      {value?.file?.size && (
        <p className="caption-regular">
          {Utils.File.formatBytes(value.file.size, 0)}
        </p>
      )}
    </FlexLayout>
  );
};

export { Info };
