import { WhiteboardConfigKey } from 'types';

import { WhiteboardConfig } from './types';

export const sortWhiteboardConfig = (data: WhiteboardConfig) =>
  Object.keys(WhiteboardConfigKey).reduce((acc, key) => {
    const valueKey =
      WhiteboardConfigKey[key as keyof typeof WhiteboardConfigKey];
    if (valueKey in data) {
      acc[valueKey] = data[valueKey];
    }
    return acc;
  }, {} as WhiteboardConfig);
