import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  Utils,
  Badge,
  Badges,
  RecommendationTitle,
} from '@gv/triage-components';

import { CallOutcomeStatus } from 'types';
import {
  CallOutcomeClientTypeTitle,
  AppointmentDeclinedReasonTitle,
} from 'types/data';

import { CallOutcomeBadgesProps } from './types';

const {
  Date: { formatDate },
} = Utils;

export const CallOutcomeBadges = ({
  data,
  timezone,
  ...props
}: CallOutcomeBadgesProps) => {
  const { doctor, details, hospital, mainHospital } = data;
  const hospitalName = hospital?.name ?? mainHospital?.name;

  const {
    STAT,
    reason,
    accepted,
    outcomes,
    datetime,
    clientType,
    customOutcome,
    recommendation,
    availabilityDays,
    availabilityTime,
  } = details || {};

  const { timeFormat, hospitalDateTime } = useMemo(() => {
    let format = 'LT';
    if (!datetime) {
      return { timeFormat: format };
    }
    let date = dayjs(datetime);
    if (timezone?.time_zone_code) {
      date = date.tz(timezone?.time_zone_code);
      format += ' (z)';
    }
    return { timeFormat: format, hospitalDateTime: date };
  }, [datetime, timezone?.time_zone_code]);

  return (
    <Badges>
      {recommendation && (
        <Badge {...props} text={RecommendationTitle[recommendation]} />
      )}

      {availabilityDays &&
        availabilityDays.map((value) => (
          <Badge key={value} text={value} {...props} />
        ))}

      {clientType &&
        clientType.map((item) => (
          <Badge
            {...props}
            key={item}
            text={CallOutcomeClientTypeTitle[item]}
          />
        ))}

      {availabilityTime &&
        availabilityTime.map((value) => (
          <Badge key={value} text={value} {...props} />
        ))}

      {doctor && <Badge text={doctor.name} {...props} />}

      {hospitalName && <Badge text={hospitalName} {...props} />}

      {typeof accepted === 'boolean' && (
        <Badge
          {...props}
          text={
            accepted ? CallOutcomeStatus.Accepted : CallOutcomeStatus.Declined
          }
        />
      )}

      {hospitalDateTime && (
        <>
          <Badge text={formatDate(hospitalDateTime, timeFormat)} {...props} />

          <Badge text={formatDate(hospitalDateTime, 'll')} {...props} />
        </>
      )}

      {STAT && <Badge text="STAT treatment" {...props} />}

      {outcomes?.map((value) => <Badge key={value} text={value} {...props} />)}

      {customOutcome && <Badge text={customOutcome} {...props} />}

      {!!reason && (
        <Badge
          {...props}
          key={reason}
          text={AppointmentDeclinedReasonTitle[reason]}
        />
      )}
    </Badges>
  );
};
