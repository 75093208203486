import { useMemo } from 'react';
import { mapQueries } from '@gv/triage-components';

import { SchedulingMatrixItem, AppointmentMatrixConfig } from 'types';
import {
  useGetMatricesListQuery,
  useGetMatrixConfigQuery,
} from 'store/api/scheduling-matrix';

type Queries = {
  matricesList?: SchedulingMatrixItem[];
  matrixConfig?: AppointmentMatrixConfig;
};

export const useSchedulingMatrixProps = (hospitalId?: string | number) => {
  const requestArgs = useMemo(
    () => [{ hospitalId: hospitalId! }, { skip: !hospitalId }] as const,
    [hospitalId]
  );

  return mapQueries<Queries>({
    matrixConfig: useGetMatrixConfigQuery(...requestArgs),
    matricesList: useGetMatricesListQuery(...requestArgs),
  });
};
