import { createApi } from '@reduxjs/toolkit/query/react';
import { Utils, OfficeMessage } from '@gv/triage-components';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { generateUrl } from 'utils/helpers';

import {
  ReadFlow,
  ReadRequest,
  ReactRequest,
  ReadResponse,
  ReactResponse,
  ReadUntilData,
  MessagesListData,
  ReadResponseData,
  ReactResponseData,
  ReadUntilResponse,
  DeleteMediaRequest,
  MessagesListRequest,
  CreateMessageRequest,
  DeleteMessageRequest,
  MessagesListResponse,
  UpdateMessageRequest,
  CreateMessageResponse,
  UpdateMessageResponse,
  DeleteMessageResponse,
  MessageDetailsRequest,
  MessageDetailsResponse,
} from './types';

const { Query } = Utils;

const tagTypes = ['GVMessages'];

export { tagTypes as gvMessagesTags };

export const gvMessagesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'gvMessagesApi',
  endpoints: (build) => ({
    createMessage: build.mutation<OfficeMessage, CreateMessageRequest>({
      transformResponse: (response: CreateMessageResponse) => response.data,
      query: (data) => {
        return {
          data,
          method: 'post',
          url: URL.CREATE_GV_TALK_MESSAGE,
        };
      },
    }),

    readMessage: build.mutation<ReadResponseData, ReadRequest>({
      transformResponse: (response: ReadResponse) => response.data,
      query: ({ chatId, messageId }) => {
        return {
          method: 'patch',
          url: Query.addParamsToUrl(
            generateUrl(URL.READ_GV_TALK_MESSAGE, { messageId }),
            { chatId }
          ),
        };
      },
    }),

    readUntilMessage: build.mutation<ReadUntilData, ReadRequest>({
      transformResponse: (response: ReadUntilResponse) => response.data,
      query: ({ chatId, messageId, flow = ReadFlow.Main }) => {
        return {
          method: 'patch',
          data: { flow, messageId },
          url: Query.addParamsToUrl(URL.READ_UNTIL_GV_TALK_MESSAGE, { chatId }),
        };
      },
    }),

    deleteMessage: build.mutation<OfficeMessage, DeleteMessageRequest>({
      transformResponse: (response: DeleteMessageResponse) => response.data,
      query: ({ chatId, messageId }) => {
        return {
          method: 'delete',
          url: Query.addParamsToUrl(
            generateUrl(URL.DELETE_GV_TALK_MESSAGE, { messageId }),
            { chatId }
          ),
        };
      },
    }),

    react: build.mutation<ReactResponseData, ReactRequest>({
      transformResponse: (response: ReactResponse) => response.data,
      query: ({ chatId, messageId, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: Query.addParamsToUrl(
            generateUrl(URL.REACT_GV_TALK_MESSAGE, { messageId }),
            { chatId }
          ),
        };
      },
    }),

    messageDetails: build.query<OfficeMessage, MessageDetailsRequest>({
      transformResponse: (response: MessageDetailsResponse) => response.data,
      query: ({ chatId, messageId }) => {
        return {
          method: 'get',
          url: Query.addParamsToUrl(
            generateUrl(URL.GET_GV_TALK_MESSAGE, { messageId }),
            {
              chatId,
            }
          ),
        };
      },
    }),

    deleteMedia: build.mutation<OfficeMessage, DeleteMediaRequest>({
      transformResponse: (response: DeleteMessageResponse) => response.data,
      query: ({ chatId, mediaId, messageId }) => {
        return {
          method: 'delete',
          url: Query.addParamsToUrl(
            generateUrl(URL.DELETE_GV_TALK_MEDIA, {
              mediaId,
              messageId,
            }),
            { chatId }
          ),
        };
      },
    }),

    updateMessage: build.mutation<OfficeMessage, UpdateMessageRequest>({
      invalidatesTags: tagTypes,
      transformResponse: (response: UpdateMessageResponse) => response.data,
      query: ({ chatId, messageId, ...rest }) => {
        return {
          data: rest,
          method: 'patch',
          url: Query.addParamsToUrl(
            generateUrl(URL.UPDATE_GV_TALK_MESSAGE, { messageId }),
            { chatId }
          ),
        };
      },
    }),

    messagesList: build.query<MessagesListData, MessagesListRequest>({
      providesTags: tagTypes,
      transformResponse: (response: MessagesListResponse) => response.data,
      query: ({
        midId,
        nextId,
        prevId,
        chatId,
        isPinned,
        maxResults,
        threadedMessageId,
      }) => {
        const searchParams: Record<string, any> = {
          midId,
          nextId,
          prevId,
          chatId,
          isPinned,
          maxResults,
          threadedMessageId,
        };
        return {
          method: 'get',
          url: Query.addParamsToUrl(URL.GET_GV_TALK_MESSAGES, searchParams),
        };
      },
    }),
  }),
});

export const {
  useReactMutation,
  useMessagesListQuery,
  useMessageDetailsQuery,
  useDeleteMediaMutation,
  useReadMessageMutation,
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useUpdateMessageMutation,
  useLazyMessageDetailsQuery,
  useReadUntilMessageMutation,
} = gvMessagesApi;
