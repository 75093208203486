import {
  Icon,
  ButtonColors,
  StandardButton,
  isDesktop as isDesktopHook,
} from '@gv/triage-components';

import { RemoveFieldButtonProps } from './types';

export const RemoveFieldButton = ({
  onClick,
}: RemoveFieldButtonProps): JSX.Element => {
  const isDesktop = isDesktopHook();

  return (
    <StandardButton
      onClick={onClick}
      icon={<Icon.Delete />}
      fullWidth={!isDesktop}
      transparent={isDesktop}
      colorType={ButtonColors.LightRed}
      text={isDesktop ? undefined : 'Delete'}
    />
  );
};
