import { useMemo, useEffect, useReducer } from 'react';

import {
  ZoomClient,
  Participant,
  AvatarContextProps,
} from 'context/video-comm/types';

function avatarActionReducer(state: AvatarContextProps, action: any) {
  // Creating a deep copy of state to ensure immutability
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'toggle-local-volume': {
      const { userId } = action.payload;
      const userItem = newState[userId];
      if (userItem !== undefined) {
        userItem.localVolumeAdjust.toggled =
          !userItem.localVolumeAdjust.toggled;
      }
      break;
    }
    case 'toggle-far-end-camera-control': {
      const { userId } = action.payload;
      const userItem = newState[userId];
      if (userItem !== undefined) {
        userItem.farEndCameraControl.toggled =
          !userItem.farEndCameraControl.toggled;
      }
      break;
    }
    case 'update-avatar-action-enable': {
      const { user, currentUserId } = action.payload;
      const { audio, userId, bVideoOn, isSpeakerOnly } = user;
      if (Object.hasOwn(newState, userId)) {
        const element = newState[userId];
        element.localVolumeAdjust.enabled =
          audio === 'computer' && !isSpeakerOnly && currentUserId !== userId;
        element.farEndCameraControl.enabled =
          bVideoOn && currentUserId !== userId;
      } else {
        newState[userId] = {
          farEndCameraControl: {
            toggled: false,
            enabled: bVideoOn && currentUserId !== userId,
          },
          localVolumeAdjust: {
            toggled: false,
            enabled:
              audio === 'computer' &&
              !isSpeakerOnly &&
              currentUserId !== userId,
          },
        };
      }
      break;
    }
    case 'update-local-volume': {
      const { userId, volume } = action.payload;
      const userItem = newState[userId];
      if (userItem !== undefined) {
        userItem.localVolumeAdjust.volume = volume;
      }
      break;
    }
    case 'set-is-controlling-remote-camera': {
      const { payload } = action;
      newState.isControllingRemoteCamera = payload;
      break;
    }
    default:
      return state;
  }
  return newState;
}

export const useAvatarAction = (
  zoomClient: ZoomClient,
  participants: Array<Participant>
) => {
  const [avatarActionState, dispatch] = useReducer(avatarActionReducer, {});

  useEffect(() => {
    const currentUserId = zoomClient.getSessionInfo().userId;
    participants.forEach((user) => {
      dispatch({
        payload: { user, currentUserId },
        type: 'update-avatar-action-enable',
      });
    });
  }, [zoomClient, participants]);

  const value = useMemo(
    () => ({
      dispatch,
      avatarActionState,
    }),
    [avatarActionState, dispatch]
  );
  return value;
};
