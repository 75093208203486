import styled from 'styled-components';
import {
  Icon,
  InfoBordered,
  Col as TCCol,
  CaseStatusType,
} from '@gv/triage-components';

export const Discard = styled(Icon.FileDiscard)`
  & > path {
    fill: ${({ theme }) =>
      theme.config.caseStatus[CaseStatusType.Discarded]} !important;
  }
`;

export const Info = styled(InfoBordered)`
  div:nth-child(2) {
    min-height: 40px;
  }
`;

export const Col = styled(TCCol)<{ withoutMargin?: boolean }>`
  ${({ withoutMargin }) => withoutMargin && 'margin-bottom: 0;'};
`;
