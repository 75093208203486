import { useMemo, useState, useEffect } from 'react';
import { Row, Col, SelectField } from '@gv/triage-components';

import { complaintsOptionsFactory } from 'utils/helpers';
import { useListQuery } from 'store/api/chief-complaints';
import { showMessageType } from 'pages/master-records/chief-complaints/form/types';

export const Complaints = (props: any) => {
  const { dataValues, complaintsValue } = props;
  const { data: chiefComplaints, isFetching: isLoadingComplaints } =
    useListQuery();

  const complaintsOptions = useMemo(complaintsOptionsFactory(chiefComplaints), [
    chiefComplaints,
  ]);

  const [showMessage, setShowMessage] = useState<showMessageType>({
    show: false,
    message: '',
  });

  const handleSelect = async (selectedName: string) => {
    const selectedItem = await dataValues?.rows?.find(
      (item: any) => item.name === selectedName
    );
    if (selectedItem && selectedItem?.ChiefComplaintsCategory) {
      setShowMessage({
        show: true,
        message: selectedItem.ChiefComplaintsCategory.name,
      });
    } else {
      setShowMessage({
        show: false,
        message: '',
      });
    }
  };

  useEffect(() => {
    if (
      complaintsValue !== undefined &&
      dataValues &&
      complaintsValue &&
      complaintsValue.length > 0
    ) {
      handleSelect(complaintsValue[0]);
    }
  }, [complaintsValue]);

  return (
    <Row>
      <Col>
        <SelectField
          isMulti
          onlyValue
          isClearable
          isAutocomplete
          name="chief_complaints"
          label="Chief complaint"
          requiredText="(Required)"
          placeholder="Start typing"
          options={complaintsOptions}
          isDisabled={isLoadingComplaints}
        />
        {showMessage.show && (
          <label style={{ fontSize: '0.88rem' }}>{showMessage.message}</label>
        )}
      </Col>
    </Row>
  );
};
