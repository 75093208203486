import { Message } from 'ably';
import debounce from 'lodash.debounce';
import { useRef, useMemo, useEffect, useCallback } from 'react';
import { AblyChannelName, AblyMessageEvent } from '@gv/triage-components';

import { useAppSelector } from 'store/hooks';
import { selectAuthUser } from 'store/slices/auth';
import { useLazyMessageDetailsQuery } from 'store/api/gv-messages';
import { MessageDetailsRequest } from 'store/api/gv-messages/types';
import { useOnceLoader, useAblyChannel, useAblyEventChannel } from 'hooks';

export const useMessageDetailsEvent = (
  props: MessageDetailsRequest,
  skip?: boolean
) => {
  const { chatId, messageId } = props || {};

  const authUser = useAppSelector(selectAuthUser);
  const [trigger, data] = useLazyMessageDetailsQuery();

  const { isError, isFetching, isUninitialized } = data;
  const { isLoading } = useOnceLoader({
    skip,
    isError,
    isFetching,
    isUninitialized,
    resetTrigger: messageId,
  });

  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);

  const refetch = useCallback(() => {
    if (!skip) {
      lastRequest.current?.abort();
      const req = trigger(props);
      lastRequest.current = req;
    }
  }, [props, skip]);

  useEffect(refetch, [JSON.stringify(props), skip]);

  const debounceRefetch = useMemo(() => debounce(refetch, 1500), [refetch]);

  useEffect(() => debounceRefetch.cancel, [debounceRefetch]);

  const onEvent = useCallback(
    (msg: Message) => {
      const { id, threadedMessageId, chatId: eventChatId } = msg.data;
      if (
        eventChatId === Number(chatId) &&
        (id === Number(messageId) || threadedMessageId === Number(messageId))
      ) {
        debounceRefetch();
      }
    },
    [debounceRefetch, chatId, authUser?.id, messageId]
  );

  const channel = useAblyChannel(AblyChannelName.Chat);
  useAblyEventChannel(Object.values(AblyMessageEvent), onEvent, channel);

  return { ...data, refetch, isFetching: isLoading };
};
