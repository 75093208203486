import { useContext } from 'react';
import {
  Icon,
  Dialog,
  TextColor,
  ButtonColors,
  StandardButton,
  ButtonStyleTypes,
} from '@gv/triage-components';

import { CallContext } from '../context';

import { SubmitCaseProps } from './types';

export const SubmitCaseDialog = ({
  isCall,
  setOpen,
  onSubmit,
  ...props
}: SubmitCaseProps) => {
  const { form, isCallTransferred } = useContext(CallContext);

  return (
    <Dialog
      title="Submit case"
      closeOnEscape={false}
      icon={<Icon.Completed />}
      iconColor={TextColor.Green}
      closeOnDocumentClick={false}
      text={
        <>
          Are you sure you want to submit case with{' '}
          <span className="semibold">{form.values.client_name}</span>?
        </>
      }
      buttons={(close) => (
        <>
          {isCallTransferred ? (
            <StandardButton
              fullWidth
              text="Submit case"
              colorType={ButtonColors.Secondary}
              onClick={() => {
                close();
                setOpen(false);
                onSubmit(false);
              }}
            />
          ) : (
            <StandardButton
              fullWidth
              text={`Submit case and end ${isCall ? 'call' : 'chat'}`}
              onClick={async () => {
                close();
                setOpen(false);
                onSubmit(true);
              }}
            />
          )}

          <StandardButton
            fullWidth
            text="Cancel"
            styleType={ButtonStyleTypes.Outline}
            onClick={() => {
              close();
              setOpen(false);
            }}
          />
        </>
      )}
      {...props}
    />
  );
};
