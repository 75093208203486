import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockBillingApiQuery } from 'store/mockQuery';
import {
  BillInvoice,
  BillingAccount,
  AppointmentType,
  SubscriptionPlan,
} from 'types';

import { ListRequestQuery } from './types';

export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: mockBillingApiQuery,
  endpoints: (build) => ({
    accountDetails: build.query<BillingAccount, void>({
      query: () => ({
        method: 'get',
        url: URL.BILLING_ACCOUNT_DETAILS,
      }),
    }),

    appointmentTypesList: build.query<AppointmentType[], void>({
      query: () => ({
        method: 'get',
        url: URL.GET_APPOINTMENT_TYPES_LIST,
      }),
    }),

    activeSubscriptionsList: build.query<SubscriptionPlan[], void>({
      query: () => ({
        method: 'get',
        url: URL.ACTIVE_SUBSCRIPTIONS_LIST,
      }),
    }),

    list: build.query<BillInvoice[], ListRequestQuery>({
      query: (data) => ({
        data,
        method: 'get',
        url: URL.HOSPITAL_BILLING_HISTORY,
      }),
    }),

    availableSubscriptionsList: build.query<SubscriptionPlan[], void>({
      query: () => ({
        method: 'get',
        url: URL.AVAILABLE_SUBSCRIPTIONS_LIST,
      }),
    }),
  }),
});

export const {
  useListQuery,
  useAccountDetailsQuery,
  useAppointmentTypesListQuery,
  useActiveSubscriptionsListQuery,
  useAvailableSubscriptionsListQuery,
} = billingApi;
