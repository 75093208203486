import {
  Dialog,
  isMobile,
  ButtonColors,
  StandardButtonWithRef,
} from '@gv/triage-components';

import { UnsubscribeDialogProps } from './types';

export const UnsubscribeDialog = ({
  onConfirm,
  expiration,
  isPetOwner,
  isDisabled,
}: UnsubscribeDialogProps) => {
  const mobile = isMobile();
  const title = 'Cancel subscription';
  return (
    <Dialog
      title={title}
      onConfirm={onConfirm}
      trigger={
        <StandardButtonWithRef
          text={title}
          fullWidth={mobile}
          disabled={isDisabled}
          colorType={ButtonColors.LightRed}
        />
      }
      text={
        <>
          <p>
            By canceling the subscription
            {isPetOwner ? '' : ' for the current user'},
            {isPetOwner ? ' your' : ' their'} subscription will be terminated at{' '}
            {expiration ? (
              <span className="semibold">{expiration}</span>
            ) : (
              'the end of the current billing period'
            )}
            . {isPetOwner ? 'You' : 'They'} can continue to use the subscription
            benefits until the end of the billing cycle.
          </p>
          <br />
          <p>Do you wish to proceed with the cancellation?</p>
        </>
      }
    />
  );
};
