import { Utils } from '@gv/triage-components';
import { FormikValues, useFormikContext } from 'formik';

import { TaskClass } from 'types';
import { useAppSelector } from 'store';
import { useFormEntities } from 'components/cases/form';
import { TasksData, useListQuery } from 'store/api/hospital-tasks';
import { selectIsTaskSwitching } from 'store/api/action-queue/selectors';

const { getValueByPath } = Utils.Object;

const dynamicFlowMockData = {
  count: 1,
  rows: [
    {
      id: 1482,
      dueAt: '2023-11-22T11:00:00.000Z',
      case: {
        chief_complaints: ['Abtest'],
        pet: { species: 'Bovine (Cow)' },
      },
    },
  ],
} as TasksData;

export const useClientTasksProps = (isTesting?: boolean) => {
  const { values } = useFormikContext<FormikValues>();
  const { clientId, hospitalId } = useFormEntities();

  const isTaskSwitching = useAppSelector(selectIsTaskSwitching);
  const corporatePath =
    'callOutcome.appointment.cancelled.corporate_hospital_id';
  const selectedHospitalId =
    getValueByPath(values, corporatePath) ?? hospitalId;

  const { data } = useListQuery(
    {
      page: 1,
      limit: 100,
      filters: {
        clientId,
        classes: [TaskClass.Appointment],
        hospitalId: Number(selectedHospitalId),
      },
    },
    { skip: isTaskSwitching || !selectedHospitalId || !clientId || isTesting }
  );

  return {
    data: isTesting ? dynamicFlowMockData : data,
  };
};
