import { Styles } from '@gv/triage-components';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  padding: 8px;
  margin-bottom: 4px;
  display: inline-block;
  ${Styles.Font.captionSemibold};

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    color: ${theme.config.input.placeholder};
    background-color: ${theme.colors.grey.tertiary.standard};
  `}
`;
