import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import { Dialog, StandardButton } from '@gv/triage-components';
import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import { NurseStatus } from 'types';
import { CommContext } from 'context/comm';
import { useRefresh, useVisibility } from 'hooks';
import { OutboundContext } from 'context/outbound';
import { useAppDispatch, useAppSelector } from 'store';
import {
  logout,
  selectNurseStatus,
  selectPauseIdleTimer,
} from 'store/slices/auth';

export const IdleTimer = () => {
  const dispatch = useAppDispatch();
  const isPaused = useAppSelector(selectPauseIdleTimer);
  const nurseStatus = useAppSelector(selectNurseStatus);
  const location = useLocation();

  const [isAlertVisible, setAlertVisible] = useState(false);
  const [lastEventDate, setLastEventDate] = useState(Date.now());
  const { refresh, refreshValue } = useRefresh();
  const deboucneRef = useRef<ReturnType<typeof debounce> | null>(null);
  const isTabVisible = useVisibility();

  const { activeCall: confCall } = useContext(CommContext);
  const { activeCall: outboundCall } = useContext(OutboundContext);

  const useIdle =
    isTabVisible &&
    !confCall &&
    !outboundCall &&
    !isPaused &&
    nurseStatus !== NurseStatus.Break;

  const onInactive = useCallback(() => {
    dispatch(logout({}));
  }, [dispatch]);

  useEffect(() => {
    if (!useIdle) {
      return;
    }

    const alertId = setTimeout(() => setAlertVisible(true), 240000);
    const inactiveId = setTimeout(onInactive, 300000);
    return () => {
      clearTimeout(alertId);
      clearTimeout(inactiveId);
    };
  }, [useIdle, onInactive, refreshValue, lastEventDate]);

  const updateLastEventDate = useCallback(() => {
    deboucneRef.current?.cancel();
    setLastEventDate(Date.now());
  }, []);

  const debounceUpdate = useMemo(
    () => debounce(updateLastEventDate, 1000),
    [updateLastEventDate]
  );

  useEffect(() => {
    deboucneRef.current?.cancel();
    deboucneRef.current = debounceUpdate;
  }, [debounceUpdate]);

  useEffect(() => {
    return () => {
      deboucneRef.current?.cancel();
    };
  }, []);

  const updateWithDebounce = useCallback(() => {
    refresh();
    debounceUpdate();
  }, [refresh, debounceUpdate]);

  useEffect(() => updateLastEventDate, [useIdle, location]);

  useEffect(() => {
    window.addEventListener('mousemove', updateWithDebounce);
    window.addEventListener('keydown', updateWithDebounce);
    return () => {
      window.removeEventListener('mousemove', updateWithDebounce);
      window.removeEventListener('keydown', updateWithDebounce);
    };
  }, [updateWithDebounce]);

  if (!isAlertVisible) {
    return null;
  }

  return (
    <Dialog
      open
      title="Hey!"
      width="256px"
      text="Are you still here?"
      onClose={() => setAlertVisible(false)}
      buttons={(close) => (
        <StandardButton fullWidth text="Yes" onClick={close} />
      )}
    />
  );
};
