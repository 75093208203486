import { Utils } from '@gv/triage-components';

import { ExpandableSection } from 'components/cases';

import { NotesProps } from './types';

const { textValue } = Utils.Helpers;

export const Notes = ({ type, plan, assessment }: NotesProps) => {
  return (
    <ExpandableSection defaultOpened withoutSpacing label={`${type} notes`}>
      {assessment && type === 'CVT' && (
        <div>
          <h3 className="semibold">Assessment</h3>
          <p>{assessment}</p>
        </div>
      )}

      <div>
        <h3 className="semibold">Plan for {type}</h3>
        <p>{textValue(plan)}</p>
      </div>
    </ExpandableSection>
  );
};
