import { Formik } from 'formik';
import { useState } from 'react';
import {
  Row,
  Col,
  Loader,
  Details,
  FormFooter,
  BackButton,
  useNavigate,
  ButtonTypes,
  ButtonColors,
  StandardButton,
} from '@gv/triage-components';

import * as CommonStyles from '../../styles';

import { PetFormProps } from './types';
import { PetFields } from './petFields';
import { ageValidate, validationSchema } from './config';

const PetForm = ({
  isEdit,
  onSubmit,
  isFetching,
  initialValues,
}: PetFormProps) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  return (
    <Details isLoading={isFetching}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validate={ageValidate}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => (
          <CommonStyles.Form>
            <Row>
              <Col>
                <BackButton text={isEdit ? 'Edit Pet' : 'New Pet'} />
              </Col>
            </Row>

            <CommonStyles.Inner>
              <PetFields setLoading={setLoading} />
            </CommonStyles.Inner>

            <FormFooter
              right={
                <>
                  <StandardButton
                    text="Cancel"
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.Secondary}
                  />

                  <StandardButton
                    text="Save"
                    type={ButtonTypes.Submit}
                    disabled={!dirty || isSubmitting}
                  />
                </>
              }
            />

            <Loader absolutePosition isLoading={isLoading} />
          </CommonStyles.Form>
        )}
      </Formik>
    </Details>
  );
};

export { PetForm };
