import { useCallback } from 'react';
import { FormikValues } from 'formik';
import { useNavigate, handleMutation } from '@gv/triage-components';

import { useAppSelector } from 'store';
import { useHospitalParams } from 'hooks';
import {
  selectClient,
  useDetailsQuery,
  useUpdatePetMutation,
} from 'store/api/clients';

import { PetForm } from './form';
import { PetFormPage } from './types';
import { getInitialValues, getUpdatePayload } from './config';

const PetEditGlobal = ({ clientId }: PetFormPage): JSX.Element => {
  const navigate = useNavigate();
  const { petId } = useHospitalParams();
  const [update, mutation] = useUpdatePetMutation();

  const { isFetching } = useDetailsQuery({ id: clientId! });
  const data = useAppSelector(selectClient(clientId));

  const onSuccess = useCallback(() => navigate(-1), []);
  handleMutation({ ...mutation, onSuccess });

  const onSubmit = async (values: FormikValues) => {
    if (clientId && petId) {
      await update(
        getUpdatePayload({
          petId,
          values,
          clientId,
        })
      );
    }
  };

  return (
    <PetForm
      isEdit
      onSubmit={onSubmit}
      isFetching={isFetching}
      initialValues={getInitialValues(data?.pets || [], petId)}
    />
  );
};

const PetEdit = (): JSX.Element => {
  const { clientId } = useHospitalParams();
  return <PetEditGlobal clientId={clientId!} />;
};

export { PetEdit, PetEditGlobal };
