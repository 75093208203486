import styled from 'styled-components';
import { FlexLayout } from '@gv/triage-components';

export const Info = styled(FlexLayout)`
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.red.secondary.standard};
`;

export const ChildrenWrapper = styled(FlexLayout)<{ disabled: boolean }>`
  gap: 24px;
  flex-direction: column;
  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
`;
