import {
  Icon,
  Info,
  Utils,
  ButtonColors,
  StandardButton,
  FeedbackPoints,
} from '@gv/triage-components';

import * as CommonStyles from 'components/communication-log/styles';

import { SelectedEventPageProps } from '../types';

export const FeedbackEvent = ({
  event,
  onClose,
}: SelectedEventPageProps): JSX.Element => {
  return (
    <>
      <CommonStyles.Header>
        <StandardButton
          padding="0"
          onClick={onClose}
          icon={<Icon.ChevronLeft />}
          colorType={ButtonColors.Transparent}
        />

        <div>
          <h3>User feedback</h3>
          <p className="caption-regular">
            {Utils.Date.formatDate(event.timestamp, 'MMMM D, YYYY LT')}
          </p>
        </div>
      </CommonStyles.Header>

      <CommonStyles.EventContent>
        <Info
          noEllipsis
          title="Client"
          value={
            <div>
              <p className="semibold">
                {Utils.Helpers.textValue(event.client?.name)}
              </p>
              <p>{Utils.Helpers.formatPhone(event.client?.phone)}</p>
            </div>
          }
        />

        <Info
          noEllipsis
          title="Rate"
          value={
            <FeedbackPoints
              gap={12}
              icons={[]}
              rating={event.feedback?.rating ?? 0}
            />
          }
        />

        <Info noEllipsis title="Comment" value={event.feedback?.note} />
      </CommonStyles.EventContent>
    </>
  );
};
