import { Row, Col, RadioFieldGroup } from '@gv/triage-components';

import { priorities } from './config';
import { ConcernProps } from './types';

export const Concern = ({ isCSR }: ConcernProps) => {
  if (isCSR) {
    return null;
  }

  return (
    <Row>
      <Col>
        <RadioFieldGroup
          items={priorities}
          label="Case status"
          name="priority_of_concern"
        />
      </Col>
    </Row>
  );
};

export type { ConcernProps };
